//钱包

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import PublicUtils from '../../../utils/publicUtils'
import Container from '../../../component/container'
import Navigate from '../../../component/navigate'
import FloatBtn from '../../../component/floatBtn'
import ActivityIndicator from '../../../component/activityIndicator'
import Storage from '../../../services/storage'
import Icon from '../../../utils/icon'
import style from '../../../utils/config.scss'
import Http from "../../../services/http";
import '../../../assets/css/app.css'
import model from "../../../services/model";
import Image from '../../../component/image'
import Avatar from "../../../component/avatar/index";
import Background from "../../../assets/images/login-bg.jpg";
import DrawerPage from "../../../component/drawerPage";

class Default extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_balance: {},
            head_img: {},
            user_account: {},
            pay_num: {},
            user_name: {},
            kuisun: {},
            showProgress: false
        };
    }

    componentDidMount() {
        this._fetchBalance();
    }

    componentWillUnmount() {
    }

    /**
     * 初始化
     * @returns {Promise<void>}
     * @private
     */
    _init_ = async () => {
        // alert(await Storage.retrieveData("user"));
        const user = JSON.parse(Storage.retrieveData("user"));
        user.pay_num = user.pay_num && (user.pay_num.substring(0, 4) + "****" + user.pay_num.substr(user.pay_num.length - 4));
        if (user) {
            let newState = {};
            for (let key in user) {
                newState[key] = {value: user[key]};
                if (user[key]) {
                    newState[key].isEdit = true;
                }
            }
            console.log(newState);
            this.setState(newState);
        }
    };

    /**
     * 绑定银行卡
     */
    _bindBankCard() {
        if (this.state.user_name.isEdit) {
            // if (!this.state.pay_num.value) {
            //     this._checkLogin('/bindbank', {type: this.state.pay_num.value ? 1 : 2});
            // } else {
            //     PublicUtils.showToast("请联系在线客服修改银行卡");
            // }
            this._checkLogin('Bindbank', {type: this.state.pay_num.value ? 1 : 2});
            //$state.go("tabs.bindBank");
            // this.Api.navPush(BindBankPage, this.navCtrl, true);
        } else {
            PublicUtils.showToast("您还没有绑定姓名哦，请前往个人中心绑定");
        }
    }

    /**
     * 判断是否登录
     * @param nextPage
     * @param Params
     * @returns {Promise<void>}
     * @private
     */
    _checkLogin = async (nextPage, Params) => {
        if (await PublicUtils._checkLogin()) {
            Params.fromPage = "Wallet";
            PublicUtils.navigate(nextPage, Params);
        } else {
            PublicUtils.navigate('Login', {nextPage: '/wallet'});
        }
    };
    /**
     * 修改取款密码
     * @private
     */
    _bindFundPassword = () => {
        let parm = {};
        Http.request("sendInfo", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            } else {
                if (res.code === 200) {
                    const isSend = res.data.is_send.is_send;
                    if (isSend && !this.state.user_phone.value) {
                        PublicUtils.showToast("请先绑定手机", {type: "FAILD"});
                        return;
                    }
                    let Params = {
                        id: 8,
                        title: "修改取款密码",
                        value: '',
                        isSend: isSend
                    };
                    this._checkLogin('MeDetail', Params);
                } else {
                    PublicUtils.showToast("会员信息获取失败", {type: "FAILD"});
                }
            }
        });
    };
    /**
     * 账单
     * @private
     */
    _bill = () => {
        this._checkLogin('AccountChange', {});
    };
    /**
     * 转账
     * @private
     */
    _gameTransfer = () => {
        this._checkLogin('GameTransfer', {});
    };
    /**
     * 提现
     * @private
     */
    _whitdrawal = async () => {
        let userInfo = JSON.parse(await Storage.retrieveData("user"));
        if (userInfo.pay_card || userInfo.more_card.length > 0) {
            this._checkLogin('Withdrawal', {});
        } else {
            PublicUtils.showToast("您必须绑定银行卡之后才能提现哦");
        }
        // this._checkLogin('Withdrawal', {});
    };
    /**
     * 充值
     * @private
     */
    _recharge = async () => {
        // let userInfo = JSON.parse(await Storage.retrieveData("user"));
        // if (userInfo.pay_card) {
        //     this._checkLogin('RechargeChannel', {});
        // } else {
        //     PublicUtils.showToast("您必须绑定银行卡之后才能充值哦");
        // }
        this._checkLogin('RechargeChannel', {});
    };
    /**
     * 彩金大厅
     * @returns {Promise<void>}
     * @private
     */
    _memberBonus = async () => {
        // let userInfo = JSON.parse(await Storage.retrieveData("user"));
        // if (userInfo.pay_card) {
        //     this._checkLogin('RechargeChannel', {});
        // } else {
        //     PublicUtils.showToast("您必须绑定银行卡之后才能充值哦");
        // }
        this._checkLogin('MemberBonus', {});
    };

    /**
     * 月月转运活动
     * @returns {Promise<void>}
     * @private
     */
    _memberKuisun = async () => {
        // let userInfo = JSON.parse(await Storage.retrieveData("user"));
        // if (userInfo.pay_card) {
        //     this._checkLogin('RechargeChannel', {});
        // } else {
        //     PublicUtils.showToast("您必须绑定银行卡之后才能充值哦");
        // }
        this._checkLogin('MemberKuisun', {});
    };

    /**
     * 获取余额
     */
    _fetchBalance() {
        let parm = {action:'refresh'};
        this.setState({showProgress: true});
        Http.request("memberBalance", parm, (res) => {
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
                this.setState({showProgress: false});
            }, 1000);
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            } else {
                if (res.code === 200) {
                    if (res.data && res.data.status) {
                        PublicUtils.updateUserInfo({is_balance: res.data.balance.balance});
                        this._init_();
                    }
                } else {
                    PublicUtils.showToast("会员信息获取失败");
                }
            }
        });
    }


    render() {
        // const getHeight = () => {
        //     let height = 50;
        //     if (Platform.OS === "ios") {
        //         if (!model.isIphoneX) {
        //             height += 20;
        //         }
        //     }
        //     return height;
        // }
        const styles = {
            headerContainer: {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: 70,
                // backgroundColor: "#000",
                zIndex: 1
            },
            header: {
                position: "relative",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 1,
                background: "linear-gradient(to left, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                // background: "url(" + Background + ")"
                // backgroundColor:"#000"
                // background:"url(../../../assets/images/login-bg.jpg) 100% 100% no-repeat"
            },
            headerBgContainer: {
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                // zIndex: -1
            },
            headerBg: {
                flex: 1,
                width: model.clientWidth,
                height: "100%",
            },
            userContainer: {
                paddingTop: 10,
                paddingLeft: 13,
                paddingRight: 13,
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderTopLeftRadius: 6,
                borderTopRightRadius: 9,
                // marginTop: model.STATUSBAR_H + 50
            },
            userTopContainer: {
                position:'relative',
                display: "flex",
                flexDirection: "row",
                paddingTop: 5,
                paddingBottom: 5,
                alignItems: "center"
            },
            avatarContainer: {
                width: 45,
                height: 45,
            },
            avatar: {
                width: "100%",
                height: "100%"
            },
            username: {
                marginLeft: 10,
                fontSize: 18,
                color: "#000"
            },
            userBottomContainer: {
                marginTop: 13
            },
            userBottomTopContainer: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 3,
            },
            userBottomBottomContainer: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
            },
            assetsImg: {
                width: 20,
                height: 20,
                marginRight: 5
            },
            assetsText: {},
            blanace: {
                fontSize: 30,
                color: "#000"
            },
            sectionContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                // borderTopWidth: 1,
                // borderStyle: "solid",
                // borderColor: "#f5f5f5",
                borderTop: "1px solid #f5f5f5",
                paddingTop: 10,
                paddingBottom: 10,
            },
            sectionItemContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 4,
                paddingBottom: 4,
                // borderRightWidth: 1,
                // borderStyle: "solid",
                // borderColor: "#f5f5f5"
                borderRight:"1px solid #f5f5f5"
            },
            sectionItemInnerContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            sectionItemText: {
                marginLeft: 5,
                fontSize: 16
            },
            menuItemWrapper: {
                marginTop: 20,
                backgroundColor: "#fff",
                borderTopColor: "#f5f5f5",
                borderTopWidth: 1
            },
            menuItemChild: {
                paddingLeft: 0
            },
            title: {
                position: "relative",
                display: "flex",
                color: "#fff",
                textAlign: "center",
                fontSize: 16,
                height: 60,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            leftSlideBtn: {
                position: "absolute",
                display: "flex",
                width: 35,
                height: 35,
                borderRadius: 35,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "rgba(0,0,0,0.1)",
                left: 0
            }
        };
        return (
            <Container tabIndex={1} showBottomTab={true} showHeader={false}>
                {/*<div style={styles.headerContainer}>*/}
                {/*    <Header leftButtonIcon={"caidan"} backButtonOnPress={() => {*/}
                {/*        PublicUtils.openDrawer();*/}
                {/*    }} backgroundColor={'transparent'} leftButtonIconColor={"#fff"}*/}
                {/*            titleColor={"#fff"}>我的钱包</Header>*/}
                {/*</div>*/}
                <div style={styles.header}>
                    {/*<div style={styles.headerBgContainer}>*/}
                    {/*    <Image style={styles.headerBg} resizeMode='cover'*/}
                    {/*           uri={require('../../../assets/images/login-bg.jpg')}/>*/}
                    {/*</div>*/}
                    <div style={styles.title} className={'headPadding'}>
                        <div style={styles.leftSlideBtn} type={1} onClick={() => {
                            this.DrawerPage.show();
                        }}>
                            <div>
                                <Icon icon={'caidan'} color={"#fff"} size={15}/>
                            </div>
                        </div>
                        <span>我的钱包</span>
                    </div>
                    <div style={styles.userContainer}>
                        <div style={styles.userTopContainer}>
                            <div style={styles.avatarContainer}>
                                <Avatar width={45} height={45} source={this.state.head_img.value}/>
                                {/*<Image style={styles.avatar} resizeMode='contain'*/}
                                {/*       source={require('../../../assets/images/theme1/tx.png')}/>*/}
                            </div>
                            <div style={styles.username}>
                                {PublicUtils.hello()}{this.state.user_account.value}
                            </div>

                            {/*<div onClick={() => {*/}
                            {/*    this._checkLogin('SignIn', {});*/}
                            {/*}} style={{position:'absolute',right:10,top:18,color:'rgb(208 80 6)',border:'1px solid rgb(199 124 124)',fontSize:12,padding:5}}>*/}
                            {/*    签到奖励*/}
                            {/*</div>*/}
                        </div>
                        <div style={styles.userBottomContainer}>
                            <div style={styles.userBottomTopContainer}>
                                <Image style={styles.assetsImg} resizeMode='cover'
                                       uri={require('../../../assets/images/theme1/mn.png')}/>
                                <div style={styles.assetsText}>总资产（元）</div>
                            </div>
                            <div style={styles.userBottomBottomContainer}>

                                {
                                    this.state.showProgress ?
                                        <ActivityIndicator style={{marginTop: 5}}
                                                           color={style['primaryColor' + model.SKINNAME]}/> :
                                        <div style={styles.blanace}>{this.state.is_balance.value}</div>
                                }
                                <div
                                    onClick={() => {
                                        this._fetchBalance();
                                    }}>
                                    <Icon icon={'refresh'} color={style['primaryColor' + model.SKINNAME]} size={25}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.sectionContainer}>
                    <div style={styles.sectionItemContainer} type={2}
                         onClick={() => {
                             this._recharge();
                         }}>
                        <div style={styles.sectionItemInnerContainer}>
                            <Icon style={{marginTop:-5}} icon={'chongzhi1'} color={"#fa7925"} size={15}/>
                            <div style={styles.sectionItemText}>充值</div>
                        </div>
                    </div>
                    <div style={styles.sectionItemContainer} type={2}
                         onClick={() => {
                             this._whitdrawal();
                         }}>
                        <div style={styles.sectionItemInnerContainer}>
                            <Icon style={{marginTop:-3}} icon={'qianbao1'} color={"#2f89dc"} size={15}/>
                            <div style={styles.sectionItemText}>提款</div>
                        </div>
                    </div>

                    <div style={styles.sectionItemContainer} type={2}
                         onClick={() => {
                             this._gameTransfer();
                         }}>
                        <div style={styles.sectionItemInnerContainer}>
                            <Icon icon={'zhuanzhang1'} color={"#64b437"} size={15}/>
                            <div style={styles.sectionItemText}>转账</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={styles.menuItemWrapper}>
                        <Navigate headerIcon={'new-bankcard'} headerIconColor={'#25a42a'} title={'我的银行卡'}
                                  note={this.state.pay_num.value || "未绑定"} onPress={() => {
                            this._bindBankCard()
                        }}/>
                    </div>
                    <div style={styles.menuItemWrapper}>
                        <Navigate headerIcon={'new-password'} headerIconColor={'#ecc016'} title={'取款密码'} note={'设置'}
                                  onPress={() => {
                                      this._bindFundPassword();
                                  }}/>
                    </div>
                    <div style={styles.menuItemWrapper}>
                        <Navigate headerIcon={'game-live'} headerIconColor={'#861fbf'} title={'彩金大厅'} note={'进入'}
                                  onPress={() => {
                                      this._memberBonus();
                                  }}/>
                        {
                            this.state.kuisun.value ?
                                <Navigate headerIcon={'game-live'} headerIconColor={'#3e9e0d'}
                                          title={this.state.kuisun.value} note={'进入'}
                                          onPress={() => {
                                              this._memberKuisun();
                                          }}/> : null
                        }
                    </div>
                    <div style={{...styles.menuItemWrapper, ...{paddingLeft: 10}}}>
                        <Navigate style={styles.menuItemChild} headerIcon={'zhangbian'} headerIconColor={'#37aef5'}
                                  title={'账单'} note={'查看'} onPress={() => {
                            this._bill();
                        }}/>
                    </div>
                </div>
                <DrawerPage ref={(ref) => {
                    this.DrawerPage = ref;
                }}/>
            </Container>
        )
    }
}

export default withRouter(Default);
