/**
 * 注册
 * @format
 * @flow
 */
import React, {Component} from 'react';
import style from '../../utils/config.scss'
import Container from '../../component/container'
import ActivityIndicator from '../../component/activityIndicator'
import MyButton from '../../component/myButton'
import MyInput from '../../component/myInput'
import MyPicker from '../../component/myPicker'
import Icon from '../../utils/icon'
import Loadding from "../../component/loadding";
import Http from "../../services/http";
import model from "../../services/model";
import global from "../../global";
import PublicUtils from "../../utils/publicUtils";
import DatePicker from 'react-mobile-datepicker';
import Image from "../../component/image";
import MsgCode from "../../component/msgCode";
import regClose from "../../assets/images/reg-close.png";
import Storage from "../../services/storage";

export default class Register extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        let agents = this.getQueryString('agents') || Storage.retrieveData("agents2");


        //
        if(global.ID == 'yy' && this.getQueryString('agents')){

            localStorage.setItem('agents2',agents);
            // PublicUtils.navigate("Home");

        }else{
            // 苹果历史记录返回不了 第一次访问处理
            if(agents){
                localStorage.setItem('agents2',agents);
                localStorage.setItem('jll','yes');
            }
        }



        this.state = {
            info: {},
            codeInfo: {},
            enableLoginBtn: false,
            showProgress: false,
            isReady: false,
            btnTips: "立即注册",
            time: new Date(),
            birthday: "",
            isOpen: false,
            safeQuestionFliter: false,
            agents: agents,
            checked: true,
            notice_win: null,
            notice_win_type:0,
            show:0,
        };
        // alert(agents);
        this.RegForm = {
            question: model.safeQuestionOption[0].label,
            agent_account: this.state.agents,
        };


        if(global.ID == 'yy'){
            this._fetchData();
        }
    }


    _fetchData = () => {
        let parm = {};
        let tempThis = this;
        Http.request("getMainPageData", parm, (res) => {
            PublicUtils.hideLoader();
            // return;
            if (res.code === 200) {
                this.setState({
                    notice_win: res.data.notice_win || null,
                    notice_win_type: res.data.notice_win.is_status,
                    show:1,
                });

            }
        });
    };

    _renderNoticeWin = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                // padding: 10,
                // borderRadius: 6,
            }
        };
        return <div style={winStyles.bgShadow}>
            <div style={winStyles.innerBody}>
                <div style={winStyles.title}>
                    <div style={winStyles.closeButtonTop} onClick={() => {

                        //下次进入首页将不在显示广告弹窗
                        this.setState({show: false});
                    }}>
                        <Icon icon={"close"} size={20} color={"#fff"}/>
                    </div>
                    <div style={winStyles.closeButtonBottom}/>
                </div>
                <div style={winStyles.content}>
                    {
                        this.state.notice_win_type === 0 ?
                          <Image width={'100%'}
                                 uri={this.state.notice_win.mobile_content}/> :
                          <div style={winStyles.noticeWinContentBox}
                               dangerouslySetInnerHTML={{__html: this.state.notice_win.mobile_true_content || this.state.notice_win.pc_content}}/>
                    }
                </div>
            </div>
        </div>

    }

    /**
     * 获取url参数
     * @param name
     */
    getQueryString = (name) => {
        let value = "";
        const paramArray = window.location.hash.split('?');
        if (paramArray.length > 1) {
            const paramItem = paramArray[1].split('&');
            for (let i = 0; i < paramItem.length; i++) {
                if (paramItem[i].split('=')[0] === name) {
                    value = paramItem[i].split('=')[1];
                }
            }
        }
        return value;
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._getRegFrom();
    };
    formatDate = (date) => {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let second = date.getSeconds();
        second = minute < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d;
    };


    /**
     * 表单验证
     * */
    _checkForm = (data) => {
        // this.RegForm = Object.assign(this.RegForm, data);
        if (!this.RegForm.user_account || !model.inputRule.username.rule.test(this.RegForm.user_account)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.username.ruleMsg});
            return false;
        }
        // if (!this.RegForm.login_password || !model.inputRule.password.rule.test(this.RegForm.login_password)) {
        //     this.setState({enableLoginBtn: false, tips: model.inputRule.password.ruleMsg});
        //     return false;
        // }

        let isflag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）――|{}【】‘；：”“'。，、？ ]")


        if(!this.RegForm.login_password || this.RegForm.login_password.length<6 || this.RegForm.login_password.length>20){
            this.setState({enableLoginBtn: false, tips: "密码长度为1-20个字符"});
            return false;
        }

        if(global.ID != 'b6' || global.ID != 'js'){

            if(!isflag.test(this.RegForm.login_password)){
                this.setState({enableLoginBtn: false, tips: "密码必须包含一个特殊字符,例如：!@#$%等等"});
                return false;
            }
        }



        if (this.RegForm.login_password2 !== this.RegForm.login_password) {
            this.setState({enableLoginBtn: false, tips: "两次登录密码输入不一致"});
            return false;
        }
        if (this.state.info.reg_name_1 == 1 && this.state.info.reg_name_2 == 1 && !model.inputRule.name.rule.test(this.RegForm.nickname)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.name.ruleMsg});
            return false;
        }

        if (this.state.info.reg_birthday_1 == 1 && this.state.info.reg_birthday_2 == 1 && !this.RegForm.birthday) {
            this.setState({enableLoginBtn: false, tips: "请选择您的生日"});
            return false;
        }
        if (this.state.info.reg_phone_1 == 1 && this.state.info.reg_phone_2 == 1 && !model.inputRule.mobile.rule.test(this.RegForm.mobile)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.mobile.ruleMsg});
            return false;
        }
        if (this.state.info.reg_mail_1 == 1 && this.state.info.reg_mail_2 == 1 && !model.inputRule.email.rule.test(this.RegForm.email)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.email.ruleMsg});
            return false;
        }
        if (this.state.info.reg_qq_1 == 1 && this.state.info.reg_qq_2 == 1 && !model.inputRule.qq.rule.test(this.RegForm.qq)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.qq.ruleMsg});
            return false;
        }
        if (this.state.info.reg_password_1 == 1 && this.state.info.reg_password_2 == 1 && !model.inputRule.pay_password.rule.test(this.RegForm.safe_password)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.pay_password.ruleMsg});
            return false;
        }
        if (this.state.info.reg_password_1 == 1 && this.state.info.reg_password_2 == 1 && (this.RegForm.safe_password !== this.RegForm.safe_password2)) {
            this.setState({enableLoginBtn: false, tips: "两次取款密码输入不一致"});
            return false;
        }
        if (this.state.info.reg_question_1 == 1 && this.state.info.reg_question_2 == 1 && (!this.RegForm.answer || !this.RegForm.answer)) {
            this.setState({enableLoginBtn: false, tips: "请输入密保答案"});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };

    /**
     * 验证用户名
     * @private
     */
    _checkUserName = (data) => {
        // this.RegForm.user_account = data.user_account;
        this.RegForm = Object.assign(this.RegForm, data);
        // console.log(this.RegForm.user_account)
        if (!this.RegForm.user_account || !model.inputRule.username.rule.test(this.RegForm.user_account)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.username.ruleMsg});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证登录密码
     * @private
     */
    _checkLoginPwd = (data) => {
        // if (!this._checkUserName()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);

        let isflag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）――|{}【】‘；：”“'。，、？ ]")


        if(!this.RegForm.login_password || this.RegForm.login_password.length<6 || this.RegForm.login_password.length>20){
            this.setState({enableLoginBtn: false, tips: "密码长度为1-20个字符"});
            return false;
        }


        if(global.ID != 'b6' || global.ID != 'js'){
            if(!isflag.test(this.RegForm.login_password)){
                this.setState({enableLoginBtn: false, tips: "密码必须包含一个特殊字符,例如：!@#$%等等"});
                return false;
            }

        }


        // if (!this.RegForm.login_password || !model.inputRule.password.rule.test(this.RegForm.login_password)) {
        //     this.setState({enableLoginBtn: false, tips: model.inputRule.password.ruleMsg});
        //     return false;
        // }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证登录密码是否一致
     * @private
     */
    _checkLoginPwdAgin = (data) => {
        // if (!this._checkLoginPwd()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.RegForm.login_password2 !== this.RegForm.login_password) {
            this.setState({enableLoginBtn: false, tips: "两次登录密码输入不一致"});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});


        return true;
    };
    /**
     * 验证推荐人
     * @private
     */
    _checkUserAgent = (data) => {
        this.RegForm = Object.assign(this.RegForm, data);
    };
    /**
     * 验证真实姓名
     * @private
     */
    _checkRealName = (data) => {
        // if (!this._checkLoginPwdAgin()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_name_1 == 1 && this.state.info.reg_name_2 == 1 && !model.inputRule.name.rule.test(this.RegForm.nickname)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.name.ruleMsg});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证生日
     * @private
     */
    _checkBirthday = (data) => {
        // if (!this._checkRealName()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_birthday_1 == 1 && this.state.info.reg_birthday_2 == 1 && !this.RegForm.birthday) {
            this.setState({enableLoginBtn: false, tips: "请选择您的生日"});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证电话号码
     * @private
     */
    _checkPhone = (data) => {
        // if (!this._checkBirthday()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_phone_1 == 1 && this.state.info.reg_phone_2 == 1 && !model.inputRule.mobile.rule.test(this.RegForm.mobile)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.mobile.ruleMsg});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证Email
     * @private
     */
    _checkEmail = (data) => {
        // if (!this._checkPhone()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_mail_1 == 1 && this.state.info.reg_mail_2 == 1 && !model.inputRule.email.rule.test(this.RegForm.email)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.email.ruleMsg});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证QQ
     * @private
     */
    _checkQQ = (data) => {
        // if (!this._checkEmail()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_qq_1 == 1 && this.state.info.reg_qq_2 == 1 && !model.inputRule.qq.rule.test(this.RegForm.qq)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.qq.ruleMsg});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证取款密码
     * @private
     */
    _checkPayPwd = (data) => {
        // if (!this._checkQQ()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_password_1 == 1 && this.state.info.reg_password_2 == 1 && !model.inputRule.pay_password.rule.test(this.RegForm.safe_password)) {
            this.setState({enableLoginBtn: false, tips: model.inputRule.pay_password.ruleMsg});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证取款密码是否一致
     * @private
     */
    _checkPayPwdAgin = (data) => {
        // if (!this._checkPayPwd()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_password_1 == 1 && this.state.info.reg_password_2 == 1 && (this.RegForm.safe_password !== this.RegForm.safe_password2)) {
            this.setState({enableLoginBtn: false, tips: "两次取款密码输入不一致"});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 验证密码答案
     * @private
     */
    _checkAnswer = (data) => {
        // if (!this._checkPayPwdAgin()) {
        //     return false;
        // }
        this.RegForm = Object.assign(this.RegForm, data);
        if (this.state.info.reg_close == 1 && this.state.info.reg_question_2 == 1 && (!this.RegForm.answer || !this.RegForm.answer)) {
            this.setState({enableLoginBtn: false, tips: "请输入密保答案"});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };

    /**
     * 获取注册字段
     * @private
     */
    _getRegFrom() {
        let parm = {
            is_agents: this.RegForm.agents ? 0 : 1
            // device: config.DEVICE,
            // action: "get"
        };
        Http.request('getRegisterFrom', parm, (response) => {
            if (response.data.status === 1) {



                if(response.data.info.agent_account){

                    this.RegForm.agent_account = response.data.info.agent_account;


                    this.setState({info: response.data.info, isReady: true,agents:response.data.info.agent_account});
                }else{

                    this.setState({info: response.data.info, isReady: true});
                }
                if (response.data.info.reg_code_1 == 1 && response.data.info.reg_code_2 == 1) {
                    this._getCode();
                }
                // alert(JSON.stringify(response.data.info));
            }
        });
    }

    /*
    *  获取 注册 验证码
    * */
    _getCode() {
        let parm = {};
        Http.request("regCode", parm, (response) => {
            if (response.code == 200) {
                // alert(JSON.stringify(response));
                const codeInfo = response.data;
                const _array = response.data.code.split("");
                codeInfo.array = _array;
                this.setState({codeInfo: codeInfo,});
            }
        });
    }

    /*
    *  验证验证码是否输入
    * */
    _checkCode = (data) => {

        console.log(this.state.info)
        if (!this._checkAnswer()) {
            return false;
        }
        this.RegForm = Object.assign(this.RegForm, data);

        // 用户输入与接口返回对应 reg_code_1 显示 reg_code_2 填与不填 验证码使用第一个
        if (!this.RegForm.code && this.state.info.reg_code_1 == 1 && this.state.info.reg_code_2 == 1) {
            this.setState({enableLoginBtn: false, tips: "请输入验证码"});
            return false;
        }
        this.setState({enableLoginBtn: true, tips: ""});
        return true;
    };
    /**
     * 提交注册信息
     * @private
     */
    _onRegister = () => {



        if(global.ID == 'b6'){
            if(!this.RegForm.agent_account){
                this.setState({enableLoginBtn: false, tips: "请输入推荐人"});
                return;
            }
        }


        if (!this._checkForm()) {
            return;
        }

        // this.RegForm.login_password

        //如果存在  验证码必填
        if (this.state.codeInfo.reg_token) {
            if (!this._checkCode()) {
                return;
            }
        }
        if (!this.state.checked) {
            PublicUtils.showToast("请同意开户条约", {type: "FAILD"});
            return;
        }
        this.setState({showProgress: true});




        this._postRegister();
    };

    _postRegister = () => {
        let body = {device: global.DEVICE}
        // ; Object.assign(this.RegForm, {
        //     reg_token:"WbFols3hVuin0250M1y84xNzcuODEuMjYtMTU2ODEyNjQxNQO0O0OO0O0O",
        // });
        if (this.state.codeInfo.reg_token) {
            body.reg_token = this.state.codeInfo.reg_token
        }
        body = Object.assign(this.RegForm, body)
        // console.log(body);
        // for (let key in body) {
        //     if (body[key].text) {
        //         body[key] = body[key].text;
        //     }
        // }
        // console.log(body);
        let tempThis = this;
        Http.request('register', body, (response) => {
            this.setState({showProgress: false});
            if (response.data.status === 1) {
                // this._onLogin();
                PublicUtils.showToast("注册成功，正在跳转至登录页面");

                if(global.ID == 'yy'){
                    let tempObj = JSON.stringify({uname:tempThis.RegForm.user_account,upwd:tempThis.RegForm.login_password})
                    Storage.storeData({zczt: tempObj});
                }

                PublicUtils.navigate("Login");
            } else {
                this.setState({enableLoginBtn: true, tips: response.data.message});
                // PublicUtils.showToast(response.data.message);
                //如果 验证码 存在  重新刷新验证码
                if (this.state.codeInfo.reg_token) {
                    this._getCode();
                }

            }
        });
    }
    /**
     * 检测用户名是否存在
     * @private
     */
    _validUsername = () => {
        if (!this.RegForm.user_account) {
            return;
        }
        let body = {
            user_account: this.RegForm.user_account
        };
        // console.log(body);
        // for (let key in body) {
        //     if (body[key].text) {
        //         body[key] = body[key].text;
        //     }
        // }
        // console.log(body);
        Http.request('valid', body, (response) => {
            if (!response.data.status) {
                this.setState({enableLoginBtn: false, tips: "用户名已存在"});
            }
        });
    };
    /**
     * 登录
     * */
    _onLogin = () => {
        this.setState({showProgress: true, btnTips: "注册成功，正在登录"});
        let parm = {
            device: global.DEVICE,
            user_account: this.RegForm.user_account,
            login_password: this.RegForm.login_password
        };
        Http.request('login', parm, (response) => {
            this.setState({showProgress: false});
            if (response.data.status === 1) {
                if (response.data.token) {
                    response.data.isSavePwd = true;
                    if (response.data.isSavePwd) {
                        response.data.userName = this.RegForm.user_account;
                        response.data.passWord = this.RegForm.login_password;
                    }


                    // y8处理
                    if(global.ID == 'y8'){
                        global.syObj.setState({usedl:true});
                        response.data.user.head_img = "https://appnew.hdyb33.com/code/y8/touxiang.png";
                    }

                    // b8处理
                    if(global.ID == 'b8'){
                        global.syObj.setState({usedl:true});
                        response.data.user.head_img = "https://appnew.hdyb33.com/code/b8/touxiang.png";
                    }

                    PublicUtils.saveUserInfo(response.data);
                }
                PublicUtils.navigate("Home");
            } else {
                PublicUtils.showToast(response.data.message);
            }
        });
    };
    _getPlaceholder = (name, isRequired) => {
        let placeholderText = name;
        if (isRequired == 1) {
            placeholderText += '（必填）';
        }
        return placeholderText;
    };

    /**
     * 构建表单
     * @returns {*}
     * @private
     */
    _getRegBody = () => {
        const styles = {
            container: {
                backgroundColor: "#fff"
            },
            btnContainer: {
                display: "flex",
                // paddingLeft: 10,
                // paddingRight: 10,
                marginTop: 40,
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
            },
            btnBox: {
                display: "flex",
                flex: 1,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderRadius: 3
            },
            bottomBtnBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30
            },
            bottomBtnItemBox: {
                marginTop: 20
            },
            bottomBtnItemText: {
                fontSize: 16
            },
            disabledBtn: {
                opacity: 0.6
            },
            btnText: {
                color: "#fff",
                fontSize: 16
            },
            title: {
                backgroundColor: "#f5f5f5",
                paddingLeft: 10,
                fontSize: 14
            },
            iptBox: {
                position: "relative",
                display: "flex",
                flexDirection: "column",
                // height: 40,
                justifyContent: "center",
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid rgba(0,0,0,0.06)",
                // backgroundColor:"#fff"
                // borderWidth: 1,
                // borderColor: "rgba(0,0,0,0.01)",
                // borderStyle: "solid",
                backgroundColor: "#fff",
                marginTop: 10,
                marginBottom: 5,
                borderRadius: 3
            },
            iptLabel: {
                // fontSize: 14,
                // // width: 60,
                // display: "flex",
                // color: "rgba(0,0,0,0.9)"

                height: 38,
                lineHeight: 38,
                paddingLeft: 4,
                // width: 60,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: 14
            },
            textIpt: {
                // width: "auto",
                // display: "flex",
                // flex: 1,
                height: 38,
                alignItems: "center",
                // justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                // textIndent: 10
            },
            regTip: {
                marginTop: 10,
                paddingLeft: 10
            },
            regTipText: {
                color: "#ff4222"
            },
            picker: {
                // height: 40,
                // width: 200,
                // fontSize: 14,
                // flex: 1,
                // padding: 0,
                // transform: [
                //     {
                //         scale: 0.87,
                //     },
                // ],
                // marginLeft: -11
            },
            pickerItem: {
                fontSize: 14,
            },
            tipsBox: {
                position: "absolute",
                top: -23,
                backgroundColor: "#000",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 3
            },
            tipsText: {
                color: "#fff",
                fontSize: 14
            },
            tipsHeaer: {
                position: "absolute",
                left: 10,
                top: "100%",
                width: 0,
                height: 0,
                borderColor: "transparent #000",
                borderWidth: "0 0 9px 12px",
                borderStyle: "solid",
            },
            codeBtn: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#fff",
                borderRadius: 3,
                justifyContent: 'center',
                width: 90,
                // color:"#fff", justifyContent:'center',flexDirection:'row',alignItems:"center",width:90,borderRadius:3,
            },
            getCodeBtn: {
                color: style['primaryColor' + model.SKINNAME],
                // backgroundColor:"#000",
                fontSize: 14
            },
            tipsCheckBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
            },
            tipsTitle: {
                display: "flex",
                border: "1px solid rgba(0,0,0,0.2)",
                // borderWidth: 0.5,
                // borderStyle: "solid",
                // borderColor: "rgba(0,0,0,0.2)",
                height: 16,
                width: 16,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
                backgroundColor: "#fff"
            },
            tipsChecked: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            tipsContent: {
                marginLeft: 5,
                color: "#2c2c2c",
                fontSize: 12
            },
        };
        return (

            this.state.info.reg_close === 1 ?
                <div>
                    {global.ID == 'yy'&&this.state.show?this._renderNoticeWin():<div></div>}
                    <div>
                        <div style={styles.title}>
                            <div>会员登录信息</div>
                        </div>
                        <div style={styles.iptBox}>
                            <div style={styles.iptLabel}>
                                <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-username'}
                                      color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                用户名
                            </div>
                            {/*<input*/}
                            {/*placeholder="请输入用户名（必填）" style={{flex:1,border:0,background:'transparent',height:'100%',fontSize:14}} textContentType={'username'}*/}
                            {/*onChangeText={(text) => {*/}
                            {/*this._checkUserName({*/}
                            {/*user_account: text*/}
                            {/*})*/}
                            {/*this._validUsername();*/}
                            {/*}}*/}
                            {/*/>*/}
                            <MyInput placeholder="请输入用户名（必填）" style={styles.textIpt} textContentType={'username'}
                                // onText={(text) => this._onText(text)}
                                     onChangeText={(text) => {
                                         this._checkUserName({
                                             user_account: text
                                         })
                                         this._validUsername();
                                     }}
                            />
                        </div>
                        <div style={styles.iptBox}>
                            <div style={styles.iptLabel}>
                                <Icon style={{marginTop: -5, marginRight: 3}} icon={'lock1'}
                                      color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                密码
                            </div>
                            <MyInput placeholder="请输入密码（必填）" type={'password'} style={styles.textIpt}
                                     textContentType={'username'}
                                     onChangeText={(text) => this._checkLoginPwd({
                                         login_password: text
                                     })}/>
                        </div>
                        <div style={styles.iptBox}>
                            <div style={styles.iptLabel}>
                                <Icon style={{marginTop: -5, marginRight: 3}} icon={'lock1'}
                                      color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                确认密码
                            </div>
                            <MyInput placeholder="请输入确认密码（必填）" type={'password'} style={styles.textIpt}
                                     textContentType={'username'}
                                     onChangeText={(text) => this._checkLoginPwdAgin({
                                         login_password2: text
                                     })}/>
                        </div>
                        {
                            global.ID !== 'b6' ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'wode-o'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        推荐人
                                    </div>
                                    <MyInput value={this.RegForm.agent_account} placeholder="您的推荐人ID（没有则不输入）"
                                             readOnly={this.state.agents}
                                             style={styles.textIpt}
                                             onChangeText={(text) => this._checkUserAgent({agent_account: text})}/>
                                </div> : <div style={styles.iptBox}>
                                  <div style={styles.iptLabel}>
                                      <Icon style={{marginTop: -5, marginRight: 3}} icon={'wode-o'}
                                            color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                      推荐人
                                  </div>
                                  <MyInput value={this.RegForm.agent_account} placeholder="您的推荐人ID（必填）"
                                           readOnly={this.state.agents}
                                           style={styles.textIpt}
                                           onChangeText={(text) => this._checkUserAgent({agent_account: text})}/>
                              </div>
                        }
                    </div>
                    {(this.state.info.reg_name_1 === 1 || this.state.info.reg_birthday_1 === 1 || this.state.info.reg_phone_1 === 1 || this.state.info.reg_mail_1 === 1
                        || this.state.info.reg_qq_1 === 1 || this.state.info.reg_password_1 === 1) ?
                        <div>
                            <div style={styles.title}>
                                <div>会员个人信息</div>
                            </div>
                            {this.state.info.reg_name_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-realname'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        真实姓名
                                    </div>
                                    <MyInput
                                        placeholder={this._getPlaceholder('必须与银行户名相同，否则不能出款!', this.state.info.reg_name_2)}
                                        style={styles.textIpt} textContentType={'username'}
                                        onChangeText={(text) => this._checkRealName({
                                            nickname: text
                                        })}/>
                                </div> : null
                            }
                            {this.state.info.reg_birthday_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-birthday'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        生日
                                    </div>
                                    <MyInput onPress={this.handleClick} readOnly={true}
                                             value={this.state.birthday}
                                             placeholder={this._getPlaceholder('请选择您的出生日期', this.state.info.reg_birthday_2)}
                                             style={styles.textIpt} textContentType={'username'}
                                             onChangeText={(text) => this._checkBirthday({
                                                 birthday: text
                                             })}/>
                                </div> : null
                            }
                            {this.state.info.reg_phone_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-phone'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        手机号码
                                    </div>
                                    <MyInput
                                        placeholder={this._getPlaceholder('请输入您的手机号码', this.state.info.reg_phone_2)}
                                        style={styles.textIpt} textContentType={'username'}
                                        onChangeText={(text) => this._checkPhone({
                                            mobile: text
                                        })}/>
                                </div> : null
                            }
                            {
                                this.state.info.is_send === 1 ?
                                    <div style={{...styles.iptBox}}>
                                        <div style={styles.iptLabel}>
                                            <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-phone'}
                                                  color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                            手机验证码
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}>
                                            <MyInput placeholder="请输入验证码"
                                                     style={{...styles.textIpt, ...{width: "auto"}}}
                                                     textContentType={'username'}
                                                     onChangeText={(text) => {
                                                         this.RegForm = Object.assign(this.RegForm, {mobile_code: text});
                                                     }}/>
                                            <MsgCode user_account={this.RegForm.user_account}
                                                     user_mobile={this.RegForm.mobile}/>
                                        </div>
                                    </div> : null
                            }
                            {this.state.info.reg_mail_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-email'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        邮箱地址
                                    </div>
                                    <MyInput placeholder={this._getPlaceholder('请输入您的邮箱地址', this.state.info.reg_mail_2)}
                                             style={styles.textIpt} textContentType={'username'}
                                             onChangeText={(text) => this._checkEmail({
                                                 email: text
                                             })}/>
                                </div> : null
                            }
                            {this.state.info.reg_qq_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-qq'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        QQ/微信
                                    </div>
                                    <MyInput placeholder={this._getPlaceholder('请输入您的QQ或微信', this.state.info.reg_qq_2)}
                                             style={styles.textIpt} textContentType={'username'}
                                             onChangeText={(text) => this._checkQQ({
                                                 qq: text
                                             })}/>
                                </div> : null
                            }
                            {this.state.info.reg_password_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'lock1'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        取款密码
                                    </div>
                                    <MyInput type={'password'}
                                             placeholder={this._getPlaceholder('请输入您的取款密码', this.state.info.reg_password_2)}
                                             style={styles.textIpt} textContentType={'username'}
                                             onChangeText={(text) => this._checkPayPwd({
                                                 safe_password: text
                                             })}/>
                                </div> : null
                            }
                            {this.state.info.reg_password_1 === 1 ?
                                <div style={styles.iptBox}>
                                    <div style={styles.iptLabel}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'lock1'}
                                              color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        确认取款密码
                                    </div>
                                    <MyInput type={'password'}
                                             placeholder={this._getPlaceholder('请输入您的确认取款密码', this.state.info.reg_password_2)}
                                             style={styles.textIpt} textContentType={'username'}
                                             onChangeText={(text) => this._checkPayPwdAgin({
                                                 safe_password2: text
                                             })}/>
                                </div> : null
                            }
                        </div> : null
                    }
                    {this.state.info.reg_question_1 === 1 ?
                        <div>
                            <div style={styles.title}>
                                <div>会员密保信息</div>
                            </div>
                            <div style={styles.iptBox} onClick={() => {
                                this.setState({safeQuestionFliter: true});
                            }}>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <MyInput readOnly={true}
                                             value={this.RegForm.question}
                                             placeholder={'请选择密保问题'}
                                             style={styles.textIpt}/>
                                    <Icon icon={'sjjt'} color={'#333'} size={16}/>
                                </div>
                                {/*<TextInput placeholder="密码提示问题（必填）" style={styles.textIpt} textContentType={'username'}*/}
                                {/*onChangeText={(text) => this._checkForm({question: text})}/>*/}
                                {/*<Picker style={styles.picker}*/}
                                {/*textStyle={{fontSize: 12}}*/}
                                {/*selectedValue={model.safeQuestionOption[0].name}*/}
                                {/*onValueChange={(value) => {*/}
                                {/*this._checkForm({question: value});*/}
                                {/*}}>*/}
                                {/*{model.safeQuestionOption.map((item) => {*/}
                                {/*return (*/}
                                {/*<Picker.Item label={item.name} value={item.name} color="#444"*/}
                                {/*itemStyle={styles.pickerItem}/>*/}
                                {/*)*/}
                                {/*})}*/}
                                {/*</Picker>*/}
                            </div>
                            <div style={styles.iptBox}>
                                <div style={styles.iptLabel}>
                                    <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-answer'}
                                          color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                    密保答案
                                </div>
                                <MyInput placeholder={this._getPlaceholder('请输入密保答案', this.state.info.reg_question_2)}
                                         style={styles.textIpt} textContentType={'username'}
                                         onChangeText={(text) => this._checkAnswer({answer: text})}/>
                            </div>
                        </div> : null
                    }
                    {(this.state.info.reg_code_1 == 1 && this.state.info.reg_code_2 == 1) ?
                        <div style={styles.iptBox}>
                            <div style={styles.iptLabel}>
                                <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-question'}
                                      color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                验证码
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <MyInput placeholder={'验证码（必填）'} maxLength={4}
                                         style={styles.textIpt} textContentType={'username'}
                                         onChangeText={(text) => this._checkCode({
                                             code: text
                                         })}/>
                                {
                                    this.state.codeInfo.array ?
                                        // onPress={()=> this._getCode()} style={styles.btnBox}
                                        <div style={styles.codeBtn} onClick={() => this._getCode()}>
                                            {
                                                this.state.codeInfo.array.map((d, i) => (
                                                    <span style={{padding: 5,}}>{d}</span>
                                                ))
                                            }
                                        </div> : null
                                }
                            </div>
                        </div> : null
                    }
                    {/*{*/}
                    {/*this.state.info.is_send !== 1 ?*/}
                    {/*<div>*/}
                    {/*<div style={styles.title}>*/}
                    {/*<div>短信验证码</div>*/}
                    {/*</div>*/}
                    {/*<div style={styles.iptBox}>*/}
                    {/*<div style={styles.iptLabel}>验证码：</div>*/}
                    {/*<MyInput placeholder="请输入短信验证码" style={styles.textIpt} textContentType={'username'}*/}
                    {/*onChangeText={(text) => {*/}
                    {/*this.RegForm = Object.assign(this.RegForm, {mobile_code: text});*/}
                    {/*}}/>*/}
                    {/*<MsgCode user_account={this.RegForm.user_account}*/}
                    {/*user_mobile={this.RegForm.mobile}/>*/}
                    {/*</div>*/}
                    {/*</div> : null*/}
                    {/*}*/}

                    {/*<div style={styles.regTip}>*/}
                    {/*<div style={styles.regTipText}>{this.state.tips}</div>*/}
                    {/*</div>*/}
                    <div
                        // disabled={!this.state.enableLoginBtn || this.state.showProgress}
                        onClick={() => {
                            this.setState({checked: !this.state.checked});
                            // alert()
                        }}>
                        <div style={styles.tipsCheckBox}>
                            <div style={{...styles.tipsTitle, ...this.state.checked ? styles.tipsChecked : null}}>
                                {this.state.checked ?
                                    <Icon style={{marginTop: -4}} icon={'yes'} color={"#fff"} size={12}/> : null
                                }
                            </div>
                            <div style={styles.tipsContent}>我已届满合法博彩年龄，且同意各项开户条约。</div>
                        </div>
                    </div>
                    <div style={styles.btnContainer}>
                        <MyButton title={'立即注册'} showProgress={this.state.showProgress}
                            // disabled={!this.state.enableLoginBtn || this.state.showProgress}
                                  style={{...styles.btnBox}}
                                  onPress={() => {
                                      this._onRegister();
                                  }}/>
                    </div>
                    <div style={styles.bottomBtnBox}>
                        <div style={styles.bottomBtnItemBox} onClick={() => {
                            PublicUtils.navigate('Login', {nextPage: "Home"});
                        }}>
                            <div style={styles.bottomBtnItemText}>已有账号？去登录~</div>
                        </div>
                    </div>
                </div> :
                <div style={{
                    display: "flex",
                    flex: 1,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    {global.ID == 'yy' && this.state.show ?this._renderNoticeWin():<div></div>}
                    <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Image width={'50%'} uri={regClose}/>
                        <span style={{marginTop: 30, color: "rgba(0,0,0,0.5)"}}>抱歉,注册功能尚未开启</span>
                    </div>
                </div>

        )
    }

    handleClick = () => {
        this.setState({isOpen: true});
    }

    handleCancel = () => {
        this.setState({isOpen: false});
    }

    handleSelect = (time) => {
        this.setState({birthday: this.formatDate(time), isOpen: false});

        this.RegForm = Object.assign(this.RegForm, {birthday: this.formatDate(time)});
        // this._checkForm({birthday: this.formatDate(time)});
    }
    _onText = (text) => {
        console.log(text);
        this._checkUserName({
            user_account: text
        })
        this._validUsername();
    }

    render() {
        const styles = {
            container: {
                backgroundColor: "#f5f7f9",
                padding: 10
            },
            btnContainer: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 40,
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
            },
            btnBox: {
                display: "flex",
                flex: 1,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderRadius: 3
            },
            bottomBtnBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30
            },
            bottomBtnItemBox: {
                marginTop: 20
            },
            bottomBtnItemText: {
                fontSize: 16
            },
            disabledBtn: {
                opacity: 0.6
            },
            btnText: {
                color: "#fff",
                fontSize: 16
            },
            title: {
                backgroundColor: "#f5f5f5",
                padding: 10,
            },
            iptBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                borderBottom: "1px solid #f5f5f5",
            },
            iptLabel: {
                fontSize: 14,
                // width: 60,
                display: "flex",
                color: "rgba(0,0,0,0.7)"
            },
            textIpt: {
                width: "auto",
                display: "flex",
                flex: 1,
            },
            text1Ipt: {
                flex: 1,
                border: "none",
                background: "transparent",
            },
            regTip: {
                marginTop: 10,
                paddingLeft: 10
            },
            regTipText: {
                color: "#ff4222"
            },
            picker: {
                // height: 40,
                // width: 200,
                // fontSize: 14,
                // flex: 1,
                // padding: 0,
                // transform: [
                //     {
                //         scale: 0.87,
                //     },
                // ],
                // marginLeft: -11
            },
            pickerItem: {
                fontSize: 14,
            },
            headerTipsBox: {
                position: "fixed",
                fontSize: 14,
                left: 0,
                top: model.headerHeight,
                padding: "5px 10px",
                backgroundColor: "rgb(229, 40, 66)",
                color: "#fff",
                textAlign: "center",
                right: 0,
                zIndex: 9
            },
        };
        return (
            <Container style={styles.container} title={'注册'}>
                {
                    this.state.tips ?
                        <div style={styles.headerTipsBox}>{this.state.tips}</div> : null
                }
                {!this.state.isReady ? <Loadding/> : this._getRegBody()}
                {/*<div>{JSON.stringify(this.RegForm)}  =========</div>*/}
                <DatePicker
                    theme={'ios'}
                    value={this.state.time}
                    isOpen={this.state.isOpen}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}/>
                <MyPicker rowCount={1}
                          title={'密保问题'}
                          model={model.safeQuestionOption}
                          display={this.state.safeQuestionFliter}
                          onHiden={() => {
                              this.setState({safeQuestionFliter: false})
                          }}
                          onItemPress={(item) => {
                              this.setState({safeQuestionFliter: false});

                              this.RegForm = Object.assign(this.RegForm, {question: item.value[0].value.label});
                              // this._checkForm({question: item.label})
                              // this._changePlatform(item.value);
                              // this.setState({playType: item, showPlayTypeFliter: false, currpage: 1}, () => {
                              //     this._fetchList();
                              // });
                          }}
                />
            </Container>
        )
    }
}
