/**
 * 首页
 * @format
 * @flow
 */
import React from 'react';
import global from "../../global";
import Container from "../../component/container";
import Image from "../../component/image";
import NoneData from "../../component/noneData";
import Storage from "../../services/storage";
import model from "../../services/model";
import PublicUtils from "../../utils/publicUtils";
import Icon from '../../utils/icon'

export default class Favorite extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loaded: false
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = async () => {
        const favorite = (await Storage.retrieveData("favorite") && JSON.parse(await Storage.retrieveData("favorite"))) || [];
        this.setState({list: favorite, loaded: true});
    };

    static navigationOptions = {
        // header: null,
    };

    _delFavorite = (item) => {
        const list = this.state.list;
        for (let i = 0; i < list.length; i++) {
            if (list[i].game_id === item.game_id) {
                list.splice(i, 1);
            }
        }
        this.setState({list: list});
        Storage.storeData({favorite: JSON.stringify(list)});
    }

    /**
     * 进入游戏
     * @private
     */
    _enterGame = async (item) => {
        if (item.game_id === 201) {
            PublicUtils.navigate('GameNavigation');
        } else if (item.game_id === 101) {
            PublicUtils.navigate('HG', {param: item});
        } else {
            if (await PublicUtils._checkLogin()) {
                PublicUtils.navigate("GameTransfer", {param: item});
            } else {
                PublicUtils.navigate('Login', {nextPage: '/'});
            }
        }
    };

    render() {
        const styles = {
            favorContainer: {
                display: "flex",
                flex: 1,
                padding: 5,
                flexDirection: "row",
                flexWrap: "wrap",
            },
            favorItem: {
                display: "flex",
                flexDirection: "column",
                paddingTop: 10,
                margin: 5,
                width: (model.clientWidth - 50) / 3,
                height: (model.clientWidth - 50) / 3,
                // backgroundColor: "red",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                borderRadius: 3,
                overflow: "hidden",
                position: "relative",
            },
            favorTop: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"

            },

            gameImg: {},
            favorBottom: {
                display: "flex",
                height: 30,
                justifyContent: "center",
                alignItems: "center"
            },
            favorCLearContainer: {
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                height: 30,
                width: 30,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5a415",
                right: 0,
                top: 0,
                // borderBottomLeftRadius: 3
            },
            favorCLearText: {
                color: "#fff"
            },
        };
        return (
            <Container title={'我的最爱'} style={{backgroundColor: "#f5f7f9"}}>
                {
                    this.state.list.length ?
                        <div style={styles.favorContainer}>
                            {
                                this.state.list.map((item) => {
                                    let icon = global.RES_URL + "/new/mobile_game_img/" + item.icon + ".png";
                                    return (
                                        <div type={1}
                                             onClick={() => {
                                                 this._enterGame(item);
                                             }}
                                        >
                                            <div style={styles.favorItem}>
                                                <div style={styles.favorTop}>
                                                    <Image hasPlaceholder={true}
                                                           placeholderSize={{
                                                               width: ((model.clientWidth - 50) / 3) - 30,
                                                               height: ((model.clientWidth - 50) / 3) - 30
                                                           }}
                                                           uri={icon}
                                                           style={styles.gameImg}
                                                           width={((model.clientWidth - 50) / 3) - 30}
                                                           height={((model.clientWidth - 50) / 3) - 40}
                                                    />
                                                    {/*<Image style={styles.gameImg}*/}
                                                    {/*       placeholderSize={{*/}
                                                    {/*           width: ((model.clientWidth - 40) / 3) - 20,*/}
                                                    {/*           // height: ((Dimensions.get('window').width - 40) / 3) - 20*/}
                                                    {/*       }}*/}
                                                    {/*       resizeMode='contain'*/}
                                                    {/*       uri={icon}*/}
                                                    {/*       width={((model.clientWidth - 40) / 3) - 30}*/}
                                                    {/*       height={((model.clientWidth - 40) / 3) - 40}*/}
                                                    {/*/>*/}
                                                </div>
                                                <div style={styles.favorBottom}>
                                                    <div style={styles.gameItemText}>{item.name}</div>
                                                </div>
                                                <div style={styles.favorCLearContainer} type={1}
                                                     onClick={(event) => {
                                                         this._delFavorite(item);
                                                         event.stopPropagation();
                                                     }}
                                                >
                                                    {/*<MyText style={styles.favorCLearText}>删除</MyText>*/}
                                                    <Icon icon={"close"}
                                                          color={"#fff"}
                                                          size={16}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : null
                }
                {
                    !this.state.list.length && this.state.loaded ?
                        <NoneData msg={<span>您还未添加任何收藏！<br/>长按图标即可收藏我的最爱</span>}/>
                        : null
                }
            </Container>
        )
    }
}
