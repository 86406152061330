const StorageManager = {
    /**
     * 保存数据
     * @param data
     * @returns {Promise<void>}
     */
    storeData: (data: Object) => {
        try {
            for (let key in data) {
                localStorage.setItem(key, data[key]);
            }
        } catch (error) {
            // Error saving data
        }
    },
    /**
     * 获取数据
     * @returns {Promise<void>}
     */
    retrieveData: (key) => {
        try {
            // debugger
            const value = localStorage.getItem(key);
            // alert(value);
            if (value !== null) {
                return value;
            }
        } catch (error) {
            // Error retrieving data
        }
    },
    /**
     * 清除持久数据
     * @returns {Promise<void>}
     */
    clearData: () => {
        try {
            // localStorage.clear();
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("status");
            localStorage.removeItem("message");
        } catch (error) {
            // Error retrieving data
        }
    }
};
export default StorageManager;