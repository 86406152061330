/**
 * 平台卡片
 */

import React from 'react';
import PropTypes from 'prop-types';
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Image from "./image";
import Icon from '../utils/icon'
import GameCard from "./gameCard";
import global from "../global";
import style from '../utils/config.scss'

class HotAlotGameList extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            index: 1
        }
    }

    static propTypes = {
        hotCasino: PropTypes.array
    };


    render() {
        const styles = {
            hotBox: {
                backgroundColor: "#f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                // color:"#fff"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                paddingLeft: 10,
                flexDirection: "row",
                alignItems: "center"
            },
            hotBigTitle: {
                fontSize: 17
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            hotAlotGameListWrapperBox: {
                width: "100%",
                overflow: "auto",
                backgroundColor: "#fff"
            },
            hotAlotGameListBox: {
                display: "flex",
            },
            hotAlotPlatformGameInnerBox: {
                backgroundColor: "#fff",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 3
            },
            hotAlotPlatformGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                margin: "0 10px 0",
                fontSize: 14,
                textAlign: "center",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0 0",
                fontSize: 14,
                textAlign: "center",
            },
            hotAlotGameActiveBox: {
                display: "flex",
                justifyContent: "center"
            },
            hotAlotGameActive: {
                width: 0,
                height: 0,
                border: "18px solid transparent",
                borderBottom: "18px solid #fff",
            }
        };
        return this.props.hotCasino ?
            <div style={{...styles.hotBox}}>
                <div style={{...styles.hotTitle, ...{justifyContent: "center"}}}>
                    <div style={styles.hotBigTitle}>热门电游推荐</div>
                    {/*<div style={styles.point}>·</div>*/}
                    {/*<div style={styles.hotSubTitle}>为您推荐最火的游戏</div>*/}
                </div>
                <div style={styles.hotGameBody}>
                    <div style={styles.hotInnerBody}>
                        {
                            this.props.hotCasino.map((item, i) => {
                                // console.log(item.icon);
                                return i !== 0 && (
                                    <div key={i} style={styles.hotAlotPlatformGameBox} onClick={() => {
                                        this.setState({index: i});
                                    }
                                    }>
                                        <div style={styles.hotAlotPlatformGameInnerBox}>
                                            <div>
                                                <Icon
                                                    icon={item.icon} color={'#000'} size={45}/>
                                            </div>
                                            <div>{item.name}</div>
                                        </div>
                                        {
                                            i === this.state.index ?
                                                <div style={styles.hotAlotGameActiveBox}>
                                                    <div style={styles.hotAlotGameActive}/>
                                                </div> : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={styles.hotAlotGameListWrapperBox}>
                    <div style={styles.hotAlotGameListBox}>
                        {
                            this.props.hotCasino[this.state.index].hotlist.map((item,key) => {
                                const gameImg = global.RES_URL + this.props.hotCasino[this.state.index].game_list_img_path + item.Button_Image_Name;
                                item.type = this.props.hotCasino[this.state.index].type;
                                item.id = this.props.hotCasino[this.state.index].game_api;
                                item.game_id = this.props.hotCasino[this.state.index].game_id;
                                return (
                                    <div key={key} style={styles.hotAlotGameBox}>
                                        <GameCard width={'100'} icon={gameImg} name={item.CN_Name} obj={item}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div> : null;
    }
}

export default HotAlotGameList
