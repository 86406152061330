//活动

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import PublicUtils from '../utils/publicUtils'
import Container from '../component/container'
import Image from '../component/image'
import Loadding from '../component/loadding'
import NoneData from '../component/noneData'
import Http from "../services/http";
import model from "../services/model";
import Icon from '../utils/icon'
import style from '../utils/config.scss'
import FloatBtn from '../component/floatBtn'
import global from '../global';

class Active extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            showProgress: false
        };
    }

    componentDidMount() {
        this._fetchData();
    }

    componentWillUnmount() {
    }

    /**
     * 获取数据
     * @private
     */
    _fetchData = () => {
        let parm = {};
        this.setState({activity: [], showProgress: true});
        Http.request("activity", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                this.props.navigation.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    this.setState({activity: res.data.list});
                }
            }
        });
    };
    /**
     * 活动详情
     * @private
     */
    _showActiveDetail = (item) => {
        const parma = {id: item.id, title: item.is_title};
        console.log(parma)
        PublicUtils.navigate("ActiveDetail", parma);
    };

    render() {

        const styles = {
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: global.THEME === "default" ? 70 : 10,
                position: "absolute",
                paddingBottom: 55,
                background: global.ID == "av" ? "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")" : null,
            },
            hotActiveImg: {
                marginBottom: 7,
                borderRadius: 3,
                overflow: "hidden",
                // boxShadow: "0 0 5px 2px rgba(0,0,0,0.3)"
                // width: (Dimensions.get('window').width - 20),
                // height: 55
            },
            hotActiveImg1: {
                marginBottom: 3,
                borderRadius: 3,
                overflow: "hidden",
                // boxShadow: "0 0 5px 2px rgba(0,0,0,0.3)"
            },
            headerbg: {
                position: "fixed",
                top: 0,
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 245,
                borderRadius: "0 0 100% 100%",
                // borderBottomRightRadius: 100,
                // borderBottomLeftRadius: 100,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
        };
        return (
            <Container tabIndex={3} showBottomTab={global.THEME === "default"} title={'活动大厅'}
                       showHeader={global.THEME !== "default"}
                       style={{backgroundColor: "#fff"}}>
                <div style={{
                    background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                    display: "flex",
                    flex: 1
                }}>
                    <div style={styles.headerbg}/>
                    {
                        global.THEME === "default" ?
                            <div style={{
                                left: -model.clientWidth * 0.1,
                                right: -model.clientWidth * 0.1,
                                height: "60px",
                                lineHeight: "60px",
                                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                                fontSize: 20,
                                color: "#fff",
                                position: "fixed",
                                zIndex: 1,
                                textAlign: "center"
                            }} className={'headPadding'}>活动大厅
                            </div>
                            : null
                    }

                    <div style={styles.hotActiveBody}
                         className={'bodyPadding'}>
                        {this.state.activity.map((item, key) => {
                            return (
                                <div key={key} onClick={() => {
                                    this._showActiveDetail(item)
                                }}>
                                    <Image hasPlaceholder={true}
                                           placeholderSize={{
                                               width: model.clientWidth - 20,
                                               height: 60
                                           }} width={model.clientWidth - 20}
                                           uri={item.is_mobile_pic || item.is_title_pic}
                                           style={global.ID == "aw" ? styles.hotActiveImg1 : styles.hotActiveImg}/>
                                </div>
                            );
                        })}

                    </div>
                    {
                        this.state.showProgress ? <Loadding/> : null
                    }
                    {
                        !this.state.activity.length && !this.state.showProgress ? <NoneData/> : null
                    }
                    <FloatBtn/>
                </div>
            </Container>
        )
    }
}

export default withRouter(Active);
