/**
 * 体育投注记录详情
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import PublicUtils from "../utils/publicUtils";
import history from "../services/history";
import copy from "copy-to-clipboard/index";

export default class SportBetRecordDetail extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            matchInfo: PublicUtils.getState(this.props.location.pathname).matchInfo,
            betType: PublicUtils.getState(this.props.location.pathname).betType,
        }
    }

    static navigationOptions = ({navigation}) => {
        return {}
    };
    /**
     * 复制内容到剪贴板
     * @param text
     * @private
     */
    _copyText = async (text) => {
        // Clipboard.setString(text);
        // await Clipboard.getString();
        copy(text);
        PublicUtils.showToast("已复制到剪贴板");
    };

    render() {
        const styles = {
            betDetailBox: {
                fontSize: 14
            },
            betDetailHeaderBox: {
                paddingTop: 15,
                paddingBottom: 15,
                borderBottom: "10px solid #f5f7f9"
                // backgroundColor: "#f5f7f9"
            },
            betDetailHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 35,
                paddingLeft: 10,
                paddingRight: 10,
            },
            matchName: {
                fontSize: 16,
                textAlign: "center",
                justifyContent: "center",
                color: "#000",
                flex: 1,
                display: "flex",
                width: 0,
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            matchTeam: {
                flex: 1,
                display: "flex",
                width: 0,
                justifyContent: "center",
                fontSize: 14,
                color: "#000"
            },
            matchTeamVs: {
                backgroundColor: "rgba(245, 72, 68, 0.93)",
                color: "#fff",
                fontSize: 16,
                height: "100%",
                padding: "0 5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            betInfo: {
                marginLeft: 2,
                marginRight: 2
            },
            color1: {
                color: "#128624"
            },
            color2: {
                color: "#ff5a00"
            },
            color3: {
                color: "rgba(245, 72, 68, 0.93)"
            },
            bf: {
                fontSize: 18,
            },
            betAmount: {
                fontSize: 26,
                color: "#000"
            },
            betStatus: {
                fontSize: 14,
                color: "rgb(255, 118, 0)"
            },
            row: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                borderBottom: '1px solid #f5f7f9',
                height: 40,
                alignItems: "center"
            },
            leftCol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)"
            },
            rightCol: {
                fontSize: 14,
                color: "#333",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative"
            },
            accountInfoItemCopyBtn: {
                position: "absolute",
                right: 0,
                top: -43,
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                borderRadius: 3,
                backgroundColor: "#333",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            accountInfoItemCopyBtnText: {
                color: "#fff",
                fontSize: 12,
                margin: "0 7px"
            },
            copyHeader: {
                position: "absolute",
                left: 8,
                top: "100%",
                width: 0,
                height: 0,
                borderColor: "transparent #333",
                borderWidth: "0 0 12px 15px",
                borderStyle: "solid",
            },
            cgTitle: {
                display: "flex",
                height: 40,
                lineHeight: 40,
                paddingLeft: 10,
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#f5f7f9"
            },
            cgBox: {
                // backgroundColor: "#fffbf2",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                paddingBottom: 10,
                borderBottom: "1px solid #f5f5f5",
            },
            cgRow: {
                height: 25,
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
            },
            cgRowLeftCol: {
                flexDirection: "row",

                flex: 1,
                display: "flex",
                width: 0,
            },
            cgText: {
                marginRight: 5,
                textAlign: "left",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            betMatchNameText: {
                flex: 1,
                textAlign: "center",
                marginRight: 0
            },
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            },
            cgVSText: {
                padding: "0 5px",
                color: "#fff",
                fontSize: 16,
                backgroundColor: "rgba(245, 72, 68, 0.93)"
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            betContentHeader: {
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 3,
                margin: "5px 0",
                padding: "0 5px"
            },
            win: {backgroundColor: "#ff353b", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
            lose: {backgroundColor: "#1dc315", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
            notopen: {
                backgroundColor: "#1dc315",
                color: "#fff",
                padding: "3px 5px",
                borderRadius: 3,
                fontSize: 16
            },
            resultText: {color: "#fff", fontSize: 16},
            cgDetailInfoRow: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                paddingTop: 5,
                paddingBottom: 5
            },
            cgDetailInfoCol: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: 'center',
                alignItems: "center",
                width: 0
            },
            bet_content: {
                color: "red",
                marginLeft: 5,
                marginRight: 5
            }
        };
        return (
            <Container title={'体育投注记录详情'} style={{backgroundColor: "#fff"}}>
                {
                    this.state.betType === "全部" && (
                        <div style={styles.betDetailBox}>
                            <div style={styles.betDetailHeaderBox}>
                                <div style={styles.betDetailHeader}>
                                    <div
                                        style={styles.matchName}>
                                        <div style={styles.matchNameText}>{this.state.matchInfo.match_name}</div>
                                    </div>
                                </div>
                                {
                                    this.state.matchInfo.bet_type === 0 && (
                                        <div style={{
                                            ...styles.betDetailHeader, ...{
                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                margin: "10px 5px",
                                                borderRadius: 3
                                            }
                                        }}>
                                            <div
                                                style={{...styles.matchTeam, ...{flex: 1}}}>
                                                <div
                                                    style={styles.matchNameText}>{this.state.matchInfo.match_hname}</div>
                                            </div>
                                            <div style={styles.matchTeamVs}>VS</div>
                                            <div style={{
                                                ...styles.matchTeam, ...{
                                                    flex: 1,
                                                    textAlign: 'right'
                                                }
                                            }}>
                                                <div
                                                    style={styles.matchNameText}>{this.state.matchInfo.match_aname}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.matchInfo.bet_type === 1 && (
                                        <div style={styles.betDetailHeader}>
                                            <div style={{...styles.betInfo, ...styles.color1}}>[滚球]</div>
                                            <div
                                                style={{...styles.betInfo, ...styles.color2}}>[{this.state.matchInfo.live_time}]
                                            </div>
                                            <div
                                                style={{...styles.betInfo, ...styles.matchTeam}}>
                                                <div
                                                    style={styles.matchNameText}>{this.state.matchInfo.match_hname}</div>
                                            </div>
                                            <div
                                                style={{...styles.betInfo, ...styles.color3, ...styles.bf}}>{this.state.matchInfo.live_hbf}</div>
                                            <div style={{...styles.betInfo, ...styles.color4}}>-</div>
                                            <div
                                                style={{...styles.betInfo, ...styles.color3, ...styles.bf}}>{this.state.matchInfo.live_abf}</div>
                                            <div
                                                style={{...styles.betInfo, ...styles.matchTeam}}>
                                                <div
                                                    style={styles.matchNameText}>{this.state.matchInfo.match_aname}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div style={styles.betDetailHeader}>
                                    <div
                                        style={{...styles.betAmount, ...this.state.matchInfo.js_win !== "未结算" ? Number(this.state.matchInfo.js_win) >= 0 ? styles.win : styles.lose : styles.notopen}}>{this.state.matchInfo.js_win}</div>
                                </div>
                                {
                                    this.state.matchInfo.bet_type === 1 && (
                                        <div style={styles.betDetailHeader}>
                                            <div
                                                style={styles.betStatus}>{this.state.matchInfo.live_ok === 0 ? '未确认' : "已审核"}</div>
                                        </div>
                                    )
                                }
                            </div>

                            <div style={styles.row}>
                                <div style={styles.leftCol}>注单号</div>
                                <div style={styles.rightCol}>
                                    {this.state.matchInfo.order_number}

                                    <div onClick={() => {
                                        this._copyText(this.state.matchInfo.order_number)
                                    }} style={styles.accountInfoItemCopyBtn}>
                                        {/*<Icon icon={'ziliao'} size={12} color={'#fff'}/>*/}
                                        <div style={styles.accountInfoItemCopyBtnText}>点击复制</div>
                                        <div style={styles.copyHeader}/>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>球类</div>
                                <div style={styles.rightCol}>{this.state.matchInfo.type_name}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>比赛时间</div>
                                <div style={styles.rightCol}>{this.state.matchInfo.match_time}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>玩法</div>
                                <div style={styles.rightCol}>{this.state.matchInfo.bet_name}</div>
                            </div>
                            {
                                this.state.matchInfo.bet_pk ? (

                                    <div style={styles.row}>
                                        <div style={styles.leftCol}>盘口</div>
                                        <div style={styles.rightCol}>{this.state.matchInfo.bet_pk}</div>
                                    </div>
                                ) : null
                            }
                            <div style={styles.row}>
                                <div style={styles.leftCol}>赔率</div>
                                <div style={styles.rightCol}>{this.state.matchInfo.bet_odds}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>下注时间</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.timestampFormat(this.state.matchInfo.bet_time)}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>下注内容</div>
                                <div style={styles.rightCol}>{this.state.matchInfo.bet_content}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>投注金额</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.subStringNum(this.state.matchInfo.bet_money)}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>可赢金额</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.subStringNum(this.state.matchInfo.bet_win)}</div>
                            </div>
                            {
                                this.state.matchInfo.is_status ? (

                                    <div style={styles.row}>
                                        <div style={styles.leftCol}>状态</div>
                                        <div style={styles.rightCol}>{this.state.matchInfo.is_status}</div>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.matchInfo.js_bf ? (

                                    <div style={styles.row}>
                                        <div style={styles.leftCol}>比分</div>
                                        <div style={styles.rightCol}>{this.state.matchInfo.js_bf}</div>
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                }
                {
                    this.state.betType === "过关" && (
                        <div style={styles.betDetailBox}>
                            <div style={styles.betDetailHeaderBox}>
                                <div style={styles.betDetailHeader}>
                                    <div
                                        style={{...styles.matchName, ...{fontSize: 24}}}>
                                        <div
                                            style={styles.matchNameText}>{this.state.matchInfo.bet_count} 串
                                            1
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.betDetailHeader}>
                                    <div
                                        style={{...styles.betAmount, ...this.state.matchInfo.js_win !== "未结算" ? Number(this.state.matchInfo.js_win) >= 0 ? styles.win : styles.lose : styles.notopen}}>{this.state.matchInfo.js_win}</div>
                                </div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>注单号</div>
                                <div style={styles.rightCol}>
                                    {this.state.matchInfo.order_number}
                                    <div onClick={() => {
                                        this._copyText(this.state.matchInfo.order_number)
                                    }} style={styles.accountInfoItemCopyBtn}>
                                        {/*<Icon icon={'ziliao'} size={12} color={'#fff'}/>*/}
                                        <div style={styles.accountInfoItemCopyBtnText}>点击复制</div>
                                        <div style={styles.copyHeader}/>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>下注时间</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.timestampFormat(this.state.matchInfo.bet_time)}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>赔率</div>
                                <div style={styles.rightCol}>{this.state.matchInfo.bet_odds}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>投注金额</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.subStringNum(this.state.matchInfo.bet_money)}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.leftCol}>可赢金额</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.subStringNum(this.state.matchInfo.bet_win)}</div>
                            </div>
                            {
                                this.state.matchInfo.is_status != 0 ?
                                    <div style={styles.row}>
                                        <div style={styles.leftCol}>状态</div>
                                        <div style={styles.rightCol}>{this.state.matchInfo.is_status}</div>
                                    </div> : null
                            }
                            <div>
                                <div style={styles.cgTitle}>过关列表</div>
                                {
                                    this.state.matchInfo.order && this.state.matchInfo.order.map((item, key) => {
                                        return (
                                            <div key={key} style={styles.cgBox}>
                                                <div style={{...styles.cgRow, ...{justifyContent: "space-between"}}}>
                                                    <div style={styles.cgRowLeftCol}>
                                                        <div
                                                            style={{...styles.cgText, ...{color: "rgba(0,0,0,0.5)"}}}>{item.match_name}</div>
                                                    </div>
                                                    <div style={styles.cgRowRightCol}>
                                                        <div
                                                            style={{...styles.cgText, ...{color: "rgba(0,0,0,0.5)"}}}>{item.match_time}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{...styles.cgRow, ...{justifyContent: 'space-between'}, ...styles.betContentHeader}}>
                                                    <div style={styles.cgRowLeftCol}>
                                                        <div
                                                            style={{...styles.cgText, ...styles.betMatchNameText, ...{textAlign: "left"}}}>{item.match_hname}</div>
                                                        <div
                                                            style={{...styles.cgVSText}}>VS
                                                        </div>
                                                        <div
                                                            style={{...styles.cgText, ...styles.betMatchNameText, ...{textAlign: "right"}}}>{item.match_aname}</div>
                                                    </div>
                                                </div>
                                                {/*<div style={styles.cgRow}>*/}
                                                {/*<div*/}
                                                {/*style={{...styles.cgText, ...{color: "#26da62"}}}>{item.bet_name}</div>*/}
                                                {/*<div*/}
                                                {/*style={{...styles.cgText}}>{item.bet_content}</div>*/}
                                                {/*<div*/}
                                                {/*style={{...styles.cgText, ...{color: "#ffa400"}}}>{item.bet_pk}</div>*/}
                                                {/*<div*/}
                                                {/*style={{...styles.cgText, ...{color: "#da433c"}}}>@ {item.bet_odds}</div>*/}
                                                {/*</div>*/}

                                                <div
                                                    style={{
                                                        ...styles.cgDetailInfoRow, ...{
                                                            borderRadius: 3
                                                        }
                                                    }}>
                                                    <div
                                                        style={{...styles.cgDetailInfoCol, ...{color: "rgba(0,0,0,0.5)"}}}>
                                                        <div>玩法</div>
                                                    </div>
                                                    <div
                                                        style={{...styles.cgDetailInfoCol, ...{color: "rgba(0,0,0,0.5)"}}}>
                                                        <div>投注/盘口</div>
                                                    </div>
                                                    <div
                                                        style={{...styles.cgDetailInfoCol, ...{color: "rgba(0,0,0,0.5)"}}}>
                                                        <div>赔率/比分</div>
                                                    </div>
                                                    <div
                                                        style={{...styles.cgDetailInfoCol, ...{color: "rgba(0,0,0,0.5)"}}}>
                                                        <div>输赢</div>
                                                    </div>
                                                </div>
                                                <div style={styles.cgDetailInfoRow}>
                                                    <div
                                                        style={styles.cgDetailInfoCol}>
                                                        <div>{item.bet_name}</div>
                                                    </div>
                                                    <div
                                                        style={styles.cgDetailInfoCol}>
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                overflow: 'hidden',
                                                                width: "100%",
                                                                textAlign: "center",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}>{item.bet_content}</div>
                                                        <div>{item.bet_pk}</div>
                                                    </div>
                                                    <div style={styles.cgDetailInfoCol}>
                                                        <div>@{item.bet_odds}</div>
                                                        <div>{item.js_bf}</div>
                                                    </div>
                                                    <div style={styles.cgDetailInfoCol}>
                                                        <div
                                                            style={{
                                                                ...item.js_win !== "未结算" ? Number(item.js_win) >= 0 ? styles.win : styles.lose : styles.notopen, ...{
                                                                }
                                                            }}>
                                                            <div
                                                                style={{...styles.resultText, ...{fontSize: 14}}}>{item.js_win}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </Container>
        )
    }
}