/**
 * 底部导航图标
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Navigate from "../../navigate";
import global from "../../../global";
import PublicUtils from "../../../utils/publicUtils";
import Storage from "../../../services/storage";
import style from "../../../utils/config.scss";
import model from "../../../services/model";

export default class Lines extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        let lines = model.lines;
        this.state = {
            lines: lines,
            lineName: ""
        }
    }

    static propTypes = {
        // style: Text.propTypes.style,
        hasOpen: PropTypes.bool,
    };
    static defaultProps = {
        hasOpen: false
    };

    getLine = async () => {
        const line = await Storage.retrieveData("line") && JSON.parse(await Storage.retrieveData("line"));
        const url = line ? line.name : "主线路";
        return url;
    }
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = async () => {
        console.log("-----------开始-------------")

        this.setState({lineName: await this.getLine()});
        if (this.timer) {
            window.clearInterval(this.timer);
        }
        this.timer = window.setInterval(() => {
            this.checkPing();
        }, 5000)
        // this.checkPing();

    };

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
        //StatusBar.setBackgroundColor("red");
        if (this.timer) {
            window.clearInterval(this.timer);
        }
    };


    checkPing = () => {

        for (let i = 0; i < this.state.lines.length; i++) {
            this.ping(this.state.lines[i], (obj) => {
                this.setDelayTime(obj);
            })
        }
    }
    // ping = (line, callback) => {
    //     let img = new Image();
    //     const starttime = new Date().getTime();
    //     img.src = line.url + "?t=" + starttime;
    //     let delay = 0;
    //     console.log("------------------------");
    //     img.onload = () => {
    //         console.log("-----------加载成功");
    //         const endtime = new Date().getTime();
    //         const obj = {
    //             url: line.url,
    //             delay: endtime - starttime
    //         };
    //         callback(obj);
    //         // const obj = {
    //         //     url: ip,
    //         //     delay: delay
    //         // };
    //         // callback(obj);
    //     };
    //     img.onerror = () => {
    //         console.log("-----------加载失败");
    //         const obj = {
    //             url: line.url,
    //             delay: 999
    //         };
    //         callback(obj);
    //         // const obj = {
    //         //     url: ip,
    //         //     delay: delay
    //         // };
    //         // callback(obj);
    //     };
    //
    // }

    ping = (line, callback) => {


        let delay = 0;
        // let timer = window.setInterval(() => {
        //     if (!flag) {
        //         delay++;
        //     } else {
        //         window.clearInterval(timer);
        //     }
        // }, 1);
        const starttime = new Date().getTime();
        let url = line.url;
        // console.log(url);
        if (line.type === 1) {
            url = line.url + global.ID + "/notice";
        } else {
            url = line.url + "mobile/notice";
        }
        fetch(url)
            .then((response) => {

                const endtime = new Date().getTime();
                const obj = {
                    url: line.url,
                    delay: endtime - starttime
                };
                callback(obj);
                // if (response.status === 200) {
                //     console.log('success');
                // } else {
                //     console.log('error');
                // }
            })
            .catch((error) => {
                console.log('network error: ' + error);
                const obj = {
                    url: line.url,
                    delay: 999
                };
                setTimeout(()=>{
                    callback(obj);
                }, 1000)
            })
    }

    setDelayTime = async (obj) => {
        let lines = this.state.lines;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].url === obj.url) {
                lines[i].delay = obj.delay !== 999 ? Math.round(obj.delay / 8) : obj.delay;
                let status = 0;
                if (lines[i].delay < 200) {
                    status = 1;
                } else if (lines[i].delay >= 200 && lines[i].delay < 400) {
                    status = 2;
                } else if (lines[i].delay >= 400 && lines[i].delay < 999) {
                    status = 3;
                } else {
                    status = 4;
                }
                lines[i].status = status;
            }
        }
        // console.log(lines);
        this.setState({lines: lines, lineName: await this.getLine()});
    }

    render() {

        const styles = {
            lineBox: {
                display: "flex",
                height: 40,
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                borderTop: "1px solid #f5f7f9"
                // borderTopWidth: 1,
                // borderColor: "#f5f7f9"
            },
            lineLeft: {
                display: "flex",
                width: 25,
                // backgroundColor: "red",
                marginRight: 8,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            lineStatus: {
                width: 8,
                height: 8,
                borderRadius: 8,
            },
            lineStatus0: {
                backgroundColor: "#666"
            },
            lineStatus1: {
                backgroundColor: "#04b60d"
            },
            lineStatus2: {
                backgroundColor: "#e1a718"
            },
            lineStatus3: {
                backgroundColor: "#d63e24"
            },
            lineStatus4: {
                backgroundColor: "#a8a8a8"
            },
            color0: {
                color: "#666"
            },
            color1: {
                color: "#04b60d"
            },
            color2: {
                color: "#e1a718"
            },
            color3: {
                color: "#d63e24"
            },
            color4: {
                color: "#a8a8a8"
            },
            lineMid: {
                display: "flex",
                flex: 1,
                // backgroundColor: "green",
                flexDirection: "row",
                justifyContent: "flex-start"
            },
            lineRight: {},
            rightBtn: {
                display: "flex",
                height: 25,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 4
            },
            rightBtnDisable: {
                opacity: 0.4
            },
            rightBtnText: {
                color: "#fff",
                fontSize: 12
            },
            delayBox: {
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: 12,
                color: "#999"
            }
        };




        return (

            <div style={{backgroundColor: "#fff"}}>
                <Navigate note={this.state.lineName} headerIcon={'zhuanhuan'} headerIconColor={'#0d97ff'}
                          headerIconSize={18}
                          title={'线路选择'}
                          onPress={() => {
                              // this._memberBonus();
                          }}/>
                {/*<MenuItem note={this.state.lineName} footerIcon={null} headerIcon={'zhuanhuan'}*/}
                {/*          headerIconColor={'#0d97ff'}*/}
                {/*          headerIconSize={18}*/}
                {/*          title={'线路选择'}*/}
                {/*          onPress={() => {*/}
                {/*          }}/>*/}
                {
                    this.state.lines.map((item, index) => {
                        return (
                            <div style={styles.lineBox}>
                                <div style={styles.lineMid}>
                                    <div style={styles.lineLeft}>
                                        <div
                                            style={{...styles.lineStatus, ...styles["lineStatus" + item.status]}}/>
                                    </div>
                                    <div>{item.name}</div>
                                    <div
                                        style={{...styles.delayBox, ...styles["color" + item.status]}}>{item.delay}</div>
                                </div>
                                <div style={styles.lineRight}>
                                    <div onClick={() => {
                                        if (item.name !== this.state.lineName) {
                                            global.APP_API_URL = item;

                                            Storage.storeData({line: JSON.stringify(item)});

                                            this.setState({lineName: item.name});
                                            PublicUtils.showToast("线路已切换，2秒后刷新页面");
                                            setTimeout(()=>{
                                                window.location.reload();
                                            }, 2000)
                                            // window.setTimeout(() => {
                                            //     // RNRestart.Restart();
                                            //     window.location.reload();
                                            // }, 2000)
                                        } else {
                                            PublicUtils.showToast("当前线路为" + item.name);
                                        }
                                    }}>
                                        <div
                                            style={styles.rightBtn}>
                                            <div style={styles.rightBtnText}>应用</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
