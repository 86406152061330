/**
 * 倒计时
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../../../utils/config.scss'

export default class CountDown extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            number: this.defaultNumber,
            isReady: this.props.isReady
        };
    }

    defaultNumber = 0;
    static propTypes = {
        onPress: PropTypes.func,
        isReady: PropTypes.bool,
        playId: PropTypes.number,
        countDownComplete: PropTypes.func
    };
    static defaultProps = {
        isReady: false
    };
    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
        window.clearInterval(this.timer);
    };
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.playId !== this.state.playId) {
            this.defaultNumber = nextProps.playId === 2 ? 30 : 90;
            this.setState({
                number: this.defaultNumber,
                playId: this.props.playId,
                isReady: nextProps.isReady
            }, () => {
                this._countdown();
            });
        }
    };
    /**
     * 倒计时
     * @private
     */
    _countdown = () => {
        window.clearInterval(this.timer);
        this.timer = window.setInterval(() => {
            if (this.state.isReady) {
                if (this.state.number > 0) {
                    this.setState({number: this.state.number - 1});
                } else {
                    this.setState({number: this.defaultNumber}, () => {
                        this.props.countDownComplete();
                    });
                }
            }
        }, 1000);

    };
    /**
     * 点击
     * @private
     */
    _onPress = () => {
        this.setState({number: this.defaultNumber});
        this.props.onPress && this.props.onPress();
    };

    render() {
        const styles = {

            openAllBtn: {
                marginRight: 5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: 14
            },
        };
        return (
            <div style={styles.openAllBtn} onClick={() => {
                this._onPress();
            }}>
                <div style={{fontSize: 16, color: "#ff313b"}}>{this.state.number}'</div>
            </div>
            // <Theme.View style={styles.floatBtnBox}>
            //     <Text style={styles.floatBtnText}>{this.state.number}</Text>
            // </Theme.View>
        )
    }
}