//按钮

import React, {Component} from 'react';
import App from "../App";
import PropTypes from 'prop-types';

class MyInput extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        readOnly: PropTypes.bool,
        placeholder: PropTypes.string,
        onChangeText: PropTypes.func,
        onBlur: PropTypes.func,
        type: PropTypes.string,
        style: PropTypes.object,
        value: PropTypes.string,
        onPress: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        _ref: PropTypes.func,
        canEmpty: PropTypes.bool,
    };
    static defaultProps = {
        disabled: false,
        readOnly: false,
        type: "text",
        canEmpty: false
    };
    componentWillReceiveProps = (nextProps) => {
        // if( nextProps.value ){
        //     const _value =  nextProps.value.toString().replace(/\s+/g, "");
        //     this.setState({value:_value+"11111"});
        // }
        if (nextProps.value || this.props.canEmpty) {
            let _value = nextProps.value ? nextProps.value.toString() : "";
            if (_value.indexOf(" ") > 0) {
                _value = _value.replace(/\s+/g, '');
            }
            this.setState({value: _value});
        }
        // nextProps.value && this.setState({value: nextProps.value.toString().replace(/\s+/g, "")});
    };

    handleChange(event) {
        if (this.props.onText) {
            this.props.onText(event.target.value)
            let _value = event.target.value.toString();
            if (_value.indexOf(" ") > 0) {
                _value = _value.replace(/\s+/g, '');
            }
            this.setState({value: _value});
        }
        // if( !this.props.readOnly ){
        //     this.props.onChangeText && this.props.onChangeText(event.target.value.toString().replace(/\s+/g, ""));
        // }
        // this.setState({value: event.target.value.toString().replace(/\s+/g, "")});
        if (!this.props.readOnly) {
            this.props.onChangeText && this.props.onChangeText(event.target.value.toString().replace(/\s+/g, ""));
            let _value = event.target.value.toString()
            if (_value.indexOf(" ") > 0) {
                _value = _value.replace(/\s+/g, '');
            }
            this.setState({value: _value});
            // this.setState({value: event.target.value.toString().rdeplace(/\s+/g, "")});
        }
    }

    render() {
        const styles = {
            textIpt: {
                width: "100%",
                height: "100%",
                border: "none",
                background: "transparent",
                fontSize: 14
            }
        };
        return (
            <input
                spellcheck={false}
                ref={(input) => {
                    this.props._ref && this.props._ref(input);
                }}
                onClick={() => {
                    this.props.onPress && this.props.onPress();
                }}
                value={this.state.value || ''}
                readOnly={this.props.readOnly}
                onChange={this.handleChange}
                type={this.props.type}
                placeholder={this.props.placeholder}
                maxLength={this.props.maxLength}
                style={{...styles.textIpt, ...this.props.style}}
                // onKeyDown={()=>{
                //      this.props.onKeyDown && this.props.onKeyDown(this.state.value)
                // }}
                onBlur={() => {
                    if (!this.props.readOnly) {
                        this.props.onBlur && this.props.onBlur(this.state.value && this.state.value.replace(/\s+/g, ""))
                    }
                }}
                onKeyUp={(event) => {
                    if (!this.props.readOnly) {
                        this.props.onKeyUp && this.props.onKeyUp(this.state.value && this.state.value.replace(/\s+/g, ""));
                        this.props.onChangeText && this.props.onChangeText(this.state.value && this.state.value.replace(/\s+/g, ""));
                    }
                }}
            />
        )
    }
}

export default MyInput;
