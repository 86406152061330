/**
 * 按钮
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FavoriteIcon from "./favoriteIcon";
import GameBodyImg from "./gameBodyImg";
import model from "../../../services/model";

class GameBody extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.timeOutEvent = null;
    }


    static propTypes = {
        onPress: PropTypes.func,
        onLongPress: PropTypes.func,
        icon: PropTypes.string,
        name: PropTypes.string,
        favorite: PropTypes.bool
    };
    static defaultProps = {};

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        // alert(1);

    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (nextProps.playName === "生肖连") {
        //     alert(nextState.subPlayName + "--" + this.state.subPlayName);
        // }
        return nextProps.favorite !== this.props.favorite ||
            nextProps.icon !== this.props.icon ||
            nextProps.name !== this.props.name
        // || (this.state.data[2] && (nextState.betContent !== this.state.data[0]));
    }

    onTouchStart = () => {
        console.log(this.timeOutEvent);
        this.timeOutEvent = setTimeout(() => {
            this.timeOutEventStatus = 0;
            this.props.onLongPress && this.props.onLongPress();
        }, 500);
    }

    onTouchMove = () => {
        clearTimeout(this.timeOutEvent);
        this.timeOutEventStatus = 0;
    }

    onTouchEnd = () => {
        clearTimeout(this.timeOutEvent);
        if (this.timeOutEventStatus != 0) {

        }
        return false;
    }

    render() {
        return (
                global.id == "b5"? (
                    <div type={1} 
                         onTouchStart={this.onTouchStart} 
                         onTouchMove={this.onTouchMove} 
                         onTouchEnd={this.onTouchEnd}
                         onClick={this.props.onPress}
                         style={{width:"50%"}}
                         >
                         <div style={styles.B5GameItemContainer}>
                         <div style={styles.b5GameItemImg}>
                           <GameBodyImg {...this.props}/>
                       </div>
                       <FavoriteIcon {...this.props}/>
                         </div>
                    </div>
                ):(
                    <div type={1} onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove} onTouchEnd={this.onTouchEnd}
                    onClick={this.props.onPress}
                   // onLongPress={this.props.onLongPress}
               >
                   <div style={styles.gameItemContainer}>
                       <div style={{
                           display: "flex",
                           flex: 1,
                           justifyContent: "center",
                           alignItem: "center",
                           // background: "#000"
                       }}>
                           <GameBodyImg {...this.props}/>
                       </div>
                       <FavoriteIcon {...this.props}/>
                   </div>
               </div>
                )
        )

    }
}

const styles = {
    gameItemContainer: {
        display: "flex",
        flexDirection: "column",
        width: (model.clientWidth - 50) / 3,
        height: (model.clientWidth - 50) / 3,
        marginLeft: 5,
        marginRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: "#fff",
        border: "1px solid rgba(0,0,0,0.08)",
        borderRadius: 3,
        overflow: "hidden",
        justifyContent: "space-between",
        alignItems: "stretch",
        marginBottom: 10
    },
    B5GameItemContainer:{
        display:"flex",
        justifyContent:"space-between",
        padding:"0 10",
        backgroundColor:"rgba(0,0,0,0.03)",
        borderRadius:5,
        border:"1px solid #ccc",
        marginTop:5,
        height:70,
        marginRight:5,
        alignItems:"center"

    },
    b5GameItemImg:{
        display:"flex",
        justifyContent:"space-between",
        flex: 1,
        height:50,
        width:40,
    },
    gameItemImg: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    gameImg: {},
};
export default GameBody;
