/**
 * 倒计时
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MyInput from '../../../component/myInput';

export default class BetAmountInput extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            betAmount: this.props.betAmount
        };
    }

    static propTypes = {
        betAmount: PropTypes.string,
        onEndEditing: PropTypes.func,
        onBlur: PropTypes.func,
        onChangeText: PropTypes.func,
        disabled: PropTypes.bool,
        _ref: PropTypes.func
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps) => {
        // alert(nextProps.betAmount);
        // if (nextProps.betAmount) {
        //     this.setState({betAmount: nextProps.betAmount});
        // }
    };


    render() {
        const betBoxStyles = {
            betAmountInput: {
                display: "flex",
                flex: 1,
                padding: 0,
                textAlign: "center"
            },
        };
        return (
            <MyInput
                type={'number'}
                _ref={(input) => {
                    this.props._ref(input);
                }}
                value={this.props.betAmount}
                placeholder={!this.props.disabled ? "下注金额" : "停止投注"}
                readOnly={this.props.disabled}
                style={betBoxStyles.betAmountInput}
                onChangeText={(text) => {
                    // this.props.onChangeText(text);
                    // this.setState({betAmount: text});
                    this.props.onChangeText(text.replace(/\D/g, ''));
                    // this._betAmount(text);
                    // this.setBetAmount(text);
                }}
                onBlur={(text) => {
                    this.props.onBlur(text.replace(/\D/g, ''));
                }}
                // onEndEditing={() => {
                //     this.props.onEndEditing(this.state.betAmount);
                // }}
                // onBlur={() => {
                //     this.props.onBlur(this.state.betAmount);
                // }}
            />
        )
    }
}
