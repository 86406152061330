/**
 * 赛事投注详情
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../utils/icon'
import SportMatchInfo from './sportMatchInfo';
import style from '../../../../utils/config.scss'
import model from "../../../../services/model";
import PublicUtils from '../../../../utils/publicUtils';

class MatchItem extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            checked: this.props.checked,
            rotate: this.props.checked ? "0deg" : "-90deg",
            translateY: this.props.checked ? "3px" : "0",
            translateX: this.props.checked ? "-3px" : "0",
            backgroundColor: this.props.checked ? "rgba(255, 166, 55, 0.15)" : "#fff",
        };
    }

    static propTypes = {
        item: PropTypes.object,
        playId: PropTypes.number,
        ballId: PropTypes.number,
        betId: PropTypes.number,
        checked: PropTypes.bool,
        onOddPress: PropTypes.func,
        type: PropTypes.string
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面渲染完成时
     */
    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({checked: !!nextProps.checked});
    }

    /**
     * 展开动画
     * @private
     */
    _translateAniOpen = () => {
        // this.parallelAnimatedOpen.start();
        this.setState({
            rotate: "-90deg",
            translateY: "0",
            translateX: "0",
            backgroundColor: "#fff",
            checked: !this.state.checked
        })
    };
    /**
     * 收起动画
     * @private
     */
    _translateAniClose = () => {
        // this.parallelAnimatedClose.start();
        this.setState({
            rotate: "0deg",
            translateY: "3px",
            translateX: "-3px",
            backgroundColor: "rgba(255, 166, 55, 0.15)",
            checked: !this.state.checked
        })
    };
    /**
     * 展开/收起投注列表
     * @private
     */
    _toggle = () => {
        if (this.props.type === "result") {
            this.state.checked ? this._translateAniClose() : this._translateAniOpen();
            // this.setState({checked: !this.state.checked});
        } else {
            PublicUtils.navigate("HgDetail", {
                playId: this.props.playId,
                ballId: this.props.ballId,
                betId: this.props.betId,
                data: this.props.item
            });
        }
    };

    /**
     * 滚球 今日 早盘 过关
     * @private
     */
    _GQ_JR_ZP_GG = () => {
        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingLeft: 5,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return this.state.item.list.map((childitem, key) => {
            return (
                <div key={key} style={matchStyles.matchDetailList}>
                    <div style={matchStyles.top}>
                        <div>
                            {
                                this.props.playId === 0 ?
                                    <div>
                                        <div
                                            style={{...matchStyles.bigSize, ...matchStyles.themeText}}>{childitem[4]} {childitem[5]}</div>
                                    </div> : null
                            }
                            {
                                this.props.playId !== 0 ?
                                    <div>
                                        <div
                                            style={{...matchStyles.bigSize, ...matchStyles.themeText}}>{childitem[4]}`
                                        </div>
                                    </div> : null
                            }
                        </div>
                        <div style={matchStyles.playTypeIconBox}>
                            <div>
                                <div
                                    style={{...matchStyles.bigSize, ...matchStyles.themeText, ...matchStyles.playTypeIcon}}>滚球
                                </div>
                            </div>
                            <Icon icon={"game-sport"} size={18} color={style['primaryColor' + model.SKINNAME]}/>
                        </div>
                    </div>
                    <div style={matchStyles.childMatchHeader}>
                        <div style={{...matchStyles.childMatchHeaderCol}}>
                            <div style={matchStyles.childMatchNameBox}>
                                <div
                                    style={matchStyles.childMatchName}>{childitem[2]}</div>
                            </div>
                        </div>
                        {
                            this.props.playId === 2 ?
                                <div style={{...matchStyles.childMatchHeaderMid}}>
                                    <div style={matchStyles.childMatchHeaderMidText}>{childitem[5]}</div>
                                    <div style={matchStyles.childMatchHeaderMidText}>-</div>
                                    <div style={matchStyles.childMatchHeaderMidText}>{childitem[6]}</div>
                                </div> : null
                        }
                        {
                            this.props.playId !== 2 ?
                                <div style={matchStyles.childMatchHeaderMid}>
                                    <div style={matchStyles.childMatchHeaderMidText}>VS</div>
                                </div> : null
                        }
                        <div style={matchStyles.childMatchHeaderCol}>
                            <div style={matchStyles.childMatchNameBox}>
                                <div
                                    style={matchStyles.childMatchName}>{childitem[3]}</div>
                            </div>
                        </div>
                    </div>
                    {/**全场（独赢）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={1} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（让球）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={3} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（大小）-**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={5} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（单双）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={7} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半场（独赢）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={2} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半场（让球）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={4} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半场（大小）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={6} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（波胆）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={9} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（主队得分）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={14} subPlayName={'主队得分'} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（客队得分）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={14} subPlayName={'客队得分'} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**总入球**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={11} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半全场**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={13} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                </div>
            )
        })
    };
    /**
     * 冠军
     * @private
     */
    _GJ = () => {
        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingLeft: 5,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return this.state.item.list.map((childitem, key) => {
            return (
                <div key={key} style={matchStyles.matchDetailList}>
                    <div style={matchStyles.gjTop}>
                        <div style={matchStyles.themeText}>{childitem[2]}</div>
                    </div>
                    <div style={matchStyles.gjChildList}>
                        {
                            childitem[3].map((item, i) => {
                                return (
                                    <div key={i}
                                         onClick={() => {
                                             this._onPress(i, childitem);
                                         }} style={matchStyles.gjChildItem}>
                                        <div>{childitem[3][i]}</div>
                                        <div
                                            style={{...childitem[6] && childitem[6][i] === 0 ? matchStyles.fc0 : childitem[6] && childitem[6][i] === 2 ? matchStyles.fc2 : matchStyles.fc1}}>{childitem[4][i]}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        })
    };

    /**
     * 点击下注
     * @param template：数据模板 data：数据
     */
    _onPress = (template, data) => {
        this.props.onOddPress(template, data);
    };
    /**
     * 渲染列表
     * @returns {*}
     * @private
     */
    _renderList = () => {
        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingLeft: 5,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        let result = null;
        if (this.props.type === "result") {
            if (this.state.checked) {
                result = this._renderResult();
            }
        } else {
            if (this.state.checked) {
                result = <div style={matchStyles.matchChildBox}>
                    {(this.props.playId === 0 || this.props.playId === 1 || this.props.playId === 2 || this.props.playId === 4) ? this._GQ_JR_ZP_GG() : this._GJ()}
                </div>
            }
        }
        return result;
    };
    /**
     * 渲染比赛结果
     * @private
     */
    _renderResult = () => {
        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingLeft: 5,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return this.state.item.list.map((childitem, key) => {
            return (
                <div key={key} style={matchStyles.matchDetailList}>
                    <div style={matchStyles.top}>
                        <div>

                            <div>
                                <div
                                    style={{...matchStyles.bigSize, ...matchStyles.themeText}}>{childitem.match_date} {childitem.match_time}</div>
                            </div>

                        </div>
                        {/*<View style={matchStyles.playTypeIconBox}>*/}
                        {/*<View><Theme.Text*/}
                        {/*style={[matchStyles.bigSize, matchStyles.themeText, matchStyles.playTypeIcon]}>滚球</Theme.Text></View>*/}
                        {/*<Icon icon={"game-sport"} size={18} color={style['primaryColor' + model.SKINNAME]}/>*/}
                        {/*</View>*/}
                    </div>
                    <div style={matchStyles.childMatchHeader}>
                        <div style={{...matchStyles.childMatchHeaderCol}}>
                            <div style={matchStyles.childMatchNameBox}>
                                <div
                                    style={matchStyles.childMatchName}>{childitem.match_homename}</div>
                            </div>
                        </div>
                        <div style={matchStyles.childMatchHeaderMid}>
                            <div style={matchStyles.childMatchHeaderMidText}>VS</div>
                        </div>
                        <div style={matchStyles.childMatchHeaderCol}>
                            <div style={matchStyles.childMatchNameBox}>
                                <div
                                    style={matchStyles.childMatchName}>{childitem.match_awayname}</div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.resultItemBox}>
                        <div style={{...styles.resultItem, ...styles.resultItemBf}}>{childitem.bhbf}</div>
                        <div style={{...styles.resultItem, ...styles.resultItemName}}>上半场</div>
                        <div style={{...styles.resultItem, ...styles.resultItemBf}}>{childitem.babf}</div>
                    </div>
                    <div style={styles.resultItemBox}>
                        <div style={{...styles.resultItem, ...styles.resultItemBf}}>{childitem.qhbf}</div>
                        <div style={{...styles.resultItem, ...styles.resultItemName}}>全场</div>
                        <div style={{...styles.resultItem, ...styles.resultItemBf}}>{childitem.qabf}</div>
                    </div>
                </div>
            )
        });
    };

    render() {

        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return (
            <div key={this.state.item.id} style={matchStyles.matchInfoBox}>
                <div onClick={() => {
                    this._toggle();
                }} style={matchStyles.matchHeaderButton}>
                    <div style={{...matchStyles.matchHeaderBox, ...this.state.checked ? matchStyles.activeItem : null}}>
                        <div style={matchStyles.matchNameBox}>
                            {/*<div*/}
                                {/*style={{...matchStyles.matchIcon, ...this.state.checked ? matchStyles.arrowDown : matchStyles.arrowRight}}>*/}
                                {/*<Icon icon={'sjjt'} size={14} color={'#333'}/>*/}
                            {/*</div>*/}
                            <div style={matchStyles.matchName}>
                                <span style={matchStyles.matchNameText}>{this.state.item.name}</span>
                            </div>
                        </div>
                        <div style={matchStyles.matchChildCount}>
                            <div
                                style={matchStyles.matchChildCountText}>{this.state.item.count}</div>
                        </div>
                    </div>
                </div>
                {
                    this._renderList()
                }

            </div>
        )
    }
}


export default MatchItem