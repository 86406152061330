/**
 * 提现
 * @format
 * @flow
 */
import React from 'react';
import Container from "../component/container";
import Storage from "../services/storage";
import PublicUtils from "../utils/publicUtils";
import model from "../services/model";
import Http from "../services/http";
import Icon from '../utils/icon'
import MyInput from '../component/myInput'
import MyButton from '../component/myButton'
import style from '../utils/config.scss'
import global from "../global";
import MyPicker from "../component/myPicker";

export default class Withdrawal extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            amount: null,
            showProgress: false,
            showProgress2: false,
            fundPassword: null,
            force_withdraw: 0,
            withdraw_text: "",
            ismt:false, // 是否有免提直充
            curzf:1, // 当前支付 1 提现 2 免提直充
            discount: null,
            mtObj:{},
            dzf:false,// 收款方式
            mannerOptionFliter:false, // 收款方式选择
            mannerOption:[{label: ""}],
            cim:{}, // 当前选择的方式
            blist:[], // 当前收款方式列表
            skkh:'', // 收款卡号
            USDT_rate:"",// 当前汇率
            USDTNumber:0,

          fcf:0, // 防重复
        }




    }

    static navigationOptions = ({navigation}) => {
        return {}
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @returns {Promise<void>}
     * @private
     */
    _init_ = async () => {
        let params = {
            type: "pay_num"
        };
        Http.request("getBankInfo", params, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'withdrawal'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {





                    if(res.data.more_card.length>0){
                        let tempArr = [];





                        if(res.data.data.pay_card){
                            // 默认银行添加
                            tempArr.push({
                                label:res.data.data.pay_card,
                                cur:{
                                    card_type: '',
                                    create_time: "",
                                    id: '',
                                    pay_address: res.data.data.pay_address,
                                    pay_card: res.data.data.pay_card,
                                    pay_num: res.data.data.pay_num,
                                    update_time: "",
                                    user_account: res.data.data.user_name,
                                }
                            })
                        }



                        for(let i =0; i<res.data.more_card.length;i++){
                            tempArr.push({
                                label:res.data.more_card[i].pay_card,
                                cur:res.data.more_card[i],
                            })
                        }




                    console.log(tempArr)

                        this.setState({withdraw_text:res.data.withdraw_text,dzf:true,mannerOption:tempArr,USDT_rate:res.data.USDT_rate});

                    }else{
                        this.setState({withdraw_text:res.data.withdraw_text});

                    }



                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });

        let user = JSON.parse(Storage.retrieveData("user"));
        user.pay_num = user.pay_num.substring(0, 4) + "****" + user.pay_num.substr(user.pay_num.length - 4);
        this.setState({userInfo: user});


        // 免提充值判断
        let tempThis = this;
        Http.request("mtzc", {}, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'withdrawal'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {


                    // 长度大于0才显示优惠
                    if(res.data.data.length>0){
                        tempThis.setState({ismt:true});

                        // 重组对象 二维数组转对象数组
                        let tempArr = [];
                        for(let i=0;i<res.data.data.length;i++){
                            tempArr.push({
                                id:res.data.data[i][0],
                                text:res.data.data[i][1],
                                mn:res.data.data[i][2],
                            })
                        }
                        tempThis.setState({mtObj:tempArr,discount:tempArr[0]})

                    }
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });


        // 余额请求



        Http.request("memberBalance", {action:'refresh'}, (res) => {
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
                this.setState({showProgress: false});
            }, 1000);
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            } else {
                if (res.code === 200) {
                    if (res.data && res.data.status) {

                        // 更新数据
                        let user = JSON.parse(Storage.retrieveData("user"));
                        user.is_balance = res.data.balance.balance;
                        tempThis.setState({userInfo: user});

                    }
                } else {
                    PublicUtils.showToast("会员信息获取失败");
                }
            }
        });


    };

    /**
     * 设置转账金额
     * @param amount
     */
    _setAmount = (amount) => {


        this.setState({amount: amount});

        if(!amount){
            return
        }


        if(this.state.cim&&this.state.cim.card_type != 1){
            this.setState({USDTNumber: (amount == ''? '0':parseFloat(amount) / parseFloat(this.state.USDT_rate)).toFixed(2)});
        }
    };
    /**
     * 可转金额
     * @private
     */
    _getTransferTips = () => {
        const styles = {
            header: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10
            },
            left: {
                display: "flex",
                paddingRight: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            middle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start"
            },
            payCard: {
                fontSize: 18,
                color: "#000"
            },
            payNum: {
                fontSize: 14,
                color: "#999"
            },
            right: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            tipsBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 8,
                paddingBottom: 8,
            },
            tipsIcon: {
                display: "flex",
                width: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            tipsBody: {
                display: "flex",
            },
            tipsText: {
                fontSize: 14,
                color: "#fff",
                marginLeft: 5,
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14
            },
            transferTipsColor2: {
                display: "flex",
                flexDirection: "row",
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14
            },
            transferTipsColor3: {
                fontSize: 14,
                marginLeft: 3,
                marginRight: 3
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                display: "flex",
                justifyContent: "center",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            fundPasswordContainer: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                flexDirection: "row",
                borderBottom: "1px solid #f5f7f9",
                alignItems: "center",
                fontSize: 14
            },
            fundPasswordInputWapper: {
                display: "flex",
                flex: 1,
            },
            fundPasswordInput: {
                display: "flex",
                marginLeft: 10,
                flex: 1
            }
        };
        return <div style={styles.transferTipsLeft}>
            <div style={styles.transferTipsColor1}>可提</div>
            <div
                style={styles.transferTipsColor2}>
                <div
                    style={styles.transferTipsColor3}>{PublicUtils.subStringNum(this.state.userInfo.withdraw_min)}</div>
                ~
                <div style={styles.transferTipsColor3}>{PublicUtils.subStringNum(this.state.userInfo.is_balance)}</div>
            </div>
            <div style={styles.transferTipsColor1}>元</div>
        </div>;
    };
    /**
     * 绑定银行卡
     * @private
     */
    _bindBankcard = async () => {
        if (PublicUtils._checkLogin()) {
            const Params = {fromPage: "GameTransfer"};
            PublicUtils.navigate("BindBank", {type: 1});
        } else {
            PublicUtils.navigate('Login', {nextPage: '/withdrawal'});
        }
    };
    /**
     * 全部划转
     * @private
     */
    _allAmount = () => {
        this._setAmount(this.state.userInfo.is_balance);
        if(this.state.cim&&this.state.cim.card_type != 1){
            this.setState({USDTNumber:(parseFloat(this.state.userInfo.is_balance) / parseFloat(this.state.USDT_rate)).toFixed(2)});
        }
    };
    /**
     * 确认提款
     */
    _formPut = () => {
        // this.navCtrl.push("WithdrawSuccessPage", {
        //   message: 1,
        //   orderno: 2
        // })
        if (!this.state.amount) {
            PublicUtils.showToast("请输入提款金额");
            return;
        }
        if (!this.state.fundPassword) {
            PublicUtils.showToast("请输入提款密码");
            return;
        }
        // if (!model.inputRule.pay_password.rule.test(this.state.fundPassword)) {
        //     PublicUtils.showToast(model.inputRule.pay_password.ruleMsg);
        //     return;
        // }

      let self = this;
      if(this.state.fcf){
        PublicUtils.showToast("请不要重复点击！");
        return;
      }
      this.state.fcf = 1;
      setTimeout(()=>{
        self.state.fcf = 0;
      },3000)


      this.setState({showProgress: true});
        let parm = {
            is_amount: this.state.amount,
            safe_password: this.state.fundPassword,
            force_withdraw: this.state.force_withdraw,
            receipt_card:this.state.cim.id?this.state.cim.id:''
        };






        Http.request("Withdraw", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'withdrawal'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status) {






                    if(this.state.skkh){
                        PublicUtils.navigate("WithdrawComplete", {
                            message: res.data.message,
                            orderno: res.data.order_no,
                            amount: this.state.amount,
                            bankname: this.state.manner,
                            banknum: this.state.skkh
                        });



                    }else{
                        PublicUtils.navigate("WithdrawComplete", {
                            message: res.data.message,
                            orderno: res.data.order_no,
                            amount: this.state.amount,
                            bankname: this.state.userInfo.pay_card,
                            banknum: this.state.userInfo.pay_num
                        });
                    }


                } else {
                    if (res.data.enforceable) {
                        PublicUtils.alert({
                            title: "温馨提示",
                            msg: res.data.message,
                            buttons: [
                                {
                                    text: "取消", onPress: () => {
                                    }
                                },
                                {
                                    text: "好的", onPress: () => {
                                        this.setState({force_withdraw: 1}, () => {
                                            this._formPut();
                                        })
                                    }
                                }
                            ]
                        });
                    } else {
                        PublicUtils.showToast(res.data.message)
                    }
                }
            }
        })

    };


    // 确认提款
    _formPut2 = () => {



        // this.navCtrl.push("WithdrawSuccessPage", {
        //   message: 1,
        //   orderno: 2
        // })
        if (!this.state.amount) {
            PublicUtils.showToast("请输入提款金额");
            return;
        }

        // if (!model.inputRule.pay_password.rule.test(this.state.fundPassword)) {
        //     PublicUtils.showToast(model.inputRule.pay_password.ruleMsg);
        //     return;
        // }
        this.setState({showProgress2: true});
        let parm = {
            is_amount: this.state.amount,
            is_activity: this.state.discount.id,
        };


        let self = this;
      if(this.state.fcf){
        PublicUtils.showToast("请不要重复点击！");
        return;
      }
      this.state.fcf = 1;
      setTimeout(()=>{
        self.state.fcf = 0;
      },3000)

        Http.request("mtzc2", parm, (res) => {
            this.setState({showProgress2: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'withdrawal'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status) {
                    PublicUtils.navigate("WithdrawComplete", {
                        message: res.data.message,
                        orderno: res.data.order_no,
                        amount: this.state.amount,
                        bankname: this.state.userInfo.pay_card,
                        banknum: this.state.userInfo.pay_num
                    });
                } else {
                    if (res.data.enforceable) {
                        PublicUtils.alert({
                            title: "温馨提示",
                            msg: res.data.message,
                            buttons: [
                                {
                                    text: "取消", onPress: () => {
                                    }
                                },
                                {
                                    text: "好的", onPress: () => {
                                        this.setState({force_withdraw: 1}, () => {
                                            this._formPut2();
                                        })
                                    }
                                }
                            ]
                        });
                    } else {
                        PublicUtils.showToast(res.data.message)
                    }
                }
            }
        })

    };

    render() {
        const styles = {
            wrapper: {
                padding: 10
            },
            header: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                backgroundColor: "#fff",
                borderRadius: 3,
                border: "1px solid rgba(0,0,0,0.06)",
                marginBottom: 10
            },
            left: {
                display: "flex",
                paddingRight: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            middle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start"
            },
            payCard: {
                fontSize: 18,
                color: "#000"
            },
            payNum: {
                fontSize: 14,
                color: "#999"
            },
            discountItemBox: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                height: 40,
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            discountItemBoxText: {
                fontSize: 14,
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            activeDiscount: {
                color: style['primaryColor' + model.SKINNAME]
            },
            discountCheckBox: {
                display: "flex",
                width: 5,
                height: 12,
                borderBottom: "2px solid " + style['primaryColor' + model.SKINNAME],
                borderRight: "2px solid " + style['primaryColor' + model.SKINNAME],
                transform: "rotate(45deg)"
            },
            right: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            tipsBox: {
                display: "flex",
                backgroundColor: "#fff",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 8,
                paddingBottom: 8,
            },
            tipsIcon: {
                display: "flex",
                width: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            tipsBody: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            },
            tipsText: {
                color: "#ff380e",
                fontSize: 14,
                marginLeft: 5,
                lineHeight: "25px",
            },
            amountWrapper: {
                padding: 10,
                backgroundColor: "#fff",
                borderRadius: 3,
                border: "1px solid rgba(0,0,0,0.06)",
            },
            amountContainer: {},
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferSubTips:{
                lineHeight: "23px",
                margin:"5px 0",
                color:"rgba(0,0,0,0.7)"
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                // marginLeft: 10,
                // marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                display: "flex",
                justifyContent: "center",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                // paddingLeft: 10,
                // paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            fundPasswordContainer: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                flexDirection: "row",
                backgroundColor: "#fff",
                borderRadius: 3,
                border: "1px solid rgba(0,0,0,0.06)",
                alignItems: "center",
                fontSize: 14,
            },
            fundPasswordInputWapper: {
                display: "flex",
                flex: 1,
            },
            fundPasswordInput: {
                display: "flex",
                marginLeft: 10,
                flex: 1
            },
            accountInfoItem: {
                display: "flex",
                flexDirection: "row",
                padding: 10,
                // justifyContent: "space-between",
                // paddingLeft: 10,
                // paddingRight: 10,
                // height: 45,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10,
                alignItems: "center"
                // alignItems: "center",
            },
            accountInfoTitle: {
                fontSize: 14,
                color: "rgba(0,0,0,1)",
                width: 85
            },
            accountInfoItemText: {
                fontSize: 14,
                color: "#000",
                flex: 1
            },
        };
        return (
            <Container title={'提现'} style={{backgroundColor: "#f5f7f9"}}>
                <div style={styles.tipsBox}>
                    {/*<div style={styles.tipsIcon}>*/}
                    {/*<Icon icon={'tips'} color={'#fff'} size={16}/>*/}
                    {/*</div>*/}
                    <div style={styles.tipsBody}>
                        <div style={styles.tipsText}>温馨提示：仅支持整数金额提款！</div>
                    </div>
                </div>

                {
                    this.state.ismt?<div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'8px'}}>
                            <div style={{width:'50%',textAlign:'center'}} onClick={()=>{
                                this.setState({curzf:1})
                                console.log(this.state.curzf)
                            }}>
                                <div style={{color:this.state.curzf == 1?'#39d679':'black'}}>提现</div>
                            </div>
                            <div style={{width:'50%',textAlign:'center'}} onClick={()=>{
                                this.setState({curzf:2})
                                console.log(this.state.curzf)
                            }}>
                                <div style={{color:this.state.curzf == 2?'#39d679':'black'}}>免提直充</div>
                            </div>
                        </div>
                    </div>:<div></div>
                }



                {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                <div style={styles.wrapper}>
                    {
                        this.state.curzf == 1?this.state.dzf?<div>
                           <div style={{marginBottom:15}}>

                               <div style={styles.fundPasswordContainer}>
                                   <div style={{fontSize: 14}}>收款方式</div>
                                   <div style={{
                                       display: "flex",
                                       flex: 1,
                                       flexDirection: "row",
                                       justifyContent: "space-between",
                                       alignItems: "center"
                                   }}>

                                       <MyInput onPress={() => {
                                           this.setState({mannerOptionFliter: true});
                                       }} style={styles.fundPasswordInput}
                                                readOnly={true}
                                                value={this.state.manner}
                                                placeholder={'请选择收款方式'} onChangeText={(text) => {
                                       }}/>
                                       <Icon icon={'sjjt'} size={12} color={'#000'}/>
                                   </div>
                               </div>


                               {
                                   this.state.skkh?
                                       <div>
                                           <div style={styles.accountInfoItem}>
                                               <div style={styles.accountInfoTitle}>{this.state.cim&&this.state.cim.card_type != 1 ?'收款地址':'收款卡号'}</div>
                                               <div style={styles.accountInfoItemText}>{this.state.skkh}</div>
                                           </div>
                                           {
                                               this.state.cim&&this.state.cim.card_type == 2?(
                                                   <>
                                                       <div style={styles.accountInfoItem}>
                                                           <div style={styles.accountInfoTitle}>
                                                               当前汇率:
                                                           </div>
                                                           <div
                                                               className={styles.accountInfoItemText}>{this.state.USDT_rate}</div>
                                                       </div>
                                                       <div style={styles.accountInfoItem}>
                                                           <div style={styles.accountInfoTitle}>
                                                               USDT数量:
                                                           </div>
                                                           <div className={styles.accountInfoItemText}>

                                                               <MyInput style={styles.amountInput}
                                                                        value={this.state.USDTNumber}
                                                                        placeholder={'0'}
                                                                        onChangeText={(text) => {
                                                                            this.setState({
                                                                                USDTNumber: text,
                                                                                amount: text == '' ? '0' : Math.round(parseFloat(text) * this.state.USDT_rate)
                                                                            })
                                                                        }}
                                                                        onKeyUp={(text) => {
                                                                            this.setState({amount: text.replace(/\D/g, '')})
                                                                        }}
                                                               />
                                                           </div>
                                                       </div>
                                                   </>
                                               ):null
                                           }

                                       </div>:
                                       <div></div>
                               }


                           </div>
                        </div>:<div>
                            <div>
                                <div  onClick={() => {
                                    this._bindBankcard({type: 1});
                                }} style={styles.header}>
                                    <div style={styles.left}>
                                        <Icon icon={PublicUtils.bankIconTransform(this.state.userInfo.pay_card)}
                                              color={style['primaryColor' + model.SKINNAME]}
                                              size={50}/>
                                    </div>
                                    <div style={styles.middle}>
                                        <div style={styles.payCard}>{this.state.userInfo.pay_card}</div>
                                        <div style={styles.payNum}>尾号{this.state.userInfo.pay_num}</div>
                                    </div>
                                    <div style={styles.right}>
                                        <Icon icon={'right'} color={'#bebebe'} size={32}/>
                                    </div>
                                </div>
                            </div>
                        </div>:<div></div>
                    }


                    {
                        this.state.curzf == 2?<div style={{marginBottom:'10px', border: "1px solid rgba(0,0,0,0.06)", backgroundColor: "#fff", borderRadius: 3,}}>
                            {
                                this.state.mtObj.length > 0 && this.state.mtObj.map((item, key) => {
                                    return (
                                      <div key={key} onClick={() => {
                                          this.setState({discount: item});
                                          console.log(item,key)
                                      }} style={styles.discountItemBox}>
                                          <div
                                            style={{...styles.discountItemBoxText, ...this.state.discount === item ? styles.activeDiscount : null}}>{item.text}</div>
                                          {this.state.discount === item &&
                                          <div style={styles.discountCheckBox}/>}
                                      </div>
                                    )
                                })
                            }
                        </div>:<div></div>
                    }


                    <div style={styles.amountWrapper}>
                        <div style={styles.amountContainer}>
                            <div style={styles.amountTitle}>{global.ID == 'yy'?'提现金额':'转入金额'}</div>
                            {
                                this.state.withdraw_text?
                                    <div style={styles.transferSubTips}>
                                        {
                                            this.state.withdraw_text
                                        }
                                    </div>:null
                            }
                            <div style={styles.amountInputContainer}>
                                <div style={styles.amountInputIconBox}>
                                    <Icon icon={'rmb'} size={24} color={'#000'}/>
                                </div>
                                <div style={styles.amountInputBox}>
                                    <MyInput style={styles.amountInput}
                                             value={this.state.amount && String(this.state.amount)}
                                             placeholder={'0.00'}
                                             onChangeText={(text) => {
                                                 this._setAmount(text);
                                             }}
                                             onKeyUp={(text) => {
                                                 this.setState({amount: text.replace(/\D/g, '')})
                                             }}
                                    />
                                </div>
                            </div>
                            <div style={styles.transferTips}>
                                <div>
                                    {
                                        this._getTransferTips()
                                    }
                                </div>
                                <div style={styles.transferTipsRight}>
                                    <div onClick={() => {
                                        this._allAmount();

                                    }} style={styles.transferTipsColor2}>全部提现
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={styles.amountTemplate}>
                            <div onClick={() => {
                                this._setAmount(100);

                            }}
                                 style={{...styles.amountTemplateItem, ...this.state.amount === 100 ? styles.active : null}}>
                                <div
                                    style={{...styles.amountTemplateItemText, ...this.state.amount === 100 ? styles.activeText : null}}>￥100
                                </div>
                            </div>
                            <div onClick={() => {
                                this._setAmount(300);

                            }}
                                 style={{...styles.amountTemplateItem, ...this.state.amount === 300 ? styles.active : null}}>
                                <div
                                    style={{...styles.amountTemplateItemText, ...this.state.amount === 300 ? styles.activeText : null}}>￥300
                                </div>
                            </div>
                            <div onClick={() => {
                                this._setAmount(500);

                            }}
                                 style={{...styles.amountTemplateItem, ...this.state.amount === 500 ? styles.active : null}}>
                                <div
                                    style={{...styles.amountTemplateItemText, ...this.state.amount === 500 ? styles.activeText : null}}>￥500
                                </div>
                            </div>
                        </div>
                        <div style={styles.amountTemplate}>
                            <div onClick={() => {
                                this._setAmount(1000);
                            }}
                                 style={{...styles.amountTemplateItem, ...this.state.amount === 1000 ? styles.active : null}}>
                                <div
                                    style={{...styles.amountTemplateItemText, ...this.state.amount === 1000 ? styles.activeText : null}}>￥1000
                                </div>
                            </div>
                            <div onClick={() => {
                                this._setAmount(2000);
                            }}
                                 style={{...styles.amountTemplateItem, ...this.state.amount === 2000 ? styles.active : null}}>
                                <div
                                    style={{...styles.amountTemplateItemText, ...this.state.amount === 2000 ? styles.activeText : null}}>￥2000
                                </div>
                            </div>
                            <div onClick={() => {
                                this._setAmount(10000);
                            }}
                                 style={{...styles.amountTemplateItem, ...this.state.amount === 10000 ? styles.active : null}}>
                                <div
                                    style={{...styles.amountTemplateItemText, ...this.state.amount === 10000 ? styles.activeText : null}}>￥10000
                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        this.state.curzf == 1? <div>
                            <div style={styles.fundPasswordContainer}>
                                <div>取款密码</div>
                                <div style={styles.fundPasswordInputWapper}>
                                    <MyInput style={styles.fundPasswordInput}
                                             value={this.state.fundPassword} type={'password'}
                                             placeholder={'请输入取款密码'} onChangeText={(text) => {
                                        this.setState({fundPassword: text});
                                    }}/>
                                </div>
                            </div>
                            <div style={styles.btnBox}>
                                <MyButton title={'确认提款'} showProgress={this.state.showProgress}
                                          disabled={this.state.showProgress}
                                          onPress={() => {
                                              this._formPut()
                                          }}/>
                            </div>
                        </div>:<div></div>
                    }



                    {
                        this.state.curzf == 2?  <div>
                            <div style={styles.btnBox}>
                                <MyButton title={'确认充值'} showProgress={this.state.showProgress2}
                                          disabled={this.state.showProgress2}
                                          onPress={() => {
                                              this._formPut2()
                                          }}/>
                            </div>
                        </div>:<div></div>
                    }


                    <MyPicker rowCount={3}
                              title={'收款方式'}
                              model={this.state.mannerOption}
                              display={this.state.mannerOptionFliter}
                              onHiden={() => {
                                  this.setState({mannerOptionFliter: false})
                              }}
                              onItemPress={(item) => {

                                  this.setState({
                                      manner: item.value[0].value.label,
                                      cim:item.value[0].value.cur,
                                      skkh:item.value[0].value.cur.pay_num
                                  });
                              }}
                    />








                </div>
            </Container>
        )
    }
}
