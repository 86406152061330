import global from "../global";


let gameJSON = [{
  "name": "体育",
  "type_id": 1,
  "gamelist": [{
    "type": 1,
    "game_id": 101,
    "game_api": "",
    "name": "皇冠体育",
    "imgPath": "https://newpic.sdcrhb.com/sport/xtty.png",
    "icon": "logo-hg",
    "memo1": "平台特色：火爆赛事，玩法多样",
    "memo2": "包含游戏：皇冠体育、各种球类下注、串关",
    "labels": "#体育#皇冠#足球",
    "hot_type": "hot",
    "showTitle": 1,
  }, {
    "type": 1,
    "game_id": 107,
    "game_api": "XJ188",
    "name": "188体育",
    "imgPath": "https://newpic.sdcrhb.com/sport/xj188.png",
    "icon": "188",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：波音体育",
    "labels": "#体育",

  }, {
    "type": 1,
    "game_id": 109,
    "game_api": "SS",
    "name": "三昇体育",
    "imgPath": "https://newpic.sdcrhb.com/sport/ss.png",
    "icon": "ss",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：三昇体育",
    "labels": "#体育",

  }, {
    "type": 1,
    "game_id": 102,
    "game_api": "SB",
    "name": "沙巴体育",
    "imgPath": "https://newpic.sdcrhb.com/sport/sbty.png",
    "icon": "logo-sb",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：沙巴体育",
    "labels": "#体育#m8#足球#篮球#羽毛球#棒球#乒乓球#排球#网球#滚球#冠军#串关",
    "hot_type": "hot",
    "showTitle": 1,

  }, {
    "type": 1,
    "game_id": 105,
    "game_api": "FY",
    "name": "开心电竞",
    "imgPath": "https://newpic.sdcrhb.com/sport/fydj.png",
    "icon": "fy",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：开心电竞",
    "labels": "#体育#电竞",

  }],
  "icon": "zuqiu-1"
}, {
  "name": "彩票", "type_id": 2, "gamelist": [{
    "type": 2,
    "game_id": 201,
    "game_api": "",
    "name": "热门彩票",
    "imgPath": "https://newpic.sdcrhb.com/lottery/cpyx.png",
    "icon": "logo-xt",
    "memo1": "平台特色：高颜色美女陪您玩彩票",
    "memo2": "包含游戏：六合彩、PC蛋蛋、时时彩、PK拾、快三",
    "labels": "#彩票#六合彩#PC蛋蛋#时时彩#PK拾#快三",
    "gamelist": [{
      "target": "lottery",
      "game_id": 7001,
      "game_type": "_self_lottery",
      "name": "PC蛋蛋",
      "icon": "pcdd.png",
      "gamelist":[{
        "target": "lottery",
        "game_id": 7001,
        "game_type": "_self_lottery",
        "name": "PC蛋蛋",
        "icon": "pcdd.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 6001,
      "game_type": "_self_lottery",
      "name": "北京PK拾",
      "icon": "pk10.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 6001,
        "game_type": "_self_lottery",
        "name": "北京PK拾",
        "icon": "pk10.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 6002,
      "game_type": "_self_lottery",
      "name": "幸运飞艇",
      "icon": "xyft.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 6002,
        "game_type": "_self_lottery",
        "name": "幸运飞艇",
        "icon": "xyft.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 1001,
      "game_type": "_self_lottery",
      "name": "六合彩",
      "icon": "lhc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 1001,
        "game_type": "_self_lottery",
        "name": "六合彩",
        "icon": "lhc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": "",
      "game_type": "_self_lottery",
      "name": "山东11选5",
      "icon": "sd115.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": "",
        "game_type": "_self_lottery",
        "name": "山东11选5",
        "icon": "sd115.png",
      }]
    }, {
      "target": "lottery",
      "game_id": "",
      "game_type": "_self_lottery",
      "name": "广东11选5",
      "icon": "gd115.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": "",
        "game_type": "_self_lottery",
        "name": "广东11选5",
        "icon": "gd115.png",
      }]
    }, {
      "target": "lottery",
      "game_id": "",
      "game_type": "_self_lottery",
      "name": "江西11选5",
      "icon": "jx115.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": "",
        "game_type": "_self_lottery",
        "name": "江西11选5",
        "icon": "jx115.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 2003,
      "game_type": "_self_lottery",
      "name": "新疆时时彩",
      "icon": "xjssc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 2003,
        "game_type": "_self_lottery",
        "name": "新疆时时彩",
        "icon": "xjssc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 2001,
      "game_type": "_self_lottery",
      "name": "重庆时时彩",
      "icon": "cqssc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 2001,
        "game_type": "_self_lottery",
        "name": "重庆时时彩",
        "icon": "cqssc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 2002,
      "game_type": "_self_lottery",
      "name": "天津时时彩",
      "icon": "tjssc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 2002,
        "game_type": "_self_lottery",
        "name": "天津时时彩",
        "icon": "tjssc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 4002,
      "game_type": "_self_lottery",
      "name": "重庆快乐十分",
      "icon": "cqkl10.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 4002,
        "game_type": "_self_lottery",
        "name": "重庆快乐十分",
        "icon": "cqkl10.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 4001,
      "game_type": "_self_lottery",
      "name": "广东快乐十分",
      "icon": "gdkl10.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 4001,
        "game_type": "_self_lottery",
        "name": "广东快乐十分",
        "icon": "gdkl10.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 3002,
      "game_type": "_self_lottery",
      "name": "广西快三",
      "icon": "gxks.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 3002,
        "game_type": "_self_lottery",
        "name": "广西快三",
        "icon": "gxks.png",
      }]
    },
      {
        "target": "lottery", "game_id": 3003, "name": "安徽快三", "icon": "ahks.png",  "gamelist": [{
          "target": "lottery", "game_id": 3003, "name": "安徽快三", "icon": "ahks.png",
        }]
      },
      {
        "target": "lottery",
        "game_id": 3001,
        "game_type": "_self_lottery",
        "name": "江苏快三",
        "icon": "ahks.png",
        "gamelist": [{
          "target": "lottery",
          "game_id": 3001,
          "game_type": "_self_lottery",
          "name": "江苏快三",
          "icon": "ahks.png",
        }]
      }],
    "hot_type": "recommend",
    "showTitle": 1
  }, {
    "type": 2,
    "game_id": 204,
    "game_api": "HC",
    "name": "豪彩彩票",
    "imgPath": "https://newpic.sdcrhb.com/lottery/hccp.png",
    "icon": "hccp",
    "memo1": "平台特色：彩种齐全，玩法多样",
    "memo2": "包含游戏：11选5、快三、时时彩、福彩3D、排列三（五）、分分彩",
    "labels": "#彩票#六合彩#PC蛋蛋#时时彩#PK拾#快三#排列三",

  }, {
    "type": 2,
    "game_id": 203,
    "game_api": "VR",
    "name": "VR彩票",
    "imgPath": "https://newpic.sdcrhb.com/lottery/vrcp.png",
    "icon": "logo-vr",
    "memo1": "平台特色：彩种齐全，玩法多样",
    "memo2": "包含游戏：VR11选5、VR快三、VR幸运28、VR10分彩、VR赛车、VR游泳",
    "labels": "#彩票#六合彩#时时彩#PK拾#快三#VR#赛车#游泳",

  }, {
    "type": 2,
    "game_id": 205,
    "game_api": "DS",
    "name": "DS彩票",
    "imgPath": "https://newpic.sdcrhb.com/lottery/dscp.png",
    "icon": "logo-ds",
    "memo1": "平台特色：彩种齐全，玩法多样",
    "memo2": "包含游戏：DS11选5、DS快三、DS幸运5、DS赛车、快乐8",
    "labels": "#彩票#六合彩#时时彩#PC28#快三#赛车#快乐8",

  }], "icon": "caipiao"
}, {
  "name": "真人",
  "type_id": 3,
  "gamelist": [{
    "type": 3,
    "game_id": 309,
    "game_api": "VIP",
    "name": "VIP国际厅",
    "imgPath": "https://newpic.sdcrhb.com/real/vip.png",
    "icon": "vip",
    "memo1": "平台特色：亚洲最知名游戏平台",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、竞咪、街机、视讯",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",
    "hot_type": "recommend",
    "showTitle": 1,

  }, {
    "type": 3,
    "game_id": 303,
    "game_api": "BBIN",
    "name": "BB娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/bbin.png",
    "icon": "logo-bbin",
    "memo1": "平台特色：全新BB，提供全视讯品项",
    "memo2": "包含游戏：百家乐、幸运转轮、主题百家乐",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 301,
    "game_api": "OB",
    "name": "OB娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/ob.png",
    "icon": "logo-ob",
    "memo1": "\"平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 304,
    "game_api": "BG",
    "name": "BG娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/bg.png",
    "icon": "logo-bg",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：传统百家乐、特色百家乐、轮盘&骰宝、龙虎&牛牛、炸金花",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 305,
    "game_api": "DG",
    "name": "DG娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/dg.png",
    "icon": "logo-dg",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：DG梦幻厅、皇冠巴域厅、皇冠波贝厅、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",
    "hot_type": "new",
    "showTitle": 1,

  }, {
    "type": 3,
    "game_id": 307,
    "game_api": "MG",
    "name": "MG娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/mg.png",
    "icon": "logo-mg",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 308,
    "game_api": "OG",
    "name": "OG娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/og.png",
    "icon": "logo-og",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 310,
    "game_api": "WM",
    "name": "WM娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/wm.png",
    "icon": "logo-wm",
    "memo1": "平台特色：专业的真人娱乐城",
    "memo2": "包含游戏：百家乐、龙虎、牛牛、三公、鱼虾蟹、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 312,
    "game_api": "CQ9",
    "name": "CQ9娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/cq9.png",
    "icon": "cq9",
    "memo1": "平台特色：专业的真人娱乐城",
    "memo2": "包含游戏：百家乐、龙虎、牛牛、三公、鱼虾蟹、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 313,
    "game_api": "BBIN",
    "name": "XBB娱乐场",
    "imgPath": "https://newpic.sdcrhb.com/real/xbb.png",
    "icon": "xbb",
    "memo1": "平台特色：专业的真人娱乐城",
    "memo2": "包含游戏：百家乐、龙虎、牛牛、三公、鱼虾蟹、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }],
  "icon": "tubiao"
}, {
  "name": "老虎机",
  "type_id": 4,
  "gamelist": [{
    "type": 4,
    "game_id": 407,
    "game_api": "VIP",
    "name": "VIP老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/vip.png",
    "icon": "vip",
    "game_list_json": "VIP_game_list",
    "game_list_img_path": "/new/slot_res/vip/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：推筒子、飞禽走兽、森林舞会、斗三公、红黑梅方、疯狂德州...",
    "labels": "#老虎机#推筒子#飞禽走兽#森林舞会#斗三公",
    "hot_type": "new",
    "showTitle": 1,
    gamelist: [
      {
        "EN_Name": "Night of Wonder",
        "CN_Name": "奇幻马戏团",
        "HTML5_Game_ID": "MA27",
        "Button_Image_Name": "MA27.png"
      },
      {
        "EN_Name": "Aromatic Harbor",
        "CN_Name": "街头食神",
        "HTML5_Game_ID": "MA22",
        "Button_Image_Name": "MA22.png"
      },
      {
        "EN_Name": "Fortune Dragon Deluxe",
        "CN_Name": "神龙报喜",
        "HTML5_Game_ID": "AP15",
        "Button_Image_Name": "AP15.png"
      },
      {
        "EN_Name": "Jackpot Frenzy",
        "CN_Name": "积宝透透乐",
        "HTML5_Game_ID": "MJ00",
        "Button_Image_Name": "MJ00.png"
      },
      {
        "EN_Name": "Railway Bandits",
        "CN_Name": "铁盗枭雄",
        "HTML5_Game_ID": "MJ01",
        "Button_Image_Name": "MJ01.png"
      },
      {
        "EN_Name": "Mystic Realm Guardian",
        "CN_Name": "四灵奇谭",
        "HTML5_Game_ID": "MJ02",
        "Button_Image_Name": "MJ02.png"
      },
      {
        "EN_Name": "Shogun Honor",
        "CN_Name": "武士荣耀",
        "HTML5_Game_ID": "MA16",
        "Button_Image_Name": "MA16.png"
      },
      {
        "EN_Name": "Toys City",
        "CN_Name": "玩具城",
        "HTML5_Game_ID": "MA06",
        "Button_Image_Name": "MA06.png"
      },
      {
        "EN_Name": "Fortune Roar",
        "CN_Name": "狮来运转",
        "HTML5_Game_ID": "MA15",
        "Button_Image_Name": "MA15.png"
      },
      {
        "EN_Name": "City of Fame",
        "CN_Name": "名光之城",
        "HTML5_Game_ID": "MA04",
        "Button_Image_Name": "MA04.png"
      },
      {
        "EN_Name": "Elven Forest",
        "CN_Name": "森林精灵",
        "HTML5_Game_ID": "MA33",
        "Button_Image_Name": "MA33.png"
      },
      {
        "EN_Name": "Pirate Loot",
        "CN_Name": "夺宝船奇",
        "HTML5_Game_ID": "MA08",
        "Button_Image_Name": "MA08.png"
      },
      {
        "EN_Name": "Pool Party",
        "CN_Name": "泳池派对",
        "HTML5_Game_ID": "MA01",
        "Button_Image_Name": "MA01.png"
      },
      {
        "EN_Name": "Wild Koi",
        "CN_Name": "百搭锦鲤",
        "HTML5_Game_ID": "SB67",
        "Button_Image_Name": "SB67.png"
      },
      {
        "EN_Name": "Royal Gems",
        "CN_Name": "皇家之宝",
        "HTML5_Game_ID": "SB72",
        "Button_Image_Name": "SB72.png"
      },
      {
        "EN_Name": "Pirates Ahoy!",
        "CN_Name": "海盗历险",
        "HTML5_Game_ID": "SB68",
        "Button_Image_Name": "SB68.png"
      },
      {
        "EN_Name": "Animal Fun Park",
        "CN_Name": "森林游乐会",
        "HTML5_Game_ID": "SB70",
        "Button_Image_Name": "SB70.png"
      },
      {
        "EN_Name": "Treasure Party",
        "CN_Name": "宝藏派对",
        "HTML5_Game_ID": "SB73",
        "Button_Image_Name": "SB73.png"
      },
      {
        "EN_Name": "Lucky Express",
        "CN_Name": "幸运快餐",
        "HTML5_Game_ID": "WH58",
        "Button_Image_Name": "WH58.png"
      },
      {
        "EN_Name": "Kong Invasion",
        "CN_Name": "金刚来袭",
        "HTML5_Game_ID": "SB66",
        "Button_Image_Name": "SB66.png"
      },
      {
        "EN_Name": "Wild Gods",
        "CN_Name": "狂神对决",
        "HTML5_Game_ID": "SB62",
        "Button_Image_Name": "SB62.png"
      },
      {
        "EN_Name": "Knight and Dragon",
        "CN_Name": "勇者屠龙",
        "HTML5_Game_ID": "SB65",
        "Button_Image_Name": "SB65.png"
      },
      {
        "EN_Name": "Gold Class Deluxe",
        "CN_Name": "豪华金拉霸",
        "HTML5_Game_ID": "SC07",
        "Button_Image_Name": "SC07.png"
      },
      {
        "EN_Name": "Pillar of Fortune",
        "CN_Name": "翻倍宝器",
        "HTML5_Game_ID": "WH48",
        "Button_Image_Name": "WH48.png"
      },
      {
        "EN_Name": "Lucky Riches",
        "CN_Name": "财气冲天",
        "HTML5_Game_ID": "SB64",
        "Button_Image_Name": "SB64.png"
      },
      {
        "EN_Name": "Mermaid Treasure",
        "CN_Name": "美人鱼",
        "HTML5_Game_ID": "WH56",
        "Button_Image_Name": "WH56.png"
      },
      {
        "EN_Name": "Beast Party",
        "CN_Name": "飞禽走兽拉霸",
        "HTML5_Game_ID": "SC06",
        "Button_Image_Name": "SC06.png"
      },
      {
        "EN_Name": "Bingo Slot",
        "CN_Name": "宾果拉霸",
        "HTML5_Game_ID": "SB63",
        "Button_Image_Name": "SB63.png"
      },
      {
        "EN_Name": "Kung Fu All Stars",
        "CN_Name": "功夫传奇",
        "HTML5_Game_ID": "SB59",
        "Button_Image_Name": "SB59.png"
      },
      {
        "EN_Name": "Bao Zu Po",
        "CN_Name": "包租婆",
        "HTML5_Game_ID": "WH52",
        "Button_Image_Name": "WH52.png"
      },
      {
        "EN_Name": "Sex and Zen",
        "CN_Name": "肉蒲团",
        "HTML5_Game_ID": "WH62",
        "Button_Image_Name": "WH62.png"
      },
      {
        "EN_Name": "Ace Heist",
        "CN_Name": "王牌狂徒",
        "HTML5_Game_ID": "SB60",
        "Button_Image_Name": "SB60.png"
      },
      {
        "EN_Name": "Golden Dragon",
        "CN_Name": "巨龙宝藏",
        "HTML5_Game_ID": "WH31",
        "Button_Image_Name": "WH31.png"
      },
      {
        "EN_Name": "Lucha Heroes",
        "CN_Name": "摔跤英雄",
        "HTML5_Game_ID": "WH49",
        "Button_Image_Name": "WH49.png"
      },
      {
        "EN_Name": "The Nutcracker",
        "CN_Name": "胡桃夹子",
        "HTML5_Game_ID": "WH25",
        "Button_Image_Name": "WH25.png"
      },
      {
        "EN_Name": "Temple Raider",
        "CN_Name": "神庙丽影",
        "HTML5_Game_ID": "WH65",
        "Button_Image_Name": "WH65.png"
      },
      {
        "EN_Name": "Vegas Vegas",
        "CN_Name": "赌城之夜",
        "HTML5_Game_ID": "SB56",
        "Button_Image_Name": "SB56.png"
      },
      {
        "EN_Name": "Ultra Shift",
        "CN_Name": "极品飙车",
        "HTML5_Game_ID": "WH54",
        "Button_Image_Name": "WH54.png"
      },
      {
        "EN_Name": "Sushi Nights",
        "CN_Name": "寿司大食客",
        "HTML5_Game_ID": "WH26",
        "Button_Image_Name": "WH26.png"
      },
      {
        "EN_Name": "Peking Opera",
        "CN_Name": "皇家戏台",
        "HTML5_Game_ID": "WH40",
        "Button_Image_Name": "WH40.png"
      },
      {
        "EN_Name": "Steampunk War",
        "CN_Name": "蒸汽战争",
        "HTML5_Game_ID": "WH44",
        "Button_Image_Name": "WH44.png"
      },
      {
        "EN_Name": "Sexy Succubus",
        "CN_Name": "魅惑魔女",
        "HTML5_Game_ID": "SB58",
        "Button_Image_Name": "SB58.png"
      },
      {
        "EN_Name": "One Percenter",
        "CN_Name": "横行霸道",
        "HTML5_Game_ID": "WH36",
        "Button_Image_Name": "WH36.png"
      },
      {
        "EN_Name": "Young and Dangerous",
        "CN_Name": "古惑仔",
        "HTML5_Game_ID": "WH42",
        "Button_Image_Name": "WH42.png"
      },
      {
        "EN_Name": "Money Monster",
        "CN_Name": "神奇宠物",
        "HTML5_Game_ID": "WH55",
        "Button_Image_Name": "WH55.png"
      },
      {
        "EN_Name": "Evil Dragons",
        "CN_Name": "魔龙",
        "HTML5_Game_ID": "SB57",
        "Button_Image_Name": "SB57.png"
      },
      {
        "EN_Name": "Treasure Tarot",
        "CN_Name": "财宝塔罗",
        "HTML5_Game_ID": "WH19",
        "Button_Image_Name": "WH19.png"
      },
      {
        "EN_Name": "Egyptian Treasure",
        "CN_Name": "埃及宝藏",
        "HTML5_Game_ID": "WH28",
        "Button_Image_Name": "WH28.png"
      },
      {
        "EN_Name": "Kabuki Gold",
        "CN_Name": "和风剧院",
        "HTML5_Game_ID": "WH27",
        "Button_Image_Name": "WH27.png"
      },
      {
        "EN_Name": "Fortune Alchemy",
        "CN_Name": "点石成金",
        "HTML5_Game_ID": "WH30",
        "Button_Image_Name": "WH30.png"
      },
      {
        "EN_Name": "Fengshen Yanyi",
        "CN_Name": "封神演义",
        "HTML5_Game_ID": "WH23",
        "Button_Image_Name": "WH23.png"
      },
      {
        "EN_Name": "Festival Lions",
        "CN_Name": "五狮进宝",
        "HTML5_Game_ID": "WH07",
        "Button_Image_Name": "WH07.png"
      },
      {
        "EN_Name": "Joan of Arc",
        "CN_Name": "圣女贞德",
        "HTML5_Game_ID": "WH02",
        "Button_Image_Name": "WH02.png"
      },
      {
        "EN_Name": "Panda's Gold",
        "CN_Name": "发财熊猫",
        "HTML5_Game_ID": "WH12",
        "Button_Image_Name": "WH12.png"
      },
      {
        "EN_Name": "Koi Treasure",
        "CN_Name": "招财锦鲤",
        "HTML5_Game_ID": "WH35",
        "Button_Image_Name": "WH35_ZH.png"
      },
      {
        "EN_Name": "Chinese Zodiac",
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "WH38",
        "Button_Image_Name": "WH38_ZH.png"
      },
      {
        "EN_Name": "Huluwa",
        "CN_Name": "葫芦兄弟",
        "HTML5_Game_ID": "WH20",
        "Button_Image_Name": "WH20_ZH.png"
      },
      {
        "EN_Name": "TanWanLanYue",
        "CN_Name": "贪玩蓝月",
        "HTML5_Game_ID": "WH32",
        "Button_Image_Name": "WH32_ZH.png"
      },
      {
        "EN_Name": "Touchdown Hottie",
        "CN_Name": "内衣橄榄球",
        "HTML5_Game_ID": "WH34",
        "Button_Image_Name": "WH34.png"
      },
      {
        "EN_Name": "Snow White",
        "CN_Name": "白雪公主",
        "HTML5_Game_ID": "WH18",
        "Button_Image_Name": "WH18_ZH.png"
      },
      {
        "EN_Name": "Lucky Combo",
        "CN_Name": "多宝鱼虾蟹",
        "HTML5_Game_ID": "SB55",
        "Button_Image_Name": "SB55_ZH.png"
      },
      {
        "EN_Name": "Happy Jump",
        "CN_Name": "跳跳乐",
        "HTML5_Game_ID": "WC01",
        "Button_Image_Name": "WC01_ZH.png"
      },
      {
        "EN_Name": "The Living Dead",
        "CN_Name": "僵尸来袭",
        "HTML5_Game_ID": "WH24",
        "Button_Image_Name": "jslaixi.png"
      },
      {
        "EN_Name": "Bloody Kisses",
        "CN_Name": "永恒之吻",
        "HTML5_Game_ID": "WH21",
        "Button_Image_Name": "yhzw123.png"
      },
      {
        "EN_Name": "Witch's Quest",
        "CN_Name": "狂野女巫",
        "HTML5_Game_ID": "WH29",
        "Button_Image_Name": "kuangyenv.png"
      },
      {
        "EN_Name": "Dark Joker",
        "CN_Name": "恐怖嘉年华",
        "HTML5_Game_ID": "WH22",
        "Button_Image_Name": "kbjiahua.png"
      },
      {
        "EN_Name": "Wild 777",
        "CN_Name": "百搭777",
        "HTML5_Game_ID": "SC05",
        "Button_Image_Name": "bd777.png"
      },
      {
        "EN_Name": "Diamond Queen",
        "CN_Name": "钻石女王",
        "HTML5_Game_ID": "WA01",
        "Button_Image_Name": "WA01.png"
      },
      {
        "EN_Name": "The Moon Lady",
        "CN_Name": "嫦娥奔月",
        "HTML5_Game_ID": "WH17",
        "Button_Image_Name": "CEBY.png"
      },
      {
        "EN_Name": "Alice's Adventures",
        "CN_Name": "爱丽丝大冒险",
        "HTML5_Game_ID": "WH10",
        "Button_Image_Name": "ALSDMX9.png"
      },
      {
        "EN_Name": "The Storm",
        "CN_Name": "战火风云",
        "HTML5_Game_ID": "WH11",
        "Button_Image_Name": "ZHFY9.png"
      },
      {
        "EN_Name": "King Arthur",
        "CN_Name": "亚瑟王",
        "HTML5_Game_ID": "WH06",
        "Button_Image_Name": "YSW9.png"
      },
      {
        "EN_Name": "Mucha Goddesses",
        "CN_Name": "穆夏女神",
        "HTML5_Game_ID": "WH04",
        "Button_Image_Name": "WH04_ZH.png"
      },
      {
        "EN_Name": "DragonTiger",
        "CN_Name": "龙虎",
        "HTML5_Game_ID": "EP02",
        "Button_Image_Name": "EP02_ZH.png"
      },
      {
        "EN_Name": "Real Champions",
        "CN_Name": "冠军足球",
        "HTML5_Game_ID": "WH03",
        "Button_Image_Name": "WH03_ZH.png"
      },
      {
        "EN_Name": "King of Glory",
        "CN_Name": "王者传说",
        "HTML5_Game_ID": "SB51",
        "Button_Image_Name": "SB51_ZH.png"
      },
      {
        "EN_Name": "Sicbo",
        "CN_Name": "双倍骰宝",
        "HTML5_Game_ID": "EP03",
        "Button_Image_Name": "EP03.png"
      },
      {
        "EN_Name": "Alibaba",
        "CN_Name": "阿里巴巴",
        "HTML5_Game_ID": "WH01",
        "Button_Image_Name": "WH01.png"
      },
      {
        "EN_Name": "Mystic Gems",
        "CN_Name": "神奇宝石",
        "HTML5_Game_ID": "SB47",
        "Button_Image_Name": "SB47_ZH.png"
      },
      {
        "EN_Name": "Mr XIN",
        "CN_Name": "XIN哥来了",
        "HTML5_Game_ID": "SB50",
        "Button_Image_Name": "SB50_ZH.png"
      },
      {
        "EN_Name": "The Dragon Pearl Gold",
        "CN_Name": "金龙珠",
        "HTML5_Game_ID": "SB49",
        "Button_Image_Name": "SB49_ZH.png"
      },
      {
        "EN_Name": "Legend of the Dragon",
        "CN_Name": "猛龙传奇",
        "HTML5_Game_ID": "SB45",
        "Button_Image_Name": "SB45_ZH.png"
      },
      {
        "EN_Name": "Gold Class",
        "CN_Name": "金拉霸",
        "HTML5_Game_ID": "SC03",
        "Button_Image_Name": "SC03_ZH.png"
      },
      {
        "EN_Name": "Street Battle",
        "CN_Name": "街头烈战",
        "HTML5_Game_ID": "SX02",
        "Button_Image_Name": "SX02_ZH.png"
      },
      {
        "EN_Name": "Fantasies",
        "CN_Name": "美女大格斗",
        "HTML5_Game_ID": "XG13",
        "Button_Image_Name": "XG13_ZH.gif"
      },
      {
        "EN_Name": "Moon Festival",
        "CN_Name": "中秋佳节",
        "HTML5_Game_ID": "XG11",
        "Button_Image_Name": "XG11_ZH.gif"
      },
      {
        "EN_Name": "K-Pop",
        "CN_Name": "韩风劲舞",
        "HTML5_Game_ID": "XG12",
        "Button_Image_Name": "XG12_ZH.gif"
      },
      {
        "EN_Name": "Kick Off",
        "CN_Name": "黄金对垒",
        "HTML5_Game_ID": "XG16",
        "Button_Image_Name": "XG16_ZH.gif"
      },
      {
        "EN_Name": "Dragon Boat Festival",
        "CN_Name": "龙舟竞渡",
        "HTML5_Game_ID": "XG10",
        "Button_Image_Name": "XG10_ZH.gif"
      },
      {
        "EN_Name": "Ice Crush",
        "CN_Name": "冰河世界",
        "HTML5_Game_ID": "SB34",
        "Button_Image_Name": "SB34_ZH.gif"
      },
      {
        "EN_Name": "Candy Quest",
        "CN_Name": "糖果碰碰乐",
        "HTML5_Game_ID": "SB33",
        "Button_Image_Name": "SB33_ZH.gif"
      },
      {
        "EN_Name": "Fruit Slot 2",
        "CN_Name": "水果拉霸2",
        "HTML5_Game_ID": "FRU2",
        "Button_Image_Name": "FRU2_ZH.gif"
      },
      {
        "EN_Name": "Shanghai Bund",
        "CN_Name": "上海百乐门",
        "HTML5_Game_ID": "SB37",
        "Button_Image_Name": "SB37_ZH.gif"
      },
      {
        "EN_Name": "Deuces Wild",
        "CN_Name": "百搭二王",
        "HTML5_Game_ID": "PKBD",
        "Button_Image_Name": "PKBD.png"
      },
      {
        "EN_Name": "Bonus Deuces Wild",
        "CN_Name": "红利百搭",
        "HTML5_Game_ID": "PKBB",
        "Button_Image_Name": "PKBB.png"
      },
      {
        "EN_Name": "Dragons Pearl",
        "CN_Name": "龙珠",
        "HTML5_Game_ID": "XG01",
        "Button_Image_Name": "XG01_ZH.gif"
      },
      {
        "EN_Name": "Lucky 8",
        "CN_Name": "幸运8",
        "HTML5_Game_ID": "XG02",
        "Button_Image_Name": "XG02_ZH.gif"
      },
      {
        "EN_Name": "Bling Bling",
        "CN_Name": "闪亮女郎",
        "HTML5_Game_ID": "XG03",
        "Button_Image_Name": "XG03_ZH.gif"
      },
      {
        "EN_Name": "Goldfish",
        "CN_Name": "金鱼",
        "HTML5_Game_ID": "XG04",
        "Button_Image_Name": "XG04_ZH.gif"
      },
      {
        "EN_Name": "Chinese New Year",
        "CN_Name": "中国新年",
        "HTML5_Game_ID": "XG05",
        "Button_Image_Name": "XG05_ZH.gif"
      },
      {
        "EN_Name": "Pirates",
        "CN_Name": "海盗王",
        "HTML5_Game_ID": "XG06",
        "Button_Image_Name": "XG06_ZH.gif"
      },
      {
        "EN_Name": "Fruitmania",
        "CN_Name": "鲜果狂热",
        "HTML5_Game_ID": "XG07",
        "Button_Image_Name": "XG07_ZH.gif"
      },
      {
        "EN_Name": "Red Panda",
        "CN_Name": "小熊猫",
        "HTML5_Game_ID": "XG08",
        "Button_Image_Name": "XG08_ZH.gif"
      },
      {
        "EN_Name": "High Roller",
        "CN_Name": "大豪客",
        "HTML5_Game_ID": "XG09",
        "Button_Image_Name": "XG09_ZH.gif"
      },
      {
        "EN_Name": "Year of the Monkey",
        "CN_Name": "灵猴献瑞",
        "HTML5_Game_ID": "SB30",
        "Button_Image_Name": "SB30_ZH.gif"
      },
      {
        "EN_Name": "God of Wu Fortune",
        "CN_Name": "武财神",
        "HTML5_Game_ID": "SB28",
        "Button_Image_Name": "SB28_ZH.gif"
      },
      {
        "EN_Name": "Chinese Chess Slot",
        "CN_Name": "象棋老虎机",
        "HTML5_Game_ID": "SB07",
        "Button_Image_Name": "SB07_ZH.gif"
      },
      {
        "EN_Name": "Chess Slot",
        "CN_Name": "西洋棋老虎机",
        "HTML5_Game_ID": "SB09",
        "Button_Image_Name": "SB09.png"
      },
      {
        "EN_Name": "Happy Farm",
        "CN_Name": "开心农场",
        "HTML5_Game_ID": "SB10",
        "Button_Image_Name": "SB10_ZH.gif"
      },
      {
        "EN_Name": "Mahjong Slot",
        "CN_Name": "麻将老虎机",
        "HTML5_Game_ID": "SB08",
        "Button_Image_Name": "SB08.png"
      },
      {
        "EN_Name": "Summer Campsite",
        "CN_Name": "夏日营地",
        "HTML5_Game_ID": "SB11",
        "Button_Image_Name": "SB11_ZH.gif"
      },
      {
        "EN_Name": "Space Odyssey",
        "CN_Name": "太空漫游",
        "HTML5_Game_ID": "SB01",
        "Button_Image_Name": "SB01.png"
      },
      {
        "EN_Name": "Samurai",
        "CN_Name": "日本武士",
        "HTML5_Game_ID": "SB06",
        "Button_Image_Name": "SB06_ZH.gif"
      },
      {
        "EN_Name": "Garden Vintage",
        "CN_Name": "复古花园",
        "HTML5_Game_ID": "SB02",
        "Button_Image_Name": "SB02.png"
      },
      {
        "EN_Name": "Video poker2",
        "CN_Name": "杰克高手",
        "HTML5_Game_ID": "PKBJ",
        "Button_Image_Name": "PKBJ_ZH.gif"
      },
      {
        "EN_Name": "Fruit Slot",
        "CN_Name": "水果拉霸",
        "HTML5_Game_ID": "FRU",
        "Button_Image_Name": "YP805.gif"
      },
      {
        "EN_Name": "Forest Dance",
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "YP801",
        "Button_Image_Name": "YP810.gif"
      },
      {
        "EN_Name": "Benz",
        "CN_Name": "奔驰宝马",
        "HTML5_Game_ID": "YP802",
        "Button_Image_Name": "YP802.gif"
      },
      {
        "EN_Name": "Guess",
        "CN_Name": "猜猜乐",
        "HTML5_Game_ID": "YP804",
        "Button_Image_Name": "YP804.gif"
      },
      {
        "EN_Name": "Fruit Slot",
        "CN_Name": "水果拉霸",
        "HTML5_Game_ID": "YP805",
        "Button_Image_Name": "YP805.gif"
      },
      {
        "EN_Name": "Texas Cowboy",
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "YP806",
        "Button_Image_Name": "YP806.gif"
      },
      {
        "EN_Name": "Birds & Beasts",
        "CN_Name": "飞禽走兽",
        "HTML5_Game_ID": "YP807",
        "Button_Image_Name": "YP807.gif"
      },
      {
        "EN_Name": "Fruit Party",
        "CN_Name": "水果派对",
        "HTML5_Game_ID": "YP808",
        "Button_Image_Name": "YP808.gif"
      },
      {
        "EN_Name": "Forest Dance Multiplayer",
        "CN_Name": "森林舞会多人版",
        "HTML5_Game_ID": "YP810",
        "Button_Image_Name": "YP810.gif"
      },
      {
        "EN_Name": "Fruit Slot Multiplayer",
        "CN_Name": "水果拉霸多人版",
        "HTML5_Game_ID": "YP813",
        "Button_Image_Name": "YP813.gif"
      },
      {
        "EN_Name": "Yo Bull Multiplayer",
        "CN_Name": "百人牛牛",
        "HTML5_Game_ID": "YP817",
        "Button_Image_Name": "YP817.gif"
      },
      {
        "EN_Name": "Treasure Fruit Slot",
        "CN_Name": "多宝水果拉霸",
        "HTML5_Game_ID": "YP818",
        "Button_Image_Name": "YP818.gif"
      },
      {
        "EN_Name": "Jackpot Fruit Slot",
        "CN_Name": "彩金水果拉霸",
        "HTML5_Game_ID": "YP820",
        "Button_Image_Name": "YP820.gif"
      },
      {
        "EN_Name": "Birds & Beasts Multiplayer",
        "CN_Name": "飞禽走兽多人版",
        "HTML5_Game_ID": "YP814",
        "Button_Image_Name": "YP814.gif"
      },
      {
        "EN_Name": "Bull Battle Multiplayer",
        "CN_Name": "牛牛对战",
        "HTML5_Game_ID": "YP821",
        "Button_Image_Name": "YP821.gif"
      },
      {
        "EN_Name": "Benz Multiplayer",
        "CN_Name": "奔驰宝马多人版",
        "HTML5_Game_ID": "YP811",
        "Button_Image_Name": "YP811.gif"
      },
      {
        "EN_Name": "Animals Carnival Multiplayer",
        "CN_Name": "动物狂欢",
        "HTML5_Game_ID": "YP823",
        "Button_Image_Name": "YP823.gif"
      },
      {
        "EN_Name": "Western Journey Multiplayer",
        "CN_Name": "西游争霸",
        "HTML5_Game_ID": "YP824",
        "Button_Image_Name": "YP824.gif"
      },
      {
        "EN_Name": "Double Win 3 Cards Multiplayer",
        "CN_Name": "翻倍炸金花",
        "HTML5_Game_ID": "YP822",
        "Button_Image_Name": "YP822.gif"
      },
      {
        "EN_Name": "Battlefield Multiplayer",
        "CN_Name": "刺激战场",
        "HTML5_Game_ID": "YP826",
        "Button_Image_Name": "ymbf.gif"
      },
      {
        "EN_Name": "San Gong Multiplayer",
        "CN_Name": "斗三公",
        "HTML5_Game_ID": "YP825",
        "Button_Image_Name": "YP825.gif"
      },
      {
        "EN_Name": "Double Jokers Multiplayer",
        "CN_Name": "红黑梅方",
        "HTML5_Game_ID": "YP827",
        "Button_Image_Name": "YP827.gif"
      },
      {
        "EN_Name": "Jackpot Treasure World",
        "CN_Name": "彩金宝藏世界",
        "HTML5_Game_ID": "YP828",
        "Button_Image_Name": "YP828.gif"
      },
      {
        "EN_Name": "Crazy Texas Multiplayer",
        "CN_Name": "疯狂德州",
        "HTML5_Game_ID": "YP830",
        "Button_Image_Name": "YP830.gif"
      },
      {
        "EN_Name": "Jackpot Benz",
        "CN_Name": "彩金奔驰宝马",
        "HTML5_Game_ID": "YP831",
        "Button_Image_Name": "YP831.gif"
      },
      {
        "EN_Name": "Sea & Land War Multiplayer",
        "CN_Name": "海陆争霸",
        "HTML5_Game_ID": "YP832",
        "Button_Image_Name": "YP832.gif"
      },
      {
        "EN_Name": "Dragon Dots Multiplayer",
        "CN_Name": "百人推筒子",
        "HTML5_Game_ID": "YP834",
        "Button_Image_Name": "YP834.gif"
      }
    ]
  }, {
    "type": 4,
    "game_id": 411,
    "game_api": "FG",
    "name": "FG老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/pg.png",
    "icon": "fg",
    "game_list_json": "FG_game_list",
    "game_list_img_path": "/new/slot_res/fg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：变形金刚、淘金热、66路、狂欢、埃及、接头霸王、东方国度、西部牛仔..",
    "labels": "#老虎机#变形金刚#淘金热#66路#狂欢#埃及#接头霸王#东方国度#西部牛仔",
    gamelist: [
      {
        "CN_Name": "水果派对",
        "HTML5_Game_ID": "party",
        "EN_Name": "",
        "Button_Image_Name": "party.png"
      },
      {
        "CN_Name": "百变猴子",
        "HTML5_Game_ID": "mcm",
        "EN_Name": "",
        "Button_Image_Name": "mcm.png"
      },
      {
        "CN_Name": "新年",
        "HTML5_Game_ID": "ny",
        "EN_Name": "",
        "Button_Image_Name": "ny.png"
      },
      {
        "CN_Name": "多福多财",
        "HTML5_Game_ID": "dfdc",
        "EN_Name": "",
        "Button_Image_Name": "dfdc.png"
      },
      {
        "CN_Name": "街机水浒传",
        "HTML5_Game_ID": "mawm",
        "EN_Name": "",
        "Button_Image_Name": "mawm.png"
      },
      {
        "CN_Name": "发发发",
        "HTML5_Game_ID": "fff",
        "EN_Name": "",
        "Button_Image_Name": "fff.png"
      },
      {
        "CN_Name": "金瓶梅2",
        "HTML5_Game_ID": "xjpm",
        "EN_Name": "",
        "Button_Image_Name": "xjpm.png"
      },
      {
        "CN_Name": "抢红包2",
        "HTML5_Game_ID": "qhb",
        "EN_Name": "",
        "Button_Image_Name": "qhb.png"
      },

      {"CN_Name": "变形金刚", "HTML5_Game_ID": "bxjg", "EN_Name": "Transformers", "Button_Image_Name": "bxjg.png"},
      {"CN_Name": "摸金校尉", "HTML5_Game_ID": "mjxw", "EN_Name": "Grave Robbers", "Button_Image_Name": "mjxw.png"},
      {"CN_Name": "金刚", "HTML5_Game_ID": "jg", "EN_Name": "King Kong", "Button_Image_Name": "jg.png"},
      {"CN_Name": "淘金热", "HTML5_Game_ID": "tjr", "EN_Name": "Gold Rush", "Button_Image_Name": "tjr.png"},
      {"CN_Name": "66路", "HTML5_Game_ID": "Route66", "EN_Name": "Route 66", "Button_Image_Name": "Route66.png"},
      {"CN_Name": "阿兹特克", "HTML5_Game_ID": "azteke", "EN_Name": "Azteke", "Button_Image_Name": "azteke.png"},
      {"CN_Name": "埃及", "HTML5_Game_ID": "Egypt", "EN_Name": "Egypt", "Button_Image_Name": "Egypt.png"},
      {"CN_Name": "狂欢", "HTML5_Game_ID": "kh", "EN_Name": "Carnival", "Button_Image_Name": "kh.png"},
      {
        "CN_Name": "中世纪特权",
        "HTML5_Game_ID": "zsjtq",
        "EN_Name": "Medieval Thrones",
        "Button_Image_Name": "zsjtq.png"
      },
      {"CN_Name": "大秦帝国", "HTML5_Game_ID": "dqdg", "EN_Name": "The Qin Empire", "Button_Image_Name": "dqdg.png"},
      {"CN_Name": "火影忍者", "HTML5_Game_ID": "hyrz", "EN_Name": "Naruto", "Button_Image_Name": "hyrz.png"},
      {"CN_Name": "欢乐水果", "HTML5_Game_ID": "sg", "EN_Name": "Joyful Fruit", "Button_Image_Name": "sg.png"},
      {"CN_Name": "街头霸王", "HTML5_Game_ID": "jtbw", "EN_Name": "Street Fighter", "Button_Image_Name": "jtbw.png"},
      {"CN_Name": "战舰少女", "HTML5_Game_ID": "zjsn", "EN_Name": "Warship Maiden", "Button_Image_Name": "zjsn.png"},
      {"CN_Name": "东方国度", "HTML5_Game_ID": "zgf", "EN_Name": "Eastern Countries", "Button_Image_Name": "zgf.png"},
      {"CN_Name": "西部牛仔", "HTML5_Game_ID": "nz", "EN_Name": "Western Cowboy", "Button_Image_Name": "nz.png"},
      {"CN_Name": "幻想大陆", "HTML5_Game_ID": "hxdl", "EN_Name": "Fantasy Earth", "Button_Image_Name": "hxdl.png"},
      {"CN_Name": "拉斯维加斯", "HTML5_Game_ID": "wjs", "EN_Name": "Las Vegas", "Button_Image_Name": "wjs.png"},
      {
        "CN_Name": "荒野大镖客",
        "HTML5_Game_ID": "nz2",
        "EN_Name": "Wilderness Escort",
        "Button_Image_Name": "nz2.png"
      },
      {"CN_Name": "埃及艳后", "HTML5_Game_ID": "mny", "EN_Name": "Cleopatra", "Button_Image_Name": "mny.png"},
      {"CN_Name": "仙剑", "HTML5_Game_ID": "xj", "EN_Name": "Xian Jian", "Button_Image_Name": "xj.png"},
      {"CN_Name": "梦游仙境", "HTML5_Game_ID": "myxj", "EN_Name": "Alice", "Button_Image_Name": "myxj.png"},
      {
        "CN_Name": "80天旅行",
        "HTML5_Game_ID": "80tlx",
        "EN_Name": "80 Days Travel",
        "Button_Image_Name": "80tlx.png"
      },
      {"CN_Name": "海盗财富", "HTML5_Game_ID": "hdcf", "EN_Name": "Pirates Fortune", "Button_Image_Name": "hdcf.png"},
      {"CN_Name": "西游", "HTML5_Game_ID": "xy", "EN_Name": "Xi You", "Button_Image_Name": "xy.png"},
      {"CN_Name": "白蛇传", "HTML5_Game_ID": "bsz", "EN_Name": "Bai She Zhuan", "Button_Image_Name": "bsz.png"},
      {"CN_Name": "指环王", "HTML5_Game_ID": "zhw", "EN_Name": "Lord Of The Ring", "Button_Image_Name": "zhw.png"},
      {"CN_Name": "封神榜", "HTML5_Game_ID": "fsb", "EN_Name": "Feng Shen Bang", "Button_Image_Name": "fsb.png"},
      {"CN_Name": "荣耀王者", "HTML5_Game_ID": "rywz", "EN_Name": "King Of Glory", "Button_Image_Name": "rywz.png"},
      {"CN_Name": "怪物命运", "HTML5_Game_ID": "gwmy", "EN_Name": "Freaks Fortune", "Button_Image_Name": "gwmy.png"},
      {"CN_Name": "埃菲尔", "HTML5_Game_ID": "Eiffel", "EN_Name": "Eiffel", "Button_Image_Name": "Eiffel.png"},
      {
        "CN_Name": "新年符号",
        "HTML5_Game_ID": "Nysymbols",
        "EN_Name": "Ny Symbols",
        "Button_Image_Name": "Nysymbols.png"
      },
      {
        "CN_Name": "性感女神",
        "HTML5_Game_ID": "Pinup",
        "EN_Name": "Marilyn Monroe",
        "Button_Image_Name": "Pinup.png"
      },
      {
        "CN_Name": "世界末日",
        "HTML5_Game_ID": "Doomsday",
        "EN_Name": "Doomsday",
        "Button_Image_Name": "Doomsday.png"
      },
      {"CN_Name": "现代战争", "HTML5_Game_ID": "Mowf", "EN_Name": "Modern War", "Button_Image_Name": "Mowf.png"},
      {
        "CN_Name": "甜水绿洲",
        "HTML5_Game_ID": "Lushwater",
        "EN_Name": "Sweet Water Oasis",
        "Button_Image_Name": "Lushwater.png"
      },
      {
        "CN_Name": "加勒比海盗",
        "HTML5_Game_ID": "Pirates",
        "EN_Name": "Caribbean Pirates",
        "Button_Image_Name": "Pirates.png"
      },
      {"CN_Name": "功夫熊猫", "HTML5_Game_ID": "Panda", "EN_Name": "Kung Fu Panda", "Button_Image_Name": "Panda.png"},
      {"CN_Name": "侏罗纪", "HTML5_Game_ID": "Jurassic", "EN_Name": "Jurassic", "Button_Image_Name": "Jurassic.png"},
      {"CN_Name": "植物大战僵尸", "HTML5_Game_ID": "PVZ", "EN_Name": "PVZ", "Button_Image_Name": "PVZ.png"},
      {"CN_Name": "守望英雄", "HTML5_Game_ID": "Watch", "EN_Name": "Overwatch", "Button_Image_Name": "Watch.png"},
      {"CN_Name": "阿凡达", "HTML5_Game_ID": "Avatar", "EN_Name": "Avatar", "Button_Image_Name": "Avatar.png"},
      {"CN_Name": "希腊传说", "HTML5_Game_ID": "xlcs", "EN_Name": "Greek Legend", "Button_Image_Name": "xlcs.png"},
      {
        "CN_Name": "吸血鬼PK狼人",
        "HTML5_Game_ID": "xxgpklr",
        "EN_Name": "Bloodz VS Wolvez",
        "Button_Image_Name": "xxgpklr.png"
      },
      {"CN_Name": "梦幻西游", "HTML5_Game_ID": "mhxy", "EN_Name": "Dream Journey", "Button_Image_Name": "mhxy.png"},
      {
        "CN_Name": "女校足球队",
        "HTML5_Game_ID": "nxzqd",
        "EN_Name": "Girls Football",
        "Button_Image_Name": "nxzqd.png"
      },
      {"CN_Name": "女校橄榄球", "HTML5_Game_ID": "nxglq", "EN_Name": "Girls Rugby", "Button_Image_Name": "nxglq.png"},
      {
        "CN_Name": "女校剑道部",
        "HTML5_Game_ID": "nxjdb",
        "EN_Name": "Girls Kendo Club",
        "Button_Image_Name": "nxjdb.png"
      },
      {
        "CN_Name": "武圣关云长",
        "HTML5_Game_ID": "wsgyc",
        "EN_Name": "Guan Yun Chang",
        "Button_Image_Name": "wsgyc.png"
      },
      {"CN_Name": "刺客信条", "HTML5_Game_ID": "ckxt", "EN_Name": "Assassins Creed", "Button_Image_Name": "ckxt.png"},
      {"CN_Name": "古墓丽影", "HTML5_Game_ID": "gmly", "EN_Name": "Tomb Raider", "Button_Image_Name": "gmly.png"},
      {
        "CN_Name": "绿野仙踪",
        "HTML5_Game_ID": "lyxz",
        "EN_Name": "The Wizard Of Oz",
        "Button_Image_Name": "lyxz.png"
      },
      {"CN_Name": "剑侠情缘", "HTML5_Game_ID": "jxqy", "EN_Name": "Sword Heroes", "Button_Image_Name": "jxqy.png"},
      {
        "CN_Name": "格斗之王",
        "HTML5_Game_ID": "gdzw",
        "EN_Name": "King Of Fighters",
        "Button_Image_Name": "gdzw.png"
      },
      {"CN_Name": "黑珍珠号", "HTML5_Game_ID": "hzzh", "EN_Name": "Black Pearl", "Button_Image_Name": "hzzh.png"},
      {"CN_Name": "富饶农场", "HTML5_Game_ID": "frnc", "EN_Name": "Rich Farm", "Button_Image_Name": "frnc.png"},
      {"CN_Name": "玛雅", "HTML5_Game_ID": "my", "EN_Name": "Maya", "Button_Image_Name": "my.png"},
      {"CN_Name": "印第安", "HTML5_Game_ID": "yda", "EN_Name": "India Dreams", "Button_Image_Name": "yda.png"},
      {"CN_Name": "神秘东方", "HTML5_Game_ID": "smdf", "EN_Name": "Mysterious East", "Button_Image_Name": "smdf.png"},
      {"CN_Name": "战争", "HTML5_Game_ID": "zz", "EN_Name": "Tanks", "Button_Image_Name": "zz.png"},
      {"CN_Name": "哪吒闹海", "HTML5_Game_ID": "nznh", "EN_Name": "Ne Zha Nao Hai", "Button_Image_Name": "nznh.png"},
      {"CN_Name": "七夕情缘", "HTML5_Game_ID": "qxqy", "EN_Name": "Tanabata Love", "Button_Image_Name": "qxqy.png"},
      {"CN_Name": "四大美女", "HTML5_Game_ID": "sdmn", "EN_Name": "Four Beauties", "Button_Image_Name": "sdmn.png"},
      {"CN_Name": "金瓶梅", "HTML5_Game_ID": "jpm", "EN_Name": "Jin Ping Mei", "Button_Image_Name": "jpm.png"},
      {"CN_Name": "红楼梦", "HTML5_Game_ID": "hlm", "EN_Name": "Hong Lou Meng", "Button_Image_Name": "hlm.png"},
      {"CN_Name": "武松打虎", "HTML5_Game_ID": "wsdf", "EN_Name": "Wu Song Da Hu", "Button_Image_Name": "wsdf.png"},
      {"CN_Name": "女校游泳队", "HTML5_Game_ID": "sst", "EN_Name": "Girls Swim Team", "Button_Image_Name": "sst.png"},
      {"CN_Name": "女校啦啦队", "HTML5_Game_ID": "scs", "EN_Name": "Cheerleaders", "Button_Image_Name": "scs.png"},
      {"CN_Name": "女校体操队", "HTML5_Game_ID": "sgt", "EN_Name": "Girls Gymnastics", "Button_Image_Name": "sgt.png"},
      {
        "CN_Name": "天龙八部",
        "HTML5_Game_ID": "wtlbb",
        "EN_Name": "Tian Long Ba Bu",
        "Button_Image_Name": "wtlbb.png"
      },
      {"CN_Name": "鹿鼎记", "HTML5_Game_ID": "wldj", "EN_Name": "Lu Ding Ji", "Button_Image_Name": "wldj.png"},
      {"CN_Name": "笑傲江湖", "HTML5_Game_ID": "wxajh", "EN_Name": "Swordsman", "Button_Image_Name": "wxajh.png"},
      {
        "CN_Name": "神雕侠侣",
        "HTML5_Game_ID": "wsdxl",
        "EN_Name": "Shen Diao Xia Lv",
        "Button_Image_Name": "wsdxl.png"
      },
      {"CN_Name": "幸运水果机", "HTML5_Game_ID": "mlfm", "EN_Name": "Lucky Fruit", "Button_Image_Name": "mlfm.png"},
      {"CN_Name": "钻石之恋", "HTML5_Game_ID": "mdl", "EN_Name": "Diamond Love", "Button_Image_Name": "mdl.png"},
      {"CN_Name": "众神之王", "HTML5_Game_ID": "mmm", "EN_Name": "Zeus", "Button_Image_Name": "mmm.png"},
      {"CN_Name": "粉红女郎", "HTML5_Game_ID": "mpg", "EN_Name": "Pink Lady", "Button_Image_Name": "mpg.png"},
      {"CN_Name": "鸟叔", "HTML5_Game_ID": "mpjs", "EN_Name": "PSY", "Button_Image_Name": "mpjs.png"},
      {
        "CN_Name": "十二星座",
        "HTML5_Game_ID": "Constellation",
        "EN_Name": "Constellation",
        "Button_Image_Name": "Constellation.png"
      },
      {
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "Zodiac",
        "EN_Name": "Chinese Zodiac",
        "Button_Image_Name": "Zodiac.png"
      },
      {
        "CN_Name": "愤怒的小鸟",
        "HTML5_Game_ID": "Angrybirds",
        "EN_Name": "Angry Birds",
        "Button_Image_Name": "Angrybirds.png"
      },
      {
        "CN_Name": "捕鱼达人",
        "HTML5_Game_ID": "Fishing",
        "EN_Name": "Fishing Joy",
        "Button_Image_Name": "Fishing.png"
      },
      {"CN_Name": "瑞狗迎春", "HTML5_Game_ID": "rgyc", "EN_Name": "Lucky Dog", "Button_Image_Name": "rgyc.png"},
      {"CN_Name": "哥谭魅影猫女", "HTML5_Game_ID": "gtmymn", "EN_Name": "Catwoman", "Button_Image_Name": "gtmymn.png"},
      {
        "CN_Name": "招财进宝",
        "HTML5_Game_ID": "zcjb",
        "EN_Name": "Zhao Cai Jin Bao",
        "Button_Image_Name": "zcjb.png"
      },
      {"CN_Name": "湛蓝深海", "HTML5_Game_ID": "xsjr", "EN_Name": "Great Blue", "Button_Image_Name": "xsjr.png"},
      {
        "CN_Name": "金狗旺财",
        "HTML5_Game_ID": "jgwc",
        "EN_Name": "Jin Gou Wang Cai",
        "Button_Image_Name": "jgwc.png"
      },
      {
        "CN_Name": "恭贺新春",
        "HTML5_Game_ID": "ghxc",
        "EN_Name": "Gong He Xin Chun",
        "Button_Image_Name": "ghxc.png"
      },
      {"CN_Name": "角斗士", "HTML5_Game_ID": "jiaods", "EN_Name": "Gladiatus", "Button_Image_Name": "jiaods.png"},
      {"CN_Name": "埃及女王", "HTML5_Game_ID": "aijinw", "EN_Name": "Egypt Queen", "Button_Image_Name": "aijinw.png"},
      {"CN_Name": "灌篮高手", "HTML5_Game_ID": "msd", "EN_Name": "Slamdunk", "Button_Image_Name": "msd.png"},
      {"CN_Name": "抢红包", "HTML5_Game_ID": "mqhb", "EN_Name": "Qiang Hong Bao", "Button_Image_Name": "mqhb.png"},
      {"CN_Name": "闹元宵", "HTML5_Game_ID": "mnys", "EN_Name": "Lantern Festival", "Button_Image_Name": "mnys.png"},
      {"CN_Name": "森林舞会", "HTML5_Game_ID": "mslwh", "EN_Name": "Forest Dance", "Button_Image_Name": "mslwh.png"},
      {"CN_Name": "金球争霸", "HTML5_Game_ID": "jqzb", "EN_Name": "Golden Globe", "Button_Image_Name": "jqzb.png"},
      {"CN_Name": "黄金右脚", "HTML5_Game_ID": "hjyj", "EN_Name": "Golden Foot", "Button_Image_Name": "hjyj.png"},
      {
        "CN_Name": "世界杯吉祥物",
        "HTML5_Game_ID": "sjbjxw",
        "EN_Name": "Fifa World",
        "Button_Image_Name": "sjbjxw.png"
      },
      {
        "CN_Name": "潘帕斯雄鹰",
        "HTML5_Game_ID": "panpasixiongying",
        "EN_Name": "Argentina",
        "Button_Image_Name": "panpasixiongying.png"
      },
      {
        "CN_Name": "群星闪耀",
        "HTML5_Game_ID": "qunxshanyao",
        "EN_Name": "Stars Shine",
        "Button_Image_Name": "qunxshanyao.png"
      },
      {
        "CN_Name": "金靴争霸",
        "HTML5_Game_ID": "mjxzb",
        "EN_Name": "The Golden Boot",
        "Button_Image_Name": "mjxzb.png"
      },
      {"CN_Name": "激情球迷", "HTML5_Game_ID": "mjqqm", "EN_Name": "Passion Fans", "Button_Image_Name": "mjqqm.png"},
      {"CN_Name": "激情世界杯", "HTML5_Game_ID": "mjqsjb", "EN_Name": "World Cup", "Button_Image_Name": "mjqsjb.png"},
      {"CN_Name": "人猿泰山", "HTML5_Game_ID": "ryts", "EN_Name": "Tarzan", "Button_Image_Name": "ryts.png"},
      {
        "CN_Name": "船长宝藏",
        "HTML5_Game_ID": "mczbz",
        "EN_Name": "Captain's Treasure",
        "Button_Image_Name": "mczbz.png"
      },
      {"CN_Name": "疯狂七", "HTML5_Game_ID": "mcrazy7", "EN_Name": "Crazy7", "Button_Image_Name": "mcrazy7.png"},
      {"CN_Name": "鹊桥会", "HTML5_Game_ID": "qqh", "EN_Name": "Magpie Bridge", "Button_Image_Name": "qqh.png"},
      {
        "CN_Name": "泰坦尼克号",
        "HTML5_Game_ID": "mtitanic",
        "EN_Name": "Titanic",
        "Button_Image_Name": "mtitanic.png"
      },
      {"CN_Name": "蜘蛛侠", "HTML5_Game_ID": "zzx", "EN_Name": "Spider Man", "Button_Image_Name": "zzx.png"},
      {
        "CN_Name": "速度与激情",
        "HTML5_Game_ID": "fastf",
        "EN_Name": "Fast & Furious",
        "Button_Image_Name": "fastf.png"
      },
      {"CN_Name": "古怪猴子", "HTML5_Game_ID": "gghz", "EN_Name": "Funky Monkey", "Button_Image_Name": "gghz.png"},
      {
        "CN_Name": "海岛奇兵",
        "HTML5_Game_ID": "mboombeach",
        "EN_Name": "BoomBeach",
        "Button_Image_Name": "mboombeach.png"
      },
      {
        "CN_Name": "美人捕鱼",
        "HTML5_Game_ID": "fish_mm",
        "EN_Name": "Beauty Fishing",
        "Button_Image_Name": "fish_mm.png"
      },
      {"CN_Name": "雷霆战警", "HTML5_Game_ID": "fish_zj", "EN_Name": "X-Men", "Button_Image_Name": "fish_zj.png"},
      {
        "CN_Name": "捕鸟达人",
        "HTML5_Game_ID": "fish_bn",
        "EN_Name": "Birds Hunter",
        "Button_Image_Name": "fish_bn.png"
      },
      {"CN_Name": "欢乐捕鱼", "HTML5_Game_ID": "fish_hl", "EN_Name": "Fish Reef", "Button_Image_Name": "fish_hl.png"},
      {
        "CN_Name": "天天捕鱼",
        "HTML5_Game_ID": "fish_tt",
        "EN_Name": "Daily Fishing",
        "Button_Image_Name": "fish_tt.png"
      },
      {
        "CN_Name": "捕鱼嘉年华3D",
        "HTML5_Game_ID": "fish_3D",
        "EN_Name": "Power fishing 3D",
        "Button_Image_Name": "fish_3D.png"
      },
      {"CN_Name": "百人牛牛", "HTML5_Game_ID": "HB", "EN_Name": "Hundreds Of Bull", "Button_Image_Name": "HB.png"},
      // {"CN_Name": "皇家德州", "HTML5_Game_ID": "rt", "EN_Name": "Royal Texas", "Button_Image_Name": "rt.png"},
      {"CN_Name": "骰宝", "HTML5_Game_ID": "tz", "EN_Name": "Sic Bo", "Button_Image_Name": "tz.png"},
      {"CN_Name": "百家乐", "HTML5_Game_ID": "bjl", "EN_Name": "Baccarat", "Button_Image_Name": "bjl.png"},
      // {"CN_Name": "皇家宫殿", "HTML5_Game_ID": "RC", "EN_Name": "Royal Palace", "Button_Image_Name": "RC.png"},
      {
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "TexasCb",
        "EN_Name": "Texas Cowboy",
        "Button_Image_Name": "TexasCb.png"
      },
      {"CN_Name": "二人麻将", "HTML5_Game_ID": "mj", "EN_Name": "Two People Mahjong", "Button_Image_Name": "mj.png"},
      {
        "CN_Name": "抢庄牛牛",
        "HTML5_Game_ID": "cqznn",
        "EN_Name": "Rob Banker Of Bull",
        "Button_Image_Name": "cqznn.png"
      },
      {"CN_Name": "三公", "HTML5_Game_ID": "csg", "EN_Name": "San_Gong", "Button_Image_Name": "csg.png"},
      {"CN_Name": "斗地主", "HTML5_Game_ID": "ddz", "EN_Name": "Landlord", "Button_Image_Name": "ddz.png"},
      {
        "CN_Name": "经典炸金花",
        "HTML5_Game_ID": "zjhdr",
        "EN_Name": "Classical Win Three Cards",
        "Button_Image_Name": "zjhdr.png"
      },
      {
        "CN_Name": "梭哈",
        "HTML5_Game_ID": "ShowHand",
        "EN_Name": "Five-Card Poker",
        "Button_Image_Name": "ShowHand.png"
      },
      {"CN_Name": "通比牛牛", "HTML5_Game_ID": "tbnn", "EN_Name": "Tongbi Cattle", "Button_Image_Name": "tbnn.png"},
      // {
      //   "CN_Name": "十三水",
      //   "HTML5_Game_ID": "sss",
      //   "EN_Name": "Open-face Chinese Poker",
      //   "Button_Image_Name": "sss.png"
      // },
      {"CN_Name": "二八杠", "HTML5_Game_ID": "ebg", "EN_Name": "Mahjong", "Button_Image_Name": "ebg.png"},
      {"CN_Name": "抢庄牌九", "HTML5_Game_ID": "qzpj", "EN_Name": "Pai Gow", "Button_Image_Name": "qzpj.png"},
      // {
      //   "CN_Name": "极速炸金花",
      //   "HTML5_Game_ID": "jszjh",
      //   "EN_Name": "Topspeed Win Three Cards",
      //   "Button_Image_Name": "jszjh.png"
      // },
      {"CN_Name": "欢乐德州", "HTML5_Game_ID": "iPoker", "EN_Name": "Fun Poker", "Button_Image_Name": "iPoker.png"},
      {
        "CN_Name": "欢乐红包",
        "HTML5_Game_ID": "hlhb",
        "EN_Name": "Happy red envelopes",
        "Button_Image_Name": "hlhb.png"
      },
      {"CN_Name": "欢乐麻将", "HTML5_Game_ID": "xzdd", "EN_Name": "Happy mahjong", "Button_Image_Name": "xzdd.png"},
      {"CN_Name": "水果机", "HTML5_Game_ID": "frt", "EN_Name": "Fruit", "Button_Image_Name": "frt.png"},
      {"CN_Name": "飞禽走兽", "HTML5_Game_ID": "bbs", "EN_Name": "Birds And Beasts", "Button_Image_Name": "bbs.png"},
      {"CN_Name": "奔驰宝马", "HTML5_Game_ID": "bzb", "EN_Name": "Benz And BMW", "Button_Image_Name": "bzb.png"},
      {"CN_Name": "森林舞会", "HTML5_Game_ID": "fod", "EN_Name": "Forest Dance", "Button_Image_Name": "fod.png"},
      {"CN_Name": "十二星座", "HTML5_Game_ID": "con", "EN_Name": "Constellation", "Button_Image_Name": "con1.png"},
      {"CN_Name": "十二生肖", "HTML5_Game_ID": "chz", "EN_Name": "Chinese Zodiac", "Button_Image_Name": "chz.png"},
      // {
      //     "CN_Name": "燃烧吧，足球",
      //     "HTML5_Game_ID": "bif",
      //     "EN_Name": "Burn It Football",
      //     "Button_Image_Name": "bif.png"
      // },
      {"CN_Name": "黄金大转轮", "HTML5_Game_ID": "gw", "EN_Name": "Golden Whell", "Button_Image_Name": "gw.png"},
      {"CN_Name": "水果转盘", "HTML5_Game_ID": "fw", "EN_Name": "Fruit Turntable", "Button_Image_Name": "fw.png"},
      {"CN_Name": "赛马", "HTML5_Game_ID": "hr", "EN_Name": "Horse Race", "Button_Image_Name": "hr.png"},
      {"CN_Name": "连环夺宝", "HTML5_Game_ID": "si", "EN_Name": "Serial Indiana", "Button_Image_Name": "si.png"},
      {"CN_Name": "幸运5", "HTML5_Game_ID": "lucky", "EN_Name": "Lucky 5", "Button_Image_Name": "lucky.png"},
      // {"CN_Name": "好运射击", "HTML5_Game_ID": "gls", "EN_Name": "Luck Shooting", "Button_Image_Name": "gls.png"},
      {"CN_Name": "猴子爬树", "HTML5_Game_ID": "mr", "EN_Name": "Monkey Race", "Button_Image_Name": "mr.png"},
      {"CN_Name": "浮岛历险记", "HTML5_Game_ID": "fia", "EN_Name": "Adventures", "Button_Image_Name": "fia.png"},
      {"CN_Name": "水果传奇", "HTML5_Game_ID": "fl", "EN_Name": "Fruit Legend", "Button_Image_Name": "fl.png"},
      {
        "CN_Name": "萌宠夺宝",
        "HTML5_Game_ID": "api",
        "EN_Name": "Adorable Pet Indiana",
        "Button_Image_Name": "api.png"
      },
      // {
      //     "CN_Name": "王者足球",
      //     "HTML5_Game_ID": "tkof",
      //     "EN_Name": "The King Of Football",
      //     "Button_Image_Name": "tkof.png"
      // },
      {
        "CN_Name": "欢乐麻将3D",
        "HTML5_Game_ID": "hlmj3D",
        "EN_Name": "Adorable Pet Indiana",
        "Button_Image_Name": "6702.png"
      },
      // {
      //   "CN_Name": "抢庄牛牛3D",
      //   "HTML5_Game_ID": "qznn3d",
      //   "EN_Name": "Adorable Pet Indiana",
      //   "Button_Image_Name": "6701.png"
      // },
    ]
  }, {
    "type": 4,
    "game_id": 414,
    "game_api": "HCDZ",
    "name": "GB老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/hcdz.png",
    "icon": "logo-hcdz",
    "game_list_json": "HCDZ_game_list",
    "game_list_img_path": "/new/slot_res/hcdz/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：富贵吉祥,爆骰,鱼虾蟹,糖果世界,宝石大亨,欢乐地鼠,齐天大圣,圣域传说",
    "labels": "",
  }, {
    "type": 4,
    "game_id": 408,
    "game_api": "BBIN",
    "name": "BB老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/bbin.png",
    "icon": "logo-bbin",
    "game_list_json": "BBIN_game_list",
    "game_list_img_path": "/new/slot_res/bbin/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：魔光幻音、熊猫乐园、糖果派对、连环夺宝、多福多财、斗鸡、趣味台球...",
    "labels": "#老虎机#魔光幻音#熊猫乐园#糖果派对#连环夺宝#多福多财#斗鸡#趣味台球",
    gamelist: [
      {"EN_Name": "Chinese Mammon", "CN_Name": "幸运财神", "HTML5_Game_ID": "5030", "Button_Image_Name": "5030.png"},
      {"EN_Name": "3D Animal Party", "CN_Name": "森林舞会", "HTML5_Game_ID": "5045", "Button_Image_Name": "5045.png"},
      {"EN_Name": "Crazy Fruit", "CN_Name": "疯狂水果盘", "HTML5_Game_ID": "5058", "Button_Image_Name": "5058.png"},
      {"EN_Name": "A Chinese Odyssey", "CN_Name": "大话西游", "HTML5_Game_ID": "5067", "Button_Image_Name": "5067.png"},
      {"EN_Name": "Lucky Number", "CN_Name": "数字大转轮", "HTML5_Game_ID": "5076", "Button_Image_Name": "5076.png"},
      {"EN_Name": "3D Lucky Number", "CN_Name": "3D数字大转轮", "HTML5_Game_ID": "5079", "Button_Image_Name": "5079.png"},
      {"EN_Name": "Jin Ping Mai 2", "CN_Name": "金瓶梅2", "HTML5_Game_ID": "5094", "Button_Image_Name": "5094.png"},
      {"EN_Name": "Wu Xing", "CN_Name": "五行", "HTML5_Game_ID": "5096", "Button_Image_Name": "5096.png"},
      {"EN_Name": "WUFU LINMEN", "CN_Name": "五福临门", "HTML5_Game_ID": "5098", "Button_Image_Name": "5098.png"},
      {"EN_Name": "Good Fortune", "CN_Name": "多福多财", "HTML5_Game_ID": "5128", "Button_Image_Name": "5128.png"},
      {"EN_Name": "Candy Party3", "CN_Name": "糖果派对3", "HTML5_Game_ID": "5143", "Button_Image_Name": "5143.png"},
      {"EN_Name": "Jiu Wei Hu", "CN_Name": "九尾狐", "HTML5_Game_ID": "5153", "Button_Image_Name": "5153.png"},
      {"EN_Name": "Fire Of Huluwa", "CN_Name": "葫芦娃", "HTML5_Game_ID": "5166", "Button_Image_Name": "5166.png"},
      {"EN_Name": "Candy Party-Fast", "CN_Name": "糖果派对-极速版", "HTML5_Game_ID": "5171", "Button_Image_Name": "5171.png"},
      {"EN_Name": "ALL STARS", "CN_Name": "满天星", "HTML5_Game_ID": "5175", "Button_Image_Name": "5175.png"},
      {"EN_Name": "TikTok DJ", "CN_Name": "抖音DJ", "HTML5_Game_ID": "5182", "Button_Image_Name": "5182.png"},
      {"EN_Name": "Carp Turn Dragon", "CN_Name": "鱼跃龙门", "HTML5_Game_ID": "5184", "Button_Image_Name": "5184.png"},
      {"EN_Name": "Rich Harvest", "CN_Name": "大丰收", "HTML5_Game_ID": "5185", "Button_Image_Name": "5185.png"},
      {"EN_Name": "Piggy Bank", "CN_Name": "猪宝满满", "HTML5_Game_ID": "5191", "Button_Image_Name": "5191.png"},
      {"EN_Name": "Candy Bar", "CN_Name": "糖果吧", "HTML5_Game_ID": "5193", "Button_Image_Name": "5193.png"},
      {"EN_Name": "The Legend of Halong Bay", "CN_Name": "下龙湾神话", "HTML5_Game_ID": "5198", "Button_Image_Name": "5198.png"},
      {"EN_Name": "Hoo Hey How", "CN_Name": "鱼虾蟹开了", "HTML5_Game_ID": "5200", "Button_Image_Name": "5200.png"},
      {"EN_Name": "SAFARI", "CN_Name": "草原拍拍", "HTML5_Game_ID": "5209", "Button_Image_Name": "5209.png"},
      {"EN_Name": "Candy Pop", "CN_Name": "糖果缤纷乐", "HTML5_Game_ID": "5210", "Button_Image_Name": "5210.png"},
      {"EN_Name": "Golden Toad", "CN_Name": "富贵金蟾", "HTML5_Game_ID": "5213", "Button_Image_Name": "5213.png"},
      {"EN_Name": "BEIJING OPERA", "CN_Name": "今晚有戏", "HTML5_Game_ID": "5215", "Button_Image_Name": "5215.png"},
      {"EN_Name": "Mermaid", "CN_Name": "人鱼秘宝", "HTML5_Game_ID": "5216", "Button_Image_Name": "5216.png"},
      {"EN_Name": "Diamond Fortune", "CN_Name": "任你钻", "HTML5_Game_ID": "5219", "Button_Image_Name": "5219.png"},
      {"EN_Name": "Spider Spirits", "CN_Name": "极乐盘丝洞", "HTML5_Game_ID": "5220", "Button_Image_Name": "5220.png"},
      {"EN_Name": "Crazy Jam Jar", "CN_Name": "疯狂果酱罐", "HTML5_Game_ID": "5222", "Button_Image_Name": "5222.png"},
      {"EN_Name": "Chibi", "CN_Name": "火烧连环船", "HTML5_Game_ID": "5225", "Button_Image_Name": "5225.png"},
      {"EN_Name": "Elimination 1024", "CN_Name": "连消1024", "HTML5_Game_ID": "5226", "Button_Image_Name": "5226.png"},
      {"EN_Name": "Treasure Pot Puzzle", "CN_Name": "聚宝消消乐", "HTML5_Game_ID": "5231", "Button_Image_Name": "5231.png"},
      {"EN_Name": "Awesome Buffalo", "CN_Name": "超牛逼", "HTML5_Game_ID": "5234", "Button_Image_Name": "5234.png"},
      {"EN_Name": "Lions Find Treasure", "CN_Name": "舞狮夺宝", "HTML5_Game_ID": "5242", "Button_Image_Name": "5242.png"},
      {"EN_Name": "Too Rich", "CN_Name": "太有财", "HTML5_Game_ID": "5243", "Button_Image_Name": "5243.png"},
      {"EN_Name": "Go!Go! Somersault Cloud", "CN_Name": "Go!Go!筋斗云", "HTML5_Game_ID": "5912", "Button_Image_Name": "5912.png"},
      {"EN_Name": "Unlimited 1024", "CN_Name": "无限1024", "HTML5_Game_ID": "5247", "Button_Image_Name": "5247.png"},
      {"EN_Name": "Minecart", "CN_Name": "采矿飞车", "HTML5_Game_ID": "5250", "Button_Image_Name": "5250.png"},
      {"EN_Name": "Clearance", "CN_Name": "一杆清台", "HTML5_Game_ID": "5251", "Button_Image_Name": "5251.png"},
      {"EN_Name": "Leprechaun", "CN_Name": "矮精灵", "HTML5_Game_ID": "5252", "Button_Image_Name": "5252.png"},
      {"EN_Name": "Parvenu", "CN_Name": "爆发富", "HTML5_Game_ID": "5253", "Button_Image_Name": "5253.png"},
      {"EN_Name": "Firecrackers", "CN_Name": "爆爆生财", "HTML5_Game_ID": "5256", "Button_Image_Name": "5256.png"},
      {"EN_Name": "IP MAN", "CN_Name": "叶问", "HTML5_Game_ID": "5257", "Button_Image_Name": "5257.png"},
      {"EN_Name": "Gold King Kong", "CN_Name": "爆利金刚", "HTML5_Game_ID": "5259", "Button_Image_Name": "5259.png"},
      {"EN_Name": "Fortune Cat", "CN_Name": "招财喵喵", "HTML5_Game_ID": "5261", "Button_Image_Name": "5261.png"},
      {"EN_Name": "Dragon 888", "CN_Name": "金龙发发发", "HTML5_Game_ID": "5264", "Button_Image_Name": "5264.png"},
      {"EN_Name": "Beauty Party", "CN_Name": "AV派对", "HTML5_Game_ID": "5267", "Button_Image_Name": "5267.png"},
      {"EN_Name": "Twins Wild", "CN_Name": "百搭双星", "HTML5_Game_ID": "5268", "Button_Image_Name": "5268.png"},
      {"EN_Name": "Big Wings", "CN_Name": "大鹏展翅", "HTML5_Game_ID": "5269", "Button_Image_Name": "5269.png"},
      {"EN_Name": "Crash", "CN_Name": "Crash", "HTML5_Game_ID": "5270", "Button_Image_Name": "5270.png"},
      {"EN_Name": "Lucky Dog", "CN_Name": "财犬", "HTML5_Game_ID": "5272", "Button_Image_Name": "5272.png"},
      {"EN_Name": "Hello Tiki", "CN_Name": "Hello Tiki", "HTML5_Game_ID": "5273", "Button_Image_Name": "5273.png"},
      {"EN_Name": "Zeus Rise", "CN_Name": "财富宙升", "HTML5_Game_ID": "5274", "Button_Image_Name": "5274.png"},
      {"EN_Name": "Mahjong Ways", "CN_Name": "麻将胡了", "HTML5_Game_ID": "5275", "Button_Image_Name": "5275.png"},
      {"EN_Name": "Mahjong", "CN_Name": "碰碰胡", "HTML5_Game_ID": "5276", "Button_Image_Name": "5276.png"},
      {"EN_Name": "LuckyAce", "CN_Name": "幸运王牌", "HTML5_Game_ID": "5277", "Button_Image_Name": "5277.png"},
      {"EN_Name": "Flipped Temple", "CN_Name": "翻转神庙", "HTML5_Game_ID": "5311", "Button_Image_Name": "5311.png"},
      {"EN_Name": "Adventure of Mystical Land", "CN_Name": "秘境冒险", "HTML5_Game_ID": "5601", "Button_Image_Name": "5601.png"},
      {"EN_Name": "Big Prosperity", "CN_Name": "发大财", "HTML5_Game_ID": "5823", "Button_Image_Name": "5823.png"},
      {"EN_Name": "Happy Golden Ox Of Happiness", "CN_Name": "喜福牛年", "HTML5_Game_ID": "5835", "Button_Image_Name": "5835.png"},
      {"EN_Name": "Happy Golden Monkey Of Happiness", "CN_Name": "喜福猴年", "HTML5_Game_ID": "5837", "Button_Image_Name": "5837.png"},
      {"EN_Name": "Duo Bao", "CN_Name": "连环夺宝", "HTML5_Game_ID": "5901", "Button_Image_Name": "5901.png"},
      {"EN_Name": "Candy Party", "CN_Name": "糖果派对", "HTML5_Game_ID": "5902", "Button_Image_Name": "5902.png"},
      {"EN_Name": "Tomb of Dragon Emperor", "CN_Name": "秦皇秘宝", "HTML5_Game_ID": "5903", "Button_Image_Name": "5903.png"},
      {"EN_Name": "Candy Party2", "CN_Name": "糖果派对2", "HTML5_Game_ID": "5908", "Button_Image_Name": "5908.png"},
      {"EN_Name": "Duo Bao2", "CN_Name": "连环夺宝2", "HTML5_Game_ID": "5912", "Button_Image_Name": "5912.png"},


    ]
  }, {
    "type": 4,
    "game_id": 406,
    "game_api": "CQ9",
    "name": "CQ9老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/cq9.png",
    "icon": "cq9",
    "game_list_json": "CQ9_game_list",
    "game_list_img_path": "/new/slot_res/cq9/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：盗法老墓、妙笔生财、喵财进宝、鱼跃龙门、丛林宝藏、喵喵、老司机...",
    "labels": "#老虎机#盗法老墓#妙笔生财#喵财进宝#鱼跃龙门",
    gamelist: [

      {"EN_Name": "JumpHigh", "CN_Name": "跳高高2", "HTML5_Game_ID": "179", "Button_Image_Name": "tgaogao2.png"},
      {
        "EN_Name": "city that never sleeps",
        "CN_Name": "不夜城",
        "HTML5_Game_ID": "229",
        "Button_Image_Name": "229.png"
      },

      {
        "EN_Name": "God of Fortune",
        "CN_Name": "发发发财神",
        "HTML5_Game_ID": "227",
        "Button_Image_Name": "227.png"
      },



      {
        "EN_Name": "crazy money making",
        "CN_Name": "疯狂赚钞票",
        "HTML5_Game_ID": "BA203",
        "Button_Image_Name": "fkzcp.png"
      },

      {
        "EN_Name": "Five Ghosts Luck Fortune",
        "CN_Name": "五鬼運財",
        "HTML5_Game_ID": "AR11",
        "Button_Image_Name": "AR11.png"
      },

      {
        "EN_Name": "Typehoon Cash",
        "CN_Name": "8級颱風",
        "HTML5_Game_ID": "AR12",
        "Button_Image_Name": "AR12_8Typhoon.png"
      },
      {
        "EN_Name": "Rabbit Rampage",
        "CN_Name": "守株待兔",
        "HTML5_Game_ID": "AR16",
        "Button_Image_Name": "AR16_Rabbit.png"
      },
      {"EN_Name": "Pyramid Raider", "CN_Name": "盗法老墓", "HTML5_Game_ID": "112", "Button_Image_Name": "dflm.png"},
      {
        "EN_Name": "Invincible Elephant",
        "CN_Name": "锁象无敌",
        "HTML5_Game_ID": "124",
        "Button_Image_Name": "sxwd.png"
      },
      {"EN_Name": "The Magic Brush", "CN_Name": "妙笔生财", "HTML5_Game_ID": "AR01", "Button_Image_Name": "mbsc.png"},
      {"EN_Name": "Fortune Cats", "CN_Name": "喵财进宝", "HTML5_Game_ID": "AR02", "Button_Image_Name": "mcjb.png"},
      {
        "EN_Name": "Backyard Gold",
        "CN_Name": "此地无银300两",
        "HTML5_Game_ID": "AR03",
        "Button_Image_Name": "cdwysbl.png"
      },
      {"EN_Name": "Dou Di Zhu", "CN_Name": "斗地主经典版", "HTML5_Game_ID": "AR04", "Button_Image_Name": "ddzjdb.png"},
      {"EN_Name": "Dragon Gate", "CN_Name": "鱼跃龙门", "HTML5_Game_ID": "AR05", "Button_Image_Name": "yylm.png"},
      {"EN_Name": "Jungle Treasure", "CN_Name": "丛林宝藏", "HTML5_Game_ID": "AR06", "Button_Image_Name": "clbz.png"},
      {"EN_Name": "Kickin' Kash", "CN_Name": "功夫小神通", "HTML5_Game_ID": "AR07", "Button_Image_Name": "gfxst.png"},
      {"EN_Name": "Baccarat", "CN_Name": "百家樂", "HTML5_Game_ID": "BG01", "Button_Image_Name": "bjl.png"},
      {"EN_Name": "Flying Cai Shen", "CN_Name": "飞天财神", "HTML5_Game_ID": "113", "Button_Image_Name": "ftcs.png"},
      {"EN_Name": "Wonderland", "CN_Name": "梦游仙境", "HTML5_Game_ID": "116", "Button_Image_Name": "myxj.png"},
      {"EN_Name": "SkrSkr", "CN_Name": "老司机", "HTML5_Game_ID": "118", "Button_Image_Name": "lsj.png"},
      {"EN_Name": "ZumaWild", "CN_Name": "印加祖玛", "HTML5_Game_ID": "122", "Button_Image_Name": "yjzm.png"},
      {"EN_Name": "Meow Meow", "CN_Name": "喵喵", "HTML5_Game_ID": "AU01", "Button_Image_Name": "mm.png"},
      {"EN_Name": "Feed the Zombie", "CN_Name": "丧尸大餐", "HTML5_Game_ID": "AU02", "Button_Image_Name": "ssdc.png"},
      {
        "EN_Name": "Strange Encounter",
        "CN_Name": "异外来客",
        "HTML5_Game_ID": "AU05",
        "Button_Image_Name": "ywlk.png"
      },
      {"EN_Name": "Poker Bull", "CN_Name": "抢庄牛牛", "HTML5_Game_ID": "AF3", "Button_Image_Name": "qznn.png"},
      {
        "EN_Name": "Poker Bull for Hundreds",
        "CN_Name": "超級百人牛牛",
        "HTML5_Game_ID": "AF4",
        "Button_Image_Name": "cjbrnn.png"
      },
      {
        "EN_Name": "Landlord Fights Carnival",
        "CN_Name": "加倍斗地主",
        "HTML5_Game_ID": "AF9",
        "Button_Image_Name": "jbddz.png"
      },
      {"EN_Name": "Demon Archive", "CN_Name": "恶魔侦探", "HTML5_Game_ID": "AN01", "Button_Image_Name": "emzt.png"},
      {"EN_Name": "Powerful Thor", "CN_Name": "雷神托尔", "HTML5_Game_ID": "AN02", "Button_Image_Name": "lste.png"},
      {"EN_Name": "God of Fortune", "CN_Name": "财神", "HTML5_Game_ID": "AN03", "Button_Image_Name": "cs.png"},
      {"EN_Name": "Colosseum", "CN_Name": "罗马竞技场", "HTML5_Game_ID": "AN04", "Button_Image_Name": "lmjjc.png"},
      {
        "EN_Name": "Treasure Island2",
        "CN_Name": "金银岛2",
        "HTML5_Game_ID": "AN05",
        "Button_Image_Name": "jyd2.png"
      },
      {"EN_Name": "Happy Farm", "CN_Name": "开心农场", "HTML5_Game_ID": "AN06", "Button_Image_Name": "kxlc.png"},
      {"EN_Name": "JumpHigh", "CN_Name": "跳高高", "HTML5_Game_ID": "52", "Button_Image_Name": "tgaogao.png"},
      {"EN_Name": "RaveJump", "CN_Name": "跳起来", "HTML5_Game_ID": "7", "Button_Image_Name": "tql.png"},
      {"EN_Name": "LuckyBats", "CN_Name": "五福临门", "HTML5_Game_ID": "10", "Button_Image_Name": "wflm.png"},
      {"EN_Name": "Paradise", "CN_Name": "皇金渔场", "HTML5_Game_ID": "AB3", "Button_Image_Name": "hjyc.png"},
      {"EN_Name": "RaveJump2", "CN_Name": "跳起来2", "HTML5_Game_ID": "24", "Button_Image_Name": "tql2.png"},
      {"EN_Name": "Good Fortune", "CN_Name": "鸿福齐天", "HTML5_Game_ID": "50", "Button_Image_Name": "hfqt.png"},
      {"EN_Name": "God of War", "CN_Name": "武圣", "HTML5_Game_ID": "31", "Button_Image_Name": "ws.png"},
      {"EN_Name": "Zeus", "CN_Name": "宙斯", "HTML5_Game_ID": "64", "Button_Image_Name": "zs.png"},
      {"EN_Name": "JumpHigher", "CN_Name": "跳更高", "HTML5_Game_ID": "99", "Button_Image_Name": "tgg.png"},
      {"EN_Name": "FaCaiShen", "CN_Name": "发财神", "HTML5_Game_ID": "69", "Button_Image_Name": "fcs.png"},
      {"EN_Name": "DiscoNight", "CN_Name": "蹦迪", "HTML5_Game_ID": "205", "Button_Image_Name": "bd.png"},
      {"EN_Name": "Fire Queen", "CN_Name": "火之女王", "HTML5_Game_ID": "83", "Button_Image_Name": "hznw.png"},
      {"EN_Name": "ZhongKui", "CN_Name": "钟馗运财", "HTML5_Game_ID": "9", "Button_Image_Name": "zkyc.png"},
      {"EN_Name": "GuGuGu", "CN_Name": "金鸡报喜", "HTML5_Game_ID": "15", "Button_Image_Name": "jjbx.png"},
      {"EN_Name": "Fire Chibi", "CN_Name": "火烧连环船", "HTML5_Game_ID": "33", "Button_Image_Name": "hslhc.png"},
      {"EN_Name": "RaveHigh", "CN_Name": "嗨起来", "HTML5_Game_ID": "203", "Button_Image_Name": "hql.png"},
      {"EN_Name": "WaterWorld", "CN_Name": "水世界", "HTML5_Game_ID": "29", "Button_Image_Name": "ssj.png"},
      {"EN_Name": "SoSweet", "CN_Name": "甜蜜蜜", "HTML5_Game_ID": "8", "Button_Image_Name": "tmm.png"},
      {"EN_Name": "THOR", "CN_Name": "雷神", "HTML5_Game_ID": "89", "Button_Image_Name": "ls.png"},
      {"EN_Name": "jumping mobile", "CN_Name": "单手跳高高", "HTML5_Game_ID": "105", "Button_Image_Name": "dstgg.png"},
      {"EN_Name": "FlyOut", "CN_Name": "飞起来", "HTML5_Game_ID": "111", "Button_Image_Name": "fql.png"},
      {"EN_Name": "GuGuGu2", "CN_Name": "金鸡报喜2", "HTML5_Game_ID": "58", "Button_Image_Name": "jjbx2.png"},
      {
        "EN_Name": "Jump Higher mobile",
        "CN_Name": "直式跳更高",
        "HTML5_Game_ID": "108",
        "Button_Image_Name": "zstgg.png"
      },
      {"EN_Name": "FruitKing", "CN_Name": "钻石水果王", "HTML5_Game_ID": "1", "Button_Image_Name": "zssgw.png"},
      {"EN_Name": "DiscoNight M", "CN_Name": "直式蹦迪", "HTML5_Game_ID": "137", "Button_Image_Name": "zsbd.png"},
      {"EN_Name": "Chameleon", "CN_Name": "变色龙", "HTML5_Game_ID": "79", "Button_Image_Name": "bsl.png"},
      {"EN_Name": "Jungle Party", "CN_Name": "丛林舞会", "HTML5_Game_ID": "60", "Button_Image_Name": "clwh.png"},
      {"EN_Name": "Super5", "CN_Name": "五行", "HTML5_Game_ID": "16", "Button_Image_Name": "wx.png"},
      {"EN_Name": "HappyRichYear", "CN_Name": "好运年年", "HTML5_Game_ID": "72", "Button_Image_Name": "hynn.png"},
      {"EN_Name": "Golden Egg", "CN_Name": "赚金蛋", "HTML5_Game_ID": "67", "Button_Image_Name": "zjd.png"},
      {"EN_Name": "Wolf Moon", "CN_Name": "狼月", "HTML5_Game_ID": "46", "Button_Image_Name": "ly.png"},
      {"EN_Name": "MuayThai", "CN_Name": "拳霸", "HTML5_Game_ID": "201", "Button_Image_Name": "qb.png"},
      {
        "EN_Name": "Rave Jump mobile",
        "CN_Name": "单手跳起來",
        "HTML5_Game_ID": "109",
        "Button_Image_Name": "dstql.png"
      },
      {"EN_Name": "TreasureBowl", "CN_Name": "聚宝盆", "HTML5_Game_ID": "74", "Button_Image_Name": "jbp.png"},
      {"EN_Name": "Dracula", "CN_Name": "黯夜公爵", "HTML5_Game_ID": "56", "Button_Image_Name": "aygj.png"},
      {"EN_Name": "Dragon OnHeart", "CN_Name": "魔龙传奇", "HTML5_Game_ID": "55", "Button_Image_Name": "mlcq.png"},
      {"EN_Name": "Football star", "CN_Name": "世界杯明星", "HTML5_Game_ID": "93", "Button_Image_Name": "sjbmx.png"},
      {"EN_Name": "Zeus M", "CN_Name": "直式宙斯", "HTML5_Game_ID": "125", "Button_Image_Name": "zszs.png"},
      {"EN_Name": "Funny Alpaca", "CN_Name": "火草泥马", "HTML5_Game_ID": "54", "Button_Image_Name": "hcnm.png"},
      {"EN_Name": "LuckyBats M", "CN_Name": "直式五福临门", "HTML5_Game_ID": "123", "Button_Image_Name": "zswflm.png"},
      {"EN_Name": "TreasureHouse", "CN_Name": "金玉满堂", "HTML5_Game_ID": "12", "Button_Image_Name": "jymt.png"},
      {
        "EN_Name": "Fire Chibi M",
        "CN_Name": "直式火烧连环船",
        "HTML5_Game_ID": "139",
        "Button_Image_Name": "zshslhc.png"
      },
      {"EN_Name": "Apollo", "CN_Name": "阿波罗", "HTML5_Game_ID": "78", "Button_Image_Name": "abl.png"},
      {"EN_Name": "The Beast War", "CN_Name": "神兽争霸", "HTML5_Game_ID": "57", "Button_Image_Name": "sszb.png"},
      {"EN_Name": "Mr.Rich", "CN_Name": "金大款", "HTML5_Game_ID": "5", "Button_Image_Name": "jdk.png"},
      {"EN_Name": "Gemstone", "CN_Name": "钻饱宝", "HTML5_Game_ID": "114", "Button_Image_Name": "zbb.png"},
      {"EN_Name": "OrientalBeauty", "CN_Name": "舞媚娘", "HTML5_Game_ID": "202", "Button_Image_Name": "wmn.png"},
      {"EN_Name": "The Ghouls", "CN_Name": "寻龙诀", "HTML5_Game_ID": "63", "Button_Image_Name": "xlj.png"},
      {"EN_Name": "God of War M", "CN_Name": "直式武圣", "HTML5_Game_ID": "127", "Button_Image_Name": "zsws.png"},
      {"EN_Name": "FruitKingII", "CN_Name": "豪华水果王", "HTML5_Game_ID": "44", "Button_Image_Name": "hhsgw.png"},
      {"EN_Name": "RaveJump2 M", "CN_Name": "直式跳起来2", "HTML5_Game_ID": "121", "Button_Image_Name": "zstql2.png"},
      {"EN_Name": "LuckyBatsJP", "CN_Name": "五福临门JP", "HTML5_Game_ID": "1010", "Button_Image_Name": "wflmjp.png"},
      {"EN_Name": "WildTarzan", "CN_Name": "森林泰后", "HTML5_Game_ID": "4", "Button_Image_Name": "slth.png"},
      {"EN_Name": "RunningToro", "CN_Name": "牛逼快跑", "HTML5_Game_ID": "86", "Button_Image_Name": "nbkp.png"},
      {"EN_Name": "SuperDiamonds", "CN_Name": "非常钻", "HTML5_Game_ID": "62", "Button_Image_Name": "fcz.png"},
      {"EN_Name": "VampireKiss", "CN_Name": "血之吻", "HTML5_Game_ID": "3", "Button_Image_Name": "xzw.png"},
      {"EN_Name": "Apsaras", "CN_Name": "飞天", "HTML5_Game_ID": "39", "Button_Image_Name": "ft.png"},
      {
        "EN_Name": "Detective Dee2",
        "CN_Name": "狄仁杰四大天王",
        "HTML5_Game_ID": "221",
        "Button_Image_Name": "drjsdtw.png"
      },
      {"EN_Name": "YuanBao", "CN_Name": "金元宝", "HTML5_Game_ID": "23", "Button_Image_Name": "jyb.png"},
      {"EN_Name": "ChickyParmParm", "CN_Name": "海滨消消乐", "HTML5_Game_ID": "104", "Button_Image_Name": "hbxxl.png"},
      {"EN_Name": "FruityCarnival", "CN_Name": "水果派对", "HTML5_Game_ID": "102", "Button_Image_Name": "sgpd.png"},
      {
        "EN_Name": "Darts Championship",
        "CN_Name": "镖王争霸",
        "HTML5_Game_ID": "40",
        "Button_Image_Name": "bwzb.png"
      },
      {"EN_Name": "WuKong&Peaches", "CN_Name": "悟空偷桃", "HTML5_Game_ID": "68", "Button_Image_Name": "wktt.png"},
      {
        "EN_Name": "Good Fortune M",
        "CN_Name": "直式洪福齐天",
        "HTML5_Game_ID": "133",
        "Button_Image_Name": "zshfqt.png"
      },
      {"EN_Name": "1945", "CN_Name": "1945", "HTML5_Game_ID": "6", "Button_Image_Name": "1945.png"},
      {"EN_Name": "RedPhoenix", "CN_Name": "火凤凰", "HTML5_Game_ID": "77", "Button_Image_Name": "hfh.png"},
      {"EN_Name": "Fire777", "CN_Name": "火爆777", "HTML5_Game_ID": "66", "Button_Image_Name": "hb777.png"},
      {"EN_Name": "Happy Magpies", "CN_Name": "金喜鹊桥", "HTML5_Game_ID": "88", "Button_Image_Name": "jxqq.png"},
      {"EN_Name": "Fa Cai Shen M", "CN_Name": "直式发财神", "HTML5_Game_ID": "131", "Button_Image_Name": "zsfcs.png"},
      {
        "EN_Name": "TreasureBowlJP",
        "CN_Name": "聚宝盆JP",
        "HTML5_Game_ID": "1074",
        "Button_Image_Name": "jbpjp.png"
      },
      {"EN_Name": "888", "CN_Name": "发发发", "HTML5_Game_ID": "20", "Button_Image_Name": "fff.png"},
      {"EN_Name": "777", "CN_Name": "777", "HTML5_Game_ID": "26", "Button_Image_Name": "777.png"},
      {"EN_Name": "Warrior legend", "CN_Name": "三国序", "HTML5_Game_ID": "30", "Button_Image_Name": "sgx.png"},
      {"EN_Name": "TreasureIsland", "CN_Name": "金银岛", "HTML5_Game_ID": "81", "Button_Image_Name": "jyd.png"},
      {"EN_Name": "Lotus", "CN_Name": "莲", "HTML5_Game_ID": "48", "Button_Image_Name": "l.png"},
      {"EN_Name": "HotSpin", "CN_Name": "风火轮", "HTML5_Game_ID": "19", "Button_Image_Name": "fhl.png"},
      {"EN_Name": "Gong He Xin Xi", "CN_Name": "恭贺新禧", "HTML5_Game_ID": "43", "Button_Image_Name": "ghxx.png"},
      {"EN_Name": "All Star Team", "CN_Name": "世界杯全明星", "HTML5_Game_ID": "98", "Button_Image_Name": "sjbqmx.png"},
      {"EN_Name": "GreatLion", "CN_Name": "祥狮献瑞", "HTML5_Game_ID": "17", "Button_Image_Name": "xsxr.png"},
      {"EN_Name": "All Wilds", "CN_Name": "舞力全开", "HTML5_Game_ID": "38", "Button_Image_Name": "wlqk.png"},
      {"EN_Name": "JewelLuxury", "CN_Name": "宝石配对", "HTML5_Game_ID": "103", "Button_Image_Name": "bspd.png"},
      {"EN_Name": "Water Balloons", "CN_Name": "水球大战", "HTML5_Game_ID": "41", "Button_Image_Name": "sqdz.png"},
      {"EN_Name": "MahjongKing", "CN_Name": "雀王", "HTML5_Game_ID": "18", "Button_Image_Name": "qw.png"},
      {"EN_Name": "WanBaoDino", "CN_Name": "万饱龙", "HTML5_Game_ID": "70", "Button_Image_Name": "wbl.png"},
      {
        "EN_Name": "Gu Gu Gu 2 M",
        "CN_Name": "直式金鸡报喜2",
        "HTML5_Game_ID": "129",
        "Button_Image_Name": "zsjjbx2.png"
      },
      {"EN_Name": "Lonely Planet", "CN_Name": "寂寞星球", "HTML5_Game_ID": "49", "Button_Image_Name": "jmxq.png"},
      {"EN_Name": "PokerSLOT", "CN_Name": "扑克拉霸", "HTML5_Game_ID": "25", "Button_Image_Name": "pklb.png"},
      {"EN_Name": "Love Night", "CN_Name": "来电99", "HTML5_Game_ID": "53", "Button_Image_Name": "ld99.png"},
      {
        "EN_Name": "JellypopHumming",
        "CN_Name": "宾果消消消",
        "HTML5_Game_ID": "100",
        "Button_Image_Name": "bgxxx.png"
      },
      {"EN_Name": "StarMatching", "CN_Name": "星星消消乐", "HTML5_Game_ID": "101", "Button_Image_Name": "xxxxx.png"},
      {"EN_Name": "Xmas", "CN_Name": "圣诞来啦", "HTML5_Game_ID": "141", "Button_Image_Name": "sdll.png"},
      {"EN_Name": "Detective Dee", "CN_Name": "通天神探狄仁杰", "HTML5_Game_ID": "32", "Button_Image_Name": "drj.png"},
      {"EN_Name": "GettingEnergies", "CN_Name": "集电宝", "HTML5_Game_ID": "87", "Button_Image_Name": "jdb.png"},
      {"EN_Name": "GodOfChess", "CN_Name": "棋圣", "HTML5_Game_ID": "2", "Button_Image_Name": "qs.png"},
      {
        "EN_Name": "Treasure of Seti",
        "CN_Name": "塞特的宝藏",
        "HTML5_Game_ID": "AS17",
        "Button_Image_Name": "stdbz.png"
      },
      {"EN_Name": "Ecstatic Circus", "CN_Name": "嗨爆大马戏", "HTML5_Game_ID": "51", "Button_Image_Name": "hbdmx.png"},
      {"EN_Name": "Crazy NuoZha", "CN_Name": "疯狂哪咤", "HTML5_Game_ID": "35", "Button_Image_Name": "fknz.png"},
      {"EN_Name": "SummerMood", "CN_Name": "夏日猩情", "HTML5_Game_ID": "59", "Button_Image_Name": "xrxq.png"},
      {"EN_Name": "Super8", "CN_Name": "超级发", "HTML5_Game_ID": "45", "Button_Image_Name": "cjf.png"},
      {"EN_Name": "Poseidon", "CN_Name": "传奇海神", "HTML5_Game_ID": "80", "Button_Image_Name": "cqhs.png"},
      {"EN_Name": "Sherlock Holmes", "CN_Name": "福尔摩斯", "HTML5_Game_ID": "42", "Button_Image_Name": "fems.png"},
      {"EN_Name": "Gophers War", "CN_Name": "地鼠战役", "HTML5_Game_ID": "34", "Button_Image_Name": "dszy.png"},
      {"EN_Name": "Magic World", "CN_Name": "魔法世界", "HTML5_Game_ID": "27", "Button_Image_Name": "mfsj.png"},
      {"EN_Name": "LuckyBoxes", "CN_Name": "百宝箱", "HTML5_Game_ID": "204", "Button_Image_Name": "bbx.png"},
      {"EN_Name": "Xmas Tales", "CN_Name": "圣诞故事", "HTML5_Game_ID": "AS19", "Button_Image_Name": "sdgs.png"},
      {
        "EN_Name": "Football Jerseys",
        "CN_Name": "世界杯球衣",
        "HTML5_Game_ID": "94",
        "Button_Image_Name": "sjbqy.png"
      },
      {"EN_Name": "Lucky 3", "CN_Name": "幸运3", "HTML5_Game_ID": "AS10", "Button_Image_Name": "xy3.png"},
      {"EN_Name": "RichWitch", "CN_Name": "绝赢巫师", "HTML5_Game_ID": "14", "Button_Image_Name": "jyws.png"},
      {"EN_Name": "Mr.Bean", "CN_Name": "天天吃豆", "HTML5_Game_ID": "61", "Button_Image_Name": "ttcd.png"},
      {"EN_Name": "SakuraLegend", "CN_Name": "樱花妹子", "HTML5_Game_ID": "13", "Button_Image_Name": "yhmz.png"},
      {"EN_Name": "Wonderland2", "CN_Name": "梦游仙境2", "HTML5_Game_ID": "11", "Button_Image_Name": "myxj2.png"},
      {"EN_Name": "Football Baby", "CN_Name": "足球宝贝", "HTML5_Game_ID": "96", "Button_Image_Name": "zqbb.png"},
      {"EN_Name": "Paradise2", "CN_Name": "皇金渔场2", "HTML5_Game_ID": "AB1", "Button_Image_Name": "hjychw.png"},
      {"EN_Name": "Wild Magic", "CN_Name": "奇幻魔术", "HTML5_Game_ID": "84", "Button_Image_Name": "qhms.png"},
      {"EN_Name": "God of Cookery", "CN_Name": "食神", "HTML5_Game_ID": "28", "Button_Image_Name": "ss.png"},
      {"EN_Name": "Pharaoh's Gold", "CN_Name": "法老宝藏", "HTML5_Game_ID": "47", "Button_Image_Name": "flbz.png"},
      {"EN_Name": "GoldenEggsJP", "CN_Name": "赚金蛋JP", "HTML5_Game_ID": "1067", "Button_Image_Name": "zjd.png"},
      {"EN_Name": "Gu Gu Gu M", "CN_Name": "直式金鸡报喜", "HTML5_Game_ID": "135", "Button_Image_Name": "zsjjbx.png"},
      {"EN_Name": "BigWolf", "CN_Name": "野狼传说", "HTML5_Game_ID": "21", "Button_Image_Name": "ylcs.png"},
      {
        "EN_Name": "World Cup Russia 2018",
        "CN_Name": "2018世界杯",
        "HTML5_Game_ID": "92",
        "Button_Image_Name": "sjb.png"
      },
      {"EN_Name": "Football Boots", "CN_Name": "世界杯球鞋", "HTML5_Game_ID": "95", "Button_Image_Name": "sjbqx.png"},
      {"EN_Name": "Queen Of Dead", "CN_Name": "死亡女王", "HTML5_Game_ID": "AS20", "Button_Image_Name": "swnw.png"},
      {"EN_Name": "Wild Fudge", "CN_Name": "疯狂软糖", "HTML5_Game_ID": "AS18", "Button_Image_Name": "fkrt.png"},
      {
        "EN_Name": "Hollywood Pets",
        "CN_Name": "好莱坞宠物",
        "HTML5_Game_ID": "AS09",
        "Button_Image_Name": "hlwcw.png"
      },
      {"EN_Name": "WorldCup Field", "CN_Name": "世界杯球场", "HTML5_Game_ID": "97", "Button_Image_Name": "sjbqc.png"},
      {
        "EN_Name": "MonkeyOfficeLegend",
        "CN_Name": "庶务西游二课",
        "HTML5_Game_ID": "22",
        "Button_Image_Name": "swxy.png"
      },
      {"EN_Name": "WonWonWon", "CN_Name": "旺旺旺", "HTML5_Game_ID": "76", "Button_Image_Name": "www.png"},
      {"EN_Name": "Pub Tycoon", "CN_Name": "夜店大亨", "HTML5_Game_ID": "36", "Button_Image_Name": "yddh.png"},
      {
        "EN_Name": "Fishing Master",
        "CN_Name": "钓鱼高手",
        "HTML5_Game_ID": "AMfish",
        "Button_Image_Name": "dygs.png"
      },
      {"EN_Name": "Golden Kick", "CN_Name": "足球世界杯", "HTML5_Game_ID": "65", "Button_Image_Name": "zqsjb.png"},
      {
        "EN_Name": "Fight the Landlord",
        "CN_Name": "斗地主",
        "HTML5_Game_ID": "AD03",
        "Button_Image_Name": "ddz.png"
      },
      {"EN_Name": "Niuniu", "CN_Name": "百人牛牛", "HTML5_Game_ID": "AQ01", "Button_Image_Name": "brnn.png"},
      {"EN_Name": "ScMahjong", "CN_Name": "四川麻将", "HTML5_Game_ID": "AD02", "Button_Image_Name": "scmj.png"},
      {"EN_Name": "Happy fruit", "CN_Name": "快乐水果机", "HTML5_Game_ID": "BC01", "Button_Image_Name": "klsgj.png"},
      {"EN_Name": "Heracles", "CN_Name": "大力神", "HTML5_Game_ID": "161", "Button_Image_Name": "dls.png"},
      {"EN_Name": "Ne Zha Advent", "CN_Name": "哪吒再临", "HTML5_Game_ID": "163", "Button_Image_Name": "nzzl.png"},

      // {"EN_Name": "Fortune Beasts", "CN_Name": "东方福兽", "HTML5_Game_ID": "167", "Button_Image_Name": "dffs.png"},
      // {"EN_Name": "Last Roll", "CN_Name": "夺镖", "HTML5_Game_ID": "BT01", "Button_Image_Name": "duobiao.png"},
      // {"EN_Name": "Gate Of The Gods", "CN_Name": "众神之门", "HTML5_Game_ID": "171", "Button_Image_Name": "zszm.png"},
      {"EN_Name": "Toros", "CN_Name": "666_6", "HTML5_Game_ID": "173", "Button_Image_Name": "666_6.png"},
      {"EN_Name": "Gu Gu Gu 3", "CN_Name": "金鸡报囍3", "HTML5_Game_ID": "180", "Button_Image_Name": "jjbx3.png"},
    ]
  }, {
    "type": 4,
    "game_id": 403,
    "game_api": "HB",
    "name": "HB老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/hb.png",
    "icon": "hb",
    "game_list_json": "HB_game_list",
    "game_list_img_path": "/new/slot_res/hb/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：欢乐喜狮、疯狂水果、快乐圣诞、快乐南瓜、神秘埃及、寻宝记...",
    "labels": "#老虎机#欢乐喜狮#疯狂水果#快乐圣诞#快乐南瓜",
    gamelist: [



      {
        "HTML5_Game_ID": "SGKnockoutFootball",
        "EN_Name": "Knockout Football",
        "CN_Name": "黄金足球",
        "Button_Image_Name": "SGKnockoutFootball_zh-CN.png"
      },



      {
        "HTML5_Game_ID": "SGGlamRock",
        "EN_Name": "",
        "CN_Name": "華麗搖滾",
        "Button_Image_Name": "SGGlamRock_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGColossalGems",
        "EN_Name": "",
        "CN_Name": "碰碰宝石",
        "Button_Image_Name": "SGColossalGems_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGWizardsWantWar",
        "EN_Name": "",
        "CN_Name": "魔法大战",
        "Button_Image_Name": "SGWizardsWantWar_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGHotHotHalloween",
        "EN_Name": "",
        "CN_Name": "疯狂万圣夜",
        "Button_Image_Name": "SGHotHotHalloween_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGLoonyBlox",
        "EN_Name": "",
        "CN_Name": "搞怪卡通",
        "Button_Image_Name": "SGLoonyBlox_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGKnockoutFootballRush",
        "EN_Name": "",
        "CN_Name": "火热足球",
        "Button_Image_Name": "SGKnockoutFootballRush_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGLuckyFortuneCat",
        "EN_Name": "",
        "CN_Name": "招財貓咪",
        "Button_Image_Name": "SGLuckyFortuneCat_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGTechnoTumble",
        "EN_Name": "",
        "CN_Name": "电球弹弹乐",
        "Button_Image_Name": "SGTechnoTumble_zh-CN.png"
      },


      {
        "HTML5_Game_ID": "SGScopa",
        "EN_Name": "",
        "CN_Name": "欢乐卡片",
        "Button_Image_Name": "SGScopa_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGHeySushi",
        "EN_Name": "",
        "CN_Name": "Hey！寿司",
        "Button_Image_Name": "SGHeySushi_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGWealthInn",
        "EN_Name": "",
        "CN_Name": "财神客栈",
        "Button_Image_Name": "SGWealthInn_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGJellyfishFlowUltra",
        "EN_Name": "",
        "CN_Name": "缤纷水母 - 极端",
        "Button_Image_Name": "SGJellyfishFlowUltra_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGJellyfishFlow",
        "EN_Name": "",
        "CN_Name": "缤纷水母",
        "Button_Image_Name": "SGJellyfishFlow_zh-CN.png"
      },


      {
        "HTML5_Game_ID": "SGHappyApe",
        "EN_Name": "",
        "CN_Name": "快乐猿猴",
        "Button_Image_Name": "SGHappyApe_zh-CN.png"
      },



      {
        "HTML5_Game_ID": "SGRainbowmania",
        "EN_Name": "",
        "CN_Name": "彩虹森林",
        "Button_Image_Name": "SGRainbowmania_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDragonTigerGate",
        "EN_Name": "",
        "CN_Name": "龙虎門",
        "Button_Image_Name": "SGDragonTigerGate_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSojuBomb",
        "EN_Name": "",
        "CN_Name": "炸弹烧酒",
        "Button_Image_Name": "SGSojuBomb_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTukTukThailand",
        "EN_Name": "",
        "CN_Name": "嘟嘟游",
        "Button_Image_Name": "SGTukTukThailand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTaikoBeats",
        "EN_Name": "",
        "CN_Name": "吉太鼓",
        "Button_Image_Name": "SGTaikoBeats_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGLaughingBuddha",
        "EN_Name": "",
        "CN_Name": "笑佛降临",
        "Button_Image_Name": "SGLaughingBuddha_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSpaceGoonz",
        "EN_Name": "",
        "CN_Name": "宇宙怪咖",
        "Button_Image_Name": "SGSpaceGoonz_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGGoldenUnicornDeluxe",
        "EN_Name": "",
        "CN_Name": "黄金独角兽",
        "Button_Image_Name": "SGGoldenUnicornDeluxe_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBombRunner",
        "EN_Name": "",
        "CN_Name": "轰炸工厂",
        "Button_Image_Name": "SGBombRunner_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMightyMedusa",
        "EN_Name": "",
        "CN_Name": "蛇发女妖",
        "Button_Image_Name": "SGMightyMedusa_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDiscoBeats",
        "EN_Name": "",
        "CN_Name": "劲舞电音",
        "Button_Image_Name": "SGDiscoBeats_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGLanternLuck",
        "EN_Name": "",
        "CN_Name": "派彩灯笼",
        "Button_Image_Name": "SGLanternLuck_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGLuckyDurian",
        "EN_Name": "",
        "CN_Name": "水果之王",
        "Button_Image_Name": "SGLuckyDurian_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGNewYearsBash",
        "EN_Name": "",
        "CN_Name": "新年派对",
        "Button_Image_Name": "SGNewYearsBash_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGProst",
        "EN_Name": "",
        "CN_Name": "干杯吧!",
        "Button_Image_Name": "SGProst_zh-CN.png"
      },





      {
        "HTML5_Game_ID": "SGNineTails",
        "EN_Name": "Carnival christmas",
        "CN_Name": "九尾狐",
        "Button_Image_Name": "SGNineTails_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFly",
        "EN_Name": "Carnival christmas",
        "CN_Name": "飞象乐园",
        "Button_Image_Name": "SGFly_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMysticFortuneDeluxe",
        "EN_Name": "Carnival christmas",
        "CN_Name": "东方之宝",
        "Button_Image_Name": "SGMysticFortuneDeluxe_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCalaverasExplosivas",
        "EN_Name": "Carnival christmas",
        "CN_Name": "爆爆骷髅",
        "Button_Image_Name": "SGCalaverasExplosivas_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMarvelousFurlongs",
        "EN_Name": "Carnival christmas",
        "CN_Name": "风驰夺冠",
        "Button_Image_Name": "SGMarvelousFurlongs_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCandyTower",
        "EN_Name": "Carnival christmas",
        "CN_Name": "糖果园",
        "Button_Image_Name": "SGCandyTower_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGReturnToTheFeature",
        "EN_Name": "Carnival christmas",
        "CN_Name": "穿越未来",
        "Button_Image_Name": "SGReturnToTheFeature_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBeforeTimeRunsOut",
        "EN_Name": "Carnival christmas",
        "CN_Name": "波斯王子",
        "Button_Image_Name": "SGBeforeTimeRunsOut_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGOrbsOfAtlantis",
        "EN_Name": "Carnival christmas",
        "CN_Name": "亚特兰蒂斯",
        "Button_Image_Name": "SGOrbsOfAtlantis_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTotemTowers",
        "EN_Name": "Carnival christmas",
        "CN_Name": "玛雅之塔",
        "Button_Image_Name": "SGTotemTowers_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGChristmasGiftRush",
        "EN_Name": "Carnival christmas",
        "CN_Name": "礼物派对",
        "Button_Image_Name": "SGChristmasGiftRush_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTabernaDeLosMuertosUltra",
        "EN_Name": "Carnival christmas",
        "CN_Name": "幽灵酒馆- 极端",
        "Button_Image_Name": "SGTabernaDeLosMuertosUltra_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTabernaDeLosMuertos",
        "EN_Name": "Carnival christmas",
        "CN_Name": "幽灵酒馆",
        "Button_Image_Name": "SGTabernaDeLosMuertos_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFaCaiShenDeluxe",
        "EN_Name": "SGFaCaiShenDeluxe",
        "CN_Name": "双喜财神",
        "Button_Image_Name": "clientpack_SGFaCaiShenDeluxe_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGNuwa",
        "EN_Name": "Nuwa",
        "CN_Name": "女娲传奇",
        "Button_Image_Name": "SGNuwa_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGWildTrucks",
        "EN_Name": "Wild Trucks",
        "CN_Name": "狂野卡车",
        "Button_Image_Name": "SGWildTrucks_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGLuckyLucky",
        "EN_Name": "Lucky Lucky",
        "CN_Name": "富贵福",
        "Button_Image_Name": "SGLuckyLucky_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMagicOak",
        "EN_Name": "Magic Oak",
        "CN_Name": "神奇橡树",
        "Button_Image_Name": "SGMagicOak.png"
      },
      {
        "HTML5_Game_ID": "SGMountMazuma",
        "EN_Name": "Mount Mazuma",
        "CN_Name": "财宝山",
        "Button_Image_Name": "SGMountMazuma_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SG5LuckyLions",
        "EN_Name": "5 Lucky Lions",
        "CN_Name": "欢乐喜狮",
        "Button_Image_Name": "SG5LuckyLions_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGHotHotFruit",
        "EN_Name": "Hot Hot Fruit",
        "CN_Name": "疯狂水果",
        "Button_Image_Name": "SGHotHotFruit_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGHappiestChristmasTree",
        "EN_Name": "Happiest Christmas Tree",
        "CN_Name": "快乐圣诞",
        "Button_Image_Name": "SGHappiestChristmasTree_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPumpkinPatch",
        "EN_Name": "Pumpkin Patch",
        "CN_Name": "快乐南瓜",
        "Button_Image_Name": "SGPumpkinPatch_zh-CN.png"
      },
      {"HTML5_Game_ID": "SGJump", "EN_Name": "Jump!", "CN_Name": "疯狂百搭", "Button_Image_Name": "SGJump_zh-CN.png"},
      {
        "HTML5_Game_ID": "SGEgyptianDreamsDeluxe",
        "EN_Name": "Egyptian Dreams Deluxe",
        "CN_Name": "神秘埃及",
        "Button_Image_Name": "SGEgyptianDreamsDeluxe_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGWaysOfFortune",
        "EN_Name": "Ways Of Fortune",
        "CN_Name": "寻宝记",
        "Button_Image_Name": "SGWaysOfFortune_zh-CN.png"
      },

      {
        "HTML5_Game_ID": "SGFortuneDogs",
        "EN_Name": "Fortune Dogs",
        "CN_Name": "富贵旺旺",
        "Button_Image_Name": "SGFortuneDogs_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPresto",
        "EN_Name": "Presto!",
        "CN_Name": "魔幻大财",
        "Button_Image_Name": "SGPresto_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGLondonHunter",
        "EN_Name": "London Hunter",
        "CN_Name": "伦敦猎人",
        "Button_Image_Name": "SGLondonHunter_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFourDivineBeasts",
        "EN_Name": "Four Divine Beasts",
        "CN_Name": "四大神兽",
        "Button_Image_Name": "SGFourDivineBeasts_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SG5Mariachis",
        "EN_Name": "5 Mariachis",
        "CN_Name": "街头乐队",
        "Button_Image_Name": "SG5Mariachis_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSantasVillage",
        "EN_Name": "Santa's Village",
        "CN_Name": "圣诞乐村",
        "Button_Image_Name": "SGSantasVillage_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGRollingRoger",
        "EN_Name": "Rolling Roger",
        "CN_Name": "刺猬宝宝",
        "Button_Image_Name": "SGRollingRoger_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCakeValley",
        "EN_Name": "Cake Valley",
        "CN_Name": "蛋糕谷",
        "Button_Image_Name": "SGCakeValley_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGScruffyScallywags",
        "EN_Name": "Scruffy Scallywags",
        "CN_Name": "欢乐帮",
        "Button_Image_Name": "SGScruffyScallywags_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTheDeadEscape",
        "EN_Name": "The Dead Escape",
        "CN_Name": "死海逃生",
        "Button_Image_Name": "SGTheDeadEscape_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPandaPanda",
        "EN_Name": "Panda Panda",
        "CN_Name": "双喜熊猫",
        "Button_Image_Name": "SGPandaPanda_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBirdOfThunder",
        "EN_Name": "Bird of Thunder",
        "CN_Name": "雷鸟",
        "Button_Image_Name": "SGBirdOfThunder_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTheKoiGate",
        "EN_Name": "Koi Gate",
        "CN_Name": "鲤鱼门",
        "Button_Image_Name": "SGTheKoiGate_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFireRooster",
        "EN_Name": "Fire Rooster",
        "CN_Name": "公鸡王",
        "Button_Image_Name": "SGFireRooster_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFenghuang",
        "EN_Name": "Fenghuang",
        "CN_Name": "凤凰",
        "Button_Image_Name": "SGFenghuang_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGJugglenaut",
        "EN_Name": "Jugglenaut",
        "CN_Name": "惊喜秀",
        "Button_Image_Name": "SGJugglenaut_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGOceansCall",
        "EN_Name": "Ocean's Call",
        "CN_Name": "海洋之音",
        "Button_Image_Name": "SGOceansCall_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGGangsters",
        "EN_Name": "Gangsters",
        "CN_Name": "黑手党",
        "Button_Image_Name": "SGGangsters_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSparta",
        "EN_Name": "Sparta",
        "CN_Name": "斯巴达",
        "Button_Image_Name": "SGSparta_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSuperTwister",
        "EN_Name": "Super Twister",
        "CN_Name": "超级龙卷风",
        "Button_Image_Name": "SGSuperTwister_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SG12Zodiacs",
        "EN_Name": "12 Zodiacs",
        "CN_Name": "十二生肖",
        "Button_Image_Name": "SG12Zodiacs_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGArcaneElements",
        "EN_Name": "Arcane Elements",
        "CN_Name": "神秘元素",
        "Button_Image_Name": "SGArcaneElements_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDragonsThrone",
        "EN_Name": "Dragon's Throne",
        "CN_Name": "龙之宝座",
        "Button_Image_Name": "SGDragonsThrone_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCoyoteCrash",
        "EN_Name": "Coyote Crash",
        "CN_Name": "狼贼夺宝",
        "Button_Image_Name": "SGCoyoteCrash_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGRomanEmpire",
        "EN_Name": "Roman Empire",
        "CN_Name": "罗马帝国",
        "Button_Image_Name": "SGRomanEmpire_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGRuffledUp",
        "EN_Name": "Ruffled Up",
        "CN_Name": "触电的小鸟",
        "Button_Image_Name": "SGRuffledUp_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFaCaiShen",
        "EN_Name": "Fa Cai Shen",
        "CN_Name": "发财神",
        "Button_Image_Name": "SGFaCaiShen_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBombsAway",
        "EN_Name": "Bombs Away",
        "CN_Name": "炸弹追击",
        "Button_Image_Name": "SGBombsAway_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGGoldRush",
        "EN_Name": "Gold Rush",
        "CN_Name": "淘金疯狂",
        "Button_Image_Name": "SGGoldRush_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGWickedWitch",
        "EN_Name": "Wicked Witch",
        "CN_Name": "巫婆大财",
        "Button_Image_Name": "SGWickedWitch_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGGalacticCash",
        "EN_Name": "Galactic Cash",
        "CN_Name": "银河大战",
        "Button_Image_Name": "SGGalacticCash_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBuggyBonus",
        "EN_Name": "Buggy Bonus",
        "CN_Name": "昆虫宝宝",
        "Button_Image_Name": "SGBuggyBonus_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGKanesInferno",
        "EN_Name": "Kane's Inferno",
        "CN_Name": "凯恩地狱",
        "Button_Image_Name": "SGKanesInferno_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGAllForOne",
        "EN_Name": "All For One",
        "CN_Name": "三剑客",
        "Button_Image_Name": "SGAllForOne_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGArcticWonders",
        "EN_Name": "Arctic Wonders",
        "CN_Name": "北极奇迹",
        "Button_Image_Name": "SGArcticWonders_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGAzlandsGold",
        "EN_Name": "Aztlan's Gold",
        "CN_Name": "亚兹特兰金",
        "Button_Image_Name": "SGAzlandsGold_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBarnstormerBucks",
        "EN_Name": "Barnstormer Bucks",
        "CN_Name": "农场现金",
        "Button_Image_Name": "SGBarnstormerBucks_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBikiniIsland",
        "EN_Name": "Bikini Island",
        "CN_Name": "比基尼岛",
        "Button_Image_Name": "SGBikiniIsland_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGBlackbeardsBounty",
        "EN_Name": "Blackbeard's Bounty",
        "CN_Name": "黑胡子赏金",
        "Button_Image_Name": "SGBlackbeardsBounty_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCarnivalCash",
        "EN_Name": "Carnival Cash",
        "CN_Name": "现金嘉年华",
        "Button_Image_Name": "SGCarnivalCash_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCashosaurus",
        "EN_Name": "Cashosaurus",
        "CN_Name": "土豪恐龙",
        "Button_Image_Name": "SGCashosaurus_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGCashReef",
        "EN_Name": "Cash Reef",
        "CN_Name": "金钱礁",
        "Button_Image_Name": "SGCashReef_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDiscoFunk",
        "EN_Name": "Disco Funk",
        "CN_Name": "舞厅飙舞",
        "Button_Image_Name": "SGDiscoFunk_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDoubleODollars",
        "EN_Name": "Double O Dollars",
        "CN_Name": "双赢密探",
        "Button_Image_Name": "SGDoubleODollars_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDragonsRealm",
        "EN_Name": "Dragon's Realm",
        "CN_Name": "神龙之境",
        "Button_Image_Name": "SGDragonsRealm_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGDrFeelgood",
        "EN_Name": "Dr Feelgood",
        "CN_Name": "好感医生",
        "Button_Image_Name": "SGDrFeelgood.png"
      },
      {
        "HTML5_Game_ID": "SGEgyptianDreams",
        "EN_Name": "Egyptian Dreams",
        "CN_Name": "埃及古梦",
        "Button_Image_Name": "SGEgyptianDreams_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFlyingHigh",
        "EN_Name": "Flying High",
        "CN_Name": "高空飞翔",
        "Button_Image_Name": "SGFlyingHigh_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGFrontierFortunes",
        "EN_Name": "Frontier Fortunes",
        "CN_Name": "边境之福",
        "Button_Image_Name": "SGFrontierFortunes_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGGoldenUnicorn",
        "EN_Name": "Golden Unicorn",
        "CN_Name": "金角兽",
        "Button_Image_Name": "SGGoldenUnicorn_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGGrapeEscape",
        "EN_Name": "Grape Escape",
        "CN_Name": "葡萄越狱",
        "Button_Image_Name": "SGGrapeEscape_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGHauntedHouse",
        "EN_Name": "Haunted House",
        "CN_Name": "鬼屋",
        "Button_Image_Name": "SGHauntedHouse_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGIndianCashCatcher",
        "EN_Name": "Indian Cash Catcher",
        "CN_Name": "印第安追梦",
        "Button_Image_Name": "SGIndianCashCatcher_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGJungleRumble",
        "EN_Name": "Jungle Rumble",
        "CN_Name": "丛林怒吼",
        "Button_Image_Name": "SGJungleRumble_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGKingTutsTomb",
        "EN_Name": "King Tut's Tomb",
        "CN_Name": "图坦卡蒙之墓",
        "Button_Image_Name": "SGKingTutsTomb_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGLittleGreenMoney",
        "EN_Name": "Little Green Money",
        "CN_Name": "太空小绿人",
        "Button_Image_Name": "SGLittleGreenMoney_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMonsterMashCash",
        "EN_Name": "Monster Mash Cash",
        "CN_Name": "怪物聚集",
        "Button_Image_Name": "SGMonsterMashCash_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMrBling",
        "EN_Name": "Mr Bling",
        "CN_Name": "珠光宝气",
        "Button_Image_Name": "SGMrBling_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMummyMoney",
        "EN_Name": "Mummy Money",
        "CN_Name": "金钱木乃伊",
        "Button_Image_Name": "SGMummyMoney_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGMysticFortune",
        "EN_Name": "Mystic Fortune",
        "CN_Name": "神秘宝藏",
        "Button_Image_Name": "SGMysticFortune_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPamperMe",
        "EN_Name": "Pamper Me",
        "CN_Name": "俏佳人",
        "Button_Image_Name": "SGPamperMe_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPiratesPlunder",
        "EN_Name": "Pirate's Plunder",
        "CN_Name": "海盗掠宝",
        "Button_Image_Name": "SGPiratesPlunder_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPoolShark",
        "EN_Name": "Pool Shark",
        "CN_Name": "台球鲨鱼",
        "Button_Image_Name": "SGPoolShark_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGPuckerUpPrince",
        "EN_Name": "Pucker Up Prince",
        "CN_Name": "青蛙王子",
        "Button_Image_Name": "SGPuckerUpPrince_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGQueenOfQueens1024",
        "EN_Name": "Queen of Queens II",
        "CN_Name": "女王至上1024赢法",
        "Button_Image_Name": "SGQueenOfQueens1024_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGQueenOfQueens243",
        "EN_Name": "Queen of Queens",
        "CN_Name": "女王之上",
        "Button_Image_Name": "SGQueenOfQueens243_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGRideEmCowboy",
        "EN_Name": "Ride 'em Cowboy",
        "CN_Name": "幸运牛仔",
        "Button_Image_Name": "SGRideEmCowboy_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGRodeoDrive",
        "EN_Name": "Rodeo Drive",
        "CN_Name": "罗迪欧大道",
        "Button_Image_Name": "SGRodeoDrive_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGShaolinFortunes100",
        "EN_Name": "Shaolin Fortunes 100",
        "CN_Name": "少林宝藏100",
        "Button_Image_Name": "SGShaolinFortunes100_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGShaolinFortunes243",
        "EN_Name": "Shaolin Fortunes",
        "CN_Name": "少林宝藏",
        "Button_Image_Name": "SGShaolinFortunes243_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGShogunsLand",
        "EN_Name": "Shogun's Land",
        "CN_Name": "幕府之地",
        "Button_Image_Name": "SGShogunsLand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSirBlingalot",
        "EN_Name": "Sir Blingalot",
        "CN_Name": "闪亮骑士",
        "Button_Image_Name": "SGSirBlingalot_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSkysTheLimit",
        "EN_Name": "Sky's the Limit",
        "CN_Name": "天空之际",
        "Button_Image_Name": "SGSkysTheLimit_zh-CN.png"
      },
      {"HTML5_Game_ID": "SGSOS", "EN_Name": "S.O.S!", "CN_Name": "求救信号", "Button_Image_Name": "SGSOS_zh-CN.png"},
      {
        "HTML5_Game_ID": "SGSpaceFortune",
        "EN_Name": "Space Fortune",
        "CN_Name": "太空宝藏",
        "Button_Image_Name": "SGSpaceFortune_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGSuperStrike",
        "EN_Name": "Super Strike",
        "CN_Name": "好球",
        "Button_Image_Name": "SGSuperStrike_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTheBigDeal",
        "EN_Name": "The Big Deal",
        "CN_Name": "重要人物",
        "Button_Image_Name": "SGTheBigDeal_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTheDragonCastle",
        "EN_Name": "Dragon Castle",
        "CN_Name": "龙之城堡",
        "Button_Image_Name": "SGTheDragonCastle_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTowerOfPizza",
        "EN_Name": "Tower Of Pizza",
        "CN_Name": "披萨塔",
        "Button_Image_Name": "SGTowerOfPizza_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTreasureDiver",
        "EN_Name": "Treasure Diver",
        "CN_Name": "深海寻宝",
        "Button_Image_Name": "SGTreasureDiver_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGTreasureTomb",
        "EN_Name": "Treasure Tomb",
        "CN_Name": "古墓宝藏",
        "Button_Image_Name": "SGTreasureTomb_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGVikingsPlunder",
        "EN_Name": "Viking's Plunder",
        "CN_Name": "维京掠宝",
        "Button_Image_Name": "SGVikingsPlunder_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGWeirdScience",
        "EN_Name": "Weird Science",
        "CN_Name": "科学怪人",
        "Button_Image_Name": "SGWeirdScience_zh-CN.png"
      },
      {"HTML5_Game_ID": "SGZeus", "EN_Name": "Zeus", "CN_Name": "宙斯", "Button_Image_Name": "SGZeus_zh-CN.png"},
      {
        "HTML5_Game_ID": "SGZeus2",
        "EN_Name": "Zeus 2",
        "CN_Name": "宙斯2",
        "Button_Image_Name": "SGZeus2_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "CaribbeanHoldem",
        "EN_Name": "Caribbean Hold'Em",
        "CN_Name": "赌场德州扑克",
        "Button_Image_Name": "CaribbeanHoldem_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "CaribbeanStud",
        "EN_Name": "Caribbean Stud",
        "CN_Name": "加勒比扑克",
        "Button_Image_Name": "CaribbeanStud_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BlackJack3H",
        "EN_Name": "Blackjack 3 Hand",
        "CN_Name": "三手黑杰克",
        "Button_Image_Name": "BlackJack3H_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BlackJack3HDoubleExposure",
        "EN_Name": "Blackjack Double Exposure 3 Hand",
        "CN_Name": "三手双重曝光",
        "Button_Image_Name": "BlackJack3HDoubleExposure"
      },

      {
        "HTML5_Game_ID": "EURoulette",
        "EN_Name": "European Roulette",
        "CN_Name": "欧洲轮盘",
        "Button_Image_Name": "EURoulette_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AmericanBaccarat",
        "EN_Name": "American Baccarat",
        "CN_Name": "美国百家乐",
        "Button_Image_Name": "AmericanBaccarat_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "Baccarat3HZC",
        "EN_Name": "American Baccarat Zero Commission",
        "CN_Name": "免佣百家乐",
        "Button_Image_Name": "Baccarat3HZC_zh-CN.png"
      },
      {"HTML5_Game_ID": "Sicbo", "EN_Name": "Sicbo", "CN_Name": "骰宝", "Button_Image_Name": "Sicbo_zh-CN.png"},
      {
        "HTML5_Game_ID": "TGThreeCardPoker",
        "EN_Name": "Three Card Poker",
        "CN_Name": "三张牌扑克",
        "Button_Image_Name": "TGThreeCardPoker_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "TGThreeCardPokerDeluxe",
        "EN_Name": "Three Card Poker Deluxe",
        "CN_Name": "三张牌扑克豪华版",
        "Button_Image_Name": "TGThreeCardPokerDeluxe_zh-CN.png"
      },
      {"HTML5_Game_ID": "TGWar", "EN_Name": "War", "CN_Name": "战争", "Button_Image_Name": "TGWar_zh-CN.png"},
      {
        "HTML5_Game_ID": "TGDragonTiger",
        "EN_Name": "Dragon Tiger",
        "CN_Name": "龙虎",
        "Button_Image_Name": "TGDragonTiger_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AcesandEights1Hand",
        "EN_Name": "Aces & Eights 1 Hand",
        "CN_Name": "经典扑克1手",
        "Button_Image_Name": "AcesandEights1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AcesandEights5Hand",
        "EN_Name": "Aces & Eights 5 Hand",
        "CN_Name": "经典扑克5手",
        "Button_Image_Name": "AcesandEights5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AcesandEights10Hand",
        "EN_Name": "Aces & Eights 10 Hand",
        "CN_Name": "经典扑克10手",
        "Button_Image_Name": "AcesandEights10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AcesandEights50Hand",
        "EN_Name": "Aces & Eights 50 Hand",
        "CN_Name": "经典扑克50手",
        "Button_Image_Name": "AcesandEights50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AcesandEights100Hand",
        "EN_Name": "Aces & Eights 100 Hand",
        "CN_Name": "经典扑克100手",
        "Button_Image_Name": "AcesandEights100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AllAmericanPoker1Hand",
        "EN_Name": "All American Poker 1 Hand",
        "CN_Name": "美国扑克1手",
        "Button_Image_Name": "AllAmericanPoker1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AllAmericanPoker5Hand",
        "EN_Name": "All American Poker 5 Hand",
        "CN_Name": "美国扑克5手",
        "Button_Image_Name": "AllAmericanPoker5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AllAmericanPoker10Hand",
        "EN_Name": "All American Poker 10 Hand",
        "CN_Name": "美国扑克10手",
        "Button_Image_Name": "AllAmericanPoker10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AllAmericanPoker50Hand",
        "EN_Name": "All American Poker 50 Hand",
        "CN_Name": "美国扑克50手",
        "Button_Image_Name": "AllAmericanPoker50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "AllAmericanPoker100Hand",
        "EN_Name": "All American Poker 100 Hand",
        "CN_Name": "美国扑克100手",
        "Button_Image_Name": "AllAmericanPoker100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusDuecesWild1Hand",
        "EN_Name": "Bonus Deuces Wild 1 Hand",
        "CN_Name": "红利百搭扑克1手",
        "Button_Image_Name": "BonusDuecesWild1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusDuecesWild5Hand",
        "EN_Name": "Bonus Deuces Wild 5 Hand",
        "CN_Name": "红利百搭扑克5手",
        "Button_Image_Name": "BonusDuecesWild5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusDuecesWild10Hand",
        "EN_Name": "Bonus Deuces Wild 10 Hand",
        "CN_Name": "红利百搭扑克10手",
        "Button_Image_Name": "BonusDuecesWild10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusDuecesWild50Hand",
        "EN_Name": "Bonus Deuces Wild 50 Hand",
        "CN_Name": "红利百搭扑克50手",
        "Button_Image_Name": "BonusDuecesWild50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusDuecesWild100Hand",
        "EN_Name": "Bonus Deuces Wild 100 Hand",
        "CN_Name": "红利百搭扑克100手",
        "Button_Image_Name": "BonusDuecesWild100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusPoker1Hand",
        "EN_Name": "Bonus Poker 1 Hand",
        "CN_Name": "红利扑克1手",
        "Button_Image_Name": "BonusPoker1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusPoker5Hand",
        "EN_Name": "Bonus Poker 5 Hand",
        "CN_Name": "红利扑克5手",
        "Button_Image_Name": "BonusPoker5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusPoker10Hand",
        "EN_Name": "Bonus Poker 10 Hand",
        "CN_Name": "红利扑克10手",
        "Button_Image_Name": "BonusPoker10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusPoker50Hand",
        "EN_Name": "Bonus Poker 50 Hand",
        "CN_Name": "红利扑克50手",
        "Button_Image_Name": "BonusPoker50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "BonusPoker100Hand",
        "EN_Name": "Bonus Poker 100 Hand",
        "CN_Name": "红利扑克100手",
        "Button_Image_Name": "BonusPoker100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DuecesWild1Hand",
        "EN_Name": "Deuces Wild 1 Hand",
        "CN_Name": "百搭二王扑克1手",
        "Button_Image_Name": "DuecesWild1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DuecesWild5Hand",
        "EN_Name": "Deuces Wild 5 Hand",
        "CN_Name": "百搭二王扑克5手",
        "Button_Image_Name": "DuecesWild5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DuecesWild10Hand",
        "EN_Name": "Deuces Wild 10 Hand",
        "CN_Name": "百搭二王扑克10手",
        "Button_Image_Name": "DuecesWild10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DuecesWild50Hand",
        "EN_Name": "Deuces Wild 50 Hand",
        "CN_Name": "百搭二王扑克50手",
        "Button_Image_Name": "DuecesWild50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DuecesWild100Hand",
        "EN_Name": "Deuces Wild 100 Hand",
        "CN_Name": "百搭二王扑克100手",
        "Button_Image_Name": "DuecesWild100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleBonusPoker1Hand",
        "EN_Name": "Double Bonus Poker 1 Hand",
        "CN_Name": "双倍红利扑克1手",
        "Button_Image_Name": "DoubleBonusPoker1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleBonusPoker5Hand",
        "EN_Name": "Double Bonus Poker 5 Hand",
        "CN_Name": "双倍红利扑克5手",
        "Button_Image_Name": "DoubleBonusPoker5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleBonusPoker10Hand",
        "EN_Name": "Double Bonus Poker 10 Hand",
        "CN_Name": "双倍红利扑克10手",
        "Button_Image_Name": "DoubleBonusPoker10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleBonusPoker50Hand",
        "EN_Name": "Double Bonus Poker 50 Hand",
        "CN_Name": "双倍红利扑克50手",
        "Button_Image_Name": "DoubleBonusPoker50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleBonusPoker100Hand",
        "EN_Name": "Double Bonus Poker 100 Hand",
        "CN_Name": "双倍红利扑克100手",
        "Button_Image_Name": "DoubleBonusPoker100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleDoubleBonusPoker1Hand",
        "EN_Name": "Double Double Bonus Poker 1 Hand",
        "CN_Name": "四倍红利扑克1手",
        "Button_Image_Name": "DoubleDoubleBonusPoker1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleDoubleBonusPoker5Hand",
        "EN_Name": "Double Double Bonus Poker 5 Hand",
        "CN_Name": "四倍红利扑克5手",
        "Button_Image_Name": "DoubleDoubleBonusPoker5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleDoubleBonusPoker10Hand",
        "EN_Name": "Double Double Bonus Poker 10 Hand",
        "CN_Name": "四倍红利扑克10手",
        "Button_Image_Name": "DoubleDoubleBonusPoker10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleDoubleBonusPoker50Hand",
        "EN_Name": "Double Double Bonus Poker 50 Hand",
        "CN_Name": "四倍红利扑克50手",
        "Button_Image_Name": "DoubleDoubleBonusPoker50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "DoubleDoubleBonusPoker100Hand",
        "EN_Name": "Double Double Bonus Poker 100 Hand",
        "CN_Name": "四倍红利扑克100手",
        "Button_Image_Name": "DoubleDoubleBonusPoker100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JacksorBetter1Hand",
        "EN_Name": "Jacks or Better 1 Hand",
        "CN_Name": "杰克高手1手",
        "Button_Image_Name": "JacksorBetter1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JacksorBetter5Hand",
        "EN_Name": "Jacks or Better 5 Hand",
        "CN_Name": "杰克高手5手",
        "Button_Image_Name": "JacksorBetter5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JacksorBetter10Hand",
        "EN_Name": "Jacks or Better 10 Hand",
        "CN_Name": "杰克高手10手",
        "Button_Image_Name": "JacksorBetter10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JacksorBetter50Hand",
        "EN_Name": "Jacks or Better 50 Hand",
        "CN_Name": "杰克高手50手",
        "Button_Image_Name": "JacksorBetter50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JacksorBetter100Hand",
        "EN_Name": "Jacks or Better 100 Hand",
        "CN_Name": "杰克高手100手",
        "Button_Image_Name": "JacksorBetter100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JokerPoker1Hand",
        "EN_Name": "Joker Poker 1 Hand",
        "CN_Name": "小丑扑克1手",
        "Button_Image_Name": "JokerPoker1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JokerPoker5Hand",
        "EN_Name": "Joker Poker 5 Hand",
        "CN_Name": "小丑扑克5手",
        "Button_Image_Name": "JokerPoker5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JokerPoker10Hand",
        "EN_Name": "Joker Poker 10 Hand",
        "CN_Name": "小丑扑克10手",
        "Button_Image_Name": "JokerPoker10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JokerPoker50Hand",
        "EN_Name": "Joker Poker 50 Hand",
        "CN_Name": "小丑扑克50手",
        "Button_Image_Name": "JokerPoker50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "JokerPoker100Hand",
        "EN_Name": "Joker Poker 100 Hand",
        "CN_Name": "小丑扑克100手",
        "Button_Image_Name": "JokerPoker100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "TensorBetter1Hand",
        "EN_Name": "Tens Or Better 1 Hand",
        "CN_Name": "对十高手扑克1手",
        "Button_Image_Name": "TensorBetter1Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "TensorBetter5Hand",
        "EN_Name": "Tens Or Better 5 Hand",
        "CN_Name": "对十高手扑克5手",
        "Button_Image_Name": "TensorBetter5Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "TensorBetter10Hand",
        "EN_Name": "Tens Or Better 10 Hand",
        "CN_Name": "对十高手扑克10手",
        "Button_Image_Name": "TensorBetter10Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "TensorBetter50Hand",
        "EN_Name": "Tens Or Better 50 Hand",
        "CN_Name": "对十高手扑克50手",
        "Button_Image_Name": "TensorBetter50Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "TensorBetter100Hand",
        "EN_Name": "Tens Or Better 100 Hand",
        "CN_Name": "对十高手扑克100手",
        "Button_Image_Name": "TensorBetter100Hand_zh-CN.png"
      },
      {
        "HTML5_Game_ID": "SGNaughtySanta",
        "EN_Name": "Carnival christmas",
        "CN_Name": "狂欢圣诞",
        "Button_Image_Name": "SGNaughtySanta_zh-CN.png"
      },


    ]
  }, {
    "type": 4,
    "game_id": 409,
    "game_api": "MG",
    "name": "MG老虎机",
    "imgPath": "https://newpic.sdcrhb.com/games/mg.png",
    "icon": "logo-mg",
    "game_list_json": "MG_game_list",
    "game_list_img_path": "/new/slot_res/mg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：108好汉、亚洲美人、大逃杀、冒险宫殿、靶心、抢劫银行、欢乐节、昆虫派对...",
    "labels": "#老虎机#108好汉#亚洲美人#大逃杀#冒险宫殿#靶心#抢劫银行#欢乐节#昆虫派对",
    gamelist: [
        {
      "EN_Name": "108 Heroes",
      "CN_Name": "108好汉",
      "HTML5_Game_ID": "1302",
      "Button_Image_Name": "BTN_108Heroes_ZH.png"
    }, {
      "EN_Name": "108 Heroes Multiplier Fortunes",
      "CN_Name": "108好汉乘数财富",
      "HTML5_Game_ID": "1897",
      "Button_Image_Name": "BTN_108HeroesMF_ZH.png"
    }, {
      "EN_Name": "5 Reel Drive",
      "CN_Name": "5卷的驱动器",
      "HTML5_Game_ID": "1035",
      "Button_Image_Name": "BTN_5ReelDrive1.png"
    }, {
      "EN_Name": "Adventure Palace HD",
      "CN_Name": "冒险宫殿",
      "HTML5_Game_ID": "1010",
      "Button_Image_Name": "BTN_AdventurePalaceHD.png"
    }, {
      "EN_Name": "Age of Discovery",
      "CN_Name": "史地大发现",
      "HTML5_Game_ID": "1246",
      "Button_Image_Name": "BTN_AgeofDiscovery3.png"
    }, {
      "EN_Name": "Agent Jane Blonde",
      "CN_Name": "特务珍金",
      "HTML5_Game_ID": "1155",
      "Button_Image_Name": "BTN_AgentJaneBlonde7.png"
    }, {
      "EN_Name": "Alaskan Fishing",
      "CN_Name": "阿拉斯加捕捞",
      "HTML5_Game_ID": "1004",
      "Button_Image_Name": "BTN_AlaskanFishing1.png"
    }, {
      "EN_Name": "Ariana",
      "CN_Name": "阿拉斯加捕捞",
      "HTML5_Game_ID": "1004",
      "Button_Image_Name": "BTN_Ariana_ZH.png"
    }, {
      "EN_Name": "Asian Beauty",
      "CN_Name": "亚洲美人",
      "HTML5_Game_ID": "1384",
      "Button_Image_Name": "BTN_AsianBeauty1.png"
    }, {
      "EN_Name": "Badminton Hero",
      "CN_Name": "热血羽球",
      "HTML5_Game_ID": "2061",
      "Button_Image_Name": "BTN_BadmintonHero_ZH.jpg"
    }, {
      "EN_Name": "Bar Bar Black Sheep 5 Reel",
      "CN_Name": "黑绵羊咩咩叫5轴",
      "HTML5_Game_ID": "1788",
      "Button_Image_Name": "BTN_BarBarBlackSheep5Reel_ZH.png"
    }, {
      "EN_Name": "Basketball Star",
      "CN_Name": "篮球巨星",
      "HTML5_Game_ID": "1159",
      "Button_Image_Name": "BTN_BasketballStar_ZH.png"
    }, {
      "EN_Name": "Battle Royale",
      "CN_Name": "大逃杀",
      "HTML5_Game_ID": "1995",
      "Button_Image_Name": "BTN_BattleRoyale_ZH.png"
    }, {
      "EN_Name": "Beautiful Bones",
      "CN_Name": "美丽骷髅",
      "HTML5_Game_ID": "1890",
      "Button_Image_Name": "BTN_BeautifulBones_ZH.png"
    }, {
      "EN_Name": "Big Kahuna",
      "CN_Name": "征服钱海",
      "HTML5_Game_ID": "1399",
      "Button_Image_Name": "BTN_BigKahuna1.png"
    }, {
      "EN_Name": "Big Top",
      "CN_Name": "马戏篷",
      "HTML5_Game_ID": "1133",
      "Button_Image_Name": "BTN_BigTop1.png"
    }, {
      "EN_Name": "Bikini Party",
      "CN_Name": "比基尼派对",
      "HTML5_Game_ID": "1290",
      "Button_Image_Name": "BTN_BikiniParty_ZH.png"
    }, {
      "EN_Name": "Boogie Monsters",
      "CN_Name": "搏击怪物",
      "HTML5_Game_ID": "1733",
      "Button_Image_Name": "BTN_BoogieMonsters.png"
    }, {
      "EN_Name": "Break Away",
      "CN_Name": "冰球突破",
      "HTML5_Game_ID": "1229",
      "Button_Image_Name": "BTN_BreakAway1.png"
    }, {
      "EN_Name": "Break Da Bank",
      "CN_Name": "抢银行",
      "HTML5_Game_ID": "1023",
      "Button_Image_Name": "BTN_BreakDaBank1.png"
    }, {
      "EN_Name": "Bulls Eye",
      "CN_Name": "靶心",
      "HTML5_Game_ID": "1718",
      "Button_Image_Name": "BTN_Bullseye_Gameshow.png"
    }, {
      "EN_Name": "Burning Desire",
      "CN_Name": "燃烧的欲望",
      "HTML5_Game_ID": "1318",
      "Button_Image_Name": "BTN_BurningDesire1.png"
    }, {
      "EN_Name": "Bush Telegraph",
      "CN_Name": "丛林摇摆",
      "HTML5_Game_ID": "1173",
      "Button_Image_Name": "BTN_BushTelegraph1.png"
    }, {
      "EN_Name": "Bust the Bank",
      "CN_Name": "抢劫银行",
      "HTML5_Game_ID": "1204",
      "Button_Image_Name": "BTN_BustTheBank1.png"
    }, {
      "EN_Name": "Candy Dreams",
      "CN_Name": "梦果子乐园",
      "HTML5_Game_ID": "1886",
      "Button_Image_Name": "BTN_CandyDreams_ZH.png"
    }, {
      "EN_Name": "Carnaval",
      "CN_Name": "狂欢节",
      "HTML5_Game_ID": "1117",
      "Button_Image_Name": "BTN_Carnaval2.png"
    }, {
      "EN_Name": "Cash Crazy",
      "CN_Name": "财运疯狂",
      "HTML5_Game_ID": "1393",
      "Button_Image_Name": "BTN_CashCrazy9.png"
    }, {
      "EN_Name": "Cashapillar",
      "CN_Name": "昆虫派对",
      "HTML5_Game_ID": "1366",
      "Button_Image_Name": "BTN_Cashapillar1.png"
    }, {
      "EN_Name": "CashOccino",
      "CN_Name": "现金咖啡",
      "HTML5_Game_ID": "1996",
      "Button_Image_Name": "BTN_CashOccino_ZH.png"
    }, {
      "EN_Name": "Cashville",
      "CN_Name": "挥金如土",
      "HTML5_Game_ID": "1197",
      "Button_Image_Name": "BTN_Cashville1.png"
    }, {
      "EN_Name": "Centre Court",
      "CN_Name": "中心球场",
      "HTML5_Game_ID": "1291",
      "Button_Image_Name": "BTN_CentreCourt1.png"
    }, {
      "EN_Name": "Classic 243",
      "CN_Name": "经典243",
      "HTML5_Game_ID": "1879",
      "Button_Image_Name": "BTN_Classic243.png"
    }, {
      "EN_Name": "Cool Buck - 5 Reel",
      "CN_Name": "运财酷儿-5卷轴",
      "HTML5_Game_ID": "1884",
      "Button_Image_Name": "BTN_CoolBuck_5Reel_ZH.png"
    }, {
      "EN_Name": "Cool Wolf",
      "CN_Name": "酷狼",
      "HTML5_Game_ID": "1084",
      "Button_Image_Name": "BTN_CoolWolf3.png"
    }, {
      "EN_Name": "Couch Potato",
      "CN_Name": "沙发土豆",
      "HTML5_Game_ID": "1327",
      "Button_Image_Name": "BTN_CouchPotato2.png"
    }, {
      "EN_Name": "Crazy Chameleons",
      "CN_Name": "疯狂变色龙",
      "HTML5_Game_ID": "1202",
      "Button_Image_Name": "BTN_CrazyChameleons1.png"
    }, {
      "EN_Name": "Cricket Star",
      "CN_Name": "板球明星",
      "HTML5_Game_ID": "1075",
      "Button_Image_Name": "BTN_CricketStar.png"
    }, {
      "EN_Name": "Deck The Halls",
      "CN_Name": "闪亮的圣诞节",
      "HTML5_Game_ID": "1234",
      "Button_Image_Name": "BTN_DeckTheHalls1.png"
    }, {
      "EN_Name": "Deco Diamonds",
      "CN_Name": "德科钻石",
      "HTML5_Game_ID": "2047",
      "Button_Image_Name": "BTN_DecoDiamonds_ZH.png"
    }, {
      "EN_Name": "Deuces Wild",
      "CN_Name": "万能两点",
      "HTML5_Game_ID": "1415",
      "Button_Image_Name": "BTN_DeucesWildPowerPoker1.png"
    }, {
      "EN_Name": "Diamond Empire",
      "CN_Name": "钻石帝国",
      "HTML5_Game_ID": "1949",
      "Button_Image_Name": "BTN_DiamondEmpire_ZH.png"
    }, {
      "EN_Name": "Dolphin Coast",
      "CN_Name": "海豚海岸",
      "HTML5_Game_ID": "2065",
      "Button_Image_Name": "BTN_DolphinCoast_ZH.png"
    }, {
      "EN_Name": "Dolphin Quest",
      "CN_Name": "寻访海豚",
      "HTML5_Game_ID": "1309",
      "Button_Image_Name": "BTN_DolphinQuest.png"
    }, {
      "EN_Name": "Double Wammy",
      "CN_Name": "双重韦密",
      "HTML5_Game_ID": "1102",
      "Button_Image_Name": "BTN_DoubleWammy1.png"
    }, {
      "EN_Name": "Dragon Dance",
      "CN_Name": "舞龙",
      "HTML5_Game_ID": "1037",
      "Button_Image_Name": "BTN_DragonDance_ZH.png"
    }, {
      "EN_Name": "Dragonz",
      "CN_Name": "幸运龙宝贝",
      "HTML5_Game_ID": "1424",
      "Button_Image_Name": "BTN_Dragonz_sc.png"
    }, {
      "EN_Name": "Dream Date",
      "CN_Name": "梦幻邂逅",
      "HTML5_Game_ID": "1948",
      "Button_Image_Name": "BTN_DreamDate_ZH.png"
    }, {
      "EN_Name": "Eagles Wings",
      "CN_Name": "老鹰的翅膀",
      "HTML5_Game_ID": "1236",
      "Button_Image_Name": "BTN_EaglesWings1.png"
    }, {
      "EN_Name": "EmotiCoins",
      "CN_Name": "表情金币",
      "HTML5_Game_ID": "1895",
      "Button_Image_Name": "BTN_EmotiCoins_ZH.png"
    }, {
      "EN_Name": "Emperor of The Sea",
      "CN_Name": "青龙出海",
      "HTML5_Game_ID": "1882",
      "Button_Image_Name": "BTN_EmperorOfTheSea_ZH.png"
    }, {
      "EN_Name": "Exotic Cats",
      "CN_Name": "异域狂兽",
      "HTML5_Game_ID": "2060",
      "Button_Image_Name": "BTN_ExoticCats_ZH.png"
    }, {
      "EN_Name": "Fish Party",
      "CN_Name": "派对鱼",
      "HTML5_Game_ID": "1113",
      "Button_Image_Name": "BTN_FishParty.png"
    }, {
      "EN_Name": "Football Star",
      "CN_Name": "足球明星",
      "HTML5_Game_ID": "1186",
      "Button_Image_Name": "BTN_footballstar1.png"
    }, {
      "EN_Name": "Forbidden Throne",
      "CN_Name": "禁忌王座",
      "HTML5_Game_ID": "1887",
      "Button_Image_Name": "BTN_ForbiddenThrone.png"
    }, {
      "EN_Name": "Fortune Girl",
      "CN_Name": "金库甜心",
      "HTML5_Game_ID": "1888",
      "Button_Image_Name": "BTN_FortuneGirl_ZH.png"
    }, {
      "EN_Name": "Fortunium",
      "CN_Name": "财富之都",
      "HTML5_Game_ID": "1993",
      "Button_Image_Name": "BTN_Fortunium_ZH.png"
    }, {
      "EN_Name": "Fruit Blast",
      "CN_Name": "水果大爆发",
      "HTML5_Game_ID": "1943",
      "Button_Image_Name": "BTN_FruitBlast_ZH.png"
    }, {
      "EN_Name": "Fruit Vs Candy",
      "CN_Name": "水果VS糖果",
      "HTML5_Game_ID": "1878",
      "Button_Image_Name": "BTN_FruitVsCandy_ZH.png"
    }, {
      "EN_Name": "Gems Odyssey",
      "CN_Name": "宝石奥德赛",
      "HTML5_Game_ID": "1945",
      "Button_Image_Name": "BTN_GemsOdyssey_ZH.png"
    }, {
      "EN_Name": "Girls With Guns-L-Jungle Heat",
      "CN_Name": "美女枪手丛林激战",
      "HTML5_Game_ID": "1313",
      "Button_Image_Name": "BTN_GirlswithGuns1.png"
    }, {
      "EN_Name": "Gnome Wood",
      "CN_Name": "矮木头",
      "HTML5_Game_ID": "1900",
      "Button_Image_Name": "BTN_GnomeWood_ZH.png"
    }, {
      "EN_Name": "Gold Factory",
      "CN_Name": "黄金工厂",
      "HTML5_Game_ID": "1267",
      "Button_Image_Name": "BTN_GoldFactory_ZH.png"
    }, {
      "EN_Name": "Golden Era",
      "CN_Name": "黄金时代",
      "HTML5_Game_ID": "1041",
      "Button_Image_Name": "BTN_GoldenEra.png"
    }, {
      "EN_Name": "Golden Princess",
      "CN_Name": "黄金公主",
      "HTML5_Game_ID": "1190",
      "Button_Image_Name": "BTN_GoldenPrincess.png"
    }, {
      "EN_Name": "Gopher Gold",
      "CN_Name": "黄金囊地鼠",
      "HTML5_Game_ID": "1216",
      "Button_Image_Name": "BTN_GopherGold2.png"
    }, {
      "EN_Name": "Halloween",
      "CN_Name": "万圣劫",
      "HTML5_Game_ID": "1904",
      "Button_Image_Name": "BTN_Halloween.png"
    }, {
      "EN_Name": "Halloweenies",
      "CN_Name": "万圣节",
      "HTML5_Game_ID": "1047",
      "Button_Image_Name": "BTN_Halloweenies1.png"
    }, {
      "EN_Name": "Happy Holidays",
      "CN_Name": "快乐假日",
      "HTML5_Game_ID": "1072",
      "Button_Image_Name": "BTN_HappyHolidays_ZH.png"
    }, {
      "EN_Name": "Harveys",
      "CN_Name": "哈维斯的晚餐",
      "HTML5_Game_ID": "1139",
      "Button_Image_Name": "BTN_Harveys1.png"
    }, {
      "EN_Name": "Hellboy",
      "CN_Name": "地狱男爵",
      "HTML5_Game_ID": 70529,
      "Button_Image_Name": "BTN_HellBoy1.png"
    }, {
      "EN_Name": "High Society",
      "CN_Name": "上流社会",
      "HTML5_Game_ID": "1163",
      "Button_Image_Name": "BTN_HighSociety.png"
    }, {
      "EN_Name": "Highlander",
      "CN_Name": "时空英豪",
      "HTML5_Game_ID": "1909",
      "Button_Image_Name": "BTN_Highlander_ZH.png"
    }, {
      "EN_Name": "HitMan",
      "CN_Name": "终极杀手",
      "HTML5_Game_ID": "1321",
      "Button_Image_Name": "BTN_Hitman1.png"
    }, {
      "EN_Name": "Holly Jolly Penguins",
      "CN_Name": "圣诞企鹅",
      "HTML5_Game_ID": "1910",
      "Button_Image_Name": "BTN_HollyJollyPenguins_ZH.png"
    }, {
      "EN_Name": "Hound Hotel",
      "CN_Name": "酷犬酒店",
      "HTML5_Game_ID": "1063",
      "Button_Image_Name": "BTN_HoundHotel_ZH.png"
    }, {
      "EN_Name": "Huangdi - The Yellow Emperor",
      "CN_Name": "轩辕帝传",
      "HTML5_Game_ID": "1849",
      "Button_Image_Name": "BTN_Huangdi_TheYellowEmperor_zh.png"
    }, {
      "EN_Name": "Immortal Romance",
      "CN_Name": "不朽情缘",
      "HTML5_Game_ID": "1103",
      "Button_Image_Name": "BTN_ImmortalRomance1.png"
    }, {
      "EN_Name": "Jungle Jim EL Dorado",
      "CN_Name": "丛林吉姆-黄金国",
      "HTML5_Game_ID": "1244",
      "Button_Image_Name": "BTN_Junglejim_zh.png"
    }, {
      "EN_Name": "Jurassic World",
      "CN_Name": "侏罗纪世界",
      "HTML5_Game_ID": "1891",
      "Button_Image_Name": "BTN_JurassicWorld.png"
    }, {
      "EN_Name": "Karaoke Party",
      "CN_Name": "K歌乐韵",
      "HTML5_Game_ID": "1053",
      "Button_Image_Name": "BTN_KaraokeParty_ZH.png"
    }, {
      "EN_Name": "Kathmandu",
      "CN_Name": "卡萨缦都",
      "HTML5_Game_ID": "1151",
      "Button_Image_Name": "BTN_Kathmandu1.png"
    }, {
      "EN_Name": "King Tusk",
      "CN_Name": "大象之王",
      "HTML5_Game_ID": "1908",
      "Button_Image_Name": "BTN_KingTusk_ZH.png"
    }, {
      "EN_Name": "Kings of Cash",
      "CN_Name": "现金之王",
      "HTML5_Game_ID": "1400",
      "Button_Image_Name": "BTN_KingsofCash1.png"
    }, {
      "EN_Name": "Kitty Cabana",
      "CN_Name": "凯蒂卡巴拉",
      "HTML5_Game_ID": "1286",
      "Button_Image_Name": "BTN_KittyCabana_ZH.png"
    }, {
      "EN_Name": "Ladies Nite",
      "CN_Name": "女仕之夜",
      "HTML5_Game_ID": "1389",
      "Button_Image_Name": "BTN_LadiesNite5.png"
    }, {
      "EN_Name": "Lady in Red",
      "CN_Name": "红衣女郎",
      "HTML5_Game_ID": "1124",
      "Button_Image_Name": "BTN_LadyInRed2.png"
    }, {
      "EN_Name": "Life of Riches",
      "CN_Name": "富裕人生",
      "HTML5_Game_ID": "1851",
      "Button_Image_Name": "BTN_LifeOfRiches_zh.png"
    }, {
      "EN_Name": "Liquid Gold",
      "CN_Name": "液体黄金",
      "HTML5_Game_ID": "1756",
      "Button_Image_Name": "BTN_LiquidGold.png"
    }, {
      "EN_Name": "Loaded",
      "CN_Name": "炫富一族",
      "HTML5_Game_ID": "1245",
      "Button_Image_Name": "BTN_Loaded1.png"
    }, {
      "EN_Name": "Lost Vegas",
      "CN_Name": "迷失拉斯维加斯",
      "HTML5_Game_ID": "1420",
      "Button_Image_Name": "BTN_LostVegas_zh.png"
    }, {
      "EN_Name": "Lucky Koi",
      "CN_Name": "幸运的锦鲤",
      "HTML5_Game_ID": "1060",
      "Button_Image_Name": "BTN_LuckyKoi.png"
    }, {
      "EN_Name": "Lucky Little Gods",
      "CN_Name": "宝贝财神",
      "HTML5_Game_ID": "1944",
      "Button_Image_Name": "BTN_LuckyLittleGods_ZH.png"
    }, {
      "EN_Name": "Lucky Twins",
      "CN_Name": "幸运双星",
      "HTML5_Game_ID": "1283",
      "Button_Image_Name": "BTN_luckyTwins_ZH.png"
    }, {
      "EN_Name": "Lucky Zodiac",
      "CN_Name": "幸运生肖",
      "HTML5_Game_ID": "1273",
      "Button_Image_Name": "BTN_LuckyZodiac_ZH.png"
    }, {
      "EN_Name": "Mad Hatters",
      "CN_Name": "疯狂的帽子",
      "HTML5_Game_ID": "1314",
      "Button_Image_Name": "BTN_MadHatters1.png"
    }, {
      "EN_Name": "Mayan Princess",
      "CN_Name": "玛雅公主",
      "HTML5_Game_ID": "1343",
      "Button_Image_Name": "BTN_MayanPrincess1.png"
    }, {
      "EN_Name": "Mega Money Multiplier",
      "CN_Name": "巨额现金乘数",
      "HTML5_Game_ID": "1885",
      "Button_Image_Name": "BTN_MegaMoneyMultiplier_ZH.png"
    }, {
      "EN_Name": "Mega Money Rush",
      "CN_Name": "巨款大冲击",
      "HTML5_Game_ID": "1942",
      "Button_Image_Name": "BTN_MegaMoneyRush_ZH.png"
    }, {
      "EN_Name": "Mermaids Millions",
      "CN_Name": "海底世界",
      "HTML5_Game_ID": "1308",
      "Button_Image_Name": "BTN_MermaidsMillions1.png"
    }, {
      "EN_Name": "Moby Dick",
      "CN_Name": "白鲸记",
      "HTML5_Game_ID": "1905",
      "Button_Image_Name": "BTN_MobyDick.png"
    }, {
      "EN_Name": "Munchkins",
      "CN_Name": "怪兽曼琪肯",
      "HTML5_Game_ID": "1008",
      "Button_Image_Name": "BTN_Munchkins2.png"
    }, {
      "EN_Name": "Mystic Dreams",
      "CN_Name": "神秘梦境",
      "HTML5_Game_ID": "1254",
      "Button_Image_Name": "BTN_MysticDreams1.png"
    }, {
      "EN_Name": "Oink Country Love",
      "CN_Name": "呼撸撸爱上乡下",
      "HTML5_Game_ID": "1896",
      "Button_Image_Name": "BTN_OinkCountryLove_ZH.png"
    }, {
      "EN_Name": "Party Island",
      "CN_Name": "派对岛",
      "HTML5_Game_ID": "1737",
      "Button_Image_Name": "BTN_PartyIsland.png"
    }, {
      "EN_Name": "Pistoleras",
      "CN_Name": "持枪王者",
      "HTML5_Game_ID": "1160",
      "Button_Image_Name": "BTN_Pistoleras_ZH.png"
    }, {
      "EN_Name": "Playboy",
      "CN_Name": "花花公子",
      "HTML5_Game_ID": "1188",
      "Button_Image_Name": "BTN_Playboy.png"
    }, {
      "EN_Name": "Playboy Gold",
      "CN_Name": "黄金花花公子",
      "HTML5_Game_ID": "1946",
      "Button_Image_Name": "BTN_PlayboyGold_ZH.png"
    }, {
      "EN_Name": "Poke The Guy",
      "CN_Name": "进击的猿人",
      "HTML5_Game_ID": "1954",
      "Button_Image_Name": "BTN_PokeTheGuy_ZH.png"
    }, {
      "EN_Name": "Pollen Party",
      "CN_Name": "花粉之国",
      "HTML5_Game_ID": "1881",
      "Button_Image_Name": "BTN_PollenParty_ZH.png"
    }, {
      "EN_Name": "Pretty Kitty",
      "CN_Name": "漂亮猫咪",
      "HTML5_Game_ID": "1045",
      "Button_Image_Name": "BTN_prettykitty_zh.png"
    }, {
      "EN_Name": "Pure Platinum",
      "CN_Name": "纯铂",
      "HTML5_Game_ID": "1312",
      "Button_Image_Name": "BTN_PurePlatinum1.png"
    }, {
      "EN_Name": "Reel Gems",
      "CN_Name": "宝石迷阵",
      "HTML5_Game_ID": 68778,
      "Button_Image_Name": "BTN_ReelGems1.png"
    }, {
      "EN_Name": "Reel Spinner",
      "CN_Name": "旋转大战",
      "HTML5_Game_ID": "1294",
      "Button_Image_Name": "BTN_ReelSpinner_zh.png"
    }, {
      "EN_Name": "Reel Strike",
      "CN_Name": "卷行使价",
      "HTML5_Game_ID": "1157",
      "Button_Image_Name": "BTN_ReelStrike1.png"
    }, {
      "EN_Name": "Reel Talent",
      "CN_Name": "真正高手",
      "HTML5_Game_ID": "2070",
      "Button_Image_Name": "BTN_ReelTalent_ZH.jpg"
    }, {
      "EN_Name": "Reel Thunder",
      "CN_Name": "雷电击",
      "HTML5_Game_ID": "1293",
      "Button_Image_Name": "BTN_ReelThunder2.png"
    }, {
      "EN_Name": "Retro Reels",
      "CN_Name": "复古旋转",
      "HTML5_Game_ID": "1110",
      "Button_Image_Name": "BTN_RetroReels1.png"
    }, {
      "EN_Name": "Retro Reels - Extreme Heat",
      "CN_Name": "复古卷轴-极热",
      "HTML5_Game_ID": "1189",
      "Button_Image_Name": "BTN_RRExtreme1.png"
    }, {
      "EN_Name": "Retro Reels Diamond Glitz",
      "CN_Name": "复古卷轴钻石耀眼",
      "HTML5_Game_ID": "1100",
      "Button_Image_Name": "BTN_RRDiamondGlitz1.png"
    }, {
      "EN_Name": "Rhyming Reels - Georgie Porgie",
      "CN_Name": "押韵的卷轴-乔治波尔吉",
      "HTML5_Game_ID": "1782",
      "Button_Image_Name": "BTN_RhymingReelsGeorgiePorgie.png"
    }, {
      "EN_Name": "Rhyming Reels - Hearts & Tarts",
      "CN_Name": "押韵的卷轴-心挞",
      "HTML5_Game_ID": "1009",
      "Button_Image_Name": "BTN_RRHearts.png"
    }, {
      "EN_Name": "Riviera Riches",
      "CN_Name": "海滨财富",
      "HTML5_Game_ID": "1359",
      "Button_Image_Name": "BTN_RivieraRiches1.png"
    }, {
      "EN_Name": "Rugby Star",
      "CN_Name": "橄榄球明星",
      "HTML5_Game_ID": "1287",
      "Button_Image_Name": "BTN_RugbyStar_ZH.png"
    }, {
      "EN_Name": "Santa Paws",
      "CN_Name": "圣诞之掌",
      "HTML5_Game_ID": "1785",
      "Button_Image_Name": "BTN_SantaPaws.png"
    }, {
      "EN_Name": "Santas Wild Ride",
      "CN_Name": "圣诞老人的百搭摩拖车",
      "HTML5_Game_ID": "1095",
      "Button_Image_Name": "BTN_SantasWildRide1_ZH.png"
    }, {
      "EN_Name": "Scrooge",
      "CN_Name": "小气财神",
      "HTML5_Game_ID": "1030",
      "Button_Image_Name": "BTN_Scrooge1_ZH.png"
    }, {
      "EN_Name": "Secret Admirer",
      "CN_Name": "秘密崇拜者",
      "HTML5_Game_ID": "1325",
      "Button_Image_Name": "BTN_SecretAdmirer1.png"
    }, {
      "EN_Name": "Secret Romance",
      "CN_Name": "秘密爱慕者",
      "HTML5_Game_ID": "1877",
      "Button_Image_Name": "BTN_SecretRomance_1_ZH.png"
    }, {
      "EN_Name": "Silver Fang",
      "CN_Name": "银芳",
      "HTML5_Game_ID": "1062",
      "Button_Image_Name": "BTN_SilverFang1.png"
    }, {
      "EN_Name": "Six Acrobats",
      "CN_Name": "杂技群英会",
      "HTML5_Game_ID": "1892",
      "Button_Image_Name": "BTN_SixAcrobats_ZH.png"
    }, {
      "EN_Name": "So Many Monsters",
      "CN_Name": "怪兽多多",
      "HTML5_Game_ID": "1001",
      "Button_Image_Name": "BTN_somanymonsters.png"
    }, {
      "EN_Name": "So Much Candy",
      "CN_Name": "糖果多多",
      "HTML5_Game_ID": "1374",
      "Button_Image_Name": "BTN_somuchcandy.png"
    }, {
      "EN_Name": "So Much Sushi",
      "CN_Name": "寿司多多",
      "HTML5_Game_ID": "1032",
      "Button_Image_Name": "BTN_somuchsushi.png"
    }, {
      "EN_Name": "Spring Break",
      "CN_Name": "春假",
      "HTML5_Game_ID": "1002",
      "Button_Image_Name": "BTN_SpringBreak2.png"
    }, {
      "EN_Name": "Stardust",
      "CN_Name": "星尘",
      "HTML5_Game_ID": "1404",
      "Button_Image_Name": "BTN_StarDust_ZH.png"
    }, {
      "EN_Name": "Starlight Kiss",
      "CN_Name": "星梦之吻",
      "HTML5_Game_ID": "1167",
      "Button_Image_Name": "BTN_StarlightKiss.png"
    }, {
      "EN_Name": "Stash of the Titans",
      "CN_Name": "泰坦之藏匿",
      "HTML5_Game_ID": "1320",
      "Button_Image_Name": "BTN_StashoftheTitans1.png"
    }, {
      "EN_Name": "Sugar Parade",
      "CN_Name": "糖果巡游",
      "HTML5_Game_ID": "1893",
      "Button_Image_Name": "BTN_SugarParade_ZH.png"
    }, {
      "EN_Name": "Summertime",
      "CN_Name": "夏天",
      "HTML5_Game_ID": "1130",
      "Button_Image_Name": "BTN_summertime_1.png"
    }, {
      "EN_Name": "SunQuest",
      "CN_Name": "探索太阳",
      "HTML5_Game_ID": "1127",
      "Button_Image_Name": "BTN_SunQuest3.png"
    }, {
      "EN_Name": "SunTide",
      "CN_Name": "太阳征程",
      "HTML5_Game_ID": "1150",
      "Button_Image_Name": "BTN_SunTide_Button_ZH.png"
    }, {
      "EN_Name": "Supe it Up",
      "CN_Name": "跑起来",
      "HTML5_Game_ID": "1289",
      "Button_Image_Name": "BTN_SupeItUp2.png"
    }, {
      "EN_Name": "Tally Ho",
      "CN_Name": "泰利嗬",
      "HTML5_Game_ID": "1395",
      "Button_Image_Name": "BTN_TallyHo1.png"
    }, {
      "EN_Name": "Tasty Street",
      "CN_Name": "妹妹很饿",
      "HTML5_Game_ID": "1901",
      "Button_Image_Name": "BTN_TastyStreet_ZH.png"
    }, {
      "EN_Name": "The Finer Reels of Life",
      "CN_Name": "好日子",
      "HTML5_Game_ID": "1200",
      "Button_Image_Name": "BTN_TheFinerReelsOfLife_ZH.png"
    }, {
      "EN_Name": "The Grand Journey",
      "CN_Name": "冒险之旅",
      "HTML5_Game_ID": "1169",
      "Button_Image_Name": "BTN_thegrandjourney_1.png"
    }, {
      "EN_Name": "The Heat Is On",
      "CN_Name": "热力四射",
      "HTML5_Game_ID": "1883",
      "Button_Image_Name": "BTN_TheHeatIsOn.png"
    }, {
      "EN_Name": "The Phantom Of The Opera",
      "CN_Name": "歌剧魅影",
      "HTML5_Game_ID": "1906",
      "Button_Image_Name": "BTN_ThePhantomOfTheOpera_ZH.png"
    }, {
      "EN_Name": "The Rat Pack",
      "CN_Name": "鼠包",
      "HTML5_Game_ID": 70923,
      "Button_Image_Name": "BTN_RatPack1.png"
    }, {
      "EN_Name": "The Twisted Circus",
      "CN_Name": "反转马戏团",
      "HTML5_Game_ID": "1386",
      "Button_Image_Name": "BTN_TwistedCircus.png"
    }, {
      "EN_Name": "Thunderstruck",
      "CN_Name": "雷神",
      "HTML5_Game_ID": "1028",
      "Button_Image_Name": "BTN_Thunderstruck1.png"
    }, {
      "EN_Name": "Thunderstruck II",
      "CN_Name": "雷神2",
      "HTML5_Game_ID": "1330",
      "Button_Image_Name": "BTN_ThunderstruckTwo1.png"
    }, {
      "EN_Name": "Titans of the Sun Theia",
      "CN_Name": "太阳神之忒伊亚",
      "HTML5_Game_ID": "1385",
      "Button_Image_Name": "BTN_TitansOfTheSunTheia_ZH.png"
    }, {
      "EN_Name": "Tomb Raider",
      "CN_Name": "古墓丽影",
      "HTML5_Game_ID": "1122",
      "Button_Image_Name": "BTN_TombRaider2.png"
    }, {
      "EN_Name": "Tomb Raider Secret of the Sword",
      "CN_Name": "古墓丽影秘密之剑",
      "HTML5_Game_ID": "1383",
      "Button_Image_Name": "BTN_TombRaiderSotS1.png"
    }, {
      "EN_Name": "Treasure Palace",
      "CN_Name": "财宝宫殿",
      "HTML5_Game_ID": "1020",
      "Button_Image_Name": "BTN_TreasurePalace.png"
    }, {
      "EN_Name": "Untamed Giant Panda",
      "CN_Name": "大熊猫",
      "HTML5_Game_ID": "1222",
      "Button_Image_Name": "BTN_UntamedGiantPanda.png"
    }, {
      "EN_Name": "Vinyl Countdown",
      "CN_Name": "黑胶热舞",
      "HTML5_Game_ID": "1306",
      "Button_Image_Name": "BTN_VinylCountdown4_ZH.png"
    }, {
      "EN_Name": "Voila!",
      "CN_Name": "瞧！",
      "HTML5_Game_ID": "1241",
      "Button_Image_Name": "BTN_voila_2.png"
    }, {
      "EN_Name": "Wacky Panda",
      "CN_Name": "囧囧熊猫",
      "HTML5_Game_ID": "1911",
      "Button_Image_Name": "BTN_WackyPanda_ZH.png"
    }, {
      "EN_Name": "What A Hoot",
      "CN_Name": "猫头鹰乐园",
      "HTML5_Game_ID": "1171",
      "Button_Image_Name": "BTN_WhataHoot3.png"
    }, {
      "EN_Name": "Wicked Tales Dark Red",
      "CN_Name": "黑暗故事神秘深红",
      "HTML5_Game_ID": "2064",
      "Button_Image_Name": "BTN_WickedTalesDarkRed.jpg"
    }, {
      "EN_Name": "Wild Orient",
      "CN_Name": "东方珍兽",
      "HTML5_Game_ID": "1164",
      "Button_Image_Name": "BTN_WildOrient_Button_ZH.png"
    }, {
      "EN_Name": "Wild Scarabs",
      "CN_Name": "百搭圣甲虫",
      "HTML5_Game_ID": "1992",
      "Button_Image_Name": "BTN_WildScarabs_ZH.jpg"
    }, {
      "EN_Name": "Win Sum Dim Sum",
      "CN_Name": "开心点心",
      "HTML5_Game_ID": "1345",
      "Button_Image_Name": "BTN_WinSumDimSum_zh.png"
    }, {
      "EN_Name": "Aurora Wilds",
      "CN_Name": "极光百搭",
      "HTML5_Game_ID": "4328",
      "Button_Image_Name": "BTN_AuroraWilds.png"
    }, {
      "EN_Name": "Book of Oz Lock 'N Spin",
      "CN_Name": "Oz之书锁定并旋转",
      "HTML5_Game_ID": "4329",
      "Button_Image_Name": "BTN_BookOfOzLockNSpin_ZH.jpg"
    }, {
      "EN_Name": "Playboy™ Gold Jackpots",
      "CN_Name": "花花公子黄金大奖",
      "HTML5_Game_ID": "4327",
      "Button_Image_Name": "BTN_PlayboyGoldJackpots_ZH.png"
    }, {
      "EN_Name": "Jungle Jim and the Lost Sphinx",
      "CN_Name": "丛林吉姆与失落的狮身人面像",
      "HTML5_Game_ID": "4340",
      "Button_Image_Name": "BTN_JungleJimandtheLostSphinx_ZH.png"
    }, {
      "EN_Name": "Lucky Bachelors",
      "CN_Name": "幸运单身族",
      "HTML5_Game_ID": "4339",
      "Button_Image_Name": "BTN_LuckyBachelors_ZH.png"
    }, {
      "EN_Name": "Banana Odyssey",
      "CN_Name": "香蕉奥德赛",
      "HTML5_Game_ID": "4296",
      "Button_Image_Name": "BTN_BananaOdyssey.png"
    }, {
      "EN_Name": "Basketball Star Deluxe",
      "CN_Name": "篮球明星豪华版",
      "HTML5_Game_ID": "4256",
      "Button_Image_Name": "BTN_BasketballStarDeluxe_ZH.png"
    }, {
      "EN_Name": "Bookie of Odds",
      "CN_Name": "好运经纪人",
      "HTML5_Game_ID": "2089",
      "Button_Image_Name": "BTN_BookieOfOdds_ZH.png"
    }, {
      "EN_Name": "Break Away Deluxe",
      "CN_Name": "冰球突破豪华版",
      "HTML5_Game_ID": "2074",
      "Button_Image_Name": "BTN_BreakAwayDeluxe_ZH.png"
    }, {
      "EN_Name": "Break Da Bank Again Respin",
      "CN_Name": "银行爆破再旋转",
      "HTML5_Game_ID": "4297",
      "Button_Image_Name": "BTN_BreakDaBankAgain1.png"
    }, {
      "EN_Name": "Dragon Shard",
      "CN_Name": "神龙碎片",
      "HTML5_Game_ID": "4257",
      "Button_Image_Name": "BTN_DragonShard_ZH.png"
    }, {
      "EN_Name": "Fire Dice Deluxe",
      "CN_Name": "火焰快骰豪华版",
      "HTML5_Game_ID": "4299",
      "Button_Image_Name": "BTN_FireDiceDeluxe_ZH.png"
    }, {
      "EN_Name": "Fire Dice Turbo",
      "CN_Name": "火焰快骰",
      "HTML5_Game_ID": "4273",
      "Button_Image_Name": "BTN_FireDiceTurbo_ZH.png"
    }, {
      "EN_Name": "Silver Lioness 4x",
      "CN_Name": "纯银母狮4x",
      "HTML5_Game_ID": "4112",
      "Button_Image_Name": "BTN_SilverLioness4x.png"
    }, {
      "EN_Name": "Tiki Vikings",
      "CN_Name": "蒂基维京",
      "HTML5_Game_ID": "4290",
      "Button_Image_Name": "BTN_TikiVikings_ZH.png"
    }, {
      "EN_Name": "Treasures of Lion City",
      "CN_Name": "海底宝城",
      "HTML5_Game_ID": "4286",
      "Button_Image_Name": "BTN_TreasuresOfLionCity_ZH.png"
    }, {
      "EN_Name": "Village People® Macho Moves",
      "CN_Name": "型男舞步",
      "HTML5_Game_ID": "4271",
      "Button_Image_Name": "BTN_VillagePeopleMachoMoves_ZH.png"
    }, {
      "EN_Name": "Zombie Hoard",
      "CN_Name": "丧尸来袭",
      "HTML5_Game_ID": "2085",
      "Button_Image_Name": "BTN_ZombieHoard_ZH.png"
    }]
  },
    {
    "type": 4,
    "game_id": 405,
    "game_api": "MW",
    "name": "MW老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/mw.png",
    "icon": "mw",
    "game_list_json": "MW_game_list",
    "game_list_img_path": "/new/slot_res/mw/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：活动专区、千炮捕鱼、水浒传、蜜糖甜心、百家乐、五路财神...",
    "labels": "#老虎机#活动专区#千炮捕鱼#水浒传#蜜糖甜心",
    gamelist:[
      {"CN_Name": "活动专区", "EN_Name": "Events", "HTML5_Game_ID": "10001", "Button_Image_Name": "10001.png"},


      {
        "CN_Name": "疯狂小羊哥",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6065,
        "Button_Image_Name": "6065.jpg"
      },
      {
        "CN_Name": "满贯王牌",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6064,
        "Button_Image_Name": "6064.jpg"
      },
      {
        "CN_Name": "开心农场",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6063,
        "Button_Image_Name": "6063.jpg"
      },


      {
        "CN_Name": "麻将碰碰",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6059,
        "Button_Image_Name": "6059.jpg"
      },

      {
        "CN_Name": "麻将碰碰2",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6060,
        "Button_Image_Name": "6060.jpg"
      },



      {
        "CN_Name": "火箭",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6805,
        "Button_Image_Name": "6805.jpg"
      },


      {
        "CN_Name": "弹珠",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6058,
        "Button_Image_Name": "6058.jpg"
      },


      {
        "CN_Name": "恐龙扛狼",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6061,
        "Button_Image_Name": "6061.jpg"
      },


      {
        "CN_Name": "麻将碰碰+",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6062,
        "Button_Image_Name": "6062.jpg"
      },



      {
        "CN_Name": "赏金洛基",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6057,
        "Button_Image_Name": "6057.jpg"
      },
      {
        "CN_Name": "开心消消乐",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6056,
        "Button_Image_Name": "6056.jpg"
      },

      {
        "CN_Name": "西游记",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6003,
        "Button_Image_Name": "6003.jpg"
      },


      {
        "CN_Name": "福袋连连-龙",
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "HTML5_Game_ID": 6055,
        "Button_Image_Name": "6055.jpg"
      },
      {
        "CN_Name": "福袋连连-熊猫",
        "EN_Name": "Fu Dai Lian Lian-Panda",
        "HTML5_Game_ID": 6054,
        "Button_Image_Name": "6054.jpg"
      },
      {
        "CN_Name": "喜茶消消乐2",
        "EN_Name": "XiBoBa Hit2",
        "HTML5_Game_ID": 6053,
        "Button_Image_Name": "6053.jpg"
      },
      {
        "CN_Name": "喜茶消消乐",
        "EN_Name": "XiBoBa Hit",
        "HTML5_Game_ID": 6052,
        "Button_Image_Name": "6052.jpg"
      },
      {
        "CN_Name": "喜茶",
        "EN_Name": "XiBoBA",
        "HTML5_Game_ID": 6051,
        "Button_Image_Name": "6051.jpg"
      },
      {
        "CN_Name": "百人摸珠子",
        "EN_Name": "Multiplayer Pick Up",
        "HTML5_Game_ID": 6804,
        "Button_Image_Name": "6804.jpg"
      },
      {
        "CN_Name": "森林舞会(H5)",
        "EN_Name": "Forest Party(H5)",
        "HTML5_Game_ID": 1041,
        "Button_Image_Name": "1041.jpg"
      },
      {
        "CN_Name": "捕鱼大满贯",
        "EN_Name": "Megawin Fishing",
        "HTML5_Game_ID": 1050,
        "Button_Image_Name": "1050.jpg"
      },
      {
        "CN_Name": "森林舞会3",
        "EN_Name": "Forest Party3",
        "HTML5_Game_ID": 6803,
        "Button_Image_Name": "6803.jpg"
      },
      {
        "CN_Name": "千炮捕鱼(H5)",
        "EN_Name": "Fishing Hunter(H5)",
        "HTML5_Game_ID": 1051,
        "Button_Image_Name": "1051.jpg"
      },
      {
        "CN_Name": "森林舞会2",
        "EN_Name": "Forest Party2",
        "HTML5_Game_ID": 1040,
        "Button_Image_Name": "1040.jpg"
      },
      {
        "CN_Name": "水浒传(H5)",
        "EN_Name": "Water Margin(H5)",
        "HTML5_Game_ID": 1110,
        "Button_Image_Name": "1110.jpg"
      },
      {
        "CN_Name": "水浒英雄(H5)",
        "EN_Name": "(H5)Outlawed Heroes",
        "HTML5_Game_ID": 1112,
        "Button_Image_Name": "1112.jpg"
      },
      {
        "CN_Name": "金鲨银鲨(H5)",
        "EN_Name": "Golden Shark(H5)",
        "HTML5_Game_ID": 1145,
        "Button_Image_Name": "1145.jpg"
      },
      {
        "CN_Name": "富-富贵牡丹(H5)",
        "EN_Name": "F-88Flower(H5)",
        "HTML5_Game_ID": 6011,
        "Button_Image_Name": "6011.jpg"
      },
      {
        "CN_Name": "龙虎斗",
        "EN_Name": "Dragon＆Tiger",
        "HTML5_Game_ID": 1163,
        "Button_Image_Name": "1163.jpg"
      },
      {
        "CN_Name": "吉祥如意(H5)",
        "EN_Name": "JiXiangRuYi(H5)",
        "HTML5_Game_ID": 6018,
        "Button_Image_Name": "6018.jpg"
      },
      {
        "CN_Name": "金吉-牡丹齐放",
        "EN_Name": "G-BloomingRiches",
        "HTML5_Game_ID": 6030,
        "Button_Image_Name": "6030.jpg"
      },
      {
        "CN_Name": "富-财源广进(H5)",
        "EN_Name": "F-88Riches(H5)",
        "HTML5_Game_ID": 6007,
        "Button_Image_Name": "6007.jpg"
      },
      {
        "CN_Name": "经典水果机(H5)",
        "EN_Name": "Fruit Trail(H5)",
        "HTML5_Game_ID": 1021,
        "Button_Image_Name": "1021.jpg"
      },
      {
        "CN_Name": "财-富贵牡丹(H5)",
        "EN_Name": "C-88Flower(H5)",
        "HTML5_Game_ID": 6017,
        "Button_Image_Name": "6017.jpg"
      },
      {
        "CN_Name": "招财猫(H5)",
        "EN_Name": "MANEKI NEKO(H5)",
        "HTML5_Game_ID": 6005,
        "Button_Image_Name": "6005.jpg"
      },
      {
        "CN_Name": "发大财(H5)",
        "EN_Name": "FA DA CAI(H5)",
        "HTML5_Game_ID": 6004,
        "Button_Image_Name": "6004.jpg"
      },
      {
        "CN_Name": "闪电-财富之眼",
        "EN_Name": "L-EyesOfFortune",
        "HTML5_Game_ID": 6033,
        "Button_Image_Name": "6033.jpg"
      },
      {
        "CN_Name": "闪电-帝王宝藏",
        "EN_Name": "L-EmperorTreasure",
        "HTML5_Game_ID": 6034,
        "Button_Image_Name": "6034.jpg"
      },
      {
        "CN_Name": "富-永恒彩钻(H5)",
        "EN_Name": "F-88Diamond(H5)",
        "HTML5_Game_ID": 6009,
        "Button_Image_Name": "6009.jpg"
      },
      {
        "CN_Name": "富-传奇选择(H5)",
        "EN_Name": "F-88Treasures(H5)",
        "HTML5_Game_ID": 6008,
        "Button_Image_Name": "6008.jpg"
      },
      {
        "CN_Name": "五路财神(H5)",
        "EN_Name": "5 CaiShen(H5)",
        "HTML5_Game_ID": 1153,
        "Button_Image_Name": "1153.jpg"
      },
      {
        "CN_Name": "鱼鱼鱼(H5)",
        "EN_Name": "Triple Fish(H5)",
        "HTML5_Game_ID": 6002,
        "Button_Image_Name": "6002.jpg"
      },
      {
        "CN_Name": "五龙争霸(H5)",
        "EN_Name": "5 Dragons(H5)",
        "HTML5_Game_ID": 1111,
        "Button_Image_Name": "1111.jpg"
      },
      {
        "CN_Name": "财-财源广进(H5)",
        "EN_Name": "C-88Riches(H5)",
        "HTML5_Game_ID": 6013,
        "Button_Image_Name": "6013.jpg"
      },
      {
        "CN_Name": "金吉-无尽宝藏",
        "EN_Name": "G-InfiniteTreasure",
        "HTML5_Game_ID": 6027,
        "Button_Image_Name": "6027.jpg"
      },
      {
        "CN_Name": "黄金777(H5)",
        "EN_Name": "Golden 777(H5)",
        "HTML5_Game_ID": 1115,
        "Button_Image_Name": "1115.jpg"
      },
      {
        "CN_Name": "金吉-锣鼓喧天",
        "EN_Name": "G-GoldenDrum",
        "HTML5_Game_ID": 6029,
        "Button_Image_Name": "6029.jpg"
      },
      {
        "CN_Name": "超级斗地主(H5)",
        "EN_Name": "Super Doudizhu(H5)",
        "HTML5_Game_ID": 1023,
        "Button_Image_Name": "1023.jpg"
      },
      {
        "CN_Name": "好运5扑克(H5)",
        "EN_Name": "Lucky 5(H5)",
        "HTML5_Game_ID": 1022,
        "Button_Image_Name": "1022.jpg"
      },
      {
        "CN_Name": "富-锣鼓迎富(H5)",
        "EN_Name": "F-88Drum(H5)",
        "HTML5_Game_ID": 6010,
        "Button_Image_Name": "6010.jpg"
      },
      {
        "CN_Name": "金吉-彩钻连连",
        "EN_Name": "G-DazzlingDiamond",
        "HTML5_Game_ID": 6028,
        "Button_Image_Name": "6028.jpg"
      },
      {
        "CN_Name": "太极(H5)",
        "EN_Name": "TaiChi(H5)",
        "HTML5_Game_ID": 1140,
        "Button_Image_Name": "1140.jpg"
      },
      {
        "CN_Name": "金运熊猫(H5)",
        "EN_Name": "Gold Panda(H5)",
        "HTML5_Game_ID": 6012,
        "Button_Image_Name": "6012.jpg"
      },
      {
        "CN_Name": "闪电-龙之宝藏",
        "EN_Name": "L-DragonRiches",
        "HTML5_Game_ID": 6032,
        "Button_Image_Name": "6032.jpg"
      },
      {
        "CN_Name": "金吉-财源滚滚",
        "EN_Name": "G-RollingRiches",
        "HTML5_Game_ID": 6026,
        "Button_Image_Name": "6026.jpg"
      },
      {
        "CN_Name": "鱼跃龙门",
        "EN_Name": "LuckyFish",
        "HTML5_Game_ID": 6019,
        "Button_Image_Name": "6019.jpg"
      },
      {
        "CN_Name": "闪电-金蟾蜍",
        "EN_Name": "L-GoldenToad",
        "HTML5_Game_ID": 6035,
        "Button_Image_Name": "6035.jpg"
      },
      {
        "CN_Name": "明星97(H5)",
        "EN_Name": "Idol 97(H5)",
        "HTML5_Game_ID": 1117,
        "Button_Image_Name": "1117.jpg"
      },
      {
        "CN_Name": "双龙抢珠(H5)",
        "EN_Name": "Double Dragons(H5)",
        "HTML5_Game_ID": 1143,
        "Button_Image_Name": "1143.jpg"
      },
      {
        "CN_Name": "洪福齐天(H5)",
        "EN_Name": "Big Fortune(H5)",
        "HTML5_Game_ID": 1120,
        "Button_Image_Name": "1120.jpg"
      },
      {
        "CN_Name": "财-传奇选择(H5)",
        "EN_Name": "C-88Treasures(H5)",
        "HTML5_Game_ID": 6014,
        "Button_Image_Name": "6014.jpg"
      },
      {
        "CN_Name": "蜜糖甜心(H5)",
        "EN_Name": "Honey Bakery(H5)",
        "HTML5_Game_ID": 1149,
        "Button_Image_Name": "1149.jpg"
      },
      {
        "CN_Name": "百乐牛牛(H5)",
        "EN_Name": "Taurus(H5)",
        "HTML5_Game_ID": 1139,
        "Button_Image_Name": "1139.jpg"
      },
      {
        "CN_Name": "逆天问道-奕剑",
        "EN_Name": "Destiny-Warrior",
        "HTML5_Game_ID": 6042,
        "Button_Image_Name": "6042.jpg"
      },
      {
        "CN_Name": "财-锣鼓迎富(H5)",
        "EN_Name": "C-88Drum(H5)",
        "HTML5_Game_ID": 6016,
        "Button_Image_Name": "6016.jpg"
      },
      {
        "CN_Name": "财-永恒彩钻(H5)",
        "EN_Name": "C-88Diamond(H5)",
        "HTML5_Game_ID": 6015,
        "Button_Image_Name": "6015.jpg"
      },
      {
        "CN_Name": "天龙虎地(H5)",
        "EN_Name": "Heaven & Earth(H5)",
        "HTML5_Game_ID": 1114,
        "Button_Image_Name": "1114.jpg"
      },
      {
        "CN_Name": "淘金热",
        "EN_Name": "Gold Rush",
        "HTML5_Game_ID": 6041,
        "Button_Image_Name": "6041.jpg"
      },
      {
        "CN_Name": "舞狮报喜(H5)",
        "EN_Name": "Lion Dance(H5)",
        "HTML5_Game_ID": 1144,
        "Button_Image_Name": "1144.jpg"
      },
      {
        "CN_Name": "尊爵会馆",
        "EN_Name": "VIP Club",
        "HTML5_Game_ID": 6409,
        "Button_Image_Name": "6409.jpg"
      },
      {
        "CN_Name": "逆天问道-斗佛",
        "EN_Name": "Destiny-Monk",
        "HTML5_Game_ID": 6047,
        "Button_Image_Name": "6047.jpg"
      },
      {
        "CN_Name": "百家乐(H5)",
        "EN_Name": "Baccarat-Super 6(H5)",
        "HTML5_Game_ID": 1141,
        "Button_Image_Name": "1141.jpg"
      },
      {
        "CN_Name": "逆天问道-法魂",
        "EN_Name": "Destiny-Mage",
        "HTML5_Game_ID": 6044,
        "Button_Image_Name": "6044.jpg"
      },
      {
        "CN_Name": "丛林秘宝",
        "EN_Name": "Jungle Gold",
        "HTML5_Game_ID": 6036,
        "Button_Image_Name": "6036.jpg"
      },
      {
        "CN_Name": "皇家轮盘(H5)",
        "EN_Name": "Royal Roulette(H5)",
        "HTML5_Game_ID": 1138,
        "Button_Image_Name": "1138.jpg"
      },
      {
        "CN_Name": "逆天问道-天音",
        "EN_Name": "Destiny-Healer",
        "HTML5_Game_ID": 6046,
        "Button_Image_Name": "6046.jpg"
      },
      {
        "CN_Name": "逆天问道-渔湘",
        "EN_Name": "Destiny-Archer",
        "HTML5_Game_ID": 6045,
        "Button_Image_Name": "6045.jpg"
      },
      {
        "CN_Name": "比倍王-777",
        "EN_Name": "King-777",
        "HTML5_Game_ID": 6043,
        "Button_Image_Name": "6043.jpg"
      },
      {
        "CN_Name": "摸珠子",
        "EN_Name": "Pick up",
        "HTML5_Game_ID": 6050,
        "Button_Image_Name": "6050.jpg"
      }
    ]
  },
    {
      "type": 4,
      "game_id": 401,
      "game_api": "PT",
      "name": "APT老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/pt.png",
      "icon": "logo-pt",
      "game_list_json": "PT_game_list",
      "game_list_img_path": "/new/slot_res/pt/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：丛林巨兽、猿人传奇、龙之战士、烈焰砖石、亚洲幻想...",
      "labels": "#老虎机#丛林巨兽#猿人传奇#龙之战士#烈焰砖石",
      gamelist: [
        {
          "CN_Name": "来自外太空的鸽子",
          "EN_Name": "Pigeons from Space",
          "HTML5_Game_ID": "gpas_pigeonfs_pop",
          "Button_Image_Name": "gpas_pigeonfs_pop.jpg"
        },
        {
          "CN_Name": "绝对长毛象强力累积奖金",
          "EN_Name": "Absolutely Mammoth PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_amammothpp_pop",
          "Button_Image_Name": "gpas_amammothpp_pop.jpg"
        },
        {
          "CN_Name": "极限热辣宝石强力累计奖金",
          "EN_Name": "Hot Gems Xtreme PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_hgextremepp_pop",
          "Button_Image_Name": "gpas_hgextremepp_pop.jpg"
        },
        {
          "CN_Name": "樱花财富",
          "EN_Name": "Sakura Fortune",
          "HTML5_Game_ID": "gpas_sfortune_pop",
          "Button_Image_Name": "gpas_sfortune_pop.jpg"
        },
        {
          "CN_Name": "奖金火车土匪",
          "EN_Name": "Bonus Train Bandits",
          "HTML5_Game_ID": "gpas_btbandit_pop",
          "Button_Image_Name": "gpas_btbandit_pop.jpg"
        },
        {
          "CN_Name": "罗马之盾",
          "EN_Name": "Shields of Rome",
          "HTML5_Game_ID": "gpas_sorome_pop",
          "Button_Image_Name": "gpas_sorome_pop.jpg"
        },
        {
          "CN_Name": "伟大的表演",
          "EN_Name": "The Great Reveal",
          "HTML5_Game_ID": "gpas_gtreveal_pop",
          "Button_Image_Name": "gpas_gtreveal_pop.jpg"
        },
        {
          "CN_Name": "星星闪耀",
          "EN_Name": "Stars Ablaze",
          "HTML5_Game_ID": "gpas_starsablaze_pop",
          "Button_Image_Name": "gpas_starsablaze_pop.jpg"
        },
        {
          "CN_Name": "发财财神",
          "EN_Name": "Fat Choy Choy Sun",
          "HTML5_Game_ID": "gpas_fatchoy_pop",
          "Button_Image_Name": "gpas_fatchoy_pop.jpg"
        },
        {
          "CN_Name": "暗黑吸血鬼公主",
          "EN_Name": "Vampire Princess of Darkness",
          "HTML5_Game_ID": "gpas_vampirep_pop",
          "Button_Image_Name": "gpas_vampirep_pop.jpg"
        },
        {
          "CN_Name": "毁灭拳手",
          "EN_Name": "Eliminators",
          "HTML5_Game_ID": "gpas_eliminators_pop",
          "Button_Image_Name": "gpas_eliminators_pop.jpg"
        },
        {
          "CN_Name": "南瓜富矿",
          "EN_Name": "Pumpkin Bonanza",
          "HTML5_Game_ID": "gpas_pumpkinb_pop",
          "Button_Image_Name": "gpas_pumpkinb_pop.jpg"
        },
        {
          "CN_Name": "自拍狂人",
          "EN_Name": "Selfie Elfie",
          "HTML5_Game_ID": "gpas_selfelf_pop",
          "Button_Image_Name": "gpas_selfelf_pop.jpg"
        },
        {
          "CN_Name": "炸弹",
          "EN_Name": "Bombs",
          "HTML5_Game_ID": "gpas_bomaway_pop",
          "Button_Image_Name": "gpas_bomaway_pop.jpg"
        },
        {
          "CN_Name": "赌场之魅",
          "EN_Name": "Casino Charms",
          "HTML5_Game_ID": "gpas_cascharms_pop",
          "Button_Image_Name": "gpas_cascharms_pop.jpg"
        },
        {
          "CN_Name": "星际流亡者",
          "EN_Name": "Starmada Exiles",
          "HTML5_Game_ID": "gpas_sexiles_pop",
          "Button_Image_Name": "gpas_sexiles_pop.jpg"
        },
        {
          "CN_Name": "级联洞穴",
          "EN_Name": "Cascading Cave",
          "HTML5_Game_ID": "gpas_ccave_pop",
          "Button_Image_Name": "gpas_ccave_pop.jpg"
        },
        {
          "CN_Name": "顶级图腾",
          "EN_Name": "Tip Top Totems",
          "HTML5_Game_ID": "gpas_tttotems_pop",
          "Button_Image_Name": "gpas_tttotems_pop.jpg"
        },
        {
          "CN_Name": "爆裂海盗",
          "EN_Name": "Buccaneer Blast",
          "HTML5_Game_ID": "gpas_pblast_pop",
          "Button_Image_Name": "gpas_pblast_pop.jpg"
        },
        {
          "CN_Name": "海南冰",
          "EN_Name": "Hainan Ice",
          "HTML5_Game_ID": "gpas_hice_pop",
          "Button_Image_Name": "gpas_hice_pop.jpg"
        },
        {
          "CN_Name": "森林王子",
          "EN_Name": "Forest Prince",
          "HTML5_Game_ID": "gpas_fprince_pop",
          "Button_Image_Name": "gpas_fprince_pop.jpg"
        },
        {
          "CN_Name": "新年富园",
          "EN_Name": "New Year's Bonanza",
          "HTML5_Game_ID": "gpas_nyslot_pop",
          "Button_Image_Name": "gpas_nyslot_pop.jpg"
        },
        {
          "CN_Name": "复古潮流冲刺",
          "EN_Name": "Retro Rush",
          "HTML5_Game_ID": "gpas_rrider_pop",
          "Button_Image_Name": "gpas_rrider_pop.jpg"
        },
        {
          "CN_Name": "幸运绿宝石",
          "EN_Name": "Lucky Emeralds",
          "HTML5_Game_ID": "gpas_lemeralds_pop",
          "Button_Image_Name": "gpas_lemeralds_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：阿斯加德国王",
          "EN_Name": "Age of the Gods Norse: King of Asgard",
          "HTML5_Game_ID": "gpas_aogkasgard_pop",
          "Button_Image_Name": "gpas_aogkasgard_pop.jpg"
        },
        {
          "CN_Name": "午夜荒野",
          "EN_Name": "Midnight Wilds",
          "HTML5_Game_ID": "gpas_mwilds_pop",
          "Button_Image_Name": "gpas_mwilds_pop.jpg"
        },
        {
          "CN_Name": "埃及绿宝石",
          "EN_Name": "Egyptian Emeralds",
          "HTML5_Game_ID": "gpas_eemeralds_pop",
          "Button_Image_Name": "gpas_eemeralds_pop.jpg"
        },
        {
          "CN_Name": "国王之书",
          "EN_Name": "Book of Kings",
          "HTML5_Game_ID": "gpas_boking_pop",
          "Button_Image_Name": "gpas_boking_pop.jpg"
        },
        {
          "CN_Name": "狂野西部荒野",
          "EN_Name": "Wild West Wilds",
          "HTML5_Game_ID": "gpas_wwd_pop",
          "Button_Image_Name": "gpas_wwd_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：雷电之道",
          "EN_Name": "Age of the Gods Norse: Ways of Thunder",
          "HTML5_Game_ID": "gpas_aognwot_pop",
          "Button_Image_Name": "gpas_aognwot_pop.jpg"
        },
        {
          "CN_Name": "进击的闪电马",
          "EN_Name": "Stallion Strike",
          "HTML5_Game_ID": "gpas_sstrike_pop",
          "Button_Image_Name": "gpas_sstrike_pop.jpg"
        },
        {
          "CN_Name": "众神时代：辉煌狮鹫",
          "EN_Name": "Age of the Gods: Glorious Griffin",
          "HTML5_Game_ID": "gpas_aogggriffin_pop",
          "Button_Image_Name": "gpas_aogggriffin_pop.jpg"
        },
        {
          "CN_Name": "探险之途",
          "EN_Name": "Adventure Trail",
          "HTML5_Game_ID": "gpas_atrail_pop",
          "Button_Image_Name": "gpas_atrail_pop.jpg"
        },
        {
          "CN_Name": "闪耀铃铛",
          "EN_Name": "Blazing Bells",
          "HTML5_Game_ID": "gpas_bbells_pop",
          "Button_Image_Name": "gpas_bbells_pop.jpg"
        },
        {
          "CN_Name": "运财熊猫",
          "EN_Name": "Panda Luck",
          "HTML5_Game_ID": "gpas_pluck_pop",
          "Button_Image_Name": "gpas_pluck_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：神与巨人",
          "EN_Name": "Age of the Gods Norse: Gods and Giants",
          "HTML5_Game_ID": "gpas_aoggagiants_pop",
          "Button_Image_Name": "gpas_aoggagiants_pop.jpg"
        },
        {
          "CN_Name": "金恩之月",
          "EN_Name": "Jinns Moon",
          "HTML5_Game_ID": "gpas_jmoon_pop",
          "Button_Image_Name": "gpas_jmoon_pop.jpg"
        },
        {
          "CN_Name": "龙女之光",
          "EN_Name": "Dragon Spark",
          "HTML5_Game_ID": "gpas_dsparks_pop",
          "Button_Image_Name": "gpas_dsparks_pop.jpg"
        },
        {
          "CN_Name": "极佳荒野",
          "EN_Name": "Better Wilds",
          "HTML5_Game_ID": "gpas_betwilds_pop",
          "Button_Image_Name": "gpas_betwilds_pop.jpg"
        },
        {
          "CN_Name": "宝石横飞：彩虹礼物",
          "EN_Name": "Gem Splash: Rainbows Gift",
          "HTML5_Game_ID": "gpas_rgift_pop",
          "Button_Image_Name": "gpas_rgift_pop.jpg"
        },
        {
          "CN_Name": "小丑转转转",
          "EN_Name": "Joker Hot Reels",
          "HTML5_Game_ID": "gpas_jhreels_pop",
          "Button_Image_Name": "gpas_jhreels_pop.jpg"
        },
        {
          "CN_Name": "猛虎馈赠",
          "EN_Name": "Legacy of the Tiger",
          "HTML5_Game_ID": "gpas_lottiger_pop",
          "Button_Image_Name": "gpas_lottiger_pop.jpg"
        },
        {
          "CN_Name": "气龙",
          "EN_Name": "Dragon Chi",
          "HTML5_Game_ID": "gpas_dragonchi_pop",
          "Button_Image_Name": "gpas_dragonchi_pop.jpg"
        },
        {
          "CN_Name": "西游记",
          "EN_Name": "Quest West",
          "HTML5_Game_ID": "gpas_mquest_pop",
          "Button_Image_Name": "gpas_mquest_pop.jpg"
        },
        {
          "CN_Name": "公牛财富",
          "EN_Name": "Ox Riches",
          "HTML5_Game_ID": "gpas_strongox_pop",
          "Button_Image_Name": "gpas_strongox_pop.jpg"
        },
        {
          "CN_Name": "九头蛇传奇",
          "EN_Name": "Legend of Hydra",
          "HTML5_Game_ID": "gpas_hydra_pop",
          "Button_Image_Name": "gpas_hydra_pop.jpg"
        },
        {
          "CN_Name": "水牛闪电战2",
          "EN_Name": "Buffalo Blitz II",
          "HTML5_Game_ID": "gpas_bblitz2_pop",
          "Button_Image_Name": "gpas_bblitz2_pop.jpg"
        },
        {
          "CN_Name": "青蛙之礼",
          "EN_Name": "Frog's Gift",
          "HTML5_Game_ID": "gpas_fgift_pop",
          "Button_Image_Name": "gpas_fgift_pop.jpg"
        },
        {
          "CN_Name": "野外宝藏2",
          "EN_Name": "Legacy of the Wilds 2",
          "HTML5_Game_ID": "gpas_lotwild2_pop",
          "Button_Image_Name": "gpas_lotwild2_pop.jpg"
        },
        {
          "CN_Name": "财神组合",
          "EN_Name": "Caishen Ways",
          "HTML5_Game_ID": "gpas_cways_pop",
          "Button_Image_Name": "gpas_cways_pop.jpg"
        },
        {
          "CN_Name": "黄金组合",
          "EN_Name": "Golden Ways",
          "HTML5_Game_ID": "gpas_buddhaways_pop",
          "Button_Image_Name": "gpas_buddhaways_pop.jpg"
        },
        {
          "CN_Name": "幸运星宿",
          "EN_Name": "28 Mansions",
          "HTML5_Game_ID": "gpas_28dman_pop",
          "Button_Image_Name": "gpas_28dman_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：矮人之书",
          "EN_Name": "Age of the Gods Norse: Book of Dwarves",
          "HTML5_Game_ID": "gpas_gogold_pop",
          "Button_Image_Name": "gpas_gogold_pop.jpg"
        },
        {
          "CN_Name": "众神时代：冥府统治者",
          "EN_Name": "Age of the Gods: Ruler Of The Dead",
          "HTML5_Game_ID": "gpas_aogrotu_pop",
          "Button_Image_Name": "gpas_aogrotu_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：托尔特克之宝藏",
          "EN_Name": "Gold Pile: Toltec Treasure",
          "HTML5_Game_ID": "gpas_ttreasure_pop",
          "Button_Image_Name": "gpas_ttreasure_pop.jpg"
        },
        {
          "CN_Name": "野生熔岩",
          "EN_Name": "Wild Lava",
          "HTML5_Game_ID": "gpas_wlava_pop",
          "Button_Image_Name": "gpas_wlava_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：新年之金",
          "EN_Name": "Gold Pile: New Years Gold",
          "HTML5_Game_ID": "gpas_gpnyg_pop",
          "Button_Image_Name": "gpas_gpnyg_pop.jpg"
        },
        {
          "CN_Name": "招财进宝2",
          "EN_Name": "Zhao Cai Jin Bao 2",
          "HTML5_Game_ID": "gpas_zcjbao2_pop",
          "Button_Image_Name": "gpas_zcjbao2_pop.jpg"
        },
        {
          "CN_Name": "黄金烈焰大奖：富贵工厂",
          "EN_Name": "Fire Blaze Golden: Amazing Factory",
          "HTML5_Game_ID": "gpas_afactory_pop",
          "Button_Image_Name": "gpas_afactory_pop.jpg"
        },
        {
          "CN_Name": "疯狂蜜蜂",
          "EN_Name": "Bee Frenzy",
          "HTML5_Game_ID": "gpas_bfrenzy_pop",
          "Button_Image_Name": "gpas_bfrenzy_pop.jpg"
        },
        {
          "CN_Name": "金龙鱼",
          "EN_Name": "Arowanas Luck",
          "HTML5_Game_ID": "gpas_aluck_pop",
          "Button_Image_Name": "gpas_aluck_pop.jpg"
        },
        {
          "CN_Name": "狂野蟒蛇2",
          "EN_Name": "Anaconda Wild II",
          "HTML5_Game_ID": "gpas_awild2_pop",
          "Button_Image_Name": "gpas_awild2_pop.jpg"
        },
        {
          "CN_Name": "音乐之财",
          "EN_Name": "Record Riches",
          "HTML5_Game_ID": "gpas_rriches_pop",
          "Button_Image_Name": "gpas_rriches_pop.jpg"
        },
        {
          "CN_Name": "爱情傻瓜",
          "EN_Name": "Lovefool",
          "HTML5_Game_ID": "gpas_scupid_pop",
          "Button_Image_Name": "gpas_scupid_pop.jpg"
        },
        {
          "CN_Name": "金蟾蜍的礼物",
          "EN_Name": "Toad's Gift",
          "HTML5_Game_ID": "gpas_tgift_pop",
          "Button_Image_Name": "gpas_tgift_pop.jpg"
        },
        {
          "CN_Name": "珍珠，珍珠，珍珠",
          "EN_Name": "Pearls Pearls Pearls",
          "HTML5_Game_ID": "gpas_pppearls_pop",
          "Button_Image_Name": "gpas_pppearls_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：老虎天骄",
          "EN_Name": "Gold Pile: Tigers Pride",
          "HTML5_Game_ID": "gpas_tfortune_pop",
          "Button_Image_Name": "gpas_tfortune_pop.jpg"
        },
        {
          "CN_Name": "众神时代：神谕之书",
          "EN_Name": "Age of Gods: Book of Oracle",
          "HTML5_Game_ID": "gpas_aogbooracle_pop",
          "Button_Image_Name": "gpas_aogbooracle_pop.jpg"
        },
        {
          "CN_Name": "简琼斯国王之书2",
          "EN_Name": "Jane Jones In Book Of King 2",
          "HTML5_Game_ID": "gpas_nalight_pop",
          "Button_Image_Name": "gpas_nalight_pop.jpg"
        },
        {
          "CN_Name": "烈焰大奖：河皇后",
          "EN_Name": "Fire Blaze: River Empress",
          "HTML5_Game_ID": "gpas_rempress_pop",
          "Button_Image_Name": "gpas_rempress_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：姐妹之礼",
          "EN_Name": "Fire Blaze: Sisters Gift",
          "HTML5_Game_ID": "gpas_sgift_pop",
          "Button_Image_Name": "gpas_sgift_pop.jpg"
        },
        {
          "CN_Name": "越狱的鲍勃",
          "EN_Name": "Breakout Bob",
          "HTML5_Game_ID": "gpas_bbob_pop",
          "Button_Image_Name": "gpas_bbob_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：猩猩",
          "EN_Name": "Gold Pile: Orangutan",
          "HTML5_Game_ID": "gpas_gporang_pop",
          "Button_Image_Name": "gpas_gporang_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：红巫师",
          "EN_Name": "Fire Blaze: Red Wizard",
          "HTML5_Game_ID": "gpas_rwizard_pop",
          "Button_Image_Name": "gpas_rwizard_pop.jpg"
        },
        {
          "CN_Name": "烈焰黄金：海盗铃钟",
          "EN_Name": "Fire Blaze Golden: Buccaneer Bells",
          "HTML5_Game_ID": "gpas_fbgbb_pop",
          "Button_Image_Name": "gpas_fbgbb_pop.jpg"
        },
        {
          "CN_Name": "完美大盗",
          "EN_Name": "The Perfect Heist",
          "HTML5_Game_ID": "gpas_etscore_pop",
          "Button_Image_Name": "gpas_etscore_pop.jpg"
        },
        {
          "CN_Name": "生肖魅力",
          "EN_Name": "Zodiac Charms",
          "HTML5_Game_ID": "gpas_zcharms_pop",
          "Button_Image_Name": "gpas_zcharms_pop.jpg"
        },
        {
          "CN_Name": "百万烈焰大奖：孔苏月神",
          "EN_Name": "Mega Fire Blaze: Khonsu God of Moon",
          "HTML5_Game_ID": "gpas_kgomoon_pop",
          "Button_Image_Name": "gpas_kgomoon_pop.jpg"
        },
        {
          "CN_Name": "宝石横飞：国王庭院",
          "EN_Name": "Gem Splash: Kings Court",
          "HTML5_Game_ID": "gpas_gskc_pop",
          "Button_Image_Name": "gpas_gskc_pop.jpg"
        },
        {
          "CN_Name": "玛雅积木",
          "EN_Name": "Mayan Blocks",
          "HTML5_Game_ID": "gpas_mblocks_pop",
          "Button_Image_Name": "gpas_mblocks_pop.jpg"
        },
        {
          "CN_Name": "魔法师公会",
          "EN_Name": "Sorcerer's Guild of Magic",
          "HTML5_Game_ID": "gpas_sgomagic_pop",
          "Button_Image_Name": "gpas_sgomagic_pop.jpg"
        },
        {
          "CN_Name": "绝对长毛象",
          "EN_Name": "Absolutely Mammoth",
          "HTML5_Game_ID": "gpas_amammoth_pop",
          "Button_Image_Name": "gpas_amammoth_pop.jpg"
        },
        {
          "CN_Name": "伊丝塔",
          "EN_Name": "Ishtar",
          "HTML5_Game_ID": "gpas_ishtar_pop",
          "Button_Image_Name": "gpas_ishtar_pop.jpg"
        },
        {
          "CN_Name": "发财黑豹奖池版",
          "EN_Name": "Panther Pays Power Play Jackpot",
          "HTML5_Game_ID": "gpas_ppayspp_pop",
          "Button_Image_Name": "gpas_ppayspp_pop.jpg"
        },
        {
          "CN_Name": "火焰棒",
          "EN_Name": "Flaming Bars",
          "HTML5_Game_ID": "gpas_fbars_pop",
          "Button_Image_Name": "gpas_fbars_pop.jpg"
        },
        {
          "CN_Name": "黄金烈焰大奖：苔原狼",
          "EN_Name": "Fire Blaze Golden: Tundra Wolf",
          "HTML5_Game_ID": "gpas_twolf_pop",
          "Button_Image_Name": "gpas_twolf_pop.jpg"
        },
        {
          "CN_Name": "龙之堂：雷霆之弹",
          "EN_Name": "Dragons Hall: Thundershots",
          "HTML5_Game_ID": "gpas_dhall_pop",
          "Button_Image_Name": "gpas_dhall_pop.jpg"
        },
        {
          "CN_Name": "神秘连接",
          "EN_Name": "Wild Linx",
          "HTML5_Game_ID": "gpas_wlinx_pop",
          "Button_Image_Name": "gpas_wlinx_pop.jpg"
        },
        {
          "CN_Name": "猿人传奇2奖池闪电战",
          "EN_Name": "Epic Ape II Jackpot Blitz",
          "HTML5_Game_ID": "gpas_eape2_pop",
          "Button_Image_Name": "gpas_eape2_pop.jpg"
        },
        {
          "CN_Name": "满月：白熊猫",
          "EN_Name": "Full Moon: White Panda",
          "HTML5_Game_ID": "gpas_wpanda_pop",
          "Button_Image_Name": "gpas_wpanda_pop.jpg"
        },
        {
          "CN_Name": "满月：狂野赛道",
          "EN_Name": "Full Moon: Wild Track",
          "HTML5_Game_ID": "gpas_wtrack_pop",
          "Button_Image_Name": "gpas_wtrack_pop.jpg"
        },
        {
          "CN_Name": "阿努比斯之眼",
          "EN_Name": "Eye of Anubis",
          "HTML5_Game_ID": "gpas_sscarab_pop",
          "Button_Image_Name": "gpas_sscarab_pop.jpg"
        },
        {
          "CN_Name": "国王之书2奖池版",
          "EN_Name": "Book of Kings 2 PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_bokings2pp_pop",
          "Button_Image_Name": "gpas_bokings2pp_pop.jpg"
        },
        {
          "CN_Name": "福临五象",
          "EN_Name": "Glorious Guardians",
          "HTML5_Game_ID": "gpas_gguardians_pop",
          "Button_Image_Name": "gpas_gguardians_pop.jpg"
        },
        {
          "CN_Name": "金字塔谷：能量区域",
          "EN_Name": "Pyramid Valley: Power Zones",
          "HTML5_Game_ID": "gpas_pstrike_pop",
          "Button_Image_Name": "gpas_pstrike_pop.jpg"
        },
        {
          "CN_Name": "辣椒喷发：雷霆之弹",
          "EN_Name": "Chili Eruption Thundershots",
          "HTML5_Game_ID": "gpas_ceruption_pop",
          "Button_Image_Name": "gpas_ceruption_pop.jpg"
        },
        {
          "CN_Name": "撒哈拉财富：现金收集",
          "EN_Name": "Sahara Riches: Cash Collect",
          "HTML5_Game_ID": "gpas_ccsahara_pop",
          "Button_Image_Name": "gpas_ccsahara_pop.jpg"
        },
        {
          "CN_Name": "猴鼠",
          "EN_Name": "Monkey and Rat",
          "HTML5_Game_ID": "gpas_marat_pop",
          "Button_Image_Name": "gpas_marat_pop.jpg"
        },
        {
          "CN_Name": "亚特兰蒂斯：现金收集",
          "EN_Name": "Atlantis Cash Collect",
          "HTML5_Game_ID": "gpas_ccskingofa_pop",
          "Button_Image_Name": "gpas_ccskingofa_pop.jpg"
        },
        {
          "CN_Name": "阿努比斯的诅咒",
          "EN_Name": "Curse of Anubis",
          "HTML5_Game_ID": "gpas_soanubis_pop",
          "Button_Image_Name": "gpas_soanubis_pop.jpg"
        },
        {
          "CN_Name": "阿兹特克探险",
          "EN_Name": "Aztec Expedition Thundershots",
          "HTML5_Game_ID": "gpas_aexpedition_pop",
          "Button_Image_Name": "gpas_aexpedition_pop.jpg"
        },
        {
          "CN_Name": "金秋",
          "EN_Name": "Autumn Gold",
          "HTML5_Game_ID": "pop_autumngold_eye",
          "Button_Image_Name": "pop_autumngold_eye.jpg"
        },
        {
          "CN_Name": "鏖战英警",
          "EN_Name": "Beat The Bobbies",
          "HTML5_Game_ID": "pop_btthbbbs_eye",
          "Button_Image_Name": "pop_btthbbbs_eye.jpg"
        },
        {
          "CN_Name": "无敌小蜜蜂",
          "EN_Name": "Beez Kneez",
          "HTML5_Game_ID": "pop_beezkneez_eye",
          "Button_Image_Name": "pop_beezkneez_eye.jpg"
        },
        {
          "CN_Name": "弹跳球2",
          "EN_Name": "Bouncy Balls 2",
          "HTML5_Game_ID": "pop_bfad477a_eye",
          "Button_Image_Name": "pop_bfad477a_eye.jpg"
        },
        {
          "CN_Name": "前往开罗的大篷车",
          "EN_Name": "Caravan to Cairo",
          "HTML5_Game_ID": "pop_caravantcro_eye",
          "Button_Image_Name": "pop_caravantcro_eye.jpg"
        },
        {
          "CN_Name": "转运四叶草2",
          "EN_Name": "Clover Rollover 2",
          "HTML5_Game_ID": "pop_1fc2f43a_eye",
          "Button_Image_Name": "pop_1fc2f43a_eye.jpg"
        },
        {
          "CN_Name": "水晶莲花",
          "EN_Name": "Crystal Lotus",
          "HTML5_Game_ID": "pop_crystallotus_eye",
          "Button_Image_Name": "pop_crystallotus_eye.jpg"
        },
        {
          "CN_Name": "足球杯狂欢节",
          "EN_Name": "Cup Carnaval",
          "HTML5_Game_ID": "pop_cupcarnaval_eye",
          "Button_Image_Name": "pop_cupcarnaval_eye.jpg"
        },
        {
          "CN_Name": "被施咒的王子",
          "EN_Name": "Enchanted Prince",
          "HTML5_Game_ID": "pop_nchntdprnc_eye",
          "Button_Image_Name": "pop_nchntdprnc_eye.jpg"
        },
        {
          "CN_Name": "毛绒爱宠大体验",
          "EN_Name": "Fluffy Favourites Megaways",
          "HTML5_Game_ID": "pop_df48c20d_eye",
          "Button_Image_Name": "pop_df48c20d_eye.jpg"
        },
        {
          "CN_Name": "烟火撩人",
          "EN_Name": "Fireworks Frenzy",
          "HTML5_Game_ID": "pop_frwrksfrnz_eye",
          "Button_Image_Name": "pop_frwrksfrnz_eye.jpg"
        },
        {
          "CN_Name": "毛绒玩具收藏品",
          "EN_Name": "Fluffy Favourites",
          "HTML5_Game_ID": "pop_flfffvrts_eye",
          "Button_Image_Name": "pop_flfffvrts_eye.jpg"
        },
        {
          "CN_Name": "银河少女",
          "EN_Name": "Galactic Girls",
          "HTML5_Game_ID": "pop_e63aedf0_eye",
          "Button_Image_Name": "pop_e63aedf0_eye.jpg"
        },
        {
          "CN_Name": "热血沸腾",
          "EN_Name": "Heartburst",
          "HTML5_Game_ID": "pop_heartburst_eye",
          "Button_Image_Name": "pop_heartburst_eye.jpg"
        },
        {
          "CN_Name": "掌控保险箱",
          "EN_Name": "Hold The Safe",
          "HTML5_Game_ID": "pop_holdthesafe_eye",
          "Button_Image_Name": "pop_holdthesafe_eye.jpg"
        },
        {
          "CN_Name": "幸运爱尔兰",
          "EN_Name": "Irish Luck(Eyecon)",
          "HTML5_Game_ID": "pop_irishluck_eye",
          "Button_Image_Name": "pop_irishluck_eye.jpg"
        },
        {
          "CN_Name": "吻我吧，三叶草妖精",
          "EN_Name": "Kiss Me Clover",
          "HTML5_Game_ID": "pop_kissmeclover_eye",
          "Button_Image_Name": "pop_kissmeclover_eye.jpg"
        },
        {
          "CN_Name": "小猫派彩",
          "EN_Name": "Kitty Payout",
          "HTML5_Game_ID": "pop_kittypayout_eye",
          "Button_Image_Name": "pop_kittypayout_eye.jpg"
        },
        {
          "CN_Name": "失落的岛屿",
          "EN_Name": "Lost Island",
          "HTML5_Game_ID": "pop_lostisland_eye",
          "Button_Image_Name": "pop_lostisland_eye.jpg"
        },
        {
          "CN_Name": "摔跤大战",
          "EN_Name": "Lucha Rumble",
          "HTML5_Game_ID": "pop_lucharumble_eye",
          "Button_Image_Name": "pop_lucharumble_eye.jpg"
        },
        {
          "CN_Name": "超越对手",
          "EN_Name": "Past the Post",
          "HTML5_Game_ID": "pop_pastthepost_eye",
          "Button_Image_Name": "pop_pastthepost_eye.jpg"
        },
        {
          "CN_Name": "小猪派彩",
          "EN_Name": "Piggy Payout",
          "HTML5_Game_ID": "pop_piggypayout_eye",
          "Button_Image_Name": "pop_piggypayout_eye.jpg"
        },
        {
          "CN_Name": "小猪的大餐",
          "EN_Name": "Pigs Feast",
          "HTML5_Game_ID": "pop_pigsfeast_eye",
          "Button_Image_Name": "pop_pigsfeast_eye.jpg"
        },
        {
          "CN_Name": "小狗派彩",
          "EN_Name": "Puggy Payout",
          "HTML5_Game_ID": "pop_puggypayout_eye",
          "Button_Image_Name": "pop_puggypayout_eye.jpg"
        },
        {
          "CN_Name": "华丽重转",
          "EN_Name": "Rebets Splendour",
          "HTML5_Game_ID": "pop_rbtssplndr_eye",
          "Button_Image_Name": "pop_rbtssplndr_eye.jpg"
        },
        {
          "CN_Name": "秘密花园",
          "EN_Name": "Secret Garden",
          "HTML5_Game_ID": "pop_secretgarden_eye",
          "Button_Image_Name": "pop_secretgarden_eye.jpg"
        },
        {
          "CN_Name": "萨满之梦2",
          "EN_Name": "Shamans Dream 2",
          "HTML5_Game_ID": "pop_047c7908_eye",
          "Button_Image_Name": "pop_047c7908_eye.jpg"
        },
        {
          "CN_Name": "疯狂购物",
          "EN_Name": "Shopping Spree",
          "HTML5_Game_ID": "pop_shppngspr_eye",
          "Button_Image_Name": "pop_shppngspr_eye.jpg"
        },
        {
          "CN_Name": "狂潮",
          "EN_Name": "Stampede",
          "HTML5_Game_ID": "pop_stampede_eye",
          "Button_Image_Name": "pop_stampede_eye.jpg"
        },
        {
          "CN_Name": "重现 777",
          "EN_Name": "Stepback 7s",
          "HTML5_Game_ID": "pop_stepback7s_eye",
          "Button_Image_Name": "pop_stepback7s_eye.jpg"
        },
        {
          "CN_Name": "热血前锋",
          "EN_Name": "Striker Goes Wild",
          "HTML5_Game_ID": "pop_strkrgswld_eye",
          "Button_Image_Name": "pop_strkrgswld_eye.jpg"
        },
        {
          "CN_Name": "伊莉斯神庙2",
          "EN_Name": "Temple of Iris 2",
          "HTML5_Game_ID": "pop_tmplfrs_eye",
          "Button_Image_Name": "pop_tmplfrs_eye.jpg"
        },
        {
          "CN_Name": "圣诞很快乐",
          "EN_Name": "Very Merry Christmas",
          "HTML5_Game_ID": "pop_vrmrrchrstms_eye",
          "Button_Image_Name": "pop_vrmrrchrstms_eye.jpg"
        },
        {
          "CN_Name": "德古拉城堡",
          "EN_Name": "Vlads Castle",
          "HTML5_Game_ID": "pop_vladscastle_eye",
          "Button_Image_Name": "pop_vladscastle_eye.jpg"
        },
        {
          "CN_Name": "白色巫师",
          "EN_Name": "White Wizard",
          "HTML5_Game_ID": "pop_whitewizard_eye",
          "Button_Image_Name": "pop_whitewizard_eye.jpg"
        },
        {
          "CN_Name": "白色巫师豪华版",
          "EN_Name": "White Wizard Deluxe",
          "HTML5_Game_ID": "pop_whtwzrddlx_eye",
          "Button_Image_Name": "pop_whtwzrddlx_eye.jpg"
        },
        {
          "CN_Name": "幸运爆竹",
          "EN_Name": "Xingyun BaoZhu",
          "HTML5_Game_ID": "pop_xngnbzh_eye",
          "Button_Image_Name": "pop_xngnbzh_eye.jpg"
        },
        {
          "CN_Name": "圣诞现金",
          "EN_Name": "Xmas Cash",
          "HTML5_Game_ID": "pop_xmascash_eye",
          "Button_Image_Name": "pop_xmascash_eye.jpg"
        },
        {
          "CN_Name": "疯狂悠悠球",
          "EN_Name": "Yoyo's Wild",
          "HTML5_Game_ID": "pop_yoyoswild_eye",
          "Button_Image_Name": "pop_yoyoswild_eye.jpg"
        },
        {
          "CN_Name": "烈焰至尊：蓝巫师Megaways",
          "EN_Name": "Fire Blaze: Blue Wizard Megaways",
          "HTML5_Game_ID": "gpas_mgbwizard_pop",
          "Button_Image_Name": "gpas_mgbwizard_pop.jpg"
        },
        {
          "CN_Name": "英雄之箭",
          "EN_Name": "Heroes Arrow",
          "HTML5_Game_ID": "gpas_harrow_pop",
          "Button_Image_Name": "gpas_harrow_pop.jpg"
        },
        {
          "CN_Name": "侏罗纪岛2",
          "EN_Name": "Jurassic Island 2",
          "HTML5_Game_ID": "gpas_jisland2_pop",
          "Button_Image_Name": "gpas_jisland2_pop.jpg"
        },
        {
          "CN_Name": "巨型烈焰：大马戏！",
          "EN_Name": "Mega Fire Blaze: Big Circus!",
          "HTML5_Game_ID": "gpas_bcircus_pop",
          "Button_Image_Name": "gpas_bcircus_pop.jpg"
        },
        {
          "CN_Name": "能量区域：雷鸟",
          "EN_Name": "Power Zones: Thunder Birds",
          "HTML5_Game_ID": "gpas_tbirds_pop",
          "Button_Image_Name": "gpas_tbirds_pop.jpg"
        },
        {
          "CN_Name": "钓好运",
          "EN_Name": "Fishin' Bonanza",
          "HTML5_Game_ID": "gpas_ffever_pop",
          "Button_Image_Name": "gpas_ffever_pop.jpg"
        },
        {
          "CN_Name": "小妖精的好运：现金收集",
          "EN_Name": "Leprechaun’s Luck: Cash Collect",
          "HTML5_Game_ID": "gpas_ccluck_pop",
          "Button_Image_Name": "gpas_ccluck_pop.jpg"
        },
        {
          "CN_Name": "巨型烈焰：狂野手枪",
          "EN_Name": "Mega Fire Blaze: Wild Pistolero",
          "HTML5_Game_ID": "gpas_wpisto_pop",
          "Button_Image_Name": "gpas_wpisto_pop.jpg"
        },
        {
          "CN_Name": "极限热辣宝石",
          "EN_Name": "Hot Gems Xtreme",
          "HTML5_Game_ID": "gpas_hgextreme_pop",
          "Button_Image_Name": "gpas_hgextreme_pop.jpg"
        },
        {
          "CN_Name": "蟒蛇献宝",
          "EN_Name": "Anaconda Uncoiled",
          "HTML5_Game_ID": "gpas_auncoil_pop",
          "Button_Image_Name": "gpas_auncoil_pop.jpg"
        },
        {
          "CN_Name": "终极火辣",
          "EN_Name": "Chilli Xtreme",
          "HTML5_Game_ID": "gpas_cxtreme_pop",
          "Button_Image_Name": "gpas_cxtreme_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：消防勇士",
          "EN_Name": "Fire Blaze: Fire Fighter",
          "HTML5_Game_ID": "gpas_ffighter_pop",
          "Button_Image_Name": "gpas_ffighter_pop.jpg"
        },
        {
          "CN_Name": "武术拳",
          "EN_Name": "Wushu Punch",
          "HTML5_Game_ID": "gpas_wpunch_pop",
          "Button_Image_Name": "gpas_wpunch_pop.jpg"
        },
        {
          "CN_Name": "钻石金字塔",
          "EN_Name": "Diamond Rise",
          "HTML5_Game_ID": "gpas_drise_pop",
          "Button_Image_Name": "gpas_drise_pop.jpg"
        },
        {
          "CN_Name": "热辣财富",
          "EN_Name": "Hit Bar",
          "HTML5_Game_ID": "gpas_fmhitbar_pop",
          "Button_Image_Name": "gpas_fmhitbar_pop.jpg"
        },
        {
          "CN_Name": "萬能帽 : 金灯",
          "EN_Name": "Mighty Hat: Lamp of Gold",
          "HTML5_Game_ID": "gpas_gwish_pop",
          "Button_Image_Name": "gpas_gwish_pop.jpg"
        },
        {
          "CN_Name": "如意锦鲤",
          "EN_Name": "Koi Harmony",
          "HTML5_Game_ID": "gpas_koihar_pop",
          "Button_Image_Name": "gpas_koihar_pop.jpg"
        },
        {
          "CN_Name": "银弹匪徒：现金收集",
          "EN_Name": "Silver Bullet Bandit: Cash Collect",
          "HTML5_Game_ID": "gpas_sbullet_pop",
          "Button_Image_Name": "gpas_sbullet_pop.jpg"
        },
        {
          "CN_Name": "小丑狂奔",
          "EN_Name": "Joker Rush",
          "HTML5_Game_ID": "gpas_jrush_pop",
          "Button_Image_Name": "gpas_jrush_pop.jpg"
        },
        {
          "CN_Name": "大秦帝国：天界守护者",
          "EN_Name": "Qin's Empire: Celestial Guardians",
          "HTML5_Game_ID": "gpas_cguard_pop",
          "Button_Image_Name": "gpas_cguard_pop.jpg"
        },
        {
          "CN_Name": "乘数怪物",
          "EN_Name": "Monster Multipliers",
          "HTML5_Game_ID": "gpas_sking_pop",
          "Button_Image_Name": "gpas_sking_pop.jpg"
        },
        {
          "CN_Name": "大秦帝国：财神庙",
          "EN_Name": "Qin's Empire: Caishen's Temple",
          "HTML5_Game_ID": "gpas_ctemple_pop",
          "Button_Image_Name": "gpas_ctemple_pop.jpg"
        },
        {
          "CN_Name": "烈焰四驱：魅力凯尔特",
          "EN_Name": "Fire Blaze Quattro: Celtic Charm",
          "HTML5_Game_ID": "gpas_qccharm_pop",
          "Button_Image_Name": "gpas_qccharm_pop.jpg"
        },
        {
          "CN_Name": "萬能帽：我的宝矿",
          "EN_Name": "Mighty Hat: Mine O' Mine",
          "HTML5_Game_ID": "gpas_grush_pop",
          "Button_Image_Name": "gpas_grush_pop.jpg"
        },
        {
          "CN_Name": "黄金疯狂：奥莱利的财富",
          "EN_Name": "Gold Hit: O'Reilly's Riches",
          "HTML5_Game_ID": "gpas_bcash_pop",
          "Button_Image_Name": "gpas_bcash_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：绿巫师",
          "EN_Name": "Fire Blaze: Green Wizard",
          "HTML5_Game_ID": "gpas_gwizard_pop",
          "Button_Image_Name": "gpas_gwizard_pop.jpg"
        },
        {
          "CN_Name": "女巫：现金收集",
          "EN_Name": "Witches: Cash Collect",
          "HTML5_Game_ID": "gpas_cchfortune_pop",
          "Button_Image_Name": "gpas_cchfortune_pop.jpg"
        },
        {
          "CN_Name": "萬能帽：神秘传说",
          "EN_Name": "Mighty Hat: Mystic Tales",
          "HTML5_Game_ID": "gpas_mforest_pop",
          "Button_Image_Name": "gpas_mforest_pop.jpg"
        },
        {
          "CN_Name": "足球！现金收集",
          "EN_Name": "Football! Cash Collect",
          "HTML5_Game_ID": "gpas_focashco_pop",
          "Button_Image_Name": "gpas_focashco_pop.jpg"
        },
        {
          "CN_Name": "撒哈拉财富MegaWays：现金收集",
          "EN_Name": "Sahara Riches MegaWays: Cash Collect",
          "HTML5_Game_ID": "gpas_mgccsahara_pop",
          "Button_Image_Name": "gpas_mgccsahara_pop.jpg"
        },
        {
          "CN_Name": "大枢纽：黄金枪",
          "EN_Name": "Grand Junction: Golden Guns",
          "HTML5_Game_ID": "gpas_ggun_pop",
          "Button_Image_Name": "gpas_ggun_pop.jpg"
        },
        {
          "CN_Name": "阿兹特克：奖励线",
          "EN_Name": "Azteca Bonus Lines",
          "HTML5_Game_ID": "gpas_azboli_pop",
          "Button_Image_Name": "gpas_azboli_pop.jpg"
        },
        {
          "CN_Name": "玉兔进财：金钱满屋",
          "EN_Name": "Yu Tu Jin Cai: Cash Collect",
          "HTML5_Game_ID": "gpas_rabbitcash_pop",
          "Button_Image_Name": "gpas_rabbitcash_pop.jpg"
        },
        {
          "CN_Name": "张飞劈鱼",
          "EN_Name": "Warrior Fishing",
          "HTML5_Game_ID": "pop_3d62bb7b_3kg",
          "Button_Image_Name": "pop_3d62bb7b_3kg.jpg"
        },
        {
          "CN_Name": "终极火辣奖池版",
          "EN_Name": "Chilli Xtreme PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_cxtremepp_pop",
          "Button_Image_Name": "gpas_cxtremepp_pop.jpg"
        },
        {
          "CN_Name": "间谍觉醒",
          "EN_Name": "Spy Rise",
          "HTML5_Game_ID": "gpas_secagent_pop",
          "Button_Image_Name": "gpas_secagent_pop.jpg"
        },
        {
          "CN_Name": "众神时代：海洋统治者",
          "EN_Name": "Age of the Gods: Ruler of the Seas",
          "HTML5_Game_ID": "gpas_aogrotsea_pop",
          "Button_Image_Name": "gpas_aogrotsea_pop.jpg"
        },
        {
          "CN_Name": "众神时代：史诗特洛伊",
          "EN_Name": "Age of the Gods: Epic Troy",
          "HTML5_Game_ID": "gpas_aogetroy_pop",
          "Button_Image_Name": "gpas_aogetroy_pop.jpg"
        },
        {
          "CN_Name": "众神时代：阿波罗之力",
          "EN_Name": "Age of the Gods: Apollo Power",
          "HTML5_Game_ID": "gpas_appower_pop",
          "Button_Image_Name": "gpas_appower_pop.jpg"
        },
        {
          "CN_Name": "闪耀铃铛奖池版",
          "EN_Name": "Blazing Bells PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_bbellspp_pop",
          "Button_Image_Name": "gpas_bbellspp_pop.jpg"
        },
        {
          "CN_Name": "国王之书奖池版",
          "EN_Name": "Book of Kings PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_bokingpp_pop",
          "Button_Image_Name": "gpas_bokingpp_pop.jpg"
        },
        {
          "CN_Name": "埃及绿宝石奖池版",
          "EN_Name": "Egyptian Emeralds PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_eemeraldspp_pop",
          "Button_Image_Name": "gpas_eemeraldspp_pop.jpg"
        },
        {
          "CN_Name": "顶级图腾奖池版",
          "EN_Name": "Tip Top Totems PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_tttotemspp_pop",
          "Button_Image_Name": "gpas_tttotemspp_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：北欧传奇",
          "EN_Name": "Age of the Gods Norse: Norse Legends",
          "HTML5_Game_ID": "gpas_aognnl_pop",
          "Button_Image_Name": "gpas_aognnl_pop.jpg"
        },
        {
          "CN_Name": "风暴精灵奖池版",
          "EN_Name": "Djinn of Storms PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_dostormspp_pop",
          "Button_Image_Name": "gpas_dostormspp_pop.jpg"
        },
        {
          "CN_Name": "更好的百搭奖池版",
          "EN_Name": "Better Wilds PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_betwildspp_pop",
          "Button_Image_Name": "gpas_betwildspp_pop.jpg"
        },
        {
          "CN_Name": "冰暴：强力累积奖金",
          "EN_Name": "Storms of Ice PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_soicepp_pop",
          "Button_Image_Name": "gpas_soicepp_pop.jpg"
        },
        {
          "CN_Name": "犀牛闪电战",
          "EN_Name": "Rhino Blitz",
          "HTML5_Game_ID": "gpas_rslot_pop",
          "Button_Image_Name": "gpas_rslot_pop.jpg"
        },
        {
          "CN_Name": "女王的诅咒：帝国宝藏",
          "EN_Name": "The Queen's Curse: Empire Treasures",
          "HTML5_Game_ID": "gpas_tqcempt_pop",
          "Button_Image_Name": "gpas_tqcempt_pop.jpg"
        },
        {
          "CN_Name": "足球刮刮乐奖池版",
          "EN_Name": "Football Scratch PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_fballiwpp_pop",
          "Button_Image_Name": "gpas_fballiwpp_pop.jpg"
        },
        {
          "CN_Name": "野生熔岩刮刮乐",
          "EN_Name": "Wild Lava Scratch",
          "HTML5_Game_ID": "gpas_wlavaiw_pop",
          "Button_Image_Name": "gpas_wlavaiw_pop.jpg"
        },
        {
          "CN_Name": "热辣宝石刮刮乐",
          "EN_Name": "Hot Gems Xtreme Scratch",
          "HTML5_Game_ID": "gpas_hgextremeiw_pop",
          "Button_Image_Name": "gpas_hgextremeiw_pop.jpg"
        },
        {
          "CN_Name": "众神时代：奇迹战士",
          "EN_Name": "Age of the Gods: Wonder Warriors",
          "HTML5_Game_ID": "gpas_aogww_pop",
          "Button_Image_Name": "gpas_aogww_pop.jpg"
        },
        {
          "CN_Name": "众神时代：奥林匹斯转盘",
          "EN_Name": "Age of the Gods: Wheels of Olympus",
          "HTML5_Game_ID": "gpas_aogwfot_pop",
          "Button_Image_Name": "gpas_aogwfot_pop.jpg"
        },
        {
          "CN_Name": "众神时代：迷宫守护者",
          "EN_Name": "Age of the Gods: Maze Keeper",
          "HTML5_Game_ID": "gpas_mkeeper_pop",
          "Button_Image_Name": "gpas_mkeeper_pop.jpg"
        },
        {
          "CN_Name": "众神时代：风暴之神2",
          "EN_Name": "Age of the Gods: God of Storms 2",
          "HTML5_Game_ID": "gpas_gstorm2_pop",
          "Button_Image_Name": "gpas_gstorm2_pop.jpg"
        },
        {
          "CN_Name": "火热皇冠豪华版",
          "EN_Name": "Hot Crown Deluxe",
          "HTML5_Game_ID": "gpas_hcdelux_pop",
          "Button_Image_Name": "gpas_hcdelux_pop.jpg"
        },
        {
          "CN_Name": "王者闪电战",
          "EN_Name": "King Blitz",
          "HTML5_Game_ID": "gpas_bprog_pop",
          "Button_Image_Name": "gpas_bprog_pop.jpg"
        },
        {
          "CN_Name": "进击的闪电马奖池版",
          "EN_Name": "Stallion Strike PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_sstrikepp_pop",
          "Button_Image_Name": "gpas_sstrikepp_pop.jpg"
        },
        {
          "CN_Name": "波斯的骄傲：帝国宝藏",
          "EN_Name": "Pride of Persia: Empire Treasures",
          "HTML5_Game_ID": "gpas_etpop_pop",
          "Button_Image_Name": "gpas_etpop_pop.jpg"
        },
        {
          "CN_Name": "维京人：帝国宝藏",
          "EN_Name": "Vikings: Empire Treasures",
          "HTML5_Game_ID": "gpas_vemptr_pop",
          "Button_Image_Name": "gpas_vemptr_pop.jpg"
        },
        {
          "CN_Name": "狂野十字军：帝国宝藏",
          "EN_Name": "Wild Crusade: Empire Treasures",
          "HTML5_Game_ID": "gpas_wcrusade_pop",
          "Button_Image_Name": "gpas_wcrusade_pop.jpg"
        },
        {
          "CN_Name": "水牛闪电战Megaways",
          "EN_Name": "Buffalo Blitz: Megaways",
          "HTML5_Game_ID": "gpas_bbmways_pop",
          "Button_Image_Name": "gpas_bbmways_pop.jpg"
        },
        {
          "CN_Name": "鲨鱼闪电战",
          "EN_Name": "Shark Blitz",
          "HTML5_Game_ID": "gpas_sharks_pop",
          "Button_Image_Name": "gpas_sharks_pop.jpg"
        },
        {
          "CN_Name": "神秘连接 强力累积奖金",
          "EN_Name": "Wild LinX PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_wlinxpp_pop",
          "Button_Image_Name": "gpas_wlinxpp_pop.jpg"
        },
        {
          "CN_Name": "热辣财富奖池版",
          "EN_Name": "Hit Bar PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_fmhitbarpp_pop",
          "Button_Image_Name": "gpas_fmhitbarpp_pop.jpg"
        },
        {
          "CN_Name": "泰国神庙",
          "EN_Name": "Thai Temple",
          "HTML5_Game_ID": "tht",
          "Button_Image_Name": "tht.jpg"
        },
        {
          "CN_Name": "年年有余亚洲版",
          "EN_Name": "Nian Nian You Yu Asia",
          "HTML5_Game_ID": "nian_k",
          "Button_Image_Name": "nian_k.jpg"
        },
        {
          "CN_Name": "众神时代：奥林匹斯之王",
          "EN_Name": "Age of the Gods: King of Olympus",
          "HTML5_Game_ID": "zeus",
          "Button_Image_Name": "zeus.jpg"
        },
        {
          "CN_Name": "华尔街狂热",
          "EN_Name": "Wall Street Fever",
          "HTML5_Game_ID": "wsffr",
          "Button_Image_Name": "wsffr.jpg"
        },
        {
          "CN_Name": "拉美西斯金字塔",
          "EN_Name": "Pyramid of Ramesses",
          "HTML5_Game_ID": "pyrr",
          "Button_Image_Name": "pyrr.jpg"
        },
        {
          "CN_Name": "彩池铃铛",
          "EN_Name": "Jackpot Bells",
          "HTML5_Game_ID": "jbells",
          "Button_Image_Name": "jbells.jpg"
        },
        {
          "CN_Name": "圣诞彩池铃铛",
          "EN_Name": "Christmas Jackpot Bells",
          "HTML5_Game_ID": "cbells",
          "Button_Image_Name": "cbells.jpg"
        },
        {
          "CN_Name": "绿野仙踪",
          "EN_Name": "Winnings of Oz",
          "HTML5_Game_ID": "ashwnoz",
          "Button_Image_Name": "ashwnoz.jpg"
        },
        {
          "CN_Name": "妖精的幸运",
          "EN_Name": "Leprechaun's Luck",
          "HTML5_Game_ID": "ashlcl",
          "Button_Image_Name": "ashlcl.jpg"
        },
        {
          "CN_Name": "水果大赢家",
          "EN_Name": "Juicy Booty",
          "HTML5_Game_ID": "ashjcb",
          "Button_Image_Name": "ashjcb.jpg"
        },
        {
          "CN_Name": "仙境冒险",
          "EN_Name": "Adventures Beyond Wonderland",
          "HTML5_Game_ID": "ashabw",
          "Button_Image_Name": "ashabw.jpg"
        },
        {
          "CN_Name": "众神时代：奥林匹斯统治者",
          "EN_Name": "Age of the Gods: Rulers of Olympus",
          "HTML5_Game_ID": "aogroo",
          "Button_Image_Name": "aogroo.jpg"
        },
        {
          "CN_Name": "无尽之龙",
          "EN_Name": "Infinity Dragon",
          "HTML5_Game_ID": "infdrg",
          "Button_Image_Name": "infdrg.jpg"
        },
        {
          "CN_Name": "世界统治者：帝国宝藏",
          "EN_Name": "Rulers of the World: Empire Treasures",
          "HTML5_Game_ID": "gfal",
          "Button_Image_Name": "gfal.jpg"
        },
        {
          "CN_Name": "假日车站豪华版",
          "EN_Name": "Vacation Station Deluxe",
          "HTML5_Game_ID": "vcstd",
          "Button_Image_Name": "vcstd.jpg"
        },
        {
          "CN_Name": "假日车站",
          "EN_Name": "Vacation Station",
          "HTML5_Game_ID": "er",
          "Button_Image_Name": "er.jpg"
        },
        {
          "CN_Name": "丛林之心",
          "EN_Name": "Heart of the Jungle",
          "HTML5_Game_ID": "ashhotj",
          "Button_Image_Name": "ashhotj.jpg"
        },
        {
          "CN_Name": "亚特兰蒂斯女王",
          "EN_Name": "Atlantis Queen",
          "HTML5_Game_ID": "gtsatq",
          "Button_Image_Name": "gtsatq.jpg"
        },
        {
          "CN_Name": "英式足球嘉年华",
          "EN_Name": "Football Carnival",
          "HTML5_Game_ID": "gtsfc",
          "Button_Image_Name": "gtsfc.jpg"
        },
        {
          "CN_Name": "命运女神",
          "EN_Name": "Miss Fortune",
          "HTML5_Game_ID": "mfrt",
          "Button_Image_Name": "mfrt.jpg"
        },
        {
          "CN_Name": "狼狼狼",
          "EN_Name": "Wolves Wolves Wolves",
          "HTML5_Game_ID": "www",
          "Button_Image_Name": "www.jpg"
        },
        {
          "CN_Name": "神灯宝藏",
          "EN_Name": "Treasures of the Lamps",
          "HTML5_Game_ID": "totl",
          "Button_Image_Name": "totl.jpg"
        },
        {
          "CN_Name": "星球觉醒",
          "EN_Name": "Stars Awakening",
          "HTML5_Game_ID": "strsawk",
          "Button_Image_Name": "strsawk.jpg"
        },
        {
          "CN_Name": "神石",
          "EN_Name": "Sacred Stones",
          "HTML5_Game_ID": "scrdstns",
          "Button_Image_Name": "scrdstns.jpg"
        },
        {
          "CN_Name": "皇家重转豪华版",
          "EN_Name": "Royal Respin Deluxe",
          "HTML5_Game_ID": "rrd",
          "Button_Image_Name": "rrd.jpg"
        },
        {
          "CN_Name": "马基荒野",
          "EN_Name": "Maji Wilds",
          "HTML5_Game_ID": "mwilds",
          "Button_Image_Name": "mwilds.jpg"
        },
        {
          "CN_Name": "谋杀之谜",
          "EN_Name": "Murder Mystery",
          "HTML5_Game_ID": "murder",
          "Button_Image_Name": "murder.jpg"
        },
        {
          "CN_Name": "闪耀生命",
          "EN_Name": "Luminous Life",
          "HTML5_Game_ID": "lumli",
          "Button_Image_Name": "lumli.jpg"
        },
        {
          "CN_Name": "赛鹅图",
          "EN_Name": "Juego De La Oca",
          "HTML5_Game_ID": "jdlo",
          "Button_Image_Name": "jdlo.jpg"
        },
        {
          "CN_Name": "全息荒野",
          "EN_Name": "Hologram Wilds",
          "HTML5_Game_ID": "hwilds",
          "Button_Image_Name": "hwilds.jpg"
        },
        {
          "CN_Name": "宝石狂热",
          "EN_Name": "Gem Heat",
          "HTML5_Game_ID": "gemheat",
          "Button_Image_Name": "gemheat.jpg"
        },
        {
          "CN_Name": "银河斑纹",
          "EN_Name": "Galactic Streak",
          "HTML5_Game_ID": "galst",
          "Button_Image_Name": "galst.jpg"
        },
        {
          "CN_Name": "达芬奇之顶",
          "EN_Name": "Da Vinci's Vault",
          "HTML5_Game_ID": "dvncv",
          "Button_Image_Name": "dvncv.jpg"
        },
        {
          "CN_Name": "系上安全带",
          "EN_Name": "Buckle Up",
          "HTML5_Game_ID": "bup",
          "Button_Image_Name": "bup.jpg"
        },
        {
          "CN_Name": "大人物",
          "EN_Name": "Big Shots",
          "HTML5_Game_ID": "bigsh",
          "Button_Image_Name": "bigsh.jpg"
        },
        {
          "CN_Name": "浆果大富园",
          "EN_Name": "Berry Berry Bonanza",
          "HTML5_Game_ID": "bbbo",
          "Button_Image_Name": "bbbo.jpg"
        },
        {
          "CN_Name": "丛林危机",
          "EN_Name": "Jungle Trouble",
          "HTML5_Game_ID": "ashjut",
          "Button_Image_Name": "ashjut.jpg"
        },
        {
          "CN_Name": "侏罗纪岛",
          "EN_Name": "Jurassic Island",
          "HTML5_Game_ID": "ashjid",
          "Button_Image_Name": "ashjid.jpg"
        },
        {
          "CN_Name": "好运连连幽灵火车",
          "EN_Name": "Fairground Fortunes Ghost Train",
          "HTML5_Game_ID": "ashfgf",
          "Button_Image_Name": "ashfgf.jpg"
        },
        {
          "CN_Name": "埃及时代",
          "EN_Name": "Age of Egypt",
          "HTML5_Game_ID": "agoeg",
          "Button_Image_Name": "agoeg.jpg"
        },
        {
          "CN_Name": "王后的权杖",
          "EN_Name": "Queen of Wands",
          "HTML5_Game_ID": "qnw",
          "Button_Image_Name": "qnw.jpg"
        },


        {
          "CN_Name": "神的时代：天空之王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogrots",
          "Button_Image_Name": "aogrots.png"
        }, {
          "CN_Name": "神的时代：强大之迈达斯",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogmt",
          "Button_Image_Name": "aogmt.png"
        }, {
          "CN_Name": "神的时代：美杜莎与妖怪",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogmm",
          "Button_Image_Name": "aogmm.png"
        }, {
          "CN_Name": "金猴",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_gmacaque_pop",
          "Button_Image_Name": "gpas_gmacaque_pop.png"
        }, {
          "CN_Name": "你属什么",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_nsshen_pop",
          "Button_Image_Name": "nsshen.png"
        }, {
          "CN_Name": "财神兴盛",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_tsgift_pop",
          "Button_Image_Name": "tsgift.png"
        }, {
          "CN_Name": "老虎堆",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_tstacks_pop",
          "Button_Image_Name": "tstacks.png"
        }, {
          "CN_Name": "蓝巫师",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_bwizard_pop",
          "Button_Image_Name": "bwizard.png"
        }, {
          "CN_Name": "疯狂猴子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mnkmn",
          "Button_Image_Name": "mnkmn.png"
        }, {
          "CN_Name": "白色王者2",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "whk2",
          "Button_Image_Name": "whk2.png"
        }, {
          "CN_Name": "三张扑克牌",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "3cb",
          "Button_Image_Name": "3Cardbrag.png"
        }, {
          "CN_Name": "所有投注黑杰克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "abbj",
          "Button_Image_Name": "AllBetsBlackjack.png"
        }, {
          "CN_Name": "玻璃拖鞋",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashglss",
          "Button_Image_Name": "ashglss.png"
        }, {
          "CN_Name": "交易时刻",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashtmd",
          "Button_Image_Name": "ashtmd.png"
        }, {
          "CN_Name": "返现黑杰克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bjcb",
          "Button_Image_Name": "CashbackBlackjack.png"
        }, {
          "CN_Name": "投降21点",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bjsd2",
          "Button_Image_Name": "BlackjackSurrender.png"
        }, {
          "CN_Name": "高级黑杰克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bjto",
          "Button_Image_Name": "PremiumBlackjack.png"
        }, {
          "CN_Name": "扑克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "car",
          "Button_Image_Name": "CaribbeanStudPoker.png"
        }, {
          "CN_Name": "免费筹码21点",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fcbj",
          "Button_Image_Name": "FreeChipBlackjack.png"
        },  {
          "CN_Name": "龙门传说",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_dbond_pop",
          "Button_Image_Name": "gpas_dbond_pop.png"
        }, {
          "CN_Name": "金福龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_jflong_pop",
          "Button_Image_Name": "gpas_jflong_pop.png"
        }, {
          "CN_Name": "法老王的女儿",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_scqueen_pop",
          "Button_Image_Name": "gpas_scqueen_pop.png"
        }, {
          "CN_Name": "野蛮丛林",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_sjungle_pop",
          "Button_Image_Name": "gpas_sjungle_pop.png"
        }, {
          "CN_Name": "足智多谋",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "huh",
          "Button_Image_Name": "huhl.png"
        }, {
          "CN_Name": "金土地",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "lndg",
          "Button_Image_Name": "lndg.png"
        }, {
          "CN_Name": "迷你轮盘",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mro",
          "Button_Image_Name": "MiniRoulette.png"
        }, {
          "CN_Name": "三倍猴子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "trpmnk",
          "Button_Image_Name": "trpmnk.png"
        }, {
          "CN_Name": "金钱蛙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "jqw",
          "Button_Image_Name": "jqw.png"
        }, {
          "CN_Name": "荣耀罗马",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "rng2",
          "Button_Image_Name": "rng2.png"
        }, {
          "CN_Name": "幸运狮子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "frtln",
          "Button_Image_Name": "Fortune_Lions.png"
        }, {
          "CN_Name": "猫女王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "catqc",
          "Button_Image_Name": "catqc.png"
        }, {
          "CN_Name": "沙漠财宝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mobdt",
          "Button_Image_Name": "dt.png"
        }, {
          "CN_Name": "招财童子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "zctz",
          "Button_Image_Name": "zctz.png"
        }, {
          "CN_Name": "巴西森宝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtssmbr",
          "Button_Image_Name": "gtssmbr.png"
        }, {
          "CN_Name": "杰克与魔豆",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashbob",
          "Button_Image_Name": "ashbob.png"
        }, {
          "CN_Name": "赌场Hold 'Em游戏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "cheaa",
          "Button_Image_Name": "CasinoHoldEm.png"
        }, {
          "CN_Name": "埃斯梅拉达",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "esm",
          "Button_Image_Name": "esm.png"
        },   {
          "CN_Name": "凤凰之道",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "wotp",
          "Button_Image_Name": "wotp.png"
        }, {
          "CN_Name": "币币币",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ccccny",
          "Button_Image_Name": "ccccny.png"
        }, {
          "CN_Name": "虎爪",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "tigc",
          "Button_Image_Name": "tigc.png"
        }, {
          "CN_Name": "狂野蟒蛇",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "anwild",
          "Button_Image_Name": "anwild.png"
        }, {
          "CN_Name": "发财黑豹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_panthpays_pop",
          "Button_Image_Name": "gpas_panthpays_pop.png"
        }, {
          "CN_Name": "金福幸运",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_xjinfu_pop",
          "Button_Image_Name": "gpas_xjinfu_pop.png"
        }, {
          "CN_Name": "嫦娥奔月",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_elady_pop",
          "Button_Image_Name": "gpas_elady_pop.png"
        }, {
          "CN_Name": "青龙,白虎,朱雀,玄武",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_tigertdp_pop",
          "Button_Image_Name": "gpas_tigertdp_pop.png"
        }, {
          "CN_Name": "天之女王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_squeen_pop",
          "Button_Image_Name": "gpas_squeen_pop.png"
        }, {
          "CN_Name": "百家乐",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ba",
          "Button_Image_Name": "Baccarat.png"
        }, {
          "CN_Name": "龙之战士",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "drgch",
          "Button_Image_Name": "drgch.png"
        }, {
          "CN_Name": "猿人传奇",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "epa",
          "Button_Image_Name": "epa.png"
        }, {
          "CN_Name": "每个人头奖",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "evj",
          "Button_Image_Name": "EverybodysJackpot.png"
        }, {
          "CN_Name": "丛林巨兽",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "jnglg",
          "Button_Image_Name": "jnglg.png"
        }, {
          "CN_Name": "龙家祥运",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ljxy",
          "Button_Image_Name": "ljxy.png"
        }, {
          "CN_Name": "超级法老王宝藏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "phtd",
          "Button_Image_Name": "phtd.png"
        }, {
          "CN_Name": "武士元素",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pisa",
          "Button_Image_Name": "pisa.png"
        }, {
          "CN_Name": "幸运盖尔",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "popc",
          "Button_Image_Name": "popc.png"
        }, {
          "CN_Name": "云从龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "yclong",
          "Button_Image_Name": "YunCongLong.png"
        }, {
          "CN_Name": "玄圃联欢",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "xufe",
          "Button_Image_Name": "XuanPuLianHuan.png"
        }, {
          "CN_Name": "烈焰钻石",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ght_a",
          "Button_Image_Name": "LieYanZuanShi.png"
        }, {
          "CN_Name": "FunkyMonkeyJackpot",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fmjp",
          "Button_Image_Name": "FunkyMonkeyJackpot.png"
        }, {
          "CN_Name": "Great Blue Jackpot",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "grbjp",
          "Button_Image_Name": "GreatBlueJackpot.png"
        }, {
          "CN_Name": "幸运日",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fday",
          "Button_Image_Name": "FortuneDay.png"
        }, {
          "CN_Name": "God of Storms",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aeolus",
          "Button_Image_Name": "AgeoftheGodsGodofStorms.png"
        }, {
          "CN_Name": "LegacyOfWild",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "legwld",
          "Button_Image_Name": "LegacyoftheWild.png"
        }, {
          "CN_Name": "Ice Cave",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashicv",
          "Button_Image_Name": "IceCave.png"
        }, {
          "CN_Name": "HeartOfFrontier",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashhof",
          "Button_Image_Name": "HeartoftheFrontier.png"
        }, {
          "CN_Name": "热紫",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "phot",
          "Button_Image_Name": "PurpleHotKiosk.png"
        }, {
          "CN_Name": "Amazon Wild",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashamw",
          "Button_Image_Name": "amazon_wild.jpg"
        }, {
          "CN_Name": "亚洲幻想",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "asfa",
          "Button_Image_Name": "Asian_Fantasy.jpg"
        }, {
          "CN_Name": "武则天",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "heavru",
          "Button_Image_Name": "heavenly_ruler.png"
        }, {
          "CN_Name": "超级狮子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "slion",
          "Button_Image_Name": "super_lion.png"
        }, {
          "CN_Name": "孙悟空",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsswk",
          "Button_Image_Name": "new_10.png"
        }, {
          "CN_Name": "玉皇大帝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsje",
          "Button_Image_Name": "Jade_Emperor_138x125.png"
        }, {
          "CN_Name": "神的时代：雷霆4神",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "furf",
          "Button_Image_Name": "Furious_4_source_file.png"
        }, {
          "CN_Name": "印加帝国头奖",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aztec",
          "Button_Image_Name": "Inca_JackPot_138x125.png"
        }, {
          "CN_Name": "火热KTV",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hotktv",
          "Button_Image_Name": "Hot_KTV.png"
        }, {
          "CN_Name": "超级888",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "chao",
          "Button_Image_Name": "Chaoji_888_138x125.png"
        }, {
          "CN_Name": "角斗士彩池游戏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "glrj",
          "Button_Image_Name": "tiger_41.jpg"
        }, {
          "CN_Name": "日日进财",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ririjc",
          "Button_Image_Name": "RiRiJinCai_138x125.png"
        }, {
          "CN_Name": "疯狂乐透",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "lm",
          "Button_Image_Name": "tiger_37.jpg"
        }, {
          "CN_Name": "黄金之旅",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gos",
          "Button_Image_Name": "golden_tour.jpg"
        }, {
          "CN_Name": "吉祥8",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsjxb",
          "Button_Image_Name": "JiXiang.png"
        }, {
          "CN_Name": "水牛闪电",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bfb",
          "Button_Image_Name": "buffalo_blitz_138x125.png"
        }, {
          "CN_Name": "疯狂麻将",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fkmj",
          "Button_Image_Name": "Feng_Kuang_Ma_Jiang_138x125.png"
        }, {
          "CN_Name": "Halloween Fortune II",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hlf2",
          "Button_Image_Name": "Halloween_Fortune_II_138x125.png"
        }, {
          "CN_Name": "幸运爱尔兰",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "irl",
          "Button_Image_Name": "irish_luck.jpg"
        }, {
          "CN_Name": "神灵时代：命运姐妹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ftsis",
          "Button_Image_Name": "Age_of_the_Gods_Fate_Sisters_138x125.png"
        },  {
          "CN_Name": "小猪与狼",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "paw",
          "Button_Image_Name": "piggle_wolf.jpg"
        },  {
          "CN_Name": "水果狂",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fmn",
          "Button_Image_Name": "fruit_mania.jpg"
        }, {
          "CN_Name": "舞龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "wlg",
          "Button_Image_Name": "dragon_dance.jpg"
        }, {
          "CN_Name": "神的时代：智慧女神",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "athn",
          "Button_Image_Name": "Age_of_the_Gods_Goddess_of_Wisdom_Athena_138x125.png"
        }, {
          "CN_Name": "五路财神",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "wlcsh",
          "Button_Image_Name": "WuLuCaiShen_SourceFile.png"
        }, {
          "CN_Name": "五虎将",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ftg",
          "Button_Image_Name": "5TigerGenerals_SourceFile.png"
        }, {
          "CN_Name": "六福兽",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "kfp",
          "Button_Image_Name": "Liu_Fu_Shou_PSD_Source.png"
        }, {
          "CN_Name": "众神时代",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogs",
          "Button_Image_Name": "Age_of_the_Gods_King_of_Olympus_138x125.png"
        }, {
          "CN_Name": "黑豹之月",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pmn",
          "Button_Image_Name": "panther_moon.png"
        }, {
          "CN_Name": "辛巴达的金色航程",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashsbd",
          "Button_Image_Name": "sinbad_golden_voyage.png"
        }, {
          "CN_Name": "宝物箱中寻",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashcpl",
          "Button_Image_Name": "chests_of_plenty.png"
        }, {
          "CN_Name": "拉斯维加斯的猫",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ctiv",
          "Button_Image_Name": "cat_in_vegas.png"
        }, {
          "CN_Name": "大明帝国",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsgme",
          "Button_Image_Name": "great_ming_empire.png"
        }, {
          "CN_Name": "海滨嘉年华",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bl",
          "Button_Image_Name": "Beach-Life.jpg"
        }, {
          "CN_Name": "金色召集",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "grel",
          "Button_Image_Name": "Gold-Rally.jpg"
        }, {
          "CN_Name": "Fairest of them All",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashfta",
          "Button_Image_Name": "fairest_of_them_all.png"
        }, {
          "CN_Name": "年年有余",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "nian",
          "Button_Image_Name": "nian_nian_you_yu.png"
        }, {
          "CN_Name": "堂吉诃德的财富",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "donq",
          "Button_Image_Name": "riches_of_don_quixote.png"
        }, {
          "CN_Name": "招财进宝彩池游戏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "zcjbjp",
          "Button_Image_Name": "zhao_cai_jin_bao.png"
        }, {
          "CN_Name": "非洲炙热",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sfh",
          "Button_Image_Name": "safari_heat.png"
        }, {
          "CN_Name": "翡翠公主",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fcgz",
          "Button_Image_Name": "fei_cui_gong_zhu.png"
        }, {
          "CN_Name": "鬼屋",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hh",
          "Button_Image_Name": "Haunted House_191X181.png"
        }, {
          "CN_Name": "沉默的武士",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sis",
          "Button_Image_Name": "Silent_Samurai_Jackpot_138x125.png"
        }, {
          "CN_Name": "泰国天堂",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "tpd2",
          "Button_Image_Name": "Thai Paradise_191x181.png"
        }, {
          "CN_Name": "熊之舞",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bob",
          "Button_Image_Name": "bonus_bears.png"
        }, {
          "CN_Name": "艺伎故事",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ges",
          "Button_Image_Name": "geisha_story.png"
        }, {
          "CN_Name": "热力宝石",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gts50",
          "Button_Image_Name": "HotGems_191X181.png"
        }, {
          "CN_Name": "飞龙在天",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsflzt",
          "Button_Image_Name": "Fei Long Zai Tian_191x181.png"
        }, {
          "CN_Name": "一夜奇遇",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hb",
          "Button_Image_Name": "A_Night_Out_138x125.png"
        }, {
          "CN_Name": "万圣节财富",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hlf",
          "Button_Image_Name": "Halloween Fortune_138x125.png"
        }, {
          "CN_Name": "中国厨房",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "cm",
          "Button_Image_Name": "hot_6.jpg"
        }, {
          "CN_Name": "趣味水果",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fnfrj",
          "Button_Image_Name": "new_5.jpg"
        }, {
          "CN_Name": "四象",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sx",
          "Button_Image_Name": "new_13.png"
        }, {
          "CN_Name": "魔法堆叠",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mgstk",
          "Button_Image_Name": "new_11.png"
        }, {
          "CN_Name": "白狮王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "whk",
          "Button_Image_Name": "hot_15.jpg"
        }, {
          "CN_Name": "白狮",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bs",
          "Button_Image_Name": "new_7.png"
        }, {
          "CN_Name": "赌徒",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtswg",
          "Button_Image_Name": "tiger_24.jpg"
        }, {
          "CN_Name": "百慕大三角",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bt",
          "Button_Image_Name": "tiger_35.jpg"
        }, {
          "CN_Name": "诙谐财富",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fxf",
          "Button_Image_Name": "hot_16.jpg"
        }, {
          "CN_Name": "法老王的秘密",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pst",
          "Button_Image_Name": "tiger_23.jpg"
        }, {
          "CN_Name": "返利先生",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mcb",
          "Button_Image_Name": "cash_back.jpg"
        }, {
          "CN_Name": "亚马逊的秘密",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "samz",
          "Button_Image_Name": "tiger_28.jpg"
        }, {
          "CN_Name": "招财进宝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "zcjb",
          "Button_Image_Name": "new_6.png"
        }, {
          "CN_Name": "弓兵",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "arc",
          "Button_Image_Name": "tiger_40.jpg"
        },

        {
          "CN_Name": "非常幸运",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtspor",
          "Button_Image_Name": "new_16.jpg"
        }, {
          "CN_Name": "夏洛克的秘密",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "shmst",
          "Button_Image_Name": "tiger_63.jpg"
        }, {
          "CN_Name": "海王星王国",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "nk",
          "Button_Image_Name": "neptunes_kingdom.jpg"
        },  {
          "CN_Name": "终极足球",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fbr",
          "Button_Image_Name": "football_rules.jpg"
        }, {
          "CN_Name": "真爱",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "trl",
          "Button_Image_Name": "tiger_94.jpg"
        }, {
          "CN_Name": "丛林巫师",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ub",
          "Button_Image_Name": "Ugga-Bugga.jpg"
        }, {
          "CN_Name": "奖金巨人",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "jpgt",
          "Button_Image_Name": "jackpot_giant.jpg"
        }, {
          "CN_Name": "好运连胜",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sol",
          "Button_Image_Name": "streak_of_luck.jpg"
        }, {
          "CN_Name": "企鹅假期",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pgv",
          "Button_Image_Name": "penguin_vacation.png"
        }, {
          "CN_Name": "古怪猴子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fm",
          "Button_Image_Name": "hot_3.jpg"
        }, {
          "CN_Name": "湛蓝深海",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bib",
          "Button_Image_Name": "great_blue.jpg"
        }, {
          "CN_Name": "高速公路之王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hk",
          "Button_Image_Name": "hot_4.jpg"
        }, {
          "CN_Name": "船长的宝藏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ct",
          "Button_Image_Name": "hot_1.jpg"
        }, {
          "CN_Name": "疯狂之七",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "c7",
          "Button_Image_Name": "hot_10.jpg"
        }, {
          "CN_Name": "圣诞奇迹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ssp",
          "Button_Image_Name": "santa_surprise.jpg"
        }, {
          "CN_Name": "惊喜复活节",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "eas",
          "Button_Image_Name": "easter_surprise.jpg"
        }, {
          "CN_Name": "甜蜜派对",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "cnpr",
          "Button_Image_Name": "hot_20.jpg"
        }, {
          "CN_Name": "海豚礁",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "dnr",
          "Button_Image_Name": "dolphin_reef.png"
        }, {
          "CN_Name": "宝石女王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gemq",
          "Button_Image_Name": "Gem_Queen_138x125.png"
        }, {
          "CN_Name": "龙龙龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "longlong",
          "Button_Image_Name": "long_long_long.png"
        }, {
          "CN_Name": "神的时代：奥林匹斯",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hrcls",
          "Button_Image_Name": "Age_of_the_Gods_Prince_of_Olympus_138x125.png"
        }, {
          "CN_Name": "银子弹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sib",
          "Button_Image_Name": "silver_bullet.jpg"
        }, {
          "CN_Name": "仙境冒险豪华版",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashadv",
          "Button_Image_Name": "Adventures_in_Wonderland_Deluxe.png"
        }, {
          "CN_Name": "日日生财",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ririshc",
          "Button_Image_Name": "Ri_Ri_Sheng_Cai_138x125.png"
        }
      ]
    },

    {
      "type": 4,
      "game_id": 402,
      "game_api": "PG",
      "name": "PG老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/pg.png",
      "icon": "logo-pg",
      "game_list_json": "PG_game_list",
      "game_list_img_path": "/new/slot_res/pg/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：爱尔兰精灵、麻将胡了、麻將胡了2、寻宝黄金城、招财喵...",
      "labels": "#老虎机#爱尔兰精灵#麻将胡了#冰雪大冲关#赏金船长",
      gameList:[
        {
          "EN_Name": "by",
          "CN_Name": "美杜莎 II",
          "HTML5_Game_ID": "6",
          "Button_Image_Name": "6.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宝石侠-大宝剑",
          "HTML5_Game_ID": "38",
          "Button_Image_Name": "38.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "维京纪元",
          "HTML5_Game_ID": "1340277",
          "Button_Image_Name": "1340277.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "点石成金",
          "HTML5_Game_ID": "1402846",
          "Button_Image_Name": "1402846.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "金钱兔",
          "HTML5_Game_ID": "1543462",
          "Button_Image_Name": "1543462.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "电音派对",
          "HTML5_Game_ID": "1420892",
          "Button_Image_Name": "1420892.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "夏威夷探奇",
          "HTML5_Game_ID": "1381200",
          "Button_Image_Name": "1381200.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "烘焙总动员",
          "HTML5_Game_ID": "1418544",
          "Button_Image_Name": "1418544.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "泰嗨泼水节",
          "HTML5_Game_ID": "1448762",
          "Button_Image_Name": "1448762.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "元素精灵",
          "HTML5_Game_ID": "1432733",
          "Button_Image_Name": "1432733.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "爱尔兰精灵",
          "HTML5_Game_ID": "60",
          "Button_Image_Name": "60.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "麻将胡了",
          "HTML5_Game_ID": "65",
          "Button_Image_Name": "65.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "麻将胡了2",
          "HTML5_Game_ID": "74",
          "Button_Image_Name": "74.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "寻宝黄金城",
          "HTML5_Game_ID": "87",
          "Button_Image_Name": "87.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "招财喵",
          "HTML5_Game_ID": "89",
          "Button_Image_Name": "89.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "双囍临门",
          "HTML5_Game_ID": "48",
          "Button_Image_Name": "48.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "冰雪大冲关",
          "HTML5_Game_ID": "53",
          "Button_Image_Name": "53.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "赏金船长",
          "HTML5_Game_ID": "54",
          "Button_Image_Name": "54.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "赢财神",
          "HTML5_Game_ID": "71",
          "Button_Image_Name": "71.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "福运象财神",
          "HTML5_Game_ID": "75",
          "Button_Image_Name": "75.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "澳门壕梦",
          "HTML5_Game_ID": "79",
          "Button_Image_Name": "79.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "赏金女王",
          "HTML5_Game_ID": "84",
          "Button_Image_Name": "84.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "十倍金牛",
          "HTML5_Game_ID": "98",
          "Button_Image_Name": "98.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "亡灵大盗",
          "HTML5_Game_ID": "104",
          "Button_Image_Name": "104.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "麒麟送宝",
          "HTML5_Game_ID": "106",
          "Button_Image_Name": "106.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "寻龙探宝",
          "HTML5_Game_ID": "57",
          "Button_Image_Name": "57.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及探秘宝典",
          "HTML5_Game_ID": "73",
          "Button_Image_Name": "73.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "凤凰传奇",
          "HTML5_Game_ID": "82",
          "Button_Image_Name": "82.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "火树赢花",
          "HTML5_Game_ID": "83",
          "Button_Image_Name": "83.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "水上泰神奇",
          "HTML5_Game_ID": "92",
          "Button_Image_Name": "92.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巴厘之旅",
          "HTML5_Game_ID": "94",
          "Button_Image_Name": "94.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "比特淘金",
          "HTML5_Game_ID": "103",
          "Button_Image_Name": "103.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "夜戏貂蝉",
          "HTML5_Game_ID": "1",
          "Button_Image_Name": "1.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "横财来啦",
          "HTML5_Game_ID": "3",
          "Button_Image_Name": "3.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "旺旺旺",
          "HTML5_Game_ID": "24",
          "Button_Image_Name": "24.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "美杜莎 II II",
          "HTML5_Game_ID": "6",
          "Button_Image_Name": "6.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "摇钱树",
          "HTML5_Game_ID": "26",
          "Button_Image_Name": "26.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "美杜莎",
          "HTML5_Game_ID": "7",
          "Button_Image_Name": "7.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "抓抓乐",
          "HTML5_Game_ID": "25",
          "Button_Image_Name": "25.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巫师之书",
          "HTML5_Game_ID": "17",
          "Button_Image_Name": "17.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宝石侠",
          "HTML5_Game_ID": "2",
          "Button_Image_Name": "2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "逆袭的小红帽",
          "HTML5_Game_ID": "18",
          "Button_Image_Name": "18.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "麻辣火锅",
          "HTML5_Game_ID": "28",
          "Button_Image_Name": "28.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "鱼跃龙门",
          "HTML5_Game_ID": "29",
          "Button_Image_Name": "29.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "万胜狂欢夜",
          "HTML5_Game_ID": "35",
          "Button_Image_Name": "35.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "后羿射日",
          "HTML5_Game_ID": "34",
          "Button_Image_Name": "34.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "舞狮进宝",
          "HTML5_Game_ID": "36",
          "Button_Image_Name": "36.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "嘻哈熊猫",
          "HTML5_Game_ID": "33",
          "Button_Image_Name": "33.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣诞欢乐送",
          "HTML5_Game_ID": "37",
          "Button_Image_Name": "37.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "至尊百家乐",
          "HTML5_Game_ID": "31",
          "Button_Image_Name": "31.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宝石侠-大宝剑-大寶劍",
          "HTML5_Game_ID": "38",
          "Button_Image_Name": "38.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "金猪报财",
          "HTML5_Game_ID": "39",
          "Button_Image_Name": "39.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及寻宝",
          "HTML5_Game_ID": "41",
          "Button_Image_Name": "41.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "皇上吉祥",
          "HTML5_Game_ID": "44",
          "Button_Image_Name": "44.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "象财神",
          "HTML5_Game_ID": "42",
          "Button_Image_Name": "42.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "三只猴子",
          "HTML5_Game_ID": "43",
          "Button_Image_Name": "43.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "水果丛林",
          "HTML5_Game_ID": "40",
          "Button_Image_Name": "40.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "嘻游记",
          "HTML5_Game_ID": "50",
          "Button_Image_Name": "50.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "唐伯虎点秋香",
          "HTML5_Game_ID": "61",
          "Button_Image_Name": "61.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "忍者vs武侍",
          "HTML5_Game_ID": "59",
          "Button_Image_Name": "59.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "拳霸",
          "HTML5_Game_ID": "64",
          "Button_Image_Name": "64.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "龙虎争霸",
          "HTML5_Game_ID": "63",
          "Button_Image_Name": "63.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "鼠鼠福福",
          "HTML5_Game_ID": "68",
          "Button_Image_Name": "68.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "亲爱的",
          "HTML5_Game_ID": "20",
          "Button_Image_Name": "20.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宝石侠 - 宝藏征途",
          "HTML5_Game_ID": "62",
          "Button_Image_Name": "62.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "少林足球",
          "HTML5_Game_ID": "67",
          "Button_Image_Name": "67.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "糖果连连爆",
          "HTML5_Game_ID": "70",
          "Button_Image_Name": "70.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "比基尼天堂",
          "HTML5_Game_ID": "69",
          "Button_Image_Name": "69.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿拉丁神灯",
          "HTML5_Game_ID": "85",
          "Button_Image_Name": "85.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "欢乐嘉年华",
          "HTML5_Game_ID": "80",
          "Button_Image_Name": "80.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "艳后之谜",
          "HTML5_Game_ID": "90",
          "Button_Image_Name": "90.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "德古拉女爵",
          "HTML5_Game_ID": "58",
          "Button_Image_Name": "58.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "金玉满堂",
          "HTML5_Game_ID": "88",
          "Button_Image_Name": "88.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "冰锋奇侠",
          "HTML5_Game_ID": "97",
          "Button_Image_Name": "97.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "星旅淘金",
          "HTML5_Game_ID": "86",
          "Button_Image_Name": "86.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "冰火双娇",
          "HTML5_Game_ID": "91",
          "Button_Image_Name": "91.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "新国粹",
          "HTML5_Game_ID": "93",
          "Button_Image_Name": "93.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宝石传奇",
          "HTML5_Game_ID": "95",
          "Button_Image_Name": "95.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "糖心风暴",
          "HTML5_Game_ID": "100",
          "Button_Image_Name": "100.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "霹雳神偷",
          "HTML5_Game_ID": "105",
          "Button_Image_Name": "105.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "太阳神传说",
          "HTML5_Game_ID": "101",
          "Button_Image_Name": "101.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "寿司之道",
          "HTML5_Game_ID": "109",
          "Button_Image_Name": "109.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "恐龙帝国",
          "HTML5_Game_ID": "110",
          "Button_Image_Name": "110.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "人鱼公主",
          "HTML5_Game_ID": "102",
          "Button_Image_Name": "102.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "地鼠总动员",
          "HTML5_Game_ID": "111",
          "Button_Image_Name": "111.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "丽影奇兵之探秘埃及",
          "HTML5_Game_ID": "113",
          "Button_Image_Name": "113.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超市大血拼",
          "HTML5_Game_ID": "115",
          "Button_Image_Name": "115.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "美洲野牛",
          "HTML5_Game_ID": "108",
          "Button_Image_Name": "108.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "美猴王传奇",
          "HTML5_Game_ID": "107",
          "Button_Image_Name": "107.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "百鬼夜行",
          "HTML5_Game_ID": "119",
          "Button_Image_Name": "119.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "星际农场",
          "HTML5_Game_ID": "116",
          "Button_Image_Name": "116.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "钞级表情包",
          "HTML5_Game_ID": "114",
          "Button_Image_Name": "114.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "夜醉佳人",
          "HTML5_Game_ID": "117",
          "Button_Image_Name": "117.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "假面嘉年华",
          "HTML5_Game_ID": "118",
          "Button_Image_Name": "118.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "江山美景图",
          "HTML5_Game_ID": "112",
          "Button_Image_Name": "112.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "虎虎生财",
          "HTML5_Game_ID": "126",
          "Button_Image_Name": "126.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "神鹰宝石",
          "HTML5_Game_ID": "122",
          "Button_Image_Name": "122.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "日月星辰",
          "HTML5_Game_ID": "121",
          "Button_Image_Name": "121.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "蝶恋花",
          "HTML5_Game_ID": "125",
          "Button_Image_Name": "125.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "斗鸡",
          "HTML5_Game_ID": "123",
          "Button_Image_Name": "123.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "韩宫御宴",
          "HTML5_Game_ID": "120",
          "Button_Image_Name": "120.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "绝地大逃杀",
          "HTML5_Game_ID": "124",
          "Button_Image_Name": "124.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "发财鱼虾蟹",
          "HTML5_Game_ID": "129",
          "Button_Image_Name": "129.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "电玩金猪",
          "HTML5_Game_ID": "130",
          "Button_Image_Name": "130.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "极速赢家",
          "HTML5_Game_ID": "127",
          "Button_Image_Name": "127.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "珀尔修斯传奇",
          "HTML5_Game_ID": "128",
          "Button_Image_Name": "128.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯赚过山车",
          "HTML5_Game_ID": "132",
          "Button_Image_Name": "132.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "赏金大对决",
          "HTML5_Game_ID": "135",
          "Button_Image_Name": "135.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金摇钱树",
          "HTML5_Game_ID": "1312883",
          "Button_Image_Name": "1312883.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "图腾奇迹",
          "HTML5_Game_ID": "1338274",
          "Button_Image_Name": "1338274.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "炼金工坊",
          "HTML5_Game_ID": "1368367",
          "Button_Image_Name": "1368367.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "美食美刻",
          "HTML5_Game_ID": "1372643",
          "Button_Image_Name": "1372643.png"
        }
      ]
    },



    {
      "type": 4,
      "game_id": 415,
      "game_api": "PP",
      "name": "PP老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/pp.png",
      "icon": "logo-pp",
      "game_list_json": "PP_game_list",
      "game_list_img_path": "/new/slot_res/pp/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：奥林匹斯之门、甜入心扉、魔幻钱迷宫、极速糖果、星光公主...",
      "labels": "#老虎机#奥林匹斯之门#甜入心扉#极速糖果#星光公主",
      gameList: [

        {
          "EN_Name": "by",
          "CN_Name": "鱼跃龙门 - 天空之船™",
          "HTML5_Game_ID": "vs10fdrasbf",
          "Button_Image_Name": "vs10fdrasbf.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "粘性蜜蜂™",
          "HTML5_Game_ID": "vs20clustwild",
          "Button_Image_Name": "vs20clustwild.png"
        },

        {
          "EN_Name": "by",
          "CN_Name": "奥林匹斯之门",
          "HTML5_Game_ID": "vs20olympgate",
          "Button_Image_Name": "vs20olympgate.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "奥林匹斯之门",
          "HTML5_Game_ID": "vs20olympgate",
          "Button_Image_Name": "vs20olympgate.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "甜入心扉",
          "HTML5_Game_ID": "vs20fruitsw",
          "Button_Image_Name": "vs20fruitsw.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "5金狮 Megaways",
          "HTML5_Game_ID": "vswayslions",
          "Button_Image_Name": "vswayslions.png"
        },
        // {
        //   "EN_Name": "by",
        //   "CN_Name": "极速糖果",
        //   "HTML5_Game_ID": "vs20sugarrush",
        //   "Button_Image_Name": "vs20sugarrush.png"
        // },
        {
          "EN_Name": "by",
          "CN_Name": "星光公主",
          "HTML5_Game_ID": "vs20starlight",
          "Button_Image_Name": "vs20starlight.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巨大犀牛 Megaways",
          "HTML5_Game_ID": "vswaysrhino",
          "Button_Image_Name": "vswaysrhino.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "5金狮",
          "HTML5_Game_ID": "vs243lionsgold",
          "Button_Image_Name": "vs243lionsgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "王者野牛 Megaways",
          "HTML5_Game_ID": "vswaysbufking",
          "Button_Image_Name": "vswaysbufking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "5雄狮",
          "HTML5_Game_ID": "vs243lions",
          "Button_Image_Name": "vs243lions.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "财神运财",
          "HTML5_Game_ID": "vs243caishien",
          "Button_Image_Name": "vs243caishien.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金富矿",
          "HTML5_Game_ID": "vs20bonzgold",
          "Button_Image_Name": "vs20bonzgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "崛起的武士 Megaways",
          "HTML5_Game_ID": "vswayssamurai",
          "Button_Image_Name": "vswayssamurai.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "甜心盛宴圣诞",
          "HTML5_Game_ID": "vs20sbxmas",
          "Button_Image_Name": "vs20sbxmas.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "汪汪之家Megaways",
          "HTML5_Game_ID": "vswaysdogs",
          "Button_Image_Name": "vswaysdogs.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "水果派对",
          "HTML5_Game_ID": "vs20fruitparty",
          "Button_Image_Name": "vs20fruitparty.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "群龙保珠-宝石致富",
          "HTML5_Game_ID": "vs1600drago",
          "Button_Image_Name": "vs1600drago.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "红火暴击",
          "HTML5_Game_ID": "vs10firestrike",
          "Button_Image_Name": "vs10firestrike.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巨大犀牛",
          "HTML5_Game_ID": "vs20rhino",
          "Button_Image_Name": "vs20rhino.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "王者野牛",
          "HTML5_Game_ID": "vs4096bufking",
          "Button_Image_Name": "vs4096bufking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "水果派对 2",
          "HTML5_Game_ID": "vs20fparty2",
          "Button_Image_Name": "vs20fparty2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣诞大鲈鱼",
          "HTML5_Game_ID": "vs10bxmasbnza",
          "Button_Image_Name": "vs10bxmasbnza.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "7只小猪",
          "HTML5_Game_ID": "vs7pigs",
          "Button_Image_Name": "vs7pigs.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣诞颂歌 Megaways",
          "HTML5_Game_ID": "vs20xmascarol",
          "Button_Image_Name": "vs20xmascarol.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "金龟子女王",
          "HTML5_Game_ID": "vs25scarabqueen",
          "Button_Image_Name": "vs25scarabqueen.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "西部牛仔黄金地段",
          "HTML5_Game_ID": "vs40wildwest",
          "Button_Image_Name": "vs40wildwest.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "英雄联盟 Megaways",
          "HTML5_Game_ID": "vswayslofhero",
          "Button_Image_Name": "vswayslofhero.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "暴怒猩猩",
          "HTML5_Game_ID": "vs1024gmayhem",
          "Button_Image_Name": "vs1024gmayhem.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "鱼跃龙门 Megaways",
          "HTML5_Game_ID": "vswaysfltdrg",
          "Button_Image_Name": "vswaysfltdrg.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "雷神之锤 Megaways",
          "HTML5_Game_ID": "vswayshammthor",
          "Button_Image_Name": "vswayshammthor.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "墨西哥亡灵节 Megaways",
          "HTML5_Game_ID": "vs20muertos",
          "Button_Image_Name": "vs20muertos.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿兹特克获利之道",
          "HTML5_Game_ID": "vs7776aztec",
          "Button_Image_Name": "vs7776aztec.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "百搭超人星际战士",
          "HTML5_Game_ID": "vs20wildman",
          "Button_Image_Name": "vs20wildman.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "农场嘉年华",
          "HTML5_Game_ID": "vs20farmfest",
          "Button_Image_Name": "vs20farmfest.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "命运女巫Megaways",
          "HTML5_Game_ID": "vswaysmadame",
          "Button_Image_Name": "vswaysmadame.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣诞仙境",
          "HTML5_Game_ID": "vs20santawonder",
          "Button_Image_Name": "vs20santawonder.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "拉摩卡 百搭",
          "HTML5_Game_ID": "vs10coffee",
          "Button_Image_Name": "vs10coffee.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及神猫",
          "HTML5_Game_ID": "vs20cleocatra",
          "Button_Image_Name": "vs20cleocatra.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "额外多汁",
          "HTML5_Game_ID": "vs10fruity2",
          "Button_Image_Name": "vs10fruity2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "鱼跃龙门 持控旋转",
          "HTML5_Game_ID": "vs10floatdrg",
          "Button_Image_Name": "vs10floatdrg.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "汪汪之家",
          "HTML5_Game_ID": "vs20doghouse",
          "Button_Image_Name": "vs20doghouse.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "活死人归来",
          "HTML5_Game_ID": "vs10returndead",
          "Button_Image_Name": "vs10returndead.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "幸运雷神",
          "HTML5_Game_ID": "vswayslight",
          "Button_Image_Name": "vswayslight.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "大鲈鱼溅水",
          "HTML5_Game_ID": "vs10txbigbass",
          "Button_Image_Name": "vs10txbigbass.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "神秘的巫师",
          "HTML5_Game_ID": "vs4096magician",
          "Button_Image_Name": "vs4096magician.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "糖果部落",
          "HTML5_Game_ID": "vs20candvil",
          "Button_Image_Name": "vs20candvil.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "小丑珠宝",
          "HTML5_Game_ID": "vs5joker",
          "Button_Image_Name": "vs5joker.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超疯狂大鲈鱼",
          "HTML5_Game_ID": "vs12bbb",
          "Button_Image_Name": "vs12bbb.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "水晶洞穴Megaways",
          "HTML5_Game_ID": "vswayscryscav",
          "Button_Image_Name": "vswayscryscav.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯狂金字塔",
          "HTML5_Game_ID": "vs20pbonanza",
          "Button_Image_Name": "vs20pbonanza.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "财神黄金",
          "HTML5_Game_ID": "vs243fortune",
          "Button_Image_Name": "vs243fortune.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宇宙现金",
          "HTML5_Game_ID": "vs40cosmiccash",
          "Button_Image_Name": "vs40cosmiccash.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "西部牛仔黄金地段 Megaways",
          "HTML5_Game_ID": "vswayswildwest",
          "Button_Image_Name": "vswayswildwest.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级辣椒 Megaways",
          "HTML5_Game_ID": "vswayschilheat",
          "Button_Image_Name": "vswayschilheat.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "财源滚滚",
          "HTML5_Game_ID": "cs5moneyroll",
          "Button_Image_Name": "cs5moneyroll.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "伦敦地铁",
          "HTML5_Game_ID": "vs20underground",
          "Button_Image_Name": "vs20underground.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热带提基",
          "HTML5_Game_ID": "vswaysjkrdrop",
          "Button_Image_Name": "vswaysjkrdrop.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "虎虎生财",
          "HTML5_Game_ID": "vs25tigeryear",
          "Button_Image_Name": "vs25tigeryear.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金老矿工Megaways",
          "HTML5_Game_ID": "vswaysoldminer",
          "Button_Image_Name": "vswaysoldminer.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "招财鼠",
          "HTML5_Game_ID": "vs25mmouse",
          "Button_Image_Name": "vs25mmouse.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "现金升降机",
          "HTML5_Game_ID": "vs20terrorv",
          "Button_Image_Name": "vs20terrorv.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黑公牛",
          "HTML5_Game_ID": "vs20trswild2",
          "Button_Image_Name": "vs20trswild2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "熊猫麻将",
          "HTML5_Game_ID": "vs1024mahjpanda",
          "Button_Image_Name": "vs1024mahjpanda.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "富矿宝石",
          "HTML5_Game_ID": "vs20goldfever",
          "Button_Image_Name": "vs20goldfever.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "迈达斯之手",
          "HTML5_Game_ID": "vs20midas",
          "Button_Image_Name": "vs20midas.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "牛转钱坤",
          "HTML5_Game_ID": "vs243chargebull",
          "Button_Image_Name": "vs243chargebull.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯狂大鲈鱼",
          "HTML5_Game_ID": "vs10bbbonanza",
          "Button_Image_Name": "vs10bbbonanza.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "野狼与小猪",
          "HTML5_Game_ID": "vs20wolfie",
          "Button_Image_Name": "vs20wolfie.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "狂热嘉年华",
          "HTML5_Game_ID": "vs25hotfiesta",
          "Button_Image_Name": "vs25hotfiesta.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金派对",
          "HTML5_Game_ID": "vs25goldparty",
          "Button_Image_Name": "vs25goldparty.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "希腊战神之剑",
          "HTML5_Game_ID": "vs20swordofares",
          "Button_Image_Name": "vs20swordofares.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "丧尸嘉年华",
          "HTML5_Game_ID": "vswayszombcarn",
          "Button_Image_Name": "vswayszombcarn.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巨大犀牛 加强版",
          "HTML5_Game_ID": "vs20rhinoluxe",
          "Button_Image_Name": "vs20rhinoluxe.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "狼人的诅咒Megaways",
          "HTML5_Game_ID": "vswayswerewolf",
          "Button_Image_Name": "vswayswerewolf.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "野狼黄金",
          "HTML5_Game_ID": "vs25wolfgold",
          "Button_Image_Name": "vs25wolfgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "寻宝冒险精神",
          "HTML5_Game_ID": "vs10spiritadv",
          "Button_Image_Name": "vs10spiritadv.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "西游记",
          "HTML5_Game_ID": "vs25journey",
          "Button_Image_Name": "vs25journey.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "发发发龙",
          "HTML5_Game_ID": "vs1dragon8",
          "Button_Image_Name": "vs1dragon8.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级持控旋转",
          "HTML5_Game_ID": "vs5ultra",
          "Button_Image_Name": "vs5ultra.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "魔幻钱迷宫",
          "HTML5_Game_ID": "vs10mmm",
          "Button_Image_Name": "vs10mmm.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "88火",
          "HTML5_Game_ID": "vs7fire88",
          "Button_Image_Name": "vs7fire88.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "十月啤酒嘉年华",
          "HTML5_Game_ID": "vs20octobeer",
          "Button_Image_Name": "vs20octobeer.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "约翰·亨特之埃及图特书",
          "HTML5_Game_ID": "vs10bookoftut",
          "Button_Image_Name": "vs10bookoftut.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "霹雳凤凰",
          "HTML5_Game_ID": "vs20phoenixf",
          "Button_Image_Name": "vs20phoenixf.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯狂海滩派对",
          "HTML5_Game_ID": "vs20bchprty",
          "Button_Image_Name": "vs20bchprty.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "三国",
          "HTML5_Game_ID": "vs25kingdoms",
          "Button_Image_Name": "vs25kingdoms.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿兹特克国王Megaways",
          "HTML5_Game_ID": "vswaysaztecking",
          "Button_Image_Name": "vswaysaztecking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及金沙之书",
          "HTML5_Game_ID": "vswaysbook",
          "Button_Image_Name": "vswaysbook.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "解放大海怪2",
          "HTML5_Game_ID": "vs20kraken2",
          "Button_Image_Name": "vs20kraken2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "多汁水果",
          "HTML5_Game_ID": "vs50juicyfr",
          "Button_Image_Name": "vs50juicyfr.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "夺金海贼",
          "HTML5_Game_ID": "vs40pirate",
          "Button_Image_Name": "vs40pirate.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "泡泡糖",
          "HTML5_Game_ID": "vs10bblpop",
          "Button_Image_Name": "vs10bblpop.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火燃烧持控旋转",
          "HTML5_Game_ID": "vs20hburnhs",
          "Button_Image_Name": "vs20hburnhs.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "禽鸟之屋",
          "HTML5_Game_ID": "vs20tweethouse",
          "Button_Image_Name": "vs20tweethouse.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "熊猫财富",
          "HTML5_Game_ID": "vs25pandagold",
          "Button_Image_Name": "vs25pandagold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "魔法大釜绝佳药剂",
          "HTML5_Game_ID": "vs20magicpot",
          "Button_Image_Name": "vs20magicpot.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "额外多汁Megaways",
          "HTML5_Game_ID": "vswaysxjuicy",
          "Button_Image_Name": "vswaysxjuicy.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金彩虹",
          "HTML5_Game_ID": "vs20rainbowg",
          "Button_Image_Name": "vs20rainbowg.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "布洛克精神",
          "HTML5_Game_ID": "vs75bronco",
          "Button_Image_Name": "vs75bronco.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿斯加德之王",
          "HTML5_Game_ID": "vs20asgard",
          "Button_Image_Name": "vs20asgard.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "终极之5",
          "HTML5_Game_ID": "vs20ultim5",
          "Button_Image_Name": "vs20ultim5.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "舞龙 热控旋转",
          "HTML5_Game_ID": "vs5drhs",
          "Button_Image_Name": "vs5drhs.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "可口好吃 Powerways",
          "HTML5_Game_ID": "vswaysyumyum",
          "Button_Image_Name": "vswaysyumyum.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级大鲈鱼暴风雨 - 圣诞鱼获",
          "HTML5_Game_ID": "vs12bbbxmas",
          "Button_Image_Name": "vs12bbbxmas.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金三叶草",
          "HTML5_Game_ID": "vs20mustanggld2",
          "Button_Image_Name": "vs20mustanggld2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "童话财富",
          "HTML5_Game_ID": "vs15fairytale",
          "Button_Image_Name": "vs15fairytale.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "跳舞趴",
          "HTML5_Game_ID": "vs243dancingpar",
          "Button_Image_Name": "vs243dancingpar.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "7只猴子",
          "HTML5_Game_ID": "vs7monkeys",
          "Button_Image_Name": "vs7monkeys.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "酷猴战士",
          "HTML5_Game_ID": "vs243mwarrior",
          "Button_Image_Name": "vs243mwarrior.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "解放大海怪",
          "HTML5_Game_ID": "vs20kraken",
          "Button_Image_Name": "vs20kraken.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "猴子疯狂",
          "HTML5_Game_ID": "vs9madmonkey",
          "Button_Image_Name": "vs9madmonkey.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "旋转及射龙门Megaways",
          "HTML5_Game_ID": "vswaysfrywld",
          "Button_Image_Name": "vswaysfrywld.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "钱生钱",
          "HTML5_Game_ID": "vs1money",
          "Button_Image_Name": "vs1money.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "古时代宝石",
          "HTML5_Game_ID": "vs5aztecgems",
          "Button_Image_Name": "vs5aztecgems.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "盗空银行",
          "HTML5_Game_ID": "vs20emptybank",
          "Button_Image_Name": "vs20emptybank.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "钻取金子",
          "HTML5_Game_ID": "vs20drtgold",
          "Button_Image_Name": "vs20drtgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "三只老虎",
          "HTML5_Game_ID": "vs1tigers",
          "Button_Image_Name": "vs1tigers.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "百万$小猪",
          "HTML5_Game_ID": "vs9piggybank",
          "Button_Image_Name": "vs9piggybank.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金野马",
          "HTML5_Game_ID": "vs25mustang",
          "Button_Image_Name": "vs25mustang.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "五狮团拜",
          "HTML5_Game_ID": "vs1024lionsd",
          "Button_Image_Name": "vs1024lionsd.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "北境守护者",
          "HTML5_Game_ID": "vs50northgard",
          "Button_Image_Name": "vs50northgard.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "冰球竞赛",
          "HTML5_Game_ID": "vs88hockattack",
          "Button_Image_Name": "vs88hockattack.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巡逻现警",
          "HTML5_Game_ID": "vs25copsrobbers",
          "Button_Image_Name": "vs25copsrobbers.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火 5",
          "HTML5_Game_ID": "vs5firehot",
          "Button_Image_Name": "vs5firehot.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "招财福猪",
          "HTML5_Game_ID": "vs25goldpig",
          "Button_Image_Name": "vs25goldpig.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "火辣辣",
          "HTML5_Game_ID": "vs25chilli",
          "Button_Image_Name": "vs25chilli.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "糖果星星",
          "HTML5_Game_ID": "vswaysstrwild",
          "Button_Image_Name": "vswaysstrwild.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "马上有钱",
          "HTML5_Game_ID": "vs18mashang",
          "Button_Image_Name": "vs18mashang.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "8条龙",
          "HTML5_Game_ID": "vs20eightdragons",
          "Button_Image_Name": "vs20eightdragons.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "吉萨金字塔 PowerNudge",
          "HTML5_Game_ID": "vs10nudgeit",
          "Button_Image_Name": "vs10nudgeit.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯狂宝藏",
          "HTML5_Game_ID": "vs20trsbox",
          "Button_Image_Name": "vs20trsbox.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "好运北京",
          "HTML5_Game_ID": "vs25peking",
          "Button_Image_Name": "vs25peking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "赏金猎人",
          "HTML5_Game_ID": "vs25btygold",
          "Button_Image_Name": "vs25btygold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "古代埃及经典版",
          "HTML5_Game_ID": "vs10egyptcls",
          "Button_Image_Name": "vs10egyptcls.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "熊猫财富2",
          "HTML5_Game_ID": "vs25pandatemple",
          "Button_Image_Name": "vs25pandatemple.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "魔力维加斯",
          "HTML5_Game_ID": "vs20vegasmagic",
          "Button_Image_Name": "vs20vegasmagic.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "玉蝴蝶",
          "HTML5_Game_ID": "vs1024butterfly",
          "Button_Image_Name": "vs1024butterfly.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "刚果财富",
          "HTML5_Game_ID": "vs432congocash",
          "Button_Image_Name": "vs432congocash.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "约翰猎人与埃及之书重转",
          "HTML5_Game_ID": "vs10tut",
          "Button_Image_Name": "vs10tut.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "王冠之火",
          "HTML5_Game_ID": "vs10crownfire",
          "Button_Image_Name": "vs10crownfire.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "地精时代",
          "HTML5_Game_ID": "vs20gobnudge",
          "Button_Image_Name": "vs20gobnudge.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "贵妃美人",
          "HTML5_Game_ID": "vs75empress",
          "Button_Image_Name": "vs75empress.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "法力无边",
          "HTML5_Game_ID": "vs25wildspells",
          "Button_Image_Name": "vs25wildspells.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火 100",
          "HTML5_Game_ID": "vs100firehot",
          "Button_Image_Name": "vs100firehot.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "仙宫",
          "HTML5_Game_ID": "vs25asgard",
          "Button_Image_Name": "vs25asgard.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "财神驾到",
          "HTML5_Game_ID": "vs243empcaishen",
          "Button_Image_Name": "vs243empcaishen.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "风暴之眼",
          "HTML5_Game_ID": "vs10eyestorm",
          "Button_Image_Name": "vs10eyestorm.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "希腊众神",
          "HTML5_Game_ID": "vs243fortseren",
          "Button_Image_Name": "vs243fortseren.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "女王",
          "HTML5_Game_ID": "vs243queenie",
          "Button_Image_Name": "vs243queenie.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "爱尔兰人宝藏",
          "HTML5_Game_ID": "vs576treasures",
          "Button_Image_Name": "vs576treasures.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿茲特克火焰",
          "HTML5_Game_ID": "vs25kfruit",
          "Button_Image_Name": "vs25kfruit.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "古时代宝石 加强版",
          "HTML5_Game_ID": "vs9aztecgemsdx",
          "Button_Image_Name": "vs9aztecgemsdx.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "小妖之歌圣诞版",
          "HTML5_Game_ID": "vs20leprexmas",
          "Button_Image_Name": "vs20leprexmas.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "钻石罢工",
          "HTML5_Game_ID": "vs15diamond",
          "Button_Image_Name": "vs15diamond.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "约翰猎人与百慕大财富",
          "HTML5_Game_ID": "vs20bermuda",
          "Button_Image_Name": "vs20bermuda.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "甜蜜蜜",
          "HTML5_Game_ID": "vs20honey",
          "Button_Image_Name": "vs20honey.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "大鱼奇缘",
          "HTML5_Game_ID": "vs10goldfish",
          "Button_Image_Name": "vs10goldfish.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿茲特克国王",
          "HTML5_Game_ID": "vs25aztecking",
          "Button_Image_Name": "vs25aztecking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "招财锦鲤",
          "HTML5_Game_ID": "vs243koipond",
          "Button_Image_Name": "vs243koipond.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "幸运新年",
          "HTML5_Game_ID": "vs25newyear",
          "Button_Image_Name": "vs25newyear.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "蛇和梯子：蛇眼",
          "HTML5_Game_ID": "vs10snakeeyes",
          "Button_Image_Name": "vs10snakeeyes.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "小鸡大逃亡",
          "HTML5_Game_ID": "vs20chicken",
          "Button_Image_Name": "vs20chicken.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "金龙吐珠",
          "HTML5_Game_ID": "vs1ball",
          "Button_Image_Name": "vs1ball.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯狂大鲈鱼 Megaways",
          "HTML5_Game_ID": "vswaysbbb",
          "Button_Image_Name": "vswaysbbb.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "淘金热",
          "HTML5_Game_ID": "vs25goldrush",
          "Button_Image_Name": "vs25goldrush.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "迪斯科女士",
          "HTML5_Game_ID": "vs243discolady",
          "Button_Image_Name": "vs243discolady.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "优质宝石",
          "HTML5_Game_ID": "vs5littlegem",
          "Button_Image_Name": "vs5littlegem.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "古埃及神话",
          "HTML5_Game_ID": "vs10wildtut",
          "Button_Image_Name": "vs10wildtut.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "富贵888",
          "HTML5_Game_ID": "cs5triple8gold",
          "Button_Image_Name": "cs5triple8gold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "火热闪烁5",
          "HTML5_Game_ID": "vs5strh",
          "Button_Image_Name": "vs5strh.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "裏約之心",
          "HTML5_Game_ID": "vs25rio",
          "Button_Image_Name": "vs25rio.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "斗牛嘉年华",
          "HTML5_Game_ID": "vs25bullfiesta",
          "Button_Image_Name": "vs25bullfiesta.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿兹特克秘宝",
          "HTML5_Game_ID": "vs7776secrets",
          "Button_Image_Name": "vs7776secrets.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "金鸡下蛋",
          "HTML5_Game_ID": "vs20chickdrop",
          "Button_Image_Name": "vs20chickdrop.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "瓦尔哈拉之门",
          "HTML5_Game_ID": "vs10runes",
          "Button_Image_Name": "vs10runes.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "永恒钻石3线",
          "HTML5_Game_ID": "cs3w",
          "Button_Image_Name": "cs3w.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级X",
          "HTML5_Game_ID": "vs20superx",
          "Button_Image_Name": "vs20superx.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "3个精灵愿望",
          "HTML5_Game_ID": "vs50aladdin",
          "Button_Image_Name": "vs50aladdin.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及艳后之眼",
          "HTML5_Game_ID": "vs40cleoeye",
          "Button_Image_Name": "vs40cleoeye.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金列车",
          "HTML5_Game_ID": "vs3train",
          "Button_Image_Name": "vs3train.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "大力神和飞马",
          "HTML5_Game_ID": "vs20hercpeg",
          "Button_Image_Name": "vs20hercpeg.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "五行宝石 Megaways",
          "HTML5_Game_ID": "vswayselements",
          "Button_Image_Name": "vswayselements.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "神秘的酋长",
          "HTML5_Game_ID": "vswayswest",
          "Button_Image_Name": "vswayswest.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "女神戈帝梵",
          "HTML5_Game_ID": "vs20godiva",
          "Button_Image_Name": "vs20godiva.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "野生动物园",
          "HTML5_Game_ID": "vs25safari",
          "Button_Image_Name": "vs25safari.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "无敌金刚",
          "HTML5_Game_ID": "vs50kingkong",
          "Button_Image_Name": "vs50kingkong.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "亡灵节",
          "HTML5_Game_ID": "vs20daydead",
          "Button_Image_Name": "vs20daydead.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及财富",
          "HTML5_Game_ID": "vs20amuleteg",
          "Button_Image_Name": "vs20amuleteg.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "王者金字塔",
          "HTML5_Game_ID": "vs25pyramid",
          "Button_Image_Name": "vs25pyramid.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火 20",
          "HTML5_Game_ID": "vs20fh",
          "Button_Image_Name": "vs20fh.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "陈师傅的财富",
          "HTML5_Game_ID": "vs9chen",
          "Button_Image_Name": "vs9chen.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火 100",
          "HTML5_Game_ID": "vs100firehot",
          "Button_Image_Name": "vs100firehot.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "翡翠之王彩虹道路",
          "HTML5_Game_ID": "vs20ekingrr",
          "Button_Image_Name": "vs20ekingrr.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "巨大的现金区域",
          "HTML5_Game_ID": "vs20colcashzone",
          "Button_Image_Name": "vs20colcashzone.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "恶毒魔法",
          "HTML5_Game_ID": "vs40voodoo",
          "Button_Image_Name": "vs40voodoo.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "蛇梯棋超级骰子",
          "HTML5_Game_ID": "vs10snakeladd",
          "Button_Image_Name": "vs10snakeladd.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "达芬奇宝藏",
          "HTML5_Game_ID": "vs25davinci",
          "Button_Image_Name": "vs25davinci.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "调皮的皇帝",
          "HTML5_Game_ID": "vs243ckemp",
          "Button_Image_Name": "vs243ckemp.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "龙之国度",
          "HTML5_Game_ID": "vs25dragonkingdom",
          "Button_Image_Name": "vs25dragonkingdom.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级辣",
          "HTML5_Game_ID": "vs9hotroll",
          "Button_Image_Name": "vs9hotroll.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "发发树",
          "HTML5_Game_ID": "vs1fortunetree",
          "Button_Image_Name": "vs1fortunetree.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "崛起的武士3",
          "HTML5_Game_ID": "vs40samurai3",
          "Button_Image_Name": "vs40samurai3.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "行星宝石 Megaways",
          "HTML5_Game_ID": "vs117649starz",
          "Button_Image_Name": "vs117649starz.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "墨西哥餐厅",
          "HTML5_Game_ID": "vs40bigjuan",
          "Button_Image_Name": "vs40bigjuan.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "宙斯之子赫拉克勒斯",
          "HTML5_Game_ID": "vs50hercules",
          "Button_Image_Name": "vs50hercules.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "铁木真宝物",
          "HTML5_Game_ID": "vs1024temuj",
          "Button_Image_Name": "vs1024temuj.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "龙龙龙",
          "HTML5_Game_ID": "vs5trdragons",
          "Button_Image_Name": "vs5trdragons.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "深林之猩",
          "HTML5_Game_ID": "vs20gorilla",
          "Button_Image_Name": "vs20gorilla.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "彩虹水果",
          "HTML5_Game_ID": "vs40frrainbow",
          "Button_Image_Name": "vs40frrainbow.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣书帝国",
          "HTML5_Game_ID": "vs25bkofkngdm",
          "Button_Image_Name": "vs25bkofkngdm.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "鸿运你最牛",
          "HTML5_Game_ID": "vs25gldox",
          "Button_Image_Name": "vs25gldox.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "贝奥武夫",
          "HTML5_Game_ID": "vs40beowulf",
          "Button_Image_Name": "vs40beowulf.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火 40",
          "HTML5_Game_ID": "vs40firehot",
          "Button_Image_Name": "vs40firehot.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "爱尔兰的魅力",
          "HTML5_Game_ID": "cs3irishcharms",
          "Button_Image_Name": "cs3irishcharms.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "崛起的武士",
          "HTML5_Game_ID": "vs25samurai",
          "Button_Image_Name": "vs25samurai.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "杰克大师",
          "HTML5_Game_ID": "vs1masterjoker",
          "Button_Image_Name": "vs1masterjoker.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火 40",
          "HTML5_Game_ID": "vs40firehot",
          "Button_Image_Name": "vs40firehot.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "水果银行",
          "HTML5_Game_ID": "vswaysbankbonz",
          "Button_Image_Name": "vswaysbankbonz.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "吸血鬼vs狼",
          "HTML5_Game_ID": "vs10vampwolf",
          "Button_Image_Name": "vs10vampwolf.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "勤劳蜜蜂",
          "HTML5_Game_ID": "vs20bl",
          "Button_Image_Name": "vs20bl.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金海盗加强版",
          "HTML5_Game_ID": "vs40pirgold",
          "Button_Image_Name": "vs40pirgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿兹特克国王之书",
          "HTML5_Game_ID": "vs10bookazteck",
          "Button_Image_Name": "vs10bookazteck.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "约翰猎人与玛雅神迹",
          "HTML5_Game_ID": "vs10mayangods",
          "Button_Image_Name": "vs10mayangods.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及神祇",
          "HTML5_Game_ID": "vs50mightra",
          "Button_Image_Name": "vs50mightra.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "飞龙在天",
          "HTML5_Game_ID": "vs50chinesecharms",
          "Button_Image_Name": "vs50chinesecharms.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "精灵翅膀",
          "HTML5_Game_ID": "vs50pixie",
          "Button_Image_Name": "vs50pixie.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "奇幻无比金钱取款机",
          "HTML5_Game_ID": "vs10amm",
          "Button_Image_Name": "vs10amm.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金叛变",
          "HTML5_Game_ID": "vs10cowgold",
          "Button_Image_Name": "vs10cowgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "极度热火燃烧",
          "HTML5_Game_ID": "vs40hotburnx",
          "Button_Image_Name": "vs40hotburnx.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "城市大盗",
          "HTML5_Game_ID": "vs20stickysymbol",
          "Button_Image_Name": "vs20stickysymbol.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "大堡礁",
          "HTML5_Game_ID": "vs25sea",
          "Button_Image_Name": "vs25sea.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "閃亮 热火 20",
          "HTML5_Game_ID": "vs20sh",
          "Button_Image_Name": "vs20sh.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "命运女士",
          "HTML5_Game_ID": "vs10madame",
          "Button_Image_Name": "vs10madame.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "龙虎榜",
          "HTML5_Game_ID": "vs1024dtiger",
          "Button_Image_Name": "vs1024dtiger.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "井字游戏",
          "HTML5_Game_ID": "vs10tictac",
          "Button_Image_Name": "vs10tictac.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "狩猎之王",
          "HTML5_Game_ID": "vs50safariking",
          "Button_Image_Name": "vs50safariking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "街头赛车",
          "HTML5_Game_ID": "vs40streetracer",
          "Button_Image_Name": "vs40streetracer.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "疯狂机器",
          "HTML5_Game_ID": "vs40madwheel",
          "Button_Image_Name": "vs40madwheel.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "斯巴达之王",
          "HTML5_Game_ID": "vs40spartaking",
          "Button_Image_Name": "vs40spartaking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣诞老人",
          "HTML5_Game_ID": "vs20santa",
          "Button_Image_Name": "vs20santa.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "神秘之城",
          "HTML5_Game_ID": "vs4096mystery",
          "Button_Image_Name": "vs4096mystery.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "幸运，恩典和魅力",
          "HTML5_Game_ID": "vs10luckcharm",
          "Button_Image_Name": "vs10luckcharm.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "百搭加强器",
          "HTML5_Game_ID": "vs20wildboost",
          "Button_Image_Name": "vs20wildboost.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "轰炸富矿",
          "HTML5_Game_ID": "vs25bomb",
          "Button_Image_Name": "vs25bomb.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "恶魔13",
          "HTML5_Game_ID": "vs13g",
          "Button_Image_Name": "vs13g.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "黄金女王",
          "HTML5_Game_ID": "vs25queenofgold",
          "Button_Image_Name": "vs25queenofgold.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "翡翠之王",
          "HTML5_Game_ID": "vs20eking",
          "Button_Image_Name": "vs20eking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "矮人黄金豪华版",
          "HTML5_Game_ID": "vs25dwarves_new",
          "Button_Image_Name": "vs25dwarves_new.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "北海道之狼",
          "HTML5_Game_ID": "vs576hokkwolf",
          "Button_Image_Name": "vs576hokkwolf.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "坠落之书",
          "HTML5_Game_ID": "vs10bookfallen",
          "Button_Image_Name": "vs10bookfallen.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "小丑王国",
          "HTML5_Game_ID": "vs25jokerking",
          "Button_Image_Name": "vs25jokerking.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "埃及宿命",
          "HTML5_Game_ID": "vs20egypttrs",
          "Button_Image_Name": "vs20egypttrs.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "野精灵",
          "HTML5_Game_ID": "vs20wildpix",
          "Button_Image_Name": "vs20wildpix.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "农场追逐",
          "HTML5_Game_ID": "vs10chkchase",
          "Button_Image_Name": "vs10chkchase.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "福福福",
          "HTML5_Game_ID": "vs1fufufu",
          "Button_Image_Name": "vs1fufufu.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "星际海盗代码",
          "HTML5_Game_ID": "vs10starpirate",
          "Button_Image_Name": "vs10starpirate.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "深海动物",
          "HTML5_Game_ID": "vs40wanderw",
          "Button_Image_Name": "vs40wanderw.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "狂野角斗士",
          "HTML5_Game_ID": "vs25gladiator",
          "Button_Image_Name": "vs25gladiator.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级7s",
          "HTML5_Game_ID": "vs5super7",
          "Button_Image_Name": "vs5super7.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "三星报喜",
          "HTML5_Game_ID": "vs10threestar",
          "Button_Image_Name": "vs10threestar.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "古代埃及",
          "HTML5_Game_ID": "vs10egypt",
          "Button_Image_Name": "vs10egypt.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "武松打虎",
          "HTML5_Game_ID": "vs25tigerwar",
          "Button_Image_Name": "vs25tigerwar.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "西游争霸",
          "HTML5_Game_ID": "vs8magicjourn",
          "Button_Image_Name": "vs8magicjourn.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "行尸走肉",
          "HTML5_Game_ID": "vs25walker",
          "Button_Image_Name": "vs25walker.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超炫小丑",
          "HTML5_Game_ID": "vs5spjoker",
          "Button_Image_Name": "vs5spjoker.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "小妖之歌",
          "HTML5_Game_ID": "vs20leprechaun",
          "Button_Image_Name": "vs20leprechaun.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "超级燃烧",
          "HTML5_Game_ID": "vs5ultrab",
          "Button_Image_Name": "vs5ultrab.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "神龙宝藏雄火之眼",
          "HTML5_Game_ID": "vs5drmystery",
          "Button_Image_Name": "vs5drmystery.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "火热闪烁5",
          "HTML5_Game_ID": "vs5strh",
          "Button_Image_Name": "vs5strh.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "艳后之神",
          "HTML5_Game_ID": "vs10egrich",
          "Button_Image_Name": "vs10egrich.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "维加斯之夜",
          "HTML5_Game_ID": "vs25vegas",
          "Button_Image_Name": "vs25vegas.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "热火燃烧",
          "HTML5_Game_ID": "vs5hotburn",
          "Button_Image_Name": "vs5hotburn.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "走私者海湾",
          "HTML5_Game_ID": "vs20smugcove",
          "Button_Image_Name": "vs20smugcove.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "狂野鹏跳青蛙",
          "HTML5_Game_ID": "vs20mparty",
          "Button_Image_Name": "vs20mparty.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "星际赏金",
          "HTML5_Game_ID": "vswayshive",
          "Button_Image_Name": "vswayshive.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "阿拉丁和巫师",
          "HTML5_Game_ID": "vs20aladdinsorc",
          "Button_Image_Name": "vs20aladdinsorc.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "红火暴击2",
          "HTML5_Game_ID": "vs10firestrike2",
          "Button_Image_Name": "vs10firestrike2.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "石头族赌城",
          "HTML5_Game_ID": "vs20rockvegas",
          "Button_Image_Name": "vs20rockvegas.png"
        },
        {
          "EN_Name": "by",
          "CN_Name": "圣诞惊喜礼物",
          "HTML5_Game_ID": "vs20porbs",
          "Button_Image_Name": "vs20porbs.png"
        }
      ]
    },


    {
      "type": 4,
      "game_id": 404,
      "game_api": "SG",
      "name": "SG老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/sg.png",
      "icon": "sg",
      "game_list_json": "SG_game_list",
      "game_list_img_path": "/new/slot_res/sg/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：捕鱼大王、朱大富、四大财神、糖果泡泡、金武门、斧头帮、初恋、海龙王...",
      "labels": "#老虎机#捕鱼大王#朱大富#四大财神#糖果泡泡",
      gamelist: [
        {
          "HTML5_Game_ID": "F-SF01",
          "EN_Name": "Fishing_God",
          "CN_Name": "捕鱼天王",
          "Button_Image_Name": "Fishing_God_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CH01",
          "EN_Name": "Mr_Chu_Tycoon",
          "CN_Name": "朱大富",
          "Button_Image_Name": "Mr_Chu_Tycoon_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GK01",
          "EN_Name": "Brothers_kingdom",
          "CN_Name": "桃园三兄弟",
          "Button_Image_Name": "Brothers_kingdom_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PG01",
          "EN_Name": "Prosperity_Gods",
          "CN_Name": "四大财神",
          "Button_Image_Name": "Prosperity_Gods_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CP01",
          "EN_Name": "Candy_Pop",
          "CN_Name": "糖果泡泡",
          "Button_Image_Name": "Candy_Pop_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GF01",
          "EN_Name": "Golden_Fist",
          "CN_Name": "金武门",
          "Button_Image_Name": "Golden_Fist_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LY02",
          "EN_Name": "FaFaFa2",
          "CN_Name": "发发发2",
          "Button_Image_Name": "FaFaFa2_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GA01",
          "EN_Name": "Gangster_Axe",
          "CN_Name": "斧头帮",
          "Button_Image_Name": "Gangster_Axe_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PW02",
          "EN_Name": "Princess_Wang",
          "CN_Name": "旺旺公主",
          "Button_Image_Name": "Princess_Wang_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WP02",
          "EN_Name": "Wow_Prosperity",
          "CN_Name": "汪旺财",
          "Button_Image_Name": "Wow_Prosperity_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DM01",
          "EN_Name": "Ming_Dynasty",
          "CN_Name": "大明帝国",
          "Button_Image_Name": "Ming_Dynasty_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FL02",
          "EN_Name": "First_Love",
          "CN_Name": "初恋",
          "Button_Image_Name": "First_Love_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FM02",
          "EN_Name": "Golden_Monkey",
          "CN_Name": "齐天大胜",
          "Button_Image_Name": "Golden_Monkey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PP01",
          "EN_Name": "Pan_Fairy",
          "CN_Name": "潘精灵",
          "Button_Image_Name": "Pan_Fairy_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SH01",
          "EN_Name": "ShangHai_008",
          "CN_Name": "上海_00发",
          "Button_Image_Name": "ShangHai_008_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TZ01",
          "EN_Name": "Jungle_King",
          "CN_Name": "森林之王",
          "Button_Image_Name": "Jungle_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FG01",
          "EN_Name": "Fist_of_Gold",
          "CN_Name": "新霸天下",
          "Button_Image_Name": "Fist_of_Gold_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TW01",
          "EN_Name": "Tiger_Warrior",
          "CN_Name": "虎大天王_",
          "Button_Image_Name": "Tiger_Warrior_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-NT01",
          "EN_Name": "Sea_Emperor",
          "CN_Name": "海龙王",
          "Button_Image_Name": "Sea_Emperor_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-ZE01",
          "EN_Name": "ZEUS",
          "CN_Name": "天通雷神",
          "Button_Image_Name": "ZEUS_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HY01",
          "EN_Name": "Ho_Yeah_Monkey",
          "CN_Name": "猴爷到",
          "Button_Image_Name": "Ho_Yeah_Monkey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GC03",
          "EN_Name": "Golden_Chicken",
          "CN_Name": "金鸡",
          "Button_Image_Name": "Golden_Chicken_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DG04",
          "EN_Name": "Dragon_Gold_SA",
          "CN_Name": "金龙赐福_SA",
          "Button_Image_Name": "Dragon_Gold_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-EG03",
          "EN_Name": "Emperor_Gate_SA",
          "CN_Name": "斩五门SA",
          "Button_Image_Name": "Emperor_Gate_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FC03",
          "EN_Name": "Big_Prosperity_SA",
          "CN_Name": "发大财_SA",
          "Button_Image_Name": "Big_Prosperity_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GS04",
          "EN_Name": "Great_Stars_SA",
          "CN_Name": "吉星_SA",
          "Button_Image_Name": "Great_Stars_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IL03",
          "EN_Name": "Iceland_SA",
          "CN_Name": "雪冰世界SA",
          "Button_Image_Name": "Iceland_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IM03",
          "EN_Name": "Indian_Myth_SA",
          "CN_Name": "印度神话SA",
          "Button_Image_Name": "Indian_Myth_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LE03",
          "EN_Name": "Lion_Emperor_SA",
          "CN_Name": "狮子王SA",
          "Button_Image_Name": "Lion_Emperor_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LH03",
          "EN_Name": "King_The_Lion_Heart_SA",
          "CN_Name": "狮心王SA",
          "Button_Image_Name": "King_The_Lion_Heart_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SG04",
          "EN_Name": "Shougen_War_SA",
          "CN_Name": "将军令SA",
          "Button_Image_Name": "Shougen_War_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SP03",
          "EN_Name": "Spartan_SA",
          "CN_Name": "斯巴达SA",
          "Button_Image_Name": "Spartan_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WC03",
          "EN_Name": "Wong_Choy_SA",
          "CN_Name": "旺财_SA",
          "Button_Image_Name": "Wong_Choy_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WM03",
          "EN_Name": "5_Fortune_SA",
          "CN_Name": "五福门_SA",
          "Button_Image_Name": "5_Fortune_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-BC01",
          "EN_Name": "Baby_Cai_Shen",
          "CN_Name": "财神宝宝",
          "Button_Image_Name": "Baby_Cai_Shen_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FO01",
          "EN_Name": "168_Fortunes",
          "CN_Name": "一路发发",
          "Button_Image_Name": "168_Fortunes_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GL02",
          "EN_Name": "Golden_Lotus_SE",
          "CN_Name": "潘金莲_特别版",
          "Button_Image_Name": "Golden_Lotus_SE_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-MR01",
          "EN_Name": "Mermaid",
          "CN_Name": "美人鱼",
          "Button_Image_Name": "Mermaid_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PH02",
          "EN_Name": "King_Pharaoh",
          "CN_Name": "法老宝藏",
          "Button_Image_Name": "King_Pharaoh_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PO01",
          "EN_Name": "Pocket_Mon_Go",
          "CN_Name": "宝袋精灵",
          "Button_Image_Name": "Pocket_Mon_Go_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WP01",
          "EN_Name": "Wong_Po",
          "CN_Name": "旺宝",
          "Button_Image_Name": "Wong_Po_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-AL01",
          "EN_Name": "Alibaba",
          "CN_Name": "阿里巴巴",
          "Button_Image_Name": "Alibaba_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CS01",
          "EN_Name": "Cai_Shen_888",
          "CN_Name": "财神888",
          "Button_Image_Name": "Cai_Shen_888_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DF01",
          "EN_Name": "Double_Fortunes",
          "CN_Name": "青龙白虎",
          "Button_Image_Name": "Double_Fortunes_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FD01",
          "EN_Name": "5_Fortune_Dragons",
          "CN_Name": "五龙吐珠",
          "Button_Image_Name": "5_Fortune_Dragons_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GO01",
          "EN_Name": "Gods_Kitchen",
          "CN_Name": "厨神",
          "Button_Image_Name": "Gods_Kitchen_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IL02",
          "EN_Name": "Adventure_Iceland",
          "CN_Name": "雪冰世界",
          "Button_Image_Name": "Adventure_Iceland_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IM02",
          "EN_Name": "Adventure_Indian_Myth",
          "CN_Name": "印度神话",
          "Button_Image_Name": "Adventure_Indian_Myth_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LE02",
          "EN_Name": "Adventure_Lion_Emperor",
          "CN_Name": "狮子王",
          "Button_Image_Name": "Adventure_Lion_Emperor_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LK01",
          "EN_Name": "Lucky_Koi",
          "CN_Name": "运财鲤鱼",
          "Button_Image_Name": "Lucky_Koi_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SM01",
          "EN_Name": "Soccer_Mania",
          "CN_Name": "疯狂足球",
          "Button_Image_Name": "Soccer_Mania_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SM02",
          "EN_Name": "Space_Monkey",
          "CN_Name": "太空神猴",
          "Button_Image_Name": "Space_Monkey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-AT02",
          "EN_Name": "Amazing_Thailand",
          "CN_Name": "泰国神游",
          "Button_Image_Name": "Amazing_Thailand_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CM01",
          "EN_Name": "Drunken_Jungle",
          "CN_Name": "森林狂欢季",
          "Button_Image_Name": "Drunken_Jungle_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DG03",
          "EN_Name": "Dragon_Gold",
          "CN_Name": "金龙赐福",
          "Button_Image_Name": "Dragon_Gold_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-EG02",
          "EN_Name": "Emperor_Gate",
          "CN_Name": "斩五门",
          "Button_Image_Name": "Emperor_Gate_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FC02",
          "EN_Name": "Big_Prosperity",
          "CN_Name": "发大财",
          "Button_Image_Name": "Big_Prosperity_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GC02",
          "EN_Name": "Great_China",
          "CN_Name": "中华之最",
          "Button_Image_Name": "Great_China_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GG01",
          "EN_Name": "Aqua_Cash_",
          "CN_Name": "如鱼得水",
          "Button_Image_Name": "Aqua_Cash__128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GS03",
          "EN_Name": "Great_Stars",
          "CN_Name": "吉星",
          "Button_Image_Name": "Great_Stars_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HH01",
          "EN_Name": "Honey_Hunter",
          "CN_Name": "熊之蜜",
          "Button_Image_Name": "Honey_Hunter_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HL01",
          "EN_Name": "Lucky_Tank",
          "CN_Name": "幸运坦克",
          "Button_Image_Name": "Lucky_Tank_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-JF02",
          "EN_Name": "Japan_Fortune",
          "CN_Name": "日本福气",
          "Button_Image_Name": "Japan_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LH02",
          "EN_Name": "King_The_Lion_Heart",
          "CN_Name": "狮心王",
          "Button_Image_Name": "King_The_Lion_Heart_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LI02",
          "EN_Name": "Lava_Island",
          "CN_Name": "冒险火岛",
          "Button_Image_Name": "Lava_Island_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LY01",
          "EN_Name": "Fafafa",
          "CN_Name": "发发发",
          "Button_Image_Name": "Fafafa_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PT01",
          "EN_Name": "Hua_Mulan",
          "CN_Name": "花木兰",
          "Button_Image_Name": "Hua_Mulan_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SG03",
          "EN_Name": "Shougen_War",
          "CN_Name": "将军令",
          "Button_Image_Name": "Shougen_War_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SP02",
          "EN_Name": "Spartan",
          "CN_Name": "斯巴达",
          "Button_Image_Name": "Spartan_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WC02",
          "EN_Name": "Wong_Choy",
          "CN_Name": "旺财",
          "Button_Image_Name": "Wong_Choy_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WM02",
          "EN_Name": "5_Fortune",
          "CN_Name": "五福门",
          "Button_Image_Name": "5_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LF01",
          "EN_Name": "Lucky_Feng_Shui",
          "CN_Name": "风生水起",
          "Button_Image_Name": "Lucky_Feng_Shui_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PK01",
          "EN_Name": "Pirate_King",
          "CN_Name": "海盗霸王",
          "Button_Image_Name": "Pirate_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GP02",
          "EN_Name": "Golf_Champions",
          "CN_Name": "冠军高尔夫",
          "Button_Image_Name": "Golf_Champions_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HF01",
          "EN_Name": "Highway_Fortune",
          "CN_Name": "极速王者",
          "Button_Image_Name": "Highway_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-BB01",
          "EN_Name": "Festive_Lion",
          "CN_Name": "金狮拜年",
          "Button_Image_Name": "Festive_Lion_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-BF02",
          "EN_Name": "Mr_Big_Foot",
          "CN_Name": "大脚先生",
          "Button_Image_Name": "Mr_Big_Foot_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CC01",
          "EN_Name": "Master_Chef",
          "CN_Name": "至尊厨师",
          "Button_Image_Name": "Master_Chef_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CY01",
          "EN_Name": "Cai_Yuan_Guang_Jin",
          "CN_Name": "财源广进",
          "Button_Image_Name": "Cai_Yuan_Guang_Jin_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DV01",
          "EN_Name": "Daddys_Vacation",
          "CN_Name": "爸爸飞那儿",
          "Button_Image_Name": "Daddys_Vacation_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DX01",
          "EN_Name": "Da_Fu_Xiao_Fu",
          "CN_Name": "大福小福",
          "Button_Image_Name": "Da_Fu_Xiao_Fu_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FB02",
          "EN_Name": "World_Cup_Golden_Boot",
          "CN_Name": "金靴世界杯",
          "Button_Image_Name": "World_Cup_Golden_Boot_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FZ02",
          "EN_Name": "Father_vs_Zombies",
          "CN_Name": "父女战殭屍",
          "Button_Image_Name": "Father_vs_Zombies_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GW01",
          "EN_Name": "Golden_Whale",
          "CN_Name": "黄金黥鱼",
          "Button_Image_Name": "Golden_Whale_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-JT01",
          "EN_Name": "Jack_The_Pirate",
          "CN_Name": "杰克海盗",
          "Button_Image_Name": "Jack_The_Pirate_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LC01",
          "EN_Name": "Lucky_Cai_Shen",
          "CN_Name": "天降财神",
          "Button_Image_Name": "Lucky_Cai_Shen_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LM01",
          "EN_Name": "Lucky_Meow",
          "CN_Name": "超吉猫",
          "Button_Image_Name": "Lucky_Meow_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LS01",
          "EN_Name": "Lucky_Strike",
          "CN_Name": "马上赢",
          "Button_Image_Name": "Lucky_Strike_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-MH02",
          "EN_Name": "The_Curse_Magic_Hammer",
          "CN_Name": "神錘魔咒",
          "Button_Image_Name": "The_Curse_Magic_Hammer_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-MP02",
          "EN_Name": "Monster_Tunnel",
          "CN_Name": "下水道小魔兽",
          "Button_Image_Name": "Monster_Tunnel_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-RG02",
          "EN_Name": "Rising_Gems",
          "CN_Name": "魔幻宝石",
          "Button_Image_Name": "Rising_Gems_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-RK01",
          "EN_Name": "Railway_King",
          "CN_Name": "铁路王",
          "Button_Image_Name": "Railway_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SA02",
          "EN_Name": "Spin_Stone",
          "CN_Name": "石器时代",
          "Button_Image_Name": "Spin_Stone_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SG02",
          "EN_Name": "Santa_Gifts",
          "CN_Name": "圣诞大礼",
          "Button_Image_Name": "Santa_Gifts_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SK01",
          "EN_Name": "Safari_King",
          "CN_Name": "野生动物园",
          "Button_Image_Name": "Safari_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TP01",
          "EN_Name": "Fun_Paradise",
          "CN_Name": "乐天堂",
          "Button_Image_Name": "Fun_Paradise_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TS02",
          "EN_Name": "The_Songs_of_China",
          "CN_Name": "中国好歌声",
          "Button_Image_Name": "The_Songs_of_China_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-RB01",
          "EN_Name": "Rob_Stars",
          "CN_Name": "小偷星星",
          "Button_Image_Name": "Rob_Stars_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DN02",
          "EN_Name": "Derby_Night",
          "CN_Name": "德比夜赛_",
          "Button_Image_Name": "Derby_Night_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-GJ01",
          "EN_Name": "Golden_Journey",
          "CN_Name": "西游金",
          "Button_Image_Name": "Golden_Journey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-GL02",
          "EN_Name": "Goblin_Treasure",
          "CN_Name": "积宝小精灵_",
          "Button_Image_Name": "Goblin_Treasure_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-RW02",
          "EN_Name": "Richie_Wheel",
          "CN_Name": "至尊轮盘",
          "Button_Image_Name": "Richie_Wheel_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-PF02",
          "EN_Name": "PinBall_Fortune",
          "CN_Name": "单球致富_",
          "Button_Image_Name": "PinBall_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-SC02",
          "EN_Name": "Dragon_Clan",
          "CN_Name": "龙会_",
          "Button_Image_Name": "Dragon_Clan_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-SH02",
          "EN_Name": "Shinobi_Hamster",
          "CN_Name": "仓鼠忍者_",
          "Button_Image_Name": "Shinobi_Hamster_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DB06",
          "EN_Name": "3_Kingdom_The_Dragon_Boat",
          "CN_Name": "三国龙舟战_",
          "Button_Image_Name": "3_Kingdom_The_Dragon_Boat_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-AP02",
          "EN_Name": "Animal_Paradise",
          "CN_Name": "动物奇园",
          "Button_Image_Name": "Animal_Paradise_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DB04",
          "EN_Name": "Bicycle_Race",
          "CN_Name": "职业赛单车",
          "Button_Image_Name": "Bicycle_Race_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DB05",
          "EN_Name": "Dirt_Bike",
          "CN_Name": "越野赛车",
          "Button_Image_Name": "Dirt_Bike_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DD02",
          "EN_Name": "Dog_Racing",
          "CN_Name": "极速赛狗",
          "Button_Image_Name": "Dog_Racing_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DE02",
          "EN_Name": "Derby_Express",
          "CN_Name": "德比直通",
          "Button_Image_Name": "Derby_Express_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-JW02",
          "EN_Name": "Magic_Journey",
          "CN_Name": "仙桃演义",
          "Button_Image_Name": "Magic_Journey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-LB02",
          "EN_Name": "Lucky_Baby",
          "CN_Name": "幸运宝贝",
          "Button_Image_Name": "Lucky_Baby_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-MT02",
          "EN_Name": "Monkey_Thunder_Bolt",
          "CN_Name": "霹雳神猴",
          "Button_Image_Name": "Monkey_Thunder_Bolt_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-HC02",
          "EN_Name": "Hulu_Cock",
          "CN_Name": "葫芦鸡_",
          "Button_Image_Name": "Hulu_Cock_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-CH02",
          "EN_Name": "Lucky_Cup",
          "CN_Name": "福杯",
          "Button_Image_Name": "Lucky_Cup_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-BK02",
          "EN_Name": "Belangkai",
          "CN_Name": "鱼虾蟹_",
          "Button_Image_Name": "Belangkai_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-DT03",
          "EN_Name": "Dragon_Tiger",
          "CN_Name": "龙虎_",
          "Button_Image_Name": "Dragon_Tiger_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-SB02",
          "EN_Name": "Sic_Bo",
          "CN_Name": "骰宝_",
          "Button_Image_Name": "Sic_Bo_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-SD03",
          "EN_Name": "Sedie",
          "CN_Name": "骰碟_",
          "Button_Image_Name": "Sedie_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-RT02",
          "EN_Name": "Roulette_Euro",
          "CN_Name": "欧式轮盘_",
          "Button_Image_Name": "Roulette_Euro_128x128_cn.png"
        },

      ]
    }],
  "icon": "dianjing"
},
  {
  "name": "棋牌",
  "type_id": 5,
  "gamelist": [
      {
    "type": 4,
    "game_id": 411,
    "game_api": "FG",
    "name": "FG棋牌",
    "imgPath": "https://newpic.hdyb33.com/games/pg.png",
    "icon": "fg",
    "game_list_json": "FG_Chess_game_list",
    "game_list_img_path": "/new/slot_res/fg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：变形金刚、淘金热、66路、狂欢、埃及、接头霸王、东方国度、西部牛仔..",
    "labels": "#老虎机#变形金刚#淘金热#66路#狂欢#埃及#接头霸王#东方国度#西部牛仔",
    gamelist: [
      {"CN_Name": "百人牛牛", "HTML5_Game_ID": "HB", "EN_Name": "Hundreds Of Bull", "Button_Image_Name": "HB.png"},
      // {"CN_Name": "皇家德州", "HTML5_Game_ID": "rt", "EN_Name": "Royal Texas", "Button_Image_Name": "rt.png"},
      {"CN_Name": "骰宝", "HTML5_Game_ID": "tz", "EN_Name": "Sic Bo", "Button_Image_Name": "tz.png"},
      {"CN_Name": "百家乐", "HTML5_Game_ID": "bjl", "EN_Name": "Baccarat", "Button_Image_Name": "bjl.png"},
      // {"CN_Name": "皇家宫殿", "HTML5_Game_ID": "RC", "EN_Name": "Royal Palace", "Button_Image_Name": "RC.png"},
      {
        "CN_Name": "经典炸金花",
        "HTML5_Game_ID": "zjhdr",
        "EN_Name": "Classical Win Three Cards",
        "Button_Image_Name": "zjhdr.png"
      },
      {
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "TexasCb",
        "EN_Name": "Texas Cowboy",
        "Button_Image_Name": "TexasCb.png"
      },
      {"CN_Name": "二人麻将", "HTML5_Game_ID": "mj", "EN_Name": "Two People Mahjong", "Button_Image_Name": "mj.png"},
      {
        "CN_Name": "抢庄牛牛",
        "HTML5_Game_ID": "cqznn",
        "EN_Name": "Rob Banker Of Bull",
        "Button_Image_Name": "cqznn.png"
      },
      {"CN_Name": "三公", "HTML5_Game_ID": "csg", "EN_Name": "San_Gong", "Button_Image_Name": "csg.png"},
      // {
      //     "CN_Name": "极速炸金花",
      //     "HTML5_Game_ID": "jszjh",
      //     "EN_Name": "Topspeed Win Three Cards",
      //     "Button_Image_Name": "jszjh.png"
      // },
      {"CN_Name": "通比牛牛", "HTML5_Game_ID": "tbnn", "EN_Name": "Tongbi Cattle", "Button_Image_Name": "tbnn.png"},
      // {
      //     "CN_Name": "十三水",
      //     "HTML5_Game_ID": "sss",
      //     "EN_Name": "Open-face Chinese Poker",
      //     "Button_Image_Name": "sss.png"
      // },
      {"CN_Name": "二八杠", "HTML5_Game_ID": "ebg", "EN_Name": "Mahjong", "Button_Image_Name": "ebg.png"},
      {"CN_Name": "抢庄牌九", "HTML5_Game_ID": "qzpj", "EN_Name": "Pai Gow", "Button_Image_Name": "qzpj.png"},
      {"CN_Name": "斗地主", "HTML5_Game_ID": "ddz", "EN_Name": "Landlord", "Button_Image_Name": "ddz.png"},
      {
        "CN_Name": "梭哈",
        "HTML5_Game_ID": "ShowHand",
        "EN_Name": "Five-Card Poker",
        "Button_Image_Name": "ShowHand.png"
      },
      {"CN_Name": "欢乐德州", "HTML5_Game_ID": "iPoker", "EN_Name": "Fun Poker", "Button_Image_Name": "iPoker.png"},
    ],
  }, {
    "type": 5,
    "game_id": 502,
    "game_api": "KY",
    "name": "开元棋牌",
    "imgPath": "https://newpic.hdyb33.com/chess/ky.png",
    "icon": "ky",
    "memo1": "平台特色：游戏玩法丰富多样，竞赛活动天天开启，24小时不停歇",
    "memo2": "包含游戏：炸金花、二八杠、德州扑克、血流成河、压庄龙虎、百家乐、二十一点...",
    "labels": "#棋牌#牛牛#抢庄牛牛#二十一点#炸金花#二八杠#龙虎斗#德州扑克",
    "hot_type": "hot",
    "showTitle": 1,
  }, {
    "type": 5,
    "game_id": 501,
    "game_api": "LY",
    "name": "乐游棋牌",
    "imgPath": "https://newpic.hdyb33.com/chess/ly.png",
    "icon": "ly",
    "memo1": "平台特色：万千玩家同时在线，自动匹配玩家，人满即开局",
    "memo2": "包含游戏：二人麻将、抢庄牛牛、二十一点、炸金花、二八杠、龙虎斗、德州扑克...",
    "labels": "#棋牌#牛牛#二人麻将#抢庄牛牛#二十一点#炸金花#二八杠#龙虎斗#德州扑克",
    "hot_type": "recommend",
    "showTitle": 1,
  },
    {
    "type": 4,
    "game_id": 406,
    "game_api": "CQ9",
    "name": "CQ9棋牌",
    "imgPath": "https://newpic.hdyb33.com/games/cq9.png",
    "icon": "cq9",
    "game_list_json": "CQ9_Chess_game_list",
    "game_list_img_path": "/new/slot_res/cq9/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：盗法老墓、妙笔生财、喵财进宝、鱼跃龙门、丛林宝藏、喵喵、老司机...",
    "labels": "#老虎机#盗法老墓#妙笔生财#喵财进宝#鱼跃龙门",
    gamelist: [
      {"Button_Image_Name": "dzpk.png", "HTML5_Game_ID": "BK04", "CN_Name": "德州扑克"},
      {"Button_Image_Name": "ermj.png", "HTML5_Game_ID": "AD11", "CN_Name": "二人麻将"},
      {"Button_Image_Name": "ddzsjb.png", "HTML5_Game_ID": "AR41", "CN_Name": "斗地主升级版"},
      {"Button_Image_Name": "jbddz.png", "HTML5_Game_ID": "AR04", "CN_Name": "斗地主经典版"},
      {"Button_Image_Name": "qznn.png", "HTML5_Game_ID": "BK01", "CN_Name": "抢庄牛牛"}
    ]
  }],
  "icon": "poker-cards",
  "FG_Chess_game_list": [{
    "type": 4,
    "game_id": 411,
    "game_api": "FG",
    "name": "FG老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/pg.png",
    "icon": "fg",
    "game_list_json": "FG_game_list",
    "game_list_img_path": "/new/slot_res/fg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：变形金刚、淘金热、66路、狂欢、埃及、接头霸王、东方国度、西部牛仔..",
    "labels": "#老虎机#变形金刚#淘金热#66路#狂欢#埃及#接头霸王#东方国度#西部牛仔",
    gamelist: [
      {"CN_Name": "百人牛牛", "HTML5_Game_ID": "HB", "EN_Name": "Hundreds Of Bull", "Button_Image_Name": "HB.png"},
      // {"CN_Name": "皇家德州", "HTML5_Game_ID": "rt", "EN_Name": "Royal Texas", "Button_Image_Name": "rt.png"},
      {"CN_Name": "骰宝", "HTML5_Game_ID": "tz", "EN_Name": "Sic Bo", "Button_Image_Name": "tz.png"},
      {"CN_Name": "百家乐", "HTML5_Game_ID": "bjl", "EN_Name": "Baccarat", "Button_Image_Name": "bjl.png"},
      // {"CN_Name": "皇家宫殿", "HTML5_Game_ID": "RC", "EN_Name": "Royal Palace", "Button_Image_Name": "RC.png"},
      {
        "CN_Name": "经典炸金花",
        "HTML5_Game_ID": "zjhdr",
        "EN_Name": "Classical Win Three Cards",
        "Button_Image_Name": "zjhdr.png"
      },
      {
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "TexasCb",
        "EN_Name": "Texas Cowboy",
        "Button_Image_Name": "TexasCb.png"
      },
      {"CN_Name": "二人麻将", "HTML5_Game_ID": "mj", "EN_Name": "Two People Mahjong", "Button_Image_Name": "mj.png"},
      {
        "CN_Name": "抢庄牛牛",
        "HTML5_Game_ID": "cqznn",
        "EN_Name": "Rob Banker Of Bull",
        "Button_Image_Name": "cqznn.png"
      },
      {"CN_Name": "三公", "HTML5_Game_ID": "csg", "EN_Name": "San_Gong", "Button_Image_Name": "csg.png"},
      // {
      //     "CN_Name": "极速炸金花",
      //     "HTML5_Game_ID": "jszjh",
      //     "EN_Name": "Topspeed Win Three Cards",
      //     "Button_Image_Name": "jszjh.png"
      // },
      {"CN_Name": "通比牛牛", "HTML5_Game_ID": "tbnn", "EN_Name": "Tongbi Cattle", "Button_Image_Name": "tbnn.png"},
      // {
      //     "CN_Name": "十三水",
      //     "HTML5_Game_ID": "sss",
      //     "EN_Name": "Open-face Chinese Poker",
      //     "Button_Image_Name": "sss.png"
      // },
      {"CN_Name": "二八杠", "HTML5_Game_ID": "ebg", "EN_Name": "Mahjong", "Button_Image_Name": "ebg.png"},
      {"CN_Name": "抢庄牌九", "HTML5_Game_ID": "qzpj", "EN_Name": "Pai Gow", "Button_Image_Name": "qzpj.png"},
      {"CN_Name": "斗地主", "HTML5_Game_ID": "ddz", "EN_Name": "Landlord", "Button_Image_Name": "ddz.png"},
      {
        "CN_Name": "梭哈",
        "HTML5_Game_ID": "ShowHand",
        "EN_Name": "Five-Card Poker",
        "Button_Image_Name": "ShowHand.png"
      },
      {"CN_Name": "欢乐德州", "HTML5_Game_ID": "iPoker", "EN_Name": "Fun Poker", "Button_Image_Name": "iPoker.png"},
    ],
  }]
}]

// 弹出不需要的游戏
if(global.ID== 'ca'){


  let vipgame = gameJSON[3].gamelist[0].gamelist;


  let tempArr =[];

  for(let i=0;i<vipgame.length;i++){
    if(vipgame[i].CN_Name == '灵猴献瑞'){
      continue;
    }
    tempArr.push(vipgame[i]);
  }

  gameJSON[3].gamelist[0].gamelist = tempArr;

}


export default gameJSON
