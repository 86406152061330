/**
 * 账单
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../component/container";
import Storage from "../../services/storage";
import Http from "../../services/http";
import PublicUtils from "../../utils/publicUtils";
import model from "../../services/model";
import style from "../../utils/config.scss";
import Icon from "../../utils/icon";
import MyPicker from "../../component/myPicker";
import PullEndFooter from "../../component/pullEndFooter";
import Dialog from "./components/dialog";

export default class YEB extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.dateTypeModel = model.dateType;
        this.changeTypeModel = [
            {label: '全部', value: ''},
            {label: '转入', value: 1},
            {label: '转出', value: 2},
            {label: '收益', value: 3}
        ];
        this.state = {
            showChangeTypeFliter: false,
            showDateTypeFliter: false,
            dateType: this.dateTypeModel[0],
            changeType: this.changeTypeModel[0],
            size: 10,
            start: "",
            end: "",
            info: {
                type_desc: ""
            },
            list: [],
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            total: 0,//总页数
            currpage: 1,//当前页
            type: 1,
            showDialog: false
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_()
    };
    _init_ = () => {
        this._fetchMemberInfo();
        this._fetchYEBRecord();
    }

    /**
     * 获取会员信息
     * @private
     */
    _fetchMemberInfo = () => {

        const parm = {};
        Http.request("YEBConfig", parm, (res) => {
            if (res.code == 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code == 200) {
                if (res.data.status) {
                    this.setState({info: res.data.data});
                }
            }
        });
    };

    /**
     * 获取余额宝账变
     * @private
     */
    _fetchYEBRecord = () => {
        let parm = {
            type: this.state.changeType.value,
            page: this.state.currpage,
            size: this.state.size
        };
        if (this.state.start && this.state.end) {
            parm.start = this.state.start;
            parm.end = this.state.end;
        } else {

            parm.day = this.state.dateType.value;
        }
        Http.request("YEBRecord", parm, (res) => {
            if (res.code == 200) {
                if (res.data.data.list.length) {
                    if (this.state.currpage === 1) {
                        this.setState({list: res.data.data.list, total: res.data.data.total});
                    } else {
                        this.setState({list: this.state.list.concat(res.data.data.list), total: res.data.data.total})
                    }
                    if (this.state.currpage >= res.data.data.total) {
                        this.setState({showFoot: 1});
                    } else {
                        this.setState({showFoot: 2});
                    }
                    // this.setState({list: res.data.data.list});
                } else {
                    this.setState({list: []});
                }
            }
        });
    }

    _onEndReached() {
        if (this.state.currpage === this.state.total) {
            this.setState({showFoot: 1});
            return;
        }
        this.setState({currpage: this.state.currpage + 1}, () => {
            //获取数据
            this._fetchYEBRecord();
        });

    }

    render() {
        const styles = {

            bodyContainer: {
                display: "flex",
                flex: 1,
                // background: "linear-gradient(to bottom, " + style['primaryColorDeep' + model.SKINNAME] + ", #fff)",
                flexDirection: "column",
            },
            headShadow: {
                display: "flex",
                background: "linear-gradient(to bottom, " + style['primaryColorDeep' + model.SKINNAME] + ", #fff)",
                flexDirection: "column",
            },
            headContainer: {
                margin: 10,
                padding: 10,
                background: "#fff",
                borderRadius: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            },
            headTopContainer: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
                paddingBottom: 20,
                flexDirection: "column",
                width: "100%"
            },
            headTopTitleContainer: {
                color: "rgba(0,0,0,0.5)",
                fontSize: 14
            },
            headTopValueContainer: {
                color: "#000",
                fontSize: 30
            },
            headBottomValueContainer: {
                position:'relative',
                display: "flex",
                // width: "40%",
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRadius: 30,
                marginTop: 10,
                color: "rgba(0,0,0,0.3)",
                fontSize: 14,
                paddingLeft: 15,
                paddingRight: 15,
            },
            headMidContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
            },
            headMidLeftContainer: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1
            },
            headMidLeftTopContainer: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            headMidLeftBottomContainer: {
                fontSize: 24,
                color: "rgba(0,0,0,0.8)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            headMidRightContainer: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1
            },
            headMidRightTopContainer: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            headMidRightBottomContainer: {
                fontSize: 24,
                color: "rgba(0,0,0,0.8)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            headBottomContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 30
            },
            headBottomLeftBtnContainer: {
                margin: 5,
                backgroundColor: style['primaryColorLight' + model.SKINNAME],
                color: style['primaryColor' + model.SKINNAME],
                display: "flex",
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10
            },
            headBottomRightBtnContainer: {
                margin: 5,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                color: "#fff",
                display: "flex",
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10
            },
        }
        const recordStyles = {
            headContainer: {
                display: "flex",
                flexDirection: "row",
                borderTop: "1px solid rgba(0,0,0,0.05)",
                borderBottom: "1px solid rgba(0,0,0,0.05)",
                height: 40,
                alignItems: "center",
                paddingLeft: 5,
                paddingRight: 5,
            },
            headerToolbarItemBtn: {
                marginLeft: 15,
            },
            headerToolbarItem: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            headerToolbarItemText: {
                fontSize: 12,
                color: "rgba(0,0,0,0.7)",
                marginRight: 5
            },
            headerToolbarItemValueText: {
                display: "flex",
                flexDirection: "row"
            }
        }
        const recordBodyStyles = {
            container: {
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                // flex: 1,
                overflow: "auto",
                flexDirection: "column"
            },
            recordContainer: {
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid rgba(0,0,0,0.05)",
                justifyContent: "space-between",
                padding: 10
                // paddingBottom: 10,
                // paddingTop: 10,
                // marginLeft:10,
                // marginRight:10,
            },
            recordleftContainer: {
                display: "flex",
                flexDirection: "column",
            },
            recordleftTopContainer: {
                marginBottom: 10,
                fontSize: 16,
                colo: "rgba(0,0,0,0.8)"
            },
            recordleftBottomContainer: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)"
            },
            recordRightContainer: {},
            recordRightValueContainer: {
                fontSize: 18,
                colo: "rgba(0,0,0,1)"
            },
        }
        return (
            <Container headerFontColor={"#fff"} headerBackgroundColor={style['primaryColorDeep' + model.SKINNAME]}
                       style={{backgroundColor: "#fff", flex: 1, display: "flex"}} title={'余额宝'}>
                <div style={styles.bodyContainer}>
                    <div style={styles.headShadow}>
                        <div style={styles.headContainer}>
                            <div style={styles.headTopContainer}>
                                <div style={styles.headTopTitleContainer}>
                                    <span>总金额(元)</span>
                                </div>
                                <div style={styles.headTopValueContainer}>
                                    <span>{this.state.info.yu_e_bao}</span>
                                </div>
                                <div style={styles.headBottomValueContainer}>
                                    <span>{this.state.info.today_earn ? ("今日收益：" + this.state.info.today_earn) : "暂无收益"}</span>
                                </div>
                            </div>
                            <div style={styles.headMidContainer}>
                                <div style={styles.headMidLeftContainer}>
                                    <div style={styles.headMidLeftTopContainer}>
                                        <span>累计收益(元)</span>
                                    </div>
                                    <div style={styles.headMidLeftBottomContainer}>
                                        <span>{this.state.info.total_earn}</span>
                                    </div>
                                </div>
                                <div style={styles.headMidRightContainer}>
                                    <div style={styles.headMidRightTopContainer}>
                                        <span>{this.state.info.type_desc}(%)</span>
                                    </div>
                                    <div style={styles.headMidRightBottomContainer}>
                                        <span>{this.state.info.rate}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.headBottomContainer}>
                                <div style={styles.headBottomLeftBtnContainer} onClick={() => {
                                    this.setState({type: 2, showDialog: true});
                                }}>
                                    <span>转出</span>
                                </div>
                                <div style={styles.headBottomRightBtnContainer} onClick={() => {
                                    this.setState({type: 1, showDialog: true});
                                }}>
                                    <span>转入</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={recordStyles.headContainer}>
                        <div style={recordStyles.headerToolbarItemBtn} onClick={() => {
                            this.setState({showChangeTypeFliter: true})
                            // this.setState({startTimeSwitch: true})
                        }}>
                            <div style={recordStyles.headerToolbarItem}>
                                <div style={recordStyles.headerToolbarItemText}>{this.state.changeType.label}</div>
                                <Icon style={{marginTop: -3}} icon={'sjjt'} size={12} color={'rgba(0,0,0,0.7)'}/>
                            </div>
                        </div>
                        <div style={{...recordStyles.headerToolbarItemBtn, ...{marginLeft: 20}}} onClick={() => {
                            this.setState({showDateTypeFliter: true})
                            // this.setState({startTimeSwitch: true})
                        }}>
                            <div style={recordStyles.headerToolbarItem}>
                                <div style={recordStyles.headerToolbarItemText}>
                                    {
                                        this.state.dateType.start && this.state.dateType.end ?
                                            <div style={recordStyles.headerToolbarItemValueText}>

                                                <div>{this.state.dateType.start}</div>
                                                <div style={{width: 30, textAlign: "center"}}>至</div>
                                                <div>{this.state.dateType.end}</div>
                                                <div style={{width: 15, textAlign: "center", marginLeft: 5}}>
                                                    <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,0.7)'}/>
                                                </div>

                                                {/*<div style={styles.headerToolbarItemText}>{this.state.changeType.label}</div>*/}
                                                {/*<Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>*/}
                                            </div> :
                                            <div style={recordStyles.headerToolbarItem}>
                                                <div
                                                    style={recordStyles.headerToolbarItemText}>{this.state.dateType.label}</div>
                                                <Icon style={{marginTop: -3}} icon={'sjjt'} size={12}
                                                      color={'rgba(0,0,0,0.7)'}/>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={recordBodyStyles.container}>
                        {
                            this.state.list.map((item) => {
                                return (
                                    <div style={recordBodyStyles.recordContainer}>
                                        <div style={recordBodyStyles.recordleftContainer}>
                                            <div style={recordBodyStyles.recordleftTopContainer}>
                                                <span>{item.type}</span>
                                            </div>
                                            <div style={recordBodyStyles.recordleftBottomContainer}>
                                                <span>{item.create_time}</span>
                                            </div>
                                        </div>
                                        <div style={recordBodyStyles.recordRightContainer}>
                                            <div style={recordBodyStyles.recordRightValueContainer}>
                                                <span>{item.money}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <PullEndFooter list={this.state.list} showFoot={this.state.showFoot} onEndReached={() => {
                        this._onEndReached()
                    }}/>
                </div>
                {
                    this.state.showDialog ?
                        <Dialog type={this.state.type}
                            info={this.state.info}
                                onSuccess={() => {
                                    PublicUtils.showToast(this.state.type === 1 ? "转入成功" : "转出成功");
                                    this.setState({currpage: 1, showDialog: false}, () => {
                                        //获取数据
                                        this._init_();
                                    });
                                }
                                }
                                onCancel={() => {
                                    this.setState({showDialog: false});
                                }}/> : null
                }
                <MyPicker
                    title={'筛选'}
                    model={this.changeTypeModel}
                    display={this.state.showChangeTypeFliter}
                    onHiden={() => {
                        this.setState({showChangeTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        console.log(item);
                        this.setState({
                            changeType: item.value[0].value,
                            showChangeTypeFliter: false,
                            currpage: 1
                        }, () => {
                            this._fetchYEBRecord();
                        });
                    }}
                />
                <MyPicker
                    hasDatetimePicker={true}
                    title={'日期'}
                    model={this.dateTypeModel}
                    display={this.state.showDateTypeFliter}
                    onHiden={() => {
                        this.setState({showDateTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        // this.setState({dateType: item, showDateTypeFliter: false, currpage: 1}, () => {
                        //     this._fetchMemberChangeList();
                        // });
                        console.log(item);
                        this.setState({
                            dateType: (item.dateTime.start && item.dateTime.end) ? {
                                start: item.dateTime.start,
                                end: item.dateTime.end
                            } : item.value.length ? item.value[0].value : "",
                            currpage: 1,
                            start: item.dateTime.start,
                            end: item.dateTime.end,
                            list: []
                        }, () => {
                            this._fetchYEBRecord();
                        });
                    }}
                />
            </Container>
        )
    }
}
