/**
 * 彩票游戏导航
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../component/container";
import Image from "../../component/image";
import global from "../../global";
import PublicUtils from "../../utils/publicUtils";
import Icon from '../../utils/icon'
import model from "../../services/model";
import Http from "../../services/http";
import Loadding from "../../component/loadding";
import Storage from "../../services/storage";

export default class GameNavigation extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            data: []
        }
    }

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // PublicUtils.setParams({openMenu: this._openMenu});
        this._fetchLotteryList();
    }

    /**
     * 获取彩种列表
     * @private
     */
    _fetchLotteryList = () => {
        this.setState({showProgress: true});
        let parm = {};
        Http.request("lotteryLhcList", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if(global.ID == 'yy'){

                    let data = res.data;
                    let new_data1 = [];
                    let new_data2 = [];
                    let new_data3 = [];

                    for(let i = 0; i < data.length; i ++){
                        if(data[i].is_name !== "澳门六合彩(旧)" && data[i].is_name !== "香港六合彩"){
                            if(data[i].is_name.indexOf("三分")!==-1 || data[i].is_name.indexOf("五分")!== -1 || data[i].is_name.indexOf("十分")!== -1){
                                new_data1.push(data[i]);
                            }else{
                                new_data2.push(data[i])
                            }
                        }else {
                            new_data3.push(data[i])
                        }
                    }
                    data = new_data3.concat(new_data1)
                    data = data.concat(new_data2)
                    this.setState({data: data});
                  model.isgl = data;


                }else{
                    this.setState({data: res.data});
                  model.isgl = res.data;
                }




            } else {

            }
        });
    };

    /**
     * 进去彩票投注页面
     * @param id
     * @private
     */
    _enterLotteryPage(item) {
        let parm = item;
        switch (item.id) {
            case 7001:
                PublicUtils.navigate("PCDD", parm);
                break;
            case 70011:
                PublicUtils.navigate("PCDD", parm);
                break;
            case 6001:
                PublicUtils.navigate("PKS", parm);
                break;
            case 6002:
                PublicUtils.navigate("PKS", parm);
                break;
            case 60011:
                PublicUtils.navigate("PKS", parm);
                break;
            case 1001:
                PublicUtils.navigate("LHC", parm);
                break;
            case 10011:
                PublicUtils.navigate("LHC", parm);
                break;
            case 2003:
                PublicUtils.navigate("SSC", parm);
                break;
            case 2001:
                PublicUtils.navigate("SSC", parm);
                break;
            case 2002:
                PublicUtils.navigate("SSC", parm);
                break;
            case 20011:
                PublicUtils.navigate("SSC", parm);
                break;
            case 4002:
                PublicUtils.navigate("KLSF", parm);
                break;
            case 4001:
                PublicUtils.navigate("KLSF", parm);
                break;
            case 40011:
                PublicUtils.navigate("KLSF", parm);
                break;
            case 3002:
                PublicUtils.navigate("KS", parm);
                break;
            case 3003:
                PublicUtils.navigate("KS", parm);
                break;
            case 3001:
                PublicUtils.navigate("KS", parm);
                break;
            case 30011:
                PublicUtils.navigate("KS", parm);
                break;
            default:
                PublicUtils.showToast("游戏维护中...")

        }
    }

    render() {
        const styles = {
            row: {
                fontSize: 14,
                display: "flex",
                padding: 5,
                flexDirection: "row",
                flexWrap: "wrap",
            },
            box: {
                marginTop: 10,
                display: "flex",
                width: (model.clientWidth - 10) / 3,
                boxSizing: "border-box",
                padding: 5,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
            },
            innerbox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
            },
            text: {
                marginTop: 10,
                textAlign: "center"
            },
            gameBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            }
        };
        const icon = global.RES_URL + "/new/template/" + global.GAME_IMG_SKIN + "/sublottery/";
        const width = (model.clientWidth - 80) / 4.5;
        return (
            <Container style={{backgroundColor: "#fff"}} title={'彩票游戏'} showMenuButton={true}
                       menuArray={[{name: "近期开奖", icon: "jqkj", href: 'RecentLotteryOpen'}, {
                           name: "投注记录",
                           icon: "tzjl",
                           href: 'LotteryBetRecord'
                       }]}>
                {/*<div style={styles.row}>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(7001)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "pcdd.png"} width={width}/>*/}
                {/*<div style={styles.text}>PC蛋蛋</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(6001)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "pk10.png"} width={width}/>*/}
                {/*<div style={styles.text}>北京PK拾</div>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(6002)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "gdkl10.png"} width={width}/>*/}
                {/*<div style={styles.text}>幸运飞艇</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                {/*<div style={styles.row}>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(2003)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "xjssc.png"} width={width}/>*/}
                {/*<div style={styles.text}>新疆时时彩</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(2001)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "cqssc.png"} width={width}/>*/}
                {/*<div style={styles.text}>重庆时时彩</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(2002)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "tjssc.png"} width={width}/>*/}
                {/*<div style={styles.text}>天津时时彩</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                {/*<div style={styles.row}>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(4002)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "cqkl10.png"} width={width}/>*/}
                {/*<div style={styles.text}>重庆快乐十分</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(4001)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "gdkl10.png"} width={width}/>*/}
                {/*<div style={styles.text}>广东快乐十分</div>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(1001)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "lhc.png"} width={width}/>*/}
                {/*<div style={styles.text}>六合彩</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                {/*<div style={styles.row}>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(3002)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "gxks.png"} width={width}/>*/}
                {/*<div style={styles.text}>广西快三</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(3003)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "ahks.png"} width={width}/>*/}
                {/*<div style={styles.text}>安徽快三</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div style={styles.box}*/}
                {/*onClick={() => {*/}
                {/*this._enterLotteryPage(3001)*/}
                {/*}}>*/}
                {/*<div style={styles.innerbox}>*/}
                {/*<Image uri={icon + "ahks.png"} width={width}/>*/}
                {/*<div style={styles.text}>江苏快三</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}

                {
                    this.state.showProgress ? <Loadding/> :
                        <div style={styles.gameBox}>
                            {
                                this.state.data.map((item) => {
                                    return (
                                        <div style={styles.box}
                                             onClick={() => {
                                                 if (item.is_status) {
                                                     let parm = item;
                                                     PublicUtils.navigate(item.tmp, parm);

                                                   model.isid = item.id

                                                 } else {
                                                     PublicUtils.showToast(item.msg, {type: "FAILD"});
                                                 }
                                                 // this._enterLotteryPage(item)
                                             }}>
                                            <div style={styles.innerbox}>
                                                <Image hasPlaceholder={true} placeholderSize={{
                                                    width: width,
                                                    height: width
                                                }} uri={icon + item.is_img + ".png"} width={width}/>
                                                <div style={styles.text}>{item.is_name}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </Container>
        )
    }
}
