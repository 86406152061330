//按钮

import React, {Component} from 'react';
import App from "../App";
import PropTypes from 'prop-types';
import style from '../utils/config.scss'

class ActivityIndicator extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
    }

    static propTypes = {
        style: PropTypes.object,
    };
    static defaultProps = {};

    render() {

        const styles = {
            spinner: {
                width: "35px",
                color: "#fff",
                display: "block"
            }
        };
        return <img src={require('../assets/images/spinner.svg')} style={{...styles.spinner, ...this.props.style}}/>;
    }
}

export default ActivityIndicator;