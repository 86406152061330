//图片

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import model from "../../services/model";
import PublicUtils from "../../utils/publicUtils";
import Avatar from "../avatar";
import Lines from "./components/lines";
import Navigate from "../navigate";
import style from '../../utils/config.scss'
import Http from "../../services/http";
import global from "../../global";
import Storage from "../../services/storage";
import Image from "../image";

class DrawerPage extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            show: false,
        }


    }

    static propTypes = {};
    static defaultProps = {};

    componentWillReceiveProps = async (nextProps) => {
        await this._init_();
    };

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        //
        let user = localStorage.getItem('user');
        if(user){
            user = JSON.parse(user);
        }else{
            user = {};
        }
        this.setState({data: user});
    };

    /**
     * 确认退出当前账号
     * @private
     */
    _loginOutCheck = () => {
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要退出当前账号吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._loginOut();
                    }
                }
            ]
        });
    };
    /**
     * 退出当前账号
     * @private
     */
    _loginOut = () => {
        Http.request("loginOut", {}, (res) => {
            Storage.clearData();
            PublicUtils.navigate("/");

            // y8处理
            if(global.ID == 'y8'){
                global.syObj.setState({usedl:false})
            }

            // b8处理
            if(global.ID == 'b8'){
                global.syObj.setState({usedl:false})
            }

            // w7处理
            if(global.ID == 'w7'){
                global.syObj.setState({hasLogin:false})
            }

            this.hide();
        });
    };

    /**
     * 彩金大厅
     * @returns {Promise<void>}
     * @private
     */
    _memberBonus = async () => {
        // let userInfo = JSON.parse(await Storage.retrieveData("user"));
        // if (userInfo.pay_card) {
        //     this._checkLogin('RechargeChannel', {});
        // } else {
        //     PublicUtils.showToast("您必须绑定银行卡之后才能充值哦");
        // }
        this._checkLogin('MemberBonus', {});
    };
    /**
     * 判断是否登录
     * @param nextPage
     * @param Params
     * @returns {Promise<void>}
     * @private
     */
    _checkLogin = async (nextPage, Params) => {
        // alert(await PublicUtils._checkLogin());
        if (await PublicUtils._checkLogin()) {
            Params.fromPage = "Wallet";
            PublicUtils.navigate(nextPage,Params);
        } else {
            PublicUtils.navigate('Login');
        }
    };
    //个性皮肤
    _bindSkin = () => {
        let Params = {
            id: 9,
            title: "设置主题",
            value: ''
        };
        this._checkLogin('MeDetail', Params);
    };
    /**
     * 显示
     */
    show = async () => {
        this.setState({show: true});
        await this._init_();
    };

    /**
     * 隐藏
     */
    hide = () => {
        this.setState({show: false});
    };


    render() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
            },
            shadow: {
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 11,
                backgroundColor: "#000",
                opacity: 0.5
            },
            header: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 10,
                paddingRight: 10,
            },

            headerLeftContainer: {
                marginRight: 10
            },
            avatar: {
                width: 50,
                height: 50
            },
            headerRightContainer: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            },
            headerRightTopText: {
                color: style['primaryLight' + model.SKINNAME],
                marginBottom: 5,
                fontSize: 12
            },
            headerRightBottomText: {
                color: style['primaryLight' + model.SKINNAME],
                fontSize: 12
            },
            bodyContainer: {
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                left: 0,
                top: 0,
                width: 220,
                height: "100%",
                zIndex: 12,
                backgroundColor: "#f5f7f9"
            },
            body: {overflow:'auto'},
            menuItemWrapper: {
                // marginTop: 20
            },
            loginBox: {},
            loginText: {
                color: style['primaryLight' + model.SKINNAME],
                fontSize: 12
            },
        }

        return this.state.show ?
          <div style={styles.container}>
              <div onClick={() => {
                  this.hide();
              }} style={styles.shadow}/>
              <div style={styles.bodyContainer}>
                  <div style={styles.header}>
                      <div style={styles.headerLeftContainer}>



                        {
                          global.ID == 'b8'?    <Image style={styles.avatar} resizeMode='contain'
                                                       uri={this.state.data.head_img}/>:   <Avatar width={50} height={50} source={this.state.data.head_img}/>
                        }

                          {/*<Image style={styles.avatar} resizeMode='contain'*/}
                          {/*       source={require('../../assets/images/theme1/tx.png')}/>*/}
                      </div>
                      <div style={styles.headerRightContainer}>
                          {
                              PublicUtils._checkLogin() ?
                                <div style={styles.headerRightContainer}>
                                    {console.log(this.state.data)}
                                    <div
                                      style={styles.headerRightTopText}>账号：{this.state.data.user_account}</div>
                                    <div
                                      style={styles.headerRightBottomText}>余额：{this.state.data.is_balance && PublicUtils.subStringNum(this.state.data.is_balance)}</div>
                                </div>
                                :
                                <div style={styles.loginBox} type={1} onClick={() => {
                                    PublicUtils.navigate("Login")
                                }}>
                                    <div style={styles.loginText}>[您还没登录，请先登录]</div>
                                </div>
                          }
                      </div>
                  </div>
                  <div style={styles.body}>
                      <div style={styles.menuItemWrapper}>
                          <Navigate headerIcon={'new-home'} headerIconColor={'#9945d0'} headerIconSize={18}
                                    title={'主页'}
                                    onPress={() => {
                                        PublicUtils.navigate("Home");
                                    }}/>
                      </div>
                      {
                          global.ID === "aw" ?
                            <div style={styles.menuItemWrapper}>
                                <Navigate headerIcon={'libao'} headerIconColor={'#006349'} headerIconSize={18}
                                          title={'活动办理大厅'}
                                          onPress={() => {
                                              PublicUtils.linking("http://3657dt.com")
                                              // this._memberBonus();
                                          }}/>
                            </div> : null
                      }
                      {/*<div style={styles.menuItemWrapper}>*/}
                      {/*    <Navigate headerIcon={'tuandui'} headerIconColor={'#f97e08'} headerIconSize={22}*/}
                      {/*              title={'代理'}*/}
                      {/*              onPress={async () => {*/}
                      {/*                  if (await PublicUtils._checkLogin()) {*/}
                      {/*                      PublicUtils.navigate('Me', {refresh: true});*/}
                      {/*                  } else {*/}
                      {/*                      PublicUtils.navigate('Login');*/}
                      {/*                  }*/}
                      {/*              }}/>*/}
                      {/*</div>*/}
                      {
                          global.THEME === "default" ?

                            <div style={styles.menuItemWrapper}>
                                <Navigate headerIcon={'new-skin'} headerIconColor={'#f55075'} headerIconSize={22}
                                          title={'皮肤'}
                                          onPress={async () => {
                                              this._bindSkin();
                                          }}/>
                            </div>
                            : null
                      }
                      <Lines/>
                      {
                          PublicUtils._checkLogin() ?
                            <div style={{...styles.menuItemWrapper, ...{marginTop: 10}}}>
                                <Navigate headerIcon={'tuichu'} headerIconColor={'#008fbd'} headerIconSize={18}
                                          title={'退出'}
                                          onPress={() => {
                                              this._loginOutCheck()
                                          }}/>
                            </div> : null
                      }
                      <div style={{marginTop:10,backgroundColor:'white',textAlign:'center',fontSize:14,paddingBottom:10,paddingTop:10}}>
                          <div onClick={() => {PublicUtils.showToast("模式已切换，2秒后刷新页面"); setTimeout(()=>{Storage.storeData({line: ''}); window.location.reload();}, 2000)}}>切换至自动选择线路</div>
                      </div>
                  </div>
              </div>
          </div>
          : null

    }
}

export default DrawerPage;
