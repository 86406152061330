/**
 * 消息
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import Storage from "../services/storage";
import model from "../services/model";
import style from '../utils/config.scss'

export default class Message extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            noticeList: [],
            type: 1,//消息类型：1-私信 2-公告
            loadding: false
        };
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentDidMount = () => {
        // PublicUtils.setParams({openMenu: this._openMenu});
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this.state.type === 1 ? this._fetchMessageList() : this._fetchNoticeList();
    };

    /**
     * 获取私信列表
     * @private
     */
    _fetchMessageList = () => {
        this.setState({loadding: true});
        Http.request("getMessage", {}, (res) => {
            this.setState({loadding: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    this.setState({list: res.data.list});
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    };
    /**
     * 获取公告列表
     * @private
     */
    _fetchNoticeList = () => {
        this.setState({loadding: true});
        Http.request("getNotice", {}, (res) => {
            // alert(JSON.stringify(res));
            this.setState({loadding: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    this.setState({noticeList: res.data.txt});
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    };
    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this._fetchMessageList();
    };
    /**
     * 判断是否登录
     * @param nextPage
     * @param Params
     * @returns {Promise<void>}
     * @private
     */
    _checkLogin = async (nextPage, Params) => {
        if (await PublicUtils._checkLogin()) {
            Params.fromPage = "Me";
            Params.refresh = () => {
                this._init_();
            };
            PublicUtils.navigate(nextPage, {data: Params, type: this.state.type});
        } else {
            PublicUtils.navigate('Login', {nextPage: 'Me'});
        }
    };
    /**
     * 跳转页面
     * @private
     */
    _navigateToPage = (pageName, params) => {
        this._checkLogin(pageName, params);
    };
    /**
     * 删除消息
     * @private
     */
    _deleteMessage = (id) => {
        let params = {id: id};
        Http.request("deleteMessage", params, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 0) {
                    this.state.type === 1 ? this._fetchMessageList() : this._fetchNoticeList();
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };

    render() {
        const styles = {
            headerContainer: {
                padding: 10,
                // borderBottomWidth: 1,
                // borderBottomColor: "#f5f7f9"
            },
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                overflow: "hidden",
                border: "1px solid " + style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            headerToolbarItem: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            headerToolbarActiveItem: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            headerToolbarActiveItemText: {
                color: "#fff"
            },
            messageBodyContainer: {},
            messageBox: {
                margin: 5,
                display: "flex",
                padding: 10,
                // height: 60,
                border: "1px solid rgba(0,0,0,0.06)",
                borderRadius: 6,
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#fff"
            },
            messageHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            messageHeaderLeft: {
                display: "flex",
                flexDirection: "row",
                flex: 6,
                fontSize: 16,
                color: "#000",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            hasRead: {
                // color: "#a8a8a8",
                width: 10,
                height: 10,
                backgroundColor: "#ff4545",
                borderRadius: 10,
                marginLeft: 5
            },
            messageHeaderRight: {
                flex: 4,
                fontSize: 12,
                color: "#a8a8a8",
                textAlign: "right",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            messageBody: {
                marginTop:10
            },
            messageBodyText: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            messageBottomBox: {
                display: "flex",
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "space-between",
            },
            messageStatus: {
                fontSize: 12,
                color: "#a8a8a8"
            },
            messageTime: {
                fontSize: 12,
                color: "#a8a8a8"
            },
            activeMessage: {
                color: style['primaryColor' + model.SKINNAME]
            },
            noticeBox: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                margin: 5,
                height: 50,
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9",
                backgroundColor: "#fff"
            },
            noticeContent: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: 14
            },
            delBtnContainer: {
                position: "absolute",
                right: 0,
                top: 0,
                height: 70,
                width: 70,
            },
            delBtn: {
                display: "flex",
                backgroundColor: "rgba(245, 72, 68, 0.93)",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: 70,
                height: 70,
            },
            delBtnText: {
                color: "#fff",
                fontSize: 18
            },

        };
        return (
            <Container title={'我的消息'} style={{backgroundColor: "#f5f7f9"}}>
                <div style={styles.headerContainer}>
                    <div style={styles.headerToolbar}>
                        <div onClick={() => {
                            this.setState({type: 1}, () => {
                                this._fetchMessageList();
                            });
                        }}
                             style={{...styles.headerToolbarItem, ...this.state.type === 1 ? styles.headerToolbarActiveItem : null}}>
                            <div
                                style={{...styles.headerToolbarItemText, ...this.state.type === 1 ? styles.headerToolbarActiveItemText : null}}>私信
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({type: 2}, () => {
                                this._fetchNoticeList();
                            });
                        }}
                             style={{...styles.headerToolbarItem, ...this.state.type === 2 ? styles.headerToolbarActiveItem : null}}>
                            <div
                                style={{...styles.headerToolbarItemText, ...this.state.type === 2 ? styles.headerToolbarActiveItemText : null}}>公告
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{padding: 5}}>

                    {
                        (!this.state.loadding && this.state.type === 1 && !this.state.list.length || this.state.type === 2 && !this.state.noticeList.length) ?
                            <NoneData/> : null
                    }
                    {this.state.loadding ? <Loadding/> :
                        this.state.type === 1 ?

                            this.state.list.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => {
                                        this._navigateToPage("MessageDetail", item);

                                    }} style={styles.messageBox}>
                                        <div style={styles.messageHeader}>
                                            <div
                                                style={{...styles.messageHeaderLeft}}
                                                ellipsizeMode={'tail'}>
                                                {item.is_title && item.is_title}
                                                {
                                                    !item.is_status ? <div style={styles.hasRead}/> : null
                                                }
                                            </div>
                                            <div
                                                style={styles.messageHeaderRight}
                                                ellipsizeMode={'tail'}>{PublicUtils.timestampFormat(item.add_time && item.add_time, 2)}</div>
                                        </div>
                                        <div style={styles.messageBody}>
                                            <div style={styles.messageBodyText}
                                                 ellipsizeMode={'tail'}>{item.is_content && item.is_content}</div>
                                        </div>
                                        {/*<View style={styles.messageBottomBox}>*/}
                                        {/*<Theme.Text*/}
                                        {/*style={[styles.messageStatus, item.is_status === 0 ? styles.activeMessage : null]}>{item.is_status === 0 ? "未读" : "已读"}</Theme.Text>*/}
                                        {/*<Text*/}
                                        {/*style={styles.messageTime}>{PublicUtils.timestampFormat(item.add_time)}</Text>*/}
                                        {/*</View>*/}
                                    </div>
                                )
                            })

                            :

                            this.state.noticeList.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => {
                                        this._navigateToPage("MessageDetail", {
                                            id: "",
                                            is_title: "",
                                            add_time: "",
                                            is_content: item
                                        });

                                    }} style={styles.noticeBox}>
                                        <div style={styles.noticeContent}
                                             ellipsizeMode={'tail'}>{item}</div>
                                    </div>
                                )
                            })


                    }
                </div>
            </Container>
        )
    }
}
