/**
 * 个人中心详情页面
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import model from "../services/model";
import Storage from "../services/storage";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import history from "../services/history";
import Icon from '../utils/icon'
import MyButton from '../component/myButton'
import MyInput from '../component/myInput'
import DatePicker from 'react-mobile-datepicker';
import style from '../utils/config.scss'
import MyPicker from '../component/myPicker'
import MsgCode from '../component/msgCode'
import global from "../global";

export default class MeDetail extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            id: PublicUtils.getState(this.props.location.pathname).id,
            title: PublicUtils.getState(this.props.location.pathname).title,
            value: PublicUtils.getState(this.props.location.pathname).value,
            isSend: PublicUtils.getState(this.props.location.pathname).isSend,
            question: "",
            safeQuestionFliter: false,
            time: new Date(),
            birthday: "",
            isOpen: false,
            theme: model.SKINNAME
        };
        this.ThemeMap = [
            {
                name: "Skin1",
                color: "#e52842",
                checked: model.SKINNAME === "Skin1"
            },
            {
                name: "Skin2",
                color: "#188cbf",
                checked: model.SKINNAME === "Skin2"
            },
            {
                name: "Skin3",
                color: "#005c43",
                checked: model.SKINNAME === "Skin3"
            },
            {
                name: "Skin4",
                color: "#ff9317",
                checked: model.SKINNAME === "Skin4"
            },
            {
                name: "Skin5",
                color: "#ff707f",
                checked: model.SKINNAME === "Skin5"
            },
          {
            name: "Skin9",
            color: "#5C3DD4",
            checked: model.SKINNAME === "Skin9"
          },
        ]
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = async () => {
        this.userInfo = JSON.parse(Storage.retrieveData("user"));
        this._fetchMemberInfo()
        // this._checkTheme();
    };
    _changeText = (text) => {
        this.setState({value: text})
    };
     /**
     * 获取会员信息
     * @private
     */
      _fetchMemberInfo = () => {
        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        const parm = {
            show_phone: 1
        };
        Http.request("memberInfo", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    // this.setState({
                    //     sub_num: {value: res.data.info.sub_num || 0, isEdit: true},
                    //     msg_num: {value: res.data.info.msg_num || 0, isEdit: true},
                    //     is_integral: {value: res.data.info.is_integral || 0, isEdit: true},
                    //     grade_name: {value: res.data.info.grade_name || 0, isEdit: true},
                    //     showBirthHandsel: !!res.data.info.is_get_birth_handsel
                    // });
                    // Storage.storeData({is_get_birth_handsel: res.data.info.is_get_birth_handsel});
                    user.sub_num = res.data.info.sub_num;
                    user.msg_num = res.data.info.msg_num;
                    user.is_integral = res.data.info.is_integral;
                    user.grade_name = res.data.info.grade_name;
                    user.is_get_birth_handsel = res.data.info.is_get_birth_handsel;
                    user.reg_time = res.data.info.reg_time
                    this.setState({
                        showBirthday: !!res.data.info.user_birthday,
                        showPhone: !!res.data.info.user_phone,
                        showMail: !!res.data.info.user_mail,
                        showQQ: !!res.data.info.user_qq,
                        showYEB: !!res.data.info.yuebao_open
                    });
                    PublicUtils.saveUserInfo({user: user});
                }
            }
        });
    };
    /**
     * 获取当前主题
     * @returns {Promise<void>}
     */
    // _checkTheme = async () => {
    //     const themeName = await Storage.retrieveData("theme");
    //     this.setState({theme: themeName})
    // };
    /**
     * 保存会员信息
     * @returns {Promise<void>}
     */
    _saveUserInfo = () => {
        let parm = {};
        let isOk = true;
        switch (this.state.id) {
            case 1:
                parm.user_name = this.state.value;
                this.userInfo.user_name = this.state.value;
                if (!model.inputRule.name.rule.test(this.userInfo.user_name)) {
                    PublicUtils.showToast(model.inputRule.name.ruleMsg);
                    isOk = false;
                }
                break;
            case 2:
                parm.user_birthday = this.state.birthday;
                this.userInfo.user_birthday = this.state.birthday;
                if (!this.userInfo.user_birthday) {
                    PublicUtils.showToast("请选择您的出生日期");
                    isOk = false;
                }
                break;
            case 3:
                parm.user_phone = this.state.value;
                this.userInfo.user_phone = this.state.value;
                if (!model.inputRule.phone.rule.test(this.state.value)) {
                    PublicUtils.showToast(model.inputRule.phone.ruleMsg);
                    isOk = false;
                }
                break;
            case 4:
                parm.user_mail = this.state.value;
                this.userInfo.user_mail = this.state.value;
                if (!model.inputRule.email.rule.test(this.state.value)) {
                    PublicUtils.showToast(model.inputRule.email.ruleMsg);
                    isOk = false;
                }
                break;
            case 5:
                parm.user_qq = this.state.value;
                this.userInfo.user_qq = this.state.value;
                if (!model.inputRule.qq.rule.test(this.state.value)) {
                    PublicUtils.showToast(model.inputRule.qq.ruleMsg);
                    isOk = false;
                }
                break;
            case 6:
                parm.user_question = this.state.question;
                parm.user_answer = this.state.value;
                this.userInfo.user_question = this.state.question;
                this.userInfo.user_answer = this.state.value;
                if (!this.state.value) {
                    PublicUtils.showToast("请填写密保答案！");
                    isOk = false;
                }
                break;
            case 7:
                this._saveLoginPwd();
                isOk = false;
                break;
            case 8:
                this._saveFundPwd();
                isOk = false;
                break;
        }
        if (isOk) {
            Http.request("memberEdit", parm, (res) => {
                if (res.code === 300) {
                    Storage.clearData();
                    PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
                }
                if (res.code === 200) {
                    if (res.data.status) {
                        PublicUtils.showToast("修改成功");
                        Storage.storeData({user: JSON.stringify(this.userInfo)});
                        // this.commonData.loginData.user.user_question = this.userInfo.user_question.value;
                        // this.commonData.loginData.user.user_answer = this.userInfo.user_answer.value;
                        // this.commonData.loginData.user.user_name = this.userInfo.user_name.value;
                        // this.commonData.loginData.user.user_birthday = this.userInfo.user_birthday.value;
                        // this.commonData.loginData.user.user_phone = this.userInfo.user_phone.value;
                        // this.commonData.loginData.user.user_mail = this.userInfo.user_mail.value;
                        // this.commonData.loginData.user.user_qq = this.userInfo.user_qq.value;
                        // this.commonData.saveLocalStorage();
                        PublicUtils.goBack();
                    } else {
                        PublicUtils.showToast(res.data.message);
                    }
                }
            });
        }

    };

    /**
     * 修改登录密码
     */
    _saveLoginPwd() {
        if (!this.state.old_password) {
            PublicUtils.showToast("请输入原始密码");
            return;
        }
        if (!model.inputRule.password.rule.test(this.state.password)) {
            PublicUtils.showToast(model.inputRule.password.ruleMsg);
            return;
        }
        if (this.state.password !== this.state.checkPassword) {
            PublicUtils.showToast("两次密码输入不一致哦！");
            return;
        }
        let parm = {
            old_password: this.state.old_password,
            login_password: this.state.password,
            login_password2: this.state.checkPassword
        };
        if (this.userInfo.is_send === 1) {
            parm.code = this.state.code;
        }
        Http.request("memberEdit", parm, (response) => {
            if (response.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (response.code === 200) {
                if (response.data.status) {
                    PublicUtils.showToast("修改成功");
                    PublicUtils.goBack(null);
                } else {
                    PublicUtils.showToast(response.data.message);
                }
            }
        });
    }

    /**
     * 修改取款密码
     */
    _saveFundPwd() {
        if (!this.state.old_password && this.userInfo.safe_password) {
            PublicUtils.showToast("请输入原始密码");
            return;
        }
        if (!model.inputRule.pay_password.rule.test(this.state.password)) {
            PublicUtils.showToast(model.inputRule.pay_password.ruleMsg);
            return;
        }
        if (this.state.password !== this.state.checkPassword) {
            PublicUtils.showToast("两次密码输入不一致哦！");
            return;
        }
        let parm = {
            // safe_old: this.state.old_password,
            safe_password: this.state.password,
            safe_password2: this.state.checkPassword
        };
        if (this.userInfo.is_send === 1) {
            parm.code = this.state.code;
        }
        if (this.userInfo.safe_password) {
            parm.safe_old = this.state.old_password;
        }
        Http.request("memberEdit", parm, (response) => {
            if (response.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (response.code === 200) {
                if (response.data.status) {
                    PublicUtils.showToast("修改成功");
                    PublicUtils.updateUserInfo({safe_password: this.state.password});
                    PublicUtils.goBack(null);
                } else {
                    PublicUtils.showToast(response.data.message);
                }
            }
        });
    }

    formatDate = (date) => {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let second = date.getSeconds();
        second = minute < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d;
    };
    handleClick = () => {
        this.setState({isOpen: true});
    }

    handleCancel = () => {
        this.setState({isOpen: false});
    }

    handleSelect = (time) => {
        this.setState({birthday: this.formatDate(time), isOpen: false});
        // this._checkForm({birthday: this.formatDate(time)});
    }

    render() {
        const styles = {
            iptBox: {
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 10,
                marginRight: 10,
            },

            iptBoxWrapper: {
                display: "flex",
                flexDirection: "column",
                // height: 45,
                justifyContent: "center",
                // alignItems: "center",
                // marginTop: 1,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10,
                // paddingRight: 10
                padding: "3px 10px"
            },
            iptBoxWrapperYY:{
                display: "flex",
                justifyContent: "center",
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10,
                padding: "3px 10px"
            },
            iconBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30,
                fontSize: 14
            },
            iconBoxYY: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 40,
                fontSize: 14,
                marginRight:10
            },
            textIpt: {
                display: "flex",
                flex: 1,
                height: 40,
                lineHeight: "40px",
                fontSize: 14
            },
            btnBox: {
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            datepicker: {
                borderWidth: 0,
                paddingLeft: 0,
                marginLeft: 0
            },
            themeBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                paddingTop: 10,
                paddingBottom: 10,
            },
            themeItem: {
                display: "flex",
                width: 50,
                height: 50,
                margin: 10,
                borderRadius: 3,
                elevation: 5,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            loginTips: {
                display: "flex",
                backgroundColor: "#fff",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 8,
                paddingBottom: 8,
            },
            loginTipsText: {
                color: "#ff380e",
                fontSize: 14,
                marginLeft: 5,
                lineHeight: "25px",
            },
            headerContainer: {
                display: "flex",
                backgroundColor: "#fff",
                width: "100%",
                height: 50,
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 10
            },
            headerbs: {
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            headerText: {
                marginLeft: 10,
                fontSize: 16
            },
        };
        return (
            <Container title={this.state.title}>
                <div style={styles.headerContainer}>
                    <div style={styles.headerbs}/>
                    <div style={styles.headerText}>用戶账号：{ this.userInfo.user_account}</div>
                </div>
                {/*{*/}
                {/*    this.state.id <= 5 &&*/}
                {/*    <div style={styles.loginTips}>*/}
                {/*        /!*<Icon icon={'tips'} color={'#fff'} size={15}/>*!/*/}
                {/*        <div style={styles.loginTipsText}>温馨提示：此项设置成功之后无法修改！</div>*/}
                {/*    </div>*/}
                {/*}*/}
                {/**真实姓名**/}
                {
                    this.state.id === 1 &&
                    <div style={styles.iptBox}>

                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-realname'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>真实姓名</span>
                            </div>
                            <MyInput placeholder="请输入您的真实姓名" style={styles.textIpt}
                                     onChangeText={(text) => this._changeText(text)}/>
                        </div>
                    </div>
                }
                {/**生日**/}
                {
                    this.state.id === 2 &&
                    <div style={styles.iptBox}>

                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-birthday'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>生日</span>
                            </div>
                            <div style={styles.textIpt}>
                                <MyInput onPress={this.handleClick} value={this.state.birthday} readOnly={true}
                                         placeholder="请选择您的生日" style={styles.textIpt}/>
                                {/*<DatePicker*/}
                                {/*style={styles.datepicker}*/}
                                {/*date={this.state.value && this.state.value}*/}
                                {/*mode="date"*/}
                                {/*placeholder="请选择日期"*/}
                                {/*format="YYYY-MM-DD"*/}
                                {/*minDate="1900-01-01"*/}
                                {/*maxDate="2000-01-01"*/}
                                {/*confirmBtnText="确定"*/}
                                {/*cancelBtnText="取消"*/}
                                {/*showIcon={false}*/}
                                {/*customStyles={{*/}
                                {/*dateInput: {*/}
                                {/*marginLeft: 0,*/}
                                {/*borderWidth: 0,*/}
                                {/*},*/}
                                {/*dateTouchBody: {*/}
                                {/*textAlign: "left"*/}
                                {/*},*/}
                                {/*datePickerCon: {*/}
                                {/*backgroundColor: "red"*/}
                                {/*},*/}
                                {/*datePicker: {*/}
                                {/*backgroundColor: "red"*/}
                                {/*}*/}
                                {/*// ... You can check the source to find the other keys.*/}
                                {/*}}*/}
                                {/*onDateChange={(date) => {*/}
                                {/*this.setState({value: date})*/}
                                {/*}}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                }
                {/**手机号码**/}
                {
                    this.state.id === 3 &&
                    <div style={styles.iptBox}>

                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-phone'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>手机号码</span>
                            </div>
                            <MyInput placeholder="请输入您的手机号码" style={styles.textIpt}
                                     onChangeText={(text) => this._changeText(text)}/>
                        </div>
                    </div>
                }
                {/**邮箱**/}
                {
                    this.state.id === 4 &&
                    <div style={styles.iptBox}>

                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-email'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>电子邮箱</span>
                            </div>
                            <MyInput placeholder="请输入您的邮箱地址" style={styles.textIpt}
                                     onChangeText={(text) => this._changeText(text)}/>
                        </div>
                    </div>
                }
                {/**QQ或者微信**/}
                {
                    this.state.id === 5 &&
                    <div style={styles.iptBox}>
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-qq'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>QQ/微信</span>
                            </div>
                            <MyInput placeholder="请输入您的QQ或者微信号码" style={styles.textIpt}
                                     onChangeText={(text) => this._changeText(text)}/>
                        </div>
                    </div>
                }
                {/**密保和答案**/}
                {
                    this.state.id === 6 &&
                    <div style={styles.iptBox}>
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-question'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                {/*<span>密保问题</span>*/}
                                <MyInput value={this.state.question} onPress={() => {
                                    this.setState({safeQuestionFliter: true});
                                }} readOnly={true} placeholder="请选择密保问题" style={styles.textIpt}
                                         onChangeText={(text) => this._changeText(text)}/>
                                <Icon icon={'sjjt'} color={'#333'} size={16}/>
                            </div>
                            {/*<Picker style={styles.textIpt}*/}
                            {/*textStyle={{fontSize: 12}}*/}
                            {/*selectedValue={this.state.question}*/}
                            {/*onValueChange={(value) => {*/}
                            {/*this.setState({question: value});*/}
                            {/*}}>*/}
                            {/*{model.safeQuestionOption.map((item) => {*/}
                            {/*return (*/}
                            {/*<Picker.Item label={item.name} value={item.name} color="#444"*/}
                            {/*itemStyle={styles.pickerItem}/>*/}
                            {/*)*/}
                            {/*})}*/}
                            {/*</Picker>*/}
                        </div>
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-answer'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>密保答案</span>
                            </div>
                            <MyInput placeholder="请输入密保答案" style={styles.textIpt}
                                     onChangeText={(text) => this._changeText(text)}/>
                        </div>
                    </div>
                }
                {/**修改登录密码**/}
                {
                    this.state.id === 7 &&
                    <div style={styles.iptBox}>
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-password'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>原始密码</span>
                            </div>
                            <MyInput type={'password'} placeholder="请输入原始登录密码" style={styles.textIpt}
                                onChangeText={(text) => {
                                this.setState({old_password: text});
                            }}/>
                        </div>

                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-password'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>新密码</span>
                            </div>
                            <MyInput type={'password'} placeholder="请输入新的登录密码" style={styles.textIpt}
                                     onChangeText={(text) => {
                                         this.setState({password: text});
                                     }}/>
                        </div>
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-password'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>确认密码</span>
                            </div>
                            <MyInput type={'password'} placeholder="请再次输入新的登录密码" style={styles.textIpt}
                                     onChangeText={(text) => {
                                         this.setState({checkPassword: text});
                                     }}/>
                        </div>
                        {
                            this.state.isSend === 1 ?

                                <div>
                                    <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                                        <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                            <Icon icon={'new-phone'} size={18} style={{marginTop: -4, marginRight: 3}}
                                                  color={style['primaryColor' + model.SKINNAME]}/>
                                            <span>手机号码</span>
                                        </div>
                                        <MyInput type={'text'} placeholder="请输入手机号码" style={styles.textIpt}
                                                 value={this.userInfo.user_phone} readOnly={true}/>
                                    </div>
                                    <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                                        <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                            <Icon icon={'new-password'} size={18}
                                                  style={{marginTop: -4, marginRight: 3}}
                                                  color={style['primaryColor' + model.SKINNAME]}/>
                                            <span>验证码</span>
                                        </div>
                                        <MyInput type={'password'} placeholder="请输入短信验证码" style={styles.textIpt}
                                                 onChangeText={(text) => {
                                                     this.setState({code: text});
                                                 }}/>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <MsgCode user_account={this.userInfo.user_account}
                                                     type={2}
                                                     user_mobile={this.userInfo.user_phone}/>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </div>
                }
                {/**修改取款密码**/}
                {
                    this.state.id === 8 &&
                    <div style={styles.iptBox}>
                        {
                            this.userInfo.safe_password ?
                                <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                                    <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                        <Icon icon={'new-password'} size={18} style={{marginTop: -4, marginRight: 3}}
                                              color={style['primaryColor' + model.SKINNAME]}/>
                                        <span>原始密码</span>
                                    </div>
                                    <MyInput type={'password'} placeholder="请输入原始取款密码" style={styles.textIpt}
                                             onChangeText={(text) => {
                                                 this.setState({old_password: text});
                                             }}/>
                                </div> : null
                        }
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-password'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>新密码</span>
                            </div>
                            <MyInput type={'password'} placeholder="请输入新的取款密码" style={styles.textIpt}
                                     onChangeText={(text) => {
                                         this.setState({password: text});
                                     }}/>
                        </div>
                        <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                            <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                <Icon icon={'new-password'} size={18} style={{marginTop: -4, marginRight: 3}}
                                      color={style['primaryColor' + model.SKINNAME]}/>
                                <span>确认密码</span>
                            </div>
                            <MyInput type={'password'} placeholder="请再次输入新的取款密码" style={styles.textIpt}
                                     onChangeText={(text) => {
                                         this.setState({checkPassword: text});
                                     }}/>
                        </div>
                        {
                            this.state.isSend === 1 ?

                                <div>
                                    <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                                        <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                            <Icon icon={'new-phone'} size={18} color={'#546af5'}/>
                                        </div>
                                        <MyInput type={'text'} placeholder="手机号码" style={styles.textIpt}
                                                 value={this.userInfo.user_phone} readOnly={true}/>
                                    </div>
                                    <div style={global.ID == "yy"?styles.iptBoxWrapperYY:styles.iptBoxWrapper}>
                                        <div style={global.ID == "yy"?styles.iconBoxYY:styles.iconBox}>
                                            <Icon icon={'new-password'} size={18} color={'#546af5'}/>
                                        </div>
                                        <MyInput type={'password'} placeholder="请输入短信验证码" style={styles.textIpt}
                                                 onChangeText={(text) => {
                                                     this.setState({code: text});
                                                 }}/>
                                        <MsgCode user_account={this.userInfo.user_account}
                                                 type={3}
                                                 user_mobile={this.userInfo.user_phone}/>
                                    </div>
                                </div> : null
                        }
                    </div>
                }
                {/**修改主题**/}
                {
                    this.state.id === 9 &&
                    <div style={styles.themeBox}>
                        {this.ThemeMap.map((theme) => {
                            return (
                                <div style={{...styles.themeItem, ...{backgroundColor: theme.color}}} onClick={() => {
                                    Storage.storeData({theme: theme.name});
                                    model.SKINNAME = theme.name;
                                    this.setState({theme: theme.name});
                                }}>
                                    {
                                        theme.name === this.state.theme &&
                                        <Icon icon={'circle-yes'} size={18} color={'#fff'}/>
                                    }
                                </div>)
                        })}
                    </div>
                }
                <div style={styles.btnBox}>
                    <MyButton title={'完成'} onPress={() => {
                        this._saveUserInfo();
                    }}/>
                </div>
                <MyPicker rowCount={1}
                          title={'密保问题'}
                          model={model.safeQuestionOption}
                          display={this.state.safeQuestionFliter}
                          onHiden={() => {
                              this.setState({safeQuestionFliter: false});
                          }}
                          onItemPress={(item) => {
                              this.setState({safeQuestionFliter: false, question: item.value[0].value.label});
                              // this._changePlatform(item.value);
                              // this.setState({playType: item, showPlayTypeFliter: false, currpage: 1}, () => {
                              //     this._fetchList();
                              // });
                          }}
                />

                <DatePicker
                    theme={'ios'}
                    value={this.state.time}
                    isOpen={this.state.isOpen}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}/>
            </Container>
        )
    }
}
