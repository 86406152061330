//图片

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Image from './image'
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import style from '../utils/config.scss'

class FloatBtn extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {}
    }

    static propTypes = {};
    static defaultProps = {};

    render() {
        const styles = {
            floatBox: {
                position: "fixed",
                bottom: 110,
                right: 30,
                height: 50,
                width: 50,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.4)",

            }
        };
        return <div style={styles.floatBox} onClick={() => {
            PublicUtils.linking(model.serviceUrl, "_blank");
        }}>
            <Image style={{width: 30}} resizeMode='contain'
                   uri={require('../assets/images/kefu.gif')}/>
        </div>
    }
}

export default FloatBtn;