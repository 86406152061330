/**
 * 注册图标（首页）
 */
import React from 'react'
import PropTypes from 'prop-types';
import PublicUtils from "../../../utils/publicUtils";
import Image from "../../../component/image";
import global from "../../../global";
export default class TopIcon extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            hasLogin: true,
        };
    }

    static propTypes = {
        navigation: PropTypes.object
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {

        // this.didBlurSubscription = this.props.navigation.addListener(
        //     'didFocus',
        //     payload = async () => {
        //         // alert(username);
        //         // this.setState({refresh: true});
        //         this._isLogin();
        //     }
        // );
    }
    _isLogin = async () => {
        const hasLogin = await PublicUtils._checkLogin();
        if (this.state.hasLogin !== hasLogin) {
            this.setState({hasLogin: hasLogin});
        }
    };

    /**
     * 跳转到pc端
     * @private
     */
    _pcUrlPage = () => {
        PublicUtils.linking(this.props.pcUrl, "_blank");
    };

    render() {

        // 图片处理
        let imgurl,imgurl2,imgurl3;
        switch (global.ID) {
            case 'x9':
                imgurl = <Image style={{ width: 70}} resizeMode='contain'
                                uri={'https://appnew.hdyb33.com/code/x9/yxtb/pc-enter2.png'}/>;
                imgurl2 = <Image style={{ width: 70}} resizeMode='contain'
                                 uri={'https://appnew.hdyb33.com/code/x9/yxtb/login-btn2.png'}/>;
                imgurl3 = <Image style={{ width: 70}} resizeMode='contain'
                                uri={'https://appnew.hdyb33.com/code/x9/yxtb/enr-btn2.png'}/>;
                break;
            case 'w7':
                imgurl = <Image style={{ width: 70}} resizeMode='contain'
                                uri={'https://appnew.hdyb33.com/code/w7/tb/diannaoduan.png'}/>;
                imgurl2 = <Image style={{ width: 70}} resizeMode='contain'
                                 uri={'https://appnew.hdyb33.com/code/w7/tb/denglu.png'}/>;
                imgurl3 = <Image style={{ width: 70}} resizeMode='contain'
                                 uri={'https://appnew.hdyb33.com/code/w7/tb/zhuce.png'}/>;
                break;
            default:
                imgurl = <Image style={styles.headerRightImg} resizeMode='contain'
                                uri={require('../../../assets/images/theme1/pc-enter.png')}/>;
                imgurl2 = <Image style={styles.headerRightImg} resizeMode='contain'
                                 uri={require('../../../assets/images/theme1/login-btn.png')}/>;
                imgurl3 = <Image style={styles.headerRightImg} resizeMode='contain'
                                uri={require('../../../assets/images/theme1/enr-btn.png')}/>;
                break;
        }

        return this.props.hasLogin ?
            <div style={styles.headerRightContainer}>
                <div type={1} onClick={() => {
                    this._pcUrlPage();
                }}>
                    {imgurl}
                </div>
            </div> :
            <div style={styles.headerRightContainer}>
                <div type={1} onClick={() => {
                    PublicUtils.navigate("Login");
                }}>
                    {imgurl2}
                </div>
                <div type={1} onClick={() => {
                    PublicUtils.navigate("Register");
                }}>
                    {imgurl3}
                </div>
            </div>

    }
}
const styles = {
    headerRightContainer: {
        // backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    leftSlideBtn: {
        display: "flex",
        width: 35,
        height: 35,
        borderRadius: 35,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.1)"
    },
    logoImg: {
        width: 100,
        height: 60,
        marginLeft: 10
    },
    headerRightImg: {
        width: 70,
        marginLeft: 10
    },
};
