/**
 * 彩票近期开奖
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import MyPicker from "../component/myPicker";
import Icon from '../utils/icon'
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import Storage from "../services/storage";
import Loadding from "../component/loadding";
import Image from "../component/image";
import NoneData from "../component/noneData";
import history from "../services/history";
import PullEndFooter from "../component/pullEndFooter";
import dice1 from "../assets/images/dice1.png";
import dice2 from "../assets/images/dice2.png";
import dice3 from "../assets/images/dice3.png";
import dice4 from "../assets/images/dice4.png";
import dice5 from "../assets/images/dice5.png";
import dice6 from "../assets/images/dice6.png";

export default class RecentLotteryOpen extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.lotterModel = [];
        // this.is_code = PublicUtils.getState(this.props.location.pathname).code;



      let isgl =  model.isgl;

      this.is_code =  model.isid;


      if(!this.is_code){
        this.is_code = isgl[0].id
      }



      let tempList = [];
      for(let i=0;i<isgl.length;i++){

        tempList.push({label: isgl[i].is_name, value: isgl[i].id, name:  isgl[i].tmp.toUpperCase()});

        if(this.is_code == isgl[i].id){

          this.lotteryType=  {label: isgl[i].is_name, value: isgl[i].id, name:  isgl[i].tmp.toUpperCase()}
        }
      }
      this.lotterModel = tempList;


      // if(!this.is_code){
        //     this.is_code = JSON.parse(localStorage.getItem("state")).RecentLotteryOpen;
        //     this.is_code = this.is_code ? parseInt(this.is_code) : this.is_code;
        // }
        // for (let i = 1; i < model.lotteryCodeList.length; i++) {
        //     this.lotterModel.push(model.lotteryCodeList[i]);
        //     if (this.is_code && this.is_code === model.lotteryCodeList[i].value) {
        //         this.lotteryType = model.lotteryCodeList[i];
        //     }
        // }





        this.state = {
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            refreshing: false,//上拉刷新,
            total: 0,//总页数
            currpage: 1,//当前页
            list: [],
            lotteryType: this.is_code ? this.lotteryType : this.lotterModel[0],
            templateType:this.lotteryType.name,
            showlotteryTypeFliter: false,
            showProgress: false
        }

    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {

      this._init_();



    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
      this._fetchList();
    };

    /**
     * 获取开奖结果
     */
    _fetchList() {
      let self  = this;
        this.setState({list: [], showFoot: 2, showProgress: true});
        let parm = {
            is_code: this.state.lotteryType.value,
        };
        Http.request("lotteryNumberList", parm, (res) => {
            this.setState({refreshing: false, showFoot: 1, showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                this.props.navigation.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {

                  self.setState({list:  res.data.number});


                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    }

    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {

        this._fetchList();
    };


    /**
     * 开奖结果总和
     * @param numbers
     * @returns {number}
     */
    _total(numbers) {
        let numList = numbers.split(',');
        let total = 0;
        for (let i = 0; i < numList.length; i++) {
            total += Number(numList[i]);
        }
        return total;
    }

    /**
     *色子
     * @private
     */
    _renderDice = (number) => {
        if (number == 1) {
            return (
                <Image style={styles.quickEntryImg} resizeMode='contain'
                       uri={require('../assets/images/dice1.png')}/>
            )
        }
        if (number == 2) {
            return (
                <Image style={styles.quickEntryImg} resizeMode='contain'
                       uri={require('../assets/images/dice2.png')}/>
            )
        }
        if (number == 3) {
            return (
                <Image style={styles.quickEntryImg} resizeMode='contain'
                       uri={require('../assets/images/dice3.png')}/>
            )
        }
        if (number == 4) {
            return (
                <Image style={styles.quickEntryImg} resizeMode='contain'
                       uri={require('../assets/images/dice4.png')}/>
            )
        }
        if (number == 5) {
            return (
                <Image style={styles.quickEntryImg} resizeMode='contain'
                       uri={require('../assets/images/dice5.png')}/>
            )
        }
        if (number == 6) {
            return (
                <Image style={styles.quickEntryImg} resizeMode='contain'
                       uri={require('../assets/images/dice6.png')}/>
            )
        }
    };
    /**
     * 渲染列表
     * @private
     */
    _renderList = (item) => {
        if (this.state.templateType === "PCDD") {
            return (
                <div style={styles.itemBox}>
                    <div style={styles.itemHeader}>
                        <div style={styles.itemHeaderSy}>第</div>
                        <div style={styles.itemHeaderNo}>{item.is_no}</div>
                        <div style={styles.itemHeaderSy}>期</div>
                    </div>
                    <div style={styles.itemBody}>
                        {
                            item.is_number.split(',').map((number, i) => {
                                let balls;
                                if (i < item.is_number.split(',').length - 1) {
                                    balls = <div key={i} style={styles.subItemBall}>
                                        <div
                                            style={styles.itemBall}>{number}</div>
                                        <div
                                            style={styles.itemSymbol}>+
                                        </div>
                                    </div>;
                                }
                                if (i === item.is_number.split(',').length - 1) {
                                    balls = <div key={i} style={styles.subItemBall}>
                                        <div
                                            style={styles.itemBall}>{number}</div>
                                        <div
                                            style={styles.itemSymbol}>=
                                        </div>
                                    </div>;
                                }
                                return balls;
                            })
                        }
                        <div style={styles.itemBall}>{this._total(item.is_number)}</div>
                    </div>
                </div>
            )
        }
        if (this.state.templateType === "PK10" || this.state.templateType === "PKS") {
            return (
                <div style={styles.itemBox}>
                    <div style={styles.itemHeader}>
                        <div style={styles.itemHeaderSy}>第</div>
                        <div style={styles.itemHeaderNo}>{item.is_no}</div>
                        <div style={styles.itemHeaderSy}>期</div>
                    </div>
                    <div style={styles.itemBody}>
                        {
                            item.is_number.split(',').map((number, i) => {
                                return (
                                    <div key={i} style={{...styles.pk, ...styles["color" + number]}}>{number}</div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        if (this.state.templateType === "SSC"|| this.state.templateType === "KLSF") {
            return (
                <div style={styles.itemBox}>
                    <div style={styles.itemHeader}>
                        <div style={styles.itemHeaderSy}>第</div>
                        <div style={styles.itemHeaderNo}>{item.is_no}</div>
                        <div style={styles.itemHeaderSy}>期</div>
                    </div>
                    <div style={styles.itemBody}>
                        {
                            item.is_number.split(',').map((number, i) => {
                                return (
                                    <div key={i} style={{...styles.itemBall}}>{number}</div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        if (this.state.templateType === "KS") {
            return (
                <div style={styles.itemBox}>
                    <div style={styles.itemHeader}>
                        <div style={styles.itemHeaderSy}>第</div>
                        <div style={styles.itemHeaderNo}>{item.is_no}</div>
                        <div style={styles.itemHeaderSy}>期</div>
                    </div>
                    <div style={styles.itemBody}>
                        {
                            item.is_number.split(',').map((number, i) => {
                                return this._renderDice(number,i);
                            })
                        }
                    </div>
                </div>
            )
        }


        if (this.state.templateType === "LHC") {
            return (
              <div style={styles.itemBox}>
                  <div style={styles.itemHeader}>
                      <div style={styles.itemHeaderSy}>第</div>
                      <div style={styles.itemHeaderNo}>{item.is_no}</div>
                      <div style={styles.itemHeaderSy}>期</div>
                  </div>
                  <div style={styles.itemBody}>
                      {
                          item.is_number.split(',').map((number, i) => {

                              // 背景颜色
                              let bcl = '';
                              if(number == 1 || number == 2 || number == 12 || number == 13 || number == 23 || number == 24 || number == 34 || number == 35 || number == 45 || number == 46 || number == 7 || number == 8 || number == 18 || number == 19 || number == 29 || number == 30 || number == 40){
                                  bcl = "rgba(246, 48, 85, 0.94)";
                              }

                              if(number == 11 || number == 21 || number == 22 || number == 32 || number == 33 || number == 43 || number == 44 || number == 5 || number == 6 || number == 16 || number == 17 || number == 27 || number == 28 || number == 38 || number == 39 || number == 49){
                                  bcl = "#28B84A";
                              }

                              if(number == 31 || number == 41 || number == 42 || number == 3 || number == 4 || number == 14 || number == 15 || number == 25 || number == 26 || number == 36 || number == 37 || number == 47 || number == 48 || number == 9 || number == 10 || number == 20){
                                  bcl = "#0C9DE8";
                              }



                              return (
                                <div key={i} style={{
                                    width: 18,
                                    height: 18,
                                    borderRadius: 18,
                                    color: "#fff",
                                    lineHeight: "18px",
                                    textAlign: "center",
                                    fontSize: 10,
                                    marginLeft: 3, backgroundColor: bcl}}>{number}</div>
                              )
                          })
                      }
                  </div>
              </div>
            )
        }
    };

    render() {
        return (
            <Container title={'彩票近期开奖'} style={{backgroundColor: "#fff"}}>
                <div style={styles.headerToolbarItemBtnWrapper}>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({showlotteryTypeFliter: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.lotteryType.label}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                </div>

                {/*{  */}
                {/*this.state.showProgress &&*/}
                {/*<View style={styles.footer}>*/}
                {/*<View style={styles.footerBody}>*/}
                {/*<Text style={styles.footerBodyText}>*/}
                {/*努力加载中...*/}
                {/*</Text>*/}
                {/*</View>*/}
                {/*<View style={styles.footerPadding}/>*/}
                {/*</View>*/}
                {/*}*/}
                <div>
                    {
                        this.state.list.map((item) => {
                            return this._renderList(item) || null;
                        })
                    }

                </div>
                <MyPicker
                    title={'选择彩种'}
                    model={this.lotterModel}
                    display={this.state.showlotteryTypeFliter}
                    onHiden={() => {
                        this.setState({showlotteryTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({lotteryType: item.value[0].value, showlotteryTypeFliter: false,templateType:item.value[0].value.name}, () => {
                            this._fetchList();
                        });
                    }}
                />
                <PullEndFooter list={this.state.list} showFoot={this.state.showFoot} onEndReached={() => {
                    this._onEndReached();
                }}/>
            </Container>
        )
    }
}
const styles = {
    headerToolbarItemBtnWrapper: {
        display:"flex",
        flexDirection: "row",
        justifyContent: "center",

    },
    headerToolbarItemBtn: {
        display:"flex",
        flex: 1,
        flexDirection: "row"
    },
    headerToolbarItem: {
        display:"flex",
        height: 50,
        flex: 1,
        backgroundColor: "#f5f7f9",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    headerToolbarItemText: {
        color: "rgba(0,0,0,1)",
        fontSize: 14,
        marginRight: 5
    },
    itemBox: {
        display:"flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 40,
        alignItems: "center",
        borderBottom: '1px solid #f5f7f9',
        paddingLeft: 10,
        paddingRight: 10
    },
    itemHeader: {
        display:"flex",
        flexDirection: "row",
        alignItems: "center"
    },
    itemHeaderSy: {
        color: "rgba(0,0,0,0.5)",
        fontSize: 12
    },
    itemHeaderNo: {
        fontSize: 14,
        marginLeft: 3,
        marginRight: 3,
        color: "#000"
    },
    itemBody: {
        display:"flex",
        flexDirection: "row",
        alignItems: "center"
    },
    subItemBall: {
        display:"flex",
        flexDirection: "row",
        alignItems: "center"
    },
    itemBall: {
        width: 18,
        height: 18,
        borderRadius: 18,
        color: "#fff",
        lineHeight: "18px",
        textAlign: "center",
        fontSize: 10,
        marginLeft: 3,
        backgroundColor:'rgba(246, 48, 85, 0.94)'
    },
    itemSymbol: {
        marginLeft: 3
    },
    pk: {
        height: 25,
        borderRadius: 2,
        color: "#fff",
        lineHeight: "25px",
        textAlign: "center",
        fontSize: 13,
        width: 20,
        marginLeft: 3
    },
    color1: {
        backgroundColor: "#fcf53c"
    },
    color2: {
        backgroundColor: "#4990e2"
    },
    color3: {
        backgroundColor: "#4a4a4a"
    },
    color4: {
        backgroundColor: "#f6a623"
    },
    color5: {
        backgroundColor: "#50b8e3"
    },
    color6: {
        backgroundColor: "#5d12fe"
    },
    color7: {
        backgroundColor: "#b4b3b3"
    },
    color8: {
        backgroundColor: "#d0011b"
    },
    color9: {
        backgroundColor: "#801111"
    },
    color10: {
        backgroundColor: "#7ed321"
    },
    footer: {
        flexDirection: "column"
    },
    footerBody: {
        display:"flex",
        height: 50,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    footerBodyText: {
        fontSize: 14,
        color: "#bbbbbb"
    },
    footerPadding: {
        height: 50
    },
    quickEntryImg: {
        width: 25,
        height: 25,
        marginLeft: 5
    },
};
