/**
 * 平台卡片
 */

import React from 'react';
import PropTypes from 'prop-types';
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Image2 from "./image2";

class GameCard extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        obj: PropTypes.object,
        name: PropTypes.string,
        icon: PropTypes.string,
        width: PropTypes.string
    };

    /**
     * 进入游戏
     * @private
     */
    _enterGamePage = () => {
        if (this.props.obj && this.props.obj.game_type === "_self_lottery") {
            PublicUtils._enterLotteryPage(this.props.obj.game_id);
        } else {
            let parm = {
                name: this.props.obj.id,
                game_id: this.props.obj.game_id,
                type: this.props.obj.type,
                title: this.props.obj.CN_Name,
                play: this.props.obj.HTML5_Game_ID,
                platform: this.props.obj.title
            };



            PublicUtils._enterGamePage(parm);
        }
    };


    render() {
        const styles = {
            gameView: {
                padding: "10px 5px",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden"
            },
            gameImgBox: {
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden"
            },
            gamePic: {
                // width: 120,
            }
            ,
            gameNameBox: {
                height: 25,
                lineHeight: "25px",
                display: "block",
                width: "100%",
            }
            ,
            gameNameText: {

                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: 14,
            }
            ,
        };
        return (
            <div onClick={() => {
                this._enterGamePage();
            }}
                 style={{
                     ...styles.gameView, ...this.props.width ? {
                         width: this.props.width,
                         height: this.props.width
                     } : {
                         width: ((model.clientWidth - 50) / 4),
                         height: ((model.clientWidth - 50) / 4)
                     }
                 }}>
                <div style={styles.gameImgBox}>
                    <Image2 width={'100%'} hasPlaceholder={true} style={{borderRadius: 6}}
                           placeholderSize={{
                               width: this.props.width || ((model.clientWidth - 50) / 4),
                               height: this.props.width || ((model.clientWidth - 50) / 4)
                           }}
                           uri={this.props.icon}/>
                </div>
                <div style={styles.gameNameBox}>
                    <div style={styles.gameNameText}>{this.props.name}</div>
                </div>
            </div>)
    }
}

export default GameCard
