/**
 * 投注号码
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";
import Image from "../../../component/image";
import dice1 from "../../../assets/images/dice1.png";
import dice2 from "../../../assets/images/dice2.png";
import dice3 from "../../../assets/images/dice3.png";
import dice4 from "../../../assets/images/dice4.png";
import dice5 from "../../../assets/images/dice5.png";
import dice6 from "../../../assets/images/dice6.png";
import model from "../../../services/model";

export default class BetNumber extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {};
    }

    static propTypes = {
        templateType: PropTypes.string,
        currentBetNumber: PropTypes.object,
        playName: PropTypes.string,
        code: PropTypes.number,
        data: PropTypes.array,
        onPress: PropTypes.func,
        onClosePress: PropTypes.func,
        betTemplate: PropTypes.object,
        parentData: PropTypes.object
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.setState({second: this.props.second}, () => {
        //     this._countdown();
        // });
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps) => {
    };
    /**
     * 点击投注号码
     * */
    _betNumberClick = (item) => {
        this.props.onPress && this.props.onPress(item);
    };
    /**
     *色子
     * @private
     */
    _renderDice = (number, size) => {
        const styles = {
            diceImg: {
                width: 18,
                height: 18,
            },
            clearBetNumber: {
                position: "absolute",
                right: -10,
                top: -10,
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }
        };
        if (number == 1) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice1}/>
            )
        }
        if (number == 2) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice2}/>
            )
        }
        if (number == 3) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice3}/>
            )
        }
        if (number == 4) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice4}/>
            )
        }
        if (number == 5) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice5}/>
            )
        }
        if (number == 6) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice6}/>
            )
        }
    };

    /**
     * 投注按钮
     * @private
     */
    _renderNumberdiv = () => {
        const styles = {
            diceImg: {
                width: 18,
                height: 18,
            },
            clearBetNumber: {
                position: "absolute",
                right: -10,
                top: -10,
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }
        };
        const betInfoStyles = {
            betInfoBox: {
                flex: 1,
                display: "flex",
                flexDirection: "row",
                borderTop: "1px solid #f5f7f9"
            },
            betTypeBox: {
                display: "flex",
                flex: 1,
                // backgroundColor: "#f5f7f9",
                borderRight: "1px solid #f5f7f9"
            },
            betBallBox: {
                width: 270,
            },
            btnBox: {
                paddingLeft: 10,
                paddingRight: 10
            },
            betTypeInnerBox: {
                // borderRightWidth: 1,
                // borderRightColor: "#f5f7f9"
            },
            betTypeItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
                lineHeight: "40px",
                fontSize: 15,
            },
            betTypeItem: {
                color: "rgba(0,0,0,0.7)"
            },
            betCount: {
                width: 18,
                height: 18,
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                lineHeight: "18px",
                textAlign: "center",
                color: "#fff",
                borderRadius: 18,
                fontSize: 10
            },
            betTypeItemActive: {
                backgroundColor: "#f5f7f9",
            },
            betBallInnerBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: 5,
            },
            betNumberBox: {
                position: "relative",
                display: "flex",
                flexDirection: "row",
                margin: 5,
                paddingRight: 8,
                height: 40,
                alignItems: "center",
                // border: "1px dashed rgba(0,0,0,0.15)",
                justifyContent: "space-between",
                backgroundColor: "#f5f7f9",
                borderRadius: 3,
                width: 120,
                boxSizing: "border-box",
            },
            betNumberActive: {
                // border: "1px solid " + style['primaryColor' + model.SKINNAME],
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betNameActive: {
                backgroundColor: style['primaryColorDeep' + model.SKINNAME],
                color: "#fff"
                // color: style['primaryColor' + model.SKINNAME]
            },
            betAmountActive: {
                color: "#fff"
            },
            betOddsActive: {
                color: "#fcff23"
            },
            betName: {
                fontSize: 14,
                color: "#515151",
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "#e1e3e5",
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                height: 40,
                lineHeight: "40px",
                paddingLeft: 10,
                paddingRight: 10,
            },
            betFullName: {
                display: "flex",
                flex: 1,
                borderRadius: 3,
                justifyContent: "center"
            },
            betAmount: {
                fontSize: 13,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "green"
            },
            betOdds: {
                fontSize: 12,
                color: "rgba(246, 48, 85, 0.94)",
                textAlign: "center",
                // backgroundColor: "blue"
            },
            betNumberAllRowBox: {
                position: "relative",
                display: "flex",
                flexDirection: "row",
                margin: 5,
                paddingLeft: 8,
                paddingRight: 8,
                height: 40,
                alignItems: "center",
                // border: "1px dashed rgba(0,0,0,0.15)",
                justifyContent: "space-between",
                backgroundColor: "#f5f7f9",
                borderRadius: 3,
                width: 234,
                overflow: "hidden"
            },
            betNumberDiceBox: {
                display: "flex",
                flexDirection: "row"
            },
            betNumberNoOddsBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: 5,
                height: 40,
                alignItems: "center",
                // border: "1px dashed rgba(0,0,0,0.15)",
                // borderRadius: 0.1,
                borderRadius: 3,
                width: 50,
            },
        };
        //快三
        if (this.props.templateType === "KS") {
            if (this.props.playName === "和值") {
                return (
                    <div onClick={(event) => {
                        event.stopPropagation();
                        this._betNumberClick(this.props.data);
                    }}
                         style={{...betInfoStyles.betNumberBox, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNumberActive : null}}>
                        <div
                            style={{...betInfoStyles.betName, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                        <div
                            style={{...betInfoStyles.betAmount, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betAmountActive : null}}>{this.props.data[2] || ""}</div>
                        <div
                            style={{...betInfoStyles.betOdds, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betOddsActive : null}}>{this.props.data[1] || "0.00"}</div>
                        {/*{*/}
                        {/*this.props.data[2] ?*/}
                        {/*<div onClick={(event) => {*/}
                        {/*event.stopPropagation();*/}
                        {/*this.props.onClosePress && this.props.onClosePress(this.props.data);*/}
                        {/*}} style={styles.clearBetNumber}><Icon icon={'close'} size={12} color={"#fff"}/>*/}
                        {/*</div> : null*/}
                        {/*}*/}
                    </div>
                );
            } else {
                return (
                    <div onClick={(event) => {
                        event.stopPropagation();
                        this._betNumberClick(this.props.data);
                    }}
                         style={{...betInfoStyles.betNumberAllRowBox, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNumberActive : null}}>
                        <div style={betInfoStyles.betNumberDiceBox}>
                            {
                                this.props.data[0][0] === "任意豹子" ?
                                    <div
                                        style={{...betInfoStyles.betName, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNameActive : null}}>{this.props.data[0][0]}</div>
                                    :
                                    this.props.data[0].map((dice, key) => {
                                        return (<div key={key}
                                                     style={{marginRight: 5}}>{this._renderDice(dice, 22)}</div>)
                                    })
                            }
                        </div>
                        <div
                            style={{...betInfoStyles.betAmount, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betAmountActive : null}}>{this.props.data[2] || ""}</div>
                        <div
                            style={{...betInfoStyles.betOdds, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betOddsActive : null}}>{this.props.data[1] || "0.00"}</div>
                        {/*{*/}
                        {/*this.props.data[2] ?*/}
                        {/*<div onClick={(event) => {*/}
                        {/*event.stopPropagation();*/}
                        {/*this.props.onClosePress && this.props.onClosePress(this.props.data);*/}
                        {/*}} style={styles.clearBetNumber}><Icon icon={'close'} size={12} color={"#fff"}/>*/}
                        {/*</div> : null*/}
                        {/*}*/}
                    </div>
                );
            }
        }
        //六合彩
        else if (this.props.templateType === "LHC") {
            if (this.props.playName === "连码" || this.props.playName === "合肖" || this.props.playName === "全不中") {
                if (this.props.playName === "连码" && this.props.betTemplate.secondSubPlayInfo && this.props.betTemplate.secondSubPlayInfo.label === "肖串尾数") {
                    let numList = [];
                    if (this.props.parentData.name === "主肖") {
                        numList = (this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.zx && this.props.currentBetNumber.nums.zx) || [];
                    }
                    if (this.props.parentData.name === "拖尾数") {
                        numList = (this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.ws && this.props.currentBetNumber.nums.ws) || [];
                    }
                    return (
                        <div onClick={(event) => {

                            event.stopPropagation();
                            this._betNumberClick(this.props.data);
                        }}
                             style={{...betInfoStyles.betNumberNoOddsBox, ...numList && numList.indexOf(this.props.data) > -1 ? betInfoStyles.betNumberActive : null}}>
                            <div
                                style={{...betInfoStyles.betName, ...betInfoStyles.betFullName, ...numList && numList.indexOf(this.props.data) > -1 ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                        </div>
                    )
                }
                else if (this.props.playName === "连码" && this.props.betTemplate.secondSubPlayInfo && this.props.betTemplate.secondSubPlayInfo.label === "拖胆") {
                    let numList = [];
                    if (this.props.parentData.name === "胆码") {
                        numList = (this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.dm && this.props.currentBetNumber.nums.dm) || [];
                    }
                    if (this.props.parentData.name === "拖码") {
                        numList = (this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.tm && this.props.currentBetNumber.nums.tm) || [];
                    }
                    return (
                        <div onClick={(event) => {
                            event.stopPropagation();
                            this._betNumberClick(this.props.data);
                        }}
                             style={{...betInfoStyles.betNumberNoOddsBox, ...numList && numList.indexOf(this.props.data) > -1 ? betInfoStyles.betNumberActive : null}}>
                            <div
                                style={{...betInfoStyles.betName, ...betInfoStyles.betFullName, ...numList && numList.indexOf(this.props.data) > -1 ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                        </div>
                    )
                }
                else {
                    return (
                        <div onClick={(event) => {
                            event.stopPropagation();
                            this._betNumberClick(this.props.data);
                        }}
                             style={{...betInfoStyles.betNumberNoOddsBox, ...this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.indexOf(this.props.data) > -1 ? betInfoStyles.betNumberActive : null}}>
                            <div
                                style={{...betInfoStyles.betName, ...betInfoStyles.betFullName, ...this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.indexOf(this.props.data) > -1 ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                        </div>
                    )
                }
            } else if (this.props.playName === "生肖连" || this.props.playName === "尾数连") {
                return (
                    <div onClick={(event) => {
                        event.stopPropagation();
                        this._betNumberClick(this.props.data);
                    }}
                         style={{...betInfoStyles.betNumberBox, ...this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.indexOf(this.props.data) > -1 ? betInfoStyles.betNumberActive : null}}>
                        <div
                            style={{...betInfoStyles.betName, ...this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.indexOf(this.props.data) > -1 ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                        <div
                            style={{...betInfoStyles.betOdds, ...this.props.currentBetNumber.nums && this.props.currentBetNumber.nums.indexOf(this.props.data) > -1 ? betInfoStyles.betOddsActive : null}}>{this.props.data[1] || "0.00"}</div>
                    </div>
                )
            } else {
                return (
                    <div onClick={(event) => {
                        event.stopPropagation();
                        this._betNumberClick(this.props.data);
                    }}
                         style={{...betInfoStyles.betNumberBox, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNumberActive : null}}>
                        <div
                            style={{...betInfoStyles.betName, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                        <div
                            style={{...betInfoStyles.betAmount, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betAmountActive : null}}>{this.props.data[2] || ""}</div>
                        <div
                            style={{...betInfoStyles.betOdds, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betOddsActive : null}}>{this.props.data[1] || "0.00"}</div>
                        {/*{*/}
                        {/*this.props.data[2] ?*/}
                        {/*<div onClick={(event) => {*/}
                        {/*event.stopPropagation();*/}
                        {/*this.props.onClosePress && this.props.onClosePress(this.props.data);*/}
                        {/*}} style={styles.clearBetNumber}><Icon icon={'close'} size={12} color={"#fff"}/>*/}
                        {/*</div> : null*/}
                        {/*}*/}
                    </div>
                )
            }
        } else {
            return (
                <div onClick={(event) => {
                    event.stopPropagation();
                    this._betNumberClick(this.props.data);
                }}
                     style={{...betInfoStyles.betNumberBox, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNumberActive : null}}>
                    <div
                        style={{...betInfoStyles.betName, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betNameActive : null}}>{this.props.data[0]}</div>
                    <div
                        style={{...betInfoStyles.betAmount, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betAmountActive : null}}>{this.props.data[2] || ""}</div>
                    <div
                        style={{...betInfoStyles.betOdds, ...this.props.data[2] || this.props.data[3] ? betInfoStyles.betOddsActive : null}}>{this.props.data[1] || "0.00"}</div>
                    {/*{*/}
                    {/*this.props.data[2] ?*/}
                    {/*<div onClick={(event) => {*/}
                    {/*event.stopPropagation();*/}
                    {/*this.props.onClosePress && this.props.onClosePress(this.props.data);*/}
                    {/*}} style={styles.clearBetNumber}><Icon icon={'close'} size={12} color={"#fff"}/>*/}
                    {/*</div> : null*/}
                    {/*}*/}
                </div>
            );
        }
    };

    render() {
        return this._renderNumberdiv();
    }
}