/**
 * 消息
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import Storage from "../services/storage";
import model from "../services/model";
import style from '../utils/config.scss'
import MyButton from '../component/myButton'

export default class MemberKuisun extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            list: {},
            loadding: false
        };
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentDidMount = () => {
        // PublicUtils.setParams({openMenu: this._openMenu});
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchMemberBonusShow();
    };

    /**
     * 获取彩金
     **/
    _fetchMemberBonusShow = () => {
        let parm = {};
        Http.request("memberKuisunShow", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Wallet'});
            } else {
                if (res.data && res.data.status) {
                    this.setState({list: res.data.info});
                } else {
                    PublicUtils.showToast("获取失败");
                }
            }
        });
    };
    /**
     * 领取彩金
     * @private
     */
    _fetchMemberBonusHandle = (id) => {
        let parm = {id: id};
        Http.request("memberKuisunHandle", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Wallet'});
            } else {
                if (res.data && res.data.status) {
                    PublicUtils.showToast("领取成功");
                    this._fetchMemberBonusShow();
                } else {
                    PublicUtils.showToast("领取失败");
                }
            }
        });
    };
    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this._fetchMemberBonusShow();
    };
    _renderView = () => {
        const styles = {
            headerContainer: {
                padding: 10,
                // borderBottomWidth: 1,
                // borderBottomColor: "#f5f7f9"
            },
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                overflow: "hidden",
                border: "1px solid " + style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            headerToolbarItem: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            headerToolbarActiveItem: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            headerToolbarActiveItemText: {
                color: "#fff"
            },
            messageBodyContainer: {},
            messageBox: {
                display: "flex",
                padding: 10,
                height: 50,
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                backgroundColor: "#fff"
            },
            messageLeftBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flex: 1
            },
            messageRightBox: {
                display: "flex",
                width: 60
            },
            messageHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            messageHeaderLeftBox: {
                display: "flex",
                flexDirection: "row",
                flex: 1
            },
            messageHeaderLeft: {
                fontSize: 16,
                color: "#000",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            hasRead: {
                color: "#a8a8a8",
            },
            messageHeaderRight: {
                flex: 1,
                marginLeft: 10,
                fontSize: 22,
                color: "#000",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            messageBody: {},
            messageBodyText: {
                fontSize: 12,
                color: "#a8a8a8",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            messageBottomBox: {
                display: "flex",
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "space-between",
            },
            messageStatus: {
                fontSize: 12,
                color: "#a8a8a8"
            },
            messageTime: {
                fontSize: 12,
                color: "#a8a8a8"
            },
            activeMessage: {
                color: style['primaryColor' + model.SKINNAME]
            },
            noticeBox: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 50,
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9",
                backgroundColor: "#fff"
            },
            noticeContent: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: 14
            },
            delBtnContainer: {
                position: "absolute",
                right: 0,
                top: 0,
                height: 70,
                width: 70,
            },
            delBtn: {
                display: "flex",
                backgroundColor: "rgba(245, 72, 68, 0.93)",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: 70,
                height: 70,
            },
            delBtnText: {
                color: "#fff",
                fontSize: 18
            }
        };
        if (this.state.loadding) {
            return <Loadding/>;
        } else {
            if (this.state.list.is_title) {
                return (
                    <div onClick={() => {
                        // this._navigateToPage("MessageDetail", item);

                    }} style={styles.messageBox}>
                        <div style={styles.messageLeftBox}>
                            <div style={styles.messageHeader}>
                                <div style={{...styles.messageHeaderLeftBox}}>
                                    {/*<div*/}
                                    {/*style={{...styles.messageHeaderLeft}}>{this.state.list.is_title && this.state.list.is_title}</div>*/}
                                    <div
                                        style={styles.messageHeaderRight}>￥{this.state.list.is_amount && PublicUtils.subStringNum(this.state.list.is_amount)}</div>
                                </div>
                            </div>
                            {/*<div style={styles.messageBody}>*/}
                            {/*<div*/}
                            {/*style={styles.messageBodyText}>{this.state.list.start_time && this.state.list.start_time} 至 {this.state.list.end_time && this.state.list.end_time}</div>*/}
                            {/*</div>*/}
                        </div>
                        <div style={styles.messageRightBox}>
                            <MyButton title={'领取'}
                                      showProgress={this.state.showProgress}
                                      disabled={this.state.showProgress}
                                      onPress={() => {
                                          this._fetchMemberBonusHandle(this.state.list.id);
                                          // this._onLogin();
                                      }}/>
                        </div>
                        {/*<View style={styles.messageBottomBox}>*/}
                        {/*<Theme.Text*/}
                        {/*style={[styles.messageStatus, item.is_status === 0 ? styles.activeMessage : null]}>{item.is_status === 0 ? "未读" : "已读"}</Theme.Text>*/}
                        {/*<Text*/}
                        {/*style={styles.messageTime}>{PublicUtils.timestampFormat(item.add_time)}</Text>*/}
                        {/*</View>*/}
                    </div>
                )
            } else {
                return <NoneData/>;
            }
        }
    }

    render() {
        return (
            <Container title={'月月转运'} style={{backgroundColor: "#fff"}}>

                {
                    this._renderView()
                }
            </Container>
        )
    }
}