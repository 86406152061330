/**
 * 玩法说明
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../../component/container";
import PublicUtils from "../../../utils/publicUtils";
import Navigate from '../../../component/navigate'

export default class LotteryIntroduction extends Component {
    // 构造
    constructor(props) {
        super(props);
        // alert(this.props.navigation.getParam("code"));
        this.template = [
            {
                name: "一般体育说明",
                title: "一般体育说明"
            },
            {
                name: "足球",
                title: "足球规则说明"
            },
            {
                name: "篮球",
                title: "篮球规则说明"
            },
            {
                name: "网球",
                title: "网球规则说明"
            },
            {
                name: "排球",
                title: "排球规则说明"
            },
            {
                name: "棒球",
                title: "棒球规则说明"
            },
            {
                name: "乒乓球",
                title: "乒乓球规则说明"
            },
            {
                name: "羽毛球",
                title: "羽毛球规则说明"
            },
            {
                name: "美式足球",
                title: "美式足球规则说明"
            },
            {
                name: "电子竞技",
                title: "电子竞技规则说明"
            },
            {
                name: "冠军",
                title: "冠军规则说明"
            },
            {
                name: "连串过关/复式过关",
                title: "连串过关/复式过关规则说明"
            },
            {
                name: "沙滩足球",
                title: "沙滩足球规则说明"
            },
            {
                name: "沙滩排球",
                title: "沙滩排球规则说明"
            },
            {
                name: "室内足球",
                title: "室内足球规则说明"
            }
        ]
    }
    render() {
        const styles = {
            itemContainer: {},
            itemText: {},
        };
        return (
            <Container title={"体育规则"} style={{backgroundColor: "#fff"}}>
                    {
                        this.template.map((item)=>{
                            return (
                                <div style={styles.itemContainer}>
                                    <Navigate title={item.name}
                                              note={"查看"} onPress={() => {

                                        PublicUtils.navigate("SportIntroductionDetail", item);
                                        // PublicUtils.linking("https://vip.hys2014.com/new/rule/Rule_zq.html");
                                    }}/>
                                </div>
                            )
                        })
                    }
            </Container>
        )
    }
}