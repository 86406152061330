/**
 * 下级代理
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";

export default class SubUsers extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            refreshing: false,//上拉刷新,
            total: 0,//总页数
            currpage: 1,//当前页
            list: [],
            month_num: 0,
            online_num: 0,
            all_num: 0
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 初始化
     */
    _init_() {
        this._fetchData();
    }

    /**
     * 获取数据
     * @private
     */
    _fetchData = () => {
        const parma = {page: this.state.currpage};
        if (this.state.currpage === 1) {
            this.setState({list: []});
        }
        this.setState({showFoot: 2});
        Http.request("agentUserList", parma, (res) => {
            this.setState({refreshing: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'SubUsers'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    let state = {
                        month_num: res.data.month_num,
                        online_num: res.data.online_num,
                        all_num: res.data.all_num
                    };
                    if (this.state.currpage === 1) {
                        state.list = res.data.list;
                        state.total = res.data.total;
                        // this.setState({list: res.data.list, total: res.data.total});
                    } else {
                        state.list = this.state.list.concat(res.data.list);
                        state.total = res.data.total;
                        // this.setState({list: this.state.list.concat(res.data.list), total: res.data.total})
                    }
                    if (this.state.currpage >= res.data.total) {
                        state.showFoot = 1;
                        // this.setState({showFoot: 1});
                    } else {
                        state.showFoot = 2;
                        // this.setState({showFoot: 2});
                    }
                    this.setState(state);
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    };


    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this.setState({currpage: 1}, () => {
            //获取数据
            this._fetchData();
        });
    };

    _onEndReached() {
        if (this.state.currpage === this.state.total) {
            this.setState({showFoot: 1});
            return;
        }
        this.setState({currpage: this.state.currpage + 1}, () => {
            //获取数据
            this._fetchData();
        });

    }

    /**
     * 页脚
     * @returns {*}
     * @private
     */
    _renderFooter() {
        const styles = {
            box: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                backgroundColor: "#fff",
                borderBottom: '1px solid #f5f7f9',
            },
            left: {},
            leftTop: {
                display: "flex",
                flexDirection: "row"
            },
            leftBottom: {},
            Right: {
                display: "flex",
                flexDirection: "row"
            },
            user_account: {
                fontSize: 16,
                color: "#000"
            },
            user_account_offline: {
                color: "rgba(0,0,0,0.3)"
            },
            offlineBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            offlineState: {
                width: 5,
                height: 5,
                borderRadius: 5,
                marginLeft: 5,
                marginRight: 5,
                backgroundColor: "rgba(0,0,0,0.3)"
            },
            offline: {
                color: "rgba(0,0,0,0.3)"
            },
            onlineBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            onlineState: {
                width: 5,
                height: 5,
                borderRadius: 5,
                marginLeft: 5,
                marginRight: 5,
                backgroundColor: "#00a60a"
            },
            online: {
                color: "#00a60a"
            },
            time: {
                fontSize: 12,
                color: "rgba(0,0,0,0.3)",
                marginTop: 10
            },
            balance: {
                fontSize: 16,
                color: "#000"
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                margin: "10px",
                borderRadius: 6
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            },
            textSpace: {
                marginRight: 3
            }
        };
        let renderView = null;
        if (this.state.showFoot === 1) {
            if (!this.state.list.length) {
                renderView = (
                    <NoneData/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody}>
                            <div style={styles.footerBodyText}>
                                没有更多数据了
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.state.showFoot === 2) {
            if (!this.state.list.length) {
                renderView = (
                    <Loadding/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody} onClick={() => {
                            this._onEndReached();
                        }}>
                            <div style={styles.footerBodyText}>
                                点击加载更多
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.state.showFoot === 0) {
            renderView = (
                <div style={styles.footer}>
                    {/*<View style={styles.footerBody}>*/}
                    {/*<Text style={styles.footerBodyText}>*/}
                    {/*努力加载中...*/}
                    {/*</Text>*/}
                    {/*</View>*/}
                    <div style={styles.footerPadding}/>
                </div>
            );
        }
        return renderView;
    }

    /**
     * 给下级会员转账
     * @private
     */
    _showDetail = async (item) => {
        let user = JSON.parse(await Storage.retrieveData("user"));
        if (user.is_agent_deposit) {
            const data = {
                user_account: item.user_account, refresh: () => {
                    this._init_();
                }
            };
            PublicUtils.navigate("SubUserTransfer", data);
        }
    };

    render() {
        const styles = {
            box: {
                margin: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                backgroundColor: "#fff",
                border: '1px solid rgba(0,0,0,0.06)',
                borderRadius: 6
            },
            left: {},
            leftTop: {
                display: "flex",
                flexDirection: "row"
            },
            leftBottom: {},
            Right: {
                // display: "flex",
                // flexDirection: "row"
            },
            user_account: {
                fontSize: 16,
                color: "#000"
            },
            user_account_offline: {
                color: "rgba(0,0,0,0.3)"
            },
            offlineBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            offlineState: {
                width: 5,
                height: 5,
                borderRadius: 5,
                marginLeft: 5,
                marginRight: 5,
                backgroundColor: "rgba(0,0,0,0.3)"
            },
            offline: {
                color: "rgba(0,0,0,0.3)"
            },
            onlineBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            onlineState: {
                width: 5,
                height: 5,
                borderRadius: 5,
                marginLeft: 5,
                marginRight: 5,
                backgroundColor: "#00a60a"
            },
            online: {
                color: "#00a60a"
            },
            time: {
                fontSize: 12,
                color: "rgba(0,0,0,0.3)",
                marginTop: 10
            },
            balance: {
                fontSize: 16,
                color: "#000"
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                margin: "10px",
                borderRadius: 6
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            },
            textSpace: {
                marginRight: 3
            },
            userInfoBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                backgroundColor: "#fff",
                paddingTop: 10,
                paddingBottom: 10,
                position: "fixed",
                top: model.headerHeight,
                height: 60,
                left: 0,
                right: 0
            },
            userInfoItemBox: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid #f5f7f9",
                paddingLeft: 10,
                paddingRight: 10
            },
            userInfoItemContent: {
                fontSize: 20,
                color: "#000"
            },
            userInfoItemTitle: {
                fontSize: 12,
                color: "rgba(0,0,0,1)",
                marginTop: 10
            },
            contentBox: {
                marginTop: 80,
                padding: 5
            }
        };
        return (
            <Container title={'下级会员'}>
                <div style={styles.userInfoBox}>
                    <div style={styles.userInfoItemBox}>
                        <div style={styles.userInfoItemContent}>{this.state.all_num}</div>
                        <div style={styles.userInfoItemTitle}>会员</div>
                    </div>
                    <div style={styles.userInfoItemBox}>
                        <div style={styles.userInfoItemContent}>{this.state.month_num}</div>
                        <div style={styles.userInfoItemTitle}>本月新增</div>
                    </div>
                    <div style={styles.userInfoItemBox}>
                        <div style={styles.userInfoItemContent}>{this.state.online_num}</div>
                        <div style={styles.userInfoItemTitle}>在线</div>
                    </div>
                </div>
                <div style={styles.contentBox}>
                    {
                        this.state.list.map((item, key) => {
                            return (

                                <div key={key} onClick={() => {
                                    this._showDetail(item);
                                }} style={styles.box}>
                                    <div style={styles.left}>
                                        <div style={styles.leftTop}>
                                            <div
                                                style={{...styles.user_account, ...item.is_online === 0 ? styles.user_account_offline : null}}>【{item.user_account}】{item.user_name}</div>
                                            {
                                                item.is_online === 0 ?
                                                    <div style={styles.offlineBox}>
                                                        <div style={styles.offlineState}/>
                                                        <div style={styles.offline}>离线</div>
                                                    </div> :
                                                    <div style={styles.onlineBox}>
                                                        <div style={styles.onlineState}/>
                                                        <div style={styles.online}>在线</div>
                                                    </div>
                                            }
                                        </div>
                                        <div style={styles.leftBottom}>
                                            <div style={styles.time}>{item.reg_time}</div>
                                        </div>
                                    </div>
                                    <div style={styles.Right}>
                                        <div style={styles.balance}>{item.is_balance}</div>
                                        <div style={{fontSize:12, marginTop:4,color:'red'}}>点击充值</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        this._renderFooter()
                    }
                </div>
            </Container>
        )
    }
}
