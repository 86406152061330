/**
 * 球类
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../../../utils/config.scss'
import Icon from '../../../../utils/icon'
import model from "../../../../services/model";

export default class BallType extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            // ballId: this.props.ballId,
        }
    }

    static propTypes = {
        data: PropTypes.object,
        color:PropTypes.string,
        ballId: PropTypes.number,
        onPress: PropTypes.func
    };
    static defaultProps = {
        ballId: 1
    };
    _onPress = (id) => {
        this.props.onPress(id);
    };

    render() {
        const styles = {

            swiperWrapper: {},
            slider: {
                borderBottom: "2px solid " + style['primaryColor' + model.SKINNAME]
            },
            sliderWrapper: {
                display: "flex",
                paddingTop: 13,
                paddingBottom: -2,
                flexDirection: "column",
                justifyContent: "space-between",
                width: model.clientWidth / 6.4,
                alignItems: "center"
            },
            sliderIconBox: {
                display: "flex",
                height: 35,
                justifyContent: "flex-start",
                alignItems: "center"
            },
            sliderIcon: {},
            sliderName: {
                marginTop: 1,
            },
            sliderNameText: {
                color: "rgba(0, 0, 0, 0.28)",
                fontSize: 14
            },
            activeSliderNameText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            openAllBtn: {
                marginRight: 5,
                color: "#444444"
            },
            arrowTop: {
                transform: "rotate(-180deg)"
            }
        };
        return (
            <div key={this.props.data.id}
                 style={styles.slider}
                 onClick={() => {
                     this.setState({ballId: this.props.data.id});
                     this._onPress(this.props.data.id);
                 }}>
                <div style={styles.sliderWrapper}>
                    <div style={styles.sliderIconBox}>
                        <Icon icon={this.props.data.icon} size={this.props.data.size}
                              color={this.props.color}/>
                    </div>
                    <div style={styles.sliderName}>
                        <div
                            style={{...styles.sliderNameText, ...this.props.ballId === this.props.data.id ? styles.activeSliderNameText : null}}>{this.props.data.name}</div>

                    </div>
                    {this.props.ballId === this.props.data.id ?
                        <div style={styles.arrowTop}><Icon icon={'sjjt'} size={14}
                                                           color={style['primaryColor' + model.SKINNAME]}/></div> : null}
                </div>
            </div>
        )
    }
}