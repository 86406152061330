/**
 * 彩票投注详情
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import PublicUtils from "../utils/publicUtils";
import history from "../services/history";
import copy from 'copy-to-clipboard';
import Icon from '../utils/icon'
import model from "../services/model";
import style from '../utils/config.scss'

export default class LotteryBetRecordDetail extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            matchInfo: PublicUtils.getState(this.props.location.pathname).matchInfo,
        }
    }

    /**
     * 复制内容到剪贴板
     * @param text
     * @private
     */
    _copyText = async (text) => {
        // Clipboard.setString(text);
        // await Clipboard.getString();
        copy(text);
        PublicUtils.showToast("已复制到剪贴板");
    };

    render() {

        const styles = {
            betDetailBox: {},
            betDetailHeaderBox: {
                paddingTop: 15,
                paddingBottom: 15,
                backgroundColor: "#f5f7f9"
            },
            betDetailHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 35,
            },
            matchName: {
                fontSize: 18,
                color: "#000"
            },
            matchTeam: {
                fontSize: 18
            },
            matchTeamVs: {
                fontSize: 12,
                marginLeft: 10,
                marginRight: 10
            },
            betInfo: {
                marginLeft: 2,
                marginRight: 2
            },
            color1: {
                color: "#049c2d"
            },
            color2: {
                color: "#ff5a00"
            },
            color3: {
                color: "rgba(245, 72, 68, 0.93)"
            },
            bf: {
                fontSize: 18,
            },
            betAmount: {
                fontSize: 26,
                color: "#000"
            },
            betStatus: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)"
            },
            row: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                borderBottom: '1px solid #f5f7f9',
                height: 40,
                alignItems: "center"
            },
            leftCol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)"
            },
            rightCol: {
                fontSize: 14,
                color: "#333",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative"
            },
            accountInfoItemCopyBtn: {
                position: "absolute",
                right: 0,
                top: -43,
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                borderRadius: 3,
                backgroundColor: "#333",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            accountInfoItemCopyBtnText: {
                color: "#fff",
                fontSize: 12,
                margin: "0 7px"
            },
            copyHeader: {
                position: "absolute",
                left: 8,
                top: "100%",
                width: 0,
                height: 0,
                borderColor: "transparent #333",
                borderWidth: "0 0 12px 15px",
                borderStyle: "solid",
            },
            win: {backgroundColor: "#ff353b", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
            lose: {backgroundColor: "#1dc315", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
            notopen: {
                backgroundColor: "#1dc315",
                color: "#fff",
                padding: "3px 5px",
                borderRadius: 3,
                fontSize: 16,
            },
        };
        return (
            <Container title={'投注详情'} style={{backgroundColor: "#fff"}}>
                <div style={styles.betDetailBox}>
                    <div style={styles.betDetailHeaderBox}>
                        <div style={styles.betDetailHeader}>
                            <div style={styles.matchName}>{this.state.matchInfo.lottery_code}[{this.state.matchInfo.bet_class}]</div>
                        </div>
                        {/*<div style={styles.betDetailHeader}>*/}
                            {/*<div style={[styles.matchTeam, styles.color1]}>{this.state.matchInfo.bet_class}</div>*/}
                        {/*</div>*/}
                        <div style={styles.betDetailHeader}>
                            <div
                                style={{...styles.betAmount, ...this.state.matchInfo.bet_win !== "未结算" ? Number(this.state.matchInfo.bet_win) >= 0 ? styles.win : styles.lose : styles.notopen}}>{this.state.matchInfo.bet_win}</div>
                        </div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.leftCol}>注单号</div>
                        <div style={styles.rightCol}>
                            {this.state.matchInfo.order_number}
                            <div onClick={() => {
                                this._copyText(this.state.matchInfo.order_number)
                            }} style={styles.accountInfoItemCopyBtn}>
                                {/*<Icon icon={'ziliao'} size={12} color={'#fff'}/>*/}
                                <div style={styles.accountInfoItemCopyBtnText}>点击复制</div>
                                <div style={styles.copyHeader}/>
                            </div>
                        </div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.leftCol}>期号</div>
                        <div style={styles.rightCol}>{this.state.matchInfo.lottery_no}</div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.leftCol}>玩法</div>
                        <div style={styles.rightCol}>{this.state.matchInfo.bet_class}</div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.leftCol}>赔率</div>
                        <div style={styles.rightCol}>{this.state.matchInfo.bet_odds}</div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.leftCol}>下注时间</div>
                        <div
                            style={styles.rightCol}>{PublicUtils.timestampFormat(this.state.matchInfo.add_time)}</div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.leftCol}>下注内容</div>
                        <div style={styles.rightCol}>{this.state.matchInfo.bet_details}</div>
                    </div>
                    {
                        this.state.matchInfo.bet_win ?
                            <div style={styles.row}>
                                <div style={styles.leftCol}>投注金额</div>
                                <div
                                    style={styles.rightCol}>{PublicUtils.subStringNum(this.state.matchInfo.bet_amount)}</div>
                            </div> : null
                    }
                </div>
            </Container>
        )
    }
}