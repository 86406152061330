/**
 * 下级代理报表
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import NoneData from "../component/noneData";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import MyPicker from "../component/myPicker";
import Icon from '../utils/icon'
import history from "../services/history";

export default class AgentUserReport extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.dateTypeModel = [];
        for (let i = 0; i < model.dateType.length; i++) {
            this.dateTypeModel.push(model.dateType[i]);
        }
        this.dateTypeModel.push({label: '上月', value: 'lastMonth'});
        this.playId = PublicUtils.getState(this.props.location.pathname).playId;
        this.gameId = PublicUtils.getState(this.props.location.pathname).gameId;
        this.gameName = PublicUtils.getState(this.props.location.pathname).gameName;
        this.dateType = PublicUtils.getState(this.props.location.pathname).dateType;
        this.state = {
            showDateTypeFliter: false,
            dateType: this.dateType,
            dataList: [],
            showProgress: false
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 初始化
     */
    _init_() {
        this._fetchReportData();
    }

    /**
     * 获取下级代理报表
     */
    _fetchReportData() {
        this.setState({dataList: [], showProgress: true});
        let parm: any = {day: this.state.dateType.value};
        if (this.playId) {
            parm.play = this.playId;
        }
        if (this.gameId) {
            parm.game_id = this.gameId;
        }
        Http.request("agentReportGame", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    if (res.data.list) {
                        this.setState({dataList: res.data.list});
                    }
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    }

    render() {
        return (
            <Container title={'下级代理报表'}>
                {
                    this.state.dataList.map((item,key) => {
                        return (
                            <div key={key} style={styles.box}>
                                <div onClick={() => {
                                    // this.setState({showDateTypeFliter: true})
                                }} style={styles.title}>
                                    <div style={{...styles.titleItemText, ...styles.name}}>{item.user_account}</div>
                                    <div style={styles.titleItemText}>-</div>
                                    <div style={styles.titleItemText}>{this.state.dateType.label}</div>
                                    {/*<Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,0.5)'}/>*/}
                                </div>
                                <div style={styles.header}>
                                    <div style={styles.headerItem}>
                                        <div style={styles.headerItemHeaderText}>注单</div>
                                        <div
                                            style={{...styles.headerItemText, ...!item.bet_num ? styles.dimText : null}}>{item.bet_num}</div>
                                    </div>
                                    <div style={styles.headerItem}>
                                        <div style={styles.headerItemHeaderText}>流水</div>
                                        <div
                                            style={{...styles.headerItemText, ...!item.valid_amount ? styles.dimText : null}}>{PublicUtils.subStringNum(item.valid_amount)}</div>
                                    </div>
                                    <div style={styles.headerItem}>
                                        <div style={styles.headerItemHeaderText}>输赢</div>
                                        <div
                                            style={{...styles.headerItemText, ...!item.win_amount ? styles.dimText : null}}>{PublicUtils.subStringNum(item.win_amount)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    this.state.showProgress ? <Loadding/> : null
                }
                {
                    !this.state.dataList.length && !this.state.showProgress ? <NoneData/> : null
                }
                <MyPicker
                    title={'日期'}
                    model={this.dateTypeModel}
                    display={this.state.showDateTypeFliter}
                    onHiden={() => {
                        this.setState({showDateTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({dateType: item.value[0].value, showDateTypeFliter: false, currpage: 1}, () => {
                            this._fetchReportData();
                        });
                    }}
                />
            </Container>
        )
    }
}
const styles = {
    box: {
        backgroundColor: "#fff",
        marginBottom: 10,
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: 40,
        alignItems: "center",
        borderBottom: "1px solid #f9f9f9",
    },
    name: {},
    titleItemText: {
        marginLeft: 5,
        marginRight: 5,
        fontSize: 14,
        color: "rgba(0,0,0,0.5)"
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px solid #f9f9f9",
        flex: 1,
    },
    headerItemHeaderText: {
        fontSize: 14,
        color: "rgba(0,0,0,0.4)",
    },
    headerItemText: {
        fontSize: 18,
        color: "rgba(0,0,0,1)",
        marginTop: 10
    },
    body: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #f9f9f9",
    },
    headerItemGameName: {
        // color: "#000",
        // fontSize: 16
    },
    dimText: {
        color: "rgba(0,0,0,0.4)"
    }
};