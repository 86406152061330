/**
 * 玩法
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../../../utils/config.scss'
import model from "../../../../services/model";

export default class RefreshOddsBox extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            defaultSecend: this.defaultSecend,
            isReady: this.props.isReady
        }
    }

    defaultSecend = this.props.playId === 2 ? 30 : 90;
    static propTypes = {
        complete: PropTypes.func,
        isReady: PropTypes.bool,
        playId: PropTypes.number
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this.countDown();
    }

    componentWillReceiveProps(nextProps) {
        // alert(nextProps.isReady + "--" + this.state.isReady);
        // if (nextProps.isReady !== this.state.isReady && nextProps.isReady) {
        //     this.countDown();
        // }
        // this.setState({checked: !!nextProps.checked});
    }

    _complete = () => {
        this.props.complete();
        this.setState({defaultSecend: this.defaultSecend});
        window.clearInterval(this.timer);
    }
    countDown = () => {
        this.timer = window.setInterval(() => {
            if (this.state.defaultSecend > 0) {
                this.setState({defaultSecend: this.state.defaultSecend - 1});
            } else {
                this._complete();
            }
        }, 1000);
    };

    render() {
        const styles = {
            refreshOdds: {
                position: "fixed",
                left: 0,
                top: model.headerHeight,
                right: 0,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                color: "#fff",
                height: 35,
                lineHeight: "35px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.1)",
                fontSize: 14,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }
        };
        return (

            <div style={styles.refreshOdds} className={'headPadding'}>[{this.state.defaultSecend}]秒后刷新赔率
                <div onClick={() => {
                    this._complete();
                }} style={{color: "#ffe206", marginLeft: 10}}>[点击刷新]</div>
            </div>
        )
    }
}