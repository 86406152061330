/**
 * 投注弹框
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BetAmountInput from "./betAmountInput";
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";
import model from "../../../services/model";
import PublicUtils from "../../../utils/publicUtils";
import Storage from "../../../services/storage";
import Http from "../../../services/http";
import ActivityIndicator from "../../../component/activityIndicator";
import global from "../../../global";

export default class Betbox extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            betTranslateY: 0,
            disabled: this.props.disabled,
            show: false,
            betTemplate: this.props.betTemplate,
            betContent: this.props.betContent,
            render: false,
            showProgress: false,
            // betAmount: this.props.betAmount
        };
        // this.betAmount = this.props.betAmount;
    }

    static propTypes = {
        templateType: PropTypes.string,
        betAmount: PropTypes.string,
        code: PropTypes.number,
        total: PropTypes.object,
        disabled: PropTypes.bool,
        show: PropTypes.bool,
        betTemplate: PropTypes.object,
        betContent: PropTypes.object,
        amountInputFn: PropTypes.func,
        hideDialog: PropTypes.func,
        betOnPress: PropTypes.func,
        lotteryInfo: PropTypes.object,
        betTemplateList: PropTypes.array,
        clearBetInfoCallbck: PropTypes.func,
        onBlur: PropTypes.func,
        onChangeText: PropTypes.func,
    };
    static defaultProps = {
        show: false,
        disabled: false,
        total: {
            count: 0,
            amount: 0
        }
    };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.setState({second: this.props.second}, () => {
        //     this._countdown();
        // });
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps, nextState) => {
        let state = {
            betAmount: nextProps.betAmount,
            code: nextProps.code,
            total: nextProps.total,
            betTemplate: nextProps.betTemplate,
            betContent: nextProps.betContent,
            disabled: nextProps.disabled,
        };
        if (nextProps.show && this.nameInput) {
            // this.nameInput.focus();
            // this.nameInput.select();
        }
        // nextProps.show && this.nameInput && this.nameInput.focus() && this.nameInput.select();
        // if (this.props.betTemplate.name !== "连码" && this.props.betTemplate.name !== "合肖" && this.props.betTemplate.name !== "生肖连" && this.props.betTemplate.name !== "尾数连" && this.props.betTemplate.name !== "全不中") {
        //
        //     console.log(nextProps.betTemplate);
        //     console.log(nextProps.betContent);
        //     nextProps.betTemplate.nums.map((item) => {
        //         if (item[3]) {
        //             state.show = true;
        //         }
        //     });
        // } else {
        //     console.log(nextProps.show);
        //     state.show = nextProps.show;
        // }

        // if (this.props.total.count < nextProps.total.count) {
        //     // if (this.props.betTemplate.name !== "连码" && this.props.betTemplate.name !== "合肖" && this.props.betTemplate.name !== "生肖连" && this.props.betTemplate.name !== "尾数连" && this.props.betTemplate.name !== "全不中") {
        //     //     this.nameInput && this.nameInput.focus();
        //     // }
        //     this.nameInput && this.nameInput.focus();
        //     state.show = true;
        //     // this._showBetBox();
        // } else {
        //     // state.show = false;
        //     // this._hideBetBox();
        // }

        this.setState(nextProps);
        // disabled: this.props.disabled,
        //     betName: this.props.betName,
        //     betContent: this.props.betContent,
        //     odds: this.props.odds,
        //     show: this.props.show
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.show !== this.state.show;
    // }

    /**
     * 显示投注框
     * @private
     */
    _showBetBox = () => {
    };
    /**
     * 隐藏投注框
     * @private
     */
    _hideBetBox = () => {
    };
    /**
     * 投注金额
     * @private
     */
    _betAmount = (value) => {
        // this.betAmount = value;
        // this.setState({betAmount: value, render: !this.state.render});
        this.props.amountInputFn && this.props.amountInputFn(value);
        // this.setState({betAmount: value || this.text}, () => {
        //     this.props.amountInputFn && this.props.amountInputFn(value || this.text);
        // });
    };

    _closeBetBox = () => {
        let betTemplate = this.state.betTemplate;
        betTemplate.nums.map((item) => {
            item[3] = false;
        });
        this.props.hideDialog(betTemplate);
    };
    /**
     * 投注信息
     * */
    _renderBetInfo = () => {
        const betBoxStyles = {
            betBodyBox: {
                transition: "all 0.3s",
                position: "absolute",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#fffae2",
                left: 0,
                right: 0,
                bottom: "-100%",
                zIndex: 1,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
                borderTop: "1px solid #f5f7f9",

            },
            betInfo: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                padding: "10px 0",
                alignItems: "center",
                fontSize: 14
            },
            betInfoText: {
                color: "#000",
                marginLeft: 5,
                marginRight: 5,
            },
            betInfoInnerText: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            betContentText: {
                backgroundColor: "#ffe87b",
                paddingLeft: 10,
                paddingRight: 10,
                height: 20,
                borderRadius: 20,
                lineHeight: "20px",
                color: "#ff2d4a"
            },
            betNumberMaxWidth: {
                width: 100,
                textAlign: "center"
            },
            betInfoOddsText: {
                color: "rgba(246, 48, 85, 0.94)",
            },
            betAmount: {
                display: "flex",
                backgroundColor: "#f5f7f9",
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 35,
                border: "1px solid rgba(0,0,0,0.05)",
                flex: 4,
                justifyContent: "center",
                fontSize: 12
            },
            betAmountInput: {
                display: "flex",
                flex: 1,
                padding: 0,
                textAlign: "center"
            },
            betAmountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 5,
            },
            betAmountTemplateItem: {
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.08)",
                borderRadius: 3,
                fontSize: 12
            },
            betAmountActiveItem: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betAmountTemplateItemText: {
                color: "#000",
            },
            betAmountActiveItemText: {
                color: "#fff"
            },
            showBetBox: {
                bottom: 50
            }
        };
        const betTemplate = this.state.betTemplate;
        const betContent = this.state.betContent;

        let minval = 0
        if(betContent.nums.length>0){
            minval = betContent.nums[0][1];


            for(let i=0;i<betContent.nums.length;i++){
                if(parseFloat(minval)>parseFloat(betContent.nums[i][1])){
                    minval  = betContent.nums[i][1];
                }
            }

        }







        let _renderView;
        if (this.props.templateType === "LHC" && (betTemplate.name === "连码" || betTemplate.name === "合肖" || betTemplate.name === "生肖连" || betTemplate.name === "尾数连" || betTemplate.name === "全不中")) {
            if (betTemplate.name === "连码" && betTemplate.secondSubPlayInfo && (betTemplate.secondSubPlayInfo.label === "肖串尾数" || betTemplate.secondSubPlayInfo.label === "拖胆")) {
                _renderView = null;
            } else {
                _renderView =
                    (<div style={betBoxStyles.betInfo}>
                        <div style={betBoxStyles.betInfoText}>{betTemplate.name}</div>
                        <div style={betBoxStyles.betInfoText}>-</div>
                        <div style={{
                            ...betBoxStyles.betInfoText, ...betBoxStyles.betContentText, ...{
                                flex: 1,
                                textAlign: 'center',
                                width: 0
                            }
                        }}>
                            <div style={betBoxStyles.betInfoInnerText}>
                                {
                                    betContent.nums && betContent.nums.map((item, i) => {
                                        let text = item[0];
                                        if (i < betContent.nums.length - 1) {
                                            text += "/";
                                        }
                                        return text;
                                    })
                                }
                            </div>
                        </div>
                        <div
                            style={{...betBoxStyles.betInfoText, ...betBoxStyles.betInfoOddsText}}>赔率：
                            {
                                betTemplate.name === "生肖连"?<span>

                                    {minval}

                                </span>:<span>{betContent.odds}</span>
                            }

                        </div>
                    </div>)
            }
        } else {
            _renderView =
                (<div style={betBoxStyles.betInfo}>
                    <div style={betBoxStyles.betInfoText}>{betTemplate.name}</div>
                    <div style={betBoxStyles.betInfoText}>-</div>
                    <div style={{...betBoxStyles.betInfoText, ...betBoxStyles.betContentText}}>{betContent.name}</div>
                    <div
                        style={{...betBoxStyles.betInfoText, ...betBoxStyles.betInfoOddsText}}>赔率：{betContent.odds}</div>
                </div>);
        }
        return _renderView;
    };


    _onChangeText = (value) => {
        this.props.onChangeText && this.props.onChangeText(value);
    }
    /**
     * 渲染投注提示或者下注金额输入框
     * */
    _renderBetTipOrAmountTextInput = () => {
        const betBoxStyles = {
            betBodyBox: {
                transition: "all 0.3s",
                position: "absolute",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#fffae2",
                left: 0,
                right: 0,
                bottom: "-100%",
                zIndex: 1,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
                borderTop: "1px solid #f5f7f9",

            },
            betInfo: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                height: 30,
                alignItems: "center",
                fontSize: 14
            },
            betInfoText: {
                color: "#000",
                marginLeft: 5,
                marginRight: 5,
            },
            betInfoInnerText: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            betContentText: {
                backgroundColor: "#ffe87b",
                paddingLeft: 10,
                paddingRight: 10,
                height: 20,
                borderRadius: 20,
                lineHeight: "20px",
                color: "#ff2d4a"
            },
            betNumberMaxWidth: {
                width: 100,
                textAlign: "center"
            },
            betInfoOddsText: {
                color: "rgba(246, 48, 85, 0.94)",
            },
            betAmount: {
                display: "flex",
                backgroundColor: "#f5f7f9",
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 35,
                border: "1px solid rgba(0,0,0,0.05)",
                flex: 4,
                justifyContent: "center",
                fontSize: 12
            },
            betAmountInput: {
                display: "flex",
                flex: 1,
                padding: 0,
                textAlign: "center"
            },
            betAmountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 5,
            },
            betAmountTemplateItem: {
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.08)",
                borderRadius: 3,
                fontSize: 12
            },
            betAmountActiveItem: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betAmountTemplateItemText: {
                color: "#000",
            },
            betAmountActiveItemText: {
                color: "#fff"
            },
            showBetBox: {
                bottom: 50
            }
        };
        const betTemplate = this.state.betTemplate;
        const betContent = this.state.betContent;
        let _renderView;
        if (this.props.templateType === "LHC" && betTemplate.subPlayInfo && ((betContent.nums && (betContent.nums.length < betTemplate.subPlayInfo.min)) || (betContent.nums && (betContent.nums.length > betTemplate.subPlayInfo.max)))) {
            _renderView = (
                <div style={betBoxStyles.betAmount}>
                    <div>{betTemplate.subPlayInfo.tips}</div>
                </div>
            );
        } else {
            _renderView = (
                <div style={betBoxStyles.betAmount}>
                    <BetAmountInput
                        _ref={(input) => {
                            this.nameInput = input;
                        }}
                        betAmount={this.props.betAmount}
                        disabled={this.state.disabled}
                        onChangeText={(text) => {
                            this._onChangeText(text);
                        }}
                        onBlur={(text) => {
                            this.props.onBlur && this.props.onBlur(text);
                            this._betAmount(text.replace(/\D/g, ''));
                        }}
                        // onEndEditing={(text) => {
                        //     this._betAmount(text);
                        // }}
                        // onChangeText={(text) => {
                        //     this._betAmount(text);
                        // }}

                    />
                    {/*<TextInput*/}
                    {/*keyboardType={'numeric'}*/}
                    {/*// value={this.state.betAmount && String(this.state.betAmount)}*/}
                    {/*placeholder={!this.state.disabled ? "下注金额" : "停止投注"}*/}
                    {/*editable={!this.state.disabled}*/}
                    {/*style={betBoxStyles.betAmountInput}*/}
                    {/*onChangeText={(text) => {*/}
                    {/*this.text = text;*/}
                    {/*// this._betAmount(text);*/}
                    {/*// this.setBetAmount(text);*/}
                    {/*}}*/}
                    {/*onEndEditing={() => {*/}
                    {/*this._betAmount();*/}
                    {/*}}*/}
                    {/*onBlur={() => {*/}
                    {/*this._betAmount();*/}
                    {/*}}*/}
                    {/*/>*/}
                </div>
            );
        }
        return _renderView;
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };

    /**
     * 确认投注
     * */
    _betting_check = () => {
        const currentBetTemplate = this.props.betTemplate;
        console.log(currentBetTemplate)
        return
        // if (currentBetTemplate.name === "连码" || currentBetTemplate.name === "合肖" || currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连" || currentBetTemplate.name === "全不中") {
        //     return;
        // } else {
        //     if (!this.props.total.count) {
        //         PublicUtils.showToast("请选择投注号码");
        //         return;
        //     }
        // }
        if (!this.props.total.count) {
            PublicUtils.showToast("请选择投注号码");
            return;
        }
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要投注吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._betting_submit();
                    }
                },
            ]
        });
    };
    /**
     * 投注
     * */
    _betting_submit = () => {
        this.setState({showProgress: true});
        const currentBetTemplate = this.props.betTemplate;
        const currentBetNumber = this.props.betContent;
        let parm = {
            is_code: this.props.code,
            is_no: this.props.lotteryInfo.lottery_no,
            ball: {}
        };
        //PC蛋蛋
        if (this.props.templateType === "PCDD") {
            let item = {};
            let bet_index = 0;
            for (let i = 0; i < this.props.betTemplateList.length; i++) {
                for (let l = 0; l < this.props.betTemplateList[i].nums.length; l++) {
                    bet_index++;
                    item[bet_index] = this.props.betTemplateList[i].nums[l][2] || "";
                }
            }
            parm.ball["1"] = item;
        }
        //六合彩
        else if (this.props.templateType === "LHC") {
            if (currentBetTemplate.subPlayInfo) {
                parm.bet_sub = currentBetTemplate.subPlayInfo.value;
            }
            parm.bet_method = 1;
            parm.is_play = currentBetTemplate.oddType;
            let item = {};
            if (currentBetTemplate.name === "特码") {
                //B面
                if (currentBetTemplate.subPlayInfo.label === "B面") {
                    parm.is_odds = 2;
                }
                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    item[i + 1] = currentBetTemplate.nums[i][2] || "";
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "正一特" || currentBetTemplate.name === "正二特" || currentBetTemplate.name === "正三特"
                || currentBetTemplate.name === "正四特" || currentBetTemplate.name === "正五特" || currentBetTemplate.name === "正六特" || currentBetTemplate.name === "正码"
                || currentBetTemplate.name === "一肖" || currentBetTemplate.name === "尾数" || currentBetTemplate.name === "总和") {
                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    item[i + 1] = currentBetTemplate.nums[i][2] || "";
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "特码生肖") {

                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    if (currentBetTemplate.nums[i][2]) {
                        item[i + 63] = currentBetTemplate.nums[i][2] || "";
                    }
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "特码尾数") {

                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    if (currentBetTemplate.nums[i][2]) {
                        item[i + 75] = currentBetTemplate.nums[i][2] || "";
                    }
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "连码" || currentBetTemplate.name === "合肖" || currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连" || currentBetTemplate.name === "全不中") {
                parm.bet_amount = currentBetNumber.amount;
                // alert(JSON.stringify(this.state.currentBetNumber));
                for (let i = 0; i < currentBetNumber.nums.length; i++) {
                    for (let l = 0; l < currentBetTemplate.nums.length; l++) {
                        if (currentBetTemplate.nums[l][0] === currentBetNumber.nums[i][0]) {
                            item[l + 1] = this.props.total.amount || "";
                        }
                    }
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
        }
        //其余彩种
        else {
            for (let i = 0; i < this.props.betTemplateList.length; i++) {
                let item = {};
                for (let l = 0; l < this.props.betTemplateList[i].nums.length; l++) {
                    item[l + 1] = this.props.betTemplateList[i].nums[l][2] || "";
                }
                parm.ball[i + 1] = item;
            }
        }
        parm.ball = JSON.stringify(parm.ball);
        Http.request("betting", parm, async (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: '/'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status) {
                    PublicUtils.showToast(res.data.message);
                    const user = JSON.parse(Storage.retrieveData("user"));
                    PublicUtils.updateUserInfo({is_balance: user.is_balance - this.props.total.amount});
                    this._clearingBetInfo();
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };
    /**
     * 清除投注
     * @private
     */
    _clearingBetInfo = () => {
        for (let i = 0; i < this.props.betTemplateList.length; i++) {
            this.props.betTemplateList[i].count = 0;
            for (let l = 0; l < this.props.betTemplateList[i].nums.length; l++) {
                this.props.betTemplateList[i].nums[l][2] = 0;
            }
        }
        this.props.clearBetInfoCallbck && this.props.clearBetInfoCallbck(this.props.betTemplateList);
        // this.setState({
        //     betAmount: 0,
        //     total: {count: 0, amount: 0}
        // });
    };

    render() {
        const styles = {

            disabledBtn: {
                opacity: 0.6
            },
        };
        const betBoxStyles = {
            betBoxShasow: {
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: -1
            },
            betBodyBox: {
                // position: "fixed",
                transition: "all 0.3s",
                // display: "none",
                // width: "80%",
                flexDirection: "column",
                backgroundColor: "#fff",
                left: 0,
                right: 0,
                bottom: "-100%",
                // flex: 1,
                zIndex: 1,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 3,
                overflow: "hidden",
                position: "absolute"
                // opacity: 0,
                // transform: "scale(0)"
            },
            betTitle: {
                color: style['primaryColor' + model.SKINNAME],
                // color: "#fff",
                padding: "5px 0 10px",
                borderRadius: 3,
                textAlign: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            betInfo: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                height: 30,
                alignItems: "center",
                fontSize: 14
            },
            betInfoText: {
                color: "#000",
                marginLeft: 5,
                marginRight: 5,
            },
            betInfoInnerText: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            betContentText: {
                backgroundColor: "#ffe87b",
                paddingLeft: 10,
                paddingRight: 10,
                height: 20,
                borderRadius: 20,
                lineHeight: "20px",
                color: "#ff2d4a"
            },
            betNumberMaxWidth: {
                width: 100,
                textAlign: "center"
            },
            betInfoOddsText: {
                color: "rgba(246, 48, 85, 0.94)",
            },
            betAmount: {
                display: "flex",
                backgroundColor: "#f5f7f9",
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 35,
                border: "1px solid rgba(0,0,0,0.05)",
                flex: 4,
                justifyContent: "center",
                fontSize: 12
            },
            betAmountInput: {
                display: "flex",
                flex: 1,
                padding: 0,
                textAlign: "center"
            },
            betAmountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 5,
            },
            betAmountTemplateItem: {
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.08)",
                borderRadius: 3,
                fontSize: 12
            },
            betAmountActiveItem: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betAmountTemplateItemText: {
                color: "#000",
            },
            betAmountActiveItemText: {
                color: "#fff"
            },
            showShasow: {
                zIndex: 10
            },
            showBetBox: {
                // display: "flex"
                bottom: 50
                // opacity: 1,
                // transform: "scale(1)"
            },
            betBtn: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                height: 40,
                margin: "5px",
                borderRadius: 3
            }
        };
        const currentBetTemplate = this.props.betTemplate;
        //Y轴位移
        // this.betBoxTranslateY = this.state.betTranslateY.interpolate({
        //     inputRange: [0, 1],
        //     outputRange: [300, 0]
        // });
        return (
            <div onClick={(event) => {
                event.stopPropagation();
            }}
                 style={{...betBoxStyles.betBodyBox, ...this.state.show ? betBoxStyles.showBetBox : null}}>
                {/*<div style={betBoxStyles.betTitle}>*/}
                {/*{this.state.betTemplate.name}*/}
                {/*</div>*/}
                {
                    (currentBetTemplate.name === "连码" || currentBetTemplate.name === "合肖" || currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连" || currentBetTemplate.name === "全不中") ?
                        this._renderBetInfo() : null
                }
                <div style={betBoxStyles.betAmountTemplate}>
                    <div
                        style={{...betBoxStyles.betAmountTemplateItem, ...this.props.betAmount === 10 ? betBoxStyles.betAmountActiveItem : null, ...this.state.disabled ? styles.disabledBtn : null}}
                        onClick={() => {
                            !this.state.disabled && this._betAmount(10);
                        }}>
                        <div
                            style={{...betBoxStyles.betAmountTemplateItemText, ...this.props.betAmount === 10 ? betBoxStyles.betAmountActiveItemText : null}}>10
                        </div>
                    </div>
                    <div
                        style={{...betBoxStyles.betAmountTemplateItem, ...this.props.betAmount === 50 ? betBoxStyles.betAmountActiveItem : null, ...this.state.disabled ? styles.disabledBtn : null}}
                        onClick={() => {
                            !this.state.disabled && this._betAmount(50);
                        }}>
                        <div
                            style={{...betBoxStyles.betAmountTemplateItemText, ...this.props.betAmount === 50 ? betBoxStyles.betAmountActiveItemText : null}}>50
                        </div>
                    </div>
                    <div
                        style={{...betBoxStyles.betAmountTemplateItem, ...this.props.betAmount === 100 ? betBoxStyles.betAmountActiveItem : null, ...this.state.disabled ? styles.disabledBtn : null}}
                        onClick={() => {
                            !this.state.disabled && this._betAmount(100);
                        }}>
                        <div
                            style={{...betBoxStyles.betAmountTemplateItemText, ...this.props.betAmount === 100 ? betBoxStyles.betAmountActiveItemText : null}}>100
                        </div>
                    </div>
                    <div
                        style={{...betBoxStyles.betAmountTemplateItem, ...this.props.betAmount === 500 ? betBoxStyles.betAmountActiveItem : null, ...this.state.disabled ? styles.disabledBtn : null}}
                        onClick={() => {
                            !this.state.disabled && this._betAmount(500);
                        }}>
                        <div
                            style={{...betBoxStyles.betAmountTemplateItemText, ...this.props.betAmount === 500 ? betBoxStyles.betAmountActiveItemText : null}}>500
                        </div>
                    </div>

                    {
                        global.ID == 'yy'?<div></div>:<div
                          style={{...betBoxStyles.betAmountTemplateItem, ...this.props.betAmount === 1000 ? betBoxStyles.betAmountActiveItem : null, ...this.state.disabled ? styles.disabledBtn : null,}}
                          onClick={() => {
                              !this.state.disabled && this._betAmount(1000);
                          }}>
                            <div
                              style={{...betBoxStyles.betAmountTemplateItemText, ...this.props.betAmount === 1000 ? betBoxStyles.betAmountActiveItemText : null}}>1000
                            </div>
                        </div>
                    }



                    {
                        this._renderBetTipOrAmountTextInput()
                    }
                </div>
                {/*{*/}
                {/*(this.props.betTemplate.name === "连码" || this.props.betTemplate.name === "合肖" || this.props.betTemplate.name === "生肖连" || this.props.betTemplate.name === "尾数连" || this.props.betTemplate.name === "全不中") ?*/}

                {/*<div style={betBoxStyles.betBtn} onClick={() => {*/}
                {/*this._betting_check();*/}
                {/*}}>*/}
                {/*{*/}
                {/*this.state.showProgress ? this._showProgress() : <div>立即投注</div>*/}
                {/*}*/}
                {/*</div> : null*/}
                {/*}*/}
            </div>
        )
    }
}
