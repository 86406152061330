/**
 * 消息
 */

import React, {Component} from 'react'
import PropTypes from "prop-types";
import Icon from "../../utils/icon";
import Image from "../../component/image";
import model from "../../services/model";

export default class Avatar extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
        }
    }

    static propTypes = {
        source: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    };
    static defaultProps = {
        source: "",
        width: 45,
        height: 45,
    };

    _onLoad(nextProps, nextState) {
        this.setState({status: 1})
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.source !== nextProps.source || this.state.status !== nextState.status;

    }

    render() {

        const imgWidth = (model.clientWidth - 50) / 5;
        const styles = {
            container: {
                position: "relative",
                overflow: "hidden",
                // elevation: 3
                // width:30,
                // height:30,
            },
            avatorContainer: {
                display: "flex",
                flexDirection: "column",
                // backgroundColor:"#000",
                borderRadius: this.props.width,
                overflow: 'hidden',
                // backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
            },
            headerImg: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                borderRadius: 3,
                overflow: 'hidden',
                justifyContent: "center",
                alignItems: "center",
            },
            iconBox: {
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                borderRadius: this.props.width,
                overflow: 'hidden',
                backgroundColor: "#ECECEC",
                justifyContent: "center",
                alignItems: "center",
                // elevation: 3
            }
        };
        return (
            <div style={{...styles.container, ...{width: this.props.width, height: this.props.height}}}>
                {
                    this.props.source ?
                        <div style={{
                            ...styles.avatorContainer, ...{
                                width: this.props.width,
                                height: this.props.height
                            }
                        }}>
                            <img
                                style={{...styles.headerImg, ...{width: this.props.width, height: this.props.height}}}
                                resizeMode={'stretch'} onLoad={() => {
                                this._onLoad();
                            }} src={this.props.source}
                            />
                        </div> : null
                }
                {
                    this.state.status == 0 || !this.props.source ?
                        <div style={{...styles.iconBox, ...{width: this.props.width, height: this.props.height}}}>
                            <Icon icon={'new-username'} color={"#c9c9c9"} size={25}/>
                        </div> : null
                }
            </div>
        )
        // return (
        //     <View style={styles.container}>
        //         <View style={[styles.iconBox, {width: this.props.width, height: this.props.height}]}>
        //             <Icon name={'new-username'} color={"#c9c9c9"} size={25}/>
        //         </View>
        //     </View>
        // )

    }
}
