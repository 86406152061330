/**
 * 投注类别
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "../../../../utils/icon";
import MyPicker from "../../../../component/myPicker";
import PublicUtils from "../../../../utils/publicUtils";
import style from '../../../../utils/config.scss'
import model from "../../../../services/model";
import SportMatchInfo from './sportMatchInfo';

export default class BetBody extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            item: {list: []},
            playId: this.props.playId,
            ballId: this.props.playId,
            betId: this.props.playId
        }
    }

    static propTypes = {
        item: PropTypes.object,
        playId: PropTypes.number,
        ballId: PropTypes.number,
        betId: PropTypes.number,
        cgOnPress: PropTypes.func
    };
    static defaultProps = {};

    componentWillReceiveProps(nextProps) {
        this.setState({
            item: nextProps.item,
            playId: nextProps.playId,
            ballId: nextProps.ballId,
            betId: nextProps.betId
        });
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.item !== this.props.item;
    // }
    /**
     * 下注内容
     * _GQ_JR_ZP_:滚球，_GJ_：冠军，_CG_：串关
     * @type {{}}
     */
    betinfo = {
        _GQ_JR_ZP_GJ_: {},
        _CG_: {list: [], odds: ""}
    };

    /**
     * 滚球 今日 早盘 过关
     * @private
     */
    _GQ_JR_ZP_GG = () => {
        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingLeft: 5,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: 5,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14,
                color: "#fff"
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return this.state.item.list.map((childitem, key) => {
            return (
                <div key={key} style={matchStyles.matchDetailList}>

                    <div style={matchStyles.childMatchHeader}>

                        <div style={matchStyles.top}>
                            <div>
                                {
                                    this.props.playId === 0 ?
                                        <div>
                                            <div
                                                style={{...matchStyles.bigSize}}>{childitem[4]} {childitem[5]}</div>
                                        </div> : null
                                }
                                {
                                    this.props.playId !== 0 ?
                                        <div>
                                            <div
                                                style={{...matchStyles.bigSize}}>{childitem[4]}`
                                            </div>
                                        </div> : null
                                }
                            </div>
                            <div style={matchStyles.playTypeIconBox}>
                                <div>
                                    <div
                                        style={{...matchStyles.bigSize, ...matchStyles.playTypeIcon}}>滚球
                                    </div>
                                </div>
                                <Icon icon={"game-sport"} size={18} color={"#fff"}/>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: 'stretch',
                        }}>

                            <div style={{...matchStyles.childMatchHeaderCol}}>
                                <div style={matchStyles.childMatchNameBox}>
                                    <div
                                        style={matchStyles.childMatchName}>{childitem[2]}</div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        width: 30,
                                        height: "100%",
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: "#f7b000",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 3
                                    }}>
                                    <div style={{borderRadius: 3, color: "#fff"}}>主</div>
                                </div>
                            </div>
                            {
                                this.props.playId === 2 ?
                                    <div style={{...matchStyles.childMatchHeaderMid}}>
                                        <div style={matchStyles.childMatchHeaderMidText}>{childitem[5]}</div>
                                        <div style={matchStyles.childMatchHeaderMidText}>-</div>
                                        <div style={matchStyles.childMatchHeaderMidText}>{childitem[6]}</div>
                                    </div> : null
                            }
                            {
                                this.props.playId !== 2 ?
                                    <div style={matchStyles.childMatchHeaderMid}>
                                        <div style={matchStyles.childMatchHeaderMidText}>VS</div>
                                    </div> : null
                            }
                            <div style={matchStyles.childMatchHeaderCol}>
                                <div
                                    style={{
                                        display: "flex",
                                        width: 30,
                                        height: "100%",
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: "#f7b000",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 3
                                    }}>
                                    <div style={{borderRadius: 3, color: "#fff"}}>客</div>
                                </div>
                                <div style={matchStyles.childMatchNameBox}>
                                    <div
                                        style={matchStyles.childMatchName}>{childitem[3]}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/**全场（独赢）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={1} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（让球）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={3} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（大小）-**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={5} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（单双）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={7} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半场（独赢）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={2} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半场（让球）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={4} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半场（大小）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={6} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（波胆）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={9} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**全场（主队得分）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={14} subPlayName={'主队得分'}
                                    onOddPress={(template) => {
                                        this._onPress(template, childitem);
                                    }}/>
                    {/**全场（客队得分）**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={14} subPlayName={'客队得分'}
                                    onOddPress={(template) => {
                                        this._onPress(template, childitem);
                                    }}/>
                    {/**总入球**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={11} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>
                    {/**半全场**/}
                    <SportMatchInfo betId={this.props.betId} ballId={this.props.ballId} playId={this.props.playId}
                                    matchInfo={childitem} subPlayId={13} onOddPress={(template) => {
                        this._onPress(template, childitem);
                    }}/>

                </div>
            )
        })
    };
    /**
     * 冠军
     * @private
     */
    _GJ = () => {
        const styles = {
            resultItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            resultItem: {
                display: "flex",
                flex: 1,
                textAlign: "center",
                justifyContent: "center"
            },
            resultItemBf: {
                fontSize: 16
            },
            resultItemName: {
                fontSize: 14
            }
        };
        const matchStyles = {
            matchInfoBox: {
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                display: "flex",
                flex: 1
            },
            activeItem: {
                backgroundColor: "rgba(255, 166, 55, 0.15)",
                transition: "all 0.3s"
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center",
                flex: 1
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flex: 1,
            },

            matchIcon: {
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s"
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingLeft: 5,
                overflow: "hidden",
                flex: 1,
                display: "flex",
                width: 0
            },
            matchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            arrowRight: {
                transform: "rotate(-90deg)"
            },
            arrowDown: {
                transform: "rotate(0)"
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottom: "1px solid #f5f5f5",
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: 'stretch',
                backgroundColor: style['primaryColor' + model.SKINNAME],
                padding: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: 30,
                borderRadius: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 12,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                display: "flex",
                borderBottom: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return this.state.item.list.map((childitem, key) => {
            return (
                <div key={key} style={matchStyles.matchDetailList}>
                    <div style={matchStyles.gjTop}>
                        <div style={matchStyles.themeText}>{childitem[2]}</div>
                    </div>
                    <div style={matchStyles.gjChildList}>
                        {
                            childitem[3].map((item, i) => {
                                return (
                                    <div key={i}
                                         onClick={() => {
                                             this._onPress(i, childitem);
                                         }} style={matchStyles.gjChildItem}>
                                        <div>{childitem[3][i]}</div>
                                        <div
                                            style={{...childitem[6] && childitem[6][i] === 0 ? matchStyles.fc0 : childitem[6] && childitem[6][i] === 2 ? matchStyles.fc2 : matchStyles.fc1}}>{childitem[4][i]}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        })
    };

    /**
     * 滚球/今日/早盘下注
     * @param template：数据模板 data：数据
     */
    _BET_GQ_JR_ZP = (template, data) => {
        model.betinfo._GQ_JR_ZP_GJ_.gid = template.bet_list.id;
        model.betinfo._GQ_JR_ZP_GJ_.info = data;
        model.betinfo._GQ_JR_ZP_GJ_.gameId = template.play_list.typeid;
        model.betinfo._GQ_JR_ZP_GJ_.gameTypeName = template.play_list.name;
        model.betinfo._GQ_JR_ZP_GJ_.betContent = template.bet_list.name;
        model.betinfo._GQ_JR_ZP_GJ_.pk = data[template.play_list.pk_value];
        model.betinfo._GQ_JR_ZP_GJ_.bs = data[template.play_list.pk_id];
        model.betinfo._GQ_JR_ZP_GJ_.typeid = template.play_list.id;
        model.betinfo._GQ_JR_ZP_GJ_.odds = data[template.bet_list.odd];
        if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0)) {
            model.betinfo._GQ_JR_ZP_GJ_.pk = "+" + data[template.play_list.pk_value];
        }
        if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0)) {
            model.betinfo._GQ_JR_ZP_GJ_.pk = "-" + data[template.play_list.pk_value];
        }
        // this.setModalVisible(true);
        const parma = {
            playId: this.props.playId,
            ballId: this.props.ballId,
            betId: this.props.betId,
            betinfo: model.betinfo,
            template: template,
            data: data
        };
        PublicUtils.navigate("HgBetting", parma);
    };

    /**
     * 串关下注
     * @param template：数据模板 data：数据
     */
    _BET_CG = (template, data) => {
        let betStatus = this.isExisMatchinfo(template, data);
        if (betStatus === 2) {
            alert("一场赛事只能投注一次哦");
            return;
        }
        if (betStatus === 0) {
            let betInfo: any = {
                id: data[0],
                gid: template.bet_list.id,
                info: data,
                gameId: template.play_list.typeid,
                gameTypeName: template.play_list.name,
                betContent: template.bet_list.name,
                pk: data[template.play_list.pk_value],
                bs: data[template.play_list.pk_id],
                typeid: template.play_list.id,
                odds: data[template.bet_list.odd],
            }
            if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                    && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0)) {
                betInfo.pk = "+" + data[template.play_list.pk_value];
            }
            if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                    && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0)) {
                betInfo.pk = "-" + data[template.play_list.pk_value];
            }
            model.betinfo._CG_.list.push(betInfo);
            // this.getTotalOdd();
            // this.setState({_CG_: {list: this.betinfo._CG_}}, () => {
            //     this.getTotalOdd();
            // });
        }
        this.props.cgOnPress();
    };
    /**
     * 冠军下注
     * @param template：数据模板 data：数据
     */
    _BET_GJ = (template, data) => {
        model.betinfo._GQ_JR_ZP_GJ_.gid = data[5][template];
        model.betinfo._GQ_JR_ZP_GJ_.info = data;
        model.betinfo._GQ_JR_ZP_GJ_.gameId = 99;
        model.betinfo._GQ_JR_ZP_GJ_.gameTypeName = "冠军";
        model.betinfo._GQ_JR_ZP_GJ_.betContent = data[3][template];
        model.betinfo._GQ_JR_ZP_GJ_.bs = data[0];
        model.betinfo._GQ_JR_ZP_GJ_.typeid = 99;
        model.betinfo._GQ_JR_ZP_GJ_.odds = data[4][template];
        // this.setModalVisible(true);
        const parma = {
            playId: this.props.playId,
            ballId: this.props.ballId,
            betId: this.props.betId,
            betinfo: model.betinfo,
            template: template,
            data: data
        };
        PublicUtils.navigate("HgBetting", parma);
    };


    /**
     * 检查是否添加过改场比赛的投注信息（过关）
     * @param item
     */
    isExisMatchinfo(template, data) {
        let result = 0;
        for (let i = 0; i < model.betinfo._CG_.list.length; i++) {
            if (model.betinfo._CG_.list[i].info[2] === data[2] && model.betinfo._CG_.list[i].info[3] === data[3]) {
                result = 1;
                if (model.betinfo._CG_.list[i].betContent === template.bet_list.name && model.betinfo._CG_.list[i].bs === template.play_list.pk_id) {
                    result = 2;
                } else {
                    model.betinfo._CG_.list[i].id = data[0];
                    model.betinfo._CG_.list[i].gid = template.bet_list.id;
                    model.betinfo._CG_.list[i].info = data;
                    model.betinfo._CG_.list[i].gameId = template.play_list.typeid;
                    model.betinfo._CG_.list[i].gameTypeName = template.play_list.name;
                    model.betinfo._CG_.list[i].betContent = template.bet_list.name;
                    model.betinfo._CG_.list[i].pk = data[template.play_list.pk_value];
                    model.betinfo._CG_.list[i].bs = data[template.play_list.pk_id];
                    model.betinfo._CG_.list[i].typeid = template.play_list.id;
                    model.betinfo._CG_.list[i].odds = data[template.bet_list.odd];
                    if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                            && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0)) {
                        model.betinfo._CG_.list[i].pk = "+" + data[template.play_list.pk_value];
                    }
                    if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                            && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0)) {
                        model.betinfo._CG_.list[i].pk = "-" + data[template.play_list.pk_value];
                    }
                }
            }
        }

        // this.setState({_CG_: {list: this.betinfo._CG_}}, () => {
        //     this.getTotalOdd();
        // });
        // this.getTotalOdd();
        return result;
    }

    /**
     * 计算串关玩法总赔率
     */
    getTotalOdd = () => {
        let totalOdd = 1;
        const _CG_ = model.betinfo._CG_.list;
        const _GQ_JR_ZP_GJ_ = model.betinfo._GQ_JR_ZP_GJ_;
        if (this.props.playId === 4) {
            for (let i = 0; i < _CG_.length; i++) {
                if (_CG_[i].typeid === 3 || _CG_[i].typeid === 4 || _CG_[i].typeid === 5 || _CG_[i].typeid === 6 || _CG_[i].typeid === 14) {
                    totalOdd = totalOdd * (1 + Number(_CG_[i].odds));
                } else {
                    totalOdd = totalOdd * _CG_[i].odds;
                }
            }
        } else {
            if (_GQ_JR_ZP_GJ_.typeid === 3 || _GQ_JR_ZP_GJ_.typeid === 4 || _GQ_JR_ZP_GJ_.typeid === 5 || _GQ_JR_ZP_GJ_.typeid === 6 || _GQ_JR_ZP_GJ_.typeid === 14) {
                totalOdd = totalOdd * Number(_GQ_JR_ZP_GJ_.odds);
            } else {
                totalOdd = totalOdd * (_GQ_JR_ZP_GJ_.odds - 1);
            }
        }
        model.betinfo._CG_.odds = PublicUtils.subStringNum(totalOdd);
        this.setState({odds: model.betinfo._CG_.odds});
        return totalOdd;
    };
    /**
     * 点击下注
     * @param template：数据模板 data：数据
     */
    _onPress = async (template, data) => {
        if (!await PublicUtils._checkLogin()) {
            PublicUtils.navigate('Login', {nextPage: 'HG'});
            return;
        }
        if (this.props.playId === 0 || this.props.playId === 1 || this.props.playId === 2) {
            if (template.play_list.pk_value && template.play_list.pk_type === "c") {
                template.play_list.pk_value = "-" + template.play_list.pk_value;
            }
            this._BET_GQ_JR_ZP(template, data);
        }
        if (this.props.playId === 4) {
            this._BET_CG(template, data);
        }
        if (this.props.playId === 5) {
            this._BET_GJ(template, data);
        }
    };


    render() {
        return (
            <div style={matchStyles.matchChildBox}>
                {(this.props.playId === 0 || this.props.playId === 1 || this.props.playId === 2 || this.props.playId === 4) ? this._GQ_JR_ZP_GG() : this._GJ()}
            </div>
        )
    }
}

const matchStyles = {
    matchInfoBox: {
        flexDirection: "column",
        borderBottomWidth: 1,
        borderBottomColor: "#f5f5f5"
    },
    matchHeaderButton: {
        flex: 1
    },
    matchHeaderBox: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10,
        height: 45,
        alignItems: "center"
    },
    matchChildBox: {},
    matchNameBox: {
        flexDirection: "row",
        alignItems: 'stretch',
        flex: 1
    },
    matchName: {
        color: "#333",
        fontSize: 13,
        paddingRight: 20
    },
    arrowRight: {
        transform: [{rotate: '-90deg'}]
    },
    matchChildCount: {
        height: 20,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 20,
        backgroundColor: "#ef4e70",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    matchChildCountText: {
        color: "#fff",
        fontSize: 10,
    },
    matchDetailList: {
        borderBottomColor: "#f5f5f5",
        borderBottomWidth: 1
    },
    top: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 5,
    },
    playTypeIconBox: {
        flexDirection: "row",
    },
    playTypeIcon: {
        marginRight: 5
    },
    bigSize: {
        fontSize: 14,
        color: "#fff"
    },
    themeText: {
        color: "@primary"
    },
    childMatchHeader: {
        flex: 1,
        flexDirection: "column",
        alignItems: 'stretch',
        backgroundColor: "@primary",
        padding: 10,
    },
    childMatchHeaderCol: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center"
    },
    childMatchHeaderMid: {
        paddingLeft: 10,
        paddingRight: 10,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    childMatchHeaderMidText: {
        color: "#e7d91b",
        fontSize: 16,
        marginLeft: 5,
        marginRight: 5,
    },
    childMatchNameBox: {
        flex: 1,
        height: 30,
        borderRadius: 3,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        overflow: 'hidden'
    },
    childMatchName: {
        fontSize: 12,
        color: "#333",
    },
    gjTop: {
        borderBottomColor: "#f5f5f5",
        borderBottomWidth: 1,
        paddingLeft: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    gjChildList: {
        flexDirection: "row",
        flexWrap: "wrap",
        flex: 1,
    },
    gjChildItem: {
        width: "50%",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        borderRightWidth: 1,
        borderRightColor: "#f5f7f9",
        borderBottomWidth: 1,
        borderBottomColor: "#f5f7f9"
    },
    fc0: {
        color: "#10a649"
    },
    fc1: {
        color: "#e8ab09"
    },
    fc2: {
        color: "#d53411"
    },
    btns: {
        borderRadius: 3
    }
};