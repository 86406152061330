/**
 * 注册图标（首页）
 */
import React from 'react'
import PropTypes from 'prop-types';
import PublicUtils from "../../../utils/publicUtils";
import Icon from '../../../utils/icon'

export default class FavoriteIcon extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {};
    }

    static propTypes = {
        navigation: PropTypes.object
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    }

    render() {
        return (
            <div style={styles.gameNameContainer}>
                <div style={styles.gameItemText}>{this.props.name}</div>
                <div
                         onClick={() => {
                         }}
                >
                    <Icon icon={this.props.favorite ? 'x-01' : 'x-02'}
                          color={this.props.favorite ? '#fe865a' : "rgba(0,0,0,0.8)"}
                          size={14}/>
                </div>
            </div>
        )
    }
}
const styles = {
    gameItemText: {
        height: 25,
        lineHeight: "25px",
        textAlign: "center",
        color:"rgba(0,0,0,0.8)"
    },
    gameNameContainer: {
        height:25,
        display:"flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
};
