import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import style from '../utils/config.scss'
import Container from '../component/container'
import Http from "../services/http";
import PublicUtils from '../utils/publicUtils'
import model from "../services/model";
import Storage from "../services/storage";
import global from "../global";

class Grade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            scoreList: [],
            nextGrade: {
                id: null,
                grade_score: null,
                grade_name: null
            },
            showScoreList: false
        }
    }

    componentDidMount() {
        this._init_();
    }

    componentWillUnmount() {
    }

    _init_ = () => {
        this._fetchGradeList();
        this._fetchScoreList();
    };
    /**
     * 获取等级列表
     * @private
     */
    _fetchGradeList = () => {
        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        const parm = {};
        Http.request("grade", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    let nextGrade = {};
                    for (let i = 0; i < res.data.list.length; i++) {
                        if (res.data.list[i].grade_name === user.grade_name) {
                            if (i + 1 < res.data.list.length) {
                                nextGrade = res.data.list[i + 1];
                            } else {
                                nextGrade.id = 0;
                            }
                        }
                    }
                    this.setState({
                        list: res.data.list,
                        grade_name: user.grade_name,
                        is_integral: user.is_integral,
                        nextGrade: nextGrade
                    });
                }
            }
        });
    };
    /**
     * 积分变动记录
     * @private
     */
    _fetchScoreList = () => {
        const parm = {};
        Http.request("score", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    this.setState({scoreList: res.data.list});
                }
            }
        });
    };
    /**
     * 升级
     * @private
     */
    _upgrade = () => {
        if (this.state.is_integral < this.state.nextGrade.grade_score) {
            PublicUtils.showToast("您当前的积分不够升级哦！");
            return
        }
        const parm = {
            id: this.state.nextGrade.id
        };
        Http.request("upgrade", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    this._upgradeSuccess();
                }
            }
        });
    };
    /**
     * 升级成功
     * @private
     */
    _upgradeSuccess = () => {
        PublicUtils.showToast("恭喜您成功升级至" + this.state.nextGrade.grade_name);
        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        user.grade_name = this.state.nextGrade.grade_name;
        user.is_integral = this.state.is_integral - this.state.nextGrade.grade_score;
        PublicUtils.saveUserInfo({user: user});
        this._init_();
    };


    render() {
        const styles = {
            header: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 14,
                paddingBottom: 20
            },
            headerInfoBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                marginBottom: 10
            },
            userNameBox: {
                fontSize: 30,
                height: 120,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            userLVBox: {
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 14
            },
            lvBox: {
                fontSize: 30,
            },
            realNameBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 14
            },
            userLVInfo: {
                backgroundColor: "#ffa600",
                height: 35,
                width: 80,
                textAlign: "center",
                lineHeight: "35px",
                borderRadius: 35,
                fontSize: 16
            },
            is_integral: {
                color: "rgb(255, 226, 4)",
                fontSize: 22,
                marginRight: 5
            },
            isAgent: {
                backgroundColor: "#27ce2e",
                padding: "5px",
                borderRadius: 3,
                marginLeft: 5
            },
            record: {
                // height: 30,
                // lineHeight: "30px",
                // backgroundColor: "#27ce2e",
                // paddingLeft: 10,
                // paddingRight: 10,
                // borderRadius: 30,
                color: "#ffe204",
                marginLeft: 10,
                fontSize: 18
            },
            gradeTitle: {
                textAlign: "center",
                marginTop: 10,
                fontSize: 18
            },
            gradeContent: {
                padding: 10,
                fontSize: 14,
                lineHeight: "22px",
                color: "rgba(0,0,0,0.7)"
            },
            gradeInfoBox: {
                display: "flex",
                flexDirection: "column",
                fontSize: 14,
                margin: 10
            },
            gradeHeaderBox: {
                flex: 1,
                display: "flex",
                flexDirection: "row",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                color: "#fff"

            },
            gradeHeaderCol: {
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                borderTop: "1px solid rgba(255,255,255,0.2)",
                borderRight: "1px solid rgba(255,255,255,0.2)",
                paddingTop: 10,
                paddingBottom: 10,
            },
            gradeBodyBox: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                fontSize: 12
            },
            gradeBodyRow: {
                display: "flex",
                flexDirection: "row",
                flex: 1,
                width: "100%"
            },
            gradeBodyCol: {
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                borderRight: "1px solid rgba(25, 24, 24, 0.28)",
                borderBottom: "1px solid rgba(25, 24, 24, 0.28)",
                paddingTop: 5,
                paddingBottom: 5,
                flexWrap: "wrap",
                backgroundColor: "#f5f7f9",
                alignItems: "center",
                // wordBreak: "break-all"
            },
            gradeInnerCol: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: 50,
                width: "50%",
                paddingTop: 5,
                paddingBottom: 5,
                borderRight: "1px solid rgba(255,255,255,0.5)",
                boxSizing: "border-box"
            },
            hasBorder: {
                borderTop: "1px solid rgba(255,255,255,0.5)",
            },
            upgradeScore: {
                color: "rgb(255, 226, 4)",
                margin: 3,
                fontSize: 18
            },
            col1: {
                flex: 1
            },
            col2: {
                flex: 2
            },
            scoreRecordShadow: {
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 11,
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            },
            scoreRecordBox: {
                width: "80%",
                backgroundColor: "#fff",
                borderRadius: 3,
                overflow: "hidden"
            },
            scoreRecordTitle: {
                backgroundColor: "#f5f7f9",
                fontSize: 16,
                textAlign: "center",
                height: 50,
                lineHeight: "50px"
            },
            scoreRecordBody: {
                maxHeight: 300,
                overflow: "auto",
                paddingLeft: 10,
                paddingRight: 10
            },
            scoreRecordBodyItem: {
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px dotted #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 14
            },
            scoreRecordBodyItemTop: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            },
            scoreRecordBodyItemTopLeft: {
                color: "#000",
            },
            scoreRecordBodyItemTopRight: {
                color: style['primaryColor' + model.SKINNAME]
            },
            scoreRecordBodyItemBottom: {
                marginTop: 5
            },
            scoreRecordBodyItemBottomLeft: {
                color: style['fontColorLight' + model.SKINNAME]
            },
            closeScoreRecordBox: {
                padding: 10,
            },
            closeScoreRecordBtn: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
                height: 40,
                lineHeight: "40px",
                borderRadius: 3
            }
        };
        return (
            <Container headerFontColor={"#fff"} headerBackgroundColor={style['primaryColor' + model.SKINNAME]} title={'我的等级'}
                       style={{backgroundColor: "#fff"}}>
                <div style={styles.header}>
                    <div style={{...styles.headerInfoBox, ...styles.userNameBox}}>
                        <div style={{...styles.headerInfoBox, ...styles.lvBox}}>{this.state.grade_name}</div>
                        <div style={{...styles.headerInfoBox, ...styles.realNameBox}}>
                            <div><span style={styles.is_integral}>{this.state.is_integral}</span>积分</div>

                            <div style={styles.record} onClick={() => {
                                this.setState({showScoreList: true});
                            }}>查看获取记录
                            </div>
                        </div>
                        <div style={{...styles.headerInfoBox, ...styles.realNameBox}}>
                            {this.state.nextGrade.id !== 0 ?
                                <div>消耗<span
                                    style={styles.upgradeScore}>{this.state.nextGrade.grade_score}</span>积分可升级<span
                                    style={styles.upgradeScore}>{this.state.nextGrade.grade_name}</span></div> :
                                <div style={styles.upgradeScore}>您已是最高等级</div>
                            }

                        </div>
                    </div>
                    <div style={{...styles.headerInfoBox, ...styles.userLVBox}}>
                        <div style={styles.userLVInfo} onClick={() => {
                            this._upgrade()
                        }}>升级
                        </div>
                    </div>
                </div>
                <div style={styles.gradeTitle}>等级与特权</div>
                <div style={styles.gradeInfoBox}>
                    <div style={styles.gradeHeaderBox}>
                        <div style={{...styles.gradeHeaderCol, ...styles.col1}}>等级<br/>特权</div>
                        <div style={{...styles.gradeHeaderCol, ...styles.col1}}>升级消<br/>耗积分</div>
                        <div style={{...styles.gradeHeaderCol, ...styles.col1}}>升级赠<br/>送彩金</div>
                        <div style={{...styles.gradeHeaderCol, ...styles.col1}}>生日<br/>彩金</div>
                        <div style={{...styles.gradeHeaderCol, ...styles.col2, ...{borderRight: "none"}}}>额外反<br/>水比例
                        </div>
                    </div>
                    <div style={styles.gradeBodyBox}>
                        {
                            this.state.list.map((item) => {
                                return (
                                    <div style={styles.gradeBodyRow}>
                                        <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.grade_name}</div>
                                        <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.grade_score}</div>
                                        <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.upgrade_bonus}<br/>元
                                        </div>
                                        <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.birthday_bonus}<br/>元
                                        </div>
                                        <div style={{...styles.gradeBodyCol, ...styles.col2}}>
                                            <div style={{...styles.gradeInnerCol}}>
                                                <div>体育</div>
                                                <div>{item.sports_discount}%</div>
                                            </div>
                                            <div style={{...styles.gradeInnerCol}}>
                                                <div>电子</div>
                                                <div>{item.game_discount}%</div>
                                            </div>
                                            <div style={{...styles.gradeInnerCol, ...styles.hasBorder}}>
                                                <div>彩票</div>
                                                <div>{item.lottery_discount}%</div>
                                            </div>
                                            <div style={{...styles.gradeInnerCol, ...styles.hasBorder}}>
                                                <div>真人</div>
                                                <div>{item.live_discount}%</div>
                                            </div>
                                            <div
                                                style={{...styles.gradeInnerCol, ...styles.hasBorder, ...{width: "100%"}}}>
                                                <div>棋牌</div>
                                                <div>{item.chess_discount}%</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


              {
                global.ID == 'b3'?<div style={{paddingLeft:10,paddingRight:10,paddingTop:10,paddingBottom:10}}>
                  <div style={styles.gradeHeaderBox}>
                    <div style={{...styles.gradeHeaderCol, ...styles.col1}}>等级<br/>特权</div>
                    <div style={{...styles.gradeHeaderCol, ...styles.col1}}>降级<br/>周期</div>
                    <div style={{...styles.gradeHeaderCol, ...styles.col1}}>降级<br/>最小流水</div>
                    <div style={{...styles.gradeHeaderCol, ...styles.col1}}>降级<br/>最小充值</div>


                  </div>

                  <div style={styles.gradeBodyBox}>
                    {
                      this.state.list.map((item) => {
                        return (
                            <div style={styles.gradeBodyRow}>
                              <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.grade_name}</div>
                              <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.lower_cycle}</div>
                              <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.min_amount}</div>
                              <div style={{...styles.gradeBodyCol, ...styles.col1}}>{item.min_deposit}</div>

                            </div>
                        )
                      })
                    }
                  </div>

                </div>:<div></div>
              }



                <div style={styles.gradeTitle}>获取积分</div>
                <div style={styles.gradeContent}>
                    等级的晋升在于有足够的积分，积分也就是等级制度的基础，
                    您登录我们的统一账号，通过充值参加活动等便可轻松获取积分，积分会加入您的账户。
                </div>
                <div style={styles.gradeTitle}>特别声明</div>
                <div style={styles.gradeContent}>
                    1.本站保留所有权利在任何时候都可以更改·终止·取消此活动,并不作提前通知。<br/>
                    2.为避免对文字的理解差异，本站保留以上方案的所有解释权。 <br/>
                    3.如果本次活动由于一些不可预测或超出本站控制之外的技术错误而无法进行，本站拥有免责、终止及撤销活动的权利。 <br/>
                    4.本站安全部门，若发现任何团体或个人使用不正当手段套取红利及彩金，如出现对押投注 以及所有无效投注、软件下注或以任何非正常方式进行投注者，将保留冻结您的账户盈利及余额的权利。<br/>

                </div>
                {
                    this.state.showScoreList ?
                        <div style={styles.scoreRecordShadow}>
                            <div style={styles.scoreRecordBox}>
                                <div style={styles.scoreRecordTitle}>积分获取记录</div>
                                <div className={'scorll'} style={styles.scoreRecordBody}>
                                    {
                                        this.state.scoreList.map((item, key) => {
                                            return (
                                                <div key={key} style={styles.scoreRecordBodyItem}>
                                                    <div style={styles.scoreRecordBodyItemTop}>
                                                        <div
                                                            style={styles.scoreRecordBodyItemTopLeft}>{item.is_explain}</div>
                                                        <div
                                                            style={styles.scoreRecordBodyItemTopRight}>{item.rate_amount}积分
                                                        </div>
                                                    </div>
                                                    <div style={styles.scoreRecordBodyItemBottom}>
                                                        <div
                                                            style={styles.scoreRecordBodyItemBottomLeft}>{PublicUtils.timestampFormat(item.add_time)}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div style={styles.closeScoreRecordBox}>
                                    <div style={styles.closeScoreRecordBtn} onClick={() => {
                                        this.setState({showScoreList: false});
                                    }}>关闭
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
            </Container>
        )
    }
}

export default withRouter(Grade);
