/**
 * 按钮
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PublicUtils from "../../../utils/publicUtils";
import Image from "../../../component/image";

class GroupBtns extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {};
    static defaultProps = {};
    /**
     * 跳转到app下载页面
     * @private
     */
    _downAppPage = () => {
        PublicUtils.linking(this.props.downloadUrl, "_blank");
        // PublicUtils.linking(this.state.downloadUrl, "_blank");
        // window.location.href = this.state.downloadUrl;
    };
    render() {
        return (

            <div style={styles.btnGroupContainer}>
                {/*<IButton style={{flex: 1}} type={2}*/}
                {/*         onPress={(item) => {*/}

                {/*         }}>*/}
                {/*    <View style={styles.btnGroupItemContainer}>*/}
                {/*        <Image style={styles.btnGroupItemImg} resizeMode='contain'*/}
                {/*               source={require('../../../assets/images/theme1/pt3-1.png')}/>*/}
                {/*        <MyText style={styles.btnGroupItemText}>APP下载</MyText>*/}
                {/*    </View>*/}
                {/*</IButton>*/}
                <div style={{flex: 1}} type={2}
                     onClick={(item) => {
                         this._downAppPage();
                         // PublicUtils.navigate("Favorite")
                     }}>
                    <div style={styles.btnGroupItemContainer}>
                        <Image style={styles.btnGroupItemImg} resizeMode='contain'
                               uri={require('../../../assets/images/theme1/pt3-1.png')}/>
                        <div style={styles.btnGroupItemText}>APP下载</div>
                    </div>
                </div>
                <div style={{flex: 1}} type={2}
                         onClick={(item) => {
                             PublicUtils.navigate("Favorite")
                         }}>
                    <div style={styles.btnGroupItemContainer}>
                        <Image style={styles.btnGroupItemImg} resizeMode='contain'
                               uri={require('../../../assets/images/theme1/pt3-2.png')}/>
                        <div style={styles.btnGroupItemText}>我的最爱</div>
                    </div>
                </div>
                <div style={{flex: 1}} type={2}
                     onClick={(item) => {
                             PublicUtils.navigate("Active")
                         }}>
                    <div style={styles.btnGroupItemContainer}>
                        <Image style={styles.btnGroupItemImg} resizeMode='contain'
                               uri={require('../../../assets/images/theme1/pt3-3.png')}/>
                        <div style={styles.btnGroupItemText}>优惠活动</div>
                    </div>
                </div>
            </div>
        )

    }
}

const styles = {
    btnGroupContainer: {
        display:"flex",
        flexDirection: "row",
        paddingLeft: 5,
        paddingRight: 5,
        // backgroundColor: "#fff"
    },
    btnGroupItemContainer: {
        display:"flex",
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        border:"1px solid #f5f7f9",
        // borderWidth: 1,
        // borderStyle: "solid",
        // borderColor: "#f5f7f9",
        backgroundColor:"#fff"
        // elevation: 1
    },
    btnGroupItemImg: {
        width: 25,
        height: 25,
    },
    btnGroupItemText: {
        fontSize:14,
        marginLeft: 5,
        color: "#000"
    },
};
export default GroupBtns;
