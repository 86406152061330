//首页

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import PublicUtils from '../../../utils/publicUtils'
import Storage from '../../../services/storage'
import Container from '../../../component/container'
import HotActive from '../../../component/hotActive'
import Image from '../../../component/image'
import DrawerPage from '../../../component/drawerPage'
import Icon from '../../../utils/icon'
import style from '../../../utils/config.scss'
import Http from "../../../services/http";
import model from "../../../services/model";
import ReactMCarousel from 'react-m-carousel'
import '../../../assets/css/app.css'
import global from "../../../global";
import HotAlotGameList from '../../../component/hotAlotGameList';
import NoticeContainer from '../../../component/noticeContainer';
import FloatBtn from '../../../component/floatBtn'
import {GroupBtns, GameTab, GameBody, TopIcon} from "../components/index";

class Theme1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: [],//轮播
            recommend: [],//推荐
            activity: [],//活动
            noticeList: [],
            playdata: [],
            activity_game_list: [],
            redbag: {},
            pcUrl: null,
            downloadUrl: null,
            notice_win: null,
            show: true,
            favorite: [],
            gameTabIndex: 0,
            hasLogin:false,
        };
        this.state.hasLogin = PublicUtils._checkLogin();

        //
        global.syObj = this;

    }


    componentDidMount = async () => {
        // Http.requestJSON("http://222.186.30.101:8181/new/game/tsconfig.json", (res) => {
        //     console.log(JSON.stringify(res));
        // })
        // this._fetchNotice();
        // const token = await Storage.retrieveData("token");
        // console.log(token);
        // let ws = new WebSocket("ws://nbs-test-front.613159.com?token=" + token + "&room_id=1");
        // ws.onopen = e => {
        //     console.log("onopen", e);
        // };
        // ws.onmessage = evt => {
        //     console.log("onmessage", JSON.parse(evt.data));
        //     // this.setState({
        //     //     Info: JSON.parse(evt.data)
        //     // });
        //     // DeviceEventEmitter.emit("sceneChange", {
        //     //     // 参数
        //     // });
        // };
        // ws.onclose = e => {
        //     console.log("onclose", e);
        // };
        // ws.onerror = e => {
        //     console.log("onerror", e);
        // };
        // // this.setState({
        // //     ws: ws
        // // });
        // ws = null;
    }

    componentWillUnmount = async () => {

    }
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        PublicUtils.getBestUrl((res) => {
            // PublicUtils.showLoder("正在获取最优线路");
            if (res.status) {
                this._fetchData();
            } else {
                PublicUtils.showToast(res.msg, {type: "FAILD"});
            }
        });
    };

    /**
     * 提交代理
     */
    postAgent() {
        let parm = {
            // agent: this.ComFn.getQueryString("ag")
        };
        Http.request("postAgent", parm, (data) => {
            if (data.data.status === 1) {
            }
        });
    }

    /**
     * 获取数据
     * @private
     */
    _fetchData = () => {
        let parm = {};
        Http.request("getMainPageData", parm, (res) => {
            PublicUtils.hideLoader();
            // return;
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                let game_list_mobile = res.data.game_list_mobile;


                let gamelist = []
                for (let i = 0; i < game_list_mobile.length; i++) {
                    if (game_list_mobile[i].type_id === 8) {
                        game_list_mobile.splice(i, 1);
                    }
                }
                if (global.ID == "b5") {


                    //
                    game_list_mobile[3].gamelist.push({
                        game_api: "VIP",
                        game_id: 407,
                        game_list_img_path: "/new/slot_res/vip/",
                        icon: "byw",
                        name: "VIP捕鱼王",
                        type: 4,
                        hotlist: [
                            {
                                Button_Image_Name: "byw.jpg",
                                CN_Name: "捕鱼王",
                                HTML5_Game_ID: "6"
                            }
                        ]
                    });


                    gamelist.push(game_list_mobile[0].gamelist[0]);
                    gamelist.push(game_list_mobile[2].gamelist[0]);
                    gamelist.push(game_list_mobile[0].gamelist[1]);
                    gamelist.push(game_list_mobile[1].gamelist[2]);
                    gamelist.push(game_list_mobile[4].gamelist[1]);
                    gamelist.push(game_list_mobile[4].gamelist[0]);
                    gamelist.push(game_list_mobile[0].gamelist[2]);
                    gamelist.push(game_list_mobile[2].gamelist[7]);
                    gamelist.push(game_list_mobile[2].gamelist[1]);
                    gamelist.push(game_list_mobile[1].gamelist[0]);


                const hotGame = {
                    gamelist,
                    icon:"new-hot",
                    name:"热门",
                    type_id:0,
                }
                    game_list_mobile.unshift(hotGame)
                }
                model.gameList = game_list_mobile;
                Storage.storeData({game_list: JSON.stringify(game_list_mobile)});
                if (res.data.service_mobile) {
                    model.serviceUrl = res.data.service_mobile;
                    Storage.storeData({serviceUrl: res.data.service_mobile});
                }
                let hotGameList = [];
                const indexList = res.data.new_index || res.data.index;
                for (let item in indexList) {
                    let game = model.getGamesInfoById(item.split('_')[1]);
                    if (game) {
                        game.hot_type = indexList[item];
                        game.showTitle = item.split('_').length > 2 ? item.split('_')[2] : 1;
                        hotGameList.push(game);
                    }
                }
                let noticeList = [];
                if (res.data.notice.status) {
                    for (let i = 0; i < res.data.notice.txt.length; i++) {
                        if (res.data.notice.txt[i]) {
                            noticeList.push(res.data.notice.txt[i]);
                        }
                    }
                }
                // let pcUrl = res.data.pc_url + "/main/index.html";
                // if (global.ID !== "ca") {
                //     pcUrl = res.data.pc_url + "/main/index.html?from=mobile";
                // }
                let pcUrl = res.data.pc_url + "/main/index.html?from=mobile";
                const favorite = (Storage.retrieveData("favorite") && JSON.parse(Storage.retrieveData("favorite"))) || [];

                // 空值处理
                let bas =[];
                for (let i=0; i< res.data.banner.length;i++){
                    if(res.data.banner[i]){
                        bas.push(res.data.banner[i]);
                    }
                }


                this.setState({
                    banner: bas,
                    activity: res.data.activity,
                    recommend: hotGameList,
                    activity_game_list: res.data.activity_game_list || [],
                    redbag: res.data.redbag || {},
                    pcUrl: pcUrl,
                    downloadUrl: res.data.pc_url + "/main/mobile.html",
                    playdata: res.data.playData.concat(res.data.playData) || [],
                    notice_win: res.data.notice_win || null,
                    notice_win_type: res.data.notice_win.is_status,
                    noticeList: noticeList,
                    hotCasino: res.data.hotCasino,
                    favorite: favorite
                });

            }
        });
    };
    /**
     * 客服
     * @private
     */
    _services = () => {
        // PublicUtils.navigate('GameRoom', {url: this.serviceUrl, title: "客服", parma: null,});
        PublicUtils.linking(model.serviceUrl, "_blank");
    };
    /**
     * 判断是否登录
     * @param nextPage
     * @param Params
     * @returns {Promise<void>}
     * @private
     */
    _checkLogin = async (nextPage, Params) => {
        if (await PublicUtils._checkLogin()) {
            Params.fromPage = "Home";
            PublicUtils.navigate(nextPage, Params);
        } else {
            PublicUtils.navigate('Login', {nextPage: '/'});
        }
    };


    /**
     * 跳转页面
     * @private
     */
    _navigateToPage = (pageName, params) => {
        this._checkLogin(pageName, params || {});
    };
    /**
     * 获取公告
     * @private
     */
    _fetchNotice = () => {
        Http.request("getNotice", {}, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    if (res.data.txt) {
                        let noticeList = [];
                        for (let i = 0; i < 2; i++) {
                            if (res.data.txt[i]) {
                                noticeList.push(res.data.txt[i]);
                            }
                        }
                        // this.setState({noticeList: noticeList});
                        // this.noticeList = res.data.txt;
                    }
                }
            }
        });
    };
    /**
     * 推荐类型
     * @private
     */
    _renderHotType = (type) => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        let result = null;
        switch (type) {
            case "recommend":
                result = <Image style={styles.hotType}
                                uri={require('../../../assets/images/tuijian.gif')}/>;
                break;
            case "new":
                result = <Image style={styles.hotType}
                                uri={require('../../../assets/images/zuixin.gif')}/>;
                break;
            case "hot":
                result = <Image style={styles.hotType}
                                uri={require('../../../assets/images/remen.gif')}/>;
                break;
        }
        return result;
    };
    /**
     * 加载轮播图
     * @private
     */
    _renderSwiperList = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        const swiperItem=this.state.banner.map((item, key) => {
            return (
                <div key={key} style={styles.swiperItem}>
                    <Image hasPlaceholder={false} placeholderSize={{
                        width: model.clientWidth - 20,
                        height: (model.clientWidth - 20) * 0.485
                    }} uri={item} style={{
                        width: model.clientWidth - 20,
                        boxShadow: "0 3px 10px rgba(0,0,0,0.4)",
                        borderRadius: 6,
                    }}/>
                </div>
            )
        });
        return <div style={styles.swiperContainer}>
          {this.state.banner.length?
            <ReactMCarousel loop={true} auto={5000} className={'swiperBox'}>
                {swiperItem}
            </ReactMCarousel>:
            <Image hasPlaceholder={true} placeholderSize={{
                        width: model.clientWidth - 20,
                        height: (model.clientWidth - 20) * 0.485
                    }} style={{
                        width: model.clientWidth - 20,
                        boxShadow: "0 3px 10px rgba(0,0,0,0.4)",
                        borderRadius: 6,
            }}/>
          }


        </div>
    };
    /**
     * app下载
     * @private
     */
    _renderDownload = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (
            <div style={styles.appDownloadShadow} onClick={() => {
                this._downAppPage();
            }}>
                <div style={styles.appDownloadBox}>
                    <div style={styles.appDownloadImg}>
                        <Image width={"100%"} uri={require("../../../assets/images/xiazai.png")}/>
                    </div>
                    <div style={styles.appDownloadText}>
                        下载客户端体验更多精彩游戏
                    </div>
                    <div style={styles.appDownloadButtonBox}>
                        <div style={styles.appDownloadButton}>立即下载</div>
                    </div>
                </div>
            </div>
        )
    };
    /**
     * 跳转到app下载页面
     * @private
     */
    _downAppPage = () => {
        PublicUtils.linking(this.state.downloadUrl, "_blank");
        // PublicUtils.linking(this.state.downloadUrl, "_blank");
        // window.location.href = this.state.downloadUrl;
    };
    /**
     * 跳转到pc端
     * @private
     */
    _pcUrlPage = () => {
        PublicUtils.linking(this.state.pcUrl, "_blank");
    };
    /**
     * 加载公告
     * @private
     */
    _renderNotice = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                // borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
                backgroundColor: "rgba(0,0,0,0.05)",
                margin: "0 10px",
                borderRadius: 3
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        // const Icon = createThemedComponent(MyIcon, ['color']);
        // const ButtonHighlight = createThemedComponent(TouchableHighlight);
        return (
            this.state.noticeList.length ?
                <div style={styles.noticeWrapperBox}>
                    <div style={styles.noticeWrapperIconBox}>
                        <Icon style={styles.noticeIcon} icon={'tongzhi'} color={"#fff"}
                              size={21}/>
                    </div>
                    <div style={styles.noticeWrapperContentBox}>
                        {
                            this.state.noticeList.length ?
                                <NoticeContainer noticeList={this.state.noticeList}/> : null
                        }
                    </div>
                </div> : null

            // <div style={styles.noticeWrapper}>
            //     {
            //         this.state.noticeList.map((item, key) => {
            //             return (
            //                 <div key={key} onClick={() => {
            //                     PublicUtils.navigate("MessageDetail", {
            //                         data: {
            //                             id: "",
            //                             is_title: "",
            //                             add_time: "",
            //                             is_content: item
            //                         }, type: 2
            //                     });
            //
            //                 }} style={styles.noticeBox}>
            //                     <Icon style={styles.noticeIcon} icon={'tongzhi'} color={style['primaryColor' + model.SKINNAME]}
            //                           size={21}/>
            //
            //                     <div style={styles.noticeText}>{item}</div>
            //                 </div>
            //             )
            //         })
            //     }
            // </div>
        )
    };
    /**
     * 电子游戏热门推荐
     * @private
     */
    _renderHotAlotGame = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return this.state.hotCasino ?
            <div style={{...styles.hotBox}}>
                <div style={{...styles.hotTitle, ...{justifyContent: "center"}}}>
                    <div style={styles.hotBigTitle}>热门电游推荐</div>
                    {/*<div style={styles.point}>·</div>*/}
                    {/*<div style={styles.hotSubTitle}>为您推荐最火的游戏</div>*/}
                </div>
                <div style={styles.hotGameBody}>
                    <div style={styles.hotInnerBody}>
                        {
                            this.state.hotCasino.map((item, i) => {
                                return i !== 0 && (
                                    <div style={styles.hotAlotGameBox}>
                                        <div>
                                            <Icon
                                                icon={item.icon} color={'#000'} size={45}/>
                                        </div>
                                        <div>{item.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={styles.hotAlotGameBox}>

                </div>
            </div> : null;
    };

    /**
     * 加载热门推荐
     * @private
     */
    _renderHotList = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
            }
        };
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (
            this.state.recommend.length ?
                <div style={styles.hotBox}>
                    <div style={styles.hotTitle}>
                        <div style={styles.hotBigTitle}>热门推荐></div>
                        {/*<div style={styles.point}>·</div>*/}
                        <div style={styles.hotSubTitle}>为您推荐最火的游戏</div>
                    </div>
                    <div style={styles.hotBody}>
                        {
                            this.state.recommend.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => {
                                        if (item.game_id === 201) {
                                            PublicUtils.navigate('GameNavigation');
                                        } else if (item.game_id === 101) {
                                            PublicUtils.navigate('HG', {param: item});
                                        } else {
                                            this._navigateToPage("GameTransfer", {param: item});
                                            // PublicUtils.navigate("GameTransfer", {param: item});
                                        }
                                        // PublicUtils._enterGame(item)
                                    }} style={styles.gameBox}>
                                        <div style={styles.gameImgBox}>
                                            <Image style={styles.gameImg}
                                                   hasPlaceholder={true}
                                                   placeholderSize={{
                                                       width: (model.clientWidth - 40) / 3,
                                                       height: ((model.clientWidth - 40) / 3) - 18
                                                   }}
                                                   uri={item.imgPath}/>
                                            {this._renderHotType(item.hot_type)}

                                        </div>
                                        {
                                            parseInt(item.showTitle) ?
                                                <div style={styles.gameNameBox}>
                                                    <div style={styles.gameName}>{item.name}</div>
                                                </div> : null
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                </div> : null
        )
    };
    /**
     * 加载优惠活动
     * @private
     */
    _renderActive = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
            }
        };
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        // const Button = createThemedComponent(TouchableOpacity);
        return (
            this.state.activity.length ? (
                <div style={styles.hotActive}>
                    <div style={styles.hotTitle}>
                        <div style={styles.hotBigTitle}>优惠活动></div>
                        {/*<div style={styles.point}>·</div>*/}
                        <div style={styles.hotSubTitle}>天天返水精彩不断</div>
                    </div>
                    <div style={styles.hotActiveBody}>
                        {/*{*/}
                        {/*this.state.redbag.status ?*/}
                        {/*<div onClick={() => {*/}
                        {/*const token = Storage.retrieveData("token") || "";*/}
                        {/*window.location.href = global.ACTIVE_URL + "/active/redpackage/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*}}>*/}
                        {/*<Image uri={this.state.redbag.pic}*/}
                        {/*style={styles.hotActiveImg}/>*/}
                        {/*</div> : null*/}
                        {/*}*/}

                        {/*{*/}
                        {/*this.state.activity_game_list.map((item, key) => {*/}
                        {/*if (item.status) {*/}
                        {/*return (*/}
                        {/*<div key={key} onClick={() => {*/}
                        {/*const token = Storage.retrieveData("token") || "";*/}
                        {/*let url = null;*/}
                        {/*switch (item.type) {*/}
                        {/*case 1:*/}
                        {/*url = global.ACTIVE_URL + "/active/rotary/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*break;*/}
                        {/*case 2:*/}
                        {/*url = global.ACTIVE_URL + "/active/box/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*break;*/}
                        {/*case 3:*/}
                        {/*url = global.ACTIVE_URL + "/active/egg/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*break;*/}
                        {/*}*/}
                        {/*window.location.href = url;*/}
                        {/*}}>*/}
                        {/*<Image uri={item.pic}*/}
                        {/*style={styles.hotActiveImg}/>*/}
                        {/*</div>*/}
                        {/*)*/}
                        {/*} else {*/}
                        {/*return null;*/}
                        {/*}*/}
                        {/*})*/}
                        {/*}*/}
                        {
                            this.state.activity.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => {
                                        const parma = {id: item.id, title: item.is_title};
                                        PublicUtils.navigate("ActiveDetail", parma);
                                    }}>
                                        <Image uri={item.is_mobile_pic} hasPlaceholder={true}
                                               placeholderSize={{
                                                   width: model.clientWidth - 20,
                                                   height: 50
                                               }}
                                               style={styles.hotActiveImg}/>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            ) : null
        )
    };
    /**
     * 活动
     * @returns {null}
     * @private
     */
    _renderHotActive = () => {
        const styles = {
            activeBox: {
                position: "fixed",
                bottom: 110,
                right: 30,
                width: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
            }
        }
        return (
            this.state.activity_game_list.length || this.state.redbag.status ? (
                <div style={styles.activeBox} onClick={() => {
                    alert(0);
                }}>
                    <Image
                        uri={require('../../../assets/images/active.png')}/>
                </div>
            ) : null
        )
    };
    /**
     * 活动详情
     * @private
     */
    _renderHotActiveDetail = () => {
        return (
            this.state.activity_game_list.length || this.state.redbag.status ? (
                <HotActive activity_game_list={this.state.activity_game_list} redbag={this.state.redbag}/>
            ) : null
        )
    };
    /**
     * 加载超级大赢家
     * @private
     */
    _renderWinner = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
            }
        };
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (
            this.state.playdata.length ?
                <div style={styles.superWinnerBox}>
                    <div style={styles.hotTitle}>
                        <div style={styles.hotBigTitle}>超级大赢家></div>
                        {/*<div style={styles.point}>·</div>*/}
                        <div style={styles.hotSubTitle}>玩家赚钱率高达98%</div>
                    </div>
                    <div style={styles.superWinnerBody}>
                        <div className={'superWinnerInnerBox'}>
                            {
                                this.state.playdata.map((item, key) => {
                                    return (
                                        <div key={key} style={styles.superWinnerItem}>
                                            <div style={styles.superWinnerItemLeft}>
                                                <Image style={styles.superWinneImg}
                                                       uri={require('../../../assets/images/jb.png')}/>
                                                <div style={styles.superWinnerText}>
                                                    {item.username}
                                                </div>
                                                <div style={styles.superWinnerText1}>在</div>
                                                <div
                                                    style={styles.superWinnerText2}>{item.game}</div>
                                                <div style={styles.superWinnerText1}>赢取</div>
                                                <div style={styles.superWinnerText3}>￥{item.money}</div>
                                            </div>
                                            <div style={styles.superWinnerItemRight}>
                                                <div
                                                    style={styles.superWinnerItemRightText}>{item.time}分钟前
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div> : null
        );
    };


    _withdrawal = async () => {
        if (await PublicUtils._checkLogin()) {
            let userInfo = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
            console.log(userInfo);
            if (userInfo.pay_card) {
                this._navigateToPage("Withdrawal");
            } else {

                PublicUtils.alert({
                    title: "温馨提示",
                    msg: "您必须绑定银行卡之后才能提现哦",
                    buttons: [
                        {
                            text: "取消", onPress: () => {
                            }
                        },
                        {
                            text: "立即绑定", onPress: async () => {
                                const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
                                if (user.user_name) {
                                    this._navigateToPage("Bindbank");
                                } else {
                                    PublicUtils.showToast("您还没有绑定姓名哦，请前往个人中心绑定", {type: "FAILD"});
                                }
                            }
                        }
                    ]
                });
            }
        } else {
            PublicUtils.navigate('Login', {nextPage: '/'});
        }
    };

    _renderNoticeWin = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                // padding: 10,
                // borderRadius: 6,
            }
        };
        let content = "";
        if (global.ID == "ao") {
            content = "<div style='width: 100%;text-align: center;'><span>系统已经维护完成，请联系在线客服</span>" +
                "<a style='display: block;margin-top: 20px;' target='_blank' href='https://chat-new.mqimg.com/widget/standalone.html?eid=63626'>点击联系</a></div>";
        }
        if (global.ID == "ao") {
            return this.state.show ? <div style={winStyles.bgShadow}>
                <div style={winStyles.innerBody}>
                    <div style={winStyles.title}>
                        <div style={winStyles.closeButtonTop} onClick={() => {
                            //下次进入首页将不在显示广告弹窗
                            model.showNoticeWin = true;
                            this.setState({show: false});
                        }}>
                            <Icon icon={"close"} size={20} color={"#fff"}/>
                        </div>
                        <div style={winStyles.closeButtonBottom}/>
                    </div>
                    <div style={winStyles.content}>
                        <div style={winStyles.noticeWinContentBox}
                             dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </div>
            </div> : null
        } else {
            return this.state.notice_win && this.state.show && this.state.notice_win.mobile_status && !model.showNoticeWin ?
                <div style={winStyles.bgShadow}>
                    <div style={{width:'90%'}}>
                        <div style={winStyles.title}>
                            <div style={winStyles.closeButtonTop} onClick={() => {
                                //下次进入首页将不在显示广告弹窗
                                model.showNoticeWin = true;
                                this.setState({show: false});
                            }}>
                                <Icon icon={"close"} size={20} color={"#fff"}/>
                            </div>
                            <div style={winStyles.closeButtonBottom}/>
                        </div>
                        <div style={winStyles.content}>
                            {
                                this.state.notice_win.mobile_true_content ?
                                    <div style={winStyles.noticeWinContentBox}
                                         dangerouslySetInnerHTML={{__html: this.state.notice_win.mobile_true_content || this.state.notice_win.pc_content}}/>:<Image style={{background: "#f5f7f9"}} width={'100%'}
                                                                                                                                                                    uri={this.state.notice_win.mobile_content}/>
                            }
                        </div>
                    </div>
                </div> : null
        }
    }

    /**
     * 进入游戏
     * @private
     */
    _enterGame = async (item) => {
        if (item.game_id === 201) {
            PublicUtils.navigate('GameNavigation');
        } else if (item.game_id === 101) {
            PublicUtils.navigate('HG', {param: item});
        } else {
            if (await PublicUtils._checkLogin()) {
                // 页面跳转
                // b5处理
                if(global.ID == 'b5' && item.name == 'VIP捕鱼王'){

                    //
                    let parm = {
                        game_id: 407,
                        name: "VIP",
                        platform: '',
                        play: "6",
                        title: "捕鱼王",
                        type: 4
                    };
                    PublicUtils._enterGamePage(parm);

                }else{
                    PublicUtils.navigate("GameTransfer", {param: item});
                }
            } else {
                PublicUtils.navigate('Login', {nextPage: '/'});
            }
        }
    };

    _addToFavorite = async (item) => {
        // alert(222);
        const favorite = (Storage.retrieveData("favorite") && JSON.parse(Storage.retrieveData("favorite"))) || [];
        let checked = true;
        let msg = "已收藏";
        for (let i = 0; i < favorite.length; i++) {
            if (favorite[i].game_id === item.game_id) {
                favorite.splice(i, 1);
                checked = false;
                msg = "移除收藏";
            }
        }
        if (checked) {
            favorite.unshift(item);
        }

        Storage.storeData({favorite: JSON.stringify(favorite)});
        this.setState({favorite: favorite});
        PublicUtils.showToast(msg);
    };



    render() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                // backgroundColor:"@primary"
            },
            // headerLinearGradientContainer: {
            //     position: "absolute",
            //     width: model.clientWidth,
            //     height: (Platform.OS === 'ios' ? Dimensions.get('window').width * 0.55 : Dimensions.get('window').width * 0.65) + 100,
            //     top: 0,
            //     // backgroundColor: "#fff"
            // },
            // headerLinearGradient: {
            //     // position: "absolute",
            //     // top: 0,
            //     marginLeft: Dimensions.get('window').width * 0.15,
            //     overflow: "hidden",
            //     width: Dimensions.get('window').width * 0.7,
            //     height: Dimensions.get('window').height,
            //     // borderBottomLeftRadius: Dimensions.get('window').width * 0.5,
            //     // borderBottomRightRadius: Dimensions.get('window').width * 0.5,
            //     transform: [
            //         {scaleX: 2}
            //     ],
            //     zIndex: 0
            // },
            header: {
                // paddingTop: model.STATUSBAR_HEIGHT,
            },
            // searchBarHeaderbg: {
            //     position: "absolute",
            //     left: -Dimensions.get('window').width * 0.1,
            //     right: -Dimensions.get('window').width * 0.1,
            //     top: 0,
            //     bottom: 0
            // },
            headerInnerBar: {
                display: "flex",
                // width: Dimensions.get('window').width,
                // width: "100%",
                // flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
            },
            headerLeftContainer: {
                display: "flex",
                // backgroundColor: "#000",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start"
            },
            headerRightContainer: {
                display: "flex",
                // backgroundColor: "#fff",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end"
            },
            leftSlideBtn: {
                display: "flex",
                width: 35,
                height: 35,
                borderRadius: 35,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.1)"
            },
            logoImg: {
                // width: 100,
                height: 50,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 10,
                marginBottom: 10,
            },
            headerRightImg: {
                width: 70,
                marginLeft: 10
            },
            swiperContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'center',
                // marginBottom: 10,
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                // height: height * 0.5,
                width: model.clientWidth,
                justifyContent: 'center'
            },
            swiperImg: {
                display: "flex",
                // height: 200,
                // width: Dimensions.get('window').width - 20,
                justifyContent: 'center',
                alignItems: "center",
                // marginRight: 10,
                // marginLeft: 10,
                borderRadius: 6,
            },
            isConnectedTipsBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: "center",
                marginTop: 30
            },
            isConnectedTipsText: {
                fontSize: 16,
                marginTop: 10,
                color: "#fff"
            },
            isConnectedTipsBtn: {
                marginTop: 20,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                borderWidth: 1,
                borderColor: style['primary' + model.SKINNAME],
                borderRadius: 3,
            },
            isConnectedTipsBtnText: {
                color: style['primary' + model.SKINNAME],
                fontSize: 16,
            },
            bodyContainer: {
                flex: 1,
                // minHeight: 370,
                // backgroundColor: "@primary"
            },
            gameTabbarContainer: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // borderBottomColor: "rgba(0,0,0,0.05)",
                // borderBottomWidth: 1,
                // borderStyle: "solid"
            },
            gameTabbarBodyContainer: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 50,
                marginTop: 10,
                // backgroundColor:"#000"
            },
            b5GameTabbarBodyContainer:{
                display: "flex",
                justifyContent:"space-between",
                flexWrap: "wrap",
                paddingLeft: 5,
                paddingBottom: 50,
                marginTop: 10,
                orderRadius: 5,
                backgroundColor:"#fff",
            }

        };

        // logo
        let khlog;
        switch (global.ID) {
            case 'x9':
                khlog =  <Image style={styles.logoImg} resizeMode='contain'
                                uri={require('../../../assets/images/logoy9.png')}/>;
                break;
            case 'b5':
            khlog =  <Image style={{...styles.logoImg,marginLeft:0}} resizeMode='contain'
            uri={require('../../../assets/images/logo-b5.png')}/>;
                break;
            case 'w7':
                khlog =  <Image style={{ marginLeft:0,   width: 100,}} resizeMode='contain'
                                uri={require('../../../assets/images/w7.png')}/>;
                break;

            case 'ff':
                khlog =  <Image style={{...styles.logoImg}} resizeMode='contain'
                                uri={require('../../../assets/images/ff.png')}/>;
                break;
            default:
                khlog =  <Image style={styles.logoImg} resizeMode='contain'
                                uri={require('../../../assets/images/theme1/logo.png')}/>;
                break;
        }


        return (
            (
                <Container tabIndex={0} showBottomTab={true} showHeader={false}
                           style={{background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")"}}
                >
                    {/*<Bar barStyle={'light-content'} backgroundColor={'transparent'}/>*/}
                    {/*<div style={styles.headerLinearGradientContainer}>*/}
                    {/*    <div style={styles.headerLinearGradient}>*/}
                    {/*        <ThemeLinearGradient colors={'@gradient'} start={{x: 1, y: 0}}*/}
                    {/*                             style={{width: "100%", height: "100%"}}*/}
                    {/*                             end={{x: 0, y: 0}}>*/}
                    {/*        </ThemeLinearGradient>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div style={styles.container} className={'headPadding'}>

                        <div style={styles.header}>

                            {/*<ThemeLinearGradient colors={'@gradient'} style={styles.searchBarHeaderbg} start={{x: 1, y: 0}}*/}
                            {/*                     end={{x: 0, y: 1}}>*/}
                            {/*</ThemeLinearGradient>*/}
                            <div style={styles.headerInnerBar}>
                                <div style={styles.headerLeftContainer}>
                                    <div style={styles.leftSlideBtn} type={1} onClick={() => {
                                        // alert(0);
                                        this.DrawerPage.show();
                                    }}>
                                        <div>
                                            <Icon icon={'caidan'} color={"#fff"} size={15}/>
                                        </div>
                                    </div>
                                    {/*logo*/}
                                    {khlog}
                                </div>

                                <TopIcon pcUrl={this.state.pcUrl} hasLogin={this.state.hasLogin}
                                         navigation={this.props.navigation}/>
                                {/*<Theme.View style={styles.headerRightContainer}>*/}
                                {/*    <IButton type={1} onPress={() => {*/}
                                {/*        PublicUtils.navigate("Login");*/}
                                {/*    }}>*/}
                                {/*        <Image style={styles.headerRightImg} resizeMode='contain'*/}
                                {/*               source={require('../../../assets/images/theme1/login-btn.png')}/>*/}
                                {/*    </IButton>*/}
                                {/*    <IButton type={1} onPress={() => {*/}
                                {/*        PublicUtils.navigate("Register");*/}
                                {/*    }}>*/}
                                {/*        <Image style={styles.headerRightImg} resizeMode='contain'*/}
                                {/*               source={require('../../../assets/images/theme1/enr-btn.png')}/>*/}
                                {/*    </IButton>*/}
                                {/*</Theme.View>*/}
                            </div>
                        </div>
                        <div>
                            {
                                this._renderSwiperList()
                            }
                        </div>
                        <div style={{paddingTop: 10, paddingBottom: 10}}>
                            <GroupBtns downloadUrl={this.state.downloadUrl}/>
                        </div>
                        {
                            this._renderNotice()
                        }
                        <div style={styles.bodyContainer}>
                            <div style={styles.gameTabbarContainer}>
                                {
                                    model.gameList.length && model.gameList.map((item, i) => {
                                        //
                                        let gname = item.name;
                                        //
                                        if(global.ID == 'b5'){
                                            if(gname.indexOf('老虎机')!=-1){
                                                gname = gname.replace('老虎机','电子');
                                            }
                                        }


                                        return (
                                            <GameTab length={model.gameList.length}
                                                     actived={this.state.gameTabIndex === i}
                                                     icon={item.icon}
                                                     name={gname}
                                                     onPress={() => {
                                                         this.setState({gameTabIndex: i});
                                                     }}/>
                                        )
                                    })
                                }
                            </div>

                            <div style={global.ID == 'b5'?styles.b5GameTabbarBodyContainer:styles.gameTabbarBodyContainer}>
                                {
                                    model.gameList.length && model.gameList[this.state.gameTabIndex].gamelist.map((item) => {
                                        let icon = global.RES_URL + "/new/mobile_game_img/" + item.icon + ".png?&v=" + +new Date().getTime().toString();
                                        let is_favorite = false;
                                        for (let i = 0; i < this.state.favorite.length; i++) {
                                            if (this.state.favorite[i].game_id === item.game_id) {
                                                is_favorite = true;
                                            }
                                        }

                                        // 游戏名分离方便处理
                                        let gameName = item.name;

                                        // x9 游戏图标处理
                                        if(global.ID == 'x9' || global.ID == 'b5' || global.ID == 'w7'){
                                            if(icon.indexOf('hg.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/logo-hg.png';
                                                if(global.ID == 'b5'){
                                                    icon = 'https://appnew.hdyb33.com/code/x9/yxtb/gf2.gif';
                                                }
                                            }
                                            if(icon.indexOf('sb.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/logo-sb.png'
                                            }
                                            if(icon.indexOf('fy.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/fy.png'
                                            }
                                            if(icon.indexOf('vr.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/vr.png'
                                            }
                                            if(icon.indexOf('og.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/og.png'
                                            }
                                            if(icon.indexOf('fg.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/fg.png'
                                            }
                                            if(icon.indexOf('ly.png') != -1){
                                                icon = 'https://appnew.hdyb33.com/code/x9/yxtb/le.png'
                                            }


                                            //
                                            if(global.ID == 'b5'){
                                                if(gameName == 'VIP国际厅'){
                                                    gameName = 'AG国际馆';
                                                }

                                                //
                                                if(gameName.indexOf('老虎机')!=-1){
                                                    gameName = gameName.replace('老虎机','电子');
                                                }
                                            }
                                        }
                                        return (
                                            <GameBody
                                                favorite={is_favorite}
                                                name={gameName}
                                                icon={icon}
                                                onPress={() => {

                                                    this._enterGame(item);
                                                }}
                                                onLongPress={() => {
                                                    this._addToFavorite(item);
                                                }}/>
                                            // <View style={styles.gameItemContainer}>
                                            //     <View style={styles.gameItemImg}>
                                            //         <MyImage style={styles.gameImg}
                                            //                  placeholderSize={{
                                            //                      width: ((Dimensions.get('window').width - 40) / 3) - 20,
                                            //                      // height: ((Dimensions.get('window').width - 40) / 3) - 20
                                            //                  }}
                                            //                  resizeMode='contain'
                                            //                  uri={icon}
                                            //                  width={((Dimensions.get('window').width - 40) / 3) - 30}/>
                                            //     </View>
                                            //     <MyText style={styles.gameItemText}>{item.name}</MyText>
                                            // </View>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/*{*/}
                        {/*    !this.state.isConnected ?*/}
                        {/*        <div style={styles.isConnectedTipsBox}>*/}
                        {/*            <div style={styles.isConnectedTipsText}>检查到您的网络未连接</div>*/}
                        {/*            <div style={styles.isConnectedTipsText}>检查后点击重试刷新</div>*/}
                        {/*            <div style={styles.isConnectedTipsBtn} activeOpacity={1} onClick={() => {*/}
                        {/*                // this._navigateToPage("RechargeChannel");*/}
                        {/*                this._fetchData();*/}
                        {/*            }}>*/}
                        {/*                <div style={styles.isConnectedTipsBtnText}>重试</div>*/}
                        {/*            </div>*/}
                        {/*        </div> : null*/}
                        {/*}*/}
                    </div>
                    {/*<NoticeWin notice_win={this.state.notice_win} notice_win_type={this.state.notice_win_type}/>*/}
                    {
                        this._renderNoticeWin()
                    }
                    {
                        this._renderHotActiveDetail()
                    }
                    <DrawerPage ref={(ref) => {
                        this.DrawerPage = ref;
                    }}/>
                </Container>
            )
        )
    }
}

export default withRouter(Theme1);
