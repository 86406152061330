//首页

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import PublicUtils from '../../../utils/publicUtils'
import Storage from '../../../services/storage'
import Container from '../../../component/container'
import HotActive from '../../../component/hotActive'
import Image from '../../../component/image'
import Icon from '../../../utils/icon'
import style from '../../../utils/config.scss'
import Http from "../../../services/http";
import model from "../../../services/model";
import ReactMCarousel from 'react-m-carousel'
import '../../../assets/css/app.css'
import global from "../../../global";
import HotAlotGameList2 from '../../../component/hotAlotGameList2';
import NoticeContainer from '../../../component/noticeContainer';
import DrawerPage from "../../../component/drawerPage";


class Default extends Component {
    constructor(props) {



        super(props);
        this.state = {
            banner: [],//轮播
            recommend: [],//推荐
            activity: [],//活动
            noticeList: [],
            playdata: [],
            activity_game_list: [],
            redbag: {},
            pcUrl: null,
            downloadUrl: null,
            notice_win: null,
            show: true,
            usedl:false,
        };

        //
        this.userInfo = Storage.retrieveData("user");
        // y8 处理
        if(this.userInfo && global.ID == 'y8'){
            //
            this.state.usedl = true;
        }
        // b8 处理
        if(this.userInfo && global.ID == 'b8'){
            //
            this.state.usedl = true;
        }
        //
        global.syObj = this;
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {

    };
    componentDidMount = () => {
        // PublicUtils.showToast("正在获取最优线路");
        // if(this.props.location.search){
        //     let search = this.props.location.search.slice(1)
        //     search = search.split("=");
        //     localStorage.setItem("agent",search[1]);
        // }
        PublicUtils.getBestUrl((res) => {
            // PublicUtils.showToast("正在获取最优线路");
            if (res.status) {
                this._fetchData();
            } else {
                PublicUtils.showToast(res.msg, {type: "FAILD"});
            }
        });


    }

    componentWillUnmount = async () => {

    }


    /**
     * 提交代理
     */
    postAgent() {
        let parm = {
            // agent: this.ComFn.getQueryString("ag")
        };
        Http.request("postAgent", parm, (data) => {
            if (data.data.status === 1) {
            }
        });
    }

    /**
     * 获取数据
     * @private
     */
    _fetchData = () => {
        let parm = {};
        Http.request("getMainPageData", parm, (res) => {



            PublicUtils.hideLoader();
            // return;
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {

                let game_list_mobile = res.data.game_list_mobile;
                let gamelist = []
                for (let i = 0; i < game_list_mobile.length; i++) {
                    if (game_list_mobile[i].type_id === 8) {
                        game_list_mobile.splice(i, 1);
                    }
                }
                if (global.ID == "b5") {

                    gamelist.push(game_list_mobile[0].gamelist[0])
                    gamelist.push(game_list_mobile[2].gamelist[0])
                    gamelist.push(game_list_mobile[0].gamelist[2])
                    gamelist.push(game_list_mobile[1].gamelist[0])
                    gamelist.push(game_list_mobile[1].gamelist[2])
                    gamelist.push(game_list_mobile[4].gamelist[0])
                    gamelist.push(game_list_mobile[4].gamelist[3])
                    gamelist.push(game_list_mobile[2].gamelist[4])
                    gamelist.push(game_list_mobile[2].gamelist[3])
                    gamelist.push(game_list_mobile[3].gamelist[3])
                    gamelist.push(game_list_mobile[3].gamelist[0])
                    gamelist.push(game_list_mobile[3].gamelist[8])
                    gamelist.push(game_list_mobile[3].gamelist[6])

                    const hotGame = {
                        gamelist,
                        icon:"new-hot",
                        name:"热门",
                        type_id:0,
                    }
                    game_list_mobile.unshift(hotGame)
                }



                if (global.ID == "yy") {
                    res.data.hotCasino=[
                        [
                            {
                                "game_api": "BBIN",
                                "game_id": 408,
                                "icon": "logo-bbin",
                                "name": "BB老虎机",
                                "type": 4,
                                "game_list_img_path": "/new/slot_res/bbin/",
                                "hotlist": {
                                    "CN_Name": "捕鱼达人",
                                    "HTML5_Game_ID": "30599",
                                    "Button_Image_Name": "30599.png"
                                }
                            },
                            {
                                "game_api": "VIP",
                                "game_id": 407,
                                "icon": "vip",
                                "name": "VIP老虎机",
                                "type": 4,
                                "game_list_img_path": "/new/slot_res/vip/",
                                "hotlist": {
                                    "CN_Name": "VIP捕鱼王",
                                    "HTML5_Game_ID": "548",
                                    "Button_Image_Name": "SB36_ZH.gif"
                                }
                            },
                            {
                                "game_api": "FG",
                                "game_id": 411,
                                "icon": "fg",
                                "name": "FG老虎机",
                                "type": 4,
                                "game_list_img_path": "/new/slot_res/fg/",
                                "hotlist": {
                                    "CN_Name": "VIP捕鱼王",
                                    "HTML5_Game_ID": "fish_3D",
                                    "Button_Image_Name": "fish_3D.png"
                                }
                            },
                            {
                                "game_api": "MW",
                                "game_id": 405,
                                "icon": "mw",
                                "name": "MW老虎机",
                                "type": 4,
                                "game_list_img_path": "/new/slot_res/mw/",
                                "hotlist": {
                                    "CN_Name": "千炮捕鱼",
                                    "HTML5_Game_ID": "1051",
                                    "Button_Image_Name": "1051.png"
                                }
                            }
                        ],

                        {
                            "game_api": "PG",
                            "game_id": 402,
                            "icon": "logo-pg",
                            "name": "PG老虎机",
                            "type": 4,
                            "game_list_img_path": "/new/slot_res/pg/",
                            "hotlist": [
                                {
                                    "CN_Name": "麻将胡了2",
                                    "HTML5_Game_ID": "74",
                                    "Button_Image_Name": "74.png"
                                },
                                {
                                    "CN_Name": "麻将胡了",
                                    "HTML5_Game_ID": "65",
                                    "Button_Image_Name": "65.png"
                                },
                                {
                                    "CN_Name": "寻宝黄金城",
                                    "HTML5_Game_ID": "87",
                                    "Button_Image_Name": "87.png"
                                },
                                {
                                    "CN_Name": "赏金大对决",
                                    "HTML5_Game_ID": "135",
                                    "Button_Image_Name": "135.png"
                                },
                                {
                                    "CN_Name": "赏金女王",
                                    "HTML5_Game_ID": "84",
                                    "Button_Image_Name": "84.png"
                                },
                                {
                                    "CN_Name": "招财喵",
                                    "HTML5_Game_ID": "89",
                                    "Button_Image_Name": "89.png"
                                }
                            ]
                        },


                        {
                            "game_api": "CQ9",
                            "game_id": 406,
                            "icon": "cq9",
                            "name": "CQ9老虎机",
                            "type": 4,
                            "game_list_img_path": "/new/slot_res/cq9/",
                            "hotlist": [
                                {
                                    "CN_Name": "跳高高", "HTML5_Game_ID": "52", "Button_Image_Name": "tgaogao.png"
                                },
                                {
                                    "CN_Name": "跳起来", "HTML5_Game_ID": "7", "Button_Image_Name": "tql.png"
                                },

                                {
                                    "CN_Name": "鸿福齐天",
                                    "HTML5_Game_ID": "50",
                                    "Button_Image_Name": "hfqt.png"
                                },
                                {
                                    "CN_Name": "发财神", "HTML5_Game_ID": "69", "Button_Image_Name": "fcs.png"
                                },
                                {
                                    "CN_Name": "直式蹦迪", "HTML5_Game_ID": "137", "Button_Image_Name": "zsbd.png"
                                },

                            ]
                        },
                        {
                            "game_api": "VIP",
                            "game_id": 407,
                            "icon": "vip",
                            "name": "VIP老虎机",
                            "type": 4,
                            "game_list_img_path": "/new/slot_res/vip/",
                            "hotlist": [
                                {
                                    "Button_Image_Name": "SC03_ZH.png",
                                    "HTML5_Game_ID": "802",
                                    "CN_Name": "金拉霸"
                                },
                                {
                                    "Button_Image_Name": "YP805.gif",
                                    "HTML5_Game_ID": "YP805",
                                    "CN_Name": "水果拉霸"
                                },
                                {
                                    "CN_Name": "太空漫游", "HTML5_Game_ID": "SB01", "Button_Image_Name": "SB01.png"
                                },

                            ]
                        },
                        {
                            "game_api": "MG",
                            "game_id": 409,
                            "icon": "logo-mg",
                            "name": "MG老虎机",
                            "type": 4,
                            "game_list_img_path": "/new/slot_res/mg/",
                            "hotlist": [
                                {
                                    "CN_Name": "不朽情缘",
                                    "HTML5_Game_ID": "1103",
                                    "Button_Image_Name": "BTN_ImmortalRomance1.png"
                                },
                                {
                                    "CN_Name": "冰球突破",
                                    "HTML5_Game_ID": "1229",
                                    "Button_Image_Name": "BTN_BreakAway1.png"
                                },
                                {
                                    "CN_Name": "幸运双星",
                                    "HTML5_Game_ID": "1283",
                                    "Button_Image_Name": "BTN_luckyTwins_ZH.png"
                                },
                                {
                                    "CN_Name": "篮球巨星",
                                    "HTML5_Game_ID": "1159",
                                    "Button_Image_Name": "BTN_BasketballStar_ZH.png"
                                },

                                {
                                    "CN_Name": "花花公子",
                                    "HTML5_Game_ID": "1188",
                                    "Button_Image_Name": "BTN_Playboy.png"
                                }
                            ]
                        },

                    ]
                }

                model.gameList = game_list_mobile;
                Storage.storeData({game_list: JSON.stringify(game_list_mobile)});
                if (res.data.service_mobile) {
                    model.serviceUrl = res.data.service_mobile;
                    model.serviceUrl_1 = res.data.service_mobile_1;
                    Storage.storeData({serviceUrl: res.data.service_mobile});
                }
                let hotGameList = [];
                const indexList = res.data.new_index || res.data.index;


                for (let item in indexList) {
                    let game = model.getGamesInfoById(item.split('_')[1]);

                    if (game) {

                        if(global.ID == 'js' && game.name == '系统彩票'){
                            game.name = '官方彩票'
                        }


                        game.hot_type = indexList[item];
                        game.showTitle = item.split('_').length > 2 ? item.split('_')[2] : 1;
                        hotGameList.push(game);
                    }
                }
                let noticeList = [];
                if (res.data.notice.status) {
                    for (let i = 0; i < res.data.notice.txt.length; i++) {
                        if (res.data.notice.txt[i]) {
                            noticeList.push(res.data.notice.txt[i]);
                        }
                    }
                }

                // let pcUrl = res.data.pc_url + "/main/index.html";
                // if (global.ID !== "ca") {
                //     pcUrl = res.data.pc_url + "/main/index.html?from=mobile";
                // }
                let pcUrl = res.data.pc_url + "/main/index.html?from=mobile";


                // 空值处理 这里
                let bas =[];
                if(global.ID == 'w7'){
                    bas = [
                        {
                            img:res.data.banner[0],
                            "xb":0,
                            "url":"http://w7002.com/activity_game/activity.html?type=2",
                            "nb":0
                        },
                        {
                            img:res.data.banner[1],
                            "xb":1,
                            "url":"https://appnew.hdyb33.com/usrActive/pxb/index.html",
                            "nb":0
                        },
                        {
                            img:res.data.banner[2],
                            "xb":2,
                            "url":"Active",
                            "nb":1
                        },
                        {
                            img:res.data.banner[3],
                            "xb":3,
                            "url":"Active",
                            "nb":1
                        },
                    ];
                }else{
                    for (let i=0; i< res.data.banner.length;i++){
                        if(res.data.banner[i]){
                            bas.push({
                                img:res.data.banner[i]
                            });
                        }
                    }
                }
               if(global.ID == 'yy'){
                   let userInfo = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
                   if(userInfo&&userInfo.lastmsg&&userInfo.lastmsg.is_content!=""){
                       PublicUtils.alert({
                           title: "温馨提示",
                           msg: userInfo.lastmsg.is_content,
                           buttons:[

                               {
                                   text: "关闭", onPress: () => {
                                   }
                               },
                           ]
                       });
                       userInfo.lastmsg.is_content = "";
                       Storage.storeData({"user":JSON.stringify(userInfo)})
                   }
               }

                this.setState({
                    banner: bas,
                    activity: res.data.activity,
                    recommend: hotGameList,
                    activity_game_list: res.data.activity_game_list || [],
                    redbag: res.data.redbag || {},
                    pcUrl: pcUrl,
                    downloadUrl: res.data.pc_url + "/main/mobile.html",
                    playdata: res.data.playData.concat(res.data.playData) || [],
                    notice_win: res.data.notice_win || null,
                    notice_win_type: res.data.notice_win.is_status,
                    noticeList: noticeList,
                    hotCasino: res.data.hotCasino
                });
            }
        });
    };


    // cycleUrl = () => {
    //     let bestUrl = model.lines[0];
    //     for (let i = 1; i < model.lines.length; i++) {
    //         if (model.lines[i].delay < bestUrl.delay) {
    //             bestUrl = model.lines[i];
    //         }
    //     }
    //
    //     global.APP_API_URL = bestUrl;
    //     Storage.storeData({line: JSON.stringify(bestUrl)});
    //     this._fetchData();
    // }

    /**
     * 客服
     * @private
     */
    _services = () => {
        // PublicUtils.navigate('GameRoom', {url: this.serviceUrl, title: "客服", parma: null,});
        PublicUtils.linking(model.serviceUrl, "_blank");
    };
    /**
     * 判断是否登录
     * @param nextPage
     * @param Params
     * @returns {Promise<void>}
     * @private
     */
    _checkLogin = async (nextPage, Params) => {
        if (await PublicUtils._checkLogin()) {
            Params.fromPage = "Home";
            PublicUtils.navigate(nextPage, Params);
        } else {
            PublicUtils.navigate('Login', {nextPage: '/'});
        }
    };


    /**
     * 跳转页面
     * @private
     */
    _navigateToPage = (pageName, params) => {
        this._checkLogin(pageName, params || {});
    };
    /**
     * 获取公告
     * @private
     */
    _fetchNotice = () => {
        Http.request("getNotice", {}, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    if (res.data.txt) {
                        let noticeList = [];
                        for (let i = 0; i < 2; i++) {
                            if (res.data.txt[i]) {
                                noticeList.push(res.data.txt[i]);
                            }
                        }
                        // this.setState({noticeList: noticeList});
                        // this.noticeList = res.data.txt;
                    }
                }
            }
        });
    };
    /**
     * 推荐类型
     * @private
     */
    _renderHotType = (type) => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        let result = null;
        switch (type) {
            case "recommend":
                result = <Image style={styles.hotType}
                                uri={require('../../../assets/images/tuijian.gif')}/>;
                break;
            case "new":
                result = <Image style={styles.hotType}
                                uri={require('../../../assets/images/zuixin.gif')}/>;
                break;
            case "hot":
                result = <Image style={styles.hotType}
                                uri={require('../../../assets/images/remen.gif')}/>;
                break;
        }
        return result;
    };
    /**
     * 加载轮播图
     * @private
     */
    _renderSwiperList = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (this.state.banner.length ?
                <div style={{...styles.swiperContainer,marginBottom:10,width:'100%'}}>

                    <ReactMCarousel loop={true} auto={5000} className={'swiperBox'} style={{width:'100%', height:'100%',overflow:'initial'}}>

                        {
                            this.state.banner.map((item, key) => {
                                return (
                                    <div key={key} style={{...styles.swiperItem,height:'100%',paddingLeft:10,paddingRight:10}} onClick={()=>{



                                      if(item.img == 'https://new.bobon900.com/b8/20230712112937_42215.png'){
                                        PublicUtils.navigate("ActiveDetail", {id: 108, title: '组队来决战 女足世界杯'});

                                        return
                                      }

                                      if(item.img == 'https://new.bobon900.com/b8/20230629023932_49565.png'){
                                        PublicUtils.navigate("ActiveDetail", {id: 106, title: '越月好运 火爆豪礼'});

                                        return
                                      }

                                      if(item.img == 'https://new.bobon900.com/b8/20230629023808_74716.png'){
                                        PublicUtils.navigate("ActiveDetail", {id: 107, title: '加入会员  特权福利'});

                                        return
                                      }




                                        if(item.nb){
                                            this._navigateToPage(item.url);
                                        }else{
                                            if(item.url){
                                                PublicUtils.linking(item.url, "_blank");
                                            }
                                        }
                                    }}>


                                        <img
                                            style={{
                                                width: '100%',
                                                height:'100%',
                                                boxShadow: "0 3px 10px rgba(0,0,0,0.4)",
                                                borderRadius: 6,
                                            }}
                                            src={item.img}/>

                                    </div>
                                )
                            })
                        }
                    </ReactMCarousel>
                </div> :
                <div style={styles.swiperContainer}>
                    <div style={styles.swiperItem}>
                        <Image hasPlaceholder={true} placeholderSize={{
                            width: model.clientWidth - 20,
                            height: (model.clientWidth - 20) * 0.485
                        }} uri={"https://baidu.com"} style={{
                            width: model.clientWidth - 20,
                            boxShadow: "0 3px 10px rgba(0,0,0,0.4)",
                            borderRadius: 6,
                        }}/>
                    </div>
                </div>
        )
    };
    /**
     * app下载
     * @private
     */
    _renderDownload = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (
            <div style={styles.appDownloadShadow} onClick={() => {
                this._downAppPage();
            }}>
                <div style={styles.appDownloadBox}>
                    <div style={styles.appDownloadImg}>
                        <Image width={"100%"} uri={require("../../../assets/images/xiazai.png")}/>
                    </div>
                    <div style={styles.appDownloadText}>
                        下载客户端体验更多精彩游戏
                    </div>
                    <div style={styles.appDownloadButtonBox}>
                        <div style={styles.appDownloadButton}>立即下载</div>
                    </div>
                </div>
            </div>
        )
    };
    /**
     * 跳转到app下载页面
     * @private
     */
    _downAppPage = () => {

      if(global.ID == 'yy'){
        PublicUtils.linking('https://appnew.hdyb33.com/code/yy/down.html', "_blank");

      }else{
        PublicUtils.linking(this.state.downloadUrl, "_blank");

      }

        // PublicUtils.linking(this.state.downloadUrl, "_blank");
        // window.location.href = this.state.downloadUrl;
    };
    /**
     * 跳转到pc端
     * @private
     */
    _pcUrlPage = () => {
        PublicUtils.linking(this.state.pcUrl, "_blank");
    };
    /**
     * 加载公告
     * @private
     */
    _renderNotice = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        // const Icon = createThemedComponent(MyIcon, ['color']);
        // const ButtonHighlight = createThemedComponent(TouchableHighlight);
        return (
            this.state.noticeList.length ?
                <div style={styles.noticeWrapperBox}>
                    <div style={styles.noticeWrapperIconBox}>
                        <Icon style={styles.noticeIcon} icon={'tongzhi'} color={style['primaryColor' + model.SKINNAME]}
                              size={21}/>
                    </div>
                    <div style={styles.noticeWrapperContentBox}>
                        {
                            this.state.noticeList.length ?
                                <NoticeContainer noticeList={this.state.noticeList}/> : null
                        }
                    </div>
                </div> : null

            // <div style={styles.noticeWrapper}>
            //     {
            //         this.state.noticeList.map((item, key) => {
            //             return (
            //                 <div key={key} onClick={() => {
            //                     PublicUtils.navigate("MessageDetail", {
            //                         data: {
            //                             id: "",
            //                             is_title: "",
            //                             add_time: "",
            //                             is_content: item
            //                         }, type: 2
            //                     });
            //
            //                 }} style={styles.noticeBox}>
            //                     <Icon style={styles.noticeIcon} icon={'tongzhi'} color={style['primaryColor' + model.SKINNAME]}
            //                           size={21}/>
            //
            //                     <div style={styles.noticeText}>{item}</div>
            //                 </div>
            //             )
            //         })
            //     }
            // </div>
        )
    };
    /**
     * 电子游戏热门推荐
     * @private
     */
    _renderHotAlotGame = () => {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return this.state.hotCasino ?
            <div style={{...styles.hotBox}}>
                <div style={{...styles.hotTitle, ...{justifyContent: "center"}}}>
                    <div style={styles.hotBigTitle}>热门电游推荐</div>
                    {/*<div style={styles.point}>·</div>*/}
                    {/*<div style={styles.hotSubTitle}>为您推荐最火的游戏</div>*/}
                </div>
                <div style={styles.hotGameBody}>
                    <div style={styles.hotInnerBody}>
                        {
                            this.state.hotCasino.map((item, i) => {
                                return i !== 0 && (
                                    <div style={styles.hotAlotGameBox}>
                                        <div>
                                            <Icon
                                                icon={item.icon} color={'#000'} size={45}/>
                                        </div>
                                        <div>{item.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={styles.hotAlotGameBox}>

                </div>
            </div> : null;
    };

    /**
     * 加载热门推荐
     * @private
     */
    _renderHotList = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
            }
        };
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (
            this.state.recommend.length ?
                <div style={styles.hotBox}>
                    <div style={styles.hotTitle}>
                        <div style={styles.hotBigTitle}>热门推荐></div>
                        {/*<div style={styles.point}>·</div>*/}
                        <div style={styles.hotSubTitle}>为您推荐最火的游戏</div>
                    </div>
                    <div style={styles.hotBody}>

                      {global.ID== 'br' ? <div  onClick={() => {

                        let parm = {
                          "id": 1001,
                          "is_name": "香港六合彩",
                          "is_function": "lhc",
                          "is_number": 0,
                          "is_status": 2,
                          "is_img": "lhc",
                          "tmp": "LHC"
                        }

                        model.isgl = [parm];
                        model.isid = parm.id;
                        PublicUtils.navigate('LHC', parm);
                      }} style={styles.gameBox}>
                        <div style={styles.gameImgBox}>
                          <Image style={styles.gameImg}
                                 hasPlaceholder={true}
                                 placeholderSize={{
                                   width: (model.clientWidth - 40) / 3,
                                   height: ((model.clientWidth - 40) / 3) - 18
                                 }}
                                 uri={'https://newpic.hdyb33.com/bw/lhc-xg-d.png'}/>
                          {this._renderHotType('hot')}

                        </div>
                        <div style={styles.gameNameBox}>
                          <div style={styles.gameName}>香港六合彩</div>
                        </div>

                      </div>:<div></div>}




                      { global.ID== 'br' ? <div  onClick={() => {
                        PublicUtils.navigate('GameNavigation');
                      }} style={styles.gameBox}>
                        <div style={styles.gameImgBox}>
                          <Image style={styles.gameImg}
                                 hasPlaceholder={true}
                                 placeholderSize={{
                                   width: (model.clientWidth - 40) / 3,
                                   height: ((model.clientWidth - 40) / 3) - 18
                                 }}
                                 uri={'https://newpic.hdyb33.com/bw/lhc-gf.gif'}/>
                          {this._renderHotType('hot')}

                        </div>
                        <div style={styles.gameNameBox}>
                          <div style={styles.gameName}>官方彩票</div>
                        </div>

                      </div>:<div></div>}




                        {
                            this.state.recommend.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => {
                                        if (item.game_id === 201) {
                                            PublicUtils.navigate('GameNavigation');
                                        } else if (item.game_id === 101) {
                                            PublicUtils.navigate('HG', {param: item});
                                        } else {


                                            this._navigateToPage("GameTransfer", {param: item});
                                            // PublicUtils.navigate("GameTransfer", {param: item});
                                        }
                                        // PublicUtils._enterGame(item)
                                    }} style={styles.gameBox}>
                                        <div style={styles.gameImgBox}>
                                            <Image style={styles.gameImg}
                                                   hasPlaceholder={true}
                                                   placeholderSize={{
                                                       width: (model.clientWidth - 40) / 3,
                                                       height: ((model.clientWidth - 40) / 3) - 18
                                                   }}
                                                   uri={item.imgPath}/>
                                            {this._renderHotType(item.hot_type)}

                                        </div>
                                        {
                                            parseInt(item.showTitle) ?
                                                <div style={styles.gameNameBox}>
                                                    <div style={styles.gameName}>{item.name}</div>
                                                </div> : null
                                        }

                                    </div>
                                )
                            })
                        }


                        {global.ID== 'ar' || global.ID== 'bw' ||  global.ID== 'y7' ||  global.ID== 'ct'? <div  onClick={() => {




                            this.vipbyfn2()

                        }} style={styles.gameBox}>
                            <div style={styles.gameImgBox}>
                                <Image style={styles.gameImg}
                                       hasPlaceholder={true}
                                       placeholderSize={{
                                           width: (model.clientWidth - 40) / 3,
                                           height: ((model.clientWidth - 40) / 3) - 18
                                       }}
                                       uri={'https://newpic.hdyb33.com/bw/mg-lhj.png'}/>
                                {this._renderHotType('')}

                            </div>
                            <div style={styles.gameNameBox}>
                                <div style={styles.gameName}>MG老虎机</div>
                            </div>

                        </div>:<div></div>}

                        {global.ID== 'bw' ? <div  onClick={() => {

                            let parm = {
                                "id": 1001,
                                "is_name": "香港六合彩",
                                "is_function": "lhc",
                                "is_number": 0,
                                "is_status": 2,
                                "is_img": "lhc",
                                "tmp": "LHC"
                            }
                          model.isgl = [parm];
                          model.isid = parm.id;

                            PublicUtils.navigate('LHC', parm);
                        }} style={styles.gameBox}>
                            <div style={styles.gameImgBox}>
                                <Image style={styles.gameImg}
                                       hasPlaceholder={true}
                                       placeholderSize={{
                                           width: (model.clientWidth - 40) / 3,
                                           height: ((model.clientWidth - 40) / 3) - 18
                                       }}
                                       uri={'https://newpic.hdyb33.com/bw/lhc-xg-d.png'}/>
                                {this._renderHotType('hot')}

                            </div>
                            <div style={styles.gameNameBox}>
                                <div style={styles.gameName}>香港六合彩</div>
                            </div>

                        </div>:<div></div>}






                        {global.ID== 'ar' || global.ID== 'bw' || global.ID== 'br'  ||  global.ID== 'y7' ||  global.ID== 'ct'? <div  onClick={() => {


                            let parm ={
                                "name": "VIP",
                                "game_id": 407,
                                "type": 4,
                                "title": "捕鱼王",
                                "play": "6"
                            }


                            this.vipbyfn(parm)

                        }} style={styles.gameBox}>
                            <div style={styles.gameImgBox}>
                                <Image style={styles.gameImg}
                                       hasPlaceholder={true}
                                       placeholderSize={{
                                           width: (model.clientWidth - 40) / 3,
                                           height: ((model.clientWidth - 40) / 3) - 18
                                       }}
                                       uri={'https://newpic.hdyb33.com/bw/vip-by.png'}/>
                                {this._renderHotType('hot')}

                            </div>
                            <div style={styles.gameNameBox}>
                                <div style={styles.gameName}>VIP捕鱼</div>
                            </div>

                        </div>:<div></div>}



                        {global.ID== 'ar'  ||  global.ID== 'y7' ||  global.ID== 'ct'? <div  onClick={() => {

                            let parm = {
                                "id": 1001,
                                "is_name": "香港六合彩",
                                "is_function": "lhc",
                                "is_number": 0,
                                "is_status": 2,
                                "is_img": "lhc",
                                "tmp": "LHC"
                            }

                          model.isgl = [parm];
                          model.isid = parm.id;
                            PublicUtils.navigate('LHC', parm);
                        }} style={styles.gameBox}>
                            <div style={styles.gameImgBox}>
                                <Image style={styles.gameImg}
                                       hasPlaceholder={true}
                                       placeholderSize={{
                                           width: (model.clientWidth - 40) / 3,
                                           height: ((model.clientWidth - 40) / 3) - 18
                                       }}
                                       uri={'https://newpic.hdyb33.com/bw/lhc-xg-d.png'}/>
                                {this._renderHotType('hot')}

                            </div>
                            <div style={styles.gameNameBox}>
                                <div style={styles.gameName}>香港六合彩</div>
                            </div>

                        </div>:<div></div>}


                        {global.ID== 'ar'  || global.ID== 'br'  || global.ID== 'bw'  ||   global.ID== 'y7' ||  global.ID== 'ct'? <div  onClick={() => {
                            let parm = {
                              "id": 10015,
                              "is_name": "澳门六合彩(新)",
                              "is_function": "lhc",
                              "is_number": 1,
                              "is_status": 1,
                              "is_img": "amlhc",
                              "tmp": "LHC"
                            }


                          model.isgl = [parm];
                          model.isid = parm.id;


                          PublicUtils.navigate('LHC', parm);
                        }} style={styles.gameBox}>
                            <div style={styles.gameImgBox}>
                                <Image style={styles.gameImg}
                                       hasPlaceholder={true}
                                       placeholderSize={{
                                           width: (model.clientWidth - 40) / 3,
                                           height: ((model.clientWidth - 40) / 3) - 18
                                       }}
                                       uri={'https://newpic.hdyb33.com/bw/lhc-am-d.png'}/>
                                {this._renderHotType('hot')}

                            </div>
                            <div style={styles.gameNameBox}>
                                <div style={styles.gameName}>澳门六合彩(新)</div>
                            </div>

                        </div>:<div></div>}









                    </div>
                </div> : null
        )
    };
    /**
     * 加载优惠活动
     * @private
     */
    _renderActive = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
            }
        };
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        // const Button = createThemedComponent(TouchableOpacity);
        return (
            this.state.activity.length ? (
                <div style={styles.hotActive}>
                    <div style={styles.hotTitle}>
                        <div style={styles.hotBigTitle}>优惠活动></div>
                        {/*<div style={styles.point}>·</div>*/}
                        <div style={styles.hotSubTitle}>天天返水精彩不断</div>
                    </div>
                    <div style={styles.hotActiveBody}>
                        {/*{*/}
                        {/*this.state.redbag.status ?*/}
                        {/*<div onClick={() => {*/}
                        {/*const token = Storage.retrieveData("token") || "";*/}
                        {/*window.location.href = global.ACTIVE_URL + "/active/redpackage/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*}}>*/}
                        {/*<Image uri={this.state.redbag.pic}*/}
                        {/*style={styles.hotActiveImg}/>*/}
                        {/*</div> : null*/}
                        {/*}*/}

                        {/*{*/}
                        {/*this.state.activity_game_list.map((item, key) => {*/}
                        {/*if (item.status) {*/}
                        {/*return (*/}
                        {/*<div key={key} onClick={() => {*/}
                        {/*const token = Storage.retrieveData("token") || "";*/}
                        {/*let url = null;*/}
                        {/*switch (item.type) {*/}
                        {/*case 1:*/}
                        {/*url = global.ACTIVE_URL + "/active/rotary/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*break;*/}
                        {/*case 2:*/}
                        {/*url = global.ACTIVE_URL + "/active/box/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*break;*/}
                        {/*case 3:*/}
                        {/*url = global.ACTIVE_URL + "/active/egg/index.html?id=" + global.ID + "&token=" + token || "";*/}
                        {/*break;*/}
                        {/*}*/}
                        {/*window.location.href = url;*/}
                        {/*}}>*/}
                        {/*<Image uri={item.pic}*/}
                        {/*style={styles.hotActiveImg}/>*/}
                        {/*</div>*/}
                        {/*)*/}
                        {/*} else {*/}
                        {/*return null;*/}
                        {/*}*/}
                        {/*})*/}
                        {/*}*/}
                        {
                            this.state.activity.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => {
                                        const parma = {id: item.id, title: item.is_title};
                                        PublicUtils.navigate("ActiveDetail", parma);
                                    }}>
                                        <Image uri={item.is_mobile_pic} hasPlaceholder={true}
                                               placeholderSize={{
                                                   width: model.clientWidth - 20,
                                                   height: 50
                                               }}
                                               style={styles.hotActiveImg}/>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            ) : null
        )
    };
    /**
     * 活动
     * @returns {null}
     * @private
     */
    _renderHotActive = () => {
        const styles = {
            activeBox: {
                position: "fixed",
                bottom: 110,
                right: 30,
                width: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
            }
        }
        return (
            this.state.activity_game_list.length || this.state.redbag.status ? (
                <div style={styles.activeBox} onClick={() => {
                    alert(0);
                }}>
                    <Image
                        uri={require('../../../assets/images/active.png')}/>
                </div>
            ) : null
        )
    };
    /**
     * 活动详情
     * @private
     */
    _renderHotActiveDetail = () => {



        return (
            this.state.activity_game_list.length || this.state.redbag.status ? (
                <HotActive activity_game_list={this.state.activity_game_list} redbag={this.state.redbag}/>
            ) : null
        )
    };
    /**
     * 加载超级大赢家
     * @private
     */
    _renderWinner = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
            }
        };
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            }
        };
        return (
            this.state.playdata.length ?
                <div style={styles.superWinnerBox}>
                    <div style={styles.hotTitle}>
                        <div style={styles.hotBigTitle}>超级大赢家></div>
                        {/*<div style={styles.point}>·</div>*/}
                        <div style={styles.hotSubTitle}>玩家赚钱率高达98%</div>
                    </div>
                    <div style={styles.superWinnerBody}>
                        <div className={'superWinnerInnerBox'}>
                            {
                                this.state.playdata.map((item, key) => {
                                    return (
                                        <div key={key} style={styles.superWinnerItem}>
                                            <div style={styles.superWinnerItemLeft}>
                                                <Image style={styles.superWinneImg}
                                                       uri={require('../../../assets/images/jb.png')}/>
                                                <div style={styles.superWinnerText}>
                                                    {item.username}
                                                </div>
                                                <div style={styles.superWinnerText1}>在</div>
                                                <div
                                                    style={styles.superWinnerText2}>{item.game}</div>
                                                <div style={styles.superWinnerText1}>赢取</div>
                                                <div style={styles.superWinnerText3}>￥{item.money}</div>
                                            </div>
                                            <div style={styles.superWinnerItemRight}>
                                                <div
                                                    style={styles.superWinnerItemRightText}>{item.time}分钟前
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div> : null
        );
    };


    async vipbyfn(obj){
        if (await PublicUtils._checkLogin()) {


            PublicUtils._enterGamePage(obj);

        } else {
            PublicUtils.navigate('Login', {nextPage: '/'});
        }
    }


    async vipbyfn2(){
        if (await PublicUtils._checkLogin()) {

            let obj = {
                "type": 4,
                "game_id": 409,
                "game_api": "MG",
                "name": "MG老虎机",
                "imgPath": "https://newpic.hdyb33.com/games/mg.png",
                "icon": "logo-mg",
                "game_list_json": "MG_game_list",
                "game_list_img_path": "/new/slot_res/mg/",
                "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
                "memo2": "包含游戏：108好汉、亚洲美人、大逃杀、冒险宫殿、靶心、抢劫银行、欢乐节、昆虫派对...",
                "labels": "#老虎机#108好汉#亚洲美人#大逃杀#冒险宫殿#靶心#抢劫银行#欢乐节#昆虫派对"
            }

            PublicUtils.navigate("GameTransfer", {param: obj});

        } else {
            PublicUtils.navigate('Login', {nextPage: '/'});
        }
    }



    _withdrawal = async () => {
        if (await PublicUtils._checkLogin()) {
            let userInfo = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
            console.log(userInfo);
            if (userInfo.pay_card) {
                this._navigateToPage("Withdrawal");
            } else {

                PublicUtils.alert({
                    title: "温馨提示",
                    msg: "您必须绑定银行卡之后才能提现哦",
                    buttons: [
                        {
                            text: "取消", onPress: () => {
                            }
                        },
                        {
                            text: "立即绑定", onPress: async () => {
                                const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
                                if (user.user_name) {
                                    this._navigateToPage("Bindbank");
                                } else {
                                    PublicUtils.showToast("您还没有绑定姓名哦，请前往个人中心绑定", {type: "FAILD"});
                                }
                            }
                        }
                    ]
                });
            }
        } else {
            PublicUtils.navigate('Login', {nextPage: '/'});
        }
    };

    _renderNoticeWin = () => {
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 40, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            caContent:{
                borderRadius: 6,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                // padding: 10,
                // borderRadius: 6,
            }
        };
        if(global.ID == 'ca'){
            winStyles.closeButtonBottom = {width: 1, height: 10, borderRight: "2px solid #fff"};
        }
        let content = "";
        if (global.ID == "ao") {
            content = "<div style='width: 100%;text-align: center;'><span>系统已经维护完成，请联系在线客服</span>" +
                "<a style='display: block;margin-top: 20px;' target='_blank' href='https://chat-new.mqimg.com/widget/standalone.html?eid=63626'>点击联系</a></div>";
        }
        if (global.ID == "ao") {
            return this.state.show ? <div style={winStyles.bgShadow}>
                <div style={winStyles.innerBody}>
                    <div style={winStyles.title}>
                        <div style={winStyles.closeButtonTop} onClick={() => {
                            //下次进入首页将不在显示广告弹窗
                            model.showNoticeWin = true;
                            this.setState({show: false});
                        }}>
                            <Icon icon={"close"} size={20} color={"#fff"}/>
                        </div>
                        <div style={winStyles.closeButtonBottom}/>
                    </div>
                    <div style={winStyles.content}>
                        <div style={winStyles.noticeWinContentBox}
                             dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </div>
            </div> : null
        } else if(global.ID == "ca"){
            return this.state.notice_win && this.state.show && this.state.notice_win.mobile_status && !model.showNoticeWin ?
                <div style={winStyles.bgShadow}>
                    <div style={{width:'90%'}}>
                        <div style={winStyles.title}>
                            <div style={winStyles.closeButtonTop} onClick={() => {
                                //下次进入首页将不在显示广告弹窗
                                model.showNoticeWin = true;
                                this.setState({show: false});
                            }}>
                                <Icon icon={"close"} size={20} color={"#fff"}/>
                            </div>
                            <div style={winStyles.closeButtonBottom}/>
                        </div>
                        <div style={winStyles.caContent}>
                            {
                                this.state.notice_win.mobile_true_content ?
                                    <div
                                        dangerouslySetInnerHTML={{__html: this.state.notice_win.mobile_true_content || this.state.notice_win.pc_content}}/>: <Image width={'100%'}
                                                                                                                                                                    uri={this.state.notice_win.mobile_content}/>
                            }
                        </div>

                    </div>
                </div> : null
        } else{
            return this.state.notice_win && this.state.show && this.state.notice_win.mobile_status && !model.showNoticeWin ?
                <div style={winStyles.bgShadow}>
                    <div style={{width:'90%'}}>
                        <div style={winStyles.title}>
                            <div style={winStyles.closeButtonTop} onClick={() => {
                                //下次进入首页将不在显示广告弹窗
                                model.showNoticeWin = true;
                                this.setState({show: false});
                            }}>
                                <Icon icon={"close"} size={20} color={"#fff"}/>
                            </div>
                            <div style={winStyles.closeButtonBottom}/>
                        </div>
                        <div style={winStyles.content}>
                            {
                                this.state.notice_win.mobile_true_content ?
                                    <div style={winStyles.noticeWinContentBox} onClick={() => {

                                    }}
                                         dangerouslySetInnerHTML={{__html: this.state.notice_win.mobile_true_content || this.state.notice_win.pc_content}}/>: <Image width={'100%'}
                                                                                                                                                                     uri={this.state.notice_win.mobile_content}/>
                            }
                        </div>

                    </div>
                </div> : null
        }
    }





    render() {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderRadius: "0 0 100% 100%",
                // borderBottomRightRadius: 95,
                // borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            },
            leftSlideBtn: {
                // position: "absolute",
                display: "flex",
                width: 35,
                height: 35,
                borderRadius: 35,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
                marginLeft: 10
            },
            kftb:{
                display:'flex'
            },
            kfbtn:{
                borderRadius:6,
                paddingTop:6,
                paddingBottom:6,
                paddingLeft:2,
                paddingRight:2,
                color:'white',
                fontSize:12,
                marginRight:8,
            },
            kfbtn4:{
                borderRadius:6,
                color:'#fef093',
                border:'1px solid #fef093',
                fontSize:12,
                marginRight:8,
            },
            kfbtn2:{
                backgroundImage:'linear-gradient(rgb(40 68 103), rgb(47, 122, 150))'
            },
            kfbtn3:{
                backgroundImage:'linear-gradient(rgb(40 68 103), rgb(47, 122, 150))'
            },

        };


        // 使用与不使用搜索栏
        let ssl;
        switch (global.ID) {
            case 'y5':
                ssl = <div style={{display:"flex",flex:1}}>
                    <Image style={{width:"100%"}} resizeMode='contain'
                           uri={require('../../../assets/images/logoy5.png?v1')}/>
                </div>
                break;
            case 'y6':
                ssl = <div style={{display:"flex",flex:1}}>
                    <Image style={{width:"100%"}} resizeMode='contain'
                           uri={require('../../../assets/images/logoy6.png')}/>
                </div>
                break;
            case 'b8':
                ssl = <div style={{display:"flex",flex:1,justifyContent:'center'}}>
                    <Image style={{width:"85%"}} resizeMode='contain'
                           uri={require('../../../assets/images/logob8.png')}/>
                </div>
                break;
            case 'y8':
                ssl = <div style={{display:"flex",flex:1}}>
                    <Image style={{width:"100%"}} resizeMode='contain'
                           uri={require('../../../assets/images/logoy8.png')}/>
                </div>
                break;
            case 'x9':

                ssl = <div style={{display:"flex",flex:1}}>
                    <Image style={{width:"100%"}} resizeMode='contain'
                           uri={require('../../../assets/images/logoy9.png')}/>
                </div>
                break;
            default:
                ssl = <div style={styles.searchIpt} onClick={() => {
                    PublicUtils.navigate("SearchView")
                    // PublicUtils.showToast("21212");
                    // PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
                }}>
                    <Icon icon={'sousuo'} color={"#fff"} size={15}/>
                    <span style={styles.searchTextTip}>搜索游戏、平台</span>
                </div>
                break;
        }

        // 使用客服与不使用客服
        let kfh;
        switch (global.ID){
            case 'y8':
                kfh = this.state.usedl?<div style={styles.kftb}>

                    <div style={styles.iconBox}
                         onClick={() => {
                             this._pcUrlPage();
                         }}>
                        {/*<Icon icon={'computer'} color={"#fff"} size={23}/>*/}
                        <Image style={{width: 30}} resizeMode='contain'
                               uri={require('../../../assets/images/diannao.gif')}/>
                    </div>
                </div>:<div style={styles.kftb}>
                    <div style={{...styles.iconBox,...styles.kfbtn,...styles.kfbtn2}} onClick={() => {
                        PublicUtils.navigate('Register', {nextPage: '/'});
                    }}>
                        注册
                    </div>
                    <div style={{...styles.iconBox,...styles.kfbtn,...styles.kfbtn3}}
                         onClick={() => {
                             PublicUtils.navigate('Login', {nextPage: '/'});
                         }}>
                        登陆
                    </div>
                </div>
                break;
            case 'b8':

                kfh = this.state.usedl?<div style={styles.kftb}>

                    <div style={styles.iconBox}
                         onClick={() => {
                             this._pcUrlPage();
                         }}>
                        {/*<Icon icon={'computer'} color={"#fff"} size={23}/>*/}
                        <Image style={{width: 30}} resizeMode='contain'
                               uri={require('../../../assets/images/diannao.gif')}/>
                    </div>
                </div>:<div style={styles.kftb}>
                  <div style={styles.iconBox}
                           onClick={() => {
                               PublicUtils.navigate("Register");
                               // this._navigateToPage("Register");
                           }}>
                          {/*<Icon icon={'new-msg'} color={"#fff"} size={20}/>*/}
                          <Image style={{width: 30}} resizeMode='contain'
                                 uri={require('../../../assets/images/reg.gif')}/>
                   </div>
                    <div style={styles.iconBox} onClick={() => {
                        this._services();
                    }}>
                        {/*<Icon icon={'new-kefu'} color={"#fff"} size={26}/>*/}
                        <Image style={{width: 30}} resizeMode='contain'
                               uri={require('../../../assets/images/kefu.gif')}/>
                    </div>
                </div>
                break;
            default:
                kfh = <div style={styles.kftb}>
                    <div style={styles.iconBox} onClick={() => {
                        this._services();
                    }}>
                        {/*<Icon icon={'new-kefu'} color={"#fff"} size={26}/>*/}
                        {
                            global.ID == 'b6'? <Image style={{width: 30}} resizeMode='contain'
                                                      uri={require('../../../assets/images/kefu2.gif')}/>: <Image style={{width: 30}} resizeMode='contain'
                                                                                                                 uri={require('../../../assets/images/kefu.gif')}/>
                        }
                    </div>
                    <div style={styles.iconBox}
                         onClick={() => {
                             this._pcUrlPage();
                         }}>
                        {/*<Icon icon={'computer'} color={"#fff"} size={23}/>*/}
                        <Image style={{width: 30}} resizeMode='contain'
                               uri={require('../../../assets/images/diannao.gif')}/>
                    </div>
                </div>
        }


        return (
            <Container tabIndex={0} showBottomTab={true} showHeader={false} style={{backgroundColor: "#fff"}}>
                <div style={styles.searchBarHeader} className={'headPadding'}>
                    <div style={styles.searchBar}>
                        <div style={styles.leftSlideBtn} type={1} onClick={() => {
                            this.DrawerPage.show();
                        }}>
                            <div>
                                <Icon icon={'caidan'} color={"#fff"} size={15}/>
                            </div>
                        </div>
                        {/*<div style={styles.iconBox} onClick={() => {*/}
                        {/*    this._services();*/}
                        {/*}}>*/}
                        {/*    /!*<Icon icon={'new-kefu'} color={"#fff"} size={26}/>*!/*/}
                        {/*    <Image style={{width: 30}} resizeMode='contain'*/}
                        {/*           uri={require('../../../assets/images/kefu.gif')}/>*/}
                        {/*</div>*/}
                        {/*搜索栏*/}
                        {ssl}





                        {/*客服*/}
                        {kfh}
                        {/*{*/}
                        {/*    !PublicUtils._checkLogin() ?*/}

                        {/*        <div style={styles.iconBox}*/}
                        {/*             onClick={() => {*/}
                        {/*                 PublicUtils.navigate("Register");*/}
                        {/*                 // this._navigateToPage("Register");*/}
                        {/*             }}>*/}
                        {/*            /!*<Icon icon={'new-msg'} color={"#fff"} size={20}/>*!/*/}
                        {/*            <Image style={{width: 30}} resizeMode='contain'*/}
                        {/*                   uri={require('../../../assets/images/reg.gif')}/>*/}
                        {/*        </div> : null*/}
                        {/*}*/}
                    </div>
                </div>
                <div style={styles.body} className={'headPadding'}>
                    <div style={styles.headerbg}/>
                    <div style={styles.quickEntryBox}>
                        {
                            this._renderSwiperList()
                        }
                        <div style={styles.quickEntry}>
                            <div style={styles.quickEntryItem} onClick={() => {
                                // this.dropdown.alertWithType('error', 'Error', "12121212");
                                this._navigateToPage("RechargeChannel");
                            }}>
                                <Image style={styles.quickEntryImg} resizeMode='contain'
                                       uri={require('../../../assets/images/chongzhi.png')}/>
                                <div style={styles.quickEntryText}>充值</div>
                            </div>
                            <div style={styles.quickEntryItem} onClick={() => {
                                this._withdrawal();
                            }}>
                                <Image style={styles.quickEntryImg} resizeMode='contain'
                                       uri={require('../../../assets/images/tixian.png')}/>
                                <div style={styles.quickEntryText}>提现</div>
                            </div>
                            <div style={styles.quickEntryItem} onClick={() => {
                                this._navigateToPage("AccountChange");
                            }}>
                                <Image style={styles.quickEntryImg} resizeMode='contain'
                                       uri={require('../../../assets/images/zhangdan.png')}/>
                                <div style={styles.quickEntryText}>账单</div>
                            </div>
                            <div style={styles.quickEntryItem} onClick={() => {
                                this._navigateToPage("GameTransfer");
                            }}>
                                <Image style={styles.quickEntryImg} resizeMode='contain'
                                       uri={require('../../../assets/images/zhuanzhang.png')}/>
                                <div style={styles.quickEntryText}>转账</div>
                            </div>
                                             {
                                global.ID == "br" ?
                                  <div style={styles.quickEntryItem} onClick={() => {
                                      PublicUtils.linking(model.serviceUrl_1, "_blank");
                                  }}>
                                      <Image style={styles.quickEntryImg} resizeMode='contain'
                                             uri={require('../../../assets/images/kefu.png')}/>
                                      <div style={styles.quickEntryText}>客服</div>
                                  </div> : null
                            }


                            {
                                global.ID == "b6" ?
                                  <div style={styles.quickEntryItem} onClick={() => {
                                      this._services();
                                  }}>
                                      <Image style={styles.quickEntryImg} resizeMode='contain'
                                             uri={require('../../../assets/images/kefu.png')}/>
                                      <div style={styles.quickEntryText}>客服</div>
                                  </div> : null
                            }


                            {
                                global.ID == "y8" ?
                                    <div style={styles.quickEntryItem} onClick={() => {
                                        this._services();
                                    }}>
                                        <Image style={styles.quickEntryImg} resizeMode='contain'
                                               uri={require('../../../assets/images/kefu.png')}/>
                                        <div style={styles.quickEntryText}>客服</div>
                                    </div> : null
                            }

                            {
                                global.ID == "b8" ?
                                  <div style={styles.quickEntryItem} onClick={() => {
                                      this._services();
                                  }}>
                                      <Image style={styles.quickEntryImg} resizeMode='contain'
                                             uri={require('../../../assets/images/kefu.png')}/>
                                      <div style={styles.quickEntryText}>客服</div>
                                  </div> : null
                            }

                            {
                                global.ID == "bw" ?
                                    <div style={styles.quickEntryItem} onClick={() => {
                                        PublicUtils.linking(model.serviceUrl_1, "_blank");
                                    }}>
                                        <Image style={styles.quickEntryImg} resizeMode='contain'
                                               uri={require('../../../assets/images/kefu.png')}/>
                                        <div style={styles.quickEntryText}>客服</div>
                                    </div> : null
                            }
                            {
                                global.ID == "ar" ?
                                    <div style={styles.quickEntryItem} onClick={() => {
                                        PublicUtils.linking(model.serviceUrl_1, "_blank");
                                    }}>
                                        <Image style={styles.quickEntryImg} resizeMode='contain'
                                               uri={require('../../../assets/images/kefu.png')}/>
                                        <div style={styles.quickEntryText}>客服</div>
                                    </div> : null
                            }
                            {
                                global.ID == "ct" ?
                                    <div style={styles.quickEntryItem} onClick={() => {
                                        PublicUtils.linking(model.serviceUrl_1, "_blank");
                                    }}>
                                        <Image style={styles.quickEntryImg} resizeMode='contain'
                                               uri={require('../../../assets/images/kefu.png')}/>
                                        <div style={styles.quickEntryText}>客服</div>
                                    </div> : null
                            }
                            {
                                global.ID == "y7" ?
                                    <div style={styles.quickEntryItem} onClick={() => {
                                        PublicUtils.linking(model.serviceUrl_1, "_blank");
                                    }}>
                                        <Image style={styles.quickEntryImg} resizeMode='contain'
                                               uri={require('../../../assets/images/kefu.png')}/>
                                        <div style={styles.quickEntryText}>客服</div>
                                    </div> : null
                            }

                          {
                            global.ID == "js" ?
                                <div style={styles.quickEntryItem} onClick={() => {
                                  PublicUtils.linking('https://d31quec3ntcede.cloudfront.net/hh.html', "_blank");
                                }}>
                                  <Image style={styles.quickEntryImg} resizeMode='contain'
                                         uri={require('../../../assets/images/kefu.png')}/>
                                  <div style={styles.quickEntryText}>客服2</div>
                                </div> : null
                          }
                        </div>


                        {/*{   global.ID == 'yy'?<div style={{paddingTop:15,paddingBottom:15}} onClick={()=>{*/}

                        {/*    let tempItem = {*/}
                        {/*        "type": 1,*/}
                        {/*        "game_id": 101,*/}
                        {/*        "game_api": "",*/}
                        {/*        "name": "皇冠体育",*/}
                        {/*        "imgPath": "https://newpic.hdyb33.com/sport/xtty.png",*/}
                        {/*        "icon": "logo-hg",*/}
                        {/*        "memo1": "平台特色：火爆赛事，玩法多样",*/}
                        {/*        "memo2": "包含游戏：皇冠体育、各种球类下注、串关",*/}
                        {/*        "labels": "#体育#皇冠#足球",*/}
                        {/*        "hot_type": "hot",*/}
                        {/*        "showTitle": 1*/}
                        {/*    }*/}


                        {/*    PublicUtils.navigate('HG', {param: tempItem});*/}

                        {/*}}>*/}
                        {/*    <Image  resizeMode='contain'*/}
                        {/*           uri={'https://newpic.hdyb33.com/yy/sjb.gif'}/>*/}
                        {/*</div>:<div></div>}*/}

                        {
                            this._renderDownload()
                        }
                        {
                            this._renderNotice()
                        }
                    </div>
                    {
                        this._renderHotList()
                    }
                    <HotAlotGameList2 hotCasino={this.state.hotCasino}/>
                    {
                        this._renderActive()
                    }
                    {
                        this._renderWinner()
                    }
                    <div style={styles.bodyEnd}>
                        <div style={styles.bodyEndText}>--</div>
                        <div style={styles.bodyEndText}>没有更多内容</div>
                        <div style={styles.bodyEndText}>--</div>
                    </div>
                </div>
                {
                    this._renderNoticeWin()
                }
                {
                    this._renderHotActiveDetail()
                }
                <DrawerPage ref={(ref) => {
                    this.DrawerPage = ref;
                }}/>


                {/*{global.ID == "ca"?<a target="_blank" href="https://tiyu.baidu.com/match/%E4%B8%96%E7%95%8C%E6%9D%AF/date_time/2022-11-21/tab/%E8%B5%9B%E7%A8%8B/from/baidu_aladdin" style={{position:'fixed',left:10,bottom:70,width:60,zIndex:'500'}}><img src="https://newpic.hdyb33.com/ca/issjb.gif" width="100%"/></a>:<div></div>}*/}

            </Container>
        )
    }
}

export default withRouter(Default);
