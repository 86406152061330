/**
 * 完成提现
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import history from "../services/history";
import Icon from '../utils/icon'
import PublicUtils from "../utils/publicUtils";
import MyInput from '../component/myInput'
import MyButton from '../component/myButton'
import style from '../utils/config.scss'
import model from "../services/model";

export default class WithdrawComplete extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            message: PublicUtils.getState(this.props.location.pathname).message,
            orderno: PublicUtils.getState(this.props.location.pathname).orderno,
            amount: PublicUtils.getState(this.props.location.pathname).amount,
            bankname: PublicUtils.getState(this.props.location.pathname).bankname,
            banknum: PublicUtils.getState(this.props.location.pathname).banknum
        };
    }

    render() {
        const styles = {
            container: {
                paddingTop: 20},
            msgBox: {
                display:"flex",
                flexDirection: 'row',
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 10,
                paddingBottom: 10
            },
            bindSuccessTips: {
                color: style['primaryColor' + model.SKINNAME],
                fontSize: 16,
                textAlign: "center"
            },
            iconBox: {
                display:"flex",
                flexDirection: "row",
                justifyContent: "center"
            },
            bankInfoContainer: {
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 20,
                marginBottom: 20
            },
            bankInfoBox: {
                display:"flex",
                paddingTop: 10,
                paddingBottom: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: '1px dashed #f5f7f9',
            },
            bankInfoHeader: {
                textAlign: "left",
                width: 70,
                color: "#999",
                fontSize:14
            },
            bankInfoBody: {
                display:"flex",
                flex: 1,
                textAlign: "right",
                color: "#333",
                fontSize:14
            },

            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display:"flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
        };
        return (
            <Container title={'提款完成'} style={{backgroundColor: "#fff"}}>
                <div style={styles.container}>
                    <div style={styles.iconBox}>
                        <Icon icon={'round-yes'} size={60} color={style['primaryColor' + model.SKINNAME]}/>
                    </div>
                    <div style={styles.msgBox}>
                        <div style={styles.bindSuccessTips}>{this.state.message}</div>
                    </div>
                    <div style={styles.bankInfoContainer}>
                        <div style={styles.bankInfoBox}>
                            <div style={styles.bankInfoHeader}>流水号：</div>
                            <div style={styles.bankInfoBody}>{this.state.orderno}</div>
                        </div>
                        <div style={styles.bankInfoBox}>
                            <div style={styles.bankInfoHeader}>提款金额：</div>
                            <div style={styles.bankInfoBody}>{this.state.amount}</div>
                        </div>
                        <div style={styles.bankInfoBox}>
                            <div style={styles.bankInfoHeader}>卡号：</div>
                            <div style={styles.bankInfoBody}>{this.state.banknum}</div>
                        </div>
                        <div style={styles.bankInfoBox}>
                            <div style={styles.bankInfoHeader}>银行：</div>
                            <div style={styles.bankInfoBody}>{this.state.bankname}</div>
                        </div>
                    </div>
                    <div style={styles.btnBox}>
                        <div
                            onClick={() => {
                                PublicUtils.navigate("/");
                            }} style={styles.btn}>
                            <div style={styles.btnText}>返回首页</div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}