/**
 * 赛事列表
 */

import React, {Component} from 'react';
import MatchItem from "./matchItem";
import PropTypes from 'prop-types';
import Icon from '../../../../utils/icon'

class MatchList extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            // isReady: this.props.isReady
        }
    }

    static propTypes = {
        matchList: PropTypes.array,
        playId: PropTypes.number,
        ballId: PropTypes.number,
        betId: PropTypes.number,
        listFooterComponent: PropTypes.func,
        onOddPress: PropTypes.func
        // isReady: PropTypes.bool
    };
    static defaultProps = {
        // isReady: false
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面渲染完成时
     */
    componentDidMount() {
        // 1: Component is mounted off-screen
    }

    componentWillReceiveProps(nextProps) {
        //this.setState({isReady: nextProps.isReady, matchList: nextProps.matchList});
        // InteractionManager.runAfterInteractions(() => {
        //     // 2: Component is done animating
        //     // 3: Start fetching the team / or render the view
        //     // this.props.dispatchTeamFetchStart();
        //     this.setState({
        //         isReady: true
        //     })
        // });
    }

    /**
     * 点击下注
     * @param template：数据模板 data：数据
     */
    _onPress = (template, data) => {
        this.props.onOddPress(template, data);
    };

    render() {
        const matchStyles = {
            matchListWrapper: {
                display: "flex",
                flexDirection: "column",
                flexGrow: 0
            }
        };
        // if (!this.state.isReady) {
        //     return (
        //         <ActivityIndicator style={{paddingTop: 20}}/>
        //     );
        // }
        return (
            <div style={matchStyles.matchListWrapper}>
                {
                    this.props.matchList.map((item,key) => {
                        return (
                            <MatchItem key={key} item={item} checked={item.checked} playId={this.props.playId}
                                       ballId={this.props.ballId} betId={this.props.betId}
                                       onOddPress={(template, data) => {
                                           this._onPress(template, data);
                                       }}/>
                        )
                    })
                }
            </div>
        )
    }
}


export default MatchList