/**
 * 玩法说明
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../component/container";
import PublicUtils from "../../utils/publicUtils";

export default class LotteryIntroduction extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.is_code = PublicUtils.getState(this.props.location.pathname).code;
        this.title = PublicUtils.getState(this.props.location.pathname).name;
        // switch (this.is_code) {
        //     case 7001:
        //         this.title = "PC蛋蛋";
        //         break;
        //     case 6001:
        //         this.title = "PK拾";
        //         break;
        //     case 6002:
        //         this.title = "幸运飞艇";
        //         break;
        //     case 1001:
        //         this.title = "六合彩";
        //         break;
        //     case 2003:
        //         this.title = "新疆时时彩";
        //         break;
        //     case 2001:
        //         this.title = "重庆时时彩";
        //         break;
        //     case 2002:
        //         this.title = "天津时时彩";
        //         break;
        //     case 4002:
        //         this.title = "重庆快乐十分";
        //         break;
        //     case 4001:
        //         this.title = "广东快乐十分";
        //         break;
        //     case 3002:
        //         this.title = "广西快三";
        //         break;
        //     case 3003:
        //         this.title = "安徽快三";
        //         break;
        //     case 3001:
        //         this.title = "江苏快三";
        //         break;
        //     case 30011:
        //         this.title = "三分快三";
        //         break;
        //     case 10011:
        //         this.title = "三分六合彩";
        //         break;
        //     case 20011:
        //         this.title = "三分时时彩";
        //         break;
        //     case 40011:
        //         this.title = "三分十分";
        //         break;
        //     case 60011:
        //         this.title = "三分PK拾";
        //         break;
        //     case 70011:
        //         this.title = "三分PC蛋蛋";
        //         break;
        //     default:
        //         PublicUtils.showToast("游戏维护中...")
        // }
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // PublicUtils.setParams({openMenu: this._openMenu});
    }

    _renderInfo = () => {
        switch (this.is_code) {
            case 7001:
                return this._renderPCDD();
            case 6001:
                return this._renderPKS();
            case 6002:
                return this._renderXYFT();
            case 1001:
                return this._renderLHC();
            case 2003:
                return this._renderXJSSC();
            case 2001:
                return this._renderCQSSC();
            case 2002:
                return this._renderTJSSC();
            case 4002:
                return this._renderCQKLSF();
            case 4001:
                return this._renderGDKLSF();
            case 3002:
                return this._renderGXKS();
            case 3003:
                return this._renderAHKS();
            case 3001:
                return this._renderJSKS();
            case 30011:
                this.min = 3;
                this.count = 480;
                return this._renderSFKS();
            case 30012:
                this.min = 5;
                this.count = 288;
                return this._renderSFKS();
            case 30013:
                this.min = 10;
                this.count = 144;
                return this._renderSFKS();
            case 10014:
                return this._renderAMLHC();
          case 10015:
            return this._renderAMLHC2();

            case 10011:
                this.min = 3;
                this.count = 480;
                return this._renderSFLHC();
            case 10012:
                this.min = 5;
                this.count = 288;
                return this._renderSFLHC();
            case 10013:
                this.min = 10;
                this.count = 144;
                return this._renderSFLHC();
            case 20011:
                this.min = 3;
                this.count = 480;
                return this._renderSFSSC();
            case 20012:
                this.min = 5;
                this.count = 288;
                return this._renderSFSSC();
            case 20013:
                this.min = 10;
                this.count = 144;
                return this._renderSFSSC();
            case 40011:
                this.min = 3;
                this.count = 480;
                return this._renderSFKLSF();
            case 40012:
                this.min = 5;
                this.count = 288;
                return this._renderSFKLSF();
            case 40013:
                this.min = 10;
                this.count = 144;
                return this._renderSFKLSF();
            case 60011:
                this.min = 3;
                this.count = 480;
                return this._renderSFPKS();
            case 60012:
                this.min = 5;
                this.count = 288;
                return this._renderSFPKS();
            case 60013:
                this.min = 10;
                this.count = 144;
                return this._renderSFPKS();
            case 60021:
                this.min = 3;
                this.count = 480;
                return this._renderSFPKS();
            case 60022:
                this.min = 5;
                this.count = 288;
                return this._renderSFPKS();
            case 60023:
                this.min = 10;
                this.count = 144;
                return this._renderSFPKS();
            case 70011:
                this.min = 3;
                this.count = 480;
                return this._renderSFPCDD();
            case 70012:
                this.min = 5;
                this.count = 288;
                return this._renderSFPCDD();
            case 70013:
                this.min = 10;
                this.count = 144;
                return this._renderSFPCDD();
            default:
                PublicUtils.showToast("游戏维护中...")
        }
    };

    /**
     * PC蛋蛋
     * @returns {*}
     * @private
     */
    _renderPCDD = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>PC蛋蛋游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"北京PC蛋蛋"完全同步，北京时间（GMT+8）每天白天从上午09:00:00开到晚上23:55:00，每5分鐘开一次奖,每天开奖179期。开奖结果以小到大排序作为计算开奖结果，PC蛋蛋为外围盘玩法
                </div>
                <div style={styles.title2}>2、下注数字为以下三区的总和来判定</div>
                <div style={styles.title3}>第一区 = 第1-6和值的末位数</div>
                <div style={styles.title3}>第二区 = 第7-12和值的末位数</div>
                <div style={styles.title3}>第三区 = 第13-18和值的末位数</div>
                <div style={styles.title3}>例如：快乐8第"641841"期数据从小到大排序
                    01,03,13,16,23,27,40,41,45,49,53,54,57,62,63,67,68,71,72,78
                </div>
                <div style={styles.title3}>第一区[第1/2/3/4/5/6位数字] 1,3,13,16,23,27 计算：1+3+13+16+23+27= 83 结果为3</div>
                <div style={styles.title3}>第二区[第7/8/9/10/11/12位数字]40,41,45,49,53,54 计算：40+41+45+49+53+54= 282 结果为2</div>
                <div style={styles.title3}>第三区[第13/14/15/16/17/18位数字]57,62,63,67,68,71 计算：57+62+63+67+68+71= 388 结果为8
                </div>
                <div style={styles.title3}>最终游戏开奖为：3+2+8=13</div>
                <div style={styles.title2}>3、总和大小单双玩法：</div>
                <div style={styles.title3}>总和为单数，如1,3,5,7,9等判总和单赢</div>

                <div style={styles.title2}>4、总和大小单双玩法</div>
                <div style={styles.title3}>总和为双数，如0，2，4，6，8等判总和双赢</div>

                <div style={styles.title2}>5、总和大小单双玩法</div>
                <div style={styles.title3}>总和为大于等于14，如14，15···，27等判总和大赢</div>

                <div style={styles.title2}>6、总和大小单双玩法</div>
                <div style={styles.title3}>总和为小于等于13，如0,···，12，13等判总和小赢</div>

                <div style={styles.title2}>7、混合组合</div>
                <div style={styles.title3}>大单：总和为15-27的单数</div>
                <div style={styles.title3}>大双：总和为14-26的双数</div>
                <div style={styles.title3}>小单：总和为1-13的单数</div>
                <div style={styles.title3}>小双：总和为0-12的双数</div>

                <div style={styles.title2}>8、极值</div>
                <div style={styles.title3}>总和为极小 : 0-5 极大:22-27</div>

                <div style={styles.title2}>9、波色</div>
                <div style={styles.title3}>绿波号码：1 , 4 , 7 , 10 , 16 , 19 , 22 , 25</div>
                <div style={styles.title3}>蓝波号码：2 , 5 , 8 , 11 , 17 , 20 , 23 , 26</div>
                <div style={styles.title3}>红波号码：3 , 6 , 9 , 12 , 15 , 18 , 21 , 24</div>
                <div style={styles.title3}>出现0,27,13,14算输</div>

                <div style={styles.title2}>10、豹子</div>
                <div style={styles.title3}>当期3个开奖号码一致则中奖, 如当期开奖结果为 3+3+3=9 则中奖豹子。</div>
            </div>
        );
    };
    /**
     * PC蛋蛋
     * @returns {*}
     * @private
     */
    _renderSFPCDD = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>{this.title}游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏每天00:00开到晚上23:59，每{this.min}分鐘开一次奖,每天开奖{this.count}期。
                </div>
                <div style={styles.title2}>2、下注数字为以下三区的总和来判定</div>
                <div style={styles.title3}>第一区 = 第1-6和值的末位数</div>
                <div style={styles.title3}>第二区 = 第7-12和值的末位数</div>
                <div style={styles.title3}>第三区 = 第13-18和值的末位数</div>
                <div style={styles.title3}>例如：快乐8第"641841"期数据从小到大排序
                    01,03,13,16,23,27,40,41,45,49,53,54,57,62,63,67,68,71,72,78
                </div>
                <div style={styles.title3}>第一区[第1/2/3/4/5/6位数字] 1,3,13,16,23,27 计算：1+3+13+16+23+27= 83 结果为3</div>
                <div style={styles.title3}>第二区[第7/8/9/10/11/12位数字]40,41,45,49,53,54 计算：40+41+45+49+53+54= 282 结果为2</div>
                <div style={styles.title3}>第三区[第13/14/15/16/17/18位数字]57,62,63,67,68,71 计算：57+62+63+67+68+71= 388 结果为8
                </div>
                <div style={styles.title3}>最终游戏开奖为：3+2+8=13</div>
                <div style={styles.title2}>3、总和大小单双玩法：</div>
                <div style={styles.title3}>总和为单数，如1,3,5,7,9等判总和单赢</div>

                <div style={styles.title2}>4、总和大小单双玩法</div>
                <div style={styles.title3}>总和为双数，如0，2，4，6，8等判总和双赢</div>

                <div style={styles.title2}>5、总和大小单双玩法</div>
                <div style={styles.title3}>总和为大于等于14，如14，15···，27等判总和大赢</div>

                <div style={styles.title2}>6、总和大小单双玩法</div>
                <div style={styles.title3}>总和为小于等于13，如0,···，12，13等判总和小赢</div>

                <div style={styles.title2}>7、混合组合</div>
                <div style={styles.title3}>大单：总和为15-27的单数</div>
                <div style={styles.title3}>大双：总和为14-26的双数</div>
                <div style={styles.title3}>小单：总和为1-13的单数</div>
                <div style={styles.title3}>小双：总和为0-12的双数</div>

                <div style={styles.title2}>8、极值</div>
                <div style={styles.title3}>总和为极小 : 0-5 极大:22-27</div>

                <div style={styles.title2}>9、波色</div>
                <div style={styles.title3}>绿波号码：1 , 4 , 7 , 10 , 16 , 19 , 22 , 25</div>
                <div style={styles.title3}>蓝波号码：2 , 5 , 8 , 11 , 17 , 20 , 23 , 26</div>
                <div style={styles.title3}>红波号码：3 , 6 , 9 , 12 , 15 , 18 , 21 , 24</div>
                <div style={styles.title3}>出现0,27,13,14算输</div>

                <div style={styles.title2}>10、豹子</div>
                <div style={styles.title3}>当期3个开奖号码一致则中奖, 如当期开奖结果为 3+3+3=9 则中奖豹子。</div>
            </div>
        );
    };
    /**
     * PKS
     * @returns {*}
     * @private
     */
    _renderPKS = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>北京PK10游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"北京PK拾"完全同步，北京时间（GMT+8）每天白天从上午09:10开到晚上23:50，每20分鐘开一次奖,每天开奖44期。
                </div>
                <div style={styles.title2}>2、第一名 ~ 第十名</div>
                <div style={styles.title3}>车号指定，每一个车号为一投注组合，开奖结果"投注车号"对应所投名次视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title2}>3、两面</div>
                <div style={styles.title3}>指单、双；大、小。</div>
                <div style={styles.title3}>单、双：号码为双数叫双，如8、10；号码为单数叫单，如9、5。</div>
                <div style={styles.title3}>大、小：开出之号码大于或等于6为大，小于或等于5为小。</div>

                <div style={styles.title2}>4、1~5龙虎</div>
                <div style={styles.title3}>冠 军 龙/虎："第一名"车号大于"第十名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>亚 军 龙/虎："第二名"车号大于"第九名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>第三名 龙/虎："第三名"车号大于"第八名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>第四名 龙/虎："第四名"车号大于"第七名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>第五名 龙/虎："第五名"车号大于"第六名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title2}>5、冠亚军和</div>
                <div style={{...styles.title3, ...styles.color1}}>冠军车号＋亚军车号＝冠亚和值：</div>
                <div style={styles.title3}>可能出现的结果为3～19， 投中对应"冠亚和值"数字的视为中奖，其余视为不中奖。</div>
                <div style={{...styles.title3, ...styles.color1}}>冠亚和大小：</div>
                <div style={styles.title3}>大于11时投注"大"的注单视为中奖，小于或等于11时投注"小"的注单视为中奖，其余视为不中</div>
                <div style={{...styles.title3, ...styles.color1}}>冠亚和单双：</div>
                <div style={styles.title3}>为单视为投注"单"的注单视为中奖，为双视为投注"双"的注单视为中奖，其余视为不中奖。</div>
            </div>
        )
    };
    /**
     * 三分PKS
     * @returns {*}
     * @private
     */
    _renderSFPKS = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>{this.title}游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏每天00:00开到晚上23:59，每{this.min}分鐘开一次奖,每天开奖{this.count}期。
                </div>
                <div style={styles.title2}>2、第一名 ~ 第十名</div>
                <div style={styles.title3}>车号指定，每一个车号为一投注组合，开奖结果"投注车号"对应所投名次视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title2}>3、两面</div>
                <div style={styles.title3}>指单、双；大、小。</div>
                <div style={styles.title3}>单、双：号码为双数叫双，如8、10；号码为单数叫单，如9、5。</div>
                <div style={styles.title3}>大、小：开出之号码大于或等于6为大，小于或等于5为小。</div>

                <div style={styles.title2}>4、1~5龙虎</div>
                <div style={styles.title3}>冠 军 龙/虎："第一名"车号大于"第十名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>亚 军 龙/虎："第二名"车号大于"第九名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>第三名 龙/虎："第三名"车号大于"第八名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>第四名 龙/虎："第四名"车号大于"第七名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title3}>第五名 龙/虎："第五名"车号大于"第六名"车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</div>
                <div style={styles.title2}>5、冠亚军和</div>
                <div style={{...styles.title3, ...styles.color1}}>冠军车号＋亚军车号＝冠亚和值：</div>
                <div style={styles.title3}>可能出现的结果为3～19， 投中对应"冠亚和值"数字的视为中奖，其余视为不中奖。</div>
                <div style={{...styles.title3, ...styles.color1}}>冠亚和大小：</div>
                <div style={styles.title3}>大于11时投注"大"的注单视为中奖，小于或等于11时投注"小"的注单视为中奖，其余视为不中</div>
                <div style={{...styles.title3, ...styles.color1}}>冠亚和单双：</div>
                <div style={styles.title3}>为单视为投注"单"的注单视为中奖，为双视为投注"双"的注单视为中奖，其余视为不中奖。</div>
            </div>
        )
    };

    /**
     * 幸运飞艇
     * @returns {*}
     * @private
     */
    _renderXYFT = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>幸运飞艇游戏规则</div>
                <div style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与“幸运飞艇”完全同步（官方网）），北京时间（GMT+8）每天白天从中午13:04
                    开到凌晨04:04，每5分钟开一次奖, 每天开奖180期。。
                </div>
                <div style={styles.title2}>2、1～10 两面</div>
                <div style={styles.title3}>指 单、双；大、小。</div>

                <div style={styles.title2}>3、单、双</div>
                <div style={styles.title3}>号码为双数叫双，如4、8；号码为单数叫单，如5、9</div>

                <div style={styles.title2}>4、大、小</div>
                <div style={styles.title3}>出之号码大于或等于6为大，小于或等于5为小</div>

                <div style={styles.title2}>5、 第一名～第十名</div>
                <div style={styles.title3}>车号指定：每一个车号为一投注组合，开奖结果“投注车号”对应所投名次视为中奖，其余情形视为不中奖</div>

                <div style={styles.title2}>6、1～5龙虎</div>
                <div style={styles.title3}>冠 军 龙/虎：“第一名”车号大于“第十名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖</div>

                <div style={styles.title3}>亚 军 龙/虎：“第二名”车号大于“第九名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖</div>

                <div style={styles.title3}>第三名 龙/虎：“第三名”车号大于“第八名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖</div>

                <div style={styles.title3}>第四名 龙/虎：“第四名”车号大于“第七名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖</div>

                <div style={styles.title3}>第五名 龙/虎：“第五名”车号大于“第六名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖</div>

                <div style={styles.title3}>冠军车号＋亚军车号＝冠亚和值（为3~19)</div>

                <div style={styles.title3}>冠亚和单双：“冠亚和值”为单视为投注“单”的注单视为中奖，为双视为投注“双”的注单视为中奖，其余视为不中奖。</div>

                <div style={styles.title3}>冠亚和大小：“冠亚和值”大于11时投注“大”的注单视为中奖，小于或等于11时投注“小”的注单视为中奖，其余视为不中奖。</div>

                <div style={styles.title3}>冠亚和指定：“冠亚和值”可能出现的结果为3～19， 投中对应“冠亚和值”数字的视为中奖，其余视为不中奖。</div>
            </div>
        )
    };


    /**
     * 澳门六合彩(旧)
     * @returns {*}
     * @private
     */
    _renderAMLHC=()=>{
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>澳门六合彩(旧)游戏规则</div>
                <div style={styles.title2}>1、每期澳门六合彩(旧)开奖球数共七粒。最后一位称之为特码，开奖时间通常为一天一期。</div>
                <div style={styles.title3}>以下所有投注皆含本金</div>

                <div style={styles.title2}>2、特别号码盘势规则说明</div>
                <div style={styles.title3}>澳门六合彩(旧)公司当期开出的最后一码为特别号或特码。</div>

                <div style={styles.title3}>特码 单 码：假设投注号码为开奖号码之特别号，视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title3}>特码 大 小：开出之特码大于或等于25为特码大， 小于或等于24为特码小，开出49为和。</div>

                <div style={styles.title3}>特码 单 双：特码为双数叫特双，如8、16；特码为单数叫特单，如21、35，开出49为和。</div>

                <div style={styles.title3}>特码合数大小：以特码个位和十位数字之和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

                <div style={styles.title3}>特码合数单双：以特码个位和十位数字之和来判断单双，如01，12，32为和单，02，11，33为和双，开出49号为和。</div>

                <div style={styles.title3}>特码尾数大小：以特别号尾数若0尾~4尾为小、5尾~9尾为大；如01、32、44为特尾小；如05、18、19为特尾大，开出49号为和。</div>

                <div style={styles.title3}>特码 半
                    特：以特别号大小与特别号单双游戏为一个投注组合；当期特别号开出符合投注组合，即视为中奖；若当期特码开出49号，则视为和局；其余情形视为不中奖。
                </div>

                <div style={styles.title2}>3、两面</div>
                <div style={styles.title3}>指大、小；单、双。</div>

                <div style={styles.title2}>4、正码</div>
                <div style={styles.title3}>澳门六合彩(旧)公司当期开出之前6个号码叫正码。每一个号码为一投注组合，假如投注号码为开奖号码之 正码，视为中奖，其馀情形视为不中奖。</div>

                <div style={styles.title2}>5、正码1-6盘势规则说明</div>
                <div style={styles.title3}>澳门六合彩(旧)公司当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次为正码2、正码3…… 正码6(并不以号码大小排序)。</div>

                <div style={styles.title3}>大 小： 以指定出现正码的位置与号码大于或等于25为大，小于或等于24为小，开出49为和。</div>

                <div style={styles.title3}>单 双： 以指定出现正码的位置与号码为单数或双数下注，开出49为和。</div>

                <div style={styles.title3}>合数大小： 以指定出现正码的位置与号码个位和十位数字总和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

                <div style={styles.title3}>合数单双： 以指定出现正码的位置与号码个位和十位数字总和来判断单双，开出49号为和。</div>

                <div style={styles.title3}>色 波： 以指定出现正码的位置的球色下注，开奖之球色与下注之颜色相同时，视为中奖，其余情形视为不中奖。</div>

                <div
                    style={styles.title3}>尾数大小：以指定出现正码的位置与号码尾数大小下注，若0尾~4尾为小、5尾~9尾为大。如01、32、44为正尾小；如05、18、19为正尾大，开出49号为和。
                </div>

                <div style={styles.title2}>6、正码特</div>
                <div style={styles.title3}>正码特是指 正1特、正2特、正3特、正4特、正5特、正6特：其下注的正码特号与现场摇珠开出之正码其开奖顺序及开奖号码相同，视为中奖，
                    如现场摇珠第一个正码开奖为49号，下注第一个正码特为49则视为中奖，其它号码视为不中奖。
                </div>

                <div style={styles.title2}>7、总和单双</div>
                <div style={styles.title3}>所有七个开奖号码的分数总和是单数叫(总分单)，如分数总和是115、183；分数总和是双数叫(总
                    分双)，如分数总和是108、162。假如投注组合符合中奖结果，视为中奖，其馀情形视为不中奖 。
                </div>

                <div style={styles.title2}>8、总和大小</div>
                <div style={styles.title3}>所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。
                    如开奖号码为02、08、17、28、39、46、25，分数总和是165，则总分小。假如投注组合符合 中奖结果，视为中奖，其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>9、连码</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>四全中：
                                所投注的每四个号码为一组合，若四个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如06、07、08、09四个都是开奖号码之正码，视为中奖，如三个正码加上一个特别号码视为不中奖。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            2.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>三中二： 所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中二，视为中奖；
                                若3个都是开奖号码中的正码，即为三中二之中三，其馀情形视为不中奖，如06、07、08
                                为一组合，开奖号码中有06、07两个正码，没有08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视
                                为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            3.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>三全中： 所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其馀情形视为
                                不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号
                                码视为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            4.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>二全中： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其馀情形视为不
                                中奖（含一个正码加一个特别号码之情形）。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            5.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>二中特： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一
                                个是正码，一个是特别号码，叫二中特之中特；其馀情形视为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            6.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>特串： 所投注的每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其馀情形视为 不中奖（含二个号码都是正码之情形）
                                。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            7.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>依照二全中·二中特·特串 此3种玩法规则,来进行‘生肖对碰’或‘尾数对碰’</div>
                            <div style={styles.title4}>例一：</div>
                            <div style={styles.title4}>选择‘二全中’,之后进行‘生肖对碰’选择蛇(2，14，26，38)以及马 (1，13，25，37，49)</div>
                            <div style={styles.title4}>则会产生20组号码组合：</div>
                            <div style={styles.title4}>[2,1] [2,13] [2,25] [2,37] [2,49]</div>
                            <div style={styles.title4}>[14,1] [14,13] [14,25] [14,37] [14,49]</div>
                            <div style={styles.title4}>[26,1] [26,13] [26,25] [26,37] [26,49]</div>
                            <div style={styles.title4}>[38,1] [38,13] [38,25] [38,37] [38,49]</div>
                            <div style={styles.title4}>例二：</div>
                            <div style={styles.title4}>选择‘二中特’,之后进行‘尾数对碰’选择0(10，20，30，40)以及5(5，15，25，35，45)</div>
                            <div style={styles.title4}>则会产生20组号码组合：</div>
                            <div style={styles.title4}>[10,5] [10,15] [10,25] [10,35] [10,45]</div>
                            <div style={styles.title4}>[20,5] [20,15] [20,25] [20,35] [20,45]</div>
                            <div style={styles.title4}>[30,5] [30,15] [30,25] [30,35] [30,45]</div>
                            <div style={styles.title4}>[40,5] [40,15] [40,25] [40,35] [40,45]</div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            8.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>肖串尾数:选择一主肖，可扥0-9尾的球． 以三全中的肖串尾数为例：
                                选择【龙】当主肖(号码03,15,27,39)并且扥9尾数．因为9尾数中的39已在主肖中出现，将不重复组合．
                                故龙主肖扥9尾数共可组合出24种组合(二个尾数号码+一个主肖号码的组合)．
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>10、正码过关</div>
                <div
                    style={styles.title3}>游戏规则同正码1-6盘势，但须同时投注多项结果，串联成投注组合，只要单期所开出之开奖结果符合您所选定之串联结果，即视为中奖，若其中一项结果不中，则视为不中奖；若其中一项结果为和，其余结果都中奖的情形，也视为中奖；和的项目赔率以一计算
                </div>

                <div style={styles.title2}>11、生肖色波:分为生肖与色波两种玩法</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>生肖：</div>
                            <div style={styles.title4}>生肖顺序为 牛 >虎 >兔 >龙 >蛇 >马 >羊 >猴 >鸡 >狗 >猪 >鼠</div>
                            <div style={styles.title4}>如今年是兔年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                          <div style={styles.title4}>兔 01 , 13 , 25 , 37 , 49</div>
                          <div style={styles.title4}>龙 02 , 14 , 26 , 38</div>
                          <div style={styles.title4}>蛇 03 , 15 , 27 , 39</div>
                          <div style={styles.title4}>马 04 , 16 , 28 , 40</div>
                          <div style={styles.title4}>羊 05 , 17 , 29 , 41</div>
                          <div style={styles.title4}>猴 06 , 18 , 30 , 42</div>
                          <div style={styles.title4}>鸡 07 , 19 , 31 , 43</div>
                          <div style={styles.title4}>狗 08 , 20 , 32 , 44</div>
                          <div style={styles.title4}>猪 09 , 21 , 33 , 45</div>
                          <div style={styles.title4}>鼠 10 , 22 , 34 , 46</div>
                          <div style={styles.title4}>牛 11 , 23 , 35 , 47</div>
                          <div style={styles.title4}>虎 12 , 24 , 36 , 48</div>

                          <div style={styles.title4}>若当期特别号，落在下注生肖范围内，视为中奖 。</div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            2.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>色波： 以特别号的球色下注，开奖的球色与下注的颜色相同，视为中奖，球色号码分布如下:</div>
                            <div style={styles.title4}><span style={styles.color1}>01</span> 11 21 <span
                                style={styles.color2}>31</span> <span style={styles.color2}>41</span></div>
                            <div style={styles.title4}><span style={styles.color1}>02</span> <span
                                style={styles.color1}>12</span> 22 32 <span style={styles.color2}>42</span></div>
                            <div style={styles.title4}><span style={styles.color2}>03</span> <span
                                style={styles.color1}>13</span> <span style={styles.color1}>23</span> 33 43
                            </div>
                            <div style={styles.title4}><span style={styles.color2}>04</span> <span
                                style={styles.color2}>14</span> <span style={styles.color1}>24</span> <span
                                style={styles.color1}>34</span> 44
                            </div>
                            <div style={styles.title4}>05 <span style={styles.color2}>15</span> <span
                                style={styles.color2}>25</span> <span style={styles.color1}>35</span> <span
                                style={styles.color1}>45</span></div>
                            <div style={styles.title4}>06 16 <span style={styles.color2}>26</span> <span
                                style={styles.color2}>36</span> <span style={styles.color1}>46</span></div>
                            <div style={styles.title4}><span style={styles.color1}>07</span> 17 27 <span
                                style={styles.color2}>37</span> <span style={styles.color2}>47</span></div>
                            <div style={styles.title4}><span style={styles.color1}>08</span> <span
                                style={styles.color1}>18</span> 28 38 <span style={styles.color2}>48</span></div>
                            <div style={styles.title4}><span style={styles.color2}>09</span> <span
                                style={styles.color1}>19</span> <span style={styles.color1}>29</span> 39 49
                            </div>
                            <div style={styles.title4}><span style={styles.color2}>10</span> <span
                                style={styles.color2}>20</span> <span style={styles.color1}>30</span> <span
                                style={styles.color1}>40</span></div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>12、一肖</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>一肖： 将生肖依序排列，例如今年是兔年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                          <div style={styles.title4}>兔 01 , 13 , 25 , 37 , 49</div>
                          <div style={styles.title4}>龙 12 , 24 , 36 , 48</div>
                          <div style={styles.title4}>蛇 11 , 23 , 35 , 47</div>
                          <div style={styles.title4}>马 10 , 22 , 34 , 46</div>
                          <div style={styles.title4}>羊 09 , 21 , 33 , 45</div>
                          <div style={styles.title4}>猴 08 , 20 , 32 , 44</div>
                          <div style={styles.title4}>鸡 07 , 19 , 31 , 43</div>
                          <div style={styles.title4}>狗 06 , 18 , 30 , 42</div>
                          <div style={styles.title4}>猪 05 , 17 , 29 , 41</div>
                          <div style={styles.title4}>鼠 04 , 16 , 28 , 40</div>
                          <div style={styles.title4}>牛 03 , 15 , 27 , 39</div>
                          <div style={styles.title4}>虎 02 , 14 , 26 , 38</div>
                            <div style={styles.title4}>只要当期号码(所有正码与最后开出的特码)，落在下注生肖范围内，则视为中奖。<span
                                style={styles.color1}>(请注意：49亦算输赢，不为和)。</span></div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>13、合肖</div>
                <div
                    style={styles.title3}>选1~11生肖(排列如同生肖)为一组合，并选择开奖号码的特码是否在此组合内，若选择是"中"(可选择2~11个生肖)且开奖号码的特码亦在此组合内，即视为中奖；若选择否"不中"(可选择1~10个生肖),系统会将选择不中的组合改为中的组合；若当期特码开出49号，则所有组合皆视为和局
                </div>

                <div style={styles.title2}>14、超快速模式</div>
                <div style={styles.title3}>在此模式下所投注的注单，若是赔率变动时，系统将不会有赔率已变动的讯息提示，而会以投注当时的最新赔率接受投注。</div>
                <div
                    style={styles.title3}>例：使用超快速模式投注特别号，输入金额时，赔率为41.5倍，金额输入的过程中赔率已更新为41倍，金额输入完毕，确认投注时，注单上所显示的赔率将会是以41倍接受投注，当下投注画面上亦会更新为最新赔率。
                </div>

                <div style={styles.title2}>15、半波</div>
                <div style={styles.title3}>以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>16、半半波</div>
                <div style={styles.title3}>以特码色波和特码单双及特码大小等三种游戏为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>17、正肖</div>
                <div style={styles.title3}>依据开出的所有正码为主。若当期6个正码，只要有1个落在下注生肖范围内，视为中奖。如超过1个正码落在下注生肖范围内 ，派彩将倍增！</div>
                <div style={styles.title3}>如：下注＄100.-正肖马赔率1.88</div>
                <div style={styles.title3}>6个正码开出01，派彩为＄88.-</div>
                <div style={styles.title3}>6个正码开出01，13，派彩为＄176.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，派彩为＄264.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，37，派彩为＄352.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，37，49，派彩为＄440.- (请注意：49亦算输赢，不为和)</div>

                <div style={styles.title2}>18、七色波</div>
                <div style={styles.title3}>以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。</div>
                <div style={styles.title3}>6个正码开出3蓝3绿，而特别码是1.5红</div>
                <div style={styles.title3}>6个正码开出3蓝3红，而特别码是1.5绿</div>
                <div style={styles.title3}>6个正码开出3绿3红，而特别码是1.5蓝</div>
                <div style={styles.title3}>如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局。</div>

                <div style={styles.title2}>19、特码头数 ：指特别号所属头数的号码</div>
                <div style={styles.title3}>"0"头 ：01，02，03，04，05，06，07，08，09</div>
                <div style={styles.title3}>"1"头 ：10，11，12，13，14，15，16，17，18，19</div>
                <div style={styles.title3}>"2"头 ：20，21，22，23，24，25，26，27，28，29</div>
                <div style={styles.title3}>"3"头 ：30，31，32，33，34，35，36，37，38，39</div>
                <div style={styles.title3}>"4"头 ：40，41，42，43，44，45，46，47，48，49</div>
                <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "2"头为中奖，其他头数都不中奖</div>

                <div style={styles.title2}>20、特码尾数 ：指特别号所属尾数的号码</div>
                <div style={styles.title3}>"1"尾 ：01，11，21，31，41</div>
                <div style={styles.title3}>"2"尾 ：02，12，22，32，42</div>
                <div style={styles.title3}>"3"尾 ：03，13，23，33，43</div>
                <div style={styles.title3}>"4"尾 ：04，14，24，34，44</div>
                <div style={styles.title3}>"5"尾 ：05，15，25，35，45</div>
                <div style={styles.title3}>"6"尾 ：06，16，26，36，46</div>
                <div style={styles.title3}>"7"尾 ：07，17，27，37，47</div>
                <div style={styles.title3}>"8"尾 ：08，18，28，38，48</div>
                <div style={styles.title3}>"9"尾 ：09，19，29，39，49</div>
                <div style={styles.title3}>"0"尾 ：10，20，30，40</div>
                <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "1"尾为中奖，其他尾数都不中奖</div>

                <div style={styles.title2}>21、正特尾数</div>
                <div style={styles.title3}>只要当期号码(所有正码及开出来的特码)，含所属尾数的一个或多个号码，但派彩只派一次，即不论同尾数号码出现一个或多个号码都只派彩一次。</div>
                <div style={styles.title3}>"1"尾：01.11.21.31.41 "2"尾：02.12.22.32.42 "3"尾：03.13.23.33.43</div>
                <div style={styles.title3}>"4"尾：04.14.24.34.44 "5"尾：05.15.25.35.45 "6"尾：06.16.26.36.46</div>
                <div style={styles.title3}>"7"尾：07.17.27.37.47 "8"尾：08.18.28.38.48 "9"尾：09.19.29.39.49</div>
                <div style={styles.title3}>"0"尾：10.20.30.40</div>
                <div style={styles.title3}>例如 ：开奖结果正码是11、31、42、44、35、32特别号码是 21 则"1"尾"2"尾"4"尾"5"尾都为中奖，其他尾数都不中奖。</div>

                <div style={styles.title2}>22、总肖</div>
                <div
                    style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数，与所投注之预计开出之生肖总数和(不用指定特定生肖)，则视为中奖，其余情形视为不中奖。例如：如果'当期号码为19、24、12、34、40、39
                </div>
                <div style={styles.title3}>特别号：49，总计六个生肖，若选总肖【6】则为中奖 (请注意：49号亦算输赢，不为和)</div>

                <div style={styles.title2}>23、总肖单双</div>
                <div
                    style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数若为单数则为单；若为双数则为双。例如当期号码为19、24、12、34、40、39.特码：49，则总计六个生肖，若下双则中奖。
                </div>

                <div style={styles.title2}>24、连肖</div>
                <div
                    style={styles.title3}>挑选2~6生肖(排列如同生肖)为一个组合，当期号码(所有正码与最后开出的特码)坐落于投注时所勾选之生肖组合所属号码内，所勾选之生肖皆至少有中一个号码，则视为中奖，其余情视为不中奖(请注意49亦算输赢，不为和)例如：如果当期号码为19、24、12、34、40、39.特码：49，所勾选三个生肖(称为三肖碰)，若所有生肖的所属号码内至少一个出现于当期号码，则视为中奖。
                </div>

                <div style={styles.title2}>25、连尾</div>
                <div style={styles.title3}>挑选2〜6个尾数为一组合，当期号码（所有正码与最后出的特码）坐落于投注时所勾选之尾数组合所属号码内，则视为中奖，其余情形视为不中奖
                    (请注意49亦算输赢，不为和)
                </div>
                <div style={styles.title3}>举例1：下注2尾碰，勾选0,1，当期七个号码若0尾及1尾皆有开出，视为中奖</div>
                <div
                    style={styles.title3}>举例2：下注2尾碰，勾选0,1,2三种尾数，会出现三种组合（组合一：0,1）（组合二：0,2）（组合三：1,2），当期七个号码若开出其中一种组合所选的两个尾数，视为中奖。
                </div>

                <div style={styles.title2}>26、自选不中奖</div>
                <div
                    style={styles.title3}>挑选5~12个号码为一个组合，当期号码(所有正码与最后开出的特码)皆没有坐落于投注时所挑选之号码组合内，则视为中奖，若是有任何一个当期号码开在所挑选的号码组合情形视为不中奖。
                </div>
                <div style={styles.title3}>例如当期号码为19,24,17,34,40,39,特别号49，所挑选5个号码(称为五不中)，若所挑选的号码内皆沒有坐落于当期号码，则为中奖</div>
            </div>
        )
    }

  /**
   * 澳门六合彩(新)
   * @returns {*}
   * @private
   */
  _renderAMLHC2=()=>{
    const styles = {
      container: {
        padding: 10
      },
      title1: {
        color: "#000",
        fontSize: 18,
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold"
      },
      title2: {
        color: "#000",
        fontSize: 14,
        marginTop: 10,
        fontWeight: "bold"
      },
      title3: {
        color: "rgba(0,0,0,0.7)",
        lineHeight: "24px",
        fontSize: 14,
        marginTop: 5,
        paddingLeft: 25,
        wordBreak: "break-all"
      },
      title4: {
        color: "rgba(0,0,0,0.7)",
        lineHeight: "24px",
        fontSize: 14,
        marginTop: 5,
        wordBreak: "break-all"
      },
      color1: {
        color: "red"
      },
      color2: {
        color: "blue"
      },
      tbContainer: {
        backgroundColor: "rgba(0,0,0,0.05)",
        borderRight: "1px solid #ddd",
        borderTop: "1px solid #ddd",
        marginLeft: 25,
        marginTop: 10
      },
      tbRow: {
        borderBottom: "1px solid #ddd",
        display: "flex",
        flexDirection: "row"
      },
      tbColumn: {
        borderLeft: "1px solid #ddd",
        padding: 10
      },
    };
    return (
        <div style={styles.container}>
          <div style={styles.title1}>澳门六合彩(新)游戏规则</div>
          <div style={styles.title2}>1、每期澳门六合彩(新)开奖球数共七粒。最后一位称之为特码，开奖时间通常为一天一期。</div>
          <div style={styles.title3}>以下所有投注皆含本金</div>

          <div style={styles.title2}>2、特别号码盘势规则说明</div>
          <div style={styles.title3}>澳门六合彩(新)公司当期开出的最后一码为特别号或特码。</div>

          <div style={styles.title3}>特码 单 码：假设投注号码为开奖号码之特别号，视为中奖，其余情形视为不中奖。</div>

          <div style={styles.title3}>特码 大 小：开出之特码大于或等于25为特码大， 小于或等于24为特码小，开出49为和。</div>

          <div style={styles.title3}>特码 单 双：特码为双数叫特双，如8、16；特码为单数叫特单，如21、35，开出49为和。</div>

          <div style={styles.title3}>特码合数大小：以特码个位和十位数字之和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

          <div style={styles.title3}>特码合数单双：以特码个位和十位数字之和来判断单双，如01，12，32为和单，02，11，33为和双，开出49号为和。</div>

          <div style={styles.title3}>特码尾数大小：以特别号尾数若0尾~4尾为小、5尾~9尾为大；如01、32、44为特尾小；如05、18、19为特尾大，开出49号为和。</div>

          <div style={styles.title3}>特码 半
            特：以特别号大小与特别号单双游戏为一个投注组合；当期特别号开出符合投注组合，即视为中奖；若当期特码开出49号，则视为和局；其余情形视为不中奖。
          </div>

          <div style={styles.title2}>3、两面</div>
          <div style={styles.title3}>指大、小；单、双。</div>

          <div style={styles.title2}>4、正码</div>
          <div style={styles.title3}>澳门六合彩(旧)公司当期开出之前6个号码叫正码。每一个号码为一投注组合，假如投注号码为开奖号码之 正码，视为中奖，其馀情形视为不中奖。</div>

          <div style={styles.title2}>5、正码1-6盘势规则说明</div>
          <div style={styles.title3}>澳门六合彩(新)公司当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次为正码2、正码3…… 正码6(并不以号码大小排序)。</div>

          <div style={styles.title3}>大 小： 以指定出现正码的位置与号码大于或等于25为大，小于或等于24为小，开出49为和。</div>

          <div style={styles.title3}>单 双： 以指定出现正码的位置与号码为单数或双数下注，开出49为和。</div>

          <div style={styles.title3}>合数大小： 以指定出现正码的位置与号码个位和十位数字总和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

          <div style={styles.title3}>合数单双： 以指定出现正码的位置与号码个位和十位数字总和来判断单双，开出49号为和。</div>

          <div style={styles.title3}>色 波： 以指定出现正码的位置的球色下注，开奖之球色与下注之颜色相同时，视为中奖，其余情形视为不中奖。</div>

          <div
              style={styles.title3}>尾数大小：以指定出现正码的位置与号码尾数大小下注，若0尾~4尾为小、5尾~9尾为大。如01、32、44为正尾小；如05、18、19为正尾大，开出49号为和。
          </div>

          <div style={styles.title2}>6、正码特</div>
          <div style={styles.title3}>正码特是指 正1特、正2特、正3特、正4特、正5特、正6特：其下注的正码特号与现场摇珠开出之正码其开奖顺序及开奖号码相同，视为中奖，
            如现场摇珠第一个正码开奖为49号，下注第一个正码特为49则视为中奖，其它号码视为不中奖。
          </div>

          <div style={styles.title2}>7、总和单双</div>
          <div style={styles.title3}>所有七个开奖号码的分数总和是单数叫(总分单)，如分数总和是115、183；分数总和是双数叫(总
            分双)，如分数总和是108、162。假如投注组合符合中奖结果，视为中奖，其馀情形视为不中奖 。
          </div>

          <div style={styles.title2}>8、总和大小</div>
          <div style={styles.title3}>所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。
            如开奖号码为02、08、17、28、39、46、25，分数总和是165，则总分小。假如投注组合符合 中奖结果，视为中奖，其馀情形视为不中奖。
          </div>

          <div style={styles.title2}>9、连码</div>
          <div style={styles.tbContainer}>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                1.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>四全中：
                  所投注的每四个号码为一组合，若四个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如06、07、08、09四个都是开奖号码之正码，视为中奖，如三个正码加上一个特别号码视为不中奖。
                </div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                2.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>三中二： 所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中二，视为中奖；
                  若3个都是开奖号码中的正码，即为三中二之中三，其馀情形视为不中奖，如06、07、08
                  为一组合，开奖号码中有06、07两个正码，没有08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视
                  为不中奖 。
                </div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                3.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>三全中： 所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其馀情形视为
                  不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号
                  码视为不中奖 。
                </div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                4.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>二全中： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其馀情形视为不
                  中奖（含一个正码加一个特别号码之情形）。
                </div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                5.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>二中特： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一
                  个是正码，一个是特别号码，叫二中特之中特；其馀情形视为不中奖 。
                </div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                6.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>特串： 所投注的每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其馀情形视为 不中奖（含二个号码都是正码之情形）
                  。
                </div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                7.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>依照二全中·二中特·特串 此3种玩法规则,来进行‘生肖对碰’或‘尾数对碰’</div>
                <div style={styles.title4}>例一：</div>
                <div style={styles.title4}>选择‘二全中’,之后进行‘生肖对碰’选择蛇(2，14，26，38)以及马 (1，13，25，37，49)</div>
                <div style={styles.title4}>则会产生20组号码组合：</div>
                <div style={styles.title4}>[2,1] [2,13] [2,25] [2,37] [2,49]</div>
                <div style={styles.title4}>[14,1] [14,13] [14,25] [14,37] [14,49]</div>
                <div style={styles.title4}>[26,1] [26,13] [26,25] [26,37] [26,49]</div>
                <div style={styles.title4}>[38,1] [38,13] [38,25] [38,37] [38,49]</div>
                <div style={styles.title4}>例二：</div>
                <div style={styles.title4}>选择‘二中特’,之后进行‘尾数对碰’选择0(10，20，30，40)以及5(5，15，25，35，45)</div>
                <div style={styles.title4}>则会产生20组号码组合：</div>
                <div style={styles.title4}>[10,5] [10,15] [10,25] [10,35] [10,45]</div>
                <div style={styles.title4}>[20,5] [20,15] [20,25] [20,35] [20,45]</div>
                <div style={styles.title4}>[30,5] [30,15] [30,25] [30,35] [30,45]</div>
                <div style={styles.title4}>[40,5] [40,15] [40,25] [40,35] [40,45]</div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                8.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>肖串尾数:选择一主肖，可扥0-9尾的球． 以三全中的肖串尾数为例：
                  选择【龙】当主肖(号码03,15,27,39)并且扥9尾数．因为9尾数中的39已在主肖中出现，将不重复组合．
                  故龙主肖扥9尾数共可组合出24种组合(二个尾数号码+一个主肖号码的组合)．
                </div>
              </div>
            </div>
          </div>
          <div style={styles.title2}>10、正码过关</div>
          <div
              style={styles.title3}>游戏规则同正码1-6盘势，但须同时投注多项结果，串联成投注组合，只要单期所开出之开奖结果符合您所选定之串联结果，即视为中奖，若其中一项结果不中，则视为不中奖；若其中一项结果为和，其余结果都中奖的情形，也视为中奖；和的项目赔率以一计算
          </div>

          <div style={styles.title2}>11、生肖色波:分为生肖与色波两种玩法</div>
          <div style={styles.tbContainer}>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                1.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>生肖：</div>
                <div style={styles.title4}>生肖顺序为 牛 >虎 >兔 >龙 >蛇 >马 >羊 >猴 >鸡 >狗 >猪 >鼠</div>
                <div style={styles.title4}>如今年是兔年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                <div style={styles.title4}>兔 01 , 13 , 25 , 37 , 49</div>
                <div style={styles.title4}>龙 02 , 14 , 26 , 38</div>
                <div style={styles.title4}>蛇 03 , 15 , 27 , 39</div>
                <div style={styles.title4}>马 04 , 16 , 28 , 40</div>
                <div style={styles.title4}>羊 05 , 17 , 29 , 41</div>
                <div style={styles.title4}>猴 06 , 18 , 30 , 42</div>
                <div style={styles.title4}>鸡 07 , 19 , 31 , 43</div>
                <div style={styles.title4}>狗 08 , 20 , 32 , 44</div>
                <div style={styles.title4}>猪 09 , 21 , 33 , 45</div>
                <div style={styles.title4}>鼠 10 , 22 , 34 , 46</div>
                <div style={styles.title4}>牛 11 , 23 , 35 , 47</div>
                <div style={styles.title4}>虎 12 , 24 , 36 , 48</div>

                <div style={styles.title4}>若当期特别号，落在下注生肖范围内，视为中奖 。</div>
              </div>
            </div>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                2.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>色波： 以特别号的球色下注，开奖的球色与下注的颜色相同，视为中奖，球色号码分布如下:</div>
                <div style={styles.title4}><span style={styles.color1}>01</span> 11 21 <span
                    style={styles.color2}>31</span> <span style={styles.color2}>41</span></div>
                <div style={styles.title4}><span style={styles.color1}>02</span> <span
                    style={styles.color1}>12</span> 22 32 <span style={styles.color2}>42</span></div>
                <div style={styles.title4}><span style={styles.color2}>03</span> <span
                    style={styles.color1}>13</span> <span style={styles.color1}>23</span> 33 43
                </div>
                <div style={styles.title4}><span style={styles.color2}>04</span> <span
                    style={styles.color2}>14</span> <span style={styles.color1}>24</span> <span
                    style={styles.color1}>34</span> 44
                </div>
                <div style={styles.title4}>05 <span style={styles.color2}>15</span> <span
                    style={styles.color2}>25</span> <span style={styles.color1}>35</span> <span
                    style={styles.color1}>45</span></div>
                <div style={styles.title4}>06 16 <span style={styles.color2}>26</span> <span
                    style={styles.color2}>36</span> <span style={styles.color1}>46</span></div>
                <div style={styles.title4}><span style={styles.color1}>07</span> 17 27 <span
                    style={styles.color2}>37</span> <span style={styles.color2}>47</span></div>
                <div style={styles.title4}><span style={styles.color1}>08</span> <span
                    style={styles.color1}>18</span> 28 38 <span style={styles.color2}>48</span></div>
                <div style={styles.title4}><span style={styles.color2}>09</span> <span
                    style={styles.color1}>19</span> <span style={styles.color1}>29</span> 39 49
                </div>
                <div style={styles.title4}><span style={styles.color2}>10</span> <span
                    style={styles.color2}>20</span> <span style={styles.color1}>30</span> <span
                    style={styles.color1}>40</span></div>
              </div>
            </div>
          </div>
          <div style={styles.title2}>12、一肖</div>
          <div style={styles.tbContainer}>
            <div style={styles.tbRow}>
              <div style={{...styles.tbColumn, ...{width: 20}}}>
                1.
              </div>
              <div style={{...styles.tbColumn, ...{flex: 1}}}>
                <div style={styles.title4}>一肖： 将生肖依序排列，例如今年是兔年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                <div style={styles.title4}>兔 01 , 13 , 25 , 37 , 49</div>
                <div style={styles.title4}>龙 12 , 24 , 36 , 48</div>
                <div style={styles.title4}>蛇 11 , 23 , 35 , 47</div>
                <div style={styles.title4}>马 10 , 22 , 34 , 46</div>
                <div style={styles.title4}>羊 09 , 21 , 33 , 45</div>
                <div style={styles.title4}>猴 08 , 20 , 32 , 44</div>
                <div style={styles.title4}>鸡 07 , 19 , 31 , 43</div>
                <div style={styles.title4}>狗 06 , 18 , 30 , 42</div>
                <div style={styles.title4}>猪 05 , 17 , 29 , 41</div>
                <div style={styles.title4}>鼠 04 , 16 , 28 , 40</div>
                <div style={styles.title4}>牛 03 , 15 , 27 , 39</div>
                <div style={styles.title4}>虎 02 , 14 , 26 , 38</div>
                <div style={styles.title4}>只要当期号码(所有正码与最后开出的特码)，落在下注生肖范围内，则视为中奖。<span
                    style={styles.color1}>(请注意：49亦算输赢，不为和)。</span></div>
              </div>
            </div>
          </div>
          <div style={styles.title2}>13、合肖</div>
          <div
              style={styles.title3}>选1~11生肖(排列如同生肖)为一组合，并选择开奖号码的特码是否在此组合内，若选择是"中"(可选择2~11个生肖)且开奖号码的特码亦在此组合内，即视为中奖；若选择否"不中"(可选择1~10个生肖),系统会将选择不中的组合改为中的组合；若当期特码开出49号，则所有组合皆视为和局
          </div>

          <div style={styles.title2}>14、超快速模式</div>
          <div style={styles.title3}>在此模式下所投注的注单，若是赔率变动时，系统将不会有赔率已变动的讯息提示，而会以投注当时的最新赔率接受投注。</div>
          <div
              style={styles.title3}>例：使用超快速模式投注特别号，输入金额时，赔率为41.5倍，金额输入的过程中赔率已更新为41倍，金额输入完毕，确认投注时，注单上所显示的赔率将会是以41倍接受投注，当下投注画面上亦会更新为最新赔率。
          </div>

          <div style={styles.title2}>15、半波</div>
          <div style={styles.title3}>以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
          </div>

          <div style={styles.title2}>16、半半波</div>
          <div style={styles.title3}>以特码色波和特码单双及特码大小等三种游戏为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
          </div>

          <div style={styles.title2}>17、正肖</div>
          <div style={styles.title3}>依据开出的所有正码为主。若当期6个正码，只要有1个落在下注生肖范围内，视为中奖。如超过1个正码落在下注生肖范围内 ，派彩将倍增！</div>
          <div style={styles.title3}>如：下注＄100.-正肖马赔率1.88</div>
          <div style={styles.title3}>6个正码开出01，派彩为＄88.-</div>
          <div style={styles.title3}>6个正码开出01，13，派彩为＄176.-</div>
          <div style={styles.title3}>6个正码开出01，13，25，派彩为＄264.-</div>
          <div style={styles.title3}>6个正码开出01，13，25，37，派彩为＄352.-</div>
          <div style={styles.title3}>6个正码开出01，13，25，37，49，派彩为＄440.- (请注意：49亦算输赢，不为和)</div>

          <div style={styles.title2}>18、七色波</div>
          <div style={styles.title3}>以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。</div>
          <div style={styles.title3}>6个正码开出3蓝3绿，而特别码是1.5红</div>
          <div style={styles.title3}>6个正码开出3蓝3红，而特别码是1.5绿</div>
          <div style={styles.title3}>6个正码开出3绿3红，而特别码是1.5蓝</div>
          <div style={styles.title3}>如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局。</div>

          <div style={styles.title2}>19、特码头数 ：指特别号所属头数的号码</div>
          <div style={styles.title3}>"0"头 ：01，02，03，04，05，06，07，08，09</div>
          <div style={styles.title3}>"1"头 ：10，11，12，13，14，15，16，17，18，19</div>
          <div style={styles.title3}>"2"头 ：20，21，22，23，24，25，26，27，28，29</div>
          <div style={styles.title3}>"3"头 ：30，31，32，33，34，35，36，37，38，39</div>
          <div style={styles.title3}>"4"头 ：40，41，42，43，44，45，46，47，48，49</div>
          <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "2"头为中奖，其他头数都不中奖</div>

          <div style={styles.title2}>20、特码尾数 ：指特别号所属尾数的号码</div>
          <div style={styles.title3}>"1"尾 ：01，11，21，31，41</div>
          <div style={styles.title3}>"2"尾 ：02，12，22，32，42</div>
          <div style={styles.title3}>"3"尾 ：03，13，23，33，43</div>
          <div style={styles.title3}>"4"尾 ：04，14，24，34，44</div>
          <div style={styles.title3}>"5"尾 ：05，15，25，35，45</div>
          <div style={styles.title3}>"6"尾 ：06，16，26，36，46</div>
          <div style={styles.title3}>"7"尾 ：07，17，27，37，47</div>
          <div style={styles.title3}>"8"尾 ：08，18，28，38，48</div>
          <div style={styles.title3}>"9"尾 ：09，19，29，39，49</div>
          <div style={styles.title3}>"0"尾 ：10，20，30，40</div>
          <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "1"尾为中奖，其他尾数都不中奖</div>

          <div style={styles.title2}>21、正特尾数</div>
          <div style={styles.title3}>只要当期号码(所有正码及开出来的特码)，含所属尾数的一个或多个号码，但派彩只派一次，即不论同尾数号码出现一个或多个号码都只派彩一次。</div>
          <div style={styles.title3}>"1"尾：01.11.21.31.41 "2"尾：02.12.22.32.42 "3"尾：03.13.23.33.43</div>
          <div style={styles.title3}>"4"尾：04.14.24.34.44 "5"尾：05.15.25.35.45 "6"尾：06.16.26.36.46</div>
          <div style={styles.title3}>"7"尾：07.17.27.37.47 "8"尾：08.18.28.38.48 "9"尾：09.19.29.39.49</div>
          <div style={styles.title3}>"0"尾：10.20.30.40</div>
          <div style={styles.title3}>例如 ：开奖结果正码是11、31、42、44、35、32特别号码是 21 则"1"尾"2"尾"4"尾"5"尾都为中奖，其他尾数都不中奖。</div>

          <div style={styles.title2}>22、总肖</div>
          <div
              style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数，与所投注之预计开出之生肖总数和(不用指定特定生肖)，则视为中奖，其余情形视为不中奖。例如：如果'当期号码为19、24、12、34、40、39
          </div>
          <div style={styles.title3}>特别号：49，总计六个生肖，若选总肖【6】则为中奖 (请注意：49号亦算输赢，不为和)</div>

          <div style={styles.title2}>23、总肖单双</div>
          <div
              style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数若为单数则为单；若为双数则为双。例如当期号码为19、24、12、34、40、39.特码：49，则总计六个生肖，若下双则中奖。
          </div>

          <div style={styles.title2}>24、连肖</div>
          <div
              style={styles.title3}>挑选2~6生肖(排列如同生肖)为一个组合，当期号码(所有正码与最后开出的特码)坐落于投注时所勾选之生肖组合所属号码内，所勾选之生肖皆至少有中一个号码，则视为中奖，其余情视为不中奖(请注意49亦算输赢，不为和)例如：如果当期号码为19、24、12、34、40、39.特码：49，所勾选三个生肖(称为三肖碰)，若所有生肖的所属号码内至少一个出现于当期号码，则视为中奖。
          </div>

          <div style={styles.title2}>25、连尾</div>
          <div style={styles.title3}>挑选2〜6个尾数为一组合，当期号码（所有正码与最后出的特码）坐落于投注时所勾选之尾数组合所属号码内，则视为中奖，其余情形视为不中奖
            (请注意49亦算输赢，不为和)
          </div>
          <div style={styles.title3}>举例1：下注2尾碰，勾选0,1，当期七个号码若0尾及1尾皆有开出，视为中奖</div>
          <div
              style={styles.title3}>举例2：下注2尾碰，勾选0,1,2三种尾数，会出现三种组合（组合一：0,1）（组合二：0,2）（组合三：1,2），当期七个号码若开出其中一种组合所选的两个尾数，视为中奖。
          </div>

          <div style={styles.title2}>26、自选不中奖</div>
          <div
              style={styles.title3}>挑选5~12个号码为一个组合，当期号码(所有正码与最后开出的特码)皆没有坐落于投注时所挑选之号码组合内，则视为中奖，若是有任何一个当期号码开在所挑选的号码组合情形视为不中奖。
          </div>
          <div style={styles.title3}>例如当期号码为19,24,17,34,40,39,特别号49，所挑选5个号码(称为五不中)，若所挑选的号码内皆沒有坐落于当期号码，则为中奖</div>
        </div>
    )
  }

    /**
     * 六合彩
     * @returns {*}
     * @private
     */
    _renderLHC = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>香港六合彩游戏规则</div>
                <div style={styles.title2}>1、每期香港六合彩开奖球数共七粒。最后一位称之为特码，开奖时间通常为开奖日的21:35分。</div>
                <div style={styles.title3}>以下所有投注皆含本金</div>

                <div style={styles.title2}>2、特别号码盘势规则说明</div>
                <div style={styles.title3}>香港六合彩公司当期开出的最后一码为特别号或特码。</div>

                <div style={styles.title3}>特码 单 码：假设投注号码为开奖号码之特别号，视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title3}>特码 大 小：开出之特码大于或等于25为特码大， 小于或等于24为特码小，开出49为和。</div>

                <div style={styles.title3}>特码 单 双：特码为双数叫特双，如8、16；特码为单数叫特单，如21、35，开出49为和。</div>

                <div style={styles.title3}>特码合数大小：以特码个位和十位数字之和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

                <div style={styles.title3}>特码合数单双：以特码个位和十位数字之和来判断单双，如01，12，32为和单，02，11，33为和双，开出49号为和。</div>

                <div style={styles.title3}>特码尾数大小：以特别号尾数若0尾~4尾为小、5尾~9尾为大；如01、32、44为特尾小；如05、18、19为特尾大，开出49号为和。</div>

                <div style={styles.title3}>特码 半
                    特：以特别号大小与特别号单双游戏为一个投注组合；当期特别号开出符合投注组合，即视为中奖；若当期特码开出49号，则视为和局；其余情形视为不中奖。
                </div>

                <div style={styles.title2}>3、两面</div>
                <div style={styles.title3}>指大、小；单、双。</div>

                <div style={styles.title2}>4、正码</div>
                <div style={styles.title3}>香港六合彩公司当期开出之前6个号码叫正码。每一个号码为一投注组合，假如投注号码为开奖号码之 正码，视为中奖，其馀情形视为不中奖。</div>

                <div style={styles.title2}>5、正码1-6盘势规则说明</div>
                <div style={styles.title3}>香港六合彩公司当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次为正码2、正码3…… 正码6(并不以号码大小排序)。</div>

                <div style={styles.title3}>大 小： 以指定出现正码的位置与号码大于或等于25为大，小于或等于24为小，开出49为和。</div>

                <div style={styles.title3}>单 双： 以指定出现正码的位置与号码为单数或双数下注，开出49为和。</div>

                <div style={styles.title3}>合数大小： 以指定出现正码的位置与号码个位和十位数字总和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

                <div style={styles.title3}>合数单双： 以指定出现正码的位置与号码个位和十位数字总和来判断单双，开出49号为和。</div>

                <div style={styles.title3}>色 波： 以指定出现正码的位置的球色下注，开奖之球色与下注之颜色相同时，视为中奖，其余情形视为不中奖。</div>

                <div
                    style={styles.title3}>尾数大小：以指定出现正码的位置与号码尾数大小下注，若0尾~4尾为小、5尾~9尾为大。如01、32、44为正尾小；如05、18、19为正尾大，开出49号为和。
                </div>

                <div style={styles.title2}>6、正码特</div>
                <div style={styles.title3}>正码特是指 正1特、正2特、正3特、正4特、正5特、正6特：其下注的正码特号与现场摇珠开出之正码其开奖顺序及开奖号码相同，视为中奖，
                    如现场摇珠第一个正码开奖为49号，下注第一个正码特为49则视为中奖，其它号码视为不中奖。
                </div>

                <div style={styles.title2}>7、总和单双</div>
                <div style={styles.title3}>所有七个开奖号码的分数总和是单数叫(总分单)，如分数总和是115、183；分数总和是双数叫(总
                    分双)，如分数总和是108、162。假如投注组合符合中奖结果，视为中奖，其馀情形视为不中奖 。
                </div>

                <div style={styles.title2}>8、总和大小</div>
                <div style={styles.title3}>所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。
                    如开奖号码为02、08、17、28、39、46、25，分数总和是165，则总分小。假如投注组合符合 中奖结果，视为中奖，其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>9、连码</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>四全中：
                                所投注的每四个号码为一组合，若四个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如06、07、08、09四个都是开奖号码之正码，视为中奖，如三个正码加上一个特别号码视为不中奖。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            2.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>三中二： 所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中二，视为中奖；
                                若3个都是开奖号码中的正码，即为三中二之中三，其馀情形视为不中奖，如06、07、08
                                为一组合，开奖号码中有06、07两个正码，没有08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视
                                为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            3.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>三全中： 所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其馀情形视为
                                不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号
                                码视为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            4.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>二全中： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其馀情形视为不
                                中奖（含一个正码加一个特别号码之情形）。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            5.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>二中特： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一
                                个是正码，一个是特别号码，叫二中特之中特；其馀情形视为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            6.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>特串： 所投注的每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其馀情形视为 不中奖（含二个号码都是正码之情形）
                                。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            7.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>依照二全中·二中特·特串 此3种玩法规则,来进行‘生肖对碰’或‘尾数对碰’</div>
                            <div style={styles.title4}>例一：</div>
                            <div style={styles.title4}>选择‘二全中’,之后进行‘生肖对碰’选择蛇(2，14，26，38)以及马 (1，13，25，37，49)</div>
                            <div style={styles.title4}>则会产生20组号码组合：</div>
                            <div style={styles.title4}>[2,1] [2,13] [2,25] [2,37] [2,49]</div>
                            <div style={styles.title4}>[14,1] [14,13] [14,25] [14,37] [14,49]</div>
                            <div style={styles.title4}>[26,1] [26,13] [26,25] [26,37] [26,49]</div>
                            <div style={styles.title4}>[38,1] [38,13] [38,25] [38,37] [38,49]</div>
                            <div style={styles.title4}>例二：</div>
                            <div style={styles.title4}>选择‘二中特’,之后进行‘尾数对碰’选择0(10，20，30，40)以及5(5，15，25，35，45)</div>
                            <div style={styles.title4}>则会产生20组号码组合：</div>
                            <div style={styles.title4}>[10,5] [10,15] [10,25] [10,35] [10,45]</div>
                            <div style={styles.title4}>[20,5] [20,15] [20,25] [20,35] [20,45]</div>
                            <div style={styles.title4}>[30,5] [30,15] [30,25] [30,35] [30,45]</div>
                            <div style={styles.title4}>[40,5] [40,15] [40,25] [40,35] [40,45]</div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            8.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>肖串尾数:选择一主肖，可扥0-9尾的球． 以三全中的肖串尾数为例：
                                选择【龙】当主肖(号码03,15,27,39)并且扥9尾数．因为9尾数中的39已在主肖中出现，将不重复组合．
                                故龙主肖扥9尾数共可组合出24种组合(二个尾数号码+一个主肖号码的组合)．
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>10、正码过关</div>
                <div
                    style={styles.title3}>游戏规则同正码1-6盘势，但须同时投注多项结果，串联成投注组合，只要单期所开出之开奖结果符合您所选定之串联结果，即视为中奖，若其中一项结果不中，则视为不中奖；若其中一项结果为和，其余结果都中奖的情形，也视为中奖；和的项目赔率以一计算
                </div>

                <div style={styles.title2}>11、生肖色波:分为生肖与色波两种玩法</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>生肖：</div>
                            <div style={styles.title4}>生肖顺序为 牛 >虎 >兔 >龙 >蛇 >马 >羊 >猴 >鸡 >狗 >猪 >鼠</div>
                            <div style={styles.title4}>如今年是龙年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                          <div style={styles.title4}>龙 01 , 13 , 25 , 37 , 49</div>
                          <div style={styles.title4}>蛇 12 , 24 , 36 , 48</div>
                          <div style={styles.title4}>马 11 , 23 , 35 , 47</div>
                          <div style={styles.title4}>羊 10 , 22 , 34 , 46</div>
                          <div style={styles.title4}>猴 09 , 21 , 33 , 45</div>
                          <div style={styles.title4}>鸡 08 , 20 , 32 , 44</div>
                          <div style={styles.title4}>狗 07 , 19 , 31 , 43</div>
                          <div style={styles.title4}>猪 06 , 18 , 30 , 42</div>
                          <div style={styles.title4}>鼠 05 , 17 , 29 , 41</div>
                          <div style={styles.title4}>牛 04 , 16 , 28 , 40</div>
                          <div style={styles.title4}>虎 03 , 15 , 27 , 39</div>
                          <div style={styles.title4}>兔 02 , 14 , 26 , 38</div>

                          <div style={styles.title4}>若当期特别号，落在下注生肖范围内，视为中奖 。</div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            2.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>色波： 以特别号的球色下注，开奖的球色与下注的颜色相同，视为中奖，球色号码分布如下:</div>
                            <div style={styles.title4}><span style={styles.color1}>01</span> 11 21 <span
                                style={styles.color2}>31</span> <span style={styles.color2}>41</span></div>
                            <div style={styles.title4}><span style={styles.color1}>02</span> <span
                                style={styles.color1}>12</span> 22 32 <span style={styles.color2}>42</span></div>
                            <div style={styles.title4}><span style={styles.color2}>03</span> <span
                                style={styles.color1}>13</span> <span style={styles.color1}>23</span> 33 43
                            </div>
                            <div style={styles.title4}><span style={styles.color2}>04</span> <span
                                style={styles.color2}>14</span> <span style={styles.color1}>24</span> <span
                                style={styles.color1}>34</span> 44
                            </div>
                            <div style={styles.title4}>05 <span style={styles.color2}>15</span> <span
                                style={styles.color2}>25</span> <span style={styles.color1}>35</span> <span
                                style={styles.color1}>45</span></div>
                            <div style={styles.title4}>06 16 <span style={styles.color2}>26</span> <span
                                style={styles.color2}>36</span> <span style={styles.color1}>46</span></div>
                            <div style={styles.title4}><span style={styles.color1}>07</span> 17 27 <span
                                style={styles.color2}>37</span> <span style={styles.color2}>47</span></div>
                            <div style={styles.title4}><span style={styles.color1}>08</span> <span
                                style={styles.color1}>18</span> 28 38 <span style={styles.color2}>48</span></div>
                            <div style={styles.title4}><span style={styles.color2}>09</span> <span
                                style={styles.color1}>19</span> <span style={styles.color1}>29</span> 39 49
                            </div>
                            <div style={styles.title4}><span style={styles.color2}>10</span> <span
                                style={styles.color2}>20</span> <span style={styles.color1}>30</span> <span
                                style={styles.color1}>40</span></div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>12、一肖</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>一肖： 将生肖依序排列，例如今年是兔年，就以龙为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                          <div style={styles.title4}>龙 01 , 13 , 25 , 37 , 49</div>
                          <div style={styles.title4}>蛇 12 , 24 , 36 , 48</div>
                          <div style={styles.title4}>马 11 , 23 , 35 , 47</div>
                          <div style={styles.title4}>羊 10 , 22 , 34 , 46</div>
                          <div style={styles.title4}>猴 09 , 21 , 33 , 45</div>
                          <div style={styles.title4}>鸡 08 , 20 , 32 , 44</div>
                          <div style={styles.title4}>狗 07 , 19 , 31 , 43</div>
                          <div style={styles.title4}>猪 06 , 18 , 30 , 42</div>
                          <div style={styles.title4}>鼠 05 , 17 , 29 , 41</div>
                          <div style={styles.title4}>牛 04 , 16 , 28 , 40</div>
                          <div style={styles.title4}>虎 03 , 15 , 27 , 39</div>
                          <div style={styles.title4}>兔 02 , 14 , 26 , 38</div>

                          <div style={styles.title4}>只要当期号码(所有正码与最后开出的特码)，落在下注生肖范围内，则视为中奖。<span
                                style={styles.color1}>(请注意：49亦算输赢，不为和)。</span></div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>13、合肖</div>
                <div
                    style={styles.title3}>选1~11生肖(排列如同生肖)为一组合，并选择开奖号码的特码是否在此组合内，若选择是"中"(可选择2~11个生肖)且开奖号码的特码亦在此组合内，即视为中奖；若选择否"不中"(可选择1~10个生肖),系统会将选择不中的组合改为中的组合；若当期特码开出49号，则所有包含49的生肖组合皆视为和局
                </div>

                <div style={styles.title2}>14、超快速模式</div>
                <div style={styles.title3}>在此模式下所投注的注单，若是赔率变动时，系统将不会有赔率已变动的讯息提示，而会以投注当时的最新赔率接受投注。</div>
                <div
                    style={styles.title3}>例：使用超快速模式投注特别号，输入金额时，赔率为41.5倍，金额输入的过程中赔率已更新为41倍，金额输入完毕，确认投注时，注单上所显示的赔率将会是以41倍接受投注，当下投注画面上亦会更新为最新赔率。
                </div>

                <div style={styles.title2}>15、半波</div>
                <div style={styles.title3}>以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>16、半半波</div>
                <div style={styles.title3}>以特码色波和特码单双及特码大小等三种游戏为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>17、正肖</div>
                <div style={styles.title3}>依据开出的所有正码为主。若当期6个正码，只要有1个落在下注生肖范围内，视为中奖。如超过1个正码落在下注生肖范围内 ，派彩将倍增！</div>
                <div style={styles.title3}>如：下注＄100.-正肖马赔率1.88</div>
                <div style={styles.title3}>6个正码开出01，派彩为＄88.-</div>
                <div style={styles.title3}>6个正码开出01，13，派彩为＄176.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，派彩为＄264.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，37，派彩为＄352.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，37，49，派彩为＄440.- (请注意：49亦算输赢，不为和)</div>

                <div style={styles.title2}>18、七色波</div>
                <div style={styles.title3}>以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。</div>
                <div style={styles.title3}>6个正码开出3蓝3绿，而特别码是1.5红</div>
                <div style={styles.title3}>6个正码开出3蓝3红，而特别码是1.5绿</div>
                <div style={styles.title3}>6个正码开出3绿3红，而特别码是1.5蓝</div>
                <div style={styles.title3}>如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局。</div>

                <div style={styles.title2}>19、特码头数 ：指特别号所属头数的号码</div>
                <div style={styles.title3}>"0"头 ：01，02，03，04，05，06，07，08，09</div>
                <div style={styles.title3}>"1"头 ：10，11，12，13，14，15，16，17，18，19</div>
                <div style={styles.title3}>"2"头 ：20，21，22，23，24，25，26，27，28，29</div>
                <div style={styles.title3}>"3"头 ：30，31，32，33，34，35，36，37，38，39</div>
                <div style={styles.title3}>"4"头 ：40，41，42，43，44，45，46，47，48，49</div>
                <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "2"头为中奖，其他头数都不中奖</div>

                <div style={styles.title2}>20、特码尾数 ：指特别号所属尾数的号码</div>
                <div style={styles.title3}>"1"尾 ：01，11，21，31，41</div>
                <div style={styles.title3}>"2"尾 ：02，12，22，32，42</div>
                <div style={styles.title3}>"3"尾 ：03，13，23，33，43</div>
                <div style={styles.title3}>"4"尾 ：04，14，24，34，44</div>
                <div style={styles.title3}>"5"尾 ：05，15，25，35，45</div>
                <div style={styles.title3}>"6"尾 ：06，16，26，36，46</div>
                <div style={styles.title3}>"7"尾 ：07，17，27，37，47</div>
                <div style={styles.title3}>"8"尾 ：08，18，28，38，48</div>
                <div style={styles.title3}>"9"尾 ：09，19，29，39，49</div>
                <div style={styles.title3}>"0"尾 ：10，20，30，40</div>
                <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "1"尾为中奖，其他尾数都不中奖</div>

                <div style={styles.title2}>21、正特尾数</div>
                <div style={styles.title3}>只要当期号码(所有正码及开出来的特码)，含所属尾数的一个或多个号码，但派彩只派一次，即不论同尾数号码出现一个或多个号码都只派彩一次。</div>
                <div style={styles.title3}>"1"尾：01.11.21.31.41 "2"尾：02.12.22.32.42 "3"尾：03.13.23.33.43</div>
                <div style={styles.title3}>"4"尾：04.14.24.34.44 "5"尾：05.15.25.35.45 "6"尾：06.16.26.36.46</div>
                <div style={styles.title3}>"7"尾：07.17.27.37.47 "8"尾：08.18.28.38.48 "9"尾：09.19.29.39.49</div>
                <div style={styles.title3}>"0"尾：10.20.30.40</div>
                <div style={styles.title3}>例如 ：开奖结果正码是11、31、42、44、35、32特别号码是 21 则"1"尾"2"尾"4"尾"5"尾都为中奖，其他尾数都不中奖。</div>

                <div style={styles.title2}>22、总肖</div>
                <div
                    style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数，与所投注之预计开出之生肖总数和(不用指定特定生肖)，则视为中奖，其余情形视为不中奖。例如：如果'当期号码为19、24、12、34、40、39
                </div>
                <div style={styles.title3}>特别号：49，总计六个生肖，若选总肖【6】则为中奖 (请注意：49号亦算输赢，不为和)</div>

                <div style={styles.title2}>23、总肖单双</div>
                <div
                    style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数若为单数则为单；若为双数则为双。例如当期号码为19、24、12、34、40、39.特码：49，则总计六个生肖，若下双则中奖。
                </div>

                <div style={styles.title2}>24、连肖</div>
                <div
                    style={styles.title3}>挑选2~6生肖(排列如同生肖)为一个组合，当期号码(所有正码与最后开出的特码)坐落于投注时所勾选之生肖组合所属号码内，所勾选之生肖皆至少有中一个号码，则视为中奖，其余情视为不中奖(请注意49亦算输赢，不为和)例如：如果当期号码为19、24、12、34、40、39.特码：49，所勾选三个生肖(称为三肖碰)，若所有生肖的所属号码内至少一个出现于当期号码，则视为中奖。
                </div>

                <div style={styles.title2}>25、连尾</div>
                <div style={styles.title3}>挑选2〜6个尾数为一组合，当期号码（所有正码与最后出的特码）坐落于投注时所勾选之尾数组合所属号码内，则视为中奖，其余情形视为不中奖
                    (请注意49亦算输赢，不为和)
                </div>
                <div style={styles.title3}>举例1：下注2尾碰，勾选0,1，当期七个号码若0尾及1尾皆有开出，视为中奖</div>
                <div
                    style={styles.title3}>举例2：下注2尾碰，勾选0,1,2三种尾数，会出现三种组合（组合一：0,1）（组合二：0,2）（组合三：1,2），当期七个号码若开出其中一种组合所选的两个尾数，视为中奖。
                </div>

                <div style={styles.title2}>26、自选不中奖</div>
                <div
                    style={styles.title3}>挑选5~12个号码为一个组合，当期号码(所有正码与最后开出的特码)皆没有坐落于投注时所挑选之号码组合内，则视为中奖，若是有任何一个当期号码开在所挑选的号码组合情形视为不中奖。
                </div>
                <div style={styles.title3}>例如当期号码为19,24,17,34,40,39,特别号49，所挑选5个号码(称为五不中)，若所挑选的号码内皆沒有坐落于当期号码，则为中奖</div>
            </div>
        )
    };
    /**
     * 三分六合彩
     * @returns {*}
     * @private
     */
    _renderSFLHC = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>{this.title}游戏规则</div>
                <div style={styles.title2}>1、{this.title}开奖球数共七粒,总49个号码.最后一位称之为特码,开奖时间每期{this.min}分钟,总的{this.count}期。
                </div>
                <div style={styles.title3}>以下所有投注皆含本金</div>

                <div style={styles.title2}>2、特别号码盘势规则说明</div>
                <div style={styles.title3}>{this.title}当期开出的最后一码为特别号或特码。</div>

                <div style={styles.title3}>特码 单 码：假设投注号码为开奖号码之特别号，视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title3}>特码 大 小：开出之特码大于或等于25为特码大， 小于或等于24为特码小，开出49为和。</div>

                <div style={styles.title3}>特码 单 双：特码为双数叫特双，如8、16；特码为单数叫特单，如21、35，开出49为和。</div>

                <div style={styles.title3}>特码合数大小：以特码个位和十位数字之和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

                <div style={styles.title3}>特码合数单双：以特码个位和十位数字之和来判断单双，如01，12，32为和单，02，11，33为和双，开出49号为和。</div>

                <div style={styles.title3}>特码尾数大小：以特别号尾数若0尾~4尾为小、5尾~9尾为大；如01、32、44为特尾小；如05、18、19为特尾大，开出49号为和。</div>

                <div style={styles.title3}>特码 半
                    特：以特别号大小与特别号单双游戏为一个投注组合；当期特别号开出符合投注组合，即视为中奖；若当期特码开出49号，则视为和局；其余情形视为不中奖。
                </div>

                <div style={styles.title2}>3、两面</div>
                <div style={styles.title3}>指大、小；单、双。</div>

                <div style={styles.title2}>4、正码</div>
                <div style={styles.title3}>{this.title}当期开出之前6个号码叫正码。每一个号码为一投注组合，假如投注号码为开奖号码之 正码，视为中奖，其馀情形视为不中奖。</div>

                <div style={styles.title2}>5、正码1-6盘势规则说明</div>
                <div style={styles.title3}>{this.title}当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次为正码2、正码3…… 正码6(并不以号码大小排序)。</div>

                <div style={styles.title3}>大 小： 以指定出现正码的位置与号码大于或等于25为大，小于或等于24为小，开出49为和。</div>

                <div style={styles.title3}>单 双： 以指定出现正码的位置与号码为单数或双数下注，开出49为和。</div>

                <div style={styles.title3}>合数大小： 以指定出现正码的位置与号码个位和十位数字总和来判断胜负，合数大于或等于7为大，小于或等于6为小，开出49号为和。</div>

                <div style={styles.title3}>合数单双： 以指定出现正码的位置与号码个位和十位数字总和来判断单双，开出49号为和。</div>

                <div style={styles.title3}>色 波： 以指定出现正码的位置的球色下注，开奖之球色与下注之颜色相同时，视为中奖，其余情形视为不中奖。</div>

                <div
                    style={styles.title3}>尾数大小：以指定出现正码的位置与号码尾数大小下注，若0尾~4尾为小、5尾~9尾为大。如01、32、44为正尾小；如05、18、19为正尾大，开出49号为和。
                </div>

                <div style={styles.title2}>6、正码特</div>
                <div style={styles.title3}>正码特是指 正1特、正2特、正3特、正4特、正5特、正6特：其下注的正码特号与现场摇珠开出之正码其开奖顺序及开奖号码相同，视为中奖，
                    如现场摇珠第一个正码开奖为49号，下注第一个正码特为49则视为中奖，其它号码视为不中奖。
                </div>

                <div style={styles.title2}>7、总和单双</div>
                <div style={styles.title3}>所有七个开奖号码的分数总和是单数叫(总分单)，如分数总和是115、183；分数总和是双数叫(总
                    分双)，如分数总和是108、162。假如投注组合符合中奖结果，视为中奖，其馀情形视为不中奖 。
                </div>

                <div style={styles.title2}>8、总和大小</div>
                <div style={styles.title3}>所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。
                    如开奖号码为02、08、17、28、39、46、25，分数总和是165，则总分小。假如投注组合符合 中奖结果，视为中奖，其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>9、连码</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>四全中：
                                所投注的每四个号码为一组合，若四个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如06、07、08、09四个都是开奖号码之正码，视为中奖，如三个正码加上一个特别号码视为不中奖。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            2.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>三中二： 所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中二，视为中奖；
                                若3个都是开奖号码中的正码，即为三中二之中三，其馀情形视为不中奖，如06、07、08
                                为一组合，开奖号码中有06、07两个正码，没有08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视
                                为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            3.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>三全中： 所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其馀情形视为
                                不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号
                                码视为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            4.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>二全中： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其馀情形视为不
                                中奖（含一个正码加一个特别号码之情形）。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            5.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>二中特： 所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一
                                个是正码，一个是特别号码，叫二中特之中特；其馀情形视为不中奖 。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            6.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>特串： 所投注的每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其馀情形视为 不中奖（含二个号码都是正码之情形）
                                。
                            </div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            7.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>依照二全中·二中特·特串 此3种玩法规则,来进行‘生肖对碰’或‘尾数对碰’</div>
                            <div style={styles.title4}>例一：</div>
                            <div style={styles.title4}>选择‘二全中’,之后进行‘生肖对碰’选择蛇(2，14，26，38)以及马 (1，13，25，37，49)</div>
                            <div style={styles.title4}>则会产生20组号码组合：</div>
                            <div style={styles.title4}>[2,1] [2,13] [2,25] [2,37] [2,49]</div>
                            <div style={styles.title4}>[14,1] [14,13] [14,25] [14,37] [14,49]</div>
                            <div style={styles.title4}>[26,1] [26,13] [26,25] [26,37] [26,49]</div>
                            <div style={styles.title4}>[38,1] [38,13] [38,25] [38,37] [38,49]</div>
                            <div style={styles.title4}>例二：</div>
                            <div style={styles.title4}>选择‘二中特’,之后进行‘尾数对碰’选择0(10，20，30，40)以及5(5，15，25，35，45)</div>
                            <div style={styles.title4}>则会产生20组号码组合：</div>
                            <div style={styles.title4}>[10,5] [10,15] [10,25] [10,35] [10,45]</div>
                            <div style={styles.title4}>[20,5] [20,15] [20,25] [20,35] [20,45]</div>
                            <div style={styles.title4}>[30,5] [30,15] [30,25] [30,35] [30,45]</div>
                            <div style={styles.title4}>[40,5] [40,15] [40,25] [40,35] [40,45]</div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            8.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>肖串尾数:选择一主肖，可扥0-9尾的球． 以三全中的肖串尾数为例：
                                选择【龙】当主肖(号码03,15,27,39)并且扥9尾数．因为9尾数中的39已在主肖中出现，将不重复组合．
                                故龙主肖扥9尾数共可组合出24种组合(二个尾数号码+一个主肖号码的组合)．
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>10、正码过关</div>
                <div
                    style={styles.title3}>游戏规则同正码1-6盘势，但须同时投注多项结果，串联成投注组合，只要单期所开出之开奖结果符合您所选定之串联结果，即视为中奖，若其中一项结果不中，则视为不中奖；若其中一项结果为和，其余结果都中奖的情形，也视为中奖；和的项目赔率以一计算
                </div>

                <div style={styles.title2}>11、生肖色波:分为生肖与色波两种玩法</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>生肖：</div>
                            <div style={styles.title4}>生肖顺序为 牛 >虎 >兔 >龙 >蛇 >马 >羊 >猴 >鸡 >狗 >猪 >鼠</div>
                            <div style={styles.title4}>如今年是兔年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                          <div style={styles.title4}>兔 01 , 13 , 25 , 37 , 49</div>
                          <div style={styles.title4}>龙 12 , 24 , 36 , 48</div>
                          <div style={styles.title4}>蛇 11 , 23 , 35 , 47</div>
                          <div style={styles.title4}>马 10 , 22 , 34 , 46</div>
                          <div style={styles.title4}>羊 09 , 21 , 33 , 45</div>
                          <div style={styles.title4}>猴 08 , 20 , 32 , 44</div>
                          <div style={styles.title4}>鸡 07 , 19 , 31 , 43</div>
                          <div style={styles.title4}>狗 06 , 18 , 30 , 42</div>
                          <div style={styles.title4}>猪 05 , 17 , 29 , 41</div>
                          <div style={styles.title4}>鼠 04 , 16 , 28 , 40</div>
                          <div style={styles.title4}>牛 03 , 15 , 27 , 39</div>
                          <div style={styles.title4}>虎 02 , 14 , 26 , 38</div>
                          <div style={styles.title4}>若当期特别号，落在下注生肖范围内，视为中奖 。</div>
                        </div>
                    </div>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            2.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>色波： 以特别号的球色下注，开奖的球色与下注的颜色相同，视为中奖，球色号码分布如下:</div>
                            <div style={styles.title4}><span style={styles.color1}>01</span> 11 21 <span
                                style={styles.color2}>31</span> <span style={styles.color2}>41</span></div>
                            <div style={styles.title4}><span style={styles.color1}>02</span> <span
                                style={styles.color1}>12</span> 22 32 <span style={styles.color2}>42</span></div>
                            <div style={styles.title4}><span style={styles.color2}>03</span> <span
                                style={styles.color1}>13</span> <span style={styles.color1}>23</span> 33 43
                            </div>
                            <div style={styles.title4}><span style={styles.color2}>04</span> <span
                                style={styles.color2}>14</span> <span style={styles.color1}>24</span> <span
                                style={styles.color1}>34</span> 44
                            </div>
                            <div style={styles.title4}>05 <span style={styles.color2}>15</span> <span
                                style={styles.color2}>25</span> <span style={styles.color1}>35</span> <span
                                style={styles.color1}>45</span></div>
                            <div style={styles.title4}>06 16 <span style={styles.color2}>26</span> <span
                                style={styles.color2}>36</span> <span style={styles.color1}>46</span></div>
                            <div style={styles.title4}><span style={styles.color1}>07</span> 17 27 <span
                                style={styles.color2}>37</span> <span style={styles.color2}>47</span></div>
                            <div style={styles.title4}><span style={styles.color1}>08</span> <span
                                style={styles.color1}>18</span> 28 38 <span style={styles.color2}>48</span></div>
                            <div style={styles.title4}><span style={styles.color2}>09</span> <span
                                style={styles.color1}>19</span> <span style={styles.color1}>29</span> 39 49
                            </div>
                            <div style={styles.title4}><span style={styles.color2}>10</span> <span
                                style={styles.color2}>20</span> <span style={styles.color1}>30</span> <span
                                style={styles.color1}>40</span></div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>12、一肖</div>
                <div style={styles.tbContainer}>
                    <div style={styles.tbRow}>
                        <div style={{...styles.tbColumn, ...{width: 20}}}>
                            1.
                        </div>
                        <div style={{...styles.tbColumn, ...{flex: 1}}}>
                            <div style={styles.title4}>一肖： 将生肖依序排列，例如今年是兔年，就以兔为开始，依顺序将49个号码分为12个生肖(如下)，再以生肖下注。</div>
                            <div style={styles.title4}>兔 01 , 13 , 25 , 37 , 49</div>
                            <div style={styles.title4}>龙 12 , 24 , 36 , 48</div>
                            <div style={styles.title4}>蛇 11 , 23 , 35 , 47</div>
                            <div style={styles.title4}>马 10 , 22 , 34 , 46</div>
                            <div style={styles.title4}>羊 09 , 21 , 33 , 45</div>
                            <div style={styles.title4}>猴 08 , 20 , 32 , 44</div>
                            <div style={styles.title4}>鸡 07 , 19 , 31 , 43</div>
                            <div style={styles.title4}>狗 06 , 18 , 30 , 42</div>
                            <div style={styles.title4}>猪 05 , 17 , 29 , 41</div>
                            <div style={styles.title4}>鼠 04 , 16 , 28 , 40</div>
                            <div style={styles.title4}>牛 03 , 15 , 27 , 39</div>
                            <div style={styles.title4}>虎 02 , 14 , 26 , 38</div>
                            <div style={styles.title4}>只要当期号码(所有正码与最后开出的特码)，落在下注生肖范围内，则视为中奖。<span
                                style={styles.color1}>(请注意：49亦算输赢，不为和)。</span></div>
                        </div>
                    </div>
                </div>
                <div style={styles.title2}>13、合肖</div>
                <div
                    style={styles.title3}>选1~11生肖(排列如同生肖)为一组合，并选择开奖号码的特码是否在此组合内，若选择是"中"(可选择2~11个生肖)且开奖号码的特码亦在此组合内，即视为中奖；若选择否"不中"(可选择1~10个生肖),系统会将选择不中的组合改为中的组合；若当期特码开出49号，则所有组合皆视为和局
                </div>

                <div style={styles.title2}>14、超快速模式</div>
                <div style={styles.title3}>在此模式下所投注的注单，若是赔率变动时，系统将不会有赔率已变动的讯息提示，而会以投注当时的最新赔率接受投注。</div>
                <div
                    style={styles.title3}>例：使用超快速模式投注特别号，输入金额时，赔率为41.5倍，金额输入的过程中赔率已更新为41倍，金额输入完毕，确认投注时，注单上所显示的赔率将会是以41倍接受投注，当下投注画面上亦会更新为最新赔率。
                </div>

                <div style={styles.title2}>15、半波</div>
                <div style={styles.title3}>以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>16、半半波</div>
                <div style={styles.title3}>以特码色波和特码单双及特码大小等三种游戏为一个投注组合，当期特码开出符合投注组合，即视为中奖； 若当期特码开出49号，则视为和局；其馀情形视为不中奖。
                </div>

                <div style={styles.title2}>17、正肖</div>
                <div style={styles.title3}>依据开出的所有正码为主。若当期6个正码，只要有1个落在下注生肖范围内，视为中奖。如超过1个正码落在下注生肖范围内 ，派彩将倍增！</div>
                <div style={styles.title3}>如：下注＄100.-正肖马赔率1.88</div>
                <div style={styles.title3}>6个正码开出01，派彩为＄88.-</div>
                <div style={styles.title3}>6个正码开出01，13，派彩为＄176.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，派彩为＄264.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，37，派彩为＄352.-</div>
                <div style={styles.title3}>6个正码开出01，13，25，37，49，派彩为＄440.- (请注意：49亦算输赢，不为和)</div>

                <div style={styles.title2}>18、七色波</div>
                <div style={styles.title3}>以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。</div>
                <div style={styles.title3}>6个正码开出3蓝3绿，而特别码是1.5红</div>
                <div style={styles.title3}>6个正码开出3蓝3红，而特别码是1.5绿</div>
                <div style={styles.title3}>6个正码开出3绿3红，而特别码是1.5蓝</div>
                <div style={styles.title3}>如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局。</div>

                <div style={styles.title2}>19、特码头数 ：指特别号所属头数的号码</div>
                <div style={styles.title3}>"0"头 ：01，02，03，04，05，06，07，08，09</div>
                <div style={styles.title3}>"1"头 ：10，11，12，13，14，15，16，17，18，19</div>
                <div style={styles.title3}>"2"头 ：20，21，22，23，24，25，26，27，28，29</div>
                <div style={styles.title3}>"3"头 ：30，31，32，33，34，35，36，37，38，39</div>
                <div style={styles.title3}>"4"头 ：40，41，42，43，44，45，46，47，48，49</div>
                <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "2"头为中奖，其他头数都不中奖</div>

                <div style={styles.title2}>20、特码尾数 ：指特别号所属尾数的号码</div>
                <div style={styles.title3}>"1"尾 ：01，11，21，31，41</div>
                <div style={styles.title3}>"2"尾 ：02，12，22，32，42</div>
                <div style={styles.title3}>"3"尾 ：03，13，23，33，43</div>
                <div style={styles.title3}>"4"尾 ：04，14，24，34，44</div>
                <div style={styles.title3}>"5"尾 ：05，15，25，35，45</div>
                <div style={styles.title3}>"6"尾 ：06，16，26，36，46</div>
                <div style={styles.title3}>"7"尾 ：07，17，27，37，47</div>
                <div style={styles.title3}>"8"尾 ：08，18，28，38，48</div>
                <div style={styles.title3}>"9"尾 ：09，19，29，39，49</div>
                <div style={styles.title3}>"0"尾 ：10，20，30，40</div>
                <div style={styles.title3}>例如 ：开奖结果特别号码是21则 "1"尾为中奖，其他尾数都不中奖</div>

                <div style={styles.title2}>21、正特尾数</div>
                <div style={styles.title3}>只要当期号码(所有正码及开出来的特码)，含所属尾数的一个或多个号码，但派彩只派一次，即不论同尾数号码出现一个或多个号码都只派彩一次。</div>
                <div style={styles.title3}>"1"尾：01.11.21.31.41 "2"尾：02.12.22.32.42 "3"尾：03.13.23.33.43</div>
                <div style={styles.title3}>"4"尾：04.14.24.34.44 "5"尾：05.15.25.35.45 "6"尾：06.16.26.36.46</div>
                <div style={styles.title3}>"7"尾：07.17.27.37.47 "8"尾：08.18.28.38.48 "9"尾：09.19.29.39.49</div>
                <div style={styles.title3}>"0"尾：10.20.30.40</div>
                <div style={styles.title3}>例如 ：开奖结果正码是11、31、42、44、35、32特别号码是 21 则"1"尾"2"尾"4"尾"5"尾都为中奖，其他尾数都不中奖。</div>

                <div style={styles.title2}>22、总肖</div>
                <div
                    style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数，与所投注之预计开出之生肖总数和(不用指定特定生肖)，则视为中奖，其余情形视为不中奖。例如：如果'当期号码为19、24、12、34、40、39
                </div>
                <div style={styles.title3}>特别号：49，总计六个生肖，若选总肖【6】则为中奖 (请注意：49号亦算输赢，不为和)</div>

                <div style={styles.title2}>23、总肖单双</div>
                <div
                    style={styles.title3}>当期号码(所有正码与最后开出的特码)开出的不同生肖总数若为单数则为单；若为双数则为双。例如当期号码为19、24、12、34、40、39.特码：49，则总计六个生肖，若下双则中奖。
                </div>

                <div style={styles.title2}>24、连肖</div>
                <div
                    style={styles.title3}>挑选2~6生肖(排列如同生肖)为一个组合，当期号码(所有正码与最后开出的特码)坐落于投注时所勾选之生肖组合所属号码内，所勾选之生肖皆至少有中一个号码，则视为中奖，其余情视为不中奖(请注意49亦算输赢，不为和)例如：如果当期号码为19、24、12、34、40、39.特码：49，所勾选三个生肖(称为三肖碰)，若所有生肖的所属号码内至少一个出现于当期号码，则视为中奖。
                </div>

                <div style={styles.title2}>25、连尾</div>
                <div style={styles.title3}>挑选2〜6个尾数为一组合，当期号码（所有正码与最后出的特码）坐落于投注时所勾选之尾数组合所属号码内，则视为中奖，其余情形视为不中奖
                    (请注意49亦算输赢，不为和)
                </div>
                <div style={styles.title3}>举例1：下注2尾碰，勾选0,1，当期七个号码若0尾及1尾皆有开出，视为中奖</div>
                <div
                    style={styles.title3}>举例2：下注2尾碰，勾选0,1,2三种尾数，会出现三种组合（组合一：0,1）（组合二：0,2）（组合三：1,2），当期七个号码若开出其中一种组合所选的两个尾数，视为中奖。
                </div>

                <div style={styles.title2}>26、自选不中奖</div>
                <div
                    style={styles.title3}>挑选5~12个号码为一个组合，当期号码(所有正码与最后开出的特码)皆没有坐落于投注时所挑选之号码组合内，则视为中奖，若是有任何一个当期号码开在所挑选的号码组合情形视为不中奖。
                </div>
                <div style={styles.title3}>例如当期号码为19,24,17,34,40,39,特别号49，所挑选5个号码(称为五不中)，若所挑选的号码内皆沒有坐落于当期号码，则为中奖</div>
            </div>
        )
    };


    /**
     * 新疆时时彩
     * @returns {*}
     * @private
     */
    _renderXJSSC = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            tableSytle:{
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                marginLeft: 25,
                wordBreak: "break-all"
            },
            trStyle:{
                borderLeft:"1px solid #d2d2d2",
                borderTop:"1px solid #d2d2d2",
            },
            tdStyle:{
                borderRight:"1px solid #d2d2d2",
                borderBottom:"1px solid #d2d2d2",
                padding:"5px"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>新疆时时彩游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"新疆时时彩"完全同步，北京时间（GMT+8）每天白天从上午10:00:00开到凌晨02:00:00，每20分鐘开一次奖,每天开奖48期。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第五球</div>
                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码 8，下注第一球为 8
                    者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>单双大小</div>

                <div style={styles.title3}>根据相应单项投注第一球 ~ 第五球开出的球号，判断胜负。</div>

                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号大于或等于 5 为特码大，小于或等于 4 为特码小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号为双数则为特码双，如 2、6；球号为单数则为特码单，如 1、3。</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值大于或等于 23 为总和大，小于或等于 22 为总和小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值是双数为总和双，数字总和值是单数为总和单。</div>

                <div style={styles.title2}>4、前三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相同。如中奖号码为：222XX、666XX、888XX...开奖号码的万位千位百位数字相同，则投注前三豹子者视为中奖，其它视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：123XX、901XX、321XX、798XX...开奖号码的万位千位百位数字相连，则投注前三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相同（不包括豹子）。如中奖号码为：001XX，288XX、696XX...开奖号码的万位千位百位有两位数字相同，则投注前三对子者视为中奖，其它视为不中奖。如果开奖号码为前三豹子，则前三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：125XX、540XX、390XX、160XX...开奖号码的万位千位百位有两位数字相连，则投注前三半顺者视为中奖，其它视为不中奖。如果开奖号码为前三顺子、前三对子，则前三半顺视为不中奖。如开奖号码为：123XX、901XX、556XX、233XX...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：157XX、268XX...开奖号码位数之间无关联性，则投注前三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>5、中三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相同。如中奖号码为：X222X、X666X、X888X...开奖号码的千位百位十位数字相同，则投注中三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：X123X、X901X、X321X、X798X...开奖号码的千位百位十位数字相连，则投注中三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相同（不包括豹子）。如中奖号码为：X001X，X288X、X696X...开奖号码的千位百位十位有两位数字相同，则投注中三对子者视为中奖，其它视为不中奖。如果开奖号码为中三豹子，则中三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：X125X、X540X、X390X、X160X...开奖号码的千位百位十位有两位数字相连，则投注中三半顺者视为中奖，其它视为不中奖。如果开奖号码为中三顺子、中三对子，则中三半顺视为不中奖。如开奖号码为：X123X、X901X、X556X、X233X...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：X157X、X268X...开奖号码位数之间无关联性，则投注中三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖。
                </div>

                <div style={styles.title2}>6、后三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相同。如中奖号码为：XX222、XX666、XX888...开奖号码的百位十位个位数字相同，则投注后三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：XX123、XX901、XX321、XX798...开奖号码的百位十位个位数字相连，则投注后三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相同（不包括豹子）。如中奖号码为：XX001、XX288、XX696...开奖号码的百位十位个位有两位数字相同，则投注后三对子者视为中奖，其它视为不中奖。如果开奖号码为后三豹子，则后三对子视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：XX125、XX540、XX390、XX160...开奖号码的百位十位个位有两位数字相连，则投注后三半顺者视为中奖，其它视为不中奖。如果开奖号码为后三顺子、后三对子，则后三半顺视为不中奖。如开奖号码为：XX123、XX901、XX556、XX233...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：XX157、XX268...开奖号码位数之间无关联性，则投注后三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>7、龙虎和特殊玩法： 龙 > 虎 > 和 （0为最小，9为最大）</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 大于 第五球（个位）的号码。如：第一球开出 6，第五球开出 2、第一球开出 8，第五球开出 6、第一球开出 5，第五球开出
                    1...开奖为龙，投注龙者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 小于 第五球（个位）的号码。如：第一球开出 2，第五球开出 6、第一球开出 6，第五球开出 8、第一球开出 1，第五球开出
                    5...开奖为虎，投注虎者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>和</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 等于 第五球（个位）的号码。如：2XXX2、6XXX6、8XXX8...开奖为和，投注和者视为中奖，其它视为不中奖。</div>
                <div style={styles.title2}>8、牛牛</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码（0-9）中，如果任意选取3个号码都不能求和为10的整数倍数，该期开奖结果为没牛。如果任意选取3个号码能够求和为10的整数倍数，则以剩余2个号码之和（大于10取减去10后的结果）作为该期牛数，根据牛数开奖结果分别为：牛1、牛2、牛3、牛4、牛5、牛6、牛7、牛8、牛9，牛10又称作牛牛。
                    如：开奖号码 0 0 0 2 6 为牛8，开奖号码 0 2 8 1 8 为牛9，开奖号码 6 8 6 2 8 和开奖号码 2 3 5 0 0 为牛牛，开奖号码 2 6 3 7 8 和开奖号码 1 5
                    2 8 6 为没牛。注：当五个开奖号码相同时，只有开奖号码 0 0 0 0 0 视为牛牛，其它开奖号码如 1 1 1 1 1，6 6 6 6 6 等皆视为没牛。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>
                <div style={styles.title3}>
                    当期牛数大小，牛1、牛2、牛3、牛4、牛5为小，牛6、牛7、牛8、牛9、牛牛为大。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛大、牛小皆视为未中奖）。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>单双</div>
                <div style={styles.title3}>
                    当期牛数单双，牛1、牛3、牛5、牛7、牛9为单，牛2、牛4、牛6、牛8、牛牛为双。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛单、牛双皆视为未中奖）。
                </div>
                <div style={styles.title2}>9、梭哈</div>
                <div style={{...styles.title3, ...styles.color1}}>五条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码都相同。 如：投注梭哈 五条，开奖号码 8 8 8 8 8 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>四条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有4个开奖号码相同。 如：投注梭哈 四条，开奖号码 6 8 6 6 6 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>葫芦</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由1组三条（3个号码相同）和1组对子（2个号码相同）组成。 如：投注梭哈 葫芦，开奖号码 3 5 3 3 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>顺子</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码数字相连（数字9、0、1相连），顺序不限。 如：投注梭哈 顺子，开奖号码 0 9 2 1 3 即为中奖，又如：投注梭哈 顺子，开奖号码 6 5 7 4 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>三条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码有且只有3个开奖号码相同，且剩余2个号码不同。 如：投注梭哈 三条，开奖号码 2 7 2 1 2 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>两对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由2组不同的对子（2个号码相同）组成。 如：投注梭哈 两对，开奖号码 1 8 5 1 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>一对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有2个开奖号码相同。 如：投注梭哈 一对，开奖号码 2 9 7 2 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>散号</div>
                <div style={styles.title3}>
                    除五条、四条、葫芦、三条、顺子、两对、一对以外的所有开奖号码。 如：投注梭哈 散号，开奖号码 2 3 1 8 6 即为中奖。
                </div>
                <div style={styles.title2}>10、百家乐</div>
                <div style={styles.title3}>百家乐游戏规则：百家乐是以开奖号码为基础，进行庄、闲对比的一种玩法！</div>
                <div style={styles.title3}>庄是指以开奖五个号码为基础，选择前两个数（万、千视为庄的前两张牌）。</div>
                <div style={styles.title3}>闲是指以开奖五个号码为基础，选择后两个数（十、个视为闲的前两张牌）。</div>
                <div style={styles.title3}>个位与百位合数的个位数，此张牌为第5张牌。万位与百位合数的个位数，此张牌为第6张牌</div>
                <div style={styles.title3}>如果闲家补牌，那么第5张牌给闲家，如果闲家不补牌，庄家补牌，那么第5张牌给庄家</div>
                <div style={styles.title3}>如第一轮未分出胜负需要再按牌例发第二轮牌，第5张牌闲先发，最多每方3张牌，谁最接近9点即为胜方</div>
                <div style={styles.title3}>有一个天生赢家或者两个都大等于6(庄闲都不补)</div>
                <div style={{...styles.title3, ...styles.color1}}>闲家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2-3-4-5 补一张牌</td>
                        <td style={styles.tdStyle}>即获得第5张牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>不需补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不需补牌</td>
                    </tr>
                </table>
                <div style={{...styles.title3, ...styles.color1}}>庄家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>头两张牌点数总和</td>
                        <td style={styles.tdStyle}>庄家补牌，如果第5张牌为以下......</td>
                        <td style={styles.tdStyle}>庄家不补牌，如果第5张牌为以下......</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、8、9</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>3</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、9</td>
                        <td style={styles.tdStyle}>8</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>4</td>
                        <td style={styles.tdStyle}>2、3、4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>5</td>
                        <td style={styles.tdStyle}>4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、2、3、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6</td>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>7</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不须补牌</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                </table>
                <div style={styles.title3}>庄家（万位千位）闲家（十位个位）两位数字相加后的个位数字相同即为打和
                    举例:开奖号码为（80444）庄点数为8点，闲点数为8点，点数相同，投注"和"者中奖。其余情形视为不中奖。
                </div>
                <div style={styles.title3}>大小/单双/质合，以庄闲的点数来进行判断结果。 0~4为小，5~9为大; 1、3、5、7、9为单，0、2、4、6、8为双;
                    2、3、5、7为质数，4、6、8、9为合数。 0和1既不是质数也不是合数
                </div>
                <div style={styles.title3}>庄对/闲对 是指两家首发(第一轮)的两张牌为对子,不含第三张。如：开奖55842，投庄对则中奖，投闲对不中奖</div>
            </div>
        )
    };
    /**
     * 三分时时彩
     * @returns {*}
     * @private
     */
    _renderSFSSC = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            tableSytle:{
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                marginLeft: 25,
                wordBreak: "break-all"
            },
            trStyle:{
                borderLeft:"1px solid #d2d2d2",
                borderTop:"1px solid #d2d2d2",
            },
            tdStyle:{
                borderRight:"1px solid #d2d2d2",
                borderBottom:"1px solid #d2d2d2",
                padding:"5px"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>{this.title}游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏每天00:00开到晚上23:59，每{this.min}分鐘开一次奖,每天开奖{this.count}期。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第五球</div>
                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码 8，下注第一球为 8
                    者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>单双大小</div>

                <div style={styles.title3}>根据相应单项投注第一球 ~ 第五球开出的球号，判断胜负。</div>

                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号大于或等于 5 为特码大，小于或等于 4 为特码小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号为双数则为特码双，如 2、6；球号为单数则为特码单，如 1、3。</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值大于或等于 23 为总和大，小于或等于 22 为总和小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值是双数为总和双，数字总和值是单数为总和单。</div>

                <div style={styles.title2}>4、前三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相同。如中奖号码为：222XX、666XX、888XX...开奖号码的万位千位百位数字相同，则投注前三豹子者视为中奖，其它视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：123XX、901XX、321XX、798XX...开奖号码的万位千位百位数字相连，则投注前三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相同（不包括豹子）。如中奖号码为：001XX，288XX、696XX...开奖号码的万位千位百位有两位数字相同，则投注前三对子者视为中奖，其它视为不中奖。如果开奖号码为前三豹子，则前三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：125XX、540XX、390XX、160XX...开奖号码的万位千位百位有两位数字相连，则投注前三半顺者视为中奖，其它视为不中奖。如果开奖号码为前三顺子、前三对子，则前三半顺视为不中奖。如开奖号码为：123XX、901XX、556XX、233XX...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：157XX、268XX...开奖号码位数之间无关联性，则投注前三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>5、中三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相同。如中奖号码为：X222X、X666X、X888X...开奖号码的千位百位十位数字相同，则投注中三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：X123X、X901X、X321X、X798X...开奖号码的千位百位十位数字相连，则投注中三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相同（不包括豹子）。如中奖号码为：X001X，X288X、X696X...开奖号码的千位百位十位有两位数字相同，则投注中三对子者视为中奖，其它视为不中奖。如果开奖号码为中三豹子，则中三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：X125X、X540X、X390X、X160X...开奖号码的千位百位十位有两位数字相连，则投注中三半顺者视为中奖，其它视为不中奖。如果开奖号码为中三顺子、中三对子，则中三半顺视为不中奖。如开奖号码为：X123X、X901X、X556X、X233X...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：X157X、X268X...开奖号码位数之间无关联性，则投注中三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖。
                </div>

                <div style={styles.title2}>6、后三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相同。如中奖号码为：XX222、XX666、XX888...开奖号码的百位十位个位数字相同，则投注后三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：XX123、XX901、XX321、XX798...开奖号码的百位十位个位数字相连，则投注后三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相同（不包括豹子）。如中奖号码为：XX001、XX288、XX696...开奖号码的百位十位个位有两位数字相同，则投注后三对子者视为中奖，其它视为不中奖。如果开奖号码为后三豹子，则后三对子视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：XX125、XX540、XX390、XX160...开奖号码的百位十位个位有两位数字相连，则投注后三半顺者视为中奖，其它视为不中奖。如果开奖号码为后三顺子、后三对子，则后三半顺视为不中奖。如开奖号码为：XX123、XX901、XX556、XX233...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：XX157、XX268...开奖号码位数之间无关联性，则投注后三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>7、龙虎和特殊玩法： 龙 > 虎 > 和 （0为最小，9为最大）</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 大于 第五球（个位）的号码。如：第一球开出 6，第五球开出 2、第一球开出 8，第五球开出 6、第一球开出 5，第五球开出
                    1...开奖为龙，投注龙者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 小于 第五球（个位）的号码。如：第一球开出 2，第五球开出 6、第一球开出 6，第五球开出 8、第一球开出 1，第五球开出
                    5...开奖为虎，投注虎者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>和</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 等于 第五球（个位）的号码。如：2XXX2、6XXX6、8XXX8...开奖为和，投注和者视为中奖，其它视为不中奖。</div>
                <div style={styles.title2}>8、牛牛</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码（0-9）中，如果任意选取3个号码都不能求和为10的整数倍数，该期开奖结果为没牛。如果任意选取3个号码能够求和为10的整数倍数，则以剩余2个号码之和（大于10取减去10后的结果）作为该期牛数，根据牛数开奖结果分别为：牛1、牛2、牛3、牛4、牛5、牛6、牛7、牛8、牛9，牛10又称作牛牛。
                    如：开奖号码 0 0 0 2 6 为牛8，开奖号码 0 2 8 1 8 为牛9，开奖号码 6 8 6 2 8 和开奖号码 2 3 5 0 0 为牛牛，开奖号码 2 6 3 7 8 和开奖号码 1 5
                    2 8 6 为没牛。注：当五个开奖号码相同时，只有开奖号码 0 0 0 0 0 视为牛牛，其它开奖号码如 1 1 1 1 1，6 6 6 6 6 等皆视为没牛。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>
                <div style={styles.title3}>
                    当期牛数大小，牛1、牛2、牛3、牛4、牛5为小，牛6、牛7、牛8、牛9、牛牛为大。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛大、牛小皆视为未中奖）。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>单双</div>
                <div style={styles.title3}>
                    当期牛数单双，牛1、牛3、牛5、牛7、牛9为单，牛2、牛4、牛6、牛8、牛牛为双。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛单、牛双皆视为未中奖）。
                </div>
                <div style={styles.title2}>9、梭哈</div>
                <div style={{...styles.title3, ...styles.color1}}>五条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码都相同。 如：投注梭哈 五条，开奖号码 8 8 8 8 8 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>四条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有4个开奖号码相同。 如：投注梭哈 四条，开奖号码 6 8 6 6 6 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>葫芦</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由1组三条（3个号码相同）和1组对子（2个号码相同）组成。 如：投注梭哈 葫芦，开奖号码 3 5 3 3 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>顺子</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码数字相连（数字9、0、1相连），顺序不限。 如：投注梭哈 顺子，开奖号码 0 9 2 1 3 即为中奖，又如：投注梭哈 顺子，开奖号码 6 5 7 4 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>三条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码有且只有3个开奖号码相同，且剩余2个号码不同。 如：投注梭哈 三条，开奖号码 2 7 2 1 2 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>两对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由2组不同的对子（2个号码相同）组成。 如：投注梭哈 两对，开奖号码 1 8 5 1 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>一对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有2个开奖号码相同。 如：投注梭哈 一对，开奖号码 2 9 7 2 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>散号</div>
                <div style={styles.title3}>
                    除五条、四条、葫芦、三条、顺子、两对、一对以外的所有开奖号码。 如：投注梭哈 散号，开奖号码 2 3 1 8 6 即为中奖。
                </div>
                <div style={styles.title2}>10、百家乐</div>
                <div style={styles.title3}>百家乐游戏规则：百家乐是以开奖号码为基础，进行庄、闲对比的一种玩法！</div>
                <div style={styles.title3}>庄是指以开奖五个号码为基础，选择前两个数（万、千视为庄的前两张牌）。</div>
                <div style={styles.title3}>闲是指以开奖五个号码为基础，选择后两个数（十、个视为闲的前两张牌）。</div>
                <div style={styles.title3}>个位与百位合数的个位数，此张牌为第5张牌。万位与百位合数的个位数，此张牌为第6张牌</div>
                <div style={styles.title3}>如果闲家补牌，那么第5张牌给闲家，如果闲家不补牌，庄家补牌，那么第5张牌给庄家</div>
                <div style={styles.title3}>如第一轮未分出胜负需要再按牌例发第二轮牌，第5张牌闲先发，最多每方3张牌，谁最接近9点即为胜方</div>
                <div style={styles.title3}>有一个天生赢家或者两个都大等于6(庄闲都不补)</div>
                <div style={{...styles.title3, ...styles.color1}}>闲家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2-3-4-5 补一张牌</td>
                        <td style={styles.tdStyle}>即获得第5张牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>不需补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不需补牌</td>
                    </tr>
                </table>
                <div style={{...styles.title3, ...styles.color1}}>庄家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>头两张牌点数总和</td>
                        <td style={styles.tdStyle}>庄家补牌，如果第5张牌为以下......</td>
                        <td style={styles.tdStyle}>庄家不补牌，如果第5张牌为以下......</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、8、9</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>3</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、9</td>
                        <td style={styles.tdStyle}>8</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>4</td>
                        <td style={styles.tdStyle}>2、3、4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>5</td>
                        <td style={styles.tdStyle}>4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、2、3、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6</td>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>7</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不须补牌</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                </table>
                <div style={styles.title3}>庄家（万位千位）闲家（十位个位）两位数字相加后的个位数字相同即为打和
                    举例:开奖号码为（80444）庄点数为8点，闲点数为8点，点数相同，投注"和"者中奖。其余情形视为不中奖。
                </div>
                <div style={styles.title3}>大小/单双/质合，以庄闲的点数来进行判断结果。 0~4为小，5~9为大; 1、3、5、7、9为单，0、2、4、6、8为双;
                    2、3、5、7为质数，4、6、8、9为合数。 0和1既不是质数也不是合数
                </div>
                <div style={styles.title3}>庄对/闲对 是指两家首发(第一轮)的两张牌为对子,不含第三张。如：开奖55842，投庄对则中奖，投闲对不中奖</div>
            </div>
        )
    };
    /**
     * 重庆时时彩
     * @returns {*}
     * @private
     */
    _renderCQSSC = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            tableSytle:{
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                marginLeft: 25,
                wordBreak: "break-all"
            },
            trStyle:{
                borderLeft:"1px solid #d2d2d2",
                borderTop:"1px solid #d2d2d2",
            },
            tdStyle:{
                borderRight:"1px solid #d2d2d2",
                borderBottom:"1px solid #d2d2d2",
                padding:"5px"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>重庆时时彩游戏规则</div>
                <div style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与重庆时时彩完全同步，北京时间（GMT+8）每天白天从上午07:10开到晚上23：50，夜场从00：10至凌晨
                    03：10 ，每 20 分钟开一次奖，每天开奖 59 期（白天 50 期，夜间 9 期）。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第五球</div>
                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码 8，下注第一球为 8
                    者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>单双大小</div>

                <div style={styles.title3}>根据相应单项投注第一球 ~ 第五球开出的球号，判断胜负。</div>

                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号大于或等于 5 为特码大，小于或等于 4 为特码小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号为双数则为特码双，如 2、6；球号为单数则为特码单，如 1、3。</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值大于或等于 23 为总和大，小于或等于 22 为总和小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值是双数为总和双，数字总和值是单数为总和单。</div>

                <div style={styles.title2}>4、前三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相同。如中奖号码为：222XX、666XX、888XX...开奖号码的万位千位百位数字相同，则投注前三豹子者视为中奖，其它视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：123XX、901XX、321XX、798XX...开奖号码的万位千位百位数字相连，则投注前三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相同（不包括豹子）。如中奖号码为：001XX，288XX、696XX...开奖号码的万位千位百位有两位数字相同，则投注前三对子者视为中奖，其它视为不中奖。如果开奖号码为前三豹子，则前三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：125XX、540XX、390XX、160XX...开奖号码的万位千位百位有两位数字相连，则投注前三半顺者视为中奖，其它视为不中奖。如果开奖号码为前三顺子、前三对子，则前三半顺视为不中奖。如开奖号码为：123XX、901XX、556XX、233XX...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：157XX、268XX...开奖号码位数之间无关联性，则投注前三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>5、中三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相同。如中奖号码为：X222X、X666X、X888X...开奖号码的千位百位十位数字相同，则投注中三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：X123X、X901X、X321X、X798X...开奖号码的千位百位十位数字相连，则投注中三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相同（不包括豹子）。如中奖号码为：X001X，X288X、X696X...开奖号码的千位百位十位有两位数字相同，则投注中三对子者视为中奖，其它视为不中奖。如果开奖号码为中三豹子，则中三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：X125X、X540X、X390X、X160X...开奖号码的千位百位十位有两位数字相连，则投注中三半顺者视为中奖，其它视为不中奖。如果开奖号码为中三顺子、中三对子，则中三半顺视为不中奖。如开奖号码为：X123X、X901X、X556X、X233X...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：X157X、X268X...开奖号码位数之间无关联性，则投注中三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖。
                </div>

                <div style={styles.title2}>6、后三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相同。如中奖号码为：XX222、XX666、XX888...开奖号码的百位十位个位数字相同，则投注后三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：XX123、XX901、XX321、XX798...开奖号码的百位十位个位数字相连，则投注后三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相同（不包括豹子）。如中奖号码为：XX001、XX288、XX696...开奖号码的百位十位个位有两位数字相同，则投注后三对子者视为中奖，其它视为不中奖。如果开奖号码为后三豹子，则后三对子视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：XX125、XX540、XX390、XX160...开奖号码的百位十位个位有两位数字相连，则投注后三半顺者视为中奖，其它视为不中奖。如果开奖号码为后三顺子、后三对子，则后三半顺视为不中奖。如开奖号码为：XX123、XX901、XX556、XX233...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：XX157、XX268...开奖号码位数之间无关联性，则投注后三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>7、龙虎和特殊玩法： 龙 > 虎 > 和 （0为最小，9为最大）</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 大于 第五球（个位）的号码。如：第一球开出 6，第五球开出 2、第一球开出 8，第五球开出 6、第一球开出 5，第五球开出
                    1...开奖为龙，投注龙者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 小于 第五球（个位）的号码。如：第一球开出 2，第五球开出 6、第一球开出 6，第五球开出 8、第一球开出 1，第五球开出
                    5...开奖为虎，投注虎者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>和</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 等于 第五球（个位）的号码。如：2XXX2、6XXX6、8XXX8...开奖为和，投注和者视为中奖，其它视为不中奖。</div>
                <div style={styles.title2}>8、牛牛</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码（0-9）中，如果任意选取3个号码都不能求和为10的整数倍数，该期开奖结果为没牛。如果任意选取3个号码能够求和为10的整数倍数，则以剩余2个号码之和（大于10取减去10后的结果）作为该期牛数，根据牛数开奖结果分别为：牛1、牛2、牛3、牛4、牛5、牛6、牛7、牛8、牛9，牛10又称作牛牛。
                    如：开奖号码 0 0 0 2 6 为牛8，开奖号码 0 2 8 1 8 为牛9，开奖号码 6 8 6 2 8 和开奖号码 2 3 5 0 0 为牛牛，开奖号码 2 6 3 7 8 和开奖号码 1 5
                    2 8 6 为没牛。注：当五个开奖号码相同时，只有开奖号码 0 0 0 0 0 视为牛牛，其它开奖号码如 1 1 1 1 1，6 6 6 6 6 等皆视为没牛。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>
                <div style={styles.title3}>
                    当期牛数大小，牛1、牛2、牛3、牛4、牛5为小，牛6、牛7、牛8、牛9、牛牛为大。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛大、牛小皆视为未中奖）。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>单双</div>
                <div style={styles.title3}>
                    当期牛数单双，牛1、牛3、牛5、牛7、牛9为单，牛2、牛4、牛6、牛8、牛牛为双。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛单、牛双皆视为未中奖）。
                </div>
                <div style={styles.title2}>9、梭哈</div>
                <div style={{...styles.title3, ...styles.color1}}>五条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码都相同。 如：投注梭哈 五条，开奖号码 8 8 8 8 8 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>四条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有4个开奖号码相同。 如：投注梭哈 四条，开奖号码 6 8 6 6 6 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>葫芦</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由1组三条（3个号码相同）和1组对子（2个号码相同）组成。 如：投注梭哈 葫芦，开奖号码 3 5 3 3 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>顺子</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码数字相连（数字9、0、1相连），顺序不限。 如：投注梭哈 顺子，开奖号码 0 9 2 1 3 即为中奖，又如：投注梭哈 顺子，开奖号码 6 5 7 4 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>三条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码有且只有3个开奖号码相同，且剩余2个号码不同。 如：投注梭哈 三条，开奖号码 2 7 2 1 2 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>两对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由2组不同的对子（2个号码相同）组成。 如：投注梭哈 两对，开奖号码 1 8 5 1 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>一对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有2个开奖号码相同。 如：投注梭哈 一对，开奖号码 2 9 7 2 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>散号</div>
                <div style={styles.title3}>
                    除五条、四条、葫芦、三条、顺子、两对、一对以外的所有开奖号码。 如：投注梭哈 散号，开奖号码 2 3 1 8 6 即为中奖。
                </div>
                <div style={styles.title2}>10、百家乐</div>
                <div style={styles.title3}>百家乐游戏规则：百家乐是以开奖号码为基础，进行庄、闲对比的一种玩法！</div>
                <div style={styles.title3}>庄是指以开奖五个号码为基础，选择前两个数（万、千视为庄的前两张牌）。</div>
                <div style={styles.title3}>闲是指以开奖五个号码为基础，选择后两个数（十、个视为闲的前两张牌）。</div>
                <div style={styles.title3}>个位与百位合数的个位数，此张牌为第5张牌。万位与百位合数的个位数，此张牌为第6张牌</div>
                <div style={styles.title3}>如果闲家补牌，那么第5张牌给闲家，如果闲家不补牌，庄家补牌，那么第5张牌给庄家</div>
                <div style={styles.title3}>如第一轮未分出胜负需要再按牌例发第二轮牌，第5张牌闲先发，最多每方3张牌，谁最接近9点即为胜方</div>
                <div style={styles.title3}>有一个天生赢家或者两个都大等于6(庄闲都不补)</div>
                <div style={{...styles.title3, ...styles.color1}}>闲家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2-3-4-5 补一张牌</td>
                        <td style={styles.tdStyle}>即获得第5张牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>不需补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不需补牌</td>
                    </tr>
                </table>
                <div style={{...styles.title3, ...styles.color1}}>庄家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>头两张牌点数总和</td>
                        <td style={styles.tdStyle}>庄家补牌，如果第5张牌为以下......</td>
                        <td style={styles.tdStyle}>庄家不补牌，如果第5张牌为以下......</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、8、9</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>3</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、9</td>
                        <td style={styles.tdStyle}>8</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>4</td>
                        <td style={styles.tdStyle}>2、3、4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>5</td>
                        <td style={styles.tdStyle}>4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、2、3、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6</td>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>7</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不须补牌</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                </table>
                <div style={styles.title3}>庄家（万位千位）闲家（十位个位）两位数字相加后的个位数字相同即为打和
                    举例:开奖号码为（80444）庄点数为8点，闲点数为8点，点数相同，投注"和"者中奖。其余情形视为不中奖。
                </div>
                <div style={styles.title3}>大小/单双/质合，以庄闲的点数来进行判断结果。 0~4为小，5~9为大; 1、3、5、7、9为单，0、2、4、6、8为双;
                    2、3、5、7为质数，4、6、8、9为合数。 0和1既不是质数也不是合数
                </div>
                <div style={styles.title3}>庄对/闲对 是指两家首发(第一轮)的两张牌为对子,不含第三张。如：开奖55842，投庄对则中奖，投闲对不中奖</div>
            </div>
        )
    };
    /**
     * 天津时时彩
     * @returns {*}
     * @private
     */
    _renderTJSSC = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            tableSytle:{
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                marginLeft: 25,
                wordBreak: "break-all"
            },
            trStyle:{
                borderLeft:"1px solid #d2d2d2",
                borderTop:"1px solid #d2d2d2",
            },
            tdStyle:{
                borderRight:"1px solid #d2d2d2",
                borderBottom:"1px solid #d2d2d2",
                padding:"5px"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>天津时时彩游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"天津时时彩"完全同步，北京时间（GMT+8）每天白天从上午09:00:30开到晚上23:00:30，每20分鐘开一次奖,每天开奖42期。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第五球</div>
                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码 8，下注第一球为 8
                    者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>单双大小</div>

                <div style={styles.title3}>根据相应单项投注第一球 ~ 第五球开出的球号，判断胜负。</div>

                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号大于或等于 5 为特码大，小于或等于 4 为特码小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号为双数则为特码双，如 2、6；球号为单数则为特码单，如 1、3。</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值大于或等于 23 为总和大，小于或等于 22 为总和小。</div>

                <div style={{...styles.title3, ...styles.color1}}>单双</div>

                <div style={styles.title3}>根据相应单项投注的第一球 ~ 第五球开出的球号数字总和值是双数为总和双，数字总和值是单数为总和单。</div>

                <div style={styles.title2}>4、前三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相同。如中奖号码为：222XX、666XX、888XX...开奖号码的万位千位百位数字相同，则投注前三豹子者视为中奖，其它视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：123XX、901XX、321XX、798XX...开奖号码的万位千位百位数字相连，则投注前三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相同（不包括豹子）。如中奖号码为：001XX，288XX、696XX...开奖号码的万位千位百位有两位数字相同，则投注前三对子者视为中奖，其它视为不中奖。如果开奖号码为前三豹子，则前三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的万位千位百位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：125XX、540XX、390XX、160XX...开奖号码的万位千位百位有两位数字相连，则投注前三半顺者视为中奖，其它视为不中奖。如果开奖号码为前三顺子、前三对子，则前三半顺视为不中奖。如开奖号码为：123XX、901XX、556XX、233XX...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：157XX、268XX...开奖号码位数之间无关联性，则投注前三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>5、中三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相同。如中奖号码为：X222X、X666X、X888X...开奖号码的千位百位十位数字相同，则投注中三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：X123X、X901X、X321X、X798X...开奖号码的千位百位十位数字相连，则投注中三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相同（不包括豹子）。如中奖号码为：X001X，X288X、X696X...开奖号码的千位百位十位有两位数字相同，则投注中三对子者视为中奖，其它视为不中奖。如果开奖号码为中三豹子，则中三对子视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的千位百位十位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：X125X、X540X、X390X、X160X...开奖号码的千位百位十位有两位数字相连，则投注中三半顺者视为中奖，其它视为不中奖。如果开奖号码为中三顺子、中三对子，则中三半顺视为不中奖。如开奖号码为：X123X、X901X、X556X、X233X...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：X157X、X268X...开奖号码位数之间无关联性，则投注中三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖。
                </div>

                <div style={styles.title2}>6、后三特殊玩法： 豹子 > 顺子 > 对子 > 半顺 > 杂六</div>
                <div style={{...styles.title3, ...styles.color1}}>豹子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相同。如中奖号码为：XX222、XX666、XX888...开奖号码的百位十位个位数字相同，则投注后三豹子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>顺子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位数字都相连，不分顺序（数字9、0、1相连）。如中奖号码为：XX123、XX901、XX321、XX798...开奖号码的百位十位个位数字相连，则投注后三顺子者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>对子</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相同（不包括豹子）。如中奖号码为：XX001、XX288、XX696...开奖号码的百位十位个位有两位数字相同，则投注后三对子者视为中奖，其它视为不中奖。如果开奖号码为后三豹子，则后三对子视为不中奖
                </div>

                <div style={{...styles.title3, ...styles.color1}}>半顺</div>

                <div
                    style={styles.title3}>开奖号码的百位十位个位任意两位数字相连，不分顺序（不包括顺子、对子）。如中奖号码为：XX125、XX540、XX390、XX160...开奖号码的百位十位个位有两位数字相连，则投注后三半顺者视为中奖，其它视为不中奖。如果开奖号码为后三顺子、后三对子，则后三半顺视为不中奖。如开奖号码为：XX123、XX901、XX556、XX233...视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>杂六</div>

                <div
                    style={styles.title3}>不包括豹子、对子、顺子、半顺的所有开奖号码。如开奖号码为：XX157、XX268...开奖号码位数之间无关联性，则投注后三杂六者视为中奖，（数字9、0、1相连）其它视为不中奖
                </div>

                <div style={styles.title2}>7、龙虎和特殊玩法： 龙 > 虎 > 和 （0为最小，9为最大）</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 大于 第五球（个位）的号码。如：第一球开出 6，第五球开出 2、第一球开出 8，第五球开出 6、第一球开出 5，第五球开出
                    1...开奖为龙，投注龙者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 小于 第五球（个位）的号码。如：第一球开出 2，第五球开出 6、第一球开出 6，第五球开出 8、第一球开出 1，第五球开出
                    5...开奖为虎，投注虎者视为中奖，其它视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>和</div>

                <div style={styles.title3}>开奖第一球（万位）的号码 等于 第五球（个位）的号码。如：2XXX2、6XXX6、8XXX8...开奖为和，投注和者视为中奖，其它视为不中奖。</div>
                <div style={styles.title2}>8、牛牛</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码（0-9）中，如果任意选取3个号码都不能求和为10的整数倍数，该期开奖结果为没牛。如果任意选取3个号码能够求和为10的整数倍数，则以剩余2个号码之和（大于10取减去10后的结果）作为该期牛数，根据牛数开奖结果分别为：牛1、牛2、牛3、牛4、牛5、牛6、牛7、牛8、牛9，牛10又称作牛牛。
                    如：开奖号码 0 0 0 2 6 为牛8，开奖号码 0 2 8 1 8 为牛9，开奖号码 6 8 6 2 8 和开奖号码 2 3 5 0 0 为牛牛，开奖号码 2 6 3 7 8 和开奖号码 1 5
                    2 8 6 为没牛。注：当五个开奖号码相同时，只有开奖号码 0 0 0 0 0 视为牛牛，其它开奖号码如 1 1 1 1 1，6 6 6 6 6 等皆视为没牛。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>大小</div>
                <div style={styles.title3}>
                    当期牛数大小，牛1、牛2、牛3、牛4、牛5为小，牛6、牛7、牛8、牛9、牛牛为大。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛大、牛小皆视为未中奖）。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>单双</div>
                <div style={styles.title3}>
                    当期牛数单双，牛1、牛3、牛5、牛7、牛9为单，牛2、牛4、牛6、牛8、牛牛为双。若投注内容符合当期开奖结果，视为中奖，其余情形视为未中奖（若当期开奖结果为没牛，则投注牛单、牛双皆视为未中奖）。
                </div>
                <div style={styles.title2}>9、梭哈</div>
                <div style={{...styles.title3, ...styles.color1}}>五条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码都相同。 如：投注梭哈 五条，开奖号码 8 8 8 8 8 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>四条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有4个开奖号码相同。 如：投注梭哈 四条，开奖号码 6 8 6 6 6 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>葫芦</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由1组三条（3个号码相同）和1组对子（2个号码相同）组成。 如：投注梭哈 葫芦，开奖号码 3 5 3 3 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>顺子</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码数字相连（数字9、0、1相连），顺序不限。 如：投注梭哈 顺子，开奖号码 0 9 2 1 3 即为中奖，又如：投注梭哈 顺子，开奖号码 6 5 7 4 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>三条</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码有且只有3个开奖号码相同，且剩余2个号码不同。 如：投注梭哈 三条，开奖号码 2 7 2 1 2 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>两对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码由2组不同的对子（2个号码相同）组成。 如：投注梭哈 两对，开奖号码 1 8 5 1 5 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>一对</div>
                <div style={styles.title3}>
                    当期所有5个开奖号码中有且只有2个开奖号码相同。 如：投注梭哈 一对，开奖号码 2 9 7 2 3 即为中奖。
                </div>
                <div style={{...styles.title3, ...styles.color1}}>散号</div>
                <div style={styles.title3}>
                    除五条、四条、葫芦、三条、顺子、两对、一对以外的所有开奖号码。 如：投注梭哈 散号，开奖号码 2 3 1 8 6 即为中奖。
                </div>
                <div style={styles.title2}>10、百家乐</div>
                <div style={styles.title3}>百家乐游戏规则：百家乐是以开奖号码为基础，进行庄、闲对比的一种玩法！</div>
                <div style={styles.title3}>庄是指以开奖五个号码为基础，选择前两个数（万、千视为庄的前两张牌）。</div>
                <div style={styles.title3}>闲是指以开奖五个号码为基础，选择后两个数（十、个视为闲的前两张牌）。</div>
                <div style={styles.title3}>个位与百位合数的个位数，此张牌为第5张牌。万位与百位合数的个位数，此张牌为第6张牌</div>
                <div style={styles.title3}>如果闲家补牌，那么第5张牌给闲家，如果闲家不补牌，庄家补牌，那么第5张牌给庄家</div>
                <div style={styles.title3}>如第一轮未分出胜负需要再按牌例发第二轮牌，第5张牌闲先发，最多每方3张牌，谁最接近9点即为胜方</div>
                <div style={styles.title3}>有一个天生赢家或者两个都大等于6(庄闲都不补)</div>
                <div style={{...styles.title3, ...styles.color1}}>闲家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2-3-4-5 补一张牌</td>
                        <td style={styles.tdStyle}>即获得第5张牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>不需补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不需补牌</td>
                    </tr>
                </table>
                <div style={{...styles.title3, ...styles.color1}}>庄家头两张牌点数总和</div>
                <table style={styles.tableSytle}>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>头两张牌点数总和</td>
                        <td style={styles.tdStyle}>庄家补牌，如果第5张牌为以下......</td>
                        <td style={styles.tdStyle}>庄家不补牌，如果第5张牌为以下......</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>0-1-2</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、8、9</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>3</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、6、7、9</td>
                        <td style={styles.tdStyle}>8</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>4</td>
                        <td style={styles.tdStyle}>2、3、4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>5</td>
                        <td style={styles.tdStyle}>4、5、6、7</td>
                        <td style={styles.tdStyle}>0、1、2、3、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>6</td>
                        <td style={styles.tdStyle}>6或7</td>
                        <td style={styles.tdStyle}>0、1、2、3、4、5、8、9</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>7</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                        <td style={styles.tdStyle}>不须补牌</td>
                    </tr>
                    <tr style={styles.trStyle}>
                        <td style={styles.tdStyle}>8或9</td>
                        <td style={styles.tdStyle}>天生赢家，不须补牌</td>
                        <td style={styles.tdStyle}></td>
                    </tr>
                </table>
                <div style={styles.title3}>庄家（万位千位）闲家（十位个位）两位数字相加后的个位数字相同即为打和
                    举例:开奖号码为（80444）庄点数为8点，闲点数为8点，点数相同，投注"和"者中奖。其余情形视为不中奖。
                </div>
                <div style={styles.title3}>大小/单双/质合，以庄闲的点数来进行判断结果。 0~4为小，5~9为大; 1、3、5、7、9为单，0、2、4、6、8为双;
                    2、3、5、7为质数，4、6、8、9为合数。 0和1既不是质数也不是合数
                </div>
                <div style={styles.title3}>庄对/闲对 是指两家首发(第一轮)的两张牌为对子,不含第三张。如：开奖55842，投庄对则中奖，投闲对不中奖</div>
            </div>
        )
    };
    /**
     * 重庆快乐十分
     * @returns {*}
     * @private
     */
    _renderCQKLSF = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>重庆快乐十分游戏规则</div>
                <div
                    style={styles.title2}>1、每期重庆快乐十分开奖球数共八粒。每粒球除了总和玩法，其它都有单独的投注页面。重庆快乐十分每天开59期，每期间隔20分钟。投注时间为18分钟，等待开奖时间为2分钟，北京时间（GMT+8）每天白天从上午07：10开到晚上23：30，开奖50期，00：10-02：50，开奖9期。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第八球 、两面、中发白、方位</div>
                <div style={{...styles.title3, ...styles.color1}}>第一球 ~ 第八球</div>

                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球、第六球、第七球、第八球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码
                    8，下注第一球为 8 者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>两面</div>

                <div style={styles.title3}>指单、双；大、小、尾大、尾小。</div>
                <div style={styles.title3}>单、双：号码为双数叫双，如8、16；号码为单数叫单，如19、5。</div>
                <div style={styles.title3}>大、小：开出之号码大于或等于11为大，小于或等于10为小。</div>
                <div style={styles.title3}>尾大、尾小：开出之尾数大于或等于5为尾大，小于或等于4为尾小。</div>
                <div style={styles.title3}>合数单双：正码个位和十位数字之和来判断胜负，如01、12、16为和单；02、11、20为和双 。</div>
                <div style={styles.title3}>每一个号码为一投注组合，假如投注号码为开奖号码并在所投的球位置，视为中奖，其余情形视为不中奖。</div>

                <div style={{...styles.title3, ...styles.color1}}>中发白</div>

                <div style={styles.title3}>中：开出之号码为01、02、03、04、05、06、07</div>
                <div style={styles.title3}>发：开出之号码为08、09、10、11、12、13、14</div>
                <div style={styles.title3}>白：开出之号码为15、16、17、18、19、20</div>

                <div style={{...styles.title3, ...styles.color1}}>方位</div>

                <div style={styles.title3}>东：开出之号码为01、05、09、13、17</div>
                <div style={styles.title3}>南：开出之号码为02、06、10、14、18</div>
                <div style={styles.title3}>西：开出之号码为03、07、11、15、19</div>
                <div style={styles.title3}>北：开出之号码为04、08、12、16、20</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>总和单双</div>

                <div
                    style={styles.title3}>所有8个开奖号码的数字总和值是单数为总和单，如数字总和值是31、51；所有8个开奖号码的数字总和值是双数为总和双，如数字总和是42、80；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>总和大小</div>

                <div
                    style={styles.title3}>所有8个开奖号码的数字总和值85到132为总大；所有8个开奖号码的数字总和值36到83为总分小；所有8个开奖号码的数字总和值为84打和；如开奖号码为01、20、02、08、17、09、11，数字总和是68，则总分小。假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖，打和不计算结果。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>总尾大小</div>

                <div style={styles.title3}>所有8个开奖号码的数字总合数值的个位数大于或等于5为总尾大，小于或等于4为总尾小；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title2}>4、龙虎</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开出之号码第一球的中奖号码大于第八球的中奖号码。如 第一球开出14 第八球开出09；第一球开出17 第八球开出08；第一球开出05
                    第八球开出01...中奖为龙。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开出之号码第一球的中奖号码小于第八球的中奖号码。如 第一球开出14 第八球开出16；第一球开出13 第八球开出18；第一球开出05
                    第八球开出08...中奖为虎。
                </div>
            </div>
        )
    };
    /**
     * 重庆快乐十分
     * @returns {*}
     * @private
     */
    _renderSFKLSF = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>{this.title}游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏每天00:00开到晚上23:59，每{this.min}分鐘开一次奖,每天开奖{this.count}期。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第八球 、两面、中发白、方位</div>
                <div style={{...styles.title3, ...styles.color1}}>第一球 ~ 第八球</div>

                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球、第六球、第七球、第八球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码
                    8，下注第一球为 8 者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>两面</div>

                <div style={styles.title3}>指单、双；大、小、尾大、尾小。</div>
                <div style={styles.title3}>单、双：号码为双数叫双，如8、16；号码为单数叫单，如19、5。</div>
                <div style={styles.title3}>大、小：开出之号码大于或等于11为大，小于或等于10为小。</div>
                <div style={styles.title3}>尾大、尾小：开出之尾数大于或等于5为尾大，小于或等于4为尾小。</div>
                <div style={styles.title3}>合数单双：正码个位和十位数字之和来判断胜负，如01、12、16为和单；02、11、20为和双 。</div>
                <div style={styles.title3}>每一个号码为一投注组合，假如投注号码为开奖号码并在所投的球位置，视为中奖，其余情形视为不中奖。</div>

                <div style={{...styles.title3, ...styles.color1}}>中发白</div>

                <div style={styles.title3}>中：开出之号码为01、02、03、04、05、06、07</div>
                <div style={styles.title3}>发：开出之号码为08、09、10、11、12、13、14</div>
                <div style={styles.title3}>白：开出之号码为15、16、17、18、19、20</div>

                <div style={{...styles.title3, ...styles.color1}}>方位</div>

                <div style={styles.title3}>东：开出之号码为01、05、09、13、17</div>
                <div style={styles.title3}>南：开出之号码为02、06、10、14、18</div>
                <div style={styles.title3}>西：开出之号码为03、07、11、15、19</div>
                <div style={styles.title3}>北：开出之号码为04、08、12、16、20</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>总和单双</div>

                <div
                    style={styles.title3}>所有8个开奖号码的数字总和值是单数为总和单，如数字总和值是31、51；所有8个开奖号码的数字总和值是双数为总和双，如数字总和是42、80；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>总和大小</div>

                <div
                    style={styles.title3}>所有8个开奖号码的数字总和值85到132为总大；所有8个开奖号码的数字总和值36到83为总分小；所有8个开奖号码的数字总和值为84打和；如开奖号码为01、20、02、08、17、09、11，数字总和是68，则总分小。假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖，打和不计算结果。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>总尾大小</div>

                <div style={styles.title3}>所有8个开奖号码的数字总合数值的个位数大于或等于5为总尾大，小于或等于4为总尾小；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title2}>4、龙虎</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开出之号码第一球的中奖号码大于第八球的中奖号码。如 第一球开出14 第八球开出09；第一球开出17 第八球开出08；第一球开出05
                    第八球开出01...中奖为龙。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开出之号码第一球的中奖号码小于第八球的中奖号码。如 第一球开出14 第八球开出16；第一球开出13 第八球开出18；第一球开出05
                    第八球开出08...中奖为虎。
                </div>
            </div>
        )
    };
    /**
     * 广东快乐十分
     * @returns {*}
     * @private
     */
    _renderGDKLSF = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>广东快乐十分游戏规则</div>
                <div
                    style={styles.title2}>1、每期广东快乐十分开奖球数共八粒。每粒球除了总和玩法，其它都有单独的投注页面。广东快乐十分每天开42期，每期间隔20分钟。投注时间为18分钟，等待开奖时间为2分钟，北京时间（GMT+8）每天白天从上午09：20开到晚上23：00。
                </div>
                <div style={styles.title2}>2、第一球 ~ 第八球 、两面、中发白、方位</div>
                <div style={{...styles.title3, ...styles.color1}}>第一球 ~ 第八球</div>

                <div style={styles.title3}>第一球、第二球、第三球、第四球、第五球、第六球、第七球、第八球：指下注的每一球特码与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码
                    8，下注第一球为 8 者视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>两面</div>

                <div style={styles.title3}>指单、双；大、小、尾大、尾小。</div>
                <div style={styles.title3}>单、双：号码为双数叫双，如8、16；号码为单数叫单，如19、5。</div>
                <div style={styles.title3}>大、小：开出之号码大于或等于11为大，小于或等于10为小。</div>
                <div style={styles.title3}>尾大、尾小：开出之尾数大于或等于5为尾大，小于或等于4为尾小。</div>
                <div style={styles.title3}>合数单双：正码个位和十位数字之和来判断胜负，如01、12、16为和单；02、11、20为和双 。</div>
                <div style={styles.title3}>每一个号码为一投注组合，假如投注号码为开奖号码并在所投的球位置，视为中奖，其余情形视为不中奖。</div>

                <div style={{...styles.title3, ...styles.color1}}>中发白</div>

                <div style={styles.title3}>中：开出之号码为01、02、03、04、05、06、07</div>
                <div style={styles.title3}>发：开出之号码为08、09、10、11、12、13、14</div>
                <div style={styles.title3}>白：开出之号码为15、16、17、18、19、20</div>

                <div style={{...styles.title3, ...styles.color1}}>方位</div>

                <div style={styles.title3}>东：开出之号码为01、05、09、13、17</div>
                <div style={styles.title3}>南：开出之号码为02、06、10、14、18</div>
                <div style={styles.title3}>西：开出之号码为03、07、11、15、19</div>
                <div style={styles.title3}>北：开出之号码为04、08、12、16、20</div>

                <div style={styles.title2}>3、总和</div>
                <div style={{...styles.title3, ...styles.color1}}>总和单双</div>

                <div
                    style={styles.title3}>所有8个开奖号码的数字总和值是单数为总和单，如数字总和值是31、51；所有8个开奖号码的数字总和值是双数为总和双，如数字总和是42、80；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>总和大小</div>

                <div
                    style={styles.title3}>所有8个开奖号码的数字总和值85到132为总大；所有8个开奖号码的数字总和值36到83为总分小；所有8个开奖号码的数字总和值为84打和；如开奖号码为01、20、02、08、17、09、11，数字总和是68，则总分小。假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖，打和不计算结果。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>总尾大小</div>

                <div style={styles.title3}>所有8个开奖号码的数字总合数值的个位数大于或等于5为总尾大，小于或等于4为总尾小；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。</div>

                <div style={styles.title2}>4、龙虎</div>
                <div style={{...styles.title3, ...styles.color1}}>龙</div>

                <div style={styles.title3}>开出之号码第一球的中奖号码大于第八球的中奖号码。如 第一球开出14 第八球开出09；第一球开出17 第八球开出08；第一球开出05
                    第八球开出01...中奖为龙。
                </div>

                <div style={{...styles.title3, ...styles.color1}}>虎</div>

                <div style={styles.title3}>开出之号码第一球的中奖号码小于第八球的中奖号码。如 第一球开出14 第八球开出16；第一球开出13 第八球开出18；第一球开出05
                    第八球开出08...中奖为虎。
                </div>
            </div>
        )
    };
    /**
     * 广西快三
     * @returns {*}
     * @private
     */
    _renderGXKS = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>广西快三游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"广西快三"完全同步，北京时间（GMT+8）每天白天从上午09:10:00开到晚上22:30:00，每20分鐘开一次奖,每天开奖40期。每期开出3个号码、每个号码范围01~06
                    。
                </div>
                <div style={styles.title2}>2、和值</div>
                <div style={styles.title3}>以全部开出的三个号码、加起来的总和来判定。</div>
                <div style={styles.title3}>开奖号码总和值为3、4、5、6、7、8、9、10、11、12、13、14、15、16、17 、18时，即为中奖；</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、总和值为6、则投注「6」即为中奖。</div>
                <div style={styles.title3}>大小：三个开奖号码总和值11~17 为大；总和值4~10 为小；若三个号码相同、则不算中奖。</div>
                <div style={styles.title3}>单双：三个开奖号码总和5、7、9、11、13、15、17为单；4、6、8、10、12、14、16、18为双；若三个号码相同、则不算中奖。</div>
                <div style={styles.title2}>3、独胆</div>
                <div style={styles.title3}>三个开奖号码其中一个与所选号码相同时、即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3，则投注独胆1或独胆3皆视为中奖。</div>
                <div style={styles.title3}>备注：不论当局指定点数出现几次，仅派彩一次(不翻倍)。</div>
                <div style={styles.title2}>4、豹子</div>
                <div style={styles.title3}>豹子：开奖号码三字同号、且与所选择的豹子组合相符时，即为中奖。</div>
                <div style={styles.title3}>任意豹子：任意豹子组合、开奖号码三字同号，即为中奖。</div>

                <div style={styles.title2}>5、两连</div>
                <div style={styles.title3}>任选一长牌组合、当开奖结果任2码与所选组合相同时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、则投注两连12、两连23、两连13皆视为中奖。</div>

                <div style={styles.title2}>6、对子</div>
                <div style={styles.title3}>开奖号码任两字同号、且与所选择的对子组合相符时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3、则投注对子1、1，即为中奖。</div>
            </div>
        )
    };
    /**
     * 安徽快三
     * @returns {*}
     * @private
     */
    _renderAHKS = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>安徽快三游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"安徽快三"完全同步，北京时间（GMT+8）每天白天从上午08:30:00开到晚上21:50:00，每20分鐘开一次奖,每天开奖40期。每期开出3个号码、每个号码范围01~06
                    。
                </div>
                <div style={styles.title2}>2、和值</div>
                <div style={styles.title3}>以全部开出的三个号码、加起来的总和来判定。</div>
                <div style={styles.title3}>开奖号码总和值为3、4、5、6、7、8、9、10、11、12、13、14、15、16、17 、18时，即为中奖；</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、总和值为6、则投注「6」即为中奖。</div>
                <div style={styles.title3}>大小：三个开奖号码总和值11~17 为大；总和值4~10 为小；若三个号码相同、则不算中奖。</div>
                <div style={styles.title3}>单双：三个开奖号码总和5、7、9、11、13、15、17为单；4、6、8、10、12、14、16、18为双；若三个号码相同、则不算中奖。</div>
                <div style={styles.title2}>3、独胆</div>
                <div style={styles.title3}>三个开奖号码其中一个与所选号码相同时、即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3，则投注独胆1或独胆3皆视为中奖。</div>
                <div style={styles.title3}>备注：不论当局指定点数出现几次，仅派彩一次(不翻倍)。</div>
                <div style={styles.title2}>4、豹子</div>
                <div style={styles.title3}>豹子：开奖号码三字同号、且与所选择的豹子组合相符时，即为中奖。</div>
                <div style={styles.title3}>任意豹子：任意豹子组合、开奖号码三字同号，即为中奖。</div>

                <div style={styles.title2}>5、两连</div>
                <div style={styles.title3}>任选一长牌组合、当开奖结果任2码与所选组合相同时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、则投注两连12、两连23、两连13皆视为中奖。</div>

                <div style={styles.title2}>6、对子</div>
                <div style={styles.title3}>开奖号码任两字同号、且与所选择的对子组合相符时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3、则投注对子1、1，即为中奖。</div>
            </div>
        )
    };
    /**
     * 江苏快三
     * @returns {*}
     * @private
     */
    _renderJSKS = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>江苏快三游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏的投注时间、开奖时间和开奖号码与"江苏快三"完全同步，北京时间（GMT+8）每天白天从上午08:30:00开到晚上22:10:00，每20分鐘开一次奖,每天开奖41期。每期开出3个号码、每个号码范围01~06
                    。
                </div>
                <div style={styles.title2}>2、和值</div>
                <div style={styles.title3}>以全部开出的三个号码、加起来的总和来判定。</div>
                <div style={styles.title3}>开奖号码总和值为3、4、5、6、7、8、9、10、11、12、13、14、15、16、17 、18时，即为中奖；</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、总和值为6、则投注「6」即为中奖。</div>
                <div style={styles.title3}>大小：三个开奖号码总和值11~17 为大；总和值4~10 为小；若三个号码相同、则不算中奖。</div>
                <div style={styles.title3}>单双：三个开奖号码总和5、7、9、11、13、15、17为单；4、6、8、10、12、14、16、18为双；若三个号码相同、则不算中奖。</div>
                <div style={styles.title2}>3、独胆</div>
                <div style={styles.title3}>三个开奖号码其中一个与所选号码相同时、即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3，则投注独胆1或独胆3皆视为中奖。</div>
                <div style={styles.title3}>备注：不论当局指定点数出现几次，仅派彩一次(不翻倍)。</div>
                <div style={styles.title2}>4、豹子</div>
                <div style={styles.title3}>豹子：开奖号码三字同号、且与所选择的豹子组合相符时，即为中奖。</div>
                <div style={styles.title3}>任意豹子：任意豹子组合、开奖号码三字同号，即为中奖。</div>

                <div style={styles.title2}>5、两连</div>
                <div style={styles.title3}>任选一长牌组合、当开奖结果任2码与所选组合相同时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、则投注两连12、两连23、两连13皆视为中奖。</div>

                <div style={styles.title2}>6、对子</div>
                <div style={styles.title3}>开奖号码任两字同号、且与所选择的对子组合相符时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3、则投注对子1、1，即为中奖。</div>
            </div>
        )
    };

    /**
     * 三分快三
     * @returns {*}
     * @private
     */
    _renderSFKS = () => {
        const styles = {
            container: {
                padding: 10
            },
            title1: {
                color: "#000",
                fontSize: 18,
                marginTop: 10,
                textAlign: "center",
                fontWeight: "bold"
            },
            title2: {
                color: "#000",
                fontSize: 14,
                marginTop: 10,
                fontWeight: "bold"
            },
            title3: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                paddingLeft: 25,
                wordBreak: "break-all"
            },
            title4: {
                color: "rgba(0,0,0,0.7)",
                lineHeight: "24px",
                fontSize: 14,
                marginTop: 5,
                wordBreak: "break-all"
            },
            color1: {
                color: "red"
            },
            color2: {
                color: "blue"
            },
            tbContainer: {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRight: "1px solid #ddd",
                borderTop: "1px solid #ddd",
                marginLeft: 25,
                marginTop: 10
            },
            tbRow: {
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row"
            },
            tbColumn: {
                borderLeft: "1px solid #ddd",
                padding: 10
            },
        };
        return (
            <div style={styles.container}>
                <div style={styles.title1}>{this.title}游戏规则</div>
                <div
                    style={styles.title2}>1、该游戏每天00:00开到晚上23:59，每{this.min}分鐘开一次奖,每天开奖{this.count}期。
                </div>
                <div style={styles.title2}>2、和值</div>
                <div style={styles.title3}>以全部开出的三个号码、加起来的总和来判定。</div>
                <div style={styles.title3}>开奖号码总和值为3、4、5、6、7、8、9、10、11、12、13、14、15、16、17 、18时，即为中奖；</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、总和值为6、则投注「6」即为中奖。</div>
                <div style={styles.title3}>大小：三个开奖号码总和值11~17 为大；总和值4~10 为小；若三个号码相同、则不算中奖。</div>
                <div style={styles.title3}>单双：三个开奖号码总和5、7、9、11、13、15、17为单；4、6、8、10、12、14、16、18为双；若三个号码相同、则不算中奖。</div>
                <div style={styles.title2}>3、独胆</div>
                <div style={styles.title3}>三个开奖号码其中一个与所选号码相同时、即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3，则投注独胆1或独胆3皆视为中奖。</div>
                <div style={styles.title3}>备注：不论当局指定点数出现几次，仅派彩一次(不翻倍)。</div>
                <div style={styles.title2}>4、豹子</div>
                <div style={styles.title3}>豹子：开奖号码三字同号、且与所选择的豹子组合相符时，即为中奖。</div>
                <div style={styles.title3}>任意豹子：任意豹子组合、开奖号码三字同号，即为中奖。</div>

                <div style={styles.title2}>5、两连</div>
                <div style={styles.title3}>任选一长牌组合、当开奖结果任2码与所选组合相同时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、2、3、则投注两连12、两连23、两连13皆视为中奖。</div>

                <div style={styles.title2}>6、对子</div>
                <div style={styles.title3}>开奖号码任两字同号、且与所选择的对子组合相符时，即为中奖。</div>
                <div style={styles.title3}>举例：如开奖号码为1、1、3、则投注对子1、1，即为中奖。</div>
            </div>
        )
    };


    render() {
        return (
            <Container title={this.title} style={{backgroundColor: "#fff"}}>
                {
                    this._renderInfo()
                }
            </Container>
        )
    }
}
