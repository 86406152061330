//登录

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import style from '../../../utils/config.scss'
import Container from '../../../component/container'
import MyButton from '../../../component/myButton'
import MyInput from '../../../component/myInput'
import Icon from '../../../utils/icon'
import Http from "../../../services/http";
import PublicUtils from '../../../utils/publicUtils'
import global from '../../../global'
import model from "../../../services/model";
import sha1 from "../../../services/sha1";
import {JSEncrypt} from 'jsencrypt'
import Storage from "../../../services/storage";

class Default extends Component {
    constructor(props) {
        super(props);



        this.state = {
            type: 1,
            form: {
                username: "",
                password: ""
            },
            tips: "",
            enableLoginBtn: false,
            showProgress: false,
            savePwd: true,
            verifyImg: null,
            PHPSESSID: null,
            is_verify: null,
            wzmm:true, // 忘记密码
        };
        this.nextPage = PublicUtils.getState(this.props.location.pathname).nextPage; // 用户进入登陆前点击的页面
        this.tips = PublicUtils.getState(this.props.location.pathname).tips;
        // alert(JSON.stringify(data));
        // 自动登陆
        if(global.ID == 'yy' && Storage.retrieveData("zczt")){
            let tempObj = JSON.parse(Storage.retrieveData("zczt"));
            this.state.form.username = tempObj.uname;
            this.state.form.password = tempObj.upwd;

            // 清空
            Storage.storeData({zczt: ''});

            this._onLogin();

        }

        if(global.ID == "br" || global.ID == "ar" || global.ID == "bw" || global.ID == "ct" || global.ID == "y7" || global.ID == 'ca'){
            this.state.wzmm = false;
        }
    }

    componentDidMount() {
        this._fetchVerify();
    }

    componentWillUnmount() {
    }


    /**
     * 获取图形验证码
     * @private
     */
    _fetchVerify = () => {
        let parm = {}
        Http.request('verify', parm, (response) => {
            if (response.code == 200) {
                this.PHPSESSID = response.data.PHPSESSID;
                this.setState({
                    is_verify: response.data.is_verify,
                    // PHPSESSID: response.data.PHPSESSID,
                    verifyImg: decodeURI(response.data.verify),
                    tn_code: decodeURI(response.data.tn_code),
                });
                const option = {
                    url: decodeURI(response.data.tn_code),
                    onBlockend: this._onLogin,
                    onRefresh: this._fetchVerify
                }
                window.tncode.init(option);
            }
        })
    }

    /**
     * 表单验证
     * */
    _checkForm = (data) => {
        this.setState(Object.assign(this.state.form, data), () => {
            // if (!model.inputRule.username.rule.test(this.state.form.username)) {
            //     this.setState({enableLoginBtn: false, tips: model.inputRule.username.ruleMsg});
            //     return;
            // }
            // if (!model.inputRule.password.rule.test(this.state.form.password)) {
            //     this.setState({enableLoginBtn: false, tips: model.inputRule.password.ruleMsg});
            //     return;
            // }
            // if (this.state.form.username.length < 2 || this.state.form.username.length > 8) {
            //     this.setState({enableLoginBtn: false, tips: "请输入2~8位数字或字母的用户名"});
            //     return;
            // }
            // if (this.state.form.password.length < 6 || this.state.form.password.length > 12) {
            //     this.setState({enableLoginBtn: false, tips: "请输入6~12位数字或字母的密码"});
            //     return;
            // }
            this.setState({enableLoginBtn: true, tips: ""});
        });
    };
    /**
     * 登录
     * */
    _onLogin = () => {
        this.setState({showProgress: true});
        let timestamp = new Date().getTime();
        let param = JSON.stringify({
            user_account: this.state.form.username,
            login_password: this.state.form.password,
            timestamp: timestamp
        });
        // param = rsa_encode(param);
        // param = encodeURIComponent(param)
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(model.public_key);
        param = encrypt.encrypt(param); //password为需要加密的字段
        // param = encodeURIComponent(param);
        let parm = {
            device: global.DEVICE,
            // user_account: this.state.form.username,
            // login_password: this.state.form.password,
            signature: sha1([this.state.form.username, this.state.form.password, timestamp, '', global.DEVICE].sort().toString()),
            param: param
        }
        if (this.state.is_verify) {
            parm.PHPSESSID = this.PHPSESSID;
            // parm.code = this.state.form.code;
            parm.code = Math.round(window.tncode._mark_offset);
            parm.signature = sha1([this.state.form.username, this.state.form.password, timestamp, Math.round(window.tncode._mark_offset), global.DEVICE].sort().toString())
        }
        Http.request('login', parm, (response) => {
            this.setState({showProgress: false});
            // window.tncode._send_result_success(response);
            if (this.state.is_verify) {
                if (response.code == 200) {
                    window.tncode._send_result_success(response);
                } else {
                    window.tncode._send_result_failure(response);
                }
            }
            if (response.data.status === 1) {
                if (response.data.user.lastmsg) {
                    if(global.ID !== 'yy'){
                        PublicUtils.showToast(response.data.user.lastmsg.is_content, {duration: 10000});
                    }
                }
                if (response.data.token) {
                    response.data.isSavePwd = this.state.savePwd;
                    if (this.isSavePwd) {
                        response.data.userName = this.state.form.username;
                        response.data.passWord = this.state.form.password;
                    }

                    // y8处理
                    if(global.ID == 'y8'){
                        global.syObj.setState({usedl:true});
                        response.data.user.head_img = "https://appnew.hdyb33.com/code/y8/touxiang.png";
                    }

                    // b8处理
                    if(global.ID == 'b8'){
                        global.syObj.setState({usedl:true});
                        response.data.user.head_img = "https://appnew.hdyb33.com/code/b8/touxiang.png";
                    }

                    PublicUtils.saveUserInfo(response.data);
                }
                if (this.nextPage) {

                    // y8处理
                    if(global.ID == 'yy'){
                        PublicUtils.navigate("Home");
                    }else{
                        PublicUtils.replaceNavigate(this.nextPage);
                    }


                } else {
                    PublicUtils.navigate("Home");
                }
            } else {
                // 重新加载验证码
                this._fetchVerify();
                PublicUtils.showToast(response.data.message);
            }
        });
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        // return <ActivityIndicator size="small" color={'#fff'}
        //                           style={{marginLeft: 5, position: "absolute"}}/>;
    };

    _fetchTncode = () => {
        if (!this.state.form.username) {
            PublicUtils.showToast("请输入用户名");
            return;
        }
        if (!this.state.form.password) {
            PublicUtils.showToast("请输入密码");
            return;
        }
        window.tncode.show();
        // window.tncode._img.src = decodeURI(this.state.tn_code);
        // window.tncode.onsuccess=()=>{
        //     alert(11);
        // }
        // Http.request('tnCode', {}, (response) => {
        //     console.log(response);
        //     window.tncode._img.src = response.data.verify;
        // });
    }

    render() {
        const styles = {
            container: {
                padding: 10,
                backgroundColor: "#fff"
            },
            welcome: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 20,
                paddingBottom: 20,
            },
            welcomeTips: {
                fontSize: 24
            },
            body: {
                marginTop: 40,
                flexDirection: "column"
            },
            item: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #f5f5f5",
                height: 50
            },
            itemLeft: {
                display: "flex",
                width: 40,
                alignItems: "center",
                justifyContent: "center"
            },
            itemRight: {
                display: "flex",
                flexDirection: "row",
                flex: 1
            },
            textIpt: {
                textAlign: "left",
                fontSize: 15,
                height: "auto"
            },
            yzmBtn: {
                borderLeftColor: "#f5f5f5",
                borderLeftWidth: 1,
                padding: 10
            },
            btnContainer: {
                display: "flex",
                marginTop: 30,
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
            },
            btnBox: {
                display: "flex",
                flex: 1,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderRadius: 3
            },
            btnText: {
                color: "#fff",
                fontSize: 16
            },
            showTab: {
                display: "flex"
            },
            hideTab: {
                display: "none"
            },
            bottomBar: {
                marginTop: 50,
            },
            bottomBarHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            bottomBarHeaderLeft: {
                display: "flex",
                flex: 1,
                height: 1,
                borderBottomColor: "#f5f5f5",
                borderBottomWidth: 1
            },
            bottomBarHeaderText: {
                paddingLeft: 10,
                paddingRight: 10,
                color: "#999"
            },
            bottomBarHeaderRight: {
                display: "flex",
                flex: 1,
                height: 1,
                borderBottomColor: "#f5f5f5",
                borderBottomWidth: 1
            },
            bottomBarBody: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            bottomBarBodyIcon: {
                display: "flex",
                width: 50,
                height: 50,
                borderRadius: 50,
                marginTop: 20,
                marginLeft: 20,
                marginRight: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            color1: {
                backgroundColor: "#bf7c28",
            },
            color2: {
                backgroundColor: "#c66457",
            },
            color3: {
                backgroundColor: "#3eab42",
            },
            regTip: {
                marginTop: 10,
                paddingLeft: 10
            },
            regTipText: {
                color: "#ff4222"
            },
            disabledBtnText: {},
            disabledBtn: {
                opacity: 0.6
            },
            switchBox: {
                display: "flex",
                marginTop: 15,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            bottomBtnBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            bottomBtnItemBox: {
                marginTop: 20
            },
            bottomBtnItemText: {
                fontSize: 16
            },
            loginTips: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                height: 30,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            },
            loginTipsText: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            }
        };
        return (
            <Container tabIndex={1} title={'登录'} style={{background: "#fff"}}>
                {this.tips &&
                <div style={styles.loginTips}>
                    <Icon name={'tips'} color={'#fff'} size={15}/>
                    <div style={styles.loginTipsText}>{this.tips}</div>
                </div>
                }
                <div style={styles.container}>
                    <div style={{...styles.body, ...this.state.type === 1 ? styles.showTab : styles.hideTab}}>
                        <div style={styles.item}>
                            <div style={styles.itemLeft}>
                                <Icon icon="user" size={18} color="#999"/>
                            </div>
                            <div style={styles.itemRight}>
                                <MyInput placeholder={'请输入账号'} onChangeText={(text) => {
                                    this._checkForm({username: text})
                                }}/>
                                {/*<divInput placeholder="请输入手机号码或邮箱" style={styles.textIpt} textContentType={'username'}*/}
                                {/*onChangeText={(text) => this._checkForm({username: text})}/>*/}
                            </div>
                        </div>
                        <div style={styles.item}>
                            <div style={styles.itemLeft}>
                                <Icon icon={'new-password'} size={18} color="#999"/>
                            </div>
                            <div style={styles.itemRight}>
                                <MyInput type={'password'} placeholder={'请输入密码'} onChangeText={(text) => {
                                    this._checkForm({password: text})
                                }}/>
                            </div>
                        </div>
                        {/*{*/}
                        {/*    this.state.is_verify ?*/}
                        {/*        <div style={styles.item}>*/}
                        {/*            <div style={styles.itemLeft}>*/}
                        {/*                <Icon icon={'new-question'} size={18} color="#999"/>*/}
                        {/*            </div>*/}
                        {/*            <div style={styles.itemRight}>*/}
                        {/*                <MyInput type={'text'} style={styles.textIpt} placeholder={'请输入图形验证码'}*/}
                        {/*                         onChangeText={(text) => {*/}
                        {/*                             this._checkForm({code: text})*/}
                        {/*                         }}/>*/}
                        {/*                <img onClick={() => {*/}
                        {/*                    this._fetchVerify();*/}
                        {/*                }} src={this.state.verifyImg} style={{width: 100, height: 50}}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        : null*/}
                        {/*}*/}
                    </div>

                    <div style={styles.regTip}>
                        <div style={styles.regTipText}>{this.state.tips}</div>
                    </div>
                    <div style={styles.btnContainer}>
                        <MyButton className={'tncode'} title={'立即登录'}
                                  showProgress={this.state.showProgress}
                                  disabled={!this.state.enableLoginBtn || this.state.showProgress}
                                  onPress={() => {
                                      if (this.state.is_verify) {
                                          this._fetchTncode();
                                      } else {
                                          this._onLogin();
                                      }
                                      // this._onLogin();
                                  }}/>
                    </div>
                    {/*<div style={styles.switchBox}>*/}
                    {/*<div style={{marginRight: 10}}>记住密码</div>*/}
                    {/*<SwitchBtn onTintColor='@onTintColor' tintColor='#ddd' thumbTintColor={'@thumbTintColor'}*/}
                    {/*value={this.state.savePwd}*/}
                    {/*onValueChange={(value) => {*/}
                    {/*this.setState({*/}
                    {/*savePwd: value*/}
                    {/*})*/}
                    {/*}}/>*/}
                    {/*</div>*/}
                    <div style={styles.bottomBtnBox}>
                        <div style={styles.bottomBtnItemBox} onClick={() => {
                            PublicUtils.navigate('Register');
                        }}>
                            <div style={styles.bottomBtnItemText}>没有账号？去注册</div>
                        </div>
                        {
                            this.state.wzmm ?
                                <div style={styles.bottomBtnItemBox} onClick={() => {
                                    if (global.ID === "aw") {
                                        PublicUtils.linking(model.serviceUrl, "_blank");
                                    } else {
                                        PublicUtils.navigate('FindPasswordAccoun');
                                    }
                                }}>
                                    <div style={styles.bottomBtnItemText}>忘记密码？</div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </Container>
        )
    }
}

export default withRouter(Default);
