//个人中心

import React, {Component} from 'react';
import PublicUtils from '../../../utils/publicUtils'
import Container from '../../../component/container'
import Image from '../../../component/image'
import Header from '../../../component/header'
import Avatar from '../../../component/avatar'
import Navigate from '../../../component/navigate'
import Http from "../../../services/http";
import Storage from "../../../services/storage";
import style from '../../../utils/config.scss'
import model from "../../../services/model";
import global from "../../../global";
import FloatBtn from '../../../component/floatBtn'
import Icon from '../../../utils/icon'
import ActivityIndicator from '../../../component/activityIndicator'
import Background from "../../../assets/images/login-bg.jpg";
import DrawerPage from "../../../component/drawerPage";

class Default extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_name: {},
            user_account: {},
            user_birthday: {},
            user_phone: {},
            head_img: {},
            user_mail: {},
            user_qq: {},
            user_question: {},
            user_answer: {},
            is_agent: {},
            grade_name: {},
            is_balance: {},
            // is_integral: {value: 0},
            msg_num: {value: 0},
            sub_num: {value: 0},
            showBirthHandsel: false,
            is_get_birth_handsel: {value: 0},
            reg_time: {},
            agent_open:{value:0},
            qds:1,// 签到状态
            addopen:0,
            userObj:{}
        };
    }

    componentDidMount() {
        this._fetchMemberInfo();
    }

    componentWillUnmount() {
    }

    /**
     * 初始化
     * @returns {Promise<void>}
     * @private
     */
    _init_ = () => {
        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        if (user) {

            let newState = {};
            for (let key in user) {
                newState[key] = {value: user[key]};
                if (user[key]) {
                    newState[key].isEdit = true;
                }
                //this.setState(item);
            }
            this.setState(newState);
            console.log(newState);
        } else {
            PublicUtils.navigate('/')
        }
    };
    /**
     * 获取会员信息
     * @private
     */
    _fetchMemberInfo = () => {
        let user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        const parm = {
            show_phone: 1
        };
        this.setState({showProgress: true});
        Http.request("memberInfo", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    user = Object.assign(user, res.data.info, {is_get_birth_handsel: res.data.info.is_get_birth_handsel || 0});
                    this.setState({
                        showBirthday: !!res.data.info.is_birth,
                        showPhone: !!res.data.info.is_mobile,
                        showMail: !!res.data.info.is_mail,
                        showQQ: !!res.data.info.is_qq,
                        showYEB: !!res.data.info.yuebao_open,
                        signOpen: !!res.data.info.sign_open,
                        qds:res.data.info.has_sign,
                        addopen:res.data.info.add_user_open,
                        userObj:res.data.info
                    });


                    // y8 处理
                    if(global.ID == 'y8'){
                        user.head_img =  "https://appnew.hdyb33.com/code/y8/touxiang.png";
                    }
                    // b8 处理
                    if(global.ID == 'b8'){
                        user.head_img =  "https://appnew.hdyb33.com/code/b8/touxiang.png";
                    }
                    console.log(user)
                    PublicUtils.saveUserInfo({user: user});
                    this._init_();
                }
            }
        });
    };
    /**
     * 领取生日彩金
     * @private
     */
    _getBirthdayPrize = () => {
        const parm = {type: "birthday"};
        Http.request("gradeLottery", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    this.setState({is_get_birth_handsel: {value: 0}});
                    this._fetchMemberInfo();
                    PublicUtils.showToast(res.message);
                }
            }
        });
    };
    /**
     * 判断是否登录
     * @param nextPage
     * @param Params
     * @returns {Promise<void>}
     * @private
     */
    _checkLogin = async (nextPage, Params) => {
        if (await PublicUtils._checkLogin()) {
            Params.fromPage = "Me";
            PublicUtils.navigate(nextPage, Params);
        } else {
            PublicUtils.navigate('Login', {nextPage: 'Me'});
        }
    };

    //绑定真实姓名
    _bindRealName() {
        if (!this.state.user_name.isEdit) {
            let Params = {
                id: 1,
                title: "我的姓名",
                value: ''
            };
            this._checkLogin('MeDetail', Params);
            // PublicUtils.navigate('MeDetail', Params);
            // this.Api.navPush(PersonInfoDetailPage, this.navCtrl, Params, true);
        } else {
            PublicUtils.showToast("姓名已绑定");
        }
    }

    //绑定生日
    _bindBirthday() {
        if (!this.state.user_birthday.isEdit) {
            let Params = {
                id: 2,
                title: "我的生日",
                value: ''
            };
            this._checkLogin('MeDetail', Params);
            // PublicUtils.navigate('MeDetail', Params);
            // this.Api.navPush(PersonInfoDetailPage, this.navCtrl, Params, true);
        } else {
            PublicUtils.showToast("生日已绑定");
        }
    }

    //绑定手机号码
    _bindMobileNumber = () => {
        if (!this.state.user_phone.isEdit) {
            let Params = {
                id: 3,
                title: "我的手机号码",
                value: ''
            };
            this._checkLogin('MeDetail', Params);
            // this.Api.navPush(PersonInfoDetailPage, this.navCtrl, Params, true);
        } else {
            PublicUtils.showToast("手机号码已绑定");
        }
    };
    //绑定邮箱
    _bindEmail = () => {
        if (!this.state.user_mail.isEdit) {
            let Params = {
                id: 4,
                title: "我的邮箱",
                value: ''
            };
            this._checkLogin('MeDetail', Params);
            // this.Api.navPush(PersonInfoDetailPage, this.navCtrl, Params, true);
        } else {
            PublicUtils.showToast("邮箱已绑定");
        }
    };
    //绑定qq或微信
    _bindQQOrWechat = () => {
        if (!this.state.user_qq.isEdit) {
            let Params = {
                id: 5,
                title: "我的QQ或微信",
                value: ''
            };
            this._checkLogin('MeDetail', Params);
            // this.Api.navPush(PersonInfoDetailPage, this.navCtrl, Params, true);
        } else {
            PublicUtils.showToast("QQ/微信已绑定");
        }
    };
    //绑定密保问题和答案
    _bindSafeQNA = () => {
        if (!this.state.user_answer.isEdit) {
            let Params = {
                id: 6,
                title: "设置密保问题和答案",
                value: ''
            };
            this._checkLogin('MeDetail', Params);
        } else {
            PublicUtils.showToast("密保已绑定");
        }
    };

    _memberSpecil=()=>{
        this._checkLogin('MemberSpecil', {});
    }

    //修改登录密码
    _bindLoginPassword = () => {
        let parm = {};
        Http.request("sendInfo", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            } else {
                if (res.code === 200) {
                    const isSend = res.data.is_send.is_send;
                    if (isSend && !this.state.user_phone.value) {
                        PublicUtils.showToast("请先绑定手机", {type: "FAILD"});
                        return;
                    }
                    let Params = {
                        id: 7,
                        title: "修改登录密码",
                        value: '',
                        isSend: isSend
                    };



                    this._checkLogin('MeDetail', Params);
                } else {
                    PublicUtils.showToast("会员信息获取失败", {type: "FAILD"});
                }
            }
        });
    };

    _YEB = () => {
        this._checkLogin('YEB', {});
    }
    //个性皮肤
    _bindSkin = () => {
        let Params = {
            id: 9,
            title: "设置主题",
            value: ''
        };
        this._checkLogin('MeDetail', Params);
    };
    /**
     * 跳转页面
     * @private
     */
    _navigateToPage = (pageName, params) => {
        this._checkLogin(pageName, params);
    };
    /**
     * 确认退出当前账号
     * @private
     */
    _loginOutCheck = () => {
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要退出当前账号吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._loginOut();
                    }
                }
            ]
        });
    };
    /**
     * 退出当前账号
     * @private
     */
    _loginOut = () => {
        Http.request("loginOut", {}, (res) => {
            Storage.clearData();
            PublicUtils.navigate("/");
        });
    };
    _renderNoticeWin=()=>{
        const winStyles = {
            bgShadow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            innerBody: {width: "80%"},
            title: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // marginLeft: "80%"
            },
            closeButtonTop: {
                width: 35,
                height: 35,
                borderRadius: 35,
                border: "1px solid #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            closeButtonBottom: {width: 1, height: 30, borderRight: "2px solid #fff"},
            content: {
                borderRadius: 6,
                maxHeight: model.clientHeight / 2,
                overflow: "auto",
            },
            noticeWinContentBox: {
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 6,
                lineHeight:"25px",
            }
        };
        return <div style={winStyles.bgShadow}>
            <div style={winStyles.innerBody}>
                <div style={winStyles.content}>
                            <div style={winStyles.noticeWinContentBox}
                                 dangerouslySetInnerHTML={{__html: "尊敬的新客户，您好！注册成功后请先完善个人资料信息，即联系客服开通便捷充值通道，期间有任何问题可随机咨询联系7x24小时在线客服或【澳门银河】寻访部微信经理"}}/>
                </div>

                <div style={winStyles.title}>
                    <div style={winStyles.closeButtonBottom}/>
                    <div style={winStyles.closeButtonTop} onClick={() => {
                        //下次进入首页将不在显示广告弹窗
                    }}>
                        <Icon icon={"close"} size={20} color={"#fff"}/>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const styles = {
            headerContainer: {
                // position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                // zIndex:1
                // height: 70,
                // backgroundColor: "#000",
                // zIndex: 1
            },
            header: {
                position: "relative",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 1,
                background: "linear-gradient(to left, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                // background: "url(" + Background + ")"
                // paddingTop: 50 + model.STATUSBAR_HEIGHT,
            },
            headerBgContainer: {
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
            headerBg: {
                display: "flex",
                flex: 1,
                width: model.clientWidth,
                height: "100%",
            },
            userContainer: {
                marginTop: 10,
                paddingTop: 1,
                paddingLeft: 13,
                paddingRight: 13,
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderTopLeftRadius: 6,
                borderTopRightRadius: 9,
            },
            userTopContainer: {
                position:'relative',
                display: "flex",
                flexDirection: "row",
                paddingTop: 5,
                paddingBottom: 5,
                marginLeft: 10,
                alignItems: "center",
                marginTop: 0
            },
            avatarContainer: {
                width: 60,
                height: 60,
            },
            avatar: {
                width: "100%",
                height: "100%"
            },
            userinfoContainer: {},
            username: {
                marginLeft: 10,
                marginBottom: 5,
                fontSize: 18,
                color: "#fff"
            },
            regtime:{
                marginTop: 5,
                marginBottom: 5,
                color:"#fff",
                marginLeft: 10,
            },
            agentContainer: {
                display: "flex",
                flexDirection: "row",
                marginTop: 4
            },
            userBottomContainer: {
                marginTop: 13
            },
            userBottomTopContainer: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 3,
                justifyContent: "space-between"

            },
            userBottomTopLeftContainer: {
                display: "flex",
                flexDirection: "row",
            },
            userBottomBottomContainer: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between"
            },
            assetsImg: {
                width: 20,
                height: 20,
                marginRight: 5
            },
            assetsText: {},
            blanace: {
                fontSize: 30,
                color: "#000"
            },
            btn1: {
                display: "flex",
                flexDirection: "row",
                color: "#fff",
                fontSize: 12,
                paddingLeft: 10,
                paddingRight: 10,
                height: 22,
                lineHeight: "22px",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 22
            },
            topBg: {
                // height: 232,
                backgroundColor: "rgba(0,0,0,1)",
                // opacity: 0
            },
            sectionContainer: {
                display: "flex",
                borderTop: "1px solid #f5f5f5",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                // borderTopWidth: 1,
                // borderStyle: "solid",
                // borderColor: "#f5f5f5",
                paddingTop: 5,
                paddingBottom: 10
            },
            sectionItemContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // paddingTop: 4,
                // paddingBottom: 4,
                // borderRightWidth: 1,
                // borderStyle: "solid",
                // borderColor: "#f5f5f5"
                borderRight: "1px solid #f5f5f5"
            },
            sectionItemInnerContainer: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,

                // backgroundColor:"green"
            },
            sectionItemText: {
                marginLeft: 5,
                fontSize: 14
            },
            userInfoItemContent: {
                fontSize: 22,
                marginBottom: 3,
                flex: 1,
            },
            sectionItemBox: {
                display: "flex",
                flexDirection: "row",
                height: 30,
                justifyContent: "center",
                alignItems: "flex-end",
                paddingBottom: 2,
                // backgroundColor:"red"
            },
            isAgent: {
                display: "flex",
                backgroundColor: style['primaryLight' + model.SKINNAME],
                paddingLeft: 10,
                paddingRight: 10,
                height: 22,
                lineHeight: "22px",
                borderRadius: 22,
                color: style['primaryColor' + model.SKINNAME],
                marginLeft: 10,
                fontSize: 12,
                justifyContent: "center",
                alignItems: "center"
            },
            userLVInfo: {
                display: "flex",
                backgroundColor: style['primaryLight' + model.SKINNAME],
                paddingLeft: 10,
                paddingRight: 10,
                height: 22,
                lineHeight: "22px",
                borderRadius: 22,
                color: style['primaryColor' + model.SKINNAME],
                marginLeft: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            bodyContainer: {
                marginTop: 10,
                backgroundColor: "#fff"
            },
            bodyHeaderContainer: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                paddingLeft: 20,
                paddingRight: 10,
                alignItems: "center",
                borderBottom: "1px solid #f5f5f5"
                // borderBottomWidth: 1,
                // borderColor: "#f5f5f5",
                // borderStyle: "solid"
            },
            bodyHeaderImg: {
                width: 18,
                height: 18,
                marginRight: 10
            },
            bodyListContainer: {
                display: "flex",
                marginLeft: 10,
                marginRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
                // borderStyle: "solid",
                // borderColor: "#f5f5f5",
                // borderBottomWidth: 1
                borderBottom: "1px solid #f5f5f5"
            },
            bodyItemContainer: {
                display: "flex",
                flexDirection: "column",
                width: (model.clientWidth - 20) / 3,
                height: 80,
                justifyContent: "center",
                alignItems: "center",
                // borderStyle: "solid",
                // borderColor: "#f5f5f5",
                // borderRightWidth: 1
                borderRight: "1px solid #f5f5f5"
            },
            bodyItemIcon: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "red"
            },
            bodyItemText: {
                height: 30,
                lineHeight: "30px",
                textAlign: "center",
                fontSize: 14
                // backgroundColor: "green"
            },
            btnBox: {
                display: "flex",
                marginTop: 30,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 70,
                overflow: "hidden",
                position: "relative",
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                // backgroundColor: "red",
                // background: "linear-gradient(to right, #57ab2f, #008f6b)",
                height: 45,
                borderRadius: 45,
                justifyContent: "center",
                alignItems: "center"
            },


            btnrq:{marginTop:15,display:'flex',alignItems:'center',flexDirection:'column'},
            btnrq2:{display:'flex',alignItems:'center',flexDirection:'column'},

            btnBox2: {
                display: "flex",
                marginTop: 15,
                width:"90%",
                overflow: "hidden",
                position: "relative",
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                // backgroundColor: "red",
                // background: "linear-gradient(to right, #57ab2f, #008f6b)",
                height: 45,
                borderRadius: 45,
                justifyContent: "center",
                alignItems: "center"
            },




            btnBg: {
                display: "flex",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                justifyContent: "center",
                alignItems: "center"
            },
            display: "flex",
            btn: {
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "@primary",
            },
            btnText: {
                color: "#fff",
                fontSize: 16
            },
            birthdayPrize: {
                display: "flex",
                backgroundColor: "#ff7413",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 3,
                paddingBottom: 3,
                borderRadius: 3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            birthdayPrizeText: {
                color: "#fff",
                fontSize: 14,
                marginLeft: 3
            },
            title: {
                position: "relative",
                display: "flex",
                color: "#fff",
                textAlign: "center",
                fontSize: 16,
                height: 60,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            leftSlideBtn: {
                position: "absolute",
                display: "flex",
                width: 35,
                height: 35,
                borderRadius: 35,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "rgba(0,0,0,0.1)",
                left: 0
            }
        };
        return (
            <Container tabIndex={4} showHeader={false} showBottomTab={true} title={'我'}>
                <div style={styles.headerContainer}>
                    {/*<div style={styles.headerBgContainer}>*/}
                    {/*    <Image style={styles.headerBg} resizeMode='cover'*/}
                    {/*           source={require('../../../assets/images/login-bg1.png')}/>*/}
                    {/*</div>*/}

                    {/*<Header leftButtonIcon={"caidan"} backButtonOnPress={() => {*/}
                    {/*    // PublicUtils.openDrawer();*/}
                    {/*}} backgroundColor={'transparent'} leftButtonIconColor={"#fff"}*/}
                    {/*        titleColor={"#fff"}>我的</Header>*/}
                    <div style={styles.header}>
                        <div style={styles.title} className={'headPadding'}>
                            <div style={styles.leftSlideBtn} type={1} onClick={() => {
                                // PublicUtils.openDrawer();
                                this.DrawerPage.show();
                            }}>
                                <div>
                                    <Icon icon={'caidan'} color={"#fff"} size={15}/>
                                </div>
                            </div>
                            <span>我的</span>
                        </div>
                        <div style={styles.userTopContainer}>


                            {
                                this.state.signOpen ? global.ID == 'bw'?<div onClick={() => {
                                    this._checkLogin('SignIn', {});
                                }} style={{position:'absolute',right:0,bottom:0,}}>
                                    <Image style={{width:40,height:40,marginRight:0}} resizeMode='cover'
                                           uri={require('../../../assets/images/qiandao.gif')}/>
                                </div>:<div onClick={() => {
                                      this._checkLogin('SignIn', {});
                                  }} style={{position:'absolute',right:10,bottom:2,color:!this.state.qds?'rgb(239 255 0)':'rgb(201 201 201)',border: !this.state.qds? '1px solid rgb(255 225 174)':'1px solid rgb(201 201 201)',fontSize:12,padding:5}}>
                                      签到奖励
                                  </div> : null
                            }



                            <div style={styles.avatarContainer}>

                              {
                                global.ID == 'b8'?    <Image style={styles.avatar} resizeMode='contain'
                                                             uri={this.state.head_img.value}/>:  <Avatar width={60} height={60} source={this.state.head_img.value}/>
                              }


                                {/*<Image style={styles.avatar} resizeMode='contain'*/}
                                {/*       source={require('../../../assets/images/theme1/tx.png')}/>*/}
                            </div>
                            <div style={styles.userinfoContainer}>
                                <div style={styles.username}>
                                    {PublicUtils.hello()}{this.state.user_account.value}
                                </div>
                                {
                                    global.ID==="ca"?
                                        <div style={styles.regtime}>
                                            注册时间：{this.state.reg_time.value}
                                        </div>
                                        :null
                                }
                                <div style={styles.agentContainer}>




                                    {this.state.is_agent.value === 1 ?
                                        <div style={styles.isAgent}>
                                            <div
                                                style={{color: style['primaryColor' + model.SKINNAME], fontSize: 12}}>代理
                                            </div>
                                        </div> : null}
                                    {
                                        this.state.grade_name.value ?
                                            <div style={styles.userLVInfo}>
                                                <div
                                                    style={{
                                                        color: style['primaryColor' + model.SKINNAME],
                                                        fontSize: 12
                                                    }}>{this.state.grade_name.value}</div>
                                            </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={styles.userContainer}>
                            <div style={styles.userBottomContainer}>
                                <div style={styles.userBottomTopContainer}>
                                    <div style={styles.userBottomTopLeftContainer}>
                                        <Image style={styles.assetsImg} resizeMode='cover'
                                               uri={require('../../../assets/images/theme1/mn.png')}/>
                                        <div style={styles.assetsText}>总资产（元）</div>
                                        {/*<IButton*/}
                                        {/*    underlayColor={'@primary'}*/}
                                        {/*    onPress={() => {*/}
                                        {/*        this._fetchBalance();*/}
                                        {/*    }}>*/}
                                        {/*    <MyText style={styles.btn1}>查看全部</MyText>*/}
                                        {/*</IButton>*/}
                                    </div>
                                    <div
                                        underlayColor={'@primary'}
                                        onClick={() => {
                                            this._fetchMemberInfo();
                                        }}>
                                        <Icon icon={'refresh'} color={"#999"} size={22}/>
                                    </div>
                                </div>

                                <div style={styles.userBottomBottomContainer}>

                                    {
                                        this.state.showProgress ?
                                            <ActivityIndicator style={{marginTop: 5}} color={"@primary"}/> :
                                            <div style={styles.blanace}>{this.state.is_balance.value}</div>
                                    }
                                    {
                                        Number(this.state.is_get_birth_handsel.value) ?
                                            <div
                                                underlayColor={'@primary'}
                                                onClick={() => {
                                                    this._getBirthdayPrize();
                                                }}>
                                                <div style={styles.birthdayPrize}>
                                                    <Icon style={{marginTop: -4}} icon={'libao'} color={"#fff"}
                                                          size={14}/>
                                                    <div style={styles.birthdayPrizeText}>领取生日彩金</div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    {/*<div style={{...styles.topBg, ...{height: this.headerBgHeight, opacity: this.state.opacity}}}/>*/}
                    <div style={{backgroundColor: "#f5f7f9"}}>
                        <div style={styles.sectionContainer}>
                            {
                                this.state.is_integral_derail && this.state.is_integral_derail.value ?
                                    <div style={styles.sectionItemContainer} type={2}
                                         onClick={() => {
                                             this._navigateToPage("Grade", {});
                                         }}>
                                        <div style={styles.sectionItemInnerContainer}>
                                            <div style={styles.userInfoItemContent}>
                                                {this.state.is_integral.value || 0}
                                            </div>
                                            <div style={styles.sectionItemBox}>
                                                <Icon icon={'game-live'} color={"#fa7925"} size={18}/>
                                                <div style={styles.sectionItemText}>积分</div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div style={styles.sectionItemContainer} type={2}
                                 onClick={() => {
                                     this._navigateToPage("Message", {});
                                 }}>
                                <div style={styles.sectionItemInnerContainer}>
                                    <div style={styles.userInfoItemContent}>
                                        {this.state.msg_num.value || 0}
                                    </div>
                                    <div style={styles.sectionItemBox}>
                                        <Icon icon={'youjian'} color={"#079167"} size={20}/>
                                        <div style={styles.sectionItemText}>我的消息</div>
                                    </div>
                                </div>
                            </div>

                            <div style={styles.sectionItemContainer} type={2}
                                 onClick={() => {
                                     if(this.state.is_agent.value === 1 && this.state.agent_open.value === 1){
                                         PublicUtils.alert({
                                             title: "温馨提示",
                                             msg: "请选择进入的代理查询模式",
                                             buttons: [
                                                 {
                                                     text: "普通代理", onPress: () => {
                                                         this._navigateToPage("SubUsers", {});
                                                     }
                                                 },
                                                 {
                                                     text: "无限代理", onPress: () => {
                                                         this._navigateToPage("SubUsers2", {
                                                             account:this.state.user_account.value,
                                                         });
                                                     }
                                                 }
                                             ]
                                         });
                                     }else if(this.state.is_agent.value == 1){
                                        this._navigateToPage("SubUsers", {});
                                     }else if(this.state.agent_open.value == 1){
                                        this._navigateToPage("SubUsers2", {
                                            account:this.state.user_account.value,
                                        });
                                     }
                                 }}>
                                <div style={styles.sectionItemInnerContainer}>
                                    <div style={styles.userInfoItemContent}>
                                        {this.state.sub_num.value || 0}
                                    </div>
                                    <div style={styles.sectionItemBox}>
                                        <Icon icon={'tuandui'} color={"#21a3f6"} size={21}/>
                                        <div style={styles.sectionItemText}>我的下级</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.bodyContainer}>
                            <div style={styles.bodyHeaderContainer}>
                                <Image style={styles.bodyHeaderImg} resizeMode='cover'
                                       uri={require('../../../assets/images/theme1/gj.png')}/>
                                <div>常用工具</div>
                            </div>
                            <div style={styles.bodyListContainer}>
                                <div type={2}
                                     onClick={() => {
                                         this._bindSafeQNA()
                                     }}>
                                    <div style={styles.bodyItemContainer}>
                                        <div style={styles.bodyItemIcon}>
                                            <Icon icon={'new-question'} color={"#4aa738"} size={30}/>
                                        </div>
                                        <div style={styles.bodyItemText}>安全问题</div>
                                    </div>
                                </div>

                                <div type={2}
                                     onClick={() => {
                                         this._bindLoginPassword()
                                     }}>
                                    <div style={styles.bodyItemContainer}>
                                        <div style={styles.bodyItemIcon}>
                                            <Icon icon={'lock1'} color={"#2698e3"} size={30}/>
                                        </div>
                                        <div style={styles.bodyItemText}>登录密码</div>
                                    </div>
                                </div>
                                <div type={2}
                                     onClick={() => {
                                         this._navigateToPage("GameReport", {});
                                     }}>
                                    <div style={{...styles.bodyItemContainer, ...{borderRightWidth: 0}}}>
                                        <div style={styles.bodyItemIcon}>
                                            <Icon icon={'baobiao1'} color={"#9e00ff"} size={30}/>
                                        </div>
                                        <div style={styles.bodyItemText}>游戏报表</div>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.bodyListContainer}>
                                <div type={2}
                                     onClick={() => {
                                         // alert(JSON.stringify(this.state));
                                         this._navigateToPage("Userinfo", {state: this.state});
                                     }}>
                                    <div style={{...styles.bodyItemContainer, ...{borderLeftWidth: 0}}}>
                                        <div style={styles.bodyItemIcon}>
                                            <Icon icon={'gerenziliao'} color={"#fb5b1e"} size={40}/>
                                        </div>
                                        <div style={styles.bodyItemText}>个人资料</div>
                                    </div>
                                </div>
                                {
                                    this.state.showYEB ?
                                        <div type={2}
                                             onClick={() => {
                                                 this._navigateToPage("YEB", {});
                                             }}>
                                            <div style={styles.bodyItemContainer}>
                                                <div style={styles.bodyItemIcon}>
                                                    <Icon icon={'qianbao'} color={"#ff6fce"} size={22}/>
                                                </div>
                                                <div style={styles.bodyItemText}>余额宝</div>
                                            </div>
                                        </div> : null
                                }
                                {
                                    this.state.is_agent.value === 1 || this.state.agent_open.value === 1?
                                        <div type={2}
                                             onClick={() => {
                                                 if(this.state.is_agent.value === 1 && this.state.agent_open.value === 1){

                                                     PublicUtils.alert({
                                                         title: "温馨提示",
                                                         msg: "请选择进入的代理报表的模式",
                                                         buttons: [
                                                             {
                                                                 text: "普通代理", onPress: () => {
                                                                     this._navigateToPage("AgentReport", {});
                                                                 }
                                                             },
                                                             {
                                                                 text: "无限代理", onPress: () => {
                                                                     this._navigateToPage("AgentReport2", {});
                                                                 }
                                                             }
                                                         ]
                                                     });


                                                     return
                                                 }

                                                 if (this.state.is_agent.value === 1) {
                                                     this._navigateToPage("AgentReport", {});
                                                 }
                                                 if (this.state.agent_open.value === 1) {
                                                     this._navigateToPage("AgentReport2", {
                                                         account:this.state.user_account.value,
                                                     });
                                                 }
                                             }}>
                                            <div style={styles.bodyItemContainer}>
                                                <div style={styles.bodyItemIcon}>
                                                    <Icon icon={'baobiao1'} color={"#ffa800"} size={28}/>
                                                </div>
                                                <div style={styles.bodyItemText}>代理报表</div>
                                            </div>
                                        </div> : null
                                }
                                {/*{*/}
                                {/*    this.state.is_agent.value === 1 ?*/}
                                {/*        <IButton type={2}*/}
                                {/*                 onPress={() => {*/}
                                {/*                     this._navigateToPage("Promotion", {});*/}
                                {/*                 }}>*/}
                                {/*            <View style={[styles.bodyItemContainer, {borderRightWidth: 0}]}>*/}
                                {/*                <View style={styles.bodyItemIcon}>*/}
                                {/*                    <Icon name={'exqrcode'} color={"#ff69b0"} size={22}/>*/}
                                {/*                </View>*/}
                                {/*                <MyText style={styles.bodyItemText}>推广链接</MyText>*/}
                                {/*            </View>*/}
                                {/*        </IButton> : null*/}
                                {/*}*/}
                                {
                                    this.state.is_agent.value !== 1 &&  global.ID==="yy"?
                                        <div style={styles.bodyListContainer}>
                                            <div type={2}
                                                 onClick={() => {
                                                     this._memberSpecil()
                                                 }}>
                                                <div style={styles.bodyItemContainer}>
                                                    <div style={styles.bodyItemIcon}>
                                                        <Image style={{width: 30,marginTop:2}} resizeMode='contain'
                                                               uri={require('../../../assets/images/xytq.png')}/>
                                                    </div>
                                                    <div style={styles.bodyItemText}>我的特权</div>
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                }
                            </div>
                            {
                                this.state.is_agent.value === 1 && global.ID==="yy"?
                                    <div style={styles.bodyListContainer}>
                                        <div type={2}
                                             onClick={() => {
                                                 this._memberSpecil()
                                             }}>
                                            <div style={styles.bodyItemContainer}>
                                                <div style={styles.bodyItemIcon}>
                                                    <Image style={{width: 30,marginTop:2}} resizeMode='contain'
                                                           uri={require('../../../assets/images/xytq.png')}/>
                                                </div>
                                                <div style={styles.bodyItemText}>我的特权</div>
                                            </div>
                                        </div>
                                    </div>
                                    :null
                            }

                            {
                                global.ID==="y6"?
                                  <div style={styles.bodyListContainer}>
                                      <div
                                           onClick={() => {
                                               PublicUtils.linking(model.serviceUrl, "_blank");
                                           }}>
                                          <div style={styles.bodyItemContainer}>
                                              <div style={styles.bodyItemIcon}>
                                                  <div style={styles.bodyItemIcon}>
                                                      <Icon icon={'new-kefu'} color={"#bb3b5c"} size={28}/>
                                                  </div>
                                              </div>
                                              <div style={styles.bodyItemText}>在线客服</div>
                                          </div>
                                      </div>
                                  </div>
                                  :null
                            }


                            {
                                this.state.addopen === 1?
                                  <div style={styles.bodyListContainer}>
                                      <div type={2}
                                           onClick={() => {
                                               this._navigateToPage("addAgentMember", {});
                                           }}>
                                          <div style={styles.bodyItemContainer}>
                                              <div style={styles.bodyItemIcon}>
                                                  <Image style={{width: 30,marginTop:2}} resizeMode='contain'
                                                         uri={require('../../../assets/images/addhy.png')}/>
                                              </div>
                                              <div style={styles.bodyItemText}>新增会员</div>
                                          </div>
                                      </div>
                                  </div>
                                  :null
                            }





                        </div>
                        {

                            this.state.is_agent.value === 1 ?
                               <div style={styles.btnrq}>

                                   <div style={styles.btnBox2}
                                        onClick={() => {
                                            this._navigateToPage("Promotion", {});
                                        }}>
                                       <div style={styles.btnText}>我要推广</div>
                                       {/*<ThemeLinearGradient colors={['#008f6b', '#57ab2f']} start={{x: 1, y: 0}}*/}
                                       {/*                     style={styles.btnBg}*/}
                                       {/*                     end={{x: 0, y: 0}}>*/}
                                       {/*    <MyText style={styles.btnText}>我要推广</MyText>*/}
                                       {/*</ThemeLinearGradient>*/}
                                   </div>

                               </div>
                              :<div></div>
                        }





                      {
                          global.ID != "yy"?<div>
                            {

                              this.state.userObj.ordinary_agent_open &&  this.state.is_agent.value != 1 || this.state.agent_open.value === 1 && this.state.is_agent.value != 1?
                                  <div style={styles.btnrq2}>

                                    <div style={styles.btnBox2}
                                         onClick={async () => {
                                           const user = JSON.parse(await Storage.retrieveData("user"));
                                           const param = {
                                             url: global.AGENT_URL + "?mid=" + user.id + "&cid=" + global.ID + "&v=" + +new Date().getTime().toString(),
                                             title: "申请代理"
                                           };
                                           // PublicUtils.navigate("GameRoom", param);
                                           PublicUtils.linking(param.url,"_blank");
                                         }}>
                                      <div style={styles.btnText}>我要成为代理</div>
                                      {/*<ThemeLinearGradient colors={['#008f6b', '#57ab2f']} start={{x: 1, y: 0}}*/}
                                      {/*                     style={styles.btnBg}*/}
                                      {/*                     end={{x: 0, y: 0}}>*/}
                                      {/*    <div style={styles.btnText}>我要成为代理</div>*/}
                                      {/*</ThemeLinearGradient>*/}
                                    </div>

                                  </div>
                                  :<div>

                                  </div>
                            }
                          </div>:<div></div>
                      }




                        <div style={{height:50}}></div>





                    </div>
                </div>
                <DrawerPage ref={(ref) => {
                    this.DrawerPage = ref;
                }}/>
                {/*{*/}
                {/*    this._renderNoticeWin()*/}
                {/*}*/}
            </Container>
        )
    }
}

export default Default;
