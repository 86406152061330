/**
 * 绑定银行卡
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import BankCard from "../services/bankCard";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import model from "../services/model";
import Storage from "../services/storage";
import Icon from '../utils/icon';
import MyInput from '../component/myInput'
import MyButton from '../component/myButton'
import Image from '../component/image'
import style from '../utils/config.scss'

export default class BindBank extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            bankName: null,
            cardNumber: null,
            userName: null,
            bankAddress: null,
            type: PublicUtils.getState(this.props.location.pathname).type,
            blist:[], // 多银行卡
            dyhk:false, // 是否多银行卡
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._getBankInfo();
    };


    /**
     * 获取银行信息
     */
    _getBankInfo() {
        let parm = {type: "pay_num"};
        Http.request("getBankInfo", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/bindbank'});
            }
            if (res.code === 200) {
                // for (let item in res.data.data) {
                //     this.bindBankPage[item] = res.data.data[item];
                // }
                //this.setState({data: res.data.data})

                if(res.data.more_card.length>0){
                    this.setState({
                        cardNumber: res.data.data.pay_num,
                        userName: res.data.data.user_name,
                        bankName: res.data.data.pay_card,
                        bankAddress: res.data.data.pay_address,
                        blist:res.data.more_card,
                        dyhk:true,
                    });
                }else{
                    this.setState({
                        cardNumber: res.data.data.pay_num,
                        userName: res.data.data.user_name,
                        bankName: res.data.data.pay_card,
                        bankAddress: res.data.data.pay_address
                    });
                }





                // this.cardNumber = res.data.data.pay_num;
                // this.userName=res.data.data.user_name;
                // this.userName=res.data.data.user_name;
            }
        });
    }

    /**
     *输入银行卡号
     * @param text
     * @private
     */
    _cardNumber = (text) => {
        // this.cardNumber = text;
        this.setState({cardNumber: text});
    };
    /**
     * 自动识别银行卡
     * @private
     */
    _checkBankName = () => {
        if (this.state.cardNumber) {
            BankCard.getBankBin(this.state.cardNumber, (err, data) => {
                if (data) {
                    this.setState({bankName: data.bankName});
                } else {
                    PublicUtils.showToast("未检测出银行信息，请手动输入！");
                }
            });
        }
    };
    /**
     * 银行名称
     * @private
     */
    _changeBankName = (text) => {
        this.setState({bankName: text});
    };
    /**
     * 银行开户行地址
     * @private
     */
    _changeBankAddress = (text) => {
        this.setState({bankAddress: text});
    };
    /**
     * 绑定银行卡
     */
    _bindBankCard = async () => {
        // bankName: null,
        //     cardNumber: null,
        //     userName: null,
        //     bankAddress: null
        if (!this.state.cardNumber || !/^[0-9]*$/.test(this.state.cardNumber)) {
            PublicUtils.showToast("卡号格式不正确");
            return;
        }
        if (!this.state.bankName) {
            PublicUtils.showToast("请输入银行名称");
            return;
        }
        if (!this.state.bankAddress) {
            PublicUtils.showToast("请输入开户行地址");
            return;
        }
        let userInfo = JSON.parse(await Storage.retrieveData("user"));
        const parm = {
            pay_address: this.state.bankAddress,
            pay_card: this.state.bankName,
            pay_num: this.state.cardNumber
        };
        Http.request("memberEdit", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/bindbank'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    userInfo.pay_address = this.state.bankAddress;
                    userInfo.pay_num = this.state.cardNumber;
                    userInfo.pay_card = this.state.bankName;
                    Storage.storeData({user: JSON.stringify(userInfo)});
                    let number = this.state.cardNumber.substring(0, 4) + "****" + this.state.cardNumber.substr(this.state.cardNumber.length - 4);

                    PublicUtils.navigate("BindbankComplete", {
                        name: this.state.userName,
                        bankname: this.state.bankName,
                        number: number
                    });
                    // let name = this.bindBankPage.user_name;
                    // let bankname = this.bindBankPage.pay_card;
                    // let number = this.bindBankPage.pay_num.substr(this.bindBankPage.pay_num.length - 4);
                    // this.commonData.loginData.user.pay_address = this.bindBankPage.pay_address;
                    // this.commonData.loginData.user.pay_card = this.bindBankPage.pay_card;
                    // this.commonData.loginData.user.pay_num = this.bindBankPage.pay_num;
                    // this.commonData.saveLocalStorage();
                    // this.Api.navPush(BindBankSuccessPage, this.navCtrl, {
                    //     name: name,
                    //     bankname: bankname,
                    //     number: number
                    // }, true);
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });

    }

    render() {
        const styles = {



            headerContainer: {
                display: "flex",
                backgroundColor: "#fff",
                width: "100%",
                height: 50,
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 20,
                boxSizing:'border-box'
            },
            headerbs: {
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            headerText: {
                marginLeft: 10,
                fontSize: 16,
                color: "#000"
            },
            tipsBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20
            },
            tipsIcon: {
                display: "flex",
                width: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            tipsBody: {
                display: "flex",
                flex: 1
            },
            tipsText: {
                color: "#ff4419",
                marginLeft: 5,
                lineHeight: "20px",
                fontSize: 14
            },
            formBody: {
                // marginTop: 20,
                // paddingLeft: 10,
                // backgroundColor: "#fff"
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: 14
            },
            formItem: {
                display: "flex",
                flexDirection: "row",
                height: 50,
                alignItems: "center",
                backgroundColor: "#fff",
                marginBottom: 15,
                paddingLeft: 10,
                borderRadius: 3,
                border: "1px solid rgba(0,0,0,0.01)"
                // borderWidth: 1,
                // borderStyle: "solid",
                // borderColor: "rgba(0,0,0,0.01)"
            },
            formItemHeader: {
                display:"flex",
                width: 80,
                fontSize: 14,
                flexDirection: "row",
                alignItems: "center"
            },
            formItemBody: {
                display: "flex",
                flex: 1
            },
            formItemIpt: {
                color: "#000"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 20,
                paddingRight: 20
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            bankContainer: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#fff",
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                padding: 10,
                borderRadius: 6,
                border: "1px solid rgba(0,0,0,0.05)",
                boxShadow: "0 0 10px rgba(0,0,0,0.08)",
            },

            bankContainer2: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#fff",
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                padding: 10,
                borderRadius: 6,
                border: "1px solid rgba(0,0,0,0.05)",
                boxShadow: "0 0 10px rgba(0,0,0,0.08)",
            },

            bankImgContainer: {},
            bankImg: {
                width: 120,
                height: 120
            },
            bankInfoContainer: {},
            bankInfoRow: {
                display: "flex",
                flexDirection: "row",
                height: 30,
                alignItems: "center",
                fontSize: 14
            },
            bankInfoLeftCol: {
                width: 50,
                color: "#000"
            },
            bankInfoRightCol: {
                color: "#000"
            },
        };
        return (
            <Container title={'绑定银行卡'}>
                <div style={styles.headerContainer}>
                    <Icon icon={'ai-pay-copy'} color={style['primaryColor' + model.SKINNAME]} size={20}/>
                    <div style={styles.headerText}>{this.state.type === 1 ? "我的银行卡" : "添加银行卡"}</div>
                </div>
                {
                    this.state.type === 1 ?
                       <div>
                           <div style={styles.bankContainer2}>
                               <div style={styles.bankImgContainer}>
                                   <Image style={styles.bankImg} resizeMode='contain'
                                          uri={require('../assets/images/theme1/yhk-bg.jpg')}/>
                               </div>
                               <div style={styles.bankInfoContainer}>
                                   <div style={styles.bankInfoRow}>
                                       <div style={styles.bankInfoLeftCol}>银行</div>
                                       <div style={styles.bankInfoRightCol}>{this.state.bankName}</div>
                                   </div>
                                   <div style={styles.bankInfoRow}>
                                       <div style={styles.bankInfoLeftCol}>开户地</div>
                                       <div style={styles.bankInfoRightCol}>{this.state.bankAddress}</div>
                                   </div>
                                   <div style={styles.bankInfoRow}>
                                       <div style={styles.bankInfoLeftCol}>开户人</div>
                                       <div style={styles.bankInfoRightCol}>{this.state.userName}</div>
                                   </div>
                                   <div style={styles.bankInfoRow}>
                                       <div style={styles.bankInfoLeftCol}>卡号</div>
                                       <div style={styles.bankInfoRightCol}>{this.state.cardNumber}</div>
                                   </div>
                               </div>
                           </div>


                           {
                               this.state.dyhk?this.state.blist.map((item,key)=>{
                                   return(  <div style={styles.bankContainer2}>
                                       <div style={styles.bankImgContainer}>
                                           <Image style={styles.bankImg} resizeMode='contain'
                                                  uri={require('../assets/images/theme1/yhk-bg.jpg')}/>
                                       </div>
                                       <div style={styles.bankInfoContainer}>
                                           <div style={styles.bankInfoRow}>
                                               <div style={styles.bankInfoLeftCol}>银行</div>
                                               <div style={styles.bankInfoRightCol}>{item.pay_card}</div>
                                           </div>
                                           <div style={styles.bankInfoRow}>
                                               <div style={styles.bankInfoLeftCol}>开户地</div>
                                               <div style={styles.bankInfoRightCol}>{item.pay_address}</div>
                                           </div>
                                           <div style={styles.bankInfoRow}>
                                               <div style={styles.bankInfoLeftCol}>开户人</div>
                                               <div style={styles.bankInfoRightCol}>{item.user_account}</div>
                                           </div>
                                           <div style={styles.bankInfoRow}>
                                               <div style={styles.bankInfoLeftCol}>卡号</div>
                                               <div style={styles.bankInfoRightCol}>{item.pay_num}</div>
                                           </div>
                                       </div>
                                   </div>)
                               }):<div></div>
                           }


                       </div>
                        :
                        <div>
                            <div style={styles.tipsBox}>
                                {/*<div style={styles.tipsIcon}>*/}
                                {/*<Icon icon={'tips'} color={'#fff'} size={16}/>*/}
                                {/*</div>*/}
                                <div style={styles.tipsBody}>
                                    <div style={styles.tipsText}>为了您的账户资金安全，只能绑定持卡人本人的银行卡，持卡人姓名将作为该账号的唯一凭证，提交后将无法修改!
                                    </div>
                                </div>
                            </div>
                            <div style={styles.formBody}>
                                <div style={styles.formItem}>
                                    <div style={styles.formItemHeader}>
                                        <Icon style={{marginTop: -5, marginRight: 3}} icon={'wode-o'} color={style['primaryColor' + model.SKINNAME]} size={14}/>
                                        持卡人
                                    </div>
                                    <div style={styles.formItemBody}>
                                        <MyInput style={styles.formItemIpt} readOnly={true}
                                                 value={this.state.userName}/>
                                    </div>
                                </div>
                                <div style={styles.formItem}>
                                    <div style={styles.formItemHeader}>
                                        <Icon style={{marginTop: -3, marginRight: 3}} icon={'ai-pay-copy'} color={style['primaryColor' + model.SKINNAME]} size={17}/>
                                        卡号
                                    </div>
                                    <div style={styles.formItemBody}>
                                        <MyInput style={styles.formItemIpt} keyboardType={'numeric'}
                                                 value={this.state.cardNumber}
                                                 placeholder={'输入银行卡号，自动识别银行卡'} onBlur={() => {
                                            this._checkBankName();
                                        }} onChangeText={(text) => {
                                            this._cardNumber(text);
                                        }}/>
                                    </div>
                                </div>
                                <div style={styles.formItem}>
                                    <div style={styles.formItemHeader}>
                                        <Icon style={{marginTop: -3, marginRight: 3}} icon={'ai-pay-copy'} color={style['primaryColor' + model.SKINNAME]} size={17}/>
                                        银行名称
                                    </div>
                                    <div style={styles.formItemBody}>
                                        <MyInput style={styles.formItemIpt} placeholder={'请输入银行名称'}
                                                 value={this.state.bankName} onChangeText={(text) => {
                                            this._changeBankName(text);
                                        }}/>
                                    </div>
                                </div>
                                <div style={styles.formItem}>
                                    <div style={styles.formItemHeader}>
                                        <Icon style={{marginTop: -3, marginRight: 3}} icon={'ai-pay-copy'} color={style['primaryColor' + model.SKINNAME]} size={17}/>
                                        开户行
                                    </div>
                                    <div style={styles.formItemBody}>
                                        <MyInput style={styles.formItemIpt} placeholder={'请填写详细支行地址'}
                                                 value={this.state.bankAddress} onChangeText={(text) => {
                                            this._changeBankAddress(text);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.btnBox}>
                                <MyButton title={'确定'} onPress={() => {
                                    this._bindBankCard();
                                }}/>
                            </div>
                        </div>
                }
            </Container>
        )
    }
}
