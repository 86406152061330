/**
 * 体育开奖结果
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import MyPicker from "../component/myPicker";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import Storage from "../services/storage";
import MatchItem from "./sport-games/hg/components/matchItem";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import Icon from '../utils/icon'
import DatePicker from 'react-mobile-datepicker';

export default class SportOpenResult extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.ballTypeModel = [{label: '足球', value: '1'}, {label: '篮球', value: '2'}, {label: '羽毛球', value: '3'},
            {label: '乒乓球', value: '4'}, {label: '网球', value: '5'}, {label: '排球', value: '6'}, {
                label: '棒球',
                value: '8'
            }, {label: '其他', value: '9'}];
        this.state = {
            time: new Date(),
            checked: false,
            date: this._fetchDate(),
            ballType: this.ballTypeModel[0],
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            refreshing: false,//上拉刷新,
            total: 0,//总页数
            currpage: 1,//当前页
            list: [],
            showBallTypeFliter: false,
            isOpen: false,
        };
    }

    /**
     * 获取当前日期
     * @returns {string}
     * @private
     */
    _fetchDate = () => {
        // 获取当前日期
        const date = new Date();
        // 获取当前月份
        let nowMonth = date.getMonth() + 1;
        // 获取当前是几号
        let strDate = date.getDate();
        // 添加分隔符“-”
        const seperator = "-";
        // 对月份进行处理，1-9月在前面添加一个“0”
        if (nowMonth >= 1 && nowMonth <= 9) {
            nowMonth = "0" + nowMonth;
        }
        // 对月份进行处理，1-9号在前面添加一个“0”
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
        return date.getFullYear() + seperator + nowMonth + seperator + strDate;
    };
    static navigationOptions = ({navigation}) => {
        return {}
    };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchList();
    };

    /**
     * 获取账变记录
     */
    _fetchList() {
        this.setState({list: [], showFoot: 2, showProgress: true});
        let parm = {
            add_date: this.state.date,
            ball: this.state.ballType.value,
        };
        Http.request("SportsGetResult", parm, (res) => {
            this.setState({refreshing: false, showFoot: 1});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Wallet'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    //重新构建根据赛事名称和时间排序的赛事json
                    let dataObj = res.data.result;
                    let listLength = dataObj.length;
                    let newList = [];
                    let match: any = {name: "", list: [], checked: false};
                    for (let i = 0; i < listLength; i++) {
                        if (!match.name) {
                            match.name = dataObj[i].match_name;
                        } else if (match.name && match.name != dataObj[i].match_name) {
                            match.count = match.list.length;
                            newList.push(match);
                            match = {name: dataObj[i].match_name, list: [], checked: false};
                        }
                        match.list.push(dataObj[i]);
                    }
                    if (match.name) {
                        match.count = match.list.length;
                        newList.push(match);
                    }
                    this.setState({list: newList});
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    }

    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this.setState({currpage: 1}, () => {
            //获取数据
            this._fetchList();
        });
    };


    /**
     * 页脚
     * @returns {*}
     * @private
     */
    _renderFooter() {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                borderBottom: '1px solid #f5f7f9',
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10,
                paddingTop: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                flexDirection: "column",
                justifyContent: "space-between",
                borderBottom: '1px solid #f5f7f9',
            },
            listItemTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end"
            },
            listItemTopLeftText: {
                fontSize: 14,
                color: "#000"
            },
            listItemTopRightText: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 16
            },
            listItemBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: 5
            },
            listItemBottomLeftText: {
                fontSize: 12,
                color: "#b0b0b0"
            },
            listItemBottomRightText: {
                fontSize: 14,
                color: "#b0b0b0"
            },
            footer: {
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            },
            datepicker: {
                borderWidth: 0,
                paddingLeft: 0,
                marginLeft: 0
            },

        };
        let renderView = null;
        if (this.state.showFoot === 1) {
            if (!this.state.list.length) {
                renderView = (
                    <NoneData/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody}>
                            <div style={styles.footerBodyText}>
                                没有更多数据了
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.state.showFoot === 2) {
            if (!this.state.list.length) {
                renderView = (
                    <Loadding/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody} onClick={() => {
                            this._onEndReached();
                        }}>
                            <div style={styles.footerBodyText}>
                                点击加载更多
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.state.showFoot === 0) {
            renderView = (
                <div style={styles.footer}>
                    {/*<View style={styles.footerBody}>*/}
                    {/*<Text style={styles.footerBodyText}>*/}
                    {/*努力加载中...*/}
                    {/*</Text>*/}
                    {/*</View>*/}
                    <div style={styles.footerPadding}/>
                </div>
            );
        }
        return renderView;
    }

    formatDate = (date) => {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let second = date.getSeconds();
        second = minute < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d;
    };
    handleClick = () => {
        this.setState({isOpen: true});
    }

    handleCancel = () => {
        this.setState({isOpen: false});
    }

    handleSelect = (time) => {
        this.setState({date: this.formatDate(time), isOpen: false}, () => {

            this._fetchList();
        });
    }

    render() {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                borderBottom: '1px solid #f5f7f9',
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10,
                paddingTop: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                flexDirection: "column",
                justifyContent: "space-between",
                borderBottom: '1px solid #f5f7f9',
            },
            listItemTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end"
            },
            listItemTopLeftText: {
                fontSize: 14,
                color: "#000"
            },
            listItemTopRightText: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 16
            },
            listItemBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: 5
            },
            listItemBottomLeftText: {
                fontSize: 12,
                color: "#b0b0b0"
            },
            listItemBottomRightText: {
                fontSize: 14,
                color: "#b0b0b0"
            },
            footer: {
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            },
            datepicker: {
                borderWidth: 0,
                paddingLeft: 0,
                marginLeft: 0
            },

        };
        return (
            <Container title={'比赛结果'} style={{backgroundColor: "#fff"}}>
                <div style={styles.headerToolbar}>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({showBallTypeFliter: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.ballType.label}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({isOpen: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.date}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                    {/*<ButtonOpacity style={styles.headerToolbarItemBtn} onPress={() => {*/}
                    {/*this.setState({showDateTypeFliter: true})*/}
                    {/*}}>*/}
                    {/*<View style={styles.headerToolbarItem}>*/}
                    {/*<Text style={styles.headerToolbarItemText}>{this.state.dateType.label}</Text>*/}
                    {/*<Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,0.5)'}/>*/}
                    {/*</View>*/}
                    {/*</ButtonOpacity>*/}

                    {/*<div style={[styles.headerToolbarItemBtn]}>*/}
                    {/*<DatePicker*/}
                    {/*style={styles.datepicker}*/}
                    {/*date={this.state.date && this.state.date}*/}
                    {/*mode="date"*/}
                    {/*placeholder="请选择日期"*/}
                    {/*format="YYYY-MM-DD"*/}
                    {/*minDate="1900-01-01"*/}
                    {/*maxDate={this._fetchDate()}*/}
                    {/*confirmBtnText="确定"*/}
                    {/*cancelBtnText="取消"*/}
                    {/*showIcon={false}*/}
                    {/*customStyles={{*/}
                    {/*dateInput: {*/}
                    {/*marginLeft: 0,*/}
                    {/*borderWidth: 0,*/}
                    {/*},*/}
                    {/*dateTouchBody: {*/}
                    {/*textAlign: "left"*/}
                    {/*},*/}
                    {/*// ... You can check the source to find the other keys.*/}
                    {/*}}*/}
                    {/*onDateChange={(date) => {*/}
                    {/*this.setState({date: date}, () => {*/}
                    {/*this._fetchList();*/}
                    {/*})*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*<Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>*/}
                    {/*</div>*/}
                </div>

                <div>
                    {
                        this.state.list.map((item,key) => {
                            return (
                                <MatchItem key={key} item={item} checked={item.checked} type={'result'}/>
                            )
                        })
                    }
                </div>
                {
                    this._renderFooter()
                }
                <MyPicker
                    title={'交易类型'}
                    model={this.ballTypeModel}
                    display={this.state.showBallTypeFliter}
                    onHiden={() => {
                        this.setState({showBallTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({ballType: item.value[0].value, showBallTypeFliter: false}, () => {
                            this._fetchList();
                            //this._fetchMemberChangeList();
                        });
                    }}
                />
                <DatePicker
                    theme={'ios'}
                    value={this.state.time}
                    isOpen={this.state.isOpen}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}/>
            </Container>
        )
    }
}