/**
 * 玩法
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../../../utils/config.scss'
import model from "../../../../services/model";

export default class PlayType extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            playId: this.props.playId,
        }
    }

    static propTypes = {
        playTypeList: PropTypes.array,
        playId: PropTypes.number,
        onPress: PropTypes.func
    };
    static defaultProps = {
        playId: 2
    };
    _onPress = (id) => {
        this.props.onPress(id);
    };

    render() {
        const styles = {
            playTypeBar: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                borderBottom: "10px solid #f5f5f5",
                height: 50,
            },
            playTypeItem: {
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 30,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 30,
            },
            playTypeItemText: {
                color: "#000",
                fontSize: 14
            },
            playTypeActiveItem: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            playTypeActiveItemText: {
                color: "#fff"
            },
        };
        return (
            <div style={styles.playTypeBar}>
                {
                    this.props.playTypeList.map((item) => {
                        return (
                            <div key={item.id}
                                 onClick={() => {
                                     this.setState({playId: item.id});
                                     this._onPress(item.id);
                                 }}
                                 style={{...styles.playTypeItem, ...this.state.playId === item.id ? styles.playTypeActiveItem : null}}>
                                <div
                                    style={{...styles.playTypeItemText, ...this.state.playId === item.id ? styles.playTypeActiveItemText : null}}>{item.name}</div>
                            </div>
                        )
                    })
                }

            </div>
        )
    }
}