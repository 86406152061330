/**
 * 下级代理充值
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import MyButton from "../component/myButton";
import MyInput from "../component/myInput";
import Storage from "../services/storage";
import Http from "../services/http";
import PublicUtils from "../utils/publicUtils";
import history from "../services/history";
import Icon from '../utils/icon';
import style from '../utils/config.scss'
import model from "../services/model";

export default class SubUserTransfer extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            name: PublicUtils.getState(this.props.location.pathname).user_account,
            amount: '',
            showProgress: false,
            safe_password: ""
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @returns {Promise<void>}
     * @private
     */
    _init_ = async () => {
        // alert(await Storage.retrieveData("user"));
        let user = JSON.parse(await Storage.retrieveData("user"));
        this.setState({userInfo: user});
    };
    /**
     * 可转金额
     * @private
     */
    _getTransferTips = () => {
        const styles = {
            user_account: {
                display: "flex",
                borderTop: "10px solid #f5f7f9",
                borderBottom: "10px solid #f5f7f9",
                backgroundColor: "#fff",
                paddingTop: 10,
                paddingBottom: 10,
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 10
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14,
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14,
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                display: "flex",
                justifyContent: "center",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },

        };
        return <div style={styles.transferTipsLeft}>
            <div style={styles.transferTipsColor1}>可提金额</div>
            <div style={styles.transferTipsColor2}>￥{this.state.userInfo.is_balance}</div>
            <div style={styles.transferTipsColor1}>元</div>
        </div>;
    };

    /**
     * 设置转账金额
     * @param amount
     */
    _setAmount = (amount) => {
        this.setState({amount: amount});
    };

    /**
     * 给下级会员充值
     */
    _agentUserTranfer() {
        if (!this.state.amount) {
            PublicUtils.showToast("请输入转账金额");
            return;
        }
        if (!this.state.safe_password) {
            PublicUtils.showToast("请输入提款密码");
            return;
        }
        this.setState({showProgress: true});
        const parma = {
            user_account: this.state.name,
            amount: this.state.amount,
            safe_password: this.state.safe_password
        };
        Http.request("agentUserTransfer", parma, (res) => {
            this.setState({showProgress: false});
            if (res.data.status) {
                PublicUtils.showToast("转账成功");
                PublicUtils.goBack();
            } else {
                PublicUtils.showToast(res.data.message);
            }
        });
    }

    render() {
        const styles = {
            user_account: {
                display: "flex",
                borderTop: "10px solid #f5f7f9",
                borderBottom: "10px solid #f5f7f9",
                backgroundColor: "#fff",
                paddingTop: 10,
                paddingBottom: 10,
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 10
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14,
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14,
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                display: "flex",
                justifyContent: "center",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            tipsBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 8,
                paddingBottom: 8,
            },
            tipsIcon: {
                display: "flex",
                width: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            tipsBody: {
                display: "flex",
            },
            tipsText: {
                fontSize: 14,
                color: "#fff",
                marginLeft: 5,
            },
            fundPasswordContainer: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                flexDirection: "row",
                borderBottom: "1px solid #f5f7f9",
                alignItems: "center",
                fontSize: 14
            },
            fundPasswordInputWapper: {
                display: "flex",
                flex: 1,
            },
            fundPasswordInput: {
                display: "flex",
                marginLeft: 10,
                flex: 1
            }
        };
        return (
            <Container title={'下级代理充值'} style={{backgroundColor: "#fff"}}>
                {
                    !this.state.userInfo.is_agent_deposit ?
                        <div style={styles.tipsBox}>
                            {/*<div style={styles.tipsIcon}>*/}
                                {/*<Icon icon={'tips'} color={'#fff'} size={16}/>*/}
                            {/*</div>*/}
                            <div style={styles.tipsBody}>
                                <div style={styles.tipsText}>温馨提示：您没有权限给下级会员转账哦</div>
                            </div>
                        </div> : null
                }
                <div style={styles.user_account}>
                    <div style={{fontSize: 14}}>转给：{this.state.name}</div>
                </div>
                <div style={styles.amountContainer}>
                    <div style={styles.amountTitle}>转入金额</div>
                    <div style={styles.amountInputContainer}>
                        <div style={styles.amountInputIconBox}>
                            <Icon icon={'rmb'} size={24} color={'#000'}/>
                        </div>
                        <div style={styles.amountInputBox}>
                            <MyInput style={styles.amountInput}
                                     keyboardType={'numeric'}
                                     value={this.state.amount && String(this.state.amount)}
                                     placeholder={'0.00'}
                                     onChangeText={(text) => {
                                         this._setAmount(text);
                                         // this.setState({amount: text});
                                     }}
                                     onKeyUp={(text) => {
                                         this.setState({amount: text.replace(/\D/g, '')})
                                     }}/>
                        </div>
                    </div>
                    <div style={styles.transferTips}>
                        <div>
                            {
                                this._getTransferTips()
                            }
                        </div>
                        {/*<View style={styles.transferTipsRight}>*/}
                        {/*<Button activeOpacity={0.8} underlayColor={'rgba(0,0,0,0.1)'} onPress={() => {*/}
                        {/*this._allAmount();*/}
                        {/*}}>*/}
                        {/*<Text style={styles.transferTipsColor2}>全部提现</Text>*/}
                        {/*</Button>*/}
                        {/*</View>*/}
                    </div>
                </div>
                <div style={styles.amountTemplate}>
                    <div onClick={() => {
                        this._setAmount(100);
                    }} style={{...styles.amountTemplateItem, ...this.state.amount === 100 ? styles.active : null}}>
                        <div
                            style={{...styles.amountTemplateItemText, ...this.state.amount === 100 ? styles.activeText : null}}>￥100
                        </div>
                    </div>
                    <div onClick={() => {
                        this._setAmount(300);
                    }} style={{...styles.amountTemplateItem, ...this.state.amount === 300 ? styles.active : null}}>
                        <div
                            style={{...styles.amountTemplateItemText, ...this.state.amount === 300 ? styles.activeText : null}}>￥300
                        </div>
                    </div>
                    <div onClick={() => {
                        this._setAmount(500);
                    }} style={{...styles.amountTemplateItem, ...this.state.amount === 500 ? styles.active : null}}>
                        <div
                            style={{...styles.amountTemplateItemText, ...this.state.amount === 500 ? styles.activeText : null}}>￥500
                        </div>
                    </div>
                </div>
                <div style={styles.amountTemplate}>
                    <div onClick={() => {
                        this._setAmount(1000);
                    }} style={{...styles.amountTemplateItem, ...this.state.amount === 1000 ? styles.active : null}}>
                        <div
                            style={{...styles.amountTemplateItemText, ...this.state.amount === 1000 ? styles.activeText : null}}>￥1000
                        </div>
                    </div>
                    <div onClick={() => {
                        this._setAmount(2000);
                    }} style={{...styles.amountTemplateItem, ...this.state.amount === 2000 ? styles.active : null}}>
                        <div
                            style={{...styles.amountTemplateItemText, ...this.state.amount === 2000 ? styles.activeText : null}}>￥2000
                        </div>
                    </div>
                    <div onClick={() => {
                        this._setAmount(10000);
                    }} style={{...styles.amountTemplateItem, ...this.state.amount === 10000 ? styles.active : null}}>
                        <div
                            style={{...styles.amountTemplateItemText, ...this.state.amount === 10000 ? styles.activeText : null}}>￥10000
                        </div>
                    </div>
                </div>
                <div style={{height: 10, backgroundColor: "#f5f7f9"}}/>
                <div style={styles.fundPasswordContainer}>
                    <div>取款密码</div>
                    <div style={styles.fundPasswordInputWapper}>
                        <MyInput style={styles.fundPasswordInput}
                                 value={this.state.safe_password} type={'password'}
                                 placeholder={'请输入取款密码'} onChangeText={(text) => {
                            this.setState({safe_password: text});
                        }}/>
                    </div>
                </div>
                <div style={styles.btnBox}>
                    <MyButton title={'确认提款'} disabled={!this.state.userInfo.is_agent_deposit || this.state.showProgress}
                              showProgress={this.state.showProgress}
                              onPress={() => {
                                  this._agentUserTranfer()
                              }}/>
                </div>
            </Container>
        )
    }
}