/**
 * 消息详情
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import history from "../services/history";
import style from '../utils/config.scss'

export default class MessageDetail extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        // alert(this.props.location.pathname);
        this.state = {
            id: PublicUtils.getState(this.props.location.pathname).data.id,
            is_title: PublicUtils.getState(this.props.location.pathname).data.is_title,
            add_time: PublicUtils.getState(this.props.location.pathname).data.add_time,
            is_content: PublicUtils.getState(this.props.location.pathname).data.is_content,
            type: PublicUtils.getState(this.props.location.pathname).type
        }
    }

    static navigationOptions = ({navigation}) => {
        return {}
    };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        let params = {id: this.state.id};
        Http.request("readMessage", params, (res) => {
            // if (res.code === 300) {
            //     Storage.clearData();
            //     PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            // }
            if (res.code === 200) {
                if (res.data.status === 0) {
                } else {
                    // PublicUtils.showToast(res.data.message);
                }
            }
        });
    };
    /**
     * 删除消息
     * @private
     */
    _deleteMessage = () => {
        let params = {id: this.state.id};
        Http.request("deleteMessage", params, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 0) {
                    PublicUtils.state.params.refresh();
                    PublicUtils.goBack();
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };

    render() {
        const styles = {
            msgHeader: {
                backgroundColor: "#f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            titleBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            },
            title: {
                fontSize: 16,
                color: "#000"
            },
            addTimeBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 10
            },
            addTime: {
                fontSize: 12,
                color: "#a8a8a8"
            },
            contentBox: {

                margin: 10
            },
            content: {
                fontSize: 16,
                color: "#000",
                lineHeight: "25px",
                // maxHeight: model.headerHeight / 2,
            },
            delMsg: {
                position: "fixed",
                bottom: 30,
                width: "80%",
                left: 0,
                right: 0,
                borderRadius: 3,
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            delMsgText: {
                fontSize: 15,
                color: "#fff"
            }
        };
        return (
            <Container title={'消息内容'} style={{backgroundColor: "#fff"}}>
                {
                    this.state.type === 1 ?
                        <div>
                            <div style={styles.msgHeader}>
                                <div style={styles.titleBox}>
                                    <div
                                        style={styles.title}>{this.state.is_title}</div>
                                </div>
                                <div style={styles.addTimeBox}>
                                    <div
                                        style={styles.addTime}>{PublicUtils.timestampFormat(this.state.add_time)}</div>
                                </div>
                            </div>
                            <div style={styles.contentBox}>
                                <div
                                    style={styles.content}>{this.state.is_content}</div>
                            </div>
                            {/*<div onClick={() => {*/}
                            {/*this._deleteMessage();*/}
                            {/*}} style={styles.delMsg}>*/}
                            {/*<div style={styles.delMsgText}>删除</div>*/}
                            {/*</div>*/}
                        </div> :
                        <div>
                            <div style={styles.contentBox}>
                                <div
                                    style={styles.content}>{this.state.is_content}</div>
                            </div>
                        </div>
                }

            </Container>
        )
    }
}