/**
 * 平台卡片
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PublicUtils from "../utils/publicUtils";
import Icon from '../utils/icon'
import style from '../utils/config.scss'
import model from "../services/model";
import Image from '../component/image'

class PlatformCard extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        obj: PropTypes.object,
        name: PropTypes.string,
        icon: PropTypes.string,
        memo1: PropTypes.string,
        memo2: PropTypes.string,
    };
    static defaultProps = {
        editable: false
    };
    /**
     * 进入游戏
     * @private
     */
    _enterGame = async () => {
        if (this.props.obj.game_id === 201) {
            PublicUtils.navigate('GameNavigation');
        } else if (this.props.obj.game_id === 101) {
            PublicUtils.navigate('HG', {param: this.props.obj});
        } else {


            if (await PublicUtils._checkLogin()) {
                PublicUtils.navigate("GameTransfer", {param: this.props.obj});
            } else {
                PublicUtils.navigate('Login', {nextPage: '/'});
            }
        }
    };

    render() {
        const styles = {
            gameBox: {
                paddingLeft: 10,
                backgroundColor: "#fff",
                display: "flex"
            },
            gameInnerBox: {
                flex: 1,
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME],
                paddingTop: 10,
                paddingBottom: 10,
                overflow: "hidden"
            },
            gameIconBox: {
                display: "flex",
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
            gameIcon: {
                display: "flex",
                width: 60,
                height: 60,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 3
            },
            gameDetailBox: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginLeft: 10,
                paddingRight: 10,
                width: 0
            },
            gameName: {
                display: "flex",
                flex: 1
            },
            gameNameText: {
                fontSize: 16,
                color: "#000",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            gameInfo: {
                marginTop: 5,
                flex: 1,
                display: "flex",
                fontSize: 12,
                color: "#999",
                lineHeight: "17px",
            },
            gameInfoTextBox: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
            gameInfoText: {},
            gameInfo1: {
                marginTop: 3,
                fontSize: 12,
                color: "#999",
                lineHeight: "17px",
                display: "flex",
                flex: 1,
            },
            gameInfoTextBox1: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
        };
        const logo = "https://newpic.hdyb33.com/new/mobile_game_icon/" + this.props.icon + ".png?&v=" + +new Date().getTime().toString();
        return (
            <div onClick={() => {
                this._enterGame();
                // PublicUtils._enterGame(this.props.obj)
            }} style={styles.gameBox}>
                <div style={styles.gameInnerBox}>
                    <div style={styles.gameIconBox}>
                        <div style={styles.gameIcon}>
                            {/*<Icon icon={this.props.icon} color={'#fff'} size={45}/>*/}
                            <Image style={{width: 30}} resizeMode='contain'
                                   uri={logo}/>
                        </div>
                    </div>
                    <div style={styles.gameDetailBox}>
                        <div style={styles.gameName}>
                            {this.props.name}
                        </div>
                        <div style={styles.gameInfo}>
                            <div
                                style={styles.gameInfoTextBox}>{this.props.memo1}</div>
                        </div>
                        <div style={styles.gameInfo1}>
                            <div
                                style={styles.gameInfoTextBox1}>{this.props.memo2}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default PlatformCard
