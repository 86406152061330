/**
 * 下级代理 (无限级)
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import MyInput from "../component/myInput";
import DatePicker from "react-mobile-datepicker";

export default class AgentReport2 extends React.PureComponent {
  // 构造
  constructor(props) {
    super(props);


    this.state = {
      showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
      total: 0,//总页数
      currpage: 1,//当前页
      list: [],
      dlList:[],
      limit:20,
      riqi:'',
      time: new Date(), // 日期选择用
      isOpen:false, // 日期选择用 DatePicker
    }

    this.state.riqi = this.formatDate(this.state.time)

  }

  /**
   * 页面进入时
   * @returns {Promise<void>}
   */
  componentWillMount = () => {
    this._init_();
  };

  /**
   * 初始化
   */
  _init_() {
    // this._fetchData({page: this.state.currpage,limit: this.state.limit});
    this._fetchData({date:this.state.riqi});
  }



  _checkBirthday = (data) => {
    // if (!this._checkRealName()) {
    //     return false;
    // }
    return true;
  };

  /**
   * 获取数据
   * @private
   */
  _fetchData = (parma) => {
    //
    this.setState({showFoot: 2});
    Http.request("agentReport2", parma, (res) => {
      if (res.code === 300) {
        Storage.clearData();
        PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'SubUsers'});
      }
      if (res.code === 200) {


        let state = {
          own_valid_bet: 0, // 直属业绩
          total_valid_bet: 0, // 总业绩
          sub_valid_bet: 0, // 团队业绩
          total_brokerage: 0, // 总返佣
          has_brokerage: 0, // 已返佣
          need_brokerage: 0, // 未返佣
        };

        if(res.data.length != 0){
          state = {
            own_valid_bet: res.data[0].own_valid_bet || 0, // 直属业绩
            total_valid_bet: res.data[0].total_valid_bet || 0, // 总业绩
            sub_valid_bet: res.data[0].sub_valid_bet || 0, // 团队业绩
            total_brokerage: res.data[0].total_brokerage || 0, // 总返佣
            has_brokerage: res.data[0].has_brokerage || 0, // 已返佣
            need_brokerage: res.data[0].need_brokerage || 0, // 未返佣
          };
        }


        this.setState(state);
      }else{
        PublicUtils.showToast('暂无总计信息');
      }
    });

    // 用户下级流水
    this.getxjls();
  };
  // 用户下级流水
  getxjls(){
    let parma = {date:this.state.riqi}
    Http.request("agentReport3", parma, (res) => {
      if (res.code === 300) {
        Storage.clearData();
        PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'SubUsers'});
      }
      if (res.code === 200) {

        if(res.data.length  == 0){
          this.setState({showFoot: 1});
          return
        }

        this.setState({list:res.data});
      }else{
        PublicUtils.showToast('暂无代理流水信息');
      }
    });
  }

  // 日期格式化用
  formatDate = (date) => {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    let minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    let second = date.getSeconds();
    second = minute < 10 ? ('0' + second) : second;
    return (y + '-' + m + '-' + d).toString();
  };


  /**
   * 刷新
   * @private
   */
  _onRefresh = () => {
    this.setState({currpage: 1}, () => {
      //获取数据
      this._fetchData({day:this.state.riqi});
    });
  };

  _onEndReached() {
    // if (this.state.currpage === this.state.total) {
    //   this.setState({showFoot: 1});
    //   return;
    // }
    this.setState({currpage: this.state.currpage + 1}, () => {
      //获取数据

    });

  }

  /**
   * 页脚
   * @returns {*}
   * @private
   */
  _renderFooter() {
    const styles = {
      box: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        backgroundColor: "#fff",
        borderBottom: '1px solid #f5f7f9',
      },
      left: {},
      leftTop: {
        display: "flex",
        flexDirection: "row"
      },
      leftBottom: {},
      Right: {
        display: "flex",
        flexDirection: "row"
      },
      user_account: {
        fontSize: 16,
        color: "#000"
      },
      user_account_offline: {
        color: "rgba(0,0,0,0.3)"
      },
      offlineBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      },
      offlineState: {
        width: 5,
        height: 5,
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "rgba(0,0,0,0.3)"
      },
      offline: {
        color: "rgba(0,0,0,0.3)"
      },
      onlineBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      },
      onlineState: {
        width: 5,
        height: 5,
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "#00a60a"
      },
      online: {
        color: "#00a60a"
      },
      time: {
        fontSize: 12,
        color: "rgba(0,0,0,0.3)",
        marginTop: 10
      },
      balance: {
        fontSize: 16,
        color: "#000"
      },
      footer: {
        display: "flex",
        flexDirection: "column"
      },
      footerBody: {
        display: "flex",
        height: 40,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #ddd",
        margin: "10px",
        borderRadius: 6
      },
      footerBodyText: {
        fontSize: 14,
        color: "#bbbbbb"
      },
      footerPadding: {
        height: 50
      },
      textSpace: {
        marginRight: 3
      }
    };
    let renderView = null;
    if (this.state.showFoot === 1) {
      if (!this.state.list.length) {
        renderView = (
          <NoneData/>
        );
      } else {
        renderView = (
          <div style={styles.footer}>
            <div style={styles.footerBody}>
              <div style={styles.footerBodyText}>
                没有更多数据了
              </div>
            </div>
            <div style={styles.footerPadding}/>
          </div>
        );
      }
    } else if (this.state.showFoot === 2) {
      if (!this.state.list.length) {
        renderView = (
          <Loadding/>
        );
      } else if(this.state.showFoot == 100)  {
        renderView = (
          <div style={styles.footer}>
            <div style={styles.footerBody} onClick={() => {
              this._onEndReached();
            }}>
              <div style={styles.footerBodyText}>
                点击加载更多
              </div>
            </div>
            <div style={styles.footerPadding}/>
          </div>
        );
      }
    } else if (this.state.showFoot === 0) {
      renderView = (
        <div style={styles.footer}>
          {/*<View style={styles.footerBody}>*/}
          {/*<Text style={styles.footerBodyText}>*/}
          {/*努力加载中...*/}
          {/*</Text>*/}
          {/*</View>*/}
          <div style={styles.footerPadding}/>
        </div>
      );
    }
    return renderView;
  }

  /**
   * 给下级会员转账
   * @private
   */
  _showDetail = async (item) => {
    let user = JSON.parse(await Storage.retrieveData("user"));
    if (user.is_agent_deposit) {
      const data = {
        user_account: item.user_account, refresh: () => {
          this._init_();
        }
      };
      PublicUtils.navigate("SubUserTransfer", data);
    }
  };

  render() {
    const styles = {
      box: {
        margin: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        backgroundColor: "#fff",
        border: '1px solid rgba(0,0,0,0.06)',
        borderRadius: 6
      },
      left: {},
      leftTop: {
        display: "flex",
        flexDirection: "row"
      },
      leftBottom: {},
      Right: {
        display: "flex",
        flexDirection: "row"
      },
      user_account: {
        fontSize: 16,
        color: "#000"
      },
      user_account_offline: {
        color: "rgba(0,0,0,0.3)"
      },
      offlineBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      },
      offlineState: {
        width: 5,
        height: 5,
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "rgba(0,0,0,0.3)"
      },
      offline: {
        color: "rgba(0,0,0,0.3)"
      },
      onlineBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      },
      onlineState: {
        width: 5,
        height: 5,
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "#00a60a"
      },
      online: {
        color: "#00a60a"
      },
      time: {
        fontSize: 12,
        color: "rgba(0,0,0,0.3)",
        marginTop: 10
      },
      balance: {
        fontSize: 16,
        color: "#000"
      },
      footer: {
        display: "flex",
        flexDirection: "column"
      },
      footerBody: {
        display: "flex",
        height: 40,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #ddd",
        margin: "10px",
        borderRadius: 6
      },
      footerBodyText: {
        fontSize: 14,
        color: "#bbbbbb"
      },
      footerPadding: {
        height: 50
      },
      textSpace: {
        marginRight: 3
      },
      userInfoBox: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
        position: "fixed",
        top: 106,
        height: 60,
        left: 0,
        right: 0
      },
      userInfoItemBox: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px solid #f5f7f9",
        paddingLeft: 10,
        paddingRight: 10
      },
      userInfoItemContent: {
        fontSize: 20,
        color: "#000"
      },
      userInfoItemTitle: {
        fontSize: 12,
        color: "rgba(0,0,0,1)",
        marginTop: 10
      },
      contentBox: {
        marginTop: 170,
        padding: 5
      }
    };
    return (
      <Container title={'下级会员'}>

        <div style={{
          display:'flex',
          alignItems: "center",
          minHeight:36,
          marginTop:10,
        }}>
          <span>选择日期：</span>
          <div style={{
            width: 150,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            marginRight: 10,
            border:'1px solid #cccc'
          }}>
            <MyInput onPress={()=>{
              this.setState({isOpen:true})
            }} readOnly={true}
                     value={this.state.riqi}
                     placeholder={'请选择查询日期'}
                     style={{}} textContentType={'username'}
                     onChangeText={(text) => this._checkBirthday({
                       riqi: text
                     })}/>
          </div>
          <span
            style={{
              display: 'inline-block',
              padding: 4,
              border: '1px solid #ccc',
              backgroundColor: '#ccc'
            }}
            onClick={()=>{
            this._fetchData({date:this.state.riqi});
          }}>查询</span>
        </div>

        <div style={styles.userInfoBox}>
          <div style={styles.userInfoItemBox}>
            <div style={styles.userInfoItemContent}>{this.state.own_valid_bet}</div>
            <div style={styles.userInfoItemTitle}>直属业绩</div>
          </div>
          <div style={styles.userInfoItemBox}>
            <div style={styles.userInfoItemContent}>{this.state.sub_valid_bet}</div>
            <div style={styles.userInfoItemTitle}>团队业绩</div>
          </div>
          <div style={styles.userInfoItemBox}>
            <div style={styles.userInfoItemContent}>{this.state.total_valid_bet}</div>
            <div style={styles.userInfoItemTitle}>总业绩</div>
          </div>
        </div>
        <div style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          backgroundColor: "#fff",
          paddingTop: 10,
          paddingBottom: 10,
          position: "fixed",
          top: 186,
          height: 60,
          left: 0,
          right: 0,
        }}>
          <div style={styles.userInfoItemBox}>
            <div style={styles.userInfoItemContent}>{this.state.has_brokerage}</div>
            <div style={styles.userInfoItemTitle}>已返佣</div>
          </div>
          <div style={styles.userInfoItemBox}>
            <div style={styles.userInfoItemContent}>{this.state.total_brokerage}</div>
            <div style={styles.userInfoItemTitle}>总返佣</div>
          </div>
          <div style={styles.userInfoItemBox}>
            <div style={styles.userInfoItemContent}>{this.state.need_brokerage}</div>
            <div style={styles.userInfoItemTitle}>未返佣</div>
          </div>
        </div>
        <div style={styles.contentBox}>
          {
            this.state.list.map((item, key) => {
              return (
                <div key={key}  style={styles.box}>
                  <div style={styles.left}>
                    <div style={styles.leftBottom}>
                      <div style={styles.balance}>帐号：{item.user_account}</div>
                    </div>
                  </div>
                  <div style={styles.Right}>
                    <div style={styles.balance}>有效流水：{item.valid_bet}</div>
                  </div>
                </div>
              )
            })
          }
          {
            this._renderFooter()
          }
        </div>

        <DatePicker
          theme={'ios'}
          value={this.state.time}
          isOpen={this.state.isOpen}
          onSelect={(time)=>{
            this.setState({riqi: this.formatDate(time), isOpen: false});
          }}
          onCancel={()=>{
            this.setState({isOpen: false});
          }}/>
      </Container>
    )
  }
}
