/**
 * 投注类别
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "../../../../utils/icon";
import MyPicker from "../../../../component/myPicker";
import PublicUtils from "../../../../utils/publicUtils";
import model from "../../../../services/model";
import style from '../../../../utils/config.scss'
import Http from "../../../../services/http";

const footerBarHeight = 55;
export default class FooterBar extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {}
    }

    static propTypes = {
        betinfo: PropTypes.object,
        balance: PropTypes.number,
        odds: PropTypes.number,
        playId: PropTypes.number,
        ballId: PropTypes.number,
        betId: PropTypes.number,
        clearBetInfo: PropTypes.func
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    _init_ = () => {
        this._fetchBalance();
    }

    /**
     * 获取余额
     */
    _fetchBalance() {
        let parm = {action:'refresh'};
        Http.request("memberBalance", parm, async (res) => {
            // alert(JSON.stringify(res));
            if (res.code === 200) {
                if (res.data && res.data.status) {
                    await PublicUtils.updateUserInfo({is_balance: res.data.balance.balance});
                    this.setState({balance: res.data.balance.balance});
                }
            }
        });
    }

    /**
     * 确认是否清楚过关投注
     * @private
     */
    _clearCGBet = () => {
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要清除所有投注吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._clearingBetInfo();
                    }
                },
            ]
        });
    };

    /**
     * 清除过关投注
     * @private
     */
    _clearingBetInfo = () => {
        this.props.clearBetInfo();
    };

    render() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column"
            },
            headerBox: {
                zIndex: 2,
                backgroundColor: "#fff"
            },
            bodyBox: {
                overflow: "auto"
            },
            disabledBtn: {
                opacity: 0.6
            },
            ballTypeWrapper: {
                width: "100%",
                overflow: "auto"
            },
            ballTypeBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgBox: {
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            operatorBar: {
                display: "flex",
                height: 40,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f5f5",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
            },
            openAllBtn: {
                marginRight: 5,
                color: "#444444",
                fontSize: 14
            },
            openAllBtnText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            pickerWrapper: {
                display: "flex",
                flex: 1
            },
            footerBox: {
                display: "flex",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                height: footerBarHeight,
                backgroundColor: "#fff",
                borderTop: "1px solid #f1f1f1",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10
            },
            clearBetBtn: {
                display: "flex", flexDirection: "row"
            },
            clearBetBtnText: {
                color: "#333", marginLeft: 3, fontSize: 16
            },
            betItems: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            betItemsText: {
                color: "#fff"
            },
            betModal: {
                display: "flex",
                backgroundColor: "rgba(0,0,0,0.3)",
                flex: 1,
            },
            betInnerBox: {
                position: "absolute",
                left: 20,
                right: 20,
                backgroundColor: "#fff",
                borderRadius: 4,
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#eff3f6"
            },
            betInnerBody: {
                padding: 10
            },
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3
            },
            betAmountInputWrapper: {
                border: "1px solid #f5f7f9",
                height: 30,
                width: 130,
                borderRadius: 4,
                fontSize: 12,
            },
            betAmountInput: {
                display: "flex",
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 14,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 14,
                color: "#e8ab09"
            },
            betOddsTips: {
                display: "flex",
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },

            pickerItem: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                alignItems: "center"
            },
            headerToolbarItemText: {
                color: "rgba(0,0,0,1)",
                fontSize: 14
            }
        };
        const footerStyles = {
            footerBox: {
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: footerBarHeight,
                alignItems: "center",
                borderTop: "1px solid #f5f7f9",
                zIndex: 10,
                backgroundColor: "#fff"
            },
            footerLeftBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginLeft: 10,
            },
            footerLeftTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            footerLeftBottomBox: {
                color: "rgba(0,0,0,0.5)",
                marginTop: 5,
                fontSize: 12
            },
            betv: {
                color: "#000",
                fontSize: 16,
                marginRight: 2
            },
            bett: {
                color: "#000",
                marginRight: 2,
                fontSize: 16
            },
            btnBox: {
                display: "flex",
                flexDirection: "row"
            },
            btn: {
                display: "flex",
                height: 35,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColorDeep' + model.SKINNAME],
                width: 85,
                fontSize: 14,
                borderRadius: 35,
                marginRight: 10
            },
            clearBetBtn: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            btndiv: {
                color: "#fff",
            },
        };
        return (
            <div style={footerStyles.footerBox}>
                <div style={footerStyles.footerLeftBox}>
                    <div style={footerStyles.footerLeftTopBox}>
                        {/*<Text style={footerStyles.bett}>共</Text>*/}
                        <div style={footerStyles.betv}>{this.props.betinfo._CG_.list.length}</div>
                        <div style={footerStyles.bett}>串</div>
                        <div style={footerStyles.bett}>1</div>
                        {
                            this.props.odds ? <div style={footerStyles.betv}>@{this.props.odds}</div> : null
                        }
                        {/*<Text style={footerStyles.bett}>元</Text>*/}
                    </div>
                    {
                        this.state.balance ?
                            <div style={footerStyles.footerLeftBottomBox}>
                                余额（￥{this.state.balance} 元）
                            </div> : null
                    }
                </div>
                <div style={footerStyles.btnBox}>
                    <div activeOpacity={0.8} style={{...footerStyles.btn, ...footerStyles.clearBetBtn}} onClick={() => {
                        this._clearCGBet();
                    }}>
                        <div style={{flexDirection: "row", display: "flex"}}>
                            <Icon icon={"laji"} size={16} color={"#fff"}/>
                            <div style={{...footerStyles.btndiv, ...{marginLeft: 5}}}>清空</div>
                        </div>
                    </div>
                    <div activeOpacity={0.8}
                         disabled={this.props.betinfo._CG_.list.length === 0}
                         onClick={() => {
                             // this.setModalVisible(true);
                             if (this.props.betinfo._CG_.list.length) {
                                 const parma = {
                                     playId: this.props.playId,
                                     ballId: this.props.ballId,
                                     betId: this.props.betId,
                                     betinfo: this.props.betinfo,
                                 };
                                 PublicUtils.navigate("HgBetting", parma);
                             }
                         }}
                         style={{...footerStyles.btn, ...!this.props.betinfo._CG_.list.length ? styles.disabledBtn : null}}>
                        <div
                            style={footerStyles.btndiv}>我的注单
                        </div>
                    </div>
                </div>
            </div>


            //
            // <div style={styles.footerBox}>
            //     <div onClick={() => {
            //         this._clearCGBet();
            //     }} style={styles.clearBetBtn}>
            //         <Icon icon={"laji"} size={20} color={"#333"}/>
            //         {
            //             this.betinfo._CG_.list.length ?
            //                 <div style={styles.footerBetAmountBox}>
            //                     <div style={styles.footerBetAmountItem}>{this.props.betinfo._CG_.list.length}</div>
            //                     <div style={styles.footerBetAmountItem}>串</div>
            //                     <div style={styles.footerBetAmountItem}>1</div>
            //                     <div style={styles.footerBetAmountItem}>@{this.props.odds}</div>
            //                 </div> :
            //                 <div style={styles.footerBetAmountItem}>请下注</div>
            //         }
            //     </div>
            //     <div
            //         onClick={() => {
            //             if (this.props.betinfo._CG_.list.length !== 0) {
            //                 const parma = {
            //                     playId: this.props.playId,
            //                     ballId: this.props.ballId,
            //                     betId: this.props.betId,
            //                     betinfo: this.props.betinfo,
            //                 };
            //                 PublicUtils.navigate("HgBetting", parma);
            //             }
            //         }}
            //         style={{...styles.betItems, ...this.props.betinfo._CG_.list.length === 0 ? styles.disabledBtn : null}}>
            //         <div style={styles.betItemsText}>我的注单</div>
            //     </div>
            // </div>
        )
    }
}
