/**
 * 按钮
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import model from "../../../services/model";
import Icon from '../../../utils/icon'
import style from "../../../utils/config.scss";
import global from "../../../global";

class GameTab extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        onPress: PropTypes.func,
        actived: PropTypes.bool,
        icon: PropTypes.string,
        name: PropTypes.string,
        length: PropTypes.number
    };
    static defaultProps = {};

    render() {
        const size = (model.clientWidth / this.props.length) - 15;
        const styles = {
            gameTabbarItemContainer: {
                display:"flex",
                width: model.clientWidth / this.props.length,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 10,
                paddingBottom: 10,
                // borderTopWidth: 1,
                // borderTopColor: "rgba(0,0,0,0.05)",
                // borderBottomWidth: 1,
                // borderBottomColor: "rgba(0,0,0,0.05)",
                // borderStyle: "solid"
                borderBottom:"1px solid rgba(0,0,0,0.05)"
            },
            gameTabbarIconContainer: {
                display:"flex",
                width: size,
                height: size,
                borderRadius: size,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                marginBottom: 5,
            },
            gameTabbarIcon: {},
            gameTabbarText: {
                color: "#fff"
            },
            gameTabbarItemContainerActive: {
                borderBottom: "2px solid "+style['primaryLight' + model.SKINNAME]
                // borderBottomWidth: 2,
                // borderBottomColor: style['primaryLight' + model.SKINNAME],
                // borderStyle: "solid"
            },
            gameTabbarIconContainerActive: {
                backgroundColor: global == 'w7'? "rgba(0,0,0,0.05)":"rgba(255,255,255,0.1)"
            },
            gameTabbarTextActive: {
                color: style['primaryLight' + model.SKINNAME]
            },
        };
        return (
            <div style={{flex: 1}} type={1}
                 onClick={this.props.onPress}>
                <div
                    style={{...styles.gameTabbarItemContainer, ...this.props.actived ? styles.gameTabbarItemContainerActive : null}}>
                    <div
                        style={{...styles.gameTabbarIconContainer, ...this.props.actived ? styles.gameTabbarIconContainerActive : null}}>
                        <Icon style={styles.gameTabbarIcon} icon={this.props.icon}
                              color={this.props.actived ? style['primaryLight' + model.SKINNAME] : style['primaryColorDeep' + model.SKINNAME]}
                              size={25}/>
                    </div>
                    <div
                        style={{...styles.gameTabbarText, ...this.props.actived ? styles.gameTabbarTextActive : null}}>{this.props.name}</div>
                </div>
            </div>
        )

    }
}

export default GameTab;
