/**
 * 近期开机那个
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Http from "../../../services/http";
import PublicUtils from "../../../utils/publicUtils";
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";
import Image from "../../../component/image";
import dice1 from "../../../assets/images/dice1.png";
import dice2 from "../../../assets/images/dice2.png";
import dice3 from "../../../assets/images/dice3.png";
import dice4 from "../../../assets/images/dice4.png";
import dice5 from "../../../assets/images/dice5.png";
import dice6 from "../../../assets/images/dice6.png";

export default class RecentOpenList extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showList: false,
            lotteryHistoryResult: {
                firstResult: {
                    is_number: ""
                },
                list: []
            }

        };
    }

    static propTypes = {
        templateType: PropTypes.string,
        code: PropTypes.number,
        lottery_no: PropTypes.number
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        // this._init_();
    };

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchHistoryResult();
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.setState({second: this.props.second}, () => {
        //     this._countdown();
        // });
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.lottery_no !== this.props.lottery_no) {
            this._init_();
        }
    };

    /**
     * 显示最近开奖
     * @private
     */
    _showOpenList = () => {

    };
    /**
     * 隐藏最近开奖
     * @private
     */
    _hideOpenList = () => {
    };


    /**
     * 获取历史开奖结果
     * */
    _fetchHistoryResult = () => {
        // this.lotteryHistoryResult.list = [];
        const params = {is_code: this.props.code, is_number: 5};
        Http.request("lotteryNumberList", params, (res) => {
            // alert(JSON.stringify(res));
            if (res.data.status === 1) {
                let lotteryHistoryResult = {
                    firstResult: res.data.number[0],
                    list: res.data.number
                };
                lotteryHistoryResult.list.splice(0, 1);
                // this.lotteryHistoryResult = {};
                // this.lotteryHistoryResult.list = res.data.number;
                // if (this.state.lotteryInfo.lottery_no - this.lotteryHistoryResult.list[0].is_no === 1) {
                //     this.lotteryHistoryResult.firstResult = this.lotteryHistoryResult.list[0];
                //     this.lotteryHistoryResult.firstResult.hasResult = true;
                //     this.lotteryHistoryResult.list.splice(0, 1);
                // } else {
                //     this.lotteryHistoryResult.firstResult = {
                //         is_no: this.state.lotteryInfo.lottery_no - 1,
                //         hasResult: false,
                //         is_number: "",
                //         message: "正在开奖"
                //     };
                //     this.lotteryHistoryResult.list = res.data.number;
                //     this._fetchOpenResult(this.lotteryHistoryResult.firstResult.is_no);
                // }
                this.setState({lotteryHistoryResult: lotteryHistoryResult});
            } else {
                PublicUtils.showToast(res.data.message);
            }
        })
    };

    /**
     * 轮询开奖结果
     * */
    // _pollingOpenResult=()=>{
    //     window.clearInterval(this.timerAndalert.openTimeout);
    //     this.timerAndalert.openTimeout = window.setInterval(() => {
    //         this._fetchOpenResult(lottery_no);
    //     }, 2000)
    // };

    /**
     * 获取当前期号的开奖结果
     */
    _fetchOpenResult(lottery_no) {
        let parm = {is_no: lottery_no, is_code: this.is_code};
        Http.request("getLotteryOpen", parm, (res) => {
            if (res.data && res.data.status) {
                if (res.data.number) {
                    //this.lotteryBaseInfo.curOpenNumber = res.data.number;
                    //this.getHistoryResult();
                    this.lotteryHistoryResult.firstResult = {
                        is_no: lottery_no,
                        hasResult: true,
                        is_number: res.data.number,
                        message: "已开奖..."
                    }
                } else {
                    this.pollingOpenResultTimer && window.clearInterval(this.pollingOpenResultTimer);
                    this.pollingOpenResultTimer = setTimeout(() => {
                        this._fetchOpenResult(lottery_no);
                    }, 2000)
                }
            }
        });
    }

    /**
     * 开奖结果总和
     * @param numbers
     * @returns {number}
     */
    _total = (numbers) => {
        let numList = numbers.split(',');
        let total = 0;
        for (let i = 0; i < numList.length; i++) {
            total += Number(numList[i]);
        }
        return total;
    };

    /**
     *色子
     * @private
     */
    _renderDice = (number, size) => {
        const styles = {
            diceImg: {
                width: 19,
                height: 19,
                marginLeft: 5,
            },
            openListBox: {
                backgroundColor: "#fff",
                position: "absolute",
                top: 0,
                width: "100%",
                zIndex: 3,
                fontSize: 14
            },
        };
        if (number == 1) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice1}/>
            )
        }
        if (number == 2) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice2}/>
            )
        }
        if (number == 3) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice3}/>
            )
        }
        if (number == 4) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice4}/>
            )
        }
        if (number == 5) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice5}/>
            )
        }
        if (number == 6) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice6}/>
            )
        }
    };

    _renderOpenNumber = (is_number) => {
        const headerStyles = {
            openBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 35,
                alignItems: "center",
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9",
                paddingLeft: 10,
                paddingRight: 10,
            },
            openNoBox: {
                display: "flex",
                flexDirection: "row"
            },
            openNo: {
                color: "#0a9dc7",
                marginRight: 3
            },
            openNumberBox: {
                display: "flex",
                flexDirection: "row"
            },
            ballbox: {
                display: "flex",
                flexDirection: "row"
            },
            ball: {
                width: 18,
                height: 18,
                borderRadius: 18,
                overflow: "hidden",
                textAlign: "center",
                lineHeight: "18px",
                marginLeft: 3,
                color: "#fff",
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                fontSize: 10
            },
            pksBall: {
                height: 23,
                borderRadius: 2,
                color: "#fff",
                lineHeight: "23px",
                textAlign: "center",
                fontSize: 12,
                width: 16,
                marginLeft: 3
            },
            color1: {
                backgroundColor: "#fcf53c"
            },
            color2: {
                backgroundColor: "#4990e2"
            },
            color3: {
                backgroundColor: "#4a4a4a"
            },
            color4: {
                backgroundColor: "#f6a623"
            },
            color5: {
                backgroundColor: "#50b8e3"
            },
            color6: {
                backgroundColor: "#5d12fe"
            },
            color7: {
                backgroundColor: "#b4b3b3"
            },
            color8: {
                backgroundColor: "#d0011b"
            },
            color9: {
                backgroundColor: "#801111"
            },
            color10: {
                backgroundColor: "#7ed321"
            },
            symbol: {
                marginLeft: 3
            },
            timeBox: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                paddingBottom: 10,
            },
            timeTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 5
            },
            timeTopLeftBox: {
                display: "flex",
                flexDirection: 'row'
            },
            timeTips: {
                color: "rgba(0,0,0,0.7)",
                marginRight: 3
            },
            timeTopRightBox: {},
            timeBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 5
            },
            timeBottomLeftBox: {
                display: "flex",
                flexDirection: "row"
            },
            timeVlaue: {
                color: "rgba(246, 48, 85, 0.94)",
                marginRight: 3,
                fontSize: 14
            },
            timeBottomRightBox: {
                fontSize: 18,
                color: "rgba(246, 48, 85, 0.94)"
            },
            openListBox: {
                backgroundColor: "#fff"
            }

        };

        //pc蛋蛋
        if (this.props.templateType === "PCDD") {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    if (i < is_number.split(',').length - 1) {
                        balls = <div key={i} style={headerStyles.ballbox}>
                            <div
                                style={headerStyles.ball}>{number}</div>
                            <div
                                style={headerStyles.symbol}>+
                            </div>
                        </div>;
                    }
                    if (i === is_number.split(',').length - 1) {
                        balls = <div key={i} style={headerStyles.ballbox}>
                            <div
                                style={headerStyles.ball}>{number}</div>
                            <div
                                style={headerStyles.symbol}>=
                            </div>
                        </div>;
                    }
                    return balls;
                })
            );
        }
        //pk拾、快乐飞艇
        else if (this.props.templateType === "PK10") {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    balls = <div key={i} style={headerStyles.ballbox}>
                        <div
                            style={{...headerStyles.pksBall, ...headerStyles["color" + number]}}>{number}</div>
                    </div>;
                    return balls;
                })
            )
        }
        //快三
        else if (this.props.templateType==="KS") {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    balls = <div key={i} style={headerStyles.ballbox}>
                        {this._renderDice(number)}
                    </div>;
                    return balls;
                })
            )
        }
        //其他彩种
        else {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    balls = <div key={i} style={headerStyles.ballbox}>
                        <div
                            style={headerStyles.ball}>{number}</div>
                    </div>;
                    return balls;
                })
            );
        }
    };

    render() {
        const styles = {
            diceImg: {
                width: 19,
                height: 19,
                marginLeft: 5,
            },
            openListBox: {
                backgroundColor: "#fff",
                position: "absolute",
                top: 0,
                width: "100%",
                zIndex: 3,
                fontSize: 14
            },
        };

        const headerStyles = {
            openBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 35,
                alignItems: "center",
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9",
                paddingLeft: 10,
                paddingRight: 10,
            },
            openNoBox: {
                display: "flex",
                flexDirection: "row"
            },
            openNo: {
                color: "#0a9dc7",
                marginRight: 3
            },
            openNumberBox: {
                display: "flex",
                flexDirection: "row"
            },
            ballbox: {
                display: "flex",
                flexDirection: "row"
            },
            ball: {
                width: 18,
                height: 18,
                borderRadius: 18,
                overflow: "hidden",
                textAlign: "center",
                lineHeight: "18px",
                marginLeft: 3,
                color: "#fff",
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                fontSize: 10
            },
            pksBall: {
                height: 23,
                borderRadius: 2,
                color: "#fff",
                lineHeight: "23px",
                textAlign: "center",
                fontSize: 12,
                width: 16,
                marginLeft: 3
            },
            color1: {
                backgroundColor: "#fcf53c"
            },
            color2: {
                backgroundColor: "#4990e2"
            },
            color3: {
                backgroundColor: "#4a4a4a"
            },
            color4: {
                backgroundColor: "#f6a623"
            },
            color5: {
                backgroundColor: "#50b8e3"
            },
            color6: {
                backgroundColor: "#5d12fe"
            },
            color7: {
                backgroundColor: "#b4b3b3"
            },
            color8: {
                backgroundColor: "#d0011b"
            },
            color9: {
                backgroundColor: "#801111"
            },
            color10: {
                backgroundColor: "#7ed321"
            },
            symbol: {
                marginLeft: 3
            },
            timeBox: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                paddingBottom: 10,
            },
            timeTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 5
            },
            timeTopLeftBox: {
                display: "flex",
                flexDirection: 'row'
            },
            timeTips: {
                color: "rgba(0,0,0,0.7)",
                marginRight: 3
            },
            timeTopRightBox: {},
            timeBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 5
            },
            timeBottomLeftBox: {
                display: "flex",
                flexDirection: "row"
            },
            timeVlaue: {
                color: "rgba(246, 48, 85, 0.94)",
                marginRight: 3,
                fontSize: 14
            },
            timeBottomRightBox: {
                fontSize: 18,
                color: "rgba(246, 48, 85, 0.94)"
            },
            openListBox: {
                backgroundColor: "#fff"
            }

        };

        const openstyles = {
            openListBox: {
                fontSize: 14,
                position: "absolute",
                left: 0,
                top: 36,
                width: "100%",
                zIndex: 2,
                transition: "all 0.3s"
            },
            showList: {
                top: 0
            },
            openListBoxShadow: {
                position: "absolute",
                left: 0,
                top: "-100%",
                width: "100%",
                height: "100%",
                zIndex: 1,
                transition: "all 0.3s"
            }
        };
        return (
            <div>
                <div style={styles.openListBox}>
                    <div onClick={() => {
                        this.setState({showList: true});
                        // this.state.openListTranslateY._value ? this._hideOpenList() : this._showOpenList();
                    }}>
                        <div style={headerStyles.openBox}>
                            <div style={headerStyles.openNoBox}>
                                <div style={headerStyles.timeTips}>第</div>
                                <div
                                    style={headerStyles.openNo}>{this.state.lotteryHistoryResult.firstResult.is_no && this.state.lotteryHistoryResult.firstResult.is_no}</div>
                                <div style={headerStyles.timeTips}>期</div>
                            </div>
                            <div style={headerStyles.openNumberBox}>
                                <div style={headerStyles.openNumberBox}>
                                    {
                                        this._renderOpenNumber(this.state.lotteryHistoryResult.firstResult.is_number)
                                    }
                                    {
                                        this.props.templateType === "PCDD" ?
                                            <div
                                                style={headerStyles.ball}>{this._total(this.state.lotteryHistoryResult.firstResult.is_number)}</div> : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* 近期开奖 */}
                {

                    <div onClick={() => {
                        this.setState({showList: false});
                    }} style={{...openstyles.openListBoxShadow, ...this.state.showList ? openstyles.showList : null}}>
                        <div onClick={(event) => {
                            event.stopPropagation();
                        }}
                             style={{...openstyles.openListBox}}>
                            {
                                this.state.lotteryHistoryResult.list && this.state.lotteryHistoryResult.list.map((item, i) => {
                                    return (

                                        <div key={i} style={{...headerStyles.openBox, ...headerStyles.openListBox}}>
                                            <div style={headerStyles.openNoBox}>
                                                <div style={headerStyles.timeTips}>第</div>
                                                <div style={headerStyles.openNo}>{item.is_no}</div>
                                                <div style={headerStyles.timeTips}>期</div>
                                            </div>
                                            <div style={headerStyles.openNumberBox}>
                                                {
                                                    this._renderOpenNumber(item.is_number)
                                                }
                                                {
                                                    this.props.templateType === "PCDD" ?
                                                        <div
                                                            style={headerStyles.ball}>{this._total(item.is_number)}</div> : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}