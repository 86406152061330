/**
 * 投注弹窗
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PublicUtils from "../../../../utils/publicUtils";
import Http from "../../../../services/http";
import Storage from "../../../../services/storage";
import model from "../../../../services/model";
import ActivityIndicator from "../../../../component/activityIndicator";
import MyInput from "../../../../component/myInput";
import MyButton from "../../../../component/myButton";
import Icon from "../../../../utils/icon";
import style from '../../../../utils/config.scss'

class BetDialog extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            translateY: 0,
            opacity: 0,
            min: 0,
            max: 0,
            betAmount: null,//投注金额
            canWin: 0,//可赢金额
            showProgress: false,
            betTips: "",
            betinfo: {
                _GQ_JR_ZP_GJ_: {
                    info: []
                },
                _CG_: {}
            }
        };
        this._fetchMaxBetAmount();
    }

    static propTypes = {
        playId: PropTypes.number,
        ballId: PropTypes.number,
        betId: PropTypes.number,
        modalVisible: PropTypes.bool,
        betinfo: PropTypes.object,
        onClose: PropTypes.func,
        delCGItem: PropTypes.func,
        onBetSuccess: PropTypes.func
    };
    static defaultProps = {
        betinfo: {
            _GQ_JR_ZP_GJ_: {},
            _CG_: {list: [], odds: ""}
        }
    };

    amountTemplate = [
        {
            name: "10",
            value: 10
        },
        {
            name: "20",
            value: 20
        },
        {
            name: "50",
            value: 50
        },
        {
            name: "100",
            value: 100
        },
        {
            name: "500",
            value: 500
        },
        {
            name: "1000",
            value: 1000
        }
    ];
    componentWillReceiveProps = (nextProps) => {
        // alert(JSON.stringify(nextProps.betinfo));
        // if (nextProps.modalVisible) {
        //     this.setState({modalVisible: nextProps.modalVisible}, () => {
        //         this.setState({betinfo: nextProps.betinfo});
        //     });
        // }else{
        //     this.setState({modalVisible: nextProps.modalVisible});
        // }
        if (nextProps.betinfo) {
            // this.setState({betinfo: nextProps.betinfo});
        }
    };


    /**
     *投注金额
     */
    setBetAmount(amount) {

        let totalOdd = this.getTotalOdd();
        let canwin = amount * totalOdd;
        if (this.props.playId === 4) {
            canwin = amount * (totalOdd - 1);
        }
        this.setState({betAmount: amount, canWin: canwin});
    }

    /**
     * 删除过关投注
     * @param item
     * @private
     */
    _deleteBet = (item) => {
        this.props.delCGItem && this.props.delCGItem(item);
    }
    /**
     * 计算串关玩法总赔率
     */
    getTotalOdd = () => {
        let totalOdd = 1;
        const _CG_ = this.props.betinfo._CG_.list;
        const _GQ_JR_ZP_GJ_ = this.props.betinfo._GQ_JR_ZP_GJ_;
        if (this.props.playId === 4) {
            for (let i = 0; i < _CG_.length; i++) {
                if (_CG_[i].typeid === 3 || _CG_[i].typeid === 4 || _CG_[i].typeid === 5 || _CG_[i].typeid === 6 || _CG_[i].typeid === 14) {
                    totalOdd = totalOdd * (1 + Number(_CG_[i].odds));
                } else {
                    totalOdd = totalOdd * _CG_[i].odds;
                }
            }
        } else {
            if (_GQ_JR_ZP_GJ_.typeid === 3 || _GQ_JR_ZP_GJ_.typeid === 4 || _GQ_JR_ZP_GJ_.typeid === 5 || _GQ_JR_ZP_GJ_.typeid === 6 || _GQ_JR_ZP_GJ_.typeid === 14) {
                totalOdd = totalOdd * Number(_GQ_JR_ZP_GJ_.odds);
            } else {
                totalOdd = totalOdd * (_GQ_JR_ZP_GJ_.odds - 1);
            }
        }
        let betinfo = {
            _GQ_JR_ZP_GJ_: this.props.betinfo._GQ_JR_ZP_GJ_,
            _CG_: {list: this.props.betinfo._CG_, odds: PublicUtils.subStringNum(totalOdd)}
        };
        this.setState({betinfo: betinfo});
        return totalOdd;
    };
    /**
     * 投注标题
     * @returns {string}
     * @private
     */
    _renderBetTitle = () => {
        if (this.props.playId === 0 || this.props.playId === 1 || this.props.playId === 2) {
            return this.props.betinfo._GQ_JR_ZP_GJ_.info[1];
        } else if (this.props.playId === 4) {
            return "串关";
        } else {
            return "冠军";
        }
    };
    /**
     * 比赛队伍
     * @private
     */
    _renderBetTeam = () => {
        const styles = {
            betModal: {
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 10,
                fontSize: 14
            },
            betInnerBox: {
                width: "90%",
                backgroundColor: "#fff",
                borderRadius: 4,
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#eff3f6"
            },
            betInnerBody: {
                padding: 10
            },
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3
            },
            betAmountInputWrapper: {
                border: "1px solid #f5f7f9",
                height: 30,
                flex: 1,
                borderRadius: 4,
                fontSize: 12,
            },
            betAmountInput: {
                display: "flex",
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
                flex: 1,
                display: "flex",
                width: 0
            },
            betMatchTeamText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 14,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 14,
                color: "#e8ab09"
            },
            betOddsTips: {
                display: "flex",
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgListBox: {
                width: "100%",
                overflow: "auto"
            },
            cgBox: {
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
        };
        if (this.props.playId === 0 || this.props.playId === 1 || this.props.playId === 2) {
            return (
                <div style={styles.betModalRow}>
                    <div
                        style={{...styles.betMatchTeam, ...{flex: 1}}}>
                        <div style={styles.betMatchTeamText}>{this.props.betinfo._GQ_JR_ZP_GJ_.info[2]}</div>
                    </div>
                    <div style={styles.betMatchVs}>VS</div>
                    <div style={{
                        ...styles.betMatchTeam, ...{
                            flex: 1,
                            textAlign: 'right'
                        }
                    }}>
                        <div style={styles.betMatchTeamText}>{this.props.betinfo._GQ_JR_ZP_GJ_.info[3]}
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.playId === 4) {
            return (
                <div style={{...styles.betModalRow, ...{justifyContent: 'space-between',}}}>
                    <div style={styles.betMatchTeam}>串关</div>
                    <div style={styles.footerBetAmountBox}>
                        <div style={styles.footerBetAmountItem}>{this.props.betinfo._CG_.list.length}</div>
                        <div style={styles.footerBetAmountItem}>串</div>
                        <div style={styles.footerBetAmountItem}>1</div>
                        <div style={styles.footerBetAmountItem}>@ {this.props.betinfo._CG_.odds}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={styles.betModalRow}>
                    <div style={styles.betMatchTeam}>
                        <div style={styles.betMatchTeamText}>{this.props.betinfo._GQ_JR_ZP_GJ_.info[1]}</div>
                    </div>
                </div>
            );
        }
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };
    /**
     * 关闭q
     * @private
     */
    _closeModal = () => {
        // this.setState({modalVisible: false});
        this.props.onClose && this.props.onClose();
    };

    /**
     * 最大投注金额
     * @private
     */
    _fetchMaxBetAmount = () => {
        let parm = {
            item: this.props.playId,
            ball: this.props.ballId,
            type: this.props.betinfo._GQ_JR_ZP_GJ_.gameId,
            bet_bs: this.props.betinfo._GQ_JR_ZP_GJ_.bs,
            bet_class: this.props.betinfo._GQ_JR_ZP_GJ_.typeid,
            bet_content: this.props.betinfo._GQ_JR_ZP_GJ_.gid,
        }
        if (this.props.playId === 4) {
            let bet_bs_list = [];
            let bet_class_list = [];
            let bet_content_list = [];
            for (let i = 0; i < this.props.betinfo._CG_.list.length; i++) {
                bet_bs_list.push(this.props.betinfo._CG_.list[i].bs);
                bet_class_list.push(this.props.betinfo._CG_.list[i].typeid);
                bet_content_list.push(this.props.betinfo._CG_.list[i].gid);
            }
            parm.item = 0;
            parm.type = 11;
            parm.bet_bs = bet_bs_list;
            parm.bet_class = bet_class_list;
            parm.bet_content = bet_content_list;
        }
        Http.request("sportMaxBetAmount", parm, (res) => {
            console.log(res);
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate('Login', {nextPage: 'HG'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status === 1) {
                    this.setState({min: res.data.min, max: res.data.max});
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };
    /**
     * 确认投注
     */
    beting = () => {
        if (!this.state.betAmount) {
            this.setState({betTips: "请输入投注金额"});
            return;
        }
        if (Number(this.state.betAmount) > Number(this.state.max) || Number(this.state.betAmount) < Number(this.state.min)) {
            this.setState({betTips: "投注限额为（" + this.state.min + "~" + this.state.max + "）元"});
            return;
        }
        let parm = {
            item: this.props.playId,
            ball: this.props.ballId,
            type: this.props.betinfo._GQ_JR_ZP_GJ_.gameId,
            bet_bs: this.props.betinfo._GQ_JR_ZP_GJ_.bs,
            bet_class: this.props.betinfo._GQ_JR_ZP_GJ_.typeid,
            bet_content: this.props.betinfo._GQ_JR_ZP_GJ_.gid,
            bet_amount: this.state.betAmount
        }
        if (this.props.playId === 4) {
            let bet_bs_list = [];
            let bet_class_list = [];
            let bet_content_list = [];
            for (let i = 0; i < this.props.betinfo._CG_.list.length; i++) {
                bet_bs_list.push(this.props.betinfo._CG_.list[i].bs);
                bet_class_list.push(this.props.betinfo._CG_.list[i].typeid);
                bet_content_list.push(this.props.betinfo._CG_.list[i].gid);
            }
            parm.item = 0;
            parm.type = 11;
            parm.bet_bs = JSON.stringify(bet_bs_list);
            parm.bet_class = JSON.stringify(bet_class_list);
            parm.bet_content = JSON.stringify(bet_content_list);
        }

        this.setState({showProgress: true});
        Http.request("sportBetting", parm, (res) => {
            this.setState({showProgress: false});
            this._closeModal();
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'HG'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status === 1) {
                    this.props.onBetSuccess && this.props.onBetSuccess();
                    PublicUtils.showToast(res.data.message);
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };

    render() {
        const styles = {
            betModal: {
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 10,
                fontSize: 14
            },
            betInnerBox: {
                width: "90%",
                backgroundColor: "#fff",
                borderRadius: 4,
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#eff3f6"
            },
            betInnerBody: {
                padding: 10
            },
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3
            },
            betAmountInputWrapper: {
                border: "1px solid #f5f7f9",
                height: 30,
                flex: 1,
                borderRadius: 4,
                fontSize: 12,
            },
            betAmountInput: {
                display: "flex",
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
                flex: 1,
                display: "flex",
                width: 0
            },
            betMatchTeamText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 14,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 14,
                color: "#e8ab09"
            },
            betOddsTips: {
                display: "flex",
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgListBox: {
                width: "100%",
                overflow: "auto"
            },
            cgBox: {
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
        };
        return (

            <div style={styles.betModal}>
                <div style={{...styles.betInnerBox}}>
                    <div style={styles.betInnerTitle}>
                        <div style={styles.betInnerTitleText}>{this._renderBetTitle()}</div>
                        <div
                            onClick={() => {
                                this._closeModal();
                            }}
                        >
                            <Icon icon={"close"} size={20} color={"#333"}/>
                        </div>
                    </div>
                    <div style={styles.betInnerBody}>
                        {
                            this._renderBetTeam()
                        }
                        {
                            this.props.playId !== 4 ?

                                <div style={styles.betModalRow}>
                                    <div
                                        style={styles.betPlayName}>{this.props.betinfo._GQ_JR_ZP_GJ_.gameTypeName}</div>
                                </div> : null
                        }
                        {
                            this.props.playId === 4 ?
                                <div
                                    style={{...styles.cgListBox, ...this.props.betinfo._CG_.list.length > 2 ? {height: 200} : null}}>
                                    {
                                        this.props.betinfo._CG_.list.map((item, key) => {
                                            return (
                                                <div key={key} style={styles.cgBox}>
                                                    <div
                                                        style={{...styles.cgRow, ...{justifyContent: 'space-between'}}}>
                                                        <div style={styles.cgRowLeftCol}>
                                                            <div
                                                                style={styles.cgText}>{item.info[2]}</div>
                                                            <div
                                                                style={styles.cgVSText}>VS
                                                            </div>
                                                            <div
                                                                style={styles.cgText}>{item.info[3]}</div>
                                                        </div>
                                                        <div style={styles.cgRowRightCol}>
                                                            <div onClick={() => {
                                                                this._deleteBet(item);
                                                            }} style={styles.cgdel}>
                                                                <Icon icon={"close"} size={12}
                                                                      color={"#fff"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={styles.cgRow}>
                                                        <div
                                                            style={styles.cgText}>{item.gameTypeName}</div>
                                                    </div>
                                                    <div style={styles.cgRow}>
                                                        <div style={styles.cgText}>{item.betContent}</div>
                                                        <div style={styles.cgText}>{item.pk}</div>
                                                        <div style={styles.cgText}>@ {item.odds}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> : null
                        }
                        {
                            this.props.playId !== 4 ?
                                <div style={styles.betModalRowBetween}>
                                    <div style={styles.betModalRow}>
                                        <div
                                            style={styles.betContent}>{this.props.betinfo._GQ_JR_ZP_GJ_.betContent}</div>
                                        <div style={styles.betPk}>{this.props.betinfo._GQ_JR_ZP_GJ_.pk}</div>
                                        <div style={styles.betSymbol}>@</div>
                                        <div
                                            style={styles.betOdds}>{this.props.betinfo._GQ_JR_ZP_GJ_.odds}</div>
                                    </div>
                                    <div style={{...styles.betModalRow, ...styles.betOddsTips}}>
                                        <div style={styles.betOddsTipsText}>自动获取最佳赔率</div>
                                    </div>
                                </div> : null
                        }
                        <div style={{...styles.amountTemplateWrapper}}>
                            {
                                this.amountTemplate.map((item, key) => {
                                    return (
                                        <div key={key}
                                             onClick={() => {
                                                 this.setBetAmount(item.value);
                                             }}
                                             style={styles.amountTemplateBox}
                                        >
                                            <div>
                                                <div
                                                    style={styles.amountTemplateText}>{item.name}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={styles.betModalRowBetween}>
                            <div style={styles.betAmountInputWrapper}>
                                <MyInput
                                    keyboardType={'numeric'}
                                    value={this.state.betAmount && String(this.state.betAmount)}
                                    placeholder={'限额（' + this.state.min + '~' + this.state.max + '）元'}
                                    style={styles.betAmountInput}
                                    onChangeText={(text) => {
                                        this.setBetAmount(text.replace(/\D/g, ''));
                                    }}
                                    // onKeyUp={(text) => {
                                    //     this.setBetAmount(text.replace(/\D/g, ''))
                                    // }}
                                />
                            </div>
                            {
                                this.state.betAmount ?
                                    <div style={{...styles.betModalRow, ...{marginLeft: 10}}}>
                                        <div>可赢：</div>
                                        <div
                                            style={styles.betCanWin}>￥{PublicUtils.subStringNum(this.state.canWin)}</div>
                                    </div> : null
                            }
                        </div>
                        {
                            this.state.betTips ?
                                <div style={styles.betModalRow}>
                                    <div
                                        style={styles.betTips}>{this.state.betTips}</div>
                                </div> : null
                        }
                        <div style={styles.btnBox}>
                            <MyButton title={'立即投注'} showProgress={this.state.showProgress}
                                      disabled={this.state.showProgress} onPress={() => {
                                this.beting()
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BetDialog