/**
 * 自定义图标
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import model from "../../../../services/model";

export default class SportMatchInfo extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            matchInfo: this.props.matchInfo
        }
    }

    show = true;
    static propTypes = {
        playId: PropTypes.number,
        ballId: PropTypes.number,
        betId: PropTypes.number,
        subPlayId: PropTypes.number,
        matchInfo: PropTypes.object,
        onOddPress: PropTypes.func,
        subPlayName: PropTypes.string
    };
    static defaultProps = {
        subPlayName: ""
    };
    /**
     * 点击下注
     * @param template：数据模板
     */
    _onPress = (template) => {
        const data = {
            play_list: this.betInfoIndexArry,
            bet_list: template
        };
        this.props.onOddPress(data);
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {

    };
    _renderPk = (item) => {
        const styles = {
            pkValue: {
                color: "#000"
            }
        };
        let renerView = null;
        if (this.props.subPlayId === 4 || this.props.subPlayId === 3) {
            if ((item.name === "主" && this.state.matchInfo[this.betInfoIndexArry.pk_type] === "c" && this.state.matchInfo[this.betInfoIndexArry.pk_value] !== 0) || (item.name === "客"
                    && this.state.matchInfo[this.betInfoIndexArry.pk_type] === "h" && this.state.matchInfo[this.betInfoIndexArry.pk_value] !== 0)) {
                renerView = <div style={styles.pkValue}>+{this.state.matchInfo[this.betInfoIndexArry.pk_value]}</div>
            }
            if ((item.name === "主" && this.state.matchInfo[this.betInfoIndexArry.pk_type] === "h" && this.state.matchInfo[this.betInfoIndexArry.pk_value] !== 0) || (item.name === "客"
                    && this.state.matchInfo[this.betInfoIndexArry.pk_type] === "c" && this.state.matchInfo[this.betInfoIndexArry.pk_value] !== 0)) {
                renerView = <div style={styles.pkValue}>-{this.state.matchInfo[this.betInfoIndexArry.pk_value]}</div>
            }
        }
        return renerView;
    };

    _renderPkValue = () => {
        const styles = {
            col: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 5
            }
        };
        let renerView = null;
        if (this.state.matchInfo[this.betInfoIndexArry.pk_value]) {
            if (this.props.subPlayId !== 4 && this.props.subPlayId !== 3) {
                renerView = <div style={styles.col}>
                    <div>{this.state.matchInfo[this.betInfoIndexArry.pk_value]}</div>
                </div>;
            } else {
                renerView = <div style={styles.col}></div>;
            }
        }
        return renerView;
    };

    render() {
        const styles = {
            row: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: 'stretch',
                fontSize: 14,
                borderTop: "1px solid #f5f7f9",
            },
            betName: {
                marginRight: 5
            },
            col: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 5
            },
            oddBtn: {
                display: "flex",
                flex: 1,
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRadius: 3
            },
            oddItem: {
                display: "flex",
                paddingTop: 10,
                paddingBottom: 10,
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                boxSizing: "border-box",
                display: "flex",
                width: model.clientWidth / 3,
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9"
            }
        };
        let isNormal = false;//是否是正常的数据，有一个正常则显示
        this.betInfoIndexArry = model.getBetInfoIndexArryById(this.props.ballId, this.props.betId, this.props.subPlayId, this.props.subPlayName);
        if (this.betInfoIndexArry) {
            for (let i = 0; i < this.betInfoIndexArry.bet_list.length; i++) {
                this.betInfoIndexArry.bet_list[i].show = !!Number(this.state.matchInfo[this.betInfoIndexArry.bet_list[i].odd]);
                if (this.betInfoIndexArry.bet_list[i].show) {
                    isNormal = true;
                }
            }
        }
        // console.log("----------------------------");
        // console.log(isNormal);
        // // console.log(this.betInfoIndexArry);
        // console.log(this.props.betId);
        // console.log(this.state.matchInfo[this.betInfoIndexArry.pk_id]);
        // console.log(this.state.matchInfo[this.betInfoIndexArry.pk_id]);
        return (
            isNormal ?
                <div>
                    {/**独赢/让球/大小/单双**/}
                    {
                        this.props.betId === 1 && this.betInfoIndexArry && this.state.matchInfo[this.betInfoIndexArry.pk_id] ?
                            <div style={{...styles.row, ...styles.matchInfo}}>
                                <div style={styles.col}>
                                    <div>{this.betInfoIndexArry.name}</div>
                                </div>
                                {!this.state.matchInfo[this.betInfoIndexArry.pk_value] && this.betInfoIndexArry.bet_list.length === 2 ?
                                    <div style={styles.col}/> : null}
                                {
                                    this._renderPkValue()
                                }
                                {
                                    this.betInfoIndexArry.bet_list.map((item, key) => {
                                        return item.show? (
                                            <div key={key} style={styles.col}>
                                                <div style={styles.oddBtn}
                                                     onClick={() => {
                                                         this._onPress(item);
                                                     }}>
                                                    <div
                                                        style={{...styles.oddItem}}>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}>
                                                            <div
                                                                style={{...styles.betName, ...this.state.matchInfo[item.trend] === 0 ? styles.fc0 : this.state.matchInfo[item.trend] === 2 ? styles.fc2 : styles.fc1}}>{item.name} </div>
                                                            <div
                                                                style={{...this.state.matchInfo[item.trend] === 0 ? styles.fc0 : this.state.matchInfo[item.trend] === 2 ? styles.fc2 : styles.fc1}}>{this.state.matchInfo[item.odd]}</div>
                                                        </div>
                                                        {
                                                            this._renderPk(item)
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        ):(
                                          <div style={styles.col}></div>
                                        )
                                    })
                                }
                            </div> : null
                    }
                    {/**波胆**/}
                    {
                        (this.props.betId === 2 || this.props.betId === 3 || this.props.betId === 4) && this.betInfoIndexArry && this.state.matchInfo[this.betInfoIndexArry.pk_id] && this.state.matchInfo[this.betInfoIndexArry.pk_id] ?
                            <div style={{...styles.matchInfo, ...styles.gjChildList,}}>
                                {this.betInfoIndexArry.bet_list.map((item, key) => {
                                    return (

                                        <div key={key}
                                             onClick={() => {
                                                 this._onPress(item);
                                             }} style={{...styles.gjChildItem}}>
                                            <div>
                                                <div>{item.name}</div>
                                            </div>
                                            <div
                                                style={{...this.state.matchInfo[item.trend] === 0 ? styles.fc0 : this.state.matchInfo[item.trend] === 2 ? styles.fc2 : styles.fc1}}>{this.state.matchInfo[item.odd]}</div>

                                        </div>
                                    );
                                })}
                            </div> : null
                    }
                </div> : null
        )

    }
}