/**
 * 投注号码列表
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BetNumber from "./betNumber";
import style from "../../../utils/config.scss";
import model from "../../../services/model";

export default class BetNumberlist extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {};
    }

    static propTypes = {
        templateType: PropTypes.string,
        style: PropTypes.object,
        currentBetNumber: PropTypes.object,
        playName: PropTypes.string,
        code: PropTypes.number,
        data: PropTypes.array,
        onPress: PropTypes.func,
        onClosePress: PropTypes.func,
        betTemplate: PropTypes.object
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.setState({second: this.props.second}, () => {
        //     this._countdown();
        // });
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps) => {
    };
    /**
     * 点击投注号码
     * */
    _betNumberClick = (subItem, item) => {
        this.props.onPress && this.props.onPress(subItem, item);
    };

    render() {
        const betInfoStyles = {
            betInfoBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                borderTop: "1px solid #f5f7f9"
            },
            betTypeBox: {
                display: "flex",
                flex: 1,
                // backgroundColor: "#f5f7f9",
                borderRight: "1px solid #f5f7f9"
            },
            betBallBox: {
                width: 270,
            },
            btnBox: {
                paddingLeft: 10,
                paddingRight: 10
            },
            betTypeInnerBox: {
                // borderRightWidth: 1,
                // borderRightColor: "#f5f7f9"
            },
            betTypeItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
                lineHeight: 40,
                fontSize: 15,
            },
            betTypeItem: {
                color: "rgba(0,0,0,0.7)"
            },
            betCount: {
                width: 18,
                height: 18,
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                lineHeight: 18,
                textAlign: "center",
                color: "#fff",
                borderRadius: 18,
                fontSize: 10
            },
            betTypeItemActive: {
                backgroundColor: "#f5f7f9",
            },
            betBallInnerBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
            },
            betNumberBox: {
                display: "flex",
                flexDirection: "row",
                margin: 5,
                paddingLeft: 8,
                paddingRight: 8,
                height: 40,
                alignItems: "center",
                border: "1px dashed rgba(0,0,0,0.15)",
                justifyContent: "space-between",
                borderRadius: 0.1,
                width: 120,
            },
            betNumberActive: {
                borderColor: "1px solid " + style['primaryColor' + model.SKINNAME],
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betNameActive: {
                color: "#fff"
            },
            betAmountActive: {
                color: "#fff"
            },
            betOddsActive: {
                color: "#fcff23"
            },
            betName: {
                fontSize: 14,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "red"
            },
            betAmount: {
                fontSize: 13,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "green"
            },
            betOdds: {
                fontSize: 12,
                color: "rgba(246, 48, 85, 0.94)",
                textAlign: "center",
                // backgroundColor: "blue"
            },
            scrollBox: {
                width: "100%",
                overflow: "auto",
            },
            numsWrpaer: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                borderRightStyle: "solid",
                borderRightColor: "rgb(245, 247, 249)",
                borderRightWidth: 1,
                padding: 5
            },
            numsWrpaerTop: {
                display: "flex",
                height: 30,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
                color: "rgba(0,0,0,0.7)",
                fontSize: 14
            },
            numsInnerWrpaer: {
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center"
            }
        };
        return (
            <div
                style={{...betInfoStyles.betBallInnerBox,}}>
                {
                    this.props.playName === "连码" && this.props.templateType === "LHC" ?
                        this.props.data.map((item, key) => {
                            return (
                                <div style={{...betInfoStyles.numsWrpaer}}>
                                    {
                                        item.name ?
                                            <div style={{...betInfoStyles.numsWrpaerTop}}>{item.name}</div> : null
                                    }
                                    <div
                                        style={{...betInfoStyles.scrollBox, ...betInfoStyles.numsInnerWrpaer, ...this.props.style}}>
                                        {
                                            item.nums.map((subItem, key) => {
                                                return (
                                                    <BetNumber templateType={this.props.templateType} key={key}
                                                               parentData={item}
                                                               betTemplate={this.props.betTemplate}
                                                               currentBetNumber={this.props.currentBetNumber}
                                                               data={subItem}
                                                               playName={this.props.playName}
                                                               code={this.props.code}
                                                               onPress={(subItem) => {
                                                                   this._betNumberClick(subItem, item)
                                                               }}
                                                               onClosePress={(subItem) => {
                                                                   this.props.onClosePress && this.props.onClosePress(subItem);
                                                               }
                                                               }/>)
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }) :
                        <div
                            style={{...betInfoStyles.scrollBox, ...betInfoStyles.numsInnerWrpaer, ...this.props.style}}>
                            {
                                this.props.data.map((item, key) => {
                                    return (
                                        <BetNumber templateType={this.props.templateType} key={key} currentBetNumber={this.props.currentBetNumber}
                                                   data={item}
                                                   playName={this.props.playName} code={this.props.code}
                                                   onPress={(item) => {
                                                       this._betNumberClick(item)
                                                   }} onClosePress={(item) => {
                                            this.props.onClosePress && this.props.onClosePress(item);
                                        }
                                        }/>)
                                })
                            }
                        </div>

                }
            </div>
        )
    }
}