/**
 * 首页
 * @format
 * @flow
 */
import React from 'react';
import Default from "./template/default";
import Theme1 from "./template/theme1";
import global from "../../global";

export default class BottomTab extends React.Component {
    // 构造
    constructor(props) {
        super(props);
    }

    /**
     * 模板
     **/
    _renderTemplate = () => {
        if (global.THEME === "default") {
            return <Default {...this.props}/>
        } else {
            return <Theme1 {...this.props}/>
        }
    };

    render() {
        return this._renderTemplate()
    }
}
