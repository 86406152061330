/**
 * 搜索框
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from '../utils/icon'
import MyInput from '../component/myInput'

class SearchBar extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            width: 130,
        }
    }

    text = '';
    static propTypes = {
        editable: PropTypes.bool,
        value: PropTypes.string,
        onPress: PropTypes.func,
        onSubmitEditing: PropTypes.func,
        onChangeText: PropTypes.func,
        autoFocus: PropTypes.bool,
    };
    static defaultProps = {
        editable: false,
        autoFocus: false
    };
    /**
     * 获取组件宽度
     * @param e
     * @private
     */
    _layout = (e) => {
    };

    /**
     * 搜索
     * @private
     */
    _onPress = () => {
        if (!this.props.editable && this.props.onPress) {
            this.props.onPress();
        }
    };
    /**
     * 搜索回调
     * @private
     */
    _onSubmitEditing = () => {
        this.props.onSubmitEditing && this.props.onSubmitEditing(this.text);
    };
    /**
     * 搜索框内容变化时
     * @param text
     * @private
     */
    _onChangeText = (text) => {
        this.props.onChangeText && this.props.onChangeText(text);
        this._onSubmitEditing();
    };

    render() {

        const styles = {
            searchBar: {
                display: "flex",
                backgroundColor: "#f1f1f1",
                height: 35,
                borderRadius: 3,
                paddingLeft: 10,
                paddingRight: 10,
                justifyContent: 'center',
                alignItems: 'center',
            },
            searchBarInner: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: 'center',
                alignItems: 'center',
            },
            searchBarInput: {
                display: "flex",
                flex: 1,
                height: 40,
            },
        };
        // const Button = createThemedComponent(TouchableHighlight);
        // const Icon = createThemedComponent(MyIcon, ['color', 'name', 'size']);
        return (
            <div style={styles.searchBar}  onClick={() => {
                this._onPress();
            }}>
                <div style={{...styles.searchBarInner, ...{width: this.state.width}}}>
                    <Icon icon={'magnifier'} size={18} color={'#999'}/>

                    <MyInput style={styles.searchBarInput}
                             placeholder="搜索游戏、平台"
                             onBlur={(text) => {
                                 this.text = text;
                                 this._onChangeText(text);
                             }}
                    />
                </div>
            </div>
        )
    }
}


export default SearchBar