/**
 * 转账
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import PublicUtils from "../utils/publicUtils";
import model from "../services/model";
import Http from "../services/http";
import MyPicker from "../component/myPicker";
import ActivityIndicator from "../component/activityIndicator";
import Storage from "../services/storage";
import Icon from '../utils/icon';
import MyInput from '../component/myInput'
import MyButton from '../component/myButton'
import style from '../utils/config.scss'
import global from "../global";

export default class GameTransfer extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            transferType: 0,
            transferDirection: 1,
            opacity: 0,
            out_account: {
                name: "中心账户",
                game_api: null,
            },
            in_account: {
                name: "请选择平台",
                game_api: null,
            },
            all_balance:null,
            game_balance:null,
            balance: {
                all_balance: null,
                game_balance: null
            },
            loadingBalance: false,
            amount: null,
            showProgress: false,
            showGameInProgress: false,
            showPlatformFliter: false,
            zzwc:0, // 是否处于全部游戏思入中心敗转账状态
            djs:30, // 等待时间和请求间隔时间一样现在是30秒
        };

        // 清定时器
        clearInterval(global.dsqxb);


        Http.request("memberInfo", {}, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                console.log(res.data.info.is_balance)

                const balance = {all_balance: res.data.info.is_balance,game_balance:0};
                PublicUtils.updateUserInfo({is_balance: res.data.info.is_balance});
                this.setState({balance: balance,all_balance: res.data.info.is_balance,game_balance:0});

            }
        });

    }

    accountList = [];
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._getPlatform();
    };


    /**
     * 进入游戏
     * @private
     */
    _enterGame = () => {

        let item;
        if (this.state.transferDirection === 1) {
            item = this.state.in_account;
        } else {
            item = this.state.in_account;
        }
        if (!item.game_api) {
            // this.ComFn.showToast("请选择转入转出账户");
            PublicUtils.showToast("请选择游戏平台");
            return;
        }
        PublicUtils._enterGame(item);
        // /**
        //  * 皇冠体育
        //  */
        // if (this.props.obj.game_id === 101) {
        //     PublicUtils.navigate('HG');
        // }
        // /**
        //  * 系统彩票
        //  */
        // else if (this.props.obj.game_id === 201) {
        //     PublicUtils.navigate('GameNavigation');
        // } else {
        //     alert(JSON.stringify(this.props.obj));
        //     PublicUtils.navigate('GameRoom',this.props.obj);
        // }
        // if (item.game_type === "_self_lottery") {
        //     this._enterLotteryPage(this.props.obj.game_id);
        // } else {
        //     let parm = {
        //         name: item.game_api,
        //         type: item.type,
        //         game_id: item.game_id,
        //         title: item.name,
        //         game_list_json: item.game_list_json,
        //         game_list_img_path: item.game_list_img_path
        //     };
        //     if (item.type === 4) {
        //         PublicUtils.navigate('GameList', parm);
        //     }
        //     else if (item.game_id === 201) {
        //         PublicUtils.navigate('GameNavigation');
        //     }
        //     else if (item.game_id === 101) {
        //         PublicUtils.navigate('HG');
        //     } else {
        //         //this.Api.navPush("GameTransferPage", this.navCtrl, parm, true);
        //         this._enterGamePage(parm, this);
        //     }
        // }
    };

    /**
     * 进入游戏
     * @private
     */
    _enterGamePage = (parm) => {
        this.setState({showGameInProgress: true});
        Http.request("gameIn", parm, (res) => {
            this.setState({showGameInProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'GameTransfer'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const parma = {
                        url: res.data.url,
                        title: parm.title,
                        parma: parm
                    };
                    // PublicUtils.navigate('GameRoom', parma);
                    PublicUtils.linking(res.data.url, "_blank");
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };
    /**
     * 查询余额
     * @private
     */
    _queryBalance = (name, type, game_id) => {
        this.setState({loadingBalance: true});
        const parm = {
            name: name,
            type: type,
            game_id: game_id
        };
        Http.request("queryBalance", parm, (res) => {
            this.setState({loadingBalance: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/gametransfer'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const balance = {all_balance: res.data.user_balance, game_balance: res.data.balance};
                    PublicUtils.updateUserInfo({is_balance: res.data.user_balance});
                    this.setState({all_balance: res.data.user_balance,game_balance:res.data.balance});
                }
                if (res.data.status === 0) {
                    PublicUtils.showToast("平台维护中...");
                }
            }
        });
    };
    /**
     * 设置转账金额
     * @param amount
     */
    _setAmount = (amount) => {
        this.setState({amount: amount});
    };
    /**
     * 获取游戏平台
     * @private
     */
    _getPlatform = function () {
        for (let i = 0; i < model.gameList.length; i++) {
            if (model.gameList[i].gamelist) {
                for (let l = 0; l < model.gameList[i].gamelist.length; l++) {
                    if (model.gameList[i].gamelist[l].game_id !== 101 && model.gameList[i].gamelist[l].game_id !== 201 && model.gameList[i].gamelist[l].game_id !== 801) {
                        const item = {label: model.gameList[i].gamelist[l].name, value: model.gameList[i].gamelist[l]};
                        this.accountList.push(item);
                    }
                }
            }
        }

        const param = PublicUtils.getState(this.props.location.pathname).param;
        if (param) {
            //this.queryBalance();
            this._changePlatform(param);
        }
        //this.queryBalance();
    };
    /**
     * 选择游戏平台
     * @param data
     * @private
     */
    _changePlatform = (data) => {
        if (data && data.name) {
            this.setState({in_account: data});
            this._queryBalance(data.game_api, data.type, data.game_id);
        }
    };
    /**
     * 改变划转方向
     * @private
     */
    _changeTransferDirection = () => {
        this.setState({transferDirection: this.state.transferDirection === 1 ? 2 : 1}, () => {
        });
        // this._startAnimation();
    };
    /**
     * 全部划转
     * @private
     */
    _allAmount = () => {
        this._setAmount(this.state.transferDirection === 1 ? this.state.all_balance : this.state.game_balance);
    };
    /**
     * 可转金额
     * @private
     */
    _getTransferTips = () => {
        const styles = {
            disabledBtnText: {
                opacity: 0.6
            },
            headerBox: {
                display: "flex",
                flexDirection: "row",
                padding: 10,
            },
            headerAccountBox: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
            },
            headerAccount: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
            },
            headerAccountNameBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
            },
            headerAccountNameLeftBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-start"
            },
            headerAccountNameRightBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-end",
                alignItems: "center"
            },
            headerAccountNameInnerBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: -10
            },
            headerAccountName: {
                fontSize: 15,
                color: "#000",
                marginRight: 2
            },
            headerAccountBalanceBox: {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                fontSize: 16,
            },
            headerAccountBalance: {
                color: "#000"
            },
            headerMid: {
                display: "flex",
                width: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            leftBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start"
            },
            rightBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end"
            },
            textLeft: {
                textAlign: "left",
            },
            textRight: {
                textAlign: "right",
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
                alignItems:"center"
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14
            },
            transferTipsColor4:{
                // display: "flex",
                // flexDirection: "row",
                color: "#fff",
                fontSize: 12,
                paddingLeft: 10,
                paddingRight: 10,
                height: 27,
                lineHeight: "27px",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 27,
                // justifyContent: "center",
                // alignItems: "center"
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                flexDirection: "column",
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            platformPicker: {
                flexDirection: "column",
                display: "flex",
                backgroundColor: "red",
                flex: 1, // for Android, not visible otherwise.
                // width: Platform.OS === 'ios' ? undefined : 120,
                height: 20,
                textAlign: "right",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                borderWidth: 0
            },
            pickerSelectContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
            },
            pickerSelectBox: {
                backgroundColor: "red",
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5
            },
            headerToolbarItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,0.5)"
            },
        };
        let view = null;
        if (this.state.transferDirection === 1) {
            if (this.state.in_account.game_api) {
                view = <div style={styles.transferTipsLeft}>
                    <div style={styles.transferTipsColor1}>可转入到{this.state.in_account.name}</div>
                    <div style={styles.transferTipsColor2}>￥{this.state.all_balance}</div>
                    <div style={styles.transferTipsColor1}>元</div>
                </div>;
            }
        }
        if (this.state.transferDirection === 2) {
            if (this.state.in_account.game_api) {
                view = <div style={styles.transferTipsLeft}>
                    <div style={styles.transferTipsColor1}>可转入到{this.state.out_account.name}</div>
                    <div style={styles.transferTipsColor2}>￥{this.state.game_balance}</div>
                    <div style={styles.transferTipsColor1}>元</div>
                </div>;
            }
        }
        return view;
    };
    /**
     * 左边余额
     * @private
     */
    _getLeftBalanceView = () => {

        const styles = {
            disabledBtnText: {
                opacity: 0.6
            },
            headerBox: {
                display: "flex",
                flexDirection: "row",
                padding: 10,
            },
            headerAccountBox: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
            },
            headerAccount: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
            },
            headerAccountNameBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
            },
            headerAccountNameLeftBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-start"
            },
            headerAccountNameRightBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-end",
                alignItems: "center"
            },
            headerAccountNameInnerBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: -10
            },
            headerAccountName: {
                fontSize: 15,
                color: "#000",
                marginRight: 2
            },
            headerAccountBalanceBox: {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                fontSize: 16,
            },
            headerAccountBalance: {
                color: "#000"
            },
            headerMid: {
                display: "flex",
                width: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            leftBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start"
            },
            rightBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end"
            },
            textLeft: {
                textAlign: "left",
            },
            textRight: {
                textAlign: "right",
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
                alignItems:"center"
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14
            },
            transferTipsColor4:{
                // display: "flex",
                // flexDirection: "row",
                color: "#fff",
                fontSize: 12,
                paddingLeft: 10,
                paddingRight: 10,
                height: 27,
                lineHeight: "27px",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 27,
                // justifyContent: "center",
                // alignItems: "center"
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                flexDirection: "column",
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            platformPicker: {
                flexDirection: "column",
                display: "flex",
                backgroundColor: "red",
                flex: 1, // for Android, not visible otherwise.
                // width: Platform.OS === 'ios' ? undefined : 120,
                height: 20,
                textAlign: "right",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                borderWidth: 0
            },
            pickerSelectContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
            },
            pickerSelectBox: {
                backgroundColor: "red",
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5
            },
            headerToolbarItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,0.5)"
            },
        };
        let view = null;
        if (this.state.loadingBalance) {
            view = <ActivityIndicator size="small" color={'rgba(245, 72, 68, 0.93)'}/>;
        } else {
            if (this.state.all_balance) {
                if (this.state.transferDirection === 1) {
                    view = <div
                        style={{...styles.headerAccountBalance, ...styles.textRight}}>{PublicUtils.subStringNum(this.state.all_balance)}元</div>;
                }
                if (this.state.transferDirection === 2) {
                    view = <div
                        style={{...styles.headerAccountBalance, ...styles.textRight}}>{PublicUtils.subStringNum(this.state.game_balance)}元</div>;
                }
            }
        }
        return view;

    };
    /**
     * 右边余额
     * @private
     */
    _getRightBalanceView = () => {

        const styles = {
            disabledBtnText: {
                opacity: 0.6
            },
            headerBox: {
                display: "flex",
                flexDirection: "row",
                padding: 10,
            },
            headerAccountBox: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
            },
            headerAccount: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
            },
            headerAccountNameBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
            },
            headerAccountNameLeftBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-start"
            },
            headerAccountNameRightBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-end",
                alignItems: "center"
            },
            headerAccountNameInnerBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: -10
            },
            headerAccountName: {
                fontSize: 15,
                color: "#000",
                marginRight: 2
            },
            headerAccountBalanceBox: {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                fontSize: 16,
            },
            headerAccountBalance: {
                color: "#000"
            },
            headerMid: {
                display: "flex",
                width: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            leftBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start"
            },
            rightBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end"
            },
            textLeft: {
                textAlign: "left",
            },
            textRight: {
                textAlign: "right",
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems:"center",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14
            },
            transferTipsColor4:{
                // display: "flex",
                // flexDirection: "row",
                color: "#fff",
                fontSize: 12,
                paddingLeft: 10,
                paddingRight: 10,
                height: 27,
                lineHeight: "27px",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 27,
                // justifyContent: "center",
                // alignItems: "center"
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                flexDirection: "column",
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            platformPicker: {
                flexDirection: "column",
                display: "flex",
                backgroundColor: "red",
                flex: 1, // for Android, not visible otherwise.
                // width: Platform.OS === 'ios' ? undefined : 120,
                height: 20,
                textAlign: "right",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                borderWidth: 0
            },
            pickerSelectContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
            },
            pickerSelectBox: {
                backgroundColor: "red",
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5
            },
            headerToolbarItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,0.5)"
            },
        };
        let view = null;
        if (this.state.loadingBalance) {
            view = <ActivityIndicator size="small" color={'rgba(245, 72, 68, 0.93)'}/>;
        } else {
            if (this.state.all_balance) {
                if (this.state.transferDirection === 1) {
                    view = <div
                        style={{...styles.headerAccountBalance, ...styles.textRight}}>{PublicUtils.subStringNum(this.state.game_balance)}元</div>;
                }
                if (this.state.transferDirection === 2) {
                    view = <div
                        style={{...styles.headerAccountBalance, ...styles.textRight}}>{PublicUtils.subStringNum(this.state.all_balance)}元</div>;
                }
            }
        }

        return view;

    };
    /**
     * 提交额度转换申请
     * @private
     */
    _submitTransfer = () => {
        if (!this.state.in_account.game_api) {
            // this.ComFn.showToast("请选择转入转出账户");
            PublicUtils.showToast("请选择游戏平台");
            return;
        }
        if (!this.state.amount) {
            PublicUtils.showToast("请输入转账金额");
            return;
        }
        if (Number(this.state.amount) < 10) {
            PublicUtils.showToast("转账金额不得低于10元");
            return;
        }
        if (Number(this.state.amount) > Number(this.state.transferDirection === 1 ? this.state.all_balance : this.state.game_balance)) {
            PublicUtils.showToast("余额不足");
            return;
        }
        let parma = {
            // is_type: this.navParams.get('type'),
            out_account: this.state.transferDirection === 1 ? "DEFAULT" : this.state.in_account.game_api,
            in_account: this.state.transferDirection === 2 ? "DEFAULT" : this.state.in_account.game_api,
            transfer_account: this.state.amount,
            game_id: this.state.in_account.game_id
        };
        this.setState({showProgress: true});
        Http.request("transferBalance", parma, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Wallet'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    PublicUtils.showToast("转账成功");
                    this._queryBalance(this.state.in_account.game_api, this.state.in_account.type, parma.game_id);
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };
    /**
     * 进入游戏加载状态
     * */
    _showGameInProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };

    // 转入中心用户
    zhuanruzx(name,game_id,mn,fn){

        let parma = {
            // is_type: this.navParams.get('type'),
            out_account: name,
            in_account: "DEFAULT",
            transfer_account: mn,
            game_id: game_id
        };
        Http.request("transferBalance", parma, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Wallet'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    fn()
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    }

    // 查询游戏余额
    chaxun(name, type, game_id,tm){
        let tempThis = this;
        const parm = {
            name: name,
            type: type,
            game_id: game_id
        };

        // 请求不能一次性发过多南要定时器
        setTimeout(()=>{
            Http.request("queryBalance", parm, (res) => {
                if (res.code === 300) {
                    Storage.clearData();
                    PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/gametransfer'});
                }
                if (res.code === 200) {
                    if (res.data.status === 1) {
                        // 全部转入中心用户
                        if(parseInt(res.data.balance)>0){


                            let tempRes = res;

                            // 需要一次性定时器
                            setTimeout(()=>{
                                tempThis.zhuanruzx(name,game_id,tempRes.data.balance,()=>{



                                })
                            },tm)

                        }



                    }
                    if (res.data.status === 0) {
                        PublicUtils.showToast(name+"平台维护中...");
                    }
                }
            });
        }, tm)
    }

    // 所有游戏余额转入中心黑户
    quanbuzhuanchu(){

        let tempThis = this;

        //
        if(this.state.zzwc){
            return;
        }
        this.setState({zzwc:1});

        //
        global.dsqxb = setInterval(()=>{
            //
            tempThis.setState({djs:tempThis.state.djs-=1})
            //
            if(!tempThis.state.djs|| !tempThis.state.zzwc){
                //
                clearInterval(global.dsqxb);
                tempThis.setState({djs:30});
                return
            }
        }, 1000)

        // 30秒发一次，最后要更新数据
        setTimeout(()=>{
            tempThis.setState({zzwc:0});
            this._queryBalance(tempThis.accountList[0].value.game_api,tempThis.accountList[0].value.type,tempThis.accountList[0].value.game_id);
        },30000)

        //
        for (var i=0;i<tempThis.accountList.length;i++){
            tempThis.chaxun(tempThis.accountList[i].value.game_api,tempThis.accountList[i].value.type,tempThis.accountList[i].value.game_id,i*500);
        }

    }


    render() {
        const styles = {
            disabledBtnText: {
                opacity: 0.6
            },
            headerBox: {
                display: "flex",
                flexDirection: "row",
                // padding: 10,
            },
            headerAccountBox: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                backgroundColor: "#fff",
                borderRadius: 3,
                border: "1px solid rgba(0,0,0,0.06)",
                justifyItems: "center",
                alignItems: "center",
                padding: "10px"
            },
            headerAccount: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
            },
            headerAccountNameBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
            },
            headerAccountNameLeftBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                height: 40,
                justifyContent: "flex-start"
            },
            headerAccountNameRightBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                // height: 40,
                justifyContent: "flex-end",
                alignItems: "center"
            },
            headerAccountNameInnerBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginTop: -10
            },
            headerAccountName: {
                fontSize: 15,
                color: "#000",
                marginRight: 2
            },
            headerAccountBalanceBox: {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                fontSize: 16,
            },
            headerAccountBalance: {
                color: "#000"
            },
            headerMid: {
                display: "flex",
                width: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
            },
            leftBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start"
            },
            rightBox: {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end"
            },
            textLeft: {
                textAlign: "left",
            },
            textRight: {
                textAlign: "right",
            },
            amountWrapper:{
                backgroundColor: "#fff",
                borderRadius: 3,
                border: "1px solid rgba(0,0,0,0.06)",
                marginTop:10
            },
            amountContainer: {
                padding: "10px 10px 0",
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                // paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems:"center",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999",
                fontSize: 14
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 14
            },
            transferTipsColor4:{
                // display: "flex",
                // flexDirection: "row",
                color: "#fff",
                fontSize: 12,
                paddingLeft: 10,
                paddingRight: 10,
                height: 27,
                lineHeight: "27px",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 27,
                // justifyContent: "center",
                // alignItems: "center"
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderTop: "1px solid #f5f7f9",
                borderLeft: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                borderRadius: 3,
                overflow: "hidden"
            },
            amountTemplateItem: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                borderRight: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                // paddingLeft: 10,
                // paddingRight: 10
            },
            btn: {
                flexDirection: "column",
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            platformPicker: {
                flexDirection: "column",
                display: "flex",
                backgroundColor: "red",
                flex: 1, // for Android, not visible otherwise.
                // width: Platform.OS === 'ios' ? undefined : 120,
                height: 20,
                textAlign: "right",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                borderWidth: 0
            },
            pickerSelectContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
            },
            pickerSelectBox: {
                backgroundColor: "red",
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5
            },
            headerToolbarItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,0.5)"
            },
        };
        // this.transferType = this.state.transferType.interpolate({
        //     inputRange: [0, 1],
        //     outputRange: ['0deg', '180deg']
        // });
        // this.opacity = this.state.opacity.interpolate({
        //     inputRange: [0, 1],
        //     outputRange: [0, 1]
        // });
        return (
            <Container title={'转账'} style={{backgroundColor: "#f5f7f9", padding: 10}}>
                <div
                    style={{...styles.headerBox}}>
                    <div style={styles.headerAccountBox}>
                        <div style={styles.headerAccount}>
                            <div style={styles.headerAccountNameLeftBox} onClick={() => {
                                this.state.transferDirection === 2 && this.setState({showPlatformFliter: true});
                            }}>
                                <div style={styles.headerAccountNameInnerBox}>
                                    <div
                                        style={{...styles.headerAccountName, ...styles.textLeft}}>{this.state.transferDirection === 2 ? this.state.in_account.name : this.state.out_account.name}</div>
                                    {
                                        this.state.transferDirection === 2 ? (
                                            <Icon style={{marginTop: -5}} icon={'sjjt'} size={14}
                                                  color={'rgba(0,0,0,1)'}/>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{...styles.headerAccountBalanceBox, ...styles.leftBox}}>
                            {this._getLeftBalanceView()}

                        </div>
                    </div>
                    <div style={styles.headerMid}>
                        <div onClick={() => {
                            this._changeTransferDirection();
                        }}>
                            <Icon icon={'transfer'} size={50} color={style['primaryColor' + model.SKINNAME]}/>
                        </div>
                    </div>
                    <div style={styles.headerAccountBox}>
                        <div style={styles.headerAccount}>
                            <div style={styles.headerAccountNameRightBox} onClick={() => {
                                this.state.transferDirection === 1 && this.setState({showPlatformFliter: true});
                            }}>
                                <div style={styles.headerAccountNameInnerBox}>
                                    <div
                                        style={{...styles.headerAccountName, ...styles.textLeft}}>{this.state.transferDirection === 1 ? this.state.in_account.name : this.state.out_account.name}</div>
                                    {
                                        this.state.transferDirection === 1 ? (
                                            <Icon style={{marginTop: -5}} icon={'sjjt'} size={14}
                                                  color={'rgba(0,0,0,1)'}/>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{...styles.headerAccountBalanceBox, ...styles.rightBox}}>

                            {this._getRightBalanceView()}
                        </div>

                    </div>
                </div>
                <div style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center', marginTop:10,marginBottom:10, paddingTop: 10,paddingBottom:10,textAlign:'center',backgroundColor:'white',color:'#6c6c6c'}} onClick={()=>{
                    this.quanbuzhuanchu();
                }}>
                    {
                        this.state.zzwc == 0?'所有游戏余额转入中心账户':<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><ActivityIndicator size="small" color={'#fff'}/>请等待{this.state.djs}秒！</div>
                    }


                </div>
                <div style={styles.amountWrapper}>
                    <div style={styles.amountContainer}>
                        <div style={styles.amountTitle}>转入金额</div>
                        <div style={styles.amountInputContainer}>
                            <div style={styles.amountInputIconBox}>
                                <Icon icon={'rmb'} size={24} color={'#000'}/>
                            </div>
                            <div style={styles.amountInputBox}>
                                <MyInput style={styles.amountInput}
                                         value={this.state.amount && String(this.state.amount)}
                                         placeholder={'0.00'}
                                         onChangeText={(text) => {
                                             this._setAmount(text);
                                             // this.setState({amount: text});
                                         }}
                                         onKeyUp={(text) => {
                                             this.setState({amount: text.replace(/\D/g, '')})
                                         }}/>
                            </div>
                        </div>
                        <div style={styles.transferTips}>
                            <div>
                                {
                                    this._getTransferTips()
                                }
                            </div>
                            <div style={styles.transferTipsRight}>
                                <div style={styles.transferTipsColor4} onClick={() => {
                                    this._allAmount();
                                }}>
                                    <span>全部转出</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.amountTemplate}>
                        <div onClick={() => {
                            this._setAmount(100);
                        }} style={{...styles.amountTemplateItem, ...this.state.amount === 100 ? styles.active : null}}>
                            <div
                                style={{...styles.amountTemplateItemText, ...this.state.amount === 100 ? styles.activeText : null}}>￥100
                            </div>
                        </div>
                        <div onClick={() => {
                            this._setAmount(300);
                        }} style={{...styles.amountTemplateItem, ...this.state.amount === 300 ? styles.active : null}}>
                            <div
                                style={{...styles.amountTemplateItemText, ...this.state.amount === 300 ? styles.activeText : null}}>￥300
                            </div>
                        </div>
                        <div onClick={() => {
                            this._setAmount(500);
                        }} style={{...styles.amountTemplateItem, ...this.state.amount === 500 ? styles.active : null}}>
                            <div
                                style={{...styles.amountTemplateItemText, ...this.state.amount === 500 ? styles.activeText : null}}>￥500
                            </div>
                        </div>
                    </div>
                    <div style={styles.amountTemplate}>
                        <div onClick={() => {
                            this._setAmount(1000);
                        }} style={{...styles.amountTemplateItem, ...this.state.amount === 1000 ? styles.active : null}}>
                            <div
                                style={{...styles.amountTemplateItemText, ...this.state.amount === 1000 ? styles.activeText : null}}>￥1000
                            </div>
                        </div>
                        <div onClick={() => {
                            this._setAmount(2000);
                        }} style={{...styles.amountTemplateItem, ...this.state.amount === 2000 ? styles.active : null}}>
                            <div
                                style={{...styles.amountTemplateItemText, ...this.state.amount === 2000 ? styles.activeText : null}}>￥2000
                            </div>
                        </div>
                        <div onClick={() => {
                            this._setAmount(10000);
                        }}
                             style={{...styles.amountTemplateItem, ...this.state.amount === 10000 ? styles.active : null}}>
                            <div
                                style={{...styles.amountTemplateItemText, ...this.state.amount === 10000 ? styles.activeText : null}}>￥10000
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.btnBox}>
                    <MyButton title={'确认转账'} disabled={this.state.showProgress} showProgress={this.state.showProgress}
                              onPress={() => {
                                  this._submitTransfer()
                              }}/>
                </div>
                <div style={styles.btnBox}>
                    <MyButton title={'进入游戏'} disabled={this.state.showGameInProgress}
                              showProgress={this.state.showGameInProgress}
                              onPress={() => {
                                  this._enterGame()
                              }}/>
                </div>
                <MyPicker
                    title={'游戏平台'}
                    model={this.accountList}
                    display={this.state.showPlatformFliter}
                    onHiden={() => {
                        this.setState({showPlatformFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({showPlatformFliter: false});
                        this._changePlatform(item.value[0].value.value);
                        // this.setState({playType: item, showPlayTypeFliter: false, currpage: 1}, () => {
                        //     this._fetchList();
                        // });
                    }}
                />
            </Container>
        )
    }
}

// const pickerSelectStyles = StyleSheet.create({
//     inputIOS: {
//         fontSize: 15,
//         paddingTop: 11,
//         paddingHorizontal: 0,
//         paddingBottom: 0,
//         borderWidth: 0,
//         borderColor: 'gray',
//         borderRadius: 4,
//         color: 'black',
//         textAlign: "right",
//         marginRight: 10
//     },
//     inputAndroid: {
//         fontSize: 15,
//         paddingTop: 11,
//         paddingHorizontal: 0,
//         paddingBottom: 0,
//         borderWidth: 0,
//         borderColor: 'gray',
//         borderRadius: 4,
//         color: 'black',
//         textAlign: "right",
//         marginRight: 10
//     },
// });
