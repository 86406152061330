/**
 * 我要推广
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Image from "../component/image";
import MyButton from "../component/myButton";
import style from '../utils/config.scss'
import PublicUtils from "../utils/publicUtils";
import Storage from "../services/storage";
import model from "../services/model";
import Http from "../services/http";
import copy from 'copy-to-clipboard';
import global from "../global";

export default class Promotion extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            qrImgUrl: null,
            tg_url: null
        }
    }

    componentDidMount() {
        this._init_();
    }

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        const parm = {agents: user && user.user_account};
        Http.request("qrcode", parm, (res) => {
            console.log(res);
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    this.setState({qrImgUrl: res.data.url, tg_url: res.data.tg_url});
                }
            }
        });
    };

    render() {
        const styles = {
            titleBox: {
                display: "flex",
                padding: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            title: {
                color: "#000",
                fontSize: 18
            },
            subTitleBox: {
                display: "flex",
                padding: 5,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            subTitle: {
                color: "#999",
                fontSize: 14
            },
            qrImgBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                margin: 20
            },
            qrImg: {
                width: "50%",
                height: "50%"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
        };
        return (
            <Container title={'我要推广'} style={{backgroundColor: "#fff"}}>
                <div style={styles.titleBox}>
                    <div style={styles.title}>★方式一：扫码</div>
                </div>
                <div style={styles.subTitleBox}>
                    <div style={styles.subTitle}>将以下二维码发送给好友，扫一扫轻松注册</div>
                </div>
                <div style={styles.subTitleBox}>
                    <div style={styles.subTitle}>保存图片后，请您使用UC浏览器扫描进入</div>
                </div>
                <div style={styles.qrImgBox}>
                    <Image uri={this.state.qrImgUrl} style={styles.qrImg}/>
                </div>
                <div style={{height: 10, backgroundColor: "#f5f7f9"}}/>
                <div style={styles.titleBox}>
                    <div style={styles.title}>★方式二：链接</div>
                </div>
                <div style={styles.titleBox}>
                    <div style={styles.title}>{this.state.tg_url}</div>
                </div>
                <div style={styles.btnBox}>
                    <MyButton title={'点击我复制推广链接'} onPress={() => {
                        copy(this.state.tg_url);
                        PublicUtils.showToast("推广链接已复制到剪贴板");
                    }}/>
                </div>
                {/*{*/}
                {/*    global.ID === "yy" ?*/}
                {/*        <div style={styles.btnBox}>*/}
                {/*            <MyButton title={'代理联盟'} onPress={() => {*/}
                {/*                PublicUtils.linking("http://yz.01daili.com/")*/}
                {/*            }}/>*/}
                {/*        </div> : null*/}
                {/*}*/}

            </Container>
        )
    }
}
