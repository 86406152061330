import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import {HashRouter as Router, Route, Link, Switch, Redirect} from 'react-router-dom'
import history from "./services/history";
import model from "./services/model";

import Active from './pages/active';
import Home from './pages/home/index';
import Login from './pages/login/index';
import Wallet from './pages/wallet/index';
import Qiandao from "./pages/qiandao";
import Games from './pages/games';
import Me from './pages/me/index';
import Favorite from './pages/favorite/index';
import Userinfo from './pages/userinfo/index';
import SearchView from './pages/searchView';
import RechargeChannel from './pages/recharge-channel';
import RechargeDetail from './pages/recharge-detail';
import RechargeDetail2 from './pages/recharge-detail2';
import RechargeComplete from './pages/recharge-complete';
import Withdrawal from './pages/withdrawal';
import WithdrawComplete from './pages/withdraw-complete';
import AccountChange from './pages/account-change';
import AccountChangeDetail from './pages/account-change-detail';
import GameTransfer from './pages/gametransfer';
import BindBank from './pages/bindbank';
import BindbankComplete from './pages/bindbank-complete';
import Register from './pages/reister/index';
import MeDetail from './pages/me-detail';
import Message from './pages/message';
import MessageDetail from './pages/message-detail';
import GameReport from './pages/gamereport';
import Promotion from './pages/promotion';
import Subusers from './pages/subusers';
import AddAgentMember from './pages/addAgentMember';
import Subusers2 from './pages/subusers2';
import SubuserTransfer from './pages/subuser-transfer';
import AgentReport from './pages/agent-report';
import AgentReport2 from './pages/agent-report2';
import AgentUserReport from './pages/agent-user-report';
import GameList from './pages/gamelist';
import ActiveDetail from './pages/active-detail';
import HG from './pages/sport-games/hg/hg';
import SportOpenresult from './pages/sport-openresult';
import SportBetrecord from './pages/sport-betrecord';
import SportBetrecordDetail from './pages/sport-betrecord-detail';
import GameNavigation from './pages/lottery-games/game-navigation';
import KLSF from './pages/lottery-games/klsf';
import KS from './pages/lottery-games/ks';
import LHC from './pages/lottery-games/lhc';
import PCDD from './pages/lottery-games/pcdd';
import PKS from './pages/lottery-games/pks';
import SSC from './pages/lottery-games/ssc';
import RecentLotteryopen from './pages/recent-lotteryopen';
import LotteryBetrecord from './pages/lottery-betrecord';
import LotteryBetrecordDetail from './pages/lottery-betrecord-detail';
import BeAgent from './pages/beangent';
import FindPasswordAccoun from './pages/findPassword-account';
import FindPasswordAuthentication from './pages/findPassword-authentication';
import FindPasswordNewpassword from './pages/findPassword-newpassword';
import FindPasswordComplete from './pages/findPassword-complete';
import MemberBonus from './pages/member-bonus';
import MemberKuisun from './pages/member-kuisun';
import YEB from './pages/yeb/index';
import Grade from './pages/grade';
import Http from "./services/http";
import PublicUtils from "./utils/publicUtils";
import Image from './component/image'
import Icon from './utils/icon'
import Storage from "./services/storage";
import LotteryIntroduction from "./pages/lottery-games/lottery-introduction";
import Order from "./pages/lottery-games/order";
import SportIntroduction from "./pages/sport-games/hg/sport-introduction";
import SportIntroductionDetail from "./pages/sport-games/hg/sport-introduction-Detail";
import HgDetail from "./pages/sport-games/hg/hg-detail";
import HgBetting from "./pages/sport-games/hg/hg-betting";
import MemberSpecil from "./pages/member-specil";
import global from "./global";

class App extends Component {
    constructor(props) {
        super(props);
        const urlArry = window.location.href.split("#");
        if (urlArry.length > 1) {
            const queryAry = urlArry[1].split("?");
            if (urlArry[1].indexOf("Register") === -1 && urlArry[1].indexOf("Home") === -1 && queryAry[0] !== "/") {
                PublicUtils.navigate("Home");
            }
        }

        PublicUtils.saveUserInfo({agents: ""});
        const agents = this.getQueryString('agents') || localStorage.getItem("agents2") || null;

        if(agents){
            localStorage.setItem("agents2",agents)
        }



        model.AGENTS = agents || "";
        // alert(agents);
        if (agents) {
            PublicUtils.saveUserInfo({agents: agents});
        }
    }

    static router = [
        ['/', Home],
        ['/active', Active],
        ['/activedetail', ActiveDetail],
        ['/login', Login],
        ['/wallet', Wallet],
        ['/SignIn', Qiandao],
        ['/games', Games],
        ['/me', Me],
        ['/userinfo', Userinfo],
        ['/favorite', Favorite],
        ['/searchview', SearchView],
        ['/rechargechannel', RechargeChannel],
        ['/rechargedetail', RechargeDetail],
        ['/rechargedetail2', RechargeDetail2],
        ['/rechargecomplete', RechargeComplete],
        ['/withdrawal', Withdrawal],
        ['/withdrawcomplete', WithdrawComplete],
        ['/accountchange', AccountChange],
        ['/accountchangedetail', AccountChangeDetail],
        ['/gametransfer', GameTransfer],
        ['/bindbank', BindBank],
        ['/bindbankcomplete', BindbankComplete],
        ['/register', Register],
        ['/message', Message],
        ['/messagedetail', MessageDetail],
        ['/medetail', MeDetail],
        ['/gamereport', GameReport],
        ['/promotion', Promotion],
        ['/subusers', Subusers],
        ['/addAgentMember', AddAgentMember],
        ['/subusers2', Subusers2],
        ['/subusertransfer', SubuserTransfer],
        ['/agentreport', AgentReport],
        ['/agentreport2', AgentReport2],
        ['/agentuserreport', AgentUserReport],
        ['/gamelist', GameList],
        ['/hg', HG],
        ['/hgdetail', HgDetail],
        ['/hgbetting', HgBetting],
        ['/sportopenresult', SportOpenresult],
        ['/sportbetrecord', SportBetrecord],
        ['/sportbetrecorddetail', SportBetrecordDetail],
        ["/gamenavigation", GameNavigation],
        ['/order', Order],
        ['/klsf', KLSF],
        ['/ks', KS],
        ['/lhc', LHC],
        ['/pcdd', PCDD],
        ['/pks', PKS],
        ['/ssc', SSC],
        ['/yeb', YEB],
        ['/recentlotteryopen', RecentLotteryopen],
        ['/lotterybetrecord', LotteryBetrecord],
        ['/lotterybetrecorddetail', LotteryBetrecordDetail],
        ['/beagent', BeAgent],
        ['/findpasswordaccoun', FindPasswordAccoun],
        ['/findpasswordauthentication', FindPasswordAuthentication],
        ['/findpasswordnewpassword', FindPasswordNewpassword],
        ['/findpasswordcomplete', FindPasswordComplete],
        ['/grade', Grade],
        ['/memberbonus', MemberBonus],
        ['/memberkuisun', MemberKuisun],
        ['/memberspecil', MemberSpecil],
        ['/lotteryintroduction', LotteryIntroduction],
        ['/sportintroduction', SportIntroduction],
        ['/sportintroductiondetail', SportIntroductionDetail],
    ];

    /**
     * 获取url参数
     * @param name
     */
    getQueryString = (name) => {
        let value = "";
        const paramArray = window.location.hash.split('?');
        if (paramArray.length > 1) {
            const paramItem = paramArray[1].split('&');
            for (let i = 0; i < paramItem.length; i++) {
                if (paramItem[i].split('=')[0] === name) {
                    value = paramItem[i].split('=')[1];
                }
            }
        }
        return value;
    };

    componentDidMount() {
        // this._fetchData();
        // model.agents = this.getQueryString('agents') || null;

    }
    componentWillUnmount() {
    }

    render() {
        return (
            <div style={{...styles.container, ...model.clientWidth >= 640 ? styles.hasborder : null}}>
                <Router history={history}>
                    <Switch>
                        {
                            App.router.map((item, key) => {
                                return (
                                    <Route key={key} exact path={item[0]} component={item[1]}
                                           location={this.props.location}/>
                                )
                            })
                        }
                        <Redirect to="/"/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

const styles = {
    container: {
        position: "relative",
        width: "100%",
        height: "100%",
        maxWidth: "640px",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    hasborder: {
        borderLeft: "1px solid #f5f7f9",
        borderRight: "1px solid #f5f7f9",
    }
};
export default App;
