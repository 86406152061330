/**
 * 自定义图标
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import model from "../services/model";
import global from "../global";
import Storage from "../services/storage";
import Image from '../component/image'
import Icon from '../utils/icon'
import PublicUtils from "../utils/publicUtils";
import style from '../utils/config.scss'

export default class HotActive extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    static propTypes = {
        redbag: PropTypes.object,
        activity_game_list: PropTypes.array,
    };
    static defaultProps = {};

    render() {
        const styles = {
            searchBarHeader: {
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                height: 60,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                maxWidth: "640px",
                margin: "auto"
            },
            swiperBox: {
                paddingBottom: "50%"
            },
            searchBar: {
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            searchIpt: {
                height: 35,
                flex: 1,
                flexDirection: "row",
                display: "flex",
                borderRadius: 35,
                backgroundColor: "rgba(0,0,0,0.2)",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10
            },
            searchTextTip: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            iconBox: {
                width: 45,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            swiperContainer: {
                flexDirection: "row",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
            },
            swiperItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            body: {
                marginTop: 60,
                position: "relative",
                overflow: "hidden"
            },
            headerbg: {
                position: "absolute",
                left: -model.clientWidth * 0.1,
                right: -model.clientWidth * 0.1,
                height: 145,
                borderBottomRightRadius: 95,
                borderBottomLeftRadius: 95,
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            quickEntryBox: {
                background: "#fff"
            },
            quickEntry: {
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 10
            },
            quickEntryItem: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            quickEntryImg: {
                width: 50,
                height: 50
            },
            quickEntryText: {
                fontSize: 14,
                marginTop: 5
            },
            // noticeWrapper: {
            //     borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME]
            // },
            // noticeBox: {
            //     backgroundColor: "#fff",
            //     display: "flex",
            //     flexDirection: "row",
            //     overflow: "hidden",
            //     height: 40,
            //     lineHeight: 40,
            //     alignItems: "center",
            //     marginTop: 1,
            //     paddingLeft: 10,
            //     paddingRight: 10,
            //     borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
            //     // borderTopWidth: 1,
            //     // borderTopColor: "#f5f7f9"
            // },
            // noticeIcon: {
            //     width: 20,
            // },
            // noticeText: {
            //     flex: 1,
            //     paddingLeft: 5,
            //     fontSize: 12,
            //     overflow: "hidden",
            //     textOverflow: "ellipsis",
            //     whiteSpace: "nowrap"
            // },
            hotBox: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            hotTitle: {
                display: "flex",
                height: 50,
                color: "#000",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            hotBigTitle: {
                color: "rgb(35,35,35)",
                fontSize: 17
            },
            point: {
                color: "rgb(127,137,143)",
                fontSize: 30,
                marginLeft: 5,
                marginRight: 5
            },
            hotSubTitle: {
                color: "rgb(127,137,143)",
                fontSize: 12
            },
            hotBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
            hotGameBody: {
                width: "100%",
                overflow: "auto"
            },
            hotInnerBody: {
                display: "flex",
            },
            gameBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                width: (model.clientWidth - 40) / 3,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            gameImgBox: {
                position: "relative"
            },
            gameImg: {
                width: (model.clientWidth - 40) / 3,
            },
            gameNameBox: {
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: "row",
                justifyContent: "center"
            },
            gameName: {
                color: "#fff",
                fontSize: 14
            },
            hotActive: {
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9"
            },
            bodyEnd: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30
            },
            bodyEndText: {
                color: "rgb(218,218,218)",
                margin: 5
            },
            hotActiveBody: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10
            },
            hotActiveImg: {
                marginBottom: 5,
                width: model.clientWidth - 20,
                // height: 55
            },
            superWinnerBox: {
                paddingBottom: 20,
                backgroundColor: "#fff"
            },
            superWinnerBody: {
                paddingLeft: 10,
                paddingRight: 10,
                height: 200,
                overflow: "hidden"
            },

            superWinnerItem: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
                alignItems: "center",
                backgroundColor: "rgb(255,250,231)"
            },
            superWinnerItemLeft: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
            },
            superWinneImg: {
                width: 16,
                height: 16
            },
            superWinnerText: {
                fontSize: 12,
                marginLeft: 5,
                marginRight: 5,
                color: "rgb(35,35,35)"
            },
            superWinnerText1: {
                fontSize: 12,
                color: "rgb(35,35,35)"
            },
            superWinnerText2: {
                fontSize: 12,
                color: style['primaryColor' + model.SKINNAME],
                margin: "0 5px"
            },

            superWinnerItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            },
            superWinnerItemRightText: {
                fontSize: 12,
                color: "rgb(193,193,193)"
            },
            superWinnerText3: {
                fontSize: 12,
                color: "rgb(255,150,0)",
                marginLeft: 5
            },
            hotType: {
                width: 40,
                height: 40,
                position: "absolute",
                left: 0,
                top: 0
            },
            appDownloadShadow: {
                // backgroundColor: "#fff"
            },
            appDownloadBox: {
                position: "relative",
                margin: "20px 10px 10px",
                backgroundColor: "#fffae7",
                height: 45,
                lineHeight: "45px",
                borderRadius: 45,
                fontSize: 12,
            },
            appDownloadImg: {
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "75px"
            },
            appDownloadText: {
                marginLeft: "85px",
                color: "#ff9900"
            },
            appDownloadButtonBox: {},
            appDownloadButton: {
                color: "#fff",
                position: "absolute",
                right: 10,
                bottom: 0,
                top: 0,
                margin: "auto",
                height: 30,
                borderRadius: 30,
                padding: "0 10px",
                lineHeight: "30px",
                background: "linear-gradient(to right, #ffc605, #ff8805)",
            },
            hotAlotGameBox: {
                flex: "0 0 " + model.clientWidth * 0.28 + "px",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
                fontSize: 14,
                textAlign: "center"
            },
            noticeWrapperBox: {
                borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                padding: "0 10px",
            },
            noticeWrapperIconBox: {
                width: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            noticeWrapperContentBox: {
                flex: 1,
                height: 40,
                lineHeight: "40px",
                overflow: "hidden",
                fontSize: 14,
                marginLeft: 5,
                position: "relative"

            },
            activeDetailBox: {
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 0,
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 100,
            },
            activeDetailRow: {
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "row",
                background: "linear-gradient(to bottom, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
            activeDetailCol: {
                width: "25%",
                paddingTop: 20,
                paddingBottom: 20,
                display:"flex"
            },
            activeDetailInnerBox: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // backgroundColor:"#000"
            },
            activeDetailInnerImgBox: {
                borderRadius: 3,
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
            },
            activeDetailInnerImg: {
                width: "80%"
            },
            activeDetailInnerTextBox: {
                color: "#fff",
                fontSize: 14,
                marginTop: 5
            },
            activeBox: {
                position: "fixed",
                bottom: 150,
                right: 30,
                width: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
            }
        };
        return (
            <div>
                <div style={styles.activeBox} onClick={() => {
                    this.setState({show: true});
                }}>
                    <Image
                        uri={require('../assets/images/active.png')}/>
                </div>
                {
                    this.state.show ?
                        <div style={styles.activeDetailBox} onClick={() => {
                            this.setState({show: false});
                        }}>
                            <div style={styles.activeDetailRow} onClick={(event) => {
                                event.stopPropagation();
                            }}>
                                {
                                    this.props.redbag.status ?
                                        <div style={styles.activeDetailCol} onClick={() => {
                                            const token = Storage.retrieveData("token") || "";

                                            if(!token){
                                                PublicUtils.navigate('Login', {nextPage: '/Home'});

                                                return
                                            }


                                            PublicUtils.linking(global.ACTIVE_URL + "/active/redpackage/index.html?id=" + global.ID + "&token=" + token || "", "_blank");
                                        }}>
                                            <div style={styles.activeDetailInnerBox}>
                                                <div style={styles.activeDetailInnerImgBox}>
                                                    <Image style={styles.activeDetailInnerImg}
                                                           hasPlaceholder={true}
                                                           placeholderSize={{
                                                               width: model.clientWidth*0.25*0.8,
                                                               height: model.clientWidth*0.25*0.8,
                                                           }}
                                                           uri={this.props.redbag.pic}/>
                                                </div>
                                                <div style={styles.activeDetailInnerTextBox}>抢红包</div>
                                            </div>
                                        </div> : null
                                }

                                {
                                    this.props.activity_game_list.map((item, key) => {
                                        if (item.status) {
                                            const token = Storage.retrieveData("token") || "";
                                            let url = null;
                                            let uri = null;
                                            let name = null;
                                            switch (item.type) {
                                                case 1:
                                                    url = global.ACTIVE_URL + "/active/rotary/index.html?id=" + global.ID + "&token=" + token || "";
                                                    uri = item.pic ? item.pic : require('../assets/images/rotary-1.png');
                                                    name = "大转盘";
                                                    break;
                                                case 2:
                                                    url = global.ACTIVE_URL + "/active/box/index.html?id=" + global.ID + "&token=" + token || "";
                                                    uri = item.pic ? item.pic : require('../assets/images/box.png');
                                                    name = "开宝箱";
                                                    break;
                                                case 3:
                                                    url = global.ACTIVE_URL + "/active/egg/index.html?id=" + global.ID + "&token=" + token || "";
                                                    uri = item.pic ? item.pic : require('../assets/images/egg.png');
                                                    name = "砸金蛋";
                                                    break;
                                                case 5:
                                                    url = global.ACTIVE_URL + "/active/sieve/index.html?id=" + global.ID + "&token=" + token || "";
                                                    uri = item.pic ? item.pic : require('../assets/images/wan_1.png');
                                                    name = "中秋博饼";
                                                    break;
                                                case 6:
                                                    url = global.ACTIVE_URL + "/active/collect/index.html?id=" + global.ID + "&token=" + token || "";
                                                    uri = item.pic ? item.pic : require('../assets/images/wan_1.png');
                                                    name = "新年集福";
                                                case 7:
                                                    url = global.ACTIVE_URL + "/active/gold_ingot/index.html?id=" + global.ID + "&token=" + token || "";
                                                    uri = item.pic ? item.pic : require('../assets/images/xiazai.png');
                                                    name = "金元宝";
                                            }
                                            return (
                                                <div style={styles.activeDetailCol} key={key} onClick={() => {

                                                    const token = Storage.retrieveData("token") || "";

                                                    if(!token){
                                                        PublicUtils.navigate('Login', {nextPage: '/Home'});

                                                        return
                                                    }


                                                    PublicUtils.linking(url, "_blank");
                                                }}>
                                                    <div style={styles.activeDetailInnerBox}>
                                                        <div style={styles.activeDetailInnerImgBox}>
                                                            <Image style={styles.activeDetailInnerImg}
                                                                   hasPlaceholder={true}
                                                                   placeholderSize={{
                                                                       width: model.clientWidth*0.25*0.8,
                                                                       height: model.clientWidth*0.25*0.8,
                                                                   }}
                                                                   uri={item.pic}/>
                                                        </div>
                                                        <div style={styles.activeDetailInnerTextBox}>{name}</div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}
