//菜单栏

import React, {Component} from 'react';
import PropTypes from "prop-types";
import Loadding from "./loadding";
import NoneData from "./noneData";

class PullEndFooter extends React.Component {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        showFoot: PropTypes.number,
        list: PropTypes.array,
        onEndReached: PropTypes.func
    };
    static defaultProps = {
        showFoot: 0,
        list: []
    };

    /**
     * 页脚
     * @returns {*}
     * @private
     */
    _renderFooter() {
        const styles = {
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                margin: "10px",
                borderRadius: 6
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            }
        }
        let renderView = null;
        if (this.props.showFoot === 1) {
            if (!this.props.list.length) {
                renderView = (
                    <NoneData/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody}>
                            <div style={styles.footerBodyText}>
                                没有更多数据了
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.props.showFoot === 2) {
            if (!this.props.list.length) {
                renderView = (
                    <Loadding/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody} onClick={() => {
                            this.props.onEndReached && this.props.onEndReached();
                        }}>
                            <div style={styles.footerBodyText}>
                                点击加载更多
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.props.showFoot === 0) {
            renderView = (
                <div style={styles.footer}>
                    {/*<View style={styles.footerBody}>*/}
                    {/*<Text style={styles.footerBodyText}>*/}
                    {/*努力加载中...*/}
                    {/*</Text>*/}
                    {/*</View>*/}
                    <div style={styles.footerPadding}/>
                </div>
            );
        }
        return renderView;
    }

    render() {
        return this._renderFooter();
    }
}

export default PullEndFooter;