/**
 * 下级代理
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import MyInput from "../component/myInput";
import style from "../utils/config.scss";
import DatePicker from 'react-mobile-datepicker';
import global from "../global";
import MyButton from "../component/myButton";

export default class AddAgentMember extends React.PureComponent {
  // 构造
  constructor(props) {
    super(props);
    this.state = {
      uName:'',
      uName2:'',
      uPwd:'',
      uPwd2:'',
      sr:'',
      sj:'',
      yx:'',
      wx:'',
      tx:'',
      tx2:'',
      isOpen:false,
      time: new Date(),
      showProgress:false,
    }
  }

  /**
   * 页面进入时
   * @returns {Promise<void>}
   */
  componentWillMount = () => {

  };

  formatDate = (date) => {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    let minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    let second = date.getSeconds();
    second = minute < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d;
  };



  handleCancel = () => {
    this.setState({isOpen: false});
  }

  handleSelect = (time) => {

    this.setState({sr: this.formatDate(time), isOpen: false});

    // this._checkForm({birthday: this.formatDate(time)});
  }

  handleClick = () => {
    this.setState({isOpen: true});
  }




  subfn(){


    // if(!this.state.uName){
    //   PublicUtils.showToast("请输入账号");
    //   return
    // }

    if(this.state.showProgress){
      return
    }

    if (!this.state.uName || !model.inputRule.username.rule.test(this.state.uName)) {

      PublicUtils.showToast(model.inputRule.username.ruleMsg)

      return false;
    }


    if(!this.state.uPwd){
      PublicUtils.showToast("请输入密码");
      return
    }

    if(this.state.uPwd != this.state.uPwd2){
      PublicUtils.showToast("两次输入密码不一致");
      return
    }

    if(this.state.tx){
      if(this.state.tx != this.state.tx2){
        PublicUtils.showToast("两次提现密码输入不一致");
        return
      }
    }


    this.setState({showProgress:true})


    Http.request('addAgentMember', {
      user_account: this.state.uName,
      login_password: this.state.uPwd,
      login_password_confirm: this.state.uPwd2,
    }, (response) => {

      this.setState({showProgress:false})

      PublicUtils.showToast(response.message);




      if (response.data.status === 1) {
      } else {
      }
    });



  }

  render() {
    const styles = {
      abd1:{
        display:'flex',
        justifyContent:"center",
        alignItems:'center',
        flexDirection:'column',
      },
      abd2:{
        marginBottom:10,
        fontSize:14,
        color:'#5f5f5f'
      },

      abd3:{
        width:"90%",
        paddingBottom:10,
        paddingTop:10,
        paddingRight:10,
        paddingLeft:10,
        backgroundColor:"white",
        borderRadius:4,
      },
      abd4:{
        paddingBottom:5,
        paddingTop:5,
        paddingRight:5,
        paddingLeft:5,
        borderStyle:'solid',
        borderWidth: 1,
        borderColor:'#e7e7e7'
      },
      btnBox: {
        width:'95%',
        margin:"30px auto 30px auto",
        display: "flex",
        color:'white',
        flex: 1,
        backgroundColor: style['primaryColor' + model.SKINNAME],
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        borderRadius: 3
      },
      fc:{
        color:"red"
      }
    };
    return (
      <Container title={'新增会员'}>
        <div style={styles.abd1}>


          <div>
            <MyInput onPress={this.handleClick} readOnly={true}
                     value={this.state.sr}
                     placeholder=""
                     onChangeText={(text) => {}}/>
          </div>

          <div style={styles.abd3}>
            <div style={styles.abd2}>
              <span style={styles.fc}>*</span>用户名
            </div>
            <div style={styles.abd4}>
              <MyInput placeholder=""
                       value={this.state.uName}
                // onText={(text) => this._onText(text)}
                       onChangeText={(text) => {
                         this.setState({
                           uName: text
                         })
                       }}
              />
            </div>
          </div>


          <div style={styles.abd3}>
            <div style={styles.abd2}>
              <span style={styles.fc}>*</span>登陆密码
            </div>
            <div style={styles.abd4}>
              <MyInput placeholder="" type="password"
                       value={this.state.uPwd}
                // onText={(text) => this._onText(text)}
                       onChangeText={(text) => {
                         this.setState({
                           uPwd: text
                         })
                       }}
              />
            </div>
          </div>


          <div style={styles.abd3}>
            <div style={styles.abd2}>
              <span style={styles.fc}>*</span>确认密码
            </div>
            <div style={styles.abd4}>
              <MyInput placeholder="" type="password"
                       value={this.state.uPwd2}
                // onText={(text) => this._onText(text)}
                       onChangeText={(text) => {
                         this.setState({
                           uPwd2: text
                         })
                       }}
              />
            </div>
          </div>


          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    真实姓名*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder=""*/}
          {/*             value={this.state.uName2}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 uName2: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    出生日期*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder=""  onPress={this.handleClick}*/}
          {/*             value={this.state.sr}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 sr: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    手机号码*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder=""*/}
          {/*             value={this.state.sj}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 sj: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    邮箱地址*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder=""*/}
          {/*             value={this.state.yx}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 yx: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    QQ或微信*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder=""*/}
          {/*             value={this.state.wx}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 wx: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    提取密码*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder="" type="password"*/}
          {/*             value={this.state.tx}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 tx: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div style={styles.abd3}>*/}
          {/*  <div style={styles.abd2}>*/}
          {/*    确认密码*/}
          {/*  </div>*/}
          {/*  <div style={styles.abd4}>*/}
          {/*    <MyInput placeholder="" type="password"*/}
          {/*             value={this.state.tx2}*/}
          {/*      // onText={(text) => this._onText(text)}*/}
          {/*             onChangeText={(text) => {*/}
          {/*               this.setState({*/}
          {/*                 tx2: text*/}
          {/*               })*/}
          {/*             }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

        </div>


        <div style={{...styles.btnBox, ...!this.state.enableLoginBtn ? styles.disabledBtn : null}}>
          {/*<div*/}

          {/*  style={{...styles.btnText, ...!this.state.enableLoginBtn ? styles.disabledBtnText : null}}>确认新增</div>*/}

          <MyButton title={'确认增加'} showProgress={this.state.showProgress}
            // disabled={!this.state.enableLoginBtn || this.state.showProgress}
                    style={{...styles.btnBox}}
                    onPress={() => {
                      this.subfn()
                    }}/>

        </div>


        <DatePicker
          theme={'ios'}
          value={this.state.time}
          isOpen={this.state.isOpen}
          onSelect={this.handleSelect}
          onCancel={this.handleCancel}/>

      </Container>
    )
  }
}
