import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import history from '../services/history'
import style from '../utils/config.scss'
import Container from '../component/container'
import MyButton from '../component/myButton'
import MyInput from '../component/myInput'
import Icon from '../utils/icon'
import Http from "../services/http";
import PublicUtils from '../utils/publicUtils'
import global from '../global'
import model from "../services/model";
import Storage from "../services/storage";

class FindPasswordAcount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            acount: null
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    /**
     * 提交用户名
     * @private
     */
    _submit = () => {
        if (!this.state.acount) {
            PublicUtils.showToast("请输入您的用户名");
            return;
        }
        this.setState({showProgress: true});
        const parm = {
            type: 1,
            user_account: this.state.acount
        };
        Http.request("mobileChange", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    PublicUtils.navigate("FindPasswordAuthentication",{username:this.state.acount});
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };

    render() {
        return (
            <Container title={'找回密码'}>
                <div style={styles.container}>
                    <div style={styles.Box}>
                        <div style={styles.iptLabel}>用户名：</div>
                        <div style={styles.iptBox}>
                            <MyInput placeholder={'请输入您的用户名'} onChangeText={(text) => {
                                this.setState({acount: text});
                            }}/>
                        </div>
                    </div>
                    <MyButton disabled={this.state.showProgress} showProgress={this.state.showProgress} title={'提交'}
                              onPress={() => {
                                  this._submit();
                              }}/>
                </div>
            </Container>
        )
    }
}

const styles = {
    container: {
        padding: 10
    },
    Box: {
        display: "flex",
        flexDirection: "row",
        height: 50,
        alignItems: "center",
        fontSize: 14,
        border: "1px solid #f5f7f9",
        marginBottom: 30,
        marginTop: 30,
        backgroundColor: "#fff",
        padding: "0 10px",
        borderRadius: 3
    },
    iptLabel: {
        display: "flex"
    },
    iptBox: {
        display: "flex",
        flex: 1
    }
};
export default withRouter(FindPasswordAcount);