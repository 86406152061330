/**
 * 提示消息
 */

import React from 'react';
import PropTypes from 'prop-types';
import TextScroll from 'react-textscroll'
import PublicUtils from '../utils/publicUtils'
import global from '../global'

class NoticeContainer extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            noticeList: []
        }
    }

    static propTypes = {
        noticeList: PropTypes.array
    };
    static defaultProps = {};
    componentWillReceiveProps = (nextProps) => {
        // let noticeList = [];
        // for (let i = 0; i < nextProps.noticeList.length; i++) {
        //     const item = {label: i, value: nextProps.noticeList[i]};
        //     noticeList.push(item);
        // }
        // this.setState({noticeList: noticeList});
    };

    render() {
        const styles = {
            noticeContainer: {
                display: "flex",
                flexDirection: "row"
            },
            noticeWrapper: {
                marginRight: 50,
                color: global.THEME === "default" ? "#000" : "#fff",
            },
            noticeBox: {
                // backgroundColor: "#fff",
                // display: "flex",
                // flexDirection: "row",
                // // overflow: "hidden",
                // height: 40,
                // lineHeight: 40,
                // alignItems: "center",
                // marginTop: 1,
                // paddingLeft: 10,
                // paddingRight: 10,
                // borderTop: "1px solid " + style['backgrountColor' + model.SKINNAME]
                // // borderTopWidth: 1,
                // // borderTopColor: "#f5f7f9"
            },
            noticeIcon: {
                width: 20,
            },
            noticeText: {
                // paddingLeft: 5,
                // fontSize: 12,
                // // overflow: "hidden",
                // whiteSpace: "nowrap"
            },
        };
        return (
            <marquee>
                <div style={styles.noticeContainer}>
                    {
                        this.props.noticeList.map((item,key) => {
                            return (
                                <div key={key} style={styles.noticeWrapper} onClick={() => {
                                    PublicUtils.navigate("MessageDetail", {
                                        data: {
                                            id: "",
                                            is_title: "",
                                            add_time: "",
                                            is_content: item
                                        }, type: 2
                                    });
                                }}>{item}</div>
                            )
                        })
                    }
                </div>
            </marquee>
            // <div className={"noticeContainer"}>
            //     <div style={styles.noticeWrapper} className={"noticeTextBox"}>
            //         <div>撒旦发射点发射点发生得分士士大夫士大夫士大夫收到收到发顺丰是 11111</div>
            //         <div>撒旦发射点发射点发生得分士231发v冰女冰女vbnvbnv 收到是 2222</div>
            //     </div>
            // </div>
        )
    }
}

export default NoticeContainer;
