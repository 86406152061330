/**
 * 投注时间
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Countdown from "./countdown";
import Http from "../../../services/http";
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";
import PublicUtils from "../../../utils/publicUtils";
import Storage from '../../../services/storage'
import model from "../../../services/model";
import Image from "../../../component/image";
import dice1 from "../../../assets/images/dice1.png";
import dice2 from "../../../assets/images/dice2.png";
import dice3 from "../../../assets/images/dice3.png";
import dice4 from "../../../assets/images/dice4.png";
import dice5 from "../../../assets/images/dice5.png";
import dice6 from "../../../assets/images/dice6.png";

export default class TimeBox extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showList: false,
            refresh: false,
            lotteryHistoryResult: {
                firstResult: {
                    is_number: "",
                    is_no: "",
                },
                list: []
            }
        };
        this.lotteryInfo = {};
    }

    static propTypes = {
        templateType: PropTypes.string,
        lotteryInfo: PropTypes.object,
        code: PropTypes.number,
        lottery_no: PropTypes.string,
        openSecondCountdownEnd: PropTypes.func,
        stopSecondCountdownEnd: PropTypes.func,
        countDownModel: PropTypes.string
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        // console.log(this.props.lottery_no);
        this._init_();
    };

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchHistoryResult();
    };

    /**
     * 获取历史开奖结果
     * */
    _fetchHistoryResult = () => {
        // this.lotteryHistoryResult.list = [];
        const params = {is_code: this.props.code, is_number: 5};
        Http.request("lotteryNumberList", params, (res) => {
            // alert(JSON.stringify(res));
            if (res.data.status === 1) {
                if (res.data.number.length) {
                    const cur_no = parseInt(this.props.lottery_no);
                    const prev_no = parseInt(res.data.number[0].is_no);


                    if (cur_no - prev_no > 1) {
                        // this.pollingOpenResultTimer && window.clearInterval(this.pollingOpenResultTimer);
                        // this.pollingOpenResultTimer = setTimeout(() => {
                        //     this._fetchHistoryResult();
                        // }, 2000);
                        // let lotteryHistoryResult = {
                        //     firstResult: {
                        //         // is_no: cur_no - 1,
                        //         is_no: res.data.number[0].is_no,
                        //         hasResult: false,
                        //         is_number: "",
                        //         message: "正在开奖"
                        //     },
                        //     list: res.data.number
                        // };
                      // this.setState({lotteryHistoryResult: lotteryHistoryResult});


                      let firstResult = res.data.number[0];
                      firstResult.hasResult = true;
                      let lotteryHistoryResult = {
                        firstResult: firstResult,
                        list: res.data.number
                      };
                      lotteryHistoryResult.list.splice(0, 1);
                      this.setState({lotteryHistoryResult: lotteryHistoryResult});


                    } else if(cur_no - prev_no  ==  1){


                      let firstResult = res.data.number[0];
                      firstResult.hasResult = true;
                      let lotteryHistoryResult = {
                        firstResult: firstResult,
                        list: res.data.number
                      };
                      lotteryHistoryResult.list.splice(0, 1);
                      this.setState({lotteryHistoryResult: lotteryHistoryResult});


                    } else {



                      this.pollingOpenResultTimer && window.clearInterval(this.pollingOpenResultTimer);
                      this.pollingOpenResultTimer = setTimeout(() => {
                        this._fetchHistoryResult();
                      }, 2000);
                      let lotteryHistoryResult = {
                        firstResult: {
                          is_no: cur_no - 1,
                          hasResult: false,
                          is_number: "",
                          message: "正在开奖"
                        },
                        list: res.data.number
                      };

                      console.log(lotteryHistoryResult)

                      this.setState({lotteryHistoryResult: lotteryHistoryResult});

                    }



                } else {
                    PublicUtils.showToast("近期开奖记录获取失败");
                }
            } else {
                PublicUtils.showToast(res.data.message);
            }
        })
    };

    /**
     * 获取当前期号的开奖结果
     */
    _fetchOpenResult(lottery_no) {
        let parm = {is_no: lottery_no, is_code: this.props.code};
        Http.request("getLotteryOpen", parm, (res) => {
            if (res.data && res.data.status) {
                if (res.data.number) {
                    //this.lotteryBaseInfo.curOpenNumber = res.data.number;
                    //this.getHistoryResult();

                    this.lotteryHistoryResult.firstResult = {
                        is_no: lottery_no,
                        hasResult: true,
                        is_number: res.data.number,
                        message: "已开奖..."
                    }
                } else {
                    this.pollingOpenResultTimer && window.clearInterval(this.pollingOpenResultTimer);
                    this.pollingOpenResultTimer = setTimeout(() => {
                        this._fetchOpenResult(lottery_no);
                    }, 2000)
                }
            }
        });
    }

    /**
     * 开奖结果总和
     * @param numbers
     * @returns {number}
     */
    _total = (numbers) => {
        let numList = numbers.split(',');
        let total = 0;
        for (let i = 0; i < numList.length; i++) {
            total += Number(numList[i]);
        }
        return total;
    };

    /**
     *色子
     * @private
     */
    _renderDice = (number, size) => {
        const styles = {
            lotteryTopBox: {
                position: "relative",
                height: 160,
            },
            diceImg: {
                width: 19,
                height: 19,
                marginLeft: 5,
            },
            wrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            timeVlaue: {
                color: "rgba(246, 48, 85, 0.94)",
                marginRight: 3,
                fontSize: 18
            },
            timeTips: {
                color: "rgba(0,0,0,0.5)",
                marginRight: 3
            },
            headerbg: {
                marginLeft: -model.clientWidth * 0.05,
                marginRight: -model.clientWidth * 0.05,
                height: 105,
                borderBottomRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
        };
        if (number == 1) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice1}/>
            )
        }
        if (number == 2) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice2}/>
            )
        }
        if (number == 3) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice3}/>
            )
        }
        if (number == 4) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice4}/>
            )
        }
        if (number == 5) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice5}/>
            )
        }
        if (number == 6) {
            return (
                <Image style={{...styles.diceImg, ...size ? {height: size, width: size} : null}}
                       uri={dice6}/>
            )
        }
    };

    _renderOpenNumber = (is_number) => {
        const headerStyles = {
            openBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 35,
                alignItems: "center",
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9",
                paddingLeft: 10,
                paddingRight: 10,
            },
            openNoBox: {
                display: "flex",
                flexDirection: "row"
            },
            openNo: {
                color: "#0a9dc7",
                marginRight: 3
            },
            openNumberBox: {
                display: "flex",
                flexDirection: "row"
            },
            symbol: {
                marginLeft: 3
            },
            timeBox: {
                position: "absolute",
                margin: "-95px auto 20px",
                left: 10,
                right: 10,
                paddingLeft: 15,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                backgroundColor: "#fff",
                boxShadow: "0 5px 10px rgba(0,0,0,0.08)",
                borderRadius: 7,
                zIndex: 2
                // marginTop: 36
            },
            timeTopWrapperBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center"
            },
            timeTopWrapperRightBox: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                paddingLeft: 20,
                paddingTop: 3,
                paddingBottom: 3,
            },
            timeTopBox: {
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
                fontSize: 14
            },
            timeTopLeftBox: {
                display: "flex",
                flexDirection: 'row'
            },
            timeTips: {
                color: "rgba(0,0,0,0.7)",
                marginRight: 3
            },
            timeTopRightBox: {},
            timeBottomBox: {
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
            },
            timeBottomLeftBox: {
                display: "flex",
                flexDirection: "row"
            },
            timeVlaue: {
                color: "rgba(246, 48, 85, 0.94)",
                marginRight: 3,
                fontSize: 14
            },
            timeBottomRightBox: {
                fontSize: 18,
                color: "rgba(246, 48, 85, 0.94)"
            },
            openListBox: {
                backgroundColor: "#fff"
            },
            recentOpenListBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                fontSize: 14,
                marginTop: 10
            },
            recentOpenFirstBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start"
            },
            recentOpenFirstTitle: {
                display: "flex",
                flexDirection: "row"
            },
            recentOpenFirstContent: {
                display: "flex",
                justifyContent: "center"
            },
            ballbox: {
                display: "flex",
                flexDirection: "row"
            },
            ball: {
                width: 18,
                height: 18,
                borderRadius: 18,
                overflow: "hidden",
                textAlign: "center",
                lineHeight: "18px",
                marginLeft: 3,
                color: "#fff",
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                fontSize: 10
            },
            pksBall: {
                height: 23,
                borderRadius: 2,
                color: "#fff",
                lineHeight: "23px",
                textAlign: "center",
                fontSize: 12,
                width: 16,
                marginLeft: 3
            },
            color1: {
                backgroundColor: "#fcf53c"
            },
            color2: {
                backgroundColor: "#4990e2"
            },
            color3: {
                backgroundColor: "#4a4a4a"
            },
            color4: {
                backgroundColor: "#f6a623"
            },
            color5: {
                backgroundColor: "#50b8e3"
            },
            color6: {
                backgroundColor: "#5d12fe"
            },
            color7: {
                backgroundColor: "#b4b3b3"
            },
            color8: {
                backgroundColor: "#d0011b"
            },
            color9: {
                backgroundColor: "#801111"
            },
            color10: {
                backgroundColor: "#7ed321"
            },
            recentOpenBox: {
                display: "flex",
                flexDirection: "column"
            },
            recentOpenItemBox: {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: 10
            },
            recentOpenTitle: {
                display: "flex",
                flexDirection: "row"
            },
            recentOpenContent: {
                display: "flex",
                justifyContent: "center"
            },
        };
        //pc蛋蛋
        if (this.props.templateType === "PCDD") {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    if (i < is_number.split(',').length - 1) {
                        balls = <div key={i} style={headerStyles.ballbox}>
                            <div
                                style={headerStyles.ball}>{number}</div>
                            <div
                                style={headerStyles.symbol}>+
                            </div>
                        </div>;
                    }
                    if (i === is_number.split(',').length - 1) {
                        balls = <div key={i} style={headerStyles.ballbox}>
                            <div
                                style={headerStyles.ball}>{number}</div>
                            <div
                                style={headerStyles.symbol}>=
                            </div>
                        </div>;
                    }
                    return balls;
                })
            );
        }
        //pk拾、快乐飞艇
        else if (this.props.templateType === "PK10") {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    balls = <div key={i} style={headerStyles.ballbox}>
                        <div
                            style={{...headerStyles.pksBall, ...headerStyles["color" + number], ...{textShadow: "1px 1px 2px #000"}}}>{number}</div>
                    </div>;
                    return balls;
                })
            )
        }
        //快三
        else if (this.props.templateType === "KS") {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    balls = <div key={i} style={headerStyles.ballbox}>
                        {this._renderDice(number)}
                    </div>;
                    return balls;
                })
            )
        } //快三
        else if (this.props.templateType === "LHC") {
            return (
              is_number.split(',').map((number, i) => {


                  // 背景颜色
                  let bcl = '';
                  if(number == 1 || number == 2 || number == 12 || number == 13 || number == 23 || number == 24 || number == 34 || number == 35 || number == 45 || number == 46 || number == 7 || number == 8 || number == 18 || number == 19 || number == 29 || number == 30 || number == 40){
                      bcl = "rgba(246, 48, 85, 0.94)";
                  }

                  if(number == 11 || number == 21 || number == 22 || number == 32 || number == 33 || number == 43 || number == 44 || number == 5 || number == 6 || number == 16 || number == 17 || number == 27 || number == 28 || number == 38 || number == 39 || number == 49){
                      bcl = "#28B84A";
                  }

                  if(number == 31 || number == 41 || number == 42 || number == 3 || number == 4 || number == 14 || number == 15 || number == 25 || number == 26 || number == 36 || number == 37 || number == 47 || number == 48 || number == 9 || number == 10 || number == 20){
                      bcl = "#0C9DE8";
                  }


                  let balls;
                  balls = <div key={i} style={headerStyles.ballbox}>
                      <div
                        style={{
                            width: 18,
                            height: 18,
                            borderRadius: 18,
                            overflow: "hidden",
                            textAlign: "center",
                            lineHeight: "18px",
                            marginLeft: 3,
                            color: "#fff",
                            backgroundColor: bcl,
                            fontSize: 10
                        }}>{number}</div>
                  </div>;
                  return balls;
              })
            );
        }
        //其他彩种
        else {
            return (
                is_number.split(',').map((number, i) => {
                    let balls;
                    balls = <div key={i} style={headerStyles.ballbox}>
                        <div
                            style={headerStyles.ball}>{number}</div>
                    </div>;
                    return balls;
                })
            );
        }
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.lottery_no !== this.lotteryInfo.lottery_no) {
            this._init_();
        }
        this.lotteryInfo = nextProps.lotteryInfo;
    };


    /**
     *封盘倒计时
     */
    _stopSecondCountdownEnd() {
        this.lotteryInfo.state = 1;
        this.lotteryInfo.open_second = this.lotteryInfo.open_second - this.lotteryInfo.stop_second;
        this.props.stopSecondCountdownEnd && this.props.stopSecondCountdownEnd(this.lotteryInfo);
        this.setState({refresh: !this.state.refresh});
    }

    /**
     *开奖倒计时
     */
    _openSecondCountdownEnd() {
        this.props.openSecondCountdownEnd && this.props.openSecondCountdownEnd();
    }


    render() {
        const styles = {
            lotteryTopBox: {
                position: "relative",
                height: 160,
            },
            diceImg: {
                width: 19,
                height: 19,
                marginLeft: 5,
            },
            wrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            timeVlaue: {
                color: "rgba(246, 48, 85, 0.94)",
                marginRight: 3,
                fontSize: 18
            },
            timeTips: {
                color: "rgba(0,0,0,0.5)",
                marginRight: 3
            },
            headerbg: {
                marginLeft: -model.clientWidth * 0.05,
                marginRight: -model.clientWidth * 0.05,
                height: 105,
                borderBottomRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                background: "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
            },
        };
        const headerStyles = {
            openBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 35,
                alignItems: "center",
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9",
                paddingLeft: 10,
                paddingRight: 10,
            },
            openNoBox: {
                display: "flex",
                flexDirection: "row"
            },
            openNo: {
                color: "#0a9dc7",
                marginRight: 3
            },
            openNumberBox: {
                display: "flex",
                flexDirection: "row"
            },
            symbol: {
                marginLeft: 3
            },
            timeBox: {
                position: "absolute",
                margin: "-95px auto 20px",
                left: 10,
                right: 10,
                paddingLeft: 15,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                backgroundColor: "#fff",
                boxShadow: "0 5px 10px rgba(0,0,0,0.08)",
                borderRadius: 7,
                zIndex: 2
                // marginTop: 36
            },
            timeTopWrapperBox: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center"
            },
            timeTopWrapperRightBox: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                paddingLeft: 20,
                paddingTop: 3,
                paddingBottom: 3,
            },
            timeTopBox: {
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
                fontSize: 14
            },
            timeTopLeftBox: {
                display: "flex",
                flexDirection: 'row'
            },
            timeTips: {
                color: "rgba(0,0,0,0.7)",
                marginRight: 3
            },
            timeTopRightBox: {},
            timeBottomBox: {
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
            },
            timeBottomLeftBox: {
                display: "flex",
                flexDirection: "row"
            },
            timeVlaue: {
                color: "rgba(246, 48, 85, 0.94)",
                marginRight: 3,
                fontSize: 14
            },
            timeBottomRightBox: {
                fontSize: 18,
                color: "rgba(246, 48, 85, 0.94)"
            },
            openListBox: {
                backgroundColor: "#fff"
            },
            recentOpenListBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                fontSize: 14,
                marginTop: 10
            },
            recentOpenFirstBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start"
            },
            recentOpenFirstTitle: {
                display: "flex",
                flexDirection: "row"
            },
            recentOpenFirstContent: {
                display: "flex",
                justifyContent: "center"
            },
            ballbox: {
                display: "flex",
                flexDirection: "row"
            },
            ball: {
                width: 18,
                height: 18,
                borderRadius: 18,
                overflow: "hidden",
                textAlign: "center",
                lineHeight: "18px",
                marginLeft: 5,
                color: "#fff",
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                fontSize: 10
            },
            pksBall: {
                height: 23,
                borderRadius: 2,
                color: "#fff",
                lineHeight: "23px",
                textAlign: "center",
                fontSize: 12,
                width: 16,
                marginLeft: 3
            },
            color1: {
                backgroundColor: "#fcf53c"
            },
            color2: {
                backgroundColor: "#4990e2"
            },
            color3: {
                backgroundColor: "#4a4a4a"
            },
            color4: {
                backgroundColor: "#f6a623"
            },
            color5: {
                backgroundColor: "#50b8e3"
            },
            color6: {
                backgroundColor: "#5d12fe"
            },
            color7: {
                backgroundColor: "#b4b3b3"
            },
            color8: {
                backgroundColor: "#d0011b"
            },
            color9: {
                backgroundColor: "#801111"
            },
            color10: {
                backgroundColor: "#7ed321"
            },
            recentOpenBox: {
                display: "flex",
                flexDirection: "column"
            },
            recentOpenItemBox: {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: 10
            },
            recentOpenTitle: {
                display: "flex",
                flexDirection: "row"
            },
            recentOpenContent: {
                display: "flex",
                justifyContent: "center"
            },
        };
        return (
            <div onClick={() => {
                this.setState({showList: !this.state.showList});
            }} style={styles.lotteryTopBox}>
                <div style={styles.headerbg}/>
                <div style={headerStyles.timeBox}>
                    <div style={headerStyles.timeTopWrapperBox}>
                        <Image style={{width: 70}} uri={require('../../../assets/images/lotteryjb.png')}/>
                        <div style={headerStyles.timeTopWrapperRightBox}>
                            <div style={headerStyles.timeTopBox}>
                                {
                                    this.lotteryInfo.state === 0 ?
                                        <div style={headerStyles.timeTopLeftBox}>
                                            <div style={headerStyles.timeTips}>距</div>
                                            <div style={headerStyles.openNo}>{this.lotteryInfo.lottery_no}</div>
                                            <div style={headerStyles.timeTips}>期截止还剩</div>
                                        </div> :
                                        <div style={headerStyles.timeTopLeftBox}>
                                            <div style={headerStyles.timeTips}>第</div>
                                            <div style={headerStyles.openNo}>{this.lotteryInfo.lottery_no}</div>
                                            <div style={headerStyles.timeTips}>期停止投注，距开奖</div>
                                        </div>
                                }
                            </div>
                            <div style={headerStyles.timeBottomBox}>

                                <div style={headerStyles.timeBottomLeftBox}>
                                    <Countdown
                                        // onSecond={(second) => {
                                        //     this.n_stop_second = second;
                                        // }}
                                        // updatedSecond={this.n_stop_second || this.props.lotteryInfo.stop_second}
                                        code={this.props.code}
                                        model={this.props.countDownModel}
                                        second={this.lotteryInfo.state === 0 ? this.lotteryInfo.stop_second : this.lotteryInfo.open_second}
                                        name={this.lotteryInfo.state === 0 ? 'stop_second' : 'open_second'}
                                        onEnd={() => {
                                            this.lotteryInfo.state === 0 ? this._stopSecondCountdownEnd() : this._openSecondCountdownEnd();
                                        }}/>
                                </div>
                                {/*{*/}
                                {/*this.lotteryInfo.state === 0 ?*/}
                                {/*<div style={headerStyles.timeBottomLeftBox}>*/}
                                {/*<Countdown*/}
                                {/*second={this.lotteryInfo.stop_second}*/}
                                {/*name={'stop_second'}*/}
                                {/*onEnd={() => {*/}
                                {/*this._stopSecondCountdownEnd();*/}
                                {/*}}/>*/}
                                {/*</div> :*/}
                                {/*<div style={headerStyles.timeBottomLeftBox}>*/}
                                {/*/!*<div style={headerStyles.timeTips}>已停止投注</div>*!/*/}

                                {/*<Countdown*/}
                                {/*second={this.lotteryInfo.open_second}*/}
                                {/*name={'open_second'}*/}
                                {/*onEnd={() => {*/}
                                {/*this._openSecondCountdownEnd();*/}
                                {/*}}/>*/}
                                {/*</div>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                    {
                        this.state.lotteryHistoryResult.firstResult.is_no ?
                            <div style={headerStyles.recentOpenListBox}>
                                <div style={headerStyles.recentOpenFirstBox}>
                                    <div style={headerStyles.recentOpenFirstTitle}>
                                        <div style={headerStyles.timeTips}>第</div>
                                        <div
                                            style={headerStyles.openNo}>{this.state.lotteryHistoryResult.firstResult.is_no && this.state.lotteryHistoryResult.firstResult.is_no}</div>
                                        <div style={headerStyles.timeTips}>期：</div>
                                    </div>
                                    <div style={headerStyles.recentOpenFirstContent}>
                                        {
                                            this.state.lotteryHistoryResult.firstResult.hasResult ? this._renderOpenNumber(this.state.lotteryHistoryResult.firstResult.is_number) : null
                                        }
                                        {
                                            this.props.templateType === "PCDD" && this.state.lotteryHistoryResult.firstResult.hasResult ?
                                                <div
                                                    style={headerStyles.ball}>{this._total(this.state.lotteryHistoryResult.firstResult.is_number)}</div> : null
                                        }
                                        {
                                            !this.state.lotteryHistoryResult.firstResult.hasResult ?
                                                <div>
                                                    {this.state.lotteryHistoryResult.firstResult.message}
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.showList ?
                                        <div style={headerStyles.recentOpenBox}>
                                            {
                                                this.state.lotteryHistoryResult.list && this.state.lotteryHistoryResult.list.map((item, i) => {
                                                    return (
                                                        <div style={headerStyles.recentOpenItemBox}>
                                                            <div style={headerStyles.recentOpenTitle}>
                                                                <div style={headerStyles.timeTips}>第</div>
                                                                <div
                                                                    style={headerStyles.openNo}>{item.is_no}</div>
                                                                <div style={headerStyles.timeTips}>期：</div>
                                                            </div>
                                                            <div style={headerStyles.recentOpenContent}>
                                                                {
                                                                    this._renderOpenNumber(item.is_number)
                                                                }
                                                                {
                                                                    this.props.templateType === "PCDD" ?
                                                                        <div
                                                                            style={headerStyles.ball}>{this._total(item.is_number)}</div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> : null
                                }
                            </div> : null
                    }
                </div>
            </div>
        )
    }
}
