/**
 * 体育开奖结果
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import MyPicker from "../component/myPicker";
import Icon from '../utils/icon'
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import Storage from "../services/storage";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import PullEndFooter from "../component/pullEndFooter";

export default class SportBetRecord extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.playTypeModel = [{label: '全部', value: 1}, {label: '过关', value: 2}];
        this.statusTypeModel = [{label: '全部', value: '9'}, {label: '未结算', value: '1'}, {
            label: '已结算',
            value: '2'
        }, {label: '已取消', value: '3'}];
        this.dateTypeModel = model.dateType;
        this.state = {
            playType: this.playTypeModel[0],
            statusType: this.statusTypeModel[0],
            dateType: this.dateTypeModel[0],
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            refreshing: false,//上拉刷新,
            total: 0,//总页数
            currpage: 1,//当前页
            list: [],
            showPlayTypeFliter: false,
            showDateTypeFliter: false,
            showStatusTypeFliter: false
        };
    }

    static navigationOptions = ({navigation}) => {
        return {}
    };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchList();
    };

    /**
     * 获取账变记录
     */
    _fetchList() {
        let parm = {
            play: this.state.playType.value,
            day: this.state.dateType.value,
            status: this.state.statusType.value,
            page: this.state.currpage
        };
        // if (this.state.currpage > 1) {
        //     this.setState({showFoot: 2});
        // } else {
        //     this.setState({refreshing: true});
        // }
        if (this.state.currpage === 1) {
            this.setState({list: []});
        }
        this.setState({showFoot: 2});
        Http.request("SportsGetOrder", parm, (res) => {
            this.setState({refreshing: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    if (this.state.currpage === 1) {
                        this.setState({list: res.data.list, total: res.data.total});
                    } else {
                        this.setState({list: this.state.list.concat(res.data.list), total: res.data.total})
                    }
                    if (this.state.currpage >= res.data.total) {
                        this.setState({showFoot: 1});
                    } else {
                        this.setState({showFoot: 2});
                    }
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    }

    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this.setState({currpage: 1}, () => {
            //获取数据
            this._fetchList();
        });
    };

    _onEndReached() {
        if (this.state.currpage === this.state.total) {
            this.setState({showFoot: 1});
            return;
        }
        this.setState({currpage: this.state.currpage + 1}, () => {
            //获取数据
            this._fetchList();
        });

    }


    /**
     * 体育图标
     * @private
     */
    _renderSportLogo = (name) => {
        let logo;
        switch (name) {
            case '足球':
                logo = <Icon icon={'zuqiu'} size={25} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '篮球':
                logo = <Icon icon={'lanqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '羽毛球':
                logo = <Icon icon={'yumaoqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '乒乓球':
                logo = <Icon icon={'pingpangqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '网球':
                logo = <Icon icon={'wangqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '排球':
                logo = <Icon icon={'paiqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '棒球':
                logo = <Icon icon={'bangqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '其他':
                logo = <Icon icon={'other'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
        }
        return logo;
    };

    /**
     * 查看投注详情
     * @param item
     */
    _showDetail(item) {
        const navParma = {
            betType: this.state.playType.label,
            matchInfo: item
        };
        PublicUtils.navigate("SportBetRecordDetail", navParma);
    }

    _renderBetcontent = (item) => {
        let result = item.bet_content;
        if (item.bet_content === item.match_hname) {
            result = "主赢";
        }
        if (item.bet_content === item.match_aname) {
            result = "客赢";
        }
        return result;
    };
    /**
     * 投注列表
     * */
    _renderItem = (item) => {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                borderBottom: '1px solid #f5f7f9',
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 50
            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                paddingTop: 10,
                paddingRight: 10,
                flexDirection: "row",
                alignItems: "stretch",
            },
            gq: {
                color: "#b0b0b0",
                fontSize: 12
            },
            iconBox: {
                width: 50,
                textAlign: "center",

            },
            listItemRight: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                paddingBottom: 10,
                justifyContent: "space-between",
                alignItems: "stretch",
                borderBottom: '1px solid #f5f7f9',
            },
            betDetailBox: {
                display: "flex",
                flexDirection: "column",
                flex: 3,
            },
            betAmountBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
                flex: 1,
            },
            betAmountText: {
                fontSize: 18,
                color: "rgba(0,0,0,1)",
            },
            betContentRow: {
                display: "flex",
                flexDirection: "row",
                marginBottom: 5
            },
            betMatchName: {
                color: "#000",
                fontSize: 14,
                flex: 1,
                width: 0,
            },
            betContentHeader: {
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 3,
            },
            betMatchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                padding: "3px 5px",
                color: "#000",
                textAlign: "center"
            },
            fontBlod: {
                padding: "0 5px",
                color: "#fff",
                fontSize: 16,
                backgroundColor: "rgba(245, 72, 68, 0.93)"
            },
            color4: {
                color: "#f55075"
            },
            color3: {
                color: "#37aef5"
            },
            betType: {},
            color1: {
                color: "#25a42a"
            },
            betTime: {
                color: "rgba(0,0,0,0.3)",
                fontSize: 13
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            },
            textSpace: {
                marginRight: 3
            },
            win: {backgroundColor: "#ff353b", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
            lose: {backgroundColor: "#1dc315", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
            notopen: {
                backgroundColor: "#1dc315",
                color: "#fff",
                padding: "3px 5px",
                borderRadius: 3,
                fontSize: 16
            },
            betMatchNameLable: {
                padding: "3px 5px",
                backgroundColor: "rgba(245, 72, 68, 0.93)",
                color: "#fff"
            }
        };
        if (this.state.playType.value === 1) {
            return (
                <div style={styles.listItemBox}>
                    <div style={styles.iconBox}>
                        {
                            this._renderSportLogo(item.type_name)
                        }
                        {
                            item.bet_type === 1 &&
                            <div style={styles.gq}>[滚球]</div>
                        }
                    </div>
                    <div style={styles.listItemRight}>
                        <div style={styles.betDetailBox}>
                            {
                                item.bet_type === 0 &&
                                <div style={{...styles.betContentRow, ...styles.betContentHeader}}>
                                    <div
                                        style={{
                                            ...styles.betMatchName, ...{
                                                flex: 1,
                                                flexDirection: "row",
                                                display: "flex"
                                            }
                                        }}>
                                        <div style={{...styles.betMatchNameText, ...{flex: 1}}}>{item.match_hname}</div>
                                        <div style={styles.betMatchNameLable}>主</div>
                                    </div>
                                    <div style={{...styles.fontBlod}}>vs</div>
                                    <div
                                        style={{
                                            ...styles.betMatchName, ...{
                                                flex: 1,
                                                flexDirection: "row",
                                                display: "flex"
                                            }
                                        }}>
                                        <div style={styles.betMatchNameLable}>客</div>
                                        <div style={{...styles.betMatchNameText, ...{flex: 1}}}>{item.match_aname}</div>
                                    </div>
                                </div>
                            }
                            {
                                item.bet_type === 1 &&
                                <div style={{...styles.betContentRow, ...styles.betContentHeader}}>
                                    {/*<Text*/}
                                    {/*style={[styles.color4, styles.textSpace]}>[{item.live_time}]</Text>*/}
                                    <div
                                        style={{
                                            ...styles.betMatchName, ...{
                                                flex: 1,
                                                flexDirection: "row",
                                                display: "flex"
                                            }
                                        }}>
                                        <div style={{...styles.betMatchNameText, ...{flex: 1}}}>{item.match_hname}</div>
                                        <div style={styles.betMatchNameLable}>主</div>
                                    </div>
                                    {/*<Text*/}
                                    {/*style={[styles.color3, styles.textSpace]}>{item.live_hbf}</Text>*/}
                                    <div style={{...styles.fontBlod}}>vs</div>
                                    {/*<Text*/}
                                    {/*style={[styles.color3, styles.textSpace]}>{item.live_abf}</Text>*/}
                                    <div
                                        style={{
                                            ...styles.betMatchName, ...{
                                                flex: 1,
                                                flexDirection: "row",
                                                display: "flex"
                                            }
                                        }}>
                                        <div style={styles.betMatchNameLable}>客</div>
                                        <div style={{...styles.betMatchNameText, ...{flex: 1}}}>{item.match_aname}</div>
                                    </div>
                                </div>
                            }
                            {
                                item.bet_type === 9 &&
                                <div style={{...styles.betContentRow, ...styles.betContentHeader}}>
                                    <div
                                        style={{...styles.betMatchName}}>
                                        <div style={styles.betMatchNameText}>{item.match_name}</div>
                                    </div>
                                </div>
                            }
                            <div style={{...styles.betContentRow, ...styles.betType}}>
                                <div
                                    style={{...styles.color4, ...styles.textSpace}}>[{item.bet_name}]
                                </div>
                                <div
                                    style={{...styles.color1, ...styles.textSpace}}>{this._renderBetcontent(item)}<span
                                    style={{color: "#ff383a", marginLeft: 5}}>{item.bet_pk}</span></div>
                            </div>
                            <div style={{...styles.betContentRow, ...{marginBottom: 0}}}>
                                <div
                                    style={{...styles.textSpace, ...styles.betTime}}>{PublicUtils.timestampFormat(item.bet_time)}</div>
                            </div>
                        </div>
                        <div style={styles.betAmountBox}>
                            <div
                                style={{...styles.betAmountText, ...item.js_win !== "未结算" ? Number(item.js_win) >= 0 ? styles.win : styles.lose : styles.notopen}}>{item.js_win}</div>
                            <div
                                style={styles.betAmountText}>{PublicUtils.subStringNum(item.bet_money)}</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={styles.listItemBox}>
                    <div style={styles.listItemRight}>
                        <div style={{...styles.betDetailBox, ...{paddingLeft: 10}}}>
                            <div style={{...styles.betContentRow, ...styles.betContentHeader}}>
                                <div
                                    style={{...styles.betMatchName, ...{fontSize: 18}}}>
                                    <div style={styles.betMatchNameText}>{item.bet_count}串1</div>
                                </div>
                            </div>
                            <div style={{...styles.betContentRow, ...styles.betType}}>
                                <div
                                    style={{...styles.color1, ...styles.textSpace}}>@ {item.bet_odds}</div>
                            </div>
                            <div style={{...styles.betContentRow, ...{marginBottom: 0}}}>
                                <div
                                    style={{...styles.textSpace, ...styles.betTime}}>{PublicUtils.timestampFormat(item.bet_time)}</div>
                            </div>
                        </div>
                        <div style={styles.betAmountBox}>
                            <div
                                style={{...styles.betAmountText, ...item.js_win !== "未结算" ? Number(item.js_win) >= 0 ? styles.win : styles.lose : styles.notopen}}>{item.js_win}</div>
                            <div
                                style={styles.betAmountText}>{PublicUtils.subStringNum(item.bet_money)}</div>
                        </div>
                    </div>
                </div>
            )
        }
    };

    render() {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                borderBottom: '1px solid #f5f7f9',
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 50
            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                paddingTop: 10,
                paddingRight: 10,
                flexDirection: "row",
                alignItems: "stretch",
            },
            gq: {
                color: "#b0b0b0",
                fontSize: 12
            },
            iconBox: {
                width: 50,
                textAlign: "center",

            },
            listItemRight: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                paddingBottom: 10,
                justifyContent: "space-between",
                alignItems: "stretch",
                borderBottom: '1px solid #f5f7f9',
            },
            betDetailBox: {
                display: "flex",
                flexDirection: "column",
                flex: 3,
            },
            betAmountBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
                flex: 1,
            },
            betAmountText: {
                fontSize: 18,
                color: "rgba(0,0,0,1)",
            },
            betContentRow: {
                display: "flex",
                flexDirection: "row",
                marginBottom: 5
            },
            betMatchName: {
                color: "#000",
                fontSize: 14,
                flex: 1,
                width: 0,
            },
            betMatchNameText: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
            fontBlod: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 16,
            },
            color4: {
                color: "#f55075"
            },
            color3: {
                color: "#37aef5"
            },
            betType: {},
            color1: {
                color: "#25a42a"
            },
            betTime: {
                color: "rgba(0,0,0,0.3)",
                fontSize: 13
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            },
            textSpace: {
                marginRight: 3
            }
        };
        return (
            <Container title={'投注记录'} style={{backgroundColor: "#fff"}}>
                <div style={styles.headerToolbar}>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({showPlayTypeFliter: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.playType.label}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({showDateTypeFliter: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.dateType.label}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({showStatusTypeFliter: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.statusType.label}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                </div>

                <div style={{fontSize: 14}}>
                    {
                        this.state.list.map((item, key) => {
                            return (
                                <div key={key} onClick={() => {
                                    this._showDetail(item);
                                }}>
                                    {
                                        this._renderItem(item)
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <PullEndFooter list={this.state.list} showFoot={this.state.showFoot} onEndReached={() => {
                    this._onEndReached();
                }}/>
                <MyPicker
                    title={'交易类型'}
                    model={this.playTypeModel}
                    display={this.state.showPlayTypeFliter}
                    onHiden={() => {
                        this.setState({showPlayTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({playType: item.value[0].value, showPlayTypeFliter: false, currpage: 1}, () => {
                            this._fetchList();
                        });
                    }}
                />
                <MyPicker
                    title={'日期'}
                    model={this.dateTypeModel}
                    display={this.state.showDateTypeFliter}
                    onHiden={() => {
                        this.setState({showDateTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({dateType: item.value[0].value, showDateTypeFliter: false, currpage: 1}, () => {
                            this._fetchList();
                        });
                    }}
                />
                <MyPicker
                    title={'日期'}
                    model={this.statusTypeModel}
                    display={this.state.showStatusTypeFliter}
                    onHiden={() => {
                        this.setState({showStatusTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({statusType: item.value[0].value, showStatusTypeFliter: false, currpage: 1}, () => {
                            this._fetchList();
                        });
                    }}
                />
            </Container>
        )
    }
}