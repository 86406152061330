/**
 * 首页
 * @format
 * @flow
 */
import React from 'react';
import Default from "./template/default";
import Theme1 from "./template/theme1";
import global from "../../global";

export default class Home extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        // const aa="%89PNG%0D%0A%1A%0A%00%00%00%0DIHDR%00%00%00P%00%00%00%1A%08%03%00%00%00%17%0C%2B%95%00%00%00%90PLTE%F3%FB%FE-%05k%A1%9B%B0%A0%DA%A9%DA%C5%B8%AA%B0%C4%C0%B7%BD%C1%C4%BA%D8%98%B2%C5%B4%E1%99%AC%A2%BD%D0%AA%C1%BD%D9E%23%7D%5EB%8F%A8%9E%C6%DA%DC%EBwa%A2%90%80%B4t%8A%91XT%82%83%A4%99%91%BF%A1_b%86%99%8B%BBfo%8A%8A%85%ADL%2F%81kZ%97%9A%9A%B8%AE%95%A4%99%7D%9B%C4%AD%AEX5%7EmM%87%B2%9E%D2y%5C%A6%9F%88%C3%87%83%92B%1Dt%83e%91cA%836%0Fr%40%1AyoQ%9E%3D%18wfF%97R3%86%E36%B9%C9%00%00%00%09pHYs%00%00%0E%C4%00%00%0E%C4%01%95%2B%0E%1B%00%00%01%A9IDATH%89%AD%94%89r%C2+%10%86Ab%1C%95c%83%3D%ADG%ABc%EF%E3%FD%DF%AE%BBKJ%B0%24%1Ec%FEq%08%FA%2F%1F%BB%0BQ%88%D35%99%E0g%D2%7C%1F%8D%EAIY6%E39bZC%8C%BC%0B%80%CD%88%B8%C8%EB%07%D8%7B%86%A2%97%1Ev%00%99U%5E%02dT%D2D%A6%9D%0FD%0DI%0C%DB%3B%94%98f.m%A4%B1%0E%3Ax%F3%07%29%EDb%99%FD%5E%B2Z%97%18%0B%02t%E5%DB%91%C6j%01%F3E%87%DB%26%90%21%16%9Cku%97%A1f%DD%E6%A2%A4%94%D9%928k%A9%7B%C9.7Q%E7.%D12%A00%F0gJ%22%CE%F0i%E3%DA%21%E5O%19%BA%CA%90%E1%29r%5C%87%CB%40%FC%0F%ACB1%15%9A%1E%E0J%9E%A6%84X%D3%0FH%9DA%8C%B5%F7+%93%9C%D3I%F1%A1%91%9A%E6%9E%A7%C5J%038%B2%F0%EE%26%8D%CE%E4%2C%8D%9EV%CE%94%BA%BE%C5%A7u1%9E%D6%12%D8%B9%F5%DA%AD%1F%E1%25%EC%E7%3Bqt+%E8%3A%0E%03%A5%28%FD%AA%F1h%1FM%B7%8B%F5T%E8WN_%1F%E2a%16%AE%0A%0B%9CRH0%A9E%BF%0Aa%83%BF%2A%C4%1Bo%7C%98%17%1AEL%AB%14%12%EE%12%2B%ECS%A37E%B19%5Ep%28%D7%00Wus%8F%D34%BC%EE%98%E7%12%B6%5B%CC%F4%5D%EE%D7%90%8Bb%2B%BC%04%5C%1D%0E.%FD%2B%F0%CD%B9%5B%BD%FB%C0%F1%F3%2B%B4%B5K%60%B9%26%08%7D4%B4%BBNS%D4%FC%D2%19%7C%8D%18%FE%3D%DE%FD%EC%1F%5B7%B8%09%B2%B9%ED%0D%C4%C0%E30%D4%60+%AAx%19%B2%17%5E%88%E93%B9%D3i%EE%FE%02c%0D%13%D9%0E%E4C%A5%00%00%00%00IEND%AEB%60%82";
        // console.log(decodeURI(aa));
    }

    /**
     * 模板
     **/
    _renderTemplate = () => {
        if (global.THEME === "default") {
            return <Default {...this.props}/>
        } else {
            return <Theme1 {...this.props}/>
        }
    };

    render() {
        return this._renderTemplate()
    }
}
