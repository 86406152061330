/**
 * 账单
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import MyPicker from "../component/myPicker";
import model from "../services/model";
import Http from "../services/http";
import Storage from "../services/storage";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import PullEndFooter from "../component/pullEndFooter";
import style from '../utils/config.scss';
import Icon from '../utils/icon';
import DatePicker from "react-mobile-datepicker";

export default class AccountChange extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.changeTypeModel = [{label: '全部', value: ''}, {label: '在线支付', value: 101}, {
            label: '公司入款',
            value: 102
        }, {label: '赠送红利', value: 104}, {label: '下注返水', value: 105}, {
            label: '账户提款',
            value: 201
        }, {label: '额度转出', value: 301}, {label: '额度转入', value: 302}];
        this.dateTypeModel = model.dateType;
        this.state = {
            changeType: this.changeTypeModel[0],
            dateType: this.dateTypeModel[0],
            start: "",
            end: "",
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            refreshing: false,//上拉刷新,
            total: 0,//总页数
            currpage: 1,//当前页
            list: [],
            showChangeTypeFliter: false,
            showDateTypeFliter: false,
        };
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchMemberChangeList();
    };

    /**
     * 获取账变记录
     */
    _fetchMemberChangeList() {
        let parm = {
            is_type: this.state.changeType.value,
            // day: this.state.dateType.value,
            page: this.state.currpage
        };
        if (this.state.start && this.state.end) {
            parm.start = this.state.start;
            parm.end = this.state.end;
        } else {

            parm.day = this.state.dateType.value;
        }
        // if (this.state.currpage > 1) {
        //     this.setState({showFoot: 2});
        // } else {
        //     this.setState({refreshing: true});
        // }
        if (this.state.currpage === 1) {
            this.setState({list: []});
        }
        this.setState({showFoot: 2});
        Http.request("memberChange", parm, (res) => {
            this.setState({refreshing: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/accountchange'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    if (this.state.currpage === 1) {
                        this.setState({list: res.data.list, total: res.data.total});
                    } else {
                        this.setState({list: this.state.list.concat(res.data.list), total: res.data.total})
                    }
                    if (this.state.currpage >= res.data.total) {
                        this.setState({showFoot: 1});
                    } else {
                        this.setState({showFoot: 2});
                    }
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    }

    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this.setState({currpage: 1}, () => {
            //获取数据
            this._fetchMemberChangeList();
        });
    };

    _onEndReached() {
        if (this.state.currpage === this.state.total) {
            this.setState({showFoot: 1});
            return;
        }
        this.setState({currpage: this.state.currpage + 1}, () => {
            //获取数据
            this._fetchMemberChangeList();
        });

    }

    /**
     * 状态
     * @param status
     * @private
     */
    _renderStatusText = (status) => {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 50,

            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                padding: 10,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "column",
                justifyContent: "space-between",
                borderBottom: '1px solid #f5f7f9',
            },
            listItemTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end"
            },
            listItemTopLeftText: {
                fontSize: 16,
                color: "#000"
            },
            listItemTopRightText: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 18
            },
            listItemBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: 5
            },
            listItemBottomLeftText: {
                fontSize: 12,
                color: "#b0b0b0"
            },
            listItemBottomRightText: {
                fontSize: 14,
                color: "#b0b0b0"
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                margin: "10px",
                borderRadius: 6
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            }

        };
        let viewContainer = null;
        switch (status) {
            case 1:
                viewContainer = <div style={styles.listItemBottomRightText}>待审</div>;
                break;
            case 2:
                viewContainer = <div style={styles.listItemBottomRightText}>成功</div>;
                break;
            case 3:
                viewContainer = <div style={styles.listItemBottomRightText}>失败</div>;
                break;
            case 4:
                viewContainer = <div style={styles.listItemBottomRightText}>处理中</div>;
                break;
        }
        return viewContainer;
    };

    /**
     * 页脚
     * @returns {*}
     * @private
     */
    _renderFooter() {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 50,

            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                padding: 10,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "column",
                justifyContent: "space-between",
                borderBottom: '1px solid #f5f7f9',
            },
            listItemTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end"
            },
            listItemTopLeftText: {
                fontSize: 16,
                color: "#000"
            },
            listItemTopRightText: {
                color: "rgba(245, 72, 68, 0.93)",
                fontSize: 18
            },
            listItemBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: 5
            },
            listItemBottomLeftText: {
                fontSize: 12,
                color: "#b0b0b0"
            },
            listItemBottomRightText: {
                fontSize: 14,
                color: "#b0b0b0"
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                margin: "10px",
                borderRadius: 6
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            }

        };
        let renderView = null;
        if (this.state.showFoot === 1) {
            if (!this.state.list.length) {
                renderView = (
                    <NoneData/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody}>
                            <div style={styles.footerBodyText}>
                                没有更多数据了
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.state.showFoot === 2) {
            if (!this.state.list.length) {
                renderView = (
                    <Loadding/>
                );
            } else {
                renderView = (
                    <div style={styles.footer}>
                        <div style={styles.footerBody} onClick={() => {
                            this._onEndReached();
                        }}>
                            <div style={styles.footerBodyText}>
                                点击加载更多
                            </div>
                        </div>
                        <div style={styles.footerPadding}/>
                    </div>
                );
            }
        } else if (this.state.showFoot === 0) {
            renderView = (
                <div style={styles.footer}>
                    {/*<View style={styles.footerBody}>*/}
                    {/*<Text style={styles.footerBodyText}>*/}
                    {/*努力加载中...*/}
                    {/*</Text>*/}
                    {/*</View>*/}
                    <div style={styles.footerPadding}/>
                </div>
            );
        }
        return renderView;
    }

    /**
     * 查看投注详情
     * @param item
     */
    _showDetail(item) {
        const navParma = {
            info: item
        };
        PublicUtils.navigate("AccountChangeDetail", navParma);
    }

    render() {
        const styles = {
            headerToolbar: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f5f7f9"
            },
            headerToolbarItemBtn: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 50,
                // backgroundColor: "#000"

            },
            headerToolbarItem: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            headerToolbarItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)"
            },
            listItemBox: {
                display: "flex",
                padding: 10,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "column",
                justifyContent: "space-between",
                borderBottom: '1px solid #f5f7f9',
            },
            listItemTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end"
            },
            listItemTopLeftText: {
                fontSize: 16,
                color: "#000"
            },
            listItemTopRightText: {
                color: "#000",
                fontSize: 18
            },
            listItemBottomBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: 5
            },
            listItemBottomLeftText: {
                fontSize: 12,
                color: "#b0b0b0"
            },
            listItemBottomRightText: {
                fontSize: 14,
                color: "#b0b0b0"
            },
            footer: {
                display: "flex",
                flexDirection: "column"
            },
            footerBody: {
                display: "flex",
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                margin: "10px",
                borderRadius: 6
            },
            footerBodyText: {
                fontSize: 14,
                color: "#bbbbbb"
            },
            footerPadding: {
                height: 50
            }

        };
        return (
            <Container title={'账单'} style={{backgroundColor: "#fff"}}>
                <div style={styles.headerToolbar}>
                    <div style={styles.headerToolbarItemBtn} onClick={() => {
                        this.setState({showChangeTypeFliter: true})
                        // this.setState({startTimeSwitch: true})
                    }}>
                        <div style={styles.headerToolbarItem}>
                            <div style={styles.headerToolbarItemText}>{this.state.changeType.label}</div>
                            <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                        </div>
                    </div>
                    {
                        this.state.dateType.label ?

                            <div style={styles.headerToolbarItemBtn} onClick={() => {
                                this.setState({showDateTypeFliter: true})
                            }}>
                                <div style={styles.headerToolbarItem}>
                                    <div style={styles.headerToolbarItemText}>{this.state.dateType.label}</div>
                                    <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                                </div>
                            </div> : null
                    }
                </div>
                {
                    this.state.dateType.start && this.state.dateType.end ?

                        <div style={styles.headerToolbar}>
                            <div style={styles.headerToolbarItemBtn} onClick={() => {
                                this.setState({showDateTypeFliter: true})
                                // this.setState({startTimeSwitch: true})
                            }}>
                                <div style={{
                                    ...styles.headerToolbarItem, ...{
                                        flex: 1
                                        // justifyContent: "space-between",
                                    }
                                }}>

                                    <div
                                        style={{
                                            ...styles.headerToolbarItemText, ...{
                                                display: "flex",
                                                flex: 1,
                                                textAlign: "center",
                                                justifyContent: "center"
                                            }
                                        }}>{this.state.dateType.start}</div>
                                    <div style={{width: 30, textAlign: "center"}}>至</div>
                                    <div
                                        style={{
                                            ...styles.headerToolbarItemText, ...{
                                                display: "flex",
                                                flex: 1,
                                                textAlign: "center",
                                                justifyContent: "center"
                                            }
                                        }}>{this.state.dateType.end}</div>
                                    <div style={{width: 15, textAlign: "center"}}>
                                        <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                                    </div>

                                    {/*<div style={styles.headerToolbarItemText}>{this.state.changeType.label}</div>*/}
                                    {/*<Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>*/}
                                </div>
                            </div>
                        </div> : null
                }


                <div>
                    {
                        this.state.list.map((item, key) => {
                            return (
                                <div key={key} style={styles.listItemBox} onClick={() => {
                                    this._showDetail(item);
                                }}>
                                    <div style={styles.listItemTopBox}>
                                        <div style={styles.listItemTopLeftText}>{item.is_type}</div>
                                        <div
                                            style={styles.listItemTopRightText}>{PublicUtils.subStringNum(item.is_amount)}</div>
                                    </div>
                                    <div style={styles.listItemBottomBox}>
                                        <div
                                            style={styles.listItemBottomLeftText}>{item.add_time} </div>
                                        {
                                            this._renderStatusText(item.is_status)
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    <PullEndFooter list={this.state.list} showFoot={this.state.showFoot} onEndReached={() => {
                        this._onEndReached()
                    }}/>
                </div>
                <MyPicker
                    title={'交易类型'}
                    model={this.changeTypeModel}
                    display={this.state.showChangeTypeFliter}
                    onHiden={() => {
                        this.setState({showChangeTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        // this.setState({changeType: item, showChangeTypeFliter: false, currpage: 1}, () => {
                        //     this._fetchMemberChangeList();
                        // });
                        this.setState({changeType: item.value[0].value, currpage: 1, list: []}, () => {
                            this._fetchMemberChangeList();
                        });
                    }}
                />
                <MyPicker
                    hasDatetimePicker={true}
                    title={'日期'}
                    model={this.dateTypeModel}
                    display={this.state.showDateTypeFliter}
                    onHiden={() => {
                        this.setState({showDateTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        // this.setState({dateType: item, showDateTypeFliter: false, currpage: 1}, () => {
                        //     this._fetchMemberChangeList();
                        // });
                        console.log(item);
                        this.setState({
                            dateType: (item.dateTime.start && item.dateTime.end) ? {
                                start: item.dateTime.start,
                                end: item.dateTime.end
                            } : item.value.length ? item.value[0].value : "",
                            currpage: 1,
                            start: item.dateTime.start,
                            end: item.dateTime.end,
                            list: []
                        }, () => {
                            this._fetchMemberChangeList();
                        });
                    }}
                />
                {/*<DatePicker*/}
                {/*    theme={'ios'}*/}
                {/*    value={""}*/}
                {/*    isOpen={this.state.endTimeSwitch}*/}
                {/*    onSelect={this.handleSelect}*/}
                {/*    onCancel={this.handleCancel}/>*/}
            </Container>
        )
    }
}
