import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import history from '../services/history'
import style from '../utils/config.scss'
import Container from '../component/container'
import MyButton from '../component/myButton'
import MyInput from '../component/myInput'
import Icon from '../utils/icon'
import Http from "../services/http";
import PublicUtils from '../utils/publicUtils'
import global from '../global'
import model from "../services/model";
import Storage from "../services/storage";
import MyPicker from '../component/myPicker'

class FindPasswordAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            safeQuestionFliter: false,
            showProgress: false,
            acount: PublicUtils.getState(this.props.location.pathname).username,
            user_name: null,
            pay_num: null,
            user_question: null,
            user_answer: null
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    /**
     * 客服
     * @private
     */
    _services = () => {
        // PublicUtils.navigate('GameRoom', {url: this.serviceUrl, title: "客服", parma: null,});
        const serviceUrl=Storage.retrieveData("serviceUrl");
        PublicUtils.linking(serviceUrl,"_blank");
    };
    /**
     * 提交用户名
     * @private
     */
    _submit = () => {
        if (!this.state.acount) {
            PublicUtils.showToast("请输入您的用户名");
            return;
        }
        if (!this.state.user_name) {
            PublicUtils.showToast("请输入您的真实姓名");
            return;
        }
        // if (!this.state.pay_num) {
        //     PublicUtils.showToast("请输入您的银行卡号");
        //     return;
        // }
        if (!this.state.user_question) {
            PublicUtils.showToast("请选择注册时选择的密保问题");
            return;
        }
        if (!this.state.user_answer) {
            PublicUtils.showToast("请输入注册时填写的密保答案");
            return;
        }
        this.setState({showProgress: true});
        const parm = {
            type: 2,
            user_account: this.state.acount,
            user_name: this.state.user_name,
            pay_num: this.state.pay_num,
            user_question: this.state.user_question,
            user_answer: this.state.user_answer,
        };
        Http.request("mobileChange", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    PublicUtils.navigate("FindPasswordNewpassword", {uid: res.data.uid});
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };

    render() {
        return (
            <Container title={'找回密码'}>
                <div style={styles.container}>
                    <div style={styles.Box}>
                        <div style={styles.iptLabel}>用户名：</div>
                        <div style={styles.iptBox}>
                            <MyInput readOnly={true} value={this.state.acount} placeholder={'请输入您的用户名'}
                                     onChangeText={(text) => {
                                         this.setState({acount: text});
                                     }}/>
                        </div>
                    </div>
                    <div style={styles.Box}>
                        <div style={styles.iptLabel}>真实姓名：</div>
                        <div style={styles.iptBox}>
                            <MyInput placeholder={'请输入您的真实姓名'} onChangeText={(text) => {
                                this.setState({user_name: text});
                            }}/>
                        </div>
                    </div>
                    <div style={styles.Box}>
                        <div style={styles.iptLabel}>银行卡号：</div>
                        <div style={styles.iptBox}>
                            <MyInput placeholder={'请输入您的银行卡号'} onChangeText={(text) => {
                                this.setState({pay_num: text});
                            }}/>
                        </div>
                    </div>
                    <div style={styles.Box}>
                        <div style={styles.iptLabel}>密保问题：</div>
                        <div style={styles.iptBox} onClick={() => {
                            this.setState({safeQuestionFliter: true});
                        }}>
                            <MyInput readOnly={true}
                                     value={this.state.user_question || ""}
                                     placeholder={'请选择密保问题'}/>
                            <Icon icon={'sjjt'} color={'#333'} size={16}/>
                        </div>
                    </div>
                    <div style={styles.Box}>
                        <div style={styles.iptLabel}>密保答案：</div>
                        <div style={styles.iptBox}>
                            <MyInput placeholder={'请输入您的密保答案'} onChangeText={(text) => {
                                this.setState({user_answer: text});
                            }}/>
                        </div>
                    </div>
                    <div style={styles.servicesBox}>
                        <div>忘记答案：</div>
                        <div style={styles.iptBox}>
                            <div style={styles.services} onClick={() => {
                                this._services();
                            }}>联系客服
                            </div>
                        </div>
                    </div>
                    <MyButton disabled={this.state.showProgress} showProgress={this.state.showProgress} title={'提交'}
                              onPress={() => {
                                  this._submit();
                              }}/>
                </div>
                <MyPicker rowCount={1}
                          title={'密保问题'}
                          model={model.safeQuestionOption}
                          display={this.state.safeQuestionFliter}
                          onHiden={() => {
                              this.setState({safeQuestionFliter: false})
                          }}
                          onItemPress={(item) => {
                              this.setState({safeQuestionFliter: false, user_question: item.value[0].value.label});
                              // this._changePlatform(item.value);
                              // this.setState({playType: item, showPlayTypeFliter: false, currpage: 1}, () => {
                              //     this._fetchList();
                              // });
                          }}
                />
            </Container>
        )
    }
}

const styles = {
    container: {
        padding: 10
    },
    Box: {
        display: "flex",
        flexDirection: "row",
        height: 50,
        alignItems: "center",
        fontSize: 14,
        border: "1px solid #f5f7f9",
        marginBottom: 10,
        marginTop: 10,
        backgroundColor: "#fff",
        padding: "0 10px",
        borderRadius: 3
    },
    iptLabel: {
        display: "flex"
    },
    iptBox: {
        display: "flex",
        flex: 1
    },
    servicesBox: {
        fontSize: 14,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 10,
        marginTop: 10,
        marginBottom: 20
    },
    services: {
        padding: "10px 20px",
        backgroundColor: "#dd8900",
        color: "#fff",
        borderRadius: 3
    }
};
export default withRouter(FindPasswordAuthentication);
