// 签到
import React, {Component} from 'react';
import Container from "../component/container";
import Image from "../component/image";
import Http from "../services/http";
import Storage from "../services/storage";
import PublicUtils from "../utils/publicUtils";
import model from "../services/model";
import global from '../global'




class Qiandao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qdObj:
        {"money":["1"],"points":["1"]},// 签到信息列表
      qdzt:0, // 签到状态
      qdt:1, // 签到类型
      qdt2:['1'], // 签到类型天数
      qdts:0, // 用户签到天数
      qdts2:0, // 用户签到天数，用于计算已签到使用
      qdwx:0, // 是否无限签到
      qdgz:'',// 签到规则
    }
    // 初始化签到信息
    this.getQianDao();
  }

  // 点击签到
  djqd(){


    //
    let tempThis = this;


    let parm = {};
    Http.request("djqd", parm, (res) => {
      if (res.code === 300) {
        Storage.clearData();
        PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
      } else {
        if (parseInt(res.code) === 200) {

          if(res.data.status){
            PublicUtils.showToast('明日再来！', {type: "FAILD"});

            // 重置
            tempThis.getQianDao();
          }else{
            PublicUtils.showToast(res.message, {type: "FAILD"});
          }


        } else {
          PublicUtils.showToast(res.message, {type: "FAILD"});
        }
      }
    });
  }

  // 获取 年-月-日
  ymdfn(){
    // 获取当前日期
    let date = new Date();

    //
    let nowMonth = date.getMonth() + 1;

    //
    let strDate = date.getDate();

    //
    let seperator = "-";

    //
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = "0" + nowMonth;
    }

    //
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }

    //
    return date.getFullYear() + seperator + nowMonth + seperator + strDate;
  }


  // 转换年月日为数值 接口内部数据使用
  ymdfn2(istime){

    var dateBegin = new Date(istime);//将-转化为/，使用new Date
    var dateEnd = new Date();//获取当前时间
    var dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
    var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数


    //
    return dayDiff;
  }


  // 转换年月日为数值 接口内部数据使用
  ymdfn3(istime){

    var dateBegin = new Date(istime).getMonth() + 1;//将-转化为/，使用new Date
    var dateEnd = new Date().getMonth() + 1;//获取当前时间


    //
    return dateEnd - dateBegin;
  }


  // 获取天数需要在得到次数之后
  isqddata(){

    let tempThis = this;

    let parm = {};
    Http.request("qdxx", parm, (res) => {
      if (res.code === 300) {
        Storage.clearData();
        PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
      } else {


        if (parseInt(res.code) === 200) {




          if (res.data.data.list.length>0){


            // 连续签到处理
            // if(res.data.data.series && res.data.data.list[0].sign_keep_days > 0){
            if(res.data.data.list[0].sign_keep_days > 0){



              //
              let riqi = tempThis.ymdfn();



              // 已签天数
              let yqts = res.data.data.list[0].sign_keep_days;




              // 无限签到
              let qdwx = 0;
              if(tempThis.state.qdt == 1 || tempThis.state.qdt == 2){


                // 相差天数
                let xcts = this.ymdfn2(res.data.data.list[0].sign_date);








                if(res.data.data.list[0].sign_keep_days>=tempThis.state.qdt2.length){








                  if(xcts  == 0){
                    yqts  = res.data.data.list[0].sign_keep_days %tempThis.state.qdt2.length;
                    if(!yqts){
                      yqts = tempThis.state.qdt2.length;
                    }
                  }else {
                    yqts  = res.data.data.list[0].sign_keep_days %tempThis.state.qdt2.length;

                  }

                  qdwx = 1;

                }

              }



              // 非无限签到可断点

              let yf = this.ymdfn3(res.data.data.list[0].sign_date)

              if(tempThis.state.qdt == 3 || tempThis.state.qdt == 4){


                if(yf  == 0){

                }else {
                  yqts  = 0

                }

              }



              // 当天的签到状态 0 未签 1已签
              if(res.data.data.list[0].sign_date == riqi){



                tempThis.setState({qdzt:1,qdts:yqts,qdts2:yqts,qdwx:qdwx});
              }else{
                tempThis.setState({qdzt:0,qdts:yqts,qdts2:yqts,qdwx:qdwx});
              }
            }else{

              tempThis.setState({qdts:0,qdzt:0,qdts2:0, qdwx:0});
            }



          }else{
            //
            tempThis.setState({qdts:0,qdzt:0,qdts2:0, qdwx:0});
          }



        } else {
          PublicUtils.showToast("获取签到用户信息失败", {type: "FAILD"});
        }
      }
    });


  }

  // 获取签到信息
  getQianDao(){


    //
    let tempThis = this;

    let parm = {};
    Http.request("qdxq", parm, (res) => {
      if (res.code === 300) {
        Storage.clearData();
        PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
      } else {

        if (parseInt(res.code) === 200) {

          let tempts = 0;
          let tempArr = [];
          let qdgz = '';

          if(res.data.data.type == 1 || res.data.data.type == 3){
            tempts = 7;

            if(res.data.data.type == 3){
              qdgz = '每周累计签到次数，每周一重新开始累计';
            }else{
              qdgz = '7天连续签到，签到奖励为连续签到奖励，一但中断，则从第1天重新开始';
            }

          }else{
            tempts = 31;

            if(res.data.data.type == 4){
              qdgz = '每月累计签到次数，每月1号重新开始累计';
            }else{
              qdgz = '31天连续签到，签到奖励为连续签到奖励，一但中断，则从第1天重新开始';
            }

          }
          for (let i=0;i<tempts;i++){
            tempArr.push(i);
          }



          // 签到类型 1 7天天连续签到类型 2 31天连续签到类型 3 7次签到类型 4 31次签到类型
          tempThis.setState({qdt:res.data.data.type,qdObj:res.data.data.award,qdt2:tempArr,qdgz:qdgz});



            //
            tempThis.isqddata();

        }else{
          PublicUtils.showToast("获取签到详情失败", {type: "FAILD"});

        }


      }
    });

  }

  // 样式对象
  myst ={
    qdgz:{marginBottom:4, color:'white',fontSize: 18},
    qdgz2:{fontSize: 16,color:'white'},
    qdbt:{marginTop:20,marginBottom:10, paddingBottom:12,borderBottom:'2px solid #ffffff',textAlign:'center'},
    qdbt2:{ marginBottom:4,color: 'white', fontSize: 18},
    yqd:{color: '#f9f457',fontSize: 16},
    dqs2:{marginLeft:4,marginRight:4,fontSize: 18},
    div2:{ display: 'flex', flexWrap: 'wrap',marginTop: 10,marginBottom:10,padding:10,fontSize:14},
    qdl:{marginRight: '2%', marginBottom:'2%', width: '23%', overflow: 'hidden', borderRadius: 1,textAlign: 'center'},
    qdlb:{position: 'relative', height: 90, borderRadius: 2, backgroundColor: 'white'},
    qdlt:{paddingTop:4,paddingBottom:4,},
    qdlzt:{    display: 'flex', flexDirection: 'column',height: 62,justifyContent: 'center',alignItems: 'center'},
    qdImg:{width:20, position: 'absolute', bottom: 0,right: 0},
  }

  render() {
    return(  <Container title={'签到奖励'} style={{backgroundColor: "#e25d5d"}}>

      {
        this.state.qdts == 31 && this.state.qdt>2 || this.state.qdts == 7 && this.state.qdt>2?<div style={this.myst.qdbt}><div style={this.myst.qdbt2}><span>您已完成了所有签到活动</span></div></div>: this.state.qdzt?<div style={this.myst.qdbt}>
          <div style={this.myst.qdbt2}><span>您已完成了今天的签到活动</span></div>
          {
            global.ID == 'yy'?
                <div style={this.myst.yqd}><span>您已签到<span style={this.myst.dqs2}>{this.state.qdts}</span>天</span>，明天签到可再得{this.state.qdObj.money[this.state.qdts]?<span><span style={this.myst.dqs2}>{this.state.qdObj.money[this.state.qdts]}</span>元</span> : ''}{this.state.qdObj.points[this.state.qdts]?<span>与<span style={this.myst.dqs2}>{this.state.qdObj.points[this.state.qdts]}</span>积分</span>:''}</div> :
                <div style={this.myst.yqd}><span>您已签到<span style={this.myst.dqs2}>{this.state.qdts}</span>{this.state.qdt == 1 || this.state.qdt == 2?'天':'次'}</span>，明天签到可再得{this.state.qdObj.money[this.state.qdts]?<span><span style={this.myst.dqs2}>{this.state.qdObj.money[this.state.qdts]}</span>元</span> : ''}{this.state.qdObj.points[this.state.qdts]?<span>与<span style={this.myst.dqs2}>{this.state.qdObj.points[this.state.qdts]}</span>积分</span>:''}</div>
          }
        </div>:<div style={this.myst.qdbt}>
          <div style={this.myst.qdbt2}><span>您还未进行今天的签到活动</span></div>
        </div>
      }


     <div style={this.myst.div2}>

       {
         this.state.qdObj.money?this.state.qdt2.map((item,key)=>{

           this.state.qdts2--;




           return(
              <div style={this.myst.qdl} onClick={()=>{
                if(this.state.qdts == key && !this.state.qdzt){
                  this.djqd();
                }
              }}>
                <div style={this.myst.qdlb}>
                  <div style={{...this.myst.qdlt, backgroundColor:this.state.qdts2 + 1 > 0?'#41A161':'#cccccc',color:this.state.qdts + 1 > 0?'white':'#818181'}}>第{key+1}{global.ID == "yy"? "天":(this.state.qdt == 1 || this.state.qdt == 2?'天':'次')}</div>
                  <div style={this.myst.qdlzt}>
                    {
                      this.state.qdwx?<div>
                        {this.state.qdObj.money[this.state.qdt2.length - 1]?<div>{this.state.qdObj.money[this.state.qdt2.length - 1]}元</div>:''}
                        {this.state.qdObj.points[this.state.qdt2.length - 1]?<div>{this.state.qdObj.points[this.state.qdt2.length - 1]}积分</div>:''}
                      </div>:<div>
                        {this.state.qdObj.money[key]?<div>{this.state.qdObj.money[key]}元</div>:''}
                        {this.state.qdObj.points[key]?<div>{this.state.qdObj.points[key]}积分</div>:''}
                      </div>
                    }

                  </div>
                  {
                    this.state.qdts2 + 1 > 0?<Image style={{...this.myst.qdImg}} resizeMode='cover'
                                               uri={require('../assets/images/ok3.png')}/>:''
                  }
                </div>
              </div>
            )
         }):<div></div>
       }










     </div>

     <div style={{paddingLeft:15,paddingRight:15,paddingTop: 10,marginBottom:20,borderTop:'2px solid white'}}>
       <div style={this.myst.qdgz}>签到规则：</div>
       <div style={this.myst.qdgz2}><span style={{width:10,display:'inline-block'}}></span>{this.state.qdgz}</div>
     </div>
    </Container>)
  }
}


export default Qiandao;
