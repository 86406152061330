/**
 * 自定义图标
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class NoneData extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        msg: PropTypes.string
    };
    static defaultProps = {
        msg: "暂无数据"
    };

    render() {
        const styles = {
            noneDataBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 50,
            },
            noneDataImg: {
                width: "60%",
            },
            noneDataMsg: {
                display: "flex",
                flexDirection:"column",
                justifyContent: "center",
                alignItems: "center"
            },
            noneDataText: {
                textAlign: "center",
                fontSize: 18,
                color: "#c5cad2"
            }
        };
        return (
            <div style={styles.noneDataBox}>
                <div style={styles.noneDataMsg}>
                    <img style={styles.noneDataImg} src={require("../assets/images/nonedata.png")}/>
                    <div style={styles.noneDataText}>{this.props.msg}</div>
                </div>
            </div>
        )
    }
}
