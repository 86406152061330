/**
 * 倒计时
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";
import Http from "../../../services/http";
import PublicUtils from "../../../utils/publicUtils";
import Storage from "../../../services/storage";
import model from "../../../services/model";
import ActivityIndicator from "../../../component/activityIndicator";

export default class Footer extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            betStatus: 0
        };
    }

    static propTypes = {
        templateType: PropTypes.string,
        currentBetTemplate: PropTypes.object,
        currentBetNumber: PropTypes.object,
        total: PropTypes.object,
        code: PropTypes.number,
        lotteryInfo: PropTypes.object,
        betTemplate: PropTypes.array,
        clearBetInfoCallbck: PropTypes.func,
        betOnpress: PropTypes.func
    };
    static defaultProps = {
        total: {
            count: 0,
            amount: 0
        }
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.lotteryInfo) {
            const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
            let state = {betDisabled: !!nextProps.lotteryInfo.state};
            if (this.state.betStatus === 1) {
                state.balance = user.is_balance;
            }
            this.setState(state);
        }
    };


    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchBalance();
    };

    /**
     * 获取余额
     */
    _fetchBalance() {
        let parm = {action:'refresh'};
        Http.request("memberBalance", parm, (res) => {
            // alert(JSON.stringify(res));
            if (res.code === 200) {
                if (res.data && res.data.status) {
                    PublicUtils.updateUserInfo({is_balance: res.data.balance.balance});
                    this.setState({balance: res.data.balance.balance, betStatus: 1});
                }
            }
        });
    }

    /**
     * 清除投注确认
     * */
    _clearBetInfo = () => {
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要清除所有投注吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._clearingBetInfo();
                    }
                },
            ]
        });
    };
    /**
     * 清除投注
     * @private
     */
    _clearingBetInfo = () => {
        let betTemplate = this.props.betTemplate;
        for (let i = 0; i < betTemplate.length; i++) {
            betTemplate[i].count = 0;
            for (let l = 0; l < betTemplate[i].nums.length; l++) {
                betTemplate[i].nums[l][2] = 0;
                betTemplate[i].nums[l][3] = false;
            }
        }
        this.props.clearBetInfoCallbck && this.props.clearBetInfoCallbck(betTemplate);
        // this.setState({
        //     betAmount: 0,
        //     total: {count: 0, amount: 0}
        // });
    };

    /**
     * 确认投注
     * */
    _betting_check = () => {
        console.log(this.props);
        this.props.betOnpress && this.props.betOnpress();
        const currentBetTemplate = this.props.currentBetTemplate;
        const currentBetNumber = this.props.currentBetNumber;

        if (this.props.templateType === "LHC") {
            if (currentBetTemplate.name === "连码") {
                if (currentBetTemplate.secondSubPlayInfo.label === "普通") {
                    if (currentBetNumber.nums.length < currentBetTemplate.subPlayInfo.min || currentBetNumber.nums.length > currentBetTemplate.subPlayInfo.max) {
                        PublicUtils.showToast("请选择" + currentBetTemplate.subPlayInfo.min + "-" + currentBetTemplate.subPlayInfo.max + "个号码");
                        return;
                    }
                }
                if (currentBetTemplate.secondSubPlayInfo.label === "肖串尾数") {
                    if (!currentBetNumber.nums || !currentBetNumber.nums.zx || !currentBetNumber.nums.zx.length) {
                        PublicUtils.showToast("请选择主肖");
                        return;
                    }
                    if (!currentBetNumber.nums || !currentBetNumber.nums.ws || !currentBetNumber.nums.ws.length) {
                        PublicUtils.showToast("请选择拖尾");
                        return;
                    }
                }
            }
        } else {
            if (!this.props.total.count) {
                PublicUtils.showToast("请选择投注号码");
                return;
            }
        }
        if (!this.props.total.amount) {
            PublicUtils.showToast("请输入下注金额");
            return;
        }
        this._betting_submit();
        // PublicUtils.navigate("Order", this.props);

        // PublicUtils.alert({
        //     title: "温馨提示",
        //     msg: "确定要投注吗？",
        //     buttons: [
        //         {
        //             text: "取消", onPress: () => {
        //             }
        //         },
        //         {
        //             text: "是的", onPress: () => {
        //                 this._betting_submit();
        //             }
        //         },
        //     ]
        // });
    };
    /**
     * 投注
     * */
    _betting_submit = () => {
        this.setState({showProgress: true});
        const currentBetTemplate = this.props.currentBetTemplate;
        const currentBetNumber = this.props.currentBetNumber;
        const betTemplate = this.props.betTemplate;
        let betNumber = [];
        let parm = {
            currentBetTemplate: currentBetTemplate,
            currentBetNumber: currentBetNumber,
            is_code: this.props.code,
            is_name: this.props.lotteryInfo.is_name,
            templateType: this.props.templateType,
            total: this.props.total,
            is_no: this.props.lotteryInfo.lottery_no,
            ball: {},
        };
        //PC蛋蛋
        if (this.props.templateType === "PCDD") {
            let item = {};
            let bet_index = 0;
            for (let i = 0; i < betTemplate.length; i++) {
                let betNumberItem = {
                    name: "",
                    list: []
                };
                for (let l = 0; l < betTemplate[i].nums.length; l++) {
                    bet_index++;
                    item[bet_index] = betTemplate[i].nums[l][2] || "";
                    if (betTemplate[i].nums[l][2]) {
                        betNumberItem.name = betTemplate[i].name;
                        betNumberItem.list.push(betTemplate[i].nums[l]);
                    }
                }
                if (betNumberItem.name) {
                    betNumber.push(betNumberItem);
                }
            }
            parm.ball["1"] = item;
        }
        //六合彩
        else if (this.props.templateType === "LHC") {
            if (currentBetTemplate.subPlayInfo) {
                parm.bet_sub = currentBetTemplate.subPlayInfo.value;
            }
            parm.bet_method = 1;
            parm.is_play = currentBetTemplate.oddType;
            let item = {};
            let betNumberItem = {
                name: "",
                list: []
            };
            if (currentBetTemplate.name === "特码") {
                //B面
                if (currentBetTemplate.subPlayInfo.label === "B面") {
                    parm.is_odds = 2;
                }
                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    item[i + 1] = currentBetTemplate.nums[i][2] || "";
                    if (currentBetTemplate.nums[i][2]) {
                        betNumberItem.name = currentBetTemplate.name + " - " + currentBetTemplate.subPlayInfo.label;
                        betNumberItem.list.push(currentBetTemplate.nums[i]);
                    }
                }
                if (betNumberItem.name) {
                    betNumber.push(betNumberItem);
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "正一特" || currentBetTemplate.name === "正二特" || currentBetTemplate.name === "正三特"
                || currentBetTemplate.name === "正四特" || currentBetTemplate.name === "正五特" || currentBetTemplate.name === "正六特" || currentBetTemplate.name === "正码"
                || currentBetTemplate.name === "一肖"  || currentBetTemplate.name === "正肖" || currentBetTemplate.name === "尾数" || currentBetTemplate.name === "总和") {
                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    item[i + 1] = currentBetTemplate.nums[i][2] || "";
                    if (currentBetTemplate.nums[i][2]) {
                        betNumberItem.name = currentBetTemplate.name;
                        betNumberItem.list.push(currentBetTemplate.nums[i]);
                    }
                }
                if (betNumberItem.name) {
                    betNumber.push(betNumberItem);
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "特码生肖") {

                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    if (currentBetTemplate.nums[i][2]) {
                        item[i + 63] = currentBetTemplate.nums[i][2] || "";
                        if (currentBetTemplate.nums[i][2]) {
                            betNumberItem.name = currentBetTemplate.name;
                            betNumberItem.list.push(currentBetTemplate.nums[i]);
                        }
                    }
                }
                if (betNumberItem.name) {
                    betNumber.push(betNumberItem);
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "特码尾数") {

                for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                    if (currentBetTemplate.nums[i][2]) {
                        item[i + 75] = currentBetTemplate.nums[i][2] || "";
                        if (currentBetTemplate.nums[i][2]) {
                            betNumberItem.name = currentBetTemplate.name;
                            betNumberItem.list.push(currentBetTemplate.nums[i]);
                        }
                    }
                }
                if (betNumberItem.name) {
                    betNumber.push(betNumberItem);
                }
                parm.ball[currentBetTemplate.oddType] = item;
            }
            if (currentBetTemplate.name === "连码") {
                betNumberItem.name = currentBetTemplate.name + " [ " + currentBetTemplate.subPlayInfo.label + " - " + currentBetTemplate.secondSubPlayInfo.label + " ] ";
                parm.bet_sub = 0;
                switch (currentBetTemplate.subPlayInfo.label) {
                    case "四全中":
                        parm.bet_sub = 1;
                        break;
                    case "三全中":
                        parm.bet_sub = 2;
                        break;
                    case "三中二":
                        parm.bet_sub = 3;
                        break;
                    case "二全中":
                        parm.bet_sub = 5;
                        break;
                    case "二中特":
                        parm.bet_sub = 6;
                        break;
                    case "特串":
                        parm.bet_sub = 8;
                        break;
                }
                if (currentBetTemplate.secondSubPlayInfo.label === "普通") {
                    parm.bet_method = 1;
                    parm.bet_amount = this.props.total.amount / this.props.total.count;
                    for (let i = 0; i < currentBetNumber.nums.length; i++) {
                        for (let l = 0; l < currentBetTemplate.nums[0].nums.length; l++) {
                            if (currentBetTemplate.nums[0].nums[l][0] === currentBetNumber.nums[i][0]) {
                                item[l + 1] = this.props.total.amount || "";
                            }
                        }
                    }
                    betNumberItem.list = currentBetNumber.nums;
                    betNumber.push(betNumberItem);
                    parm.ball[currentBetTemplate.oddType] = item;
                }
                if (currentBetTemplate.secondSubPlayInfo.label === "肖串尾数") {
                    parm.bet_method = 4;
                    parm.zx = currentBetTemplate.nums[0].nums.indexOf(currentBetNumber.nums.zx[0]) + 1;
                    parm.tw = currentBetTemplate.nums[1].nums.indexOf(currentBetNumber.nums.ws[0]) + 1;
                    parm.bet_amount = this.props.total.amount / this.props.total.count;
                    betNumberItem.list = currentBetNumber.nums;
                    betNumber.push(betNumberItem);
                }
                if (currentBetTemplate.secondSubPlayInfo.label === "拖胆") {
                    parm.bet_method = 5;
                    parm.bet_amount = this.props.total.amount / this.props.total.count;
                    parm.dm = {};
                    parm.tm = {};
                    for (let i = 0; i < currentBetNumber.nums.dm.length; i++) {
                        parm.dm[currentBetNumber.nums.dm[i][2]] = currentBetNumber.nums.dm[i][2];
                    }
                    for (let i = 0; i < currentBetNumber.nums.tm.length; i++) {
                        parm.tm[currentBetNumber.nums.tm[i][2]] = currentBetNumber.nums.tm[i][2];
                    }
                    parm.dm = JSON.stringify(parm.dm);
                    parm.tm = JSON.stringify(parm.tm);
                    betNumberItem.list = currentBetNumber.nums;
                    betNumber.push(betNumberItem);
                }
                if (currentBetTemplate.secondSubPlayInfo.label === "生肖对碰") {
                    parm.bet_method = 2;
                    parm.bet_amount = this.props.total.amount / this.props.total.count;
                    parm.sx = {};
                    for (let i = 0; i < currentBetNumber.nums.length; i++) {
                        parm.sx[currentBetTemplate.nums[0].nums.indexOf(currentBetNumber.nums[i]) + 1] = currentBetTemplate.nums[0].nums.indexOf(currentBetNumber.nums[i]) + 1;
                    }
                    parm.sx = JSON.stringify(parm.sx);
                    betNumberItem.list = currentBetNumber.nums;
                    betNumber.push(betNumberItem);
                }
                if (currentBetTemplate.secondSubPlayInfo.label === "尾数对碰") {
                    parm.bet_method = 3;
                    parm.bet_amount = this.props.total.amount / this.props.total.count;
                    parm.ws = {};
                    for (let i = 0; i < currentBetNumber.nums.length; i++) {
                        parm.ws[currentBetTemplate.nums[0].nums.indexOf(currentBetNumber.nums[i]) + 1] = currentBetTemplate.nums[0].nums.indexOf(currentBetNumber.nums[i]) + 1;
                    }
                    parm.ws = JSON.stringify(parm.ws);
                    betNumberItem.list = currentBetNumber.nums;
                    betNumber.push(betNumberItem);
                }
            }
            if (currentBetTemplate.name === "合肖" || currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连" || currentBetTemplate.name === "全不中") {
                betNumberItem.name = currentBetTemplate.name;
                if (currentBetTemplate.subPlayInfo && currentBetTemplate.subPlayInfo.label) {
                    betNumberItem.name += " [ " + currentBetTemplate.subPlayInfo.label + " ] ";
                }
                if (currentBetTemplate.name === "合肖") {
                    parm.bet_amount = this.props.total.amount;
                } else {
                    parm.bet_amount = this.props.total.amount / this.props.total.count;
                }
                for (let i = 0; i < currentBetNumber.nums.length; i++) {
                    for (let l = 0; l < currentBetTemplate.nums.length; l++) {
                        if (currentBetTemplate.nums[l][0] === currentBetNumber.nums[i][0]) {
                            item[l + 1] = this.props.total.amount || "";
                            betNumberItem.list.push(currentBetTemplate.nums[l]);
                        }
                    }
                }
                // betNumberItem.list = currentBetNumber.nums;
                betNumber.push(betNumberItem);
                parm.ball[currentBetTemplate.oddType] = item;
            }
        }
        //其余彩种
        else {
            for (let i = 0; i < betTemplate.length; i++) {
                let item = {};
                let betNumberItem = {
                    name: "",
                    list: []
                };
                for (let l = 0; l < betTemplate[i].nums.length; l++) {
                    item[l + 1] = betTemplate[i].nums[l][2] || "";
                    if (betTemplate[i].nums[l][2]) {
                        betNumberItem.name = betTemplate[i].name;
                        betNumberItem.list.push(betTemplate[i].nums[l]);
                    }
                }
                if (betNumberItem.name) {
                    betNumber.push(betNumberItem);
                }
                parm.ball[i + 1] = item;
            }
        }
        parm.ball = JSON.stringify(parm.ball);
        parm.betNumber = betNumber;
        PublicUtils.navigate("Order", {parm: parm});
        // Http.request("betting", parm, async (res) => {
        //     this.setState({showProgress: false});
        //     if (res.code === 300) {
        //         Storage.clearData();
        //         PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: '/'});
        //     }
        //     if (res.code === 200) {
        //         if (res.data && res.data.status) {
        //             PublicUtils.showToast(res.data.message);
        //             const user = JSON.parse(Storage.retrieveData("user"));
        //             PublicUtils.updateUserInfo({is_balance: user.is_balance - this.props.total.amount});
        //             this._clearingBetInfo();
        //         } else if (res.data.status === 6) {
        //             PublicUtils.showToast(res.data.message);
        //             Storage.clearData();
        //             PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'HG'});
        //         } else {
        //             PublicUtils.showToast(res.data.message);
        //         }
        //     }
        // });
    };

    render() {
        const styles = {
            disabledBtn: {
                opacity: 0.6
            },
        };

        const footerStyles = {
            footerBox: {
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 55,
                alignItems: "center",
                borderTop: "1px solid #f5f7f9",
                zIndex: 10,
                backgroundColor: "#fff"
            },
            footerLeftBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginLeft: 10,
            },
            footerLeftTopBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            footerLeftBottomBox: {
                color: "rgba(0,0,0,0.5)",
                marginTop: 5,
                fontSize: 12
            },
            betv: {
                color: "#000",
                fontSize: 14,
                marginRight: 2
            },
            bett: {
                color: "#000",
                marginRight: 2,
                fontSize: 14
            },
            btnBox: {
                display: "flex",
                flexDirection: "row"
            },
            btn: {
                display: "flex",
                height: 35,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColorDeep' + model.SKINNAME],
                width: 85,
                fontSize: 14,
                borderRadius: 35,
                marginRight: 10
            },
            clearBetBtn: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            btndiv: {
                color: "#fff",
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activediv: {
                color: "#fff"
            },
        };
        return (
            <div style={footerStyles.footerBox}>
                <div style={footerStyles.footerLeftBox}>
                    <div style={footerStyles.footerLeftTopBox}>
                        <div style={footerStyles.bett}>共</div>
                        <div style={footerStyles.betv}>{this.props.total.count}</div>
                        <div style={footerStyles.bett}>注</div>
                        {/*<div style={footerStyles.bett}>￥</div>*/}
                        <div style={footerStyles.betv}>{PublicUtils.subStringNum(this.props.total.amount)}</div>
                        <div style={footerStyles.bett}>元</div>
                    </div>
                    {
                        this.state.balance ?
                            <div style={footerStyles.footerLeftBottomBox}>
                                余额（￥{PublicUtils.subStringNum(this.state.balance)}元）
                            </div> : null
                    }
                </div>
                <div style={footerStyles.btnBox}>
                    <div onClick={() => {
                        this._clearBetInfo();
                    }}>
                        <div
                            style={{...footerStyles.btn, ...footerStyles.clearBetBtn}}>
                            <Icon icon={"laji"} size={16} color={"#fff"}/>
                            <div style={{...footerStyles.btndiv, ...{marginLeft: 5}}}>清空</div>
                        </div>
                    </div>
                    <div onClick={() => {
                        !this.state.showProgress && !this.state.betDisabled && this._betting_check();
                    }}>
                        <div
                            style={{...footerStyles.btn, ...(this.state.showProgress || this.state.betDisabled) ? styles.disabledBtn : null}}>
                            {this.state.showProgress ? this._showProgress() : <div
                                style={{...footerStyles.btndiv}}>我的注单</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
