//包装器

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BottomTab from './bottomTab/index'
import Header from './header'
import {withRouter} from 'react-router-dom'
import style from '../utils/config.scss'
import model from "../services/model";

class Container extends React.Component {

    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        title: PropTypes.string,
        showBackButton: PropTypes.bool,
        showMenuButton: PropTypes.bool,
        showBottomTab: PropTypes.bool,
        menuArray: PropTypes.array,
        showHeader: PropTypes.bool,
        tabIndex: PropTypes.number,
        style: PropTypes.object,
        onPress: PropTypes.func,
        headerBackgroundColor: PropTypes.string,
        headerFontColor: PropTypes.string,
        headerPosition: PropTypes.string
    };
    static defaultProps = {
        showBackButton: true,
        showMenuButton: false,
        showBottomTab: false,
        showHeader: true,
        tabIndex: 0,
        headerPosition: "relative"
    };

    render() {

        const styles = {
            container: {
                display: "flex",
                flexDirection: "column",
                backgroundColor: style['backgrountColor' + model.SKINNAME],
            },
            headerWrapper: {
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                maxWidth: "640px",
                margin: "auto"
            },
            bodyWrapper: {
                position: "absolute",
                minHeight: model.clientHeight - model.headerHeight - model.bottomTabHeight,
                left: 0,
                right: 0,
                top: this.props.headerPosition === "relative" ? model.headerHeight : 0,
                paddingBottom: model.bottomTabHeight,
                backgroundColor: style['backgrountColor' + model.SKINNAME]
            },
            bodyWrapperNoHeader: {
                position: "absolute",
                minHeight: model.clientHeight - model.headerHeight,
                left: 0,
                right: 0,
                top: 0,
                paddingBottom: model.bottomTabHeight,
                backgroundColor: style['backgrountColor' + model.SKINNAME]
            },
            bodyWrapperNoBottomtab: {
                position: "absolute",
                minHeight: model.clientHeight - model.bottomTabHeight,
                left: 0,
                right: 0,
                top: this.props.headerPosition === "relative" ? model.headerHeight : 0,
                paddingBottom: 0,
                backgroundColor: style['backgrountColor' + model.SKINNAME]
            },
            bottomWrapper: {
                display: "flex",
                flexDirection: "column"
            },
        };
        return (
            <div style={styles.container} onClick={() => {
                this.props.onPress && this.props.onPress();
            }}>
                {
                    this.props.showHeader ?
                        <div style={styles.headerWrapper}>
                            <Header fontColor={this.props.headerFontColor}
                                    backgroundColor={this.props.headerBackgroundColor} title={this.props.title}
                                    showBackButton={this.props.showBackButton}
                                    menuArray={this.props.menuArray} showMenuButton={this.props.showMenuButton}/>
                        </div> : null
                }
                <div
                    style={{...this.props.showHeader ? this.props.showBottomTab ? styles.bodyWrapper : styles.bodyWrapperNoBottomtab : styles.bodyWrapperNoHeader, ...this.props.style}}
                    className={this.props.showHeader ? 'bodyPadding' : null}>
                    {this.props.children}
                </div>
                {
                    this.props.showBottomTab ?
                        <div style={styles.bottomWrapper}>
                            <BottomTab tabIndex={this.props.tabIndex}/>
                        </div> : null
                }
            </div>
        )
    }
}

export default withRouter(Container);
