//游戏搜索


import React, {Component} from 'react';
import Container from "../component/container";
import SearchBar from '../component/searchBar';
import PlatformCard from '../component/platformCard';
import GameCard from '../component/gameCard';
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Storage from "../services/storage";
import global from "../global";
import Icon from "../utils/icon";
import MyInput from "../component/myInput";
import gameJSON from "../services/gameJSON";

export default class SearchView extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);

        model.gameList = gameJSON;

        this.state = {

            stext:'', // 搜索内容
            searchResult: [{
                name: "平台",
                list: []
            },
                {
                    name: "彩票",
                    list: []
                }],
            text: '',
            autoFocus: true,
            targets: []
        };
    }

    static navigationOptions = {
        header: null,
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._loadTargets();
        // const targets = Storage.retrieveData('targets') || '';
        // targets && this.setState({targets: targets.split('#')});
    };
    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
        // Storage.storeData({'targets': this.state.targets.join('#')});
    };

    /**
     * 加载近期查询标签
     * @returns {Promise<void>}
     * @private
     */
    _loadTargets = () => {
        const targets = Storage.retrieveData('targets') || '';
        targets && this.setState({targets: targets.split('#')}, () => {
            for (let i = 0; i < targets.split('#').length; i++) {
                if (targets.split('#')[i]) {
                    this._onTargetPress(targets.split('#')[i]);
                    return;
                }
            }
        });
        // this._onTargetPress(targets.split('#')[0]);
    }
    /**
     * 保存查询标签
     */
    _saveTargets = () => {
        const targetArray = this.state.targets;
        for (let i = 0; i < targetArray.length; i++) {
            if (targetArray[i] === this.state.stext) {
                targetArray.splice(i, 1);
                targetArray.unshift(this.state.stext);
                this.setState({targets: targetArray});
                return;
            }
        }
        targetArray.unshift(this.state.stext);
        if (targetArray.length > 10) {
            targetArray.pop();
        }
        this.setState({targets: targetArray});
        Storage.storeData({'targets': this.state.targets.join('#')});
    };
    /**
     * 取消
     * @private
     */
    _onCancel = () => {
        PublicUtils.goBack(null);
    };
    /**
     * 搜索
     * @private
     */
    _onSearch = (text) => {
        this.setState({autoFocus: false});
        if (PublicUtils.rtrim((text))) {
            // 保存标签
            this._saveTargets();

            let searchResult = [
                {
                    name: "平台",
                    list: []
                },
                {
                    name: "彩票",
                    list: []
                }
            ];
            for (let i = 0; i < model.gameList.length; i++) {


                for (let l = 0; l < model.gameList[i].gamelist.length; l++) {

                    if (model.gameList[i].type_id !== 4 || !model.gameList[i].gamelist[l].gamelist) {
                        //
                        if (model.gameList[i].gamelist[l].labels.includes(text) || model.gameList[i].gamelist[l].name.includes(text)) {
                            searchResult[0].list.push(model.gameList[i].gamelist[l]);
                        }



                        // 垃圾代码？
                        // if (model.gameList[i].gamelist[l].gamelist) {
                        //     for (let y = 0; y < model.gameList[i].gamelist[l].gamelist.length; y++) {
                        //         if (model.gameList[i].gamelist[l].gamelist[y].name.includes(text)) {
                        //             let item = model.gameList[i].gamelist[l].gamelist[y];
                        //             item.img = global.RES_URL + "/new/template/" + global.GAME_IMG_SKIN + "/sublottery/" + item.icon;
                        //             searchResult[1].list.push(item);
                        //         }
                        //     }
                        // }

                    } else {



                        let games_result = {
                            name: model.gameList[i].gamelist[l].name,
                            list: []
                        };




                        for (let j = 0; j < model.gameList[i].gamelist[l].gamelist.length; j++) {
                            if (model.gameList[i].gamelist[l].gamelist[j].CN_Name.includes(text)) {
                                let item = model.gameList[i].gamelist[l].gamelist[j];
                                item.type = model.gameList[i].gamelist[l].type;
                                item.id = model.gameList[i].gamelist[l].game_api;
                                item.game_id = model.gameList[i].gamelist[l].game_id;
                                item.game_name = model.gameList[i].gamelist[l].name;
                                item.img = item.Button_Image_Name ? global.RES_URL + model.gameList[i].gamelist[l].game_list_img_path + item.Button_Image_Name : null;
                                games_result.list.push(item);
                            }
                        }


                        games_result.list.length && searchResult.push(games_result);
                    }
                }
            }
            // alert(JSON.stringify(searchResult));
            this.setState({searchResult: searchResult});
        }
    };
    /**
     * 获取标签
     * @returns {*}
     * @private
     */
    _getTargets = () => {
        const styles = {
            container: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#fff",
                alignItems: 'stretch',
            },
            searchBarWrapper: {
                display: "flex",
                flexDirection: "row",
                paddingTop: "20px"
            },
            searchBarBox: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingLeft: 10,
            },
            searchBarButtonBox: {
                display: "flex",
                width: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            searchBarButton: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                lineHeight: 35,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            searchBarButtonText: {
                textAlign: "center"
            },
            searchResultBox: {
                flexDirection: "column",
                display: "flex",
                marginTop: 20,
                flex: 1
            },
            labelWrapper: {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderTop: "1px solid #f5f5f5",
            },
            labelHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            labelHeaderText: {
                flexDirection: "column",
                flex: 1,
                fontSize: 12,
                color: "#999"
            },
            labelBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 5
            },
            labelBox: {
                display: "flex",
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid #f1f1f1",
                borderRadius: 30,
                marginRight: 10,
                marginTop: 5,
                marginBottom: 5,
            },
            labelName: {
                color: "#333"
            },
            searchResultWrapper: {
                display: "flex",
                borderTop: "1px solid #f5f5f5",
                flexDirection: "column"
            },
            searchTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 20
            },
            searchTipsText: {
                fontSize: 16
            },
            columnView: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                paddingLeft: 5,
                paddingRight: 5
            },
            gameHeader: {
                paddingLeft: 10,
                paddingTop:
                    5,
                paddingBottom:
                    5,
                backgroundColor:
                    "#f5f5f5"
            }
            ,
            gameHeaderText: {
                color: "#333"
            }
        };
        if (this.state.targets.length > 0) {
            return (<div style={styles.labelWrapper}>
                <div style={styles.labelHeader}>
                    <div style={styles.labelHeaderText}>近期搜索：</div>
                </div>
                <div style={styles.labelBody}>
                    {this.state.targets.map((item, key) => {
                        return item ?
                            <div key={key} style={styles.labelBox} onClick={() => {
                                this._onTargetPress(item);
                            }}>
                                <div style={styles.labelName}>{item}</div>
                            </div> : null
                    })}
                </div>
            </div>);
        } else {
            return null;
        }
    };
    /**
     * 点击标签
     * @private
     */
    _onTargetPress = (text) => {
        this._onSearch(text);
    };
    /**
     * 获取查询结果
     * @returns {*}
     * @private
     */
    _getResult = () => {
        const styles = {
            container: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#fff",
                alignItems: 'stretch',
            },
            searchBarWrapper: {
                display: "flex",
                flexDirection: "row",
                paddingTop: "20px"
            },
            searchBarBox: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingLeft: 10,
            },
            searchBarButtonBox: {
                display: "flex",
                width: 60,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            searchBarButton: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                lineHeight: 35,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            searchBarButtonText: {
                textAlign: "center"
            },
            searchResultBox: {
                flexDirection: "column",
                display: "flex",
                marginTop: 20,
                flex: 1
            },
            labelWrapper: {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderTop: "1px solid #f5f5f5",
            },
            labelHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            labelHeaderText: {
                flexDirection: "column",
                flex: 1,
                fontSize: 12,
                color: "#999"
            },
            labelBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 5
            },
            labelBox: {
                display: "flex",
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid #f1f1f1",
                borderRadius: 30,
                marginRight: 10,
                marginTop: 5,
                marginBottom: 5,
            },
            labelName: {
                color: "#333"
            },
            searchResultWrapper: {
                display: "flex",
                borderTop: "1px solid #f5f5f5",
                flexDirection: "column"
            },
            searchTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 20
            },
            searchTipsText: {
                fontSize: 16
            },
            columnView: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                paddingLeft: 5,
                paddingRight: 5
            },
            gameHeader: {
                paddingLeft: 10,
                paddingTop:
                    5,
                paddingBottom:
                    5,
                backgroundColor:
                    "#f5f5f5"
            }
            ,
            gameHeaderText: {
                color: "#333"
            }
        };
        if (this.state.searchResult[0].list.length || this.state.searchResult[1].length || this.state.searchResult.length > 2) {
            return (
                <div>
                    {
                        this.state.searchResult.length ? this.state.searchResult.map((platformItem) => {
                            return platformItem.list.length ?
                                <div>
                                    <div style={styles.gameHeader}>
                                        <div
                                            style={styles.gameHeaderText}>{platformItem.name}</div>
                                    </div>
                                    {

                                        platformItem.name === "平台" ? platformItem.list.length && platformItem.list.map((gameItem) => {
                                            return (<PlatformCard obj={gameItem} icon={gameItem.icon}
                                                                  name={gameItem.name}
                                                                  memo1={gameItem.memo1}
                                                                  memo2={gameItem.memo2}/>)
                                        }) : <div style={styles.columnView}>
                                            {
                                                platformItem.list.length && platformItem.list.map((gameItem) => {
                                                    return (<GameCard obj={gameItem} icon={gameItem.img}
                                                                      name={gameItem.CN_Name || gameItem.name}/>)
                                                })
                                            }
                                        </div>
                                    }
                                </div> : null
                        }) : null
                    }


                </div>
            )
        } else {
            return (<div style={styles.searchTips}>
                <div style={styles.searchTipsText}>无查询结果</div>
            </div>);
        }


        //
        // if (this.state.platforms_result.length > 0 || this.state.games_result.length > 0 || this.state.CQ9_game_list.length > 0 ||
        //     this.state.HB_game_list.length > 0 || this.state.MG_game_list.length > 0 || this.state.PG_game_list.length > 0 || this.state.SG_game_list.length > 0) {
        //     return (<div>
        //         {this.state.platforms_result.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>平台</div>
        //         </div>}
        //         {this.state.platforms_result.map((item,key) => {
        //             return <PlatformCard key={key} obj={item} icon={item.icon} name={item.name} memo1={item.memo1}
        //                                  memo2={item.memo2}/>
        //         })}
        //         {/*彩票*/}
        //         {this.state.games_result.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>彩票游戏</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.games_result.map((item,key) => {
        //                 let icon;
        //                 if (item.icon) {
        //                     icon = global.RES_URL + "/new/template/" + global.GAME_IMG_SKIN + "/sublottery/" + item.icon;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*PT*/}
        //         {this.state.PT_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>PT游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.PT_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.PT_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*HB*/}
        //         {this.state.HB_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>HB游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.HB_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.HB_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*SG*/}
        //         {this.state.SG_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>SG游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.SG_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.SG_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*MW*/}
        //         {this.state.MW_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>MW游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.MW_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.MW_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*CQ9*/}
        //         {this.state.CQ9_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>CQ9游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.CQ9_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.CQ9_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*VIP*/}
        //         {this.state.VIP_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>VIP游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.VIP_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.VIP_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*BBIN*/}
        //         {this.state.BBIN_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>BBIN游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.BBIN_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.BBIN_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>
        //                 )
        //             })}
        //         </div>
        //         {/*MG*/}
        //         {this.state.MG_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>MG游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.MG_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.MG_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>)
        //             })}
        //         </div>
        //         {/*GD*/}
        //         {this.state.GD_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>GD游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.GD_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.GD_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>)
        //             })}
        //         </div>
        //         {/*FG*/}
        //         {this.state.FG_game_list.length > 0 &&
        //         <div style={styles.gameHeader}>
        //             <div style={styles.gameHeaderText}>FG游戏厅</div>
        //         </div>}
        //         <div style={styles.columnView}>
        //             {this.state.FG_game_list.map((item,key) => {
        //                 let icon;
        //                 if (item.Button_Image_Name) {
        //                     icon = global.RES_URL + model.FG_game_list.game_list_img_path + item.Button_Image_Name;
        //                 }
        //                 return (
        //                     <GameCard key={key} width={((model.clientWidth - 50) / 4)} obj={item} icon={icon} name={item.CN_Name}/>)
        //             })}
        //         </div>
        //     </div>)
        //         ;
        // } else {
        //     return (<div style={styles.searchTips}>
        //         <div style={styles.searchTipsText}>无查询结果</div>
        //     </div>);
        // }
    };

    render() {

        const styles = {
            container: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#fff",
                alignItems: 'stretch',
            },
            searchBarWrapper: {
                display: "flex",
                flexDirection: "row",
                paddingTop: "20px"
            },
            searchBarBox: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingLeft: 10,
            },
            searchBarButtonBox: {
                display: "flex",
                width: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            searchBarButton: {
                flexDirection: "column",
                display: "flex",
                flex: 1,
                marginLeft: 5,
                marginRight: 5,
                height: 35,
                lineHeight: 35,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            searchBarButtonText: {
                textAlign: "center"
            },
            searchResultBox: {
                flexDirection: "column",
                display: "flex",
                marginTop: 20,
                flex: 1
            },
            labelWrapper: {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderTop: "1px solid #f5f5f5",
            },
            labelHeader: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            labelHeaderText: {
                flexDirection: "column",
                flex: 1,
                fontSize: 12,
                color: "#999"
            },
            labelBody: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 5
            },
            labelBox: {
                display: "flex",
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid #f1f1f1",
                borderRadius: 30,
                marginRight: 10,
                marginTop: 5,
                marginBottom: 5,
            },
            labelName: {
                color: "#333"
            },
            searchResultWrapper: {
                display: "flex",
                borderTop: "1px solid #f5f5f5",
                flexDirection: "column"
            },
            searchTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 20
            },
            searchTipsText: {
                fontSize: 16
            },
            columnView: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                paddingLeft: 5,
                paddingRight: 5
            },
            gameHeader: {
                paddingLeft: 10,
                paddingTop:
                    5,
                paddingBottom:
                    5,
                backgroundColor:
                    "#f5f5f5"
            }
            ,
            gameHeaderText: {
                color: "#333"
            }
        };
        return (
            <Container showHeader={false} style={{
                background: "#fff"
            }}>
                <div style={styles.container} className={'headPadding'}>
                    <div style={styles.searchBarWrapper}>
                        <div style={styles.searchBarBox}>
                            <div style={{
                                display: "flex",
                                backgroundColor: "#f1f1f1",
                                height: 35,
                                borderRadius: 3,
                                paddingLeft: 10,
                                paddingRight: 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} >
                                <div style={{    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: 'center',
                                    alignItems: 'center',width:'100%'}}>
                                    <Icon icon={'magnifier'} size={18} color={'#999'}/>

                                    {/*<MyInput style={{    display: "flex", flex: 1, height: 30}} placeholder="搜索游戏、平台"*/}
                                    {/*         onChangeText={(text) => {*/}
                                    {/*            this.state.stext = text;*/}
                                    {/*         }}*/}
                                    {/*/>*/}


                                    <input
                                      style={{
                                          width: '100%',
                                          height: 30,
                                          border: 'none',
                                          background: 'transparent',
                                          fontSize: 14,
                                          display:'flex',
                                      }}
                                      value={this.state.stext}

                                      onChange={(ev)=>{
                                          this.setState({stext:ev.target.value})
                                      }}
                                      type={'text'}
                                      placeholder={'搜索游戏、平台'}
                                      // onKeyDown={()=>{
                                      //      this.props.onKeyDown && this.props.onKeyDown(this.state.value)
                                      // }}
                                    />


                                </div>
                            </div>
                        </div>
                        <div style={styles.searchBarButtonBox}>
                            <div style={styles.searchBarButton} onClick={() => {

                                this._onSearch(this.state.stext);
                            }}>
                                <div style={{...styles.searchBarButtonText,color:'#191970'}}>搜索</div>
                            </div>
                        </div>
                        <div style={styles.searchBarButtonBox}>
                            <div style={styles.searchBarButton} onClick={() => {
                                this._onCancel();
                            }}>
                                <div style={{...styles.searchBarButtonText,color:'red'}}>取消</div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.searchResultBox}>
                        {this._getTargets()}

                        <div style={styles.searchResultWrapper}>
                            {this._getResult()}
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}
