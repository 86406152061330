/**
 * 倒计时
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";

export default class Countdown extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            second: 0,
            name: null,
            time: {day: "00", hour: "00", min: "00", second: "00"}
        };
    }

    static propTypes = {
        templateType: PropTypes.string,
        second: PropTypes.number,
        name: PropTypes.string,
        onEnd: PropTypes.func,
        model: PropTypes.string,
        code: PropTypes.number
    };
    static defaultProps = {
        second: 0,
        model: "normal"
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        // this.setState({second: this.props.second, name: this.props.name}, () => {
        //     this._countdown();
        // });
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.setState({second: this.props.second}, () => {
        //     this._countdown();
        // });
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
        clearInterval(this.timer);
    };
    componentWillReceiveProps = (nextProps) => {
        if ((this.name !== nextProps.name) && nextProps.second > 0) {
            this.second = nextProps.second;
            this.name = nextProps.name;
            this._countdown();
            // this.setState({second: nextProps.second, name: nextProps.name}, () => {
            //     this._countdown();
            // });
        }
    };
    /**
     * 倒计时
     * @private
     */
    _countdown = () => {
        this.timer && window.clearInterval(this.timer);
        this.timer = window.setInterval(() => {
            this.setState({time: this._timeFromt(this.second)}, () => {
                if (this.second > 0) {
                    this.second--;
                } else {
                    this.timer && window.clearInterval(this.timer);
                    this.props.onEnd && this.props.onEnd();
                }
            });
        }, 1000);
        // if (this.state.second > 0) {
        //     this.timer && window.clearInterval(this.timer);
        //     this.timer = window.setTimeout(() => {
        //         this.setState({second: this.state.second - 1, time: this._timeFromt(this.state.second)}, () => {
        //             this.props.onSecond && this.props.onSecond(this.state.second);
        //             this._countdown();
        //         });
        //     }, 1000);
        // } else {
        //     this.setState({time: this._timeFromt(this.state.second)}, () => {
        //         this.props.onEnd && this.props.onEnd();
        //     });
        //     // this.props.onEnd && this.props.onEnd();
        // }
    };

    /**
     * 将秒数转化为*天*时*分*秒
     * @param {number} second_time
     * @returns {any}
     */
    _timeFromt = (second_time) => {

        let day = 0, hour = 0, min = 0, second = 0;
        if (second_time > 60) {

            second = Math.floor(second_time % 60);
            min = Math.floor(second_time / 60);
            //time = min + "分" + second + "秒";

            if (min > 60) {
                min = Math.floor(Math.floor(second_time / 60) % 60);
                hour = Math.floor(Math.floor(second_time / 60) / 60);
                //time = hour + "小时" + min + "分" + second + "秒";

                if (this.props.model === "day") {
                    if (hour > 24) {
                        hour = Math.floor(Math.floor(Math.floor(second_time / 60) / 60) % 24);
                        day = Math.floor(Math.floor(Math.floor(second_time / 60) / 60) / 24);
                        //time = day + "天" + hour + "小时" + min + "分" + second + "秒";
                    }
                }
            }

        } else {
            second = second_time;
        }
        if (hour > 0 && hour < 10) {
            hour = "0" + hour;
        }
        if (min > 0 && min < 10) {
            min = "0" + min;
        }
        if (second > 0 && second < 10) {
            second = "0" + second;
        }
        return {day: day, hour: hour || "00", min: min || "00", second: second || "00"};
    };

    render() {
        const styles = {

            wrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            timeVlaue: {
                color: "#000",
                marginRight: 3,
                fontSize: 24
            },
            timeTips: {
                color: "rgba(0,0,0,1)",
                marginRight: 3,
                fontSize: 24
            },
        };
        return (
            this.props.model === "day" ?
                <div style={styles.wrapper}>
                    {
                        this.props.code !== 10011 ?
                            <div style={styles.wrapper}>
                                <div
                                    style={styles.timeVlaue}>{this.state.time.day}</div>

                                <div style={{...styles.timeTips, ...{fontSize: 16}}}>天</div>

                                <div
                                    style={styles.timeVlaue}>{this.state.time.hour}</div>

                                <div style={{...styles.timeTips, ...{fontSize: 16}}}>时</div>
                            </div>
                            : null
                    }
                    <div
                        style={styles.timeVlaue}>{this.state.time.min}</div>
                    <div style={{...styles.timeTips, ...{fontSize: 16}}}>分</div>
                    <div
                        style={styles.timeVlaue}>{this.state.time.second}</div>
                    <div style={{...styles.timeTips, ...{fontSize: 16}}}>秒</div>
                </div> :
                <div style={styles.wrapper}>
                    {/*<div*/}
                    {/*style={styles.timeVlaue}>{this.state.time.day}</div>*/}
                    {/*<div style={styles.timeTips}>:</div>*/}
                    <div
                        style={styles.timeVlaue}>{this.state.time.hour}</div>
                    <div style={styles.timeTips}>:</div>
                    <div
                        style={styles.timeVlaue}>{this.state.time.min}</div>
                    <div style={styles.timeTips}>:</div>
                    <div
                        style={styles.timeVlaue}>{this.state.time.second}</div>
                    {/*<div style={styles.timeTips}>秒</div>*/}
                </div>
        )
    }
}