/**
 * 彩票游戏导航
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../component/container";
import PublicUtils from "../../utils/publicUtils";
import model from "../../services/model";
import Http from "../../services/http";
import style from "../../utils/config.scss";
import Storage from "../../services/storage";
import ActivityIndicator from "../../component/activityIndicator";

export default class Order extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            parm: PublicUtils.getState(this.props.location.pathname).parm,
            betNumber: [],
            showProgress: false,
            balance: "0.00"
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
    }

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        // this._fetchBalance();
        // this._betNumberData();

        this._resolutionParma()
    };

    _combo = (arr, num) => {
        let result = [];
        let range = function (r, _arr) {
            if (r.length == num) {
                result.push(r)
            } else {
                let l = r.length;
                for (let i = 0, len = _arr.length - num + l; i <= len; i++) {
                    range(r.concat(_arr[i]), _arr.slice(i + 1))
                }
            }
        };
        range([], arr);
        return result
    };

    _resolutionParma = () => {
        let betNumber = PublicUtils.getState(this.props.location.pathname).parm.betNumber;
        console.log(JSON.stringify(betNumber));
        const currentBetTemplate = PublicUtils.getState(this.props.location.pathname).parm.currentBetTemplate;
        const currentBetNumber = PublicUtils.getState(this.props.location.pathname).parm.currentBetNumber;
        const name = currentBetTemplate && currentBetTemplate.name;
        const amount = currentBetNumber && currentBetNumber.amount;
        const odds = currentBetNumber && currentBetNumber.odds;
        const subPlayInfo = currentBetTemplate && currentBetTemplate.subPlayInfo;
        const subName = currentBetTemplate && currentBetTemplate.secondSubPlayInfo && currentBetTemplate.secondSubPlayInfo.label;
        if (name === "连码" && subName === "普通") {
            let num_arr = [];
            let new_list = [];
            for (let i = 0; i < betNumber[0].list.length; i++) {
                num_arr.push(betNumber[0].list[i][0]);
            }
            let list = this._combo(num_arr, subPlayInfo.min);
            for (let i = 0; i < list.length; i++) {
                new_list.push([list[i], odds, amount]);
            }
            console.log(new_list);
            betNumber[0].list = new_list;
        }
        if (name === "连码" && subName === "肖串尾数") {
            let maxBetCount = (subPlayInfo.label === "四全中" ? 4 : 3) - 1;
            let zx_arr = betNumber[0].list.zx;
            let ws_arr = betNumber[0].list.ws;
            let ws_num_arr = [];
            let result_list = [];
            let new_list = [];
            for (let i = 0; i < ws_arr[0][2].length; i++) {
                if (zx_arr[0][2].indexOf(ws_arr[0][2][i]) === -1) {
                    ws_num_arr.push(ws_arr[0][2][i]);
                }
            }
            let subPlayName = subPlayInfo.label;
            if (subPlayName === "四全中" || subPlayName === "三全中" || subPlayName === "三中二") {
                let _combo_list = this._combo(ws_num_arr, maxBetCount);
                console.log(_combo_list);
                for (let l = 0; l < _combo_list.length; l++) {
                    for (let i = 0; i < zx_arr[0][2].length; i++) {
                        // let item = list[l];
                        // new_list.push(item.push(zx_arr[0][2][i]));
                        result_list.push(_combo_list[l].concat(zx_arr[0][2][i]));
                        // list[l].push(zx_arr[0][2][i]);
                    }
                }
            } else {
                for (let l = 0; l < ws_num_arr.length; l++) {
                    for (let i = 0; i < zx_arr[0][2].length; i++) {
                        // let item = list[l];
                        // new_list.push(item.push(zx_arr[0][2][i]));
                        result_list.push([ws_num_arr[l], zx_arr[0][2][i]]);
                        // list[l].push(zx_arr[0][2][i]);
                    }
                }
            }
            for (let i = 0; i < result_list.length; i++) {
                new_list.push([result_list[i], odds, amount]);
            }
            console.log(new_list);
            betNumber[0].list = new_list;
        }
        if (name === "连码" && subName === "拖胆") {
            let maxBetCount = subPlayInfo.label === "四全中" ? 4 : 3;
            let subPlayName = subPlayInfo.label;
            let dm_arr = betNumber[0].list.dm;
            let tm_arr = betNumber[0].list.tm;
            let num_arr = [];
            let result_list = [];
            let new_list = [];
            if (subPlayName === "四全中" || subPlayName === "三全中" || subPlayName === "三中二") {
                for (let i = 0; i < tm_arr.length; i++) {
                    num_arr.push(tm_arr[i][0]);
                }
                let _combo_list = this._combo(num_arr, (maxBetCount - dm_arr.length));
                console.log(_combo_list);
                for (let i = 0; i < _combo_list.length; i++) {
                    let item = _combo_list[i];
                    for (let l = 0; l < dm_arr.length; l++) {
                        // let item = list[l];
                        // new_list.push(item.push(zx_arr[0][2][i]));
                        item.push(dm_arr[l][0]);
                        // list[l].push(zx_arr[0][2][i]);
                    }
                    result_list.push(item);
                }
                console.log(result_list);
            } else {
                for (let l = 0; l < dm_arr.length; l++) {
                    for (let i = 0; i < tm_arr.length; i++) {
                        // let item = list[l];
                        // new_list.push(item.push(zx_arr[0][2][i]));
                        result_list.push([dm_arr[l][0], tm_arr[i][0]]);
                        // list[l].push(zx_arr[0][2][i]);
                    }
                }
            }

            for (let i = 0; i < result_list.length; i++) {
                new_list.push([result_list[i], odds, amount]);
            }
            console.log(new_list);
            betNumber[0].list = new_list;
        }
        if (name === "连码" && (subName === "生肖对碰" || subName === "尾数对碰")) {
            let arr1 = betNumber[0].list[0][2];
            let arr2 = betNumber[0].list[1][2];
            let num_arr = [];
            let result_list = [];
            let new_list = [];
            for (let l = 0; l < arr1.length; l++) {
                for (let i = 0; i < arr2.length; i++) {
                    // let item = list[l];
                    // new_list.push(item.push(zx_arr[0][2][i]));
                    result_list.push([arr1[l], arr2[i]]);
                    // list[l].push(zx_arr[0][2][i]);
                }
            }
            for (let i = 0; i < result_list.length; i++) {
                new_list.push([result_list[i], odds, amount]);
            }
            console.log(new_list);
            betNumber[0].list = new_list;
        }
        if (name === "合肖") {
            let num_arr = betNumber[0].list;
            for (let i = 0; i < num_arr.length; i++) {
                num_arr[i] = [num_arr[i][0], odds, '-'];
            }
            betNumber[0].list = num_arr;
        }
        if (name === "生肖连" || name === "尾数连") {
            let num_arr = [];
            let new_list = [];
            for (let i = 0; i < betNumber[0].list.length; i++) {
                num_arr.push(betNumber[0].list[i][0]);
            }

            let list = this._combo(num_arr, subPlayInfo.min);
            for (let i = 0; i < list.length; i++) {
                let odd = "";
                for (let l = 0; l < list[i].length; l++) {
                    for (let j = 0; j < betNumber[0].list.length; j++) {
                        if (betNumber[0].list[j][0] === list[i][l]) {
                            if (!odd) {
                                odd = betNumber[0].list[j][1];
                            }
                            if (odd && betNumber[0].list[j][1] < odd) {
                                odd = betNumber[0].list[j][1];
                            }
                        }
                    }
                }
                new_list.push([list[i], odd, amount]);
            }
            console.log(new_list);
            betNumber[0].list = new_list;
        }
        if (name === "全不中") {
            let num_arr = [];
            let new_list = [];
            for (let i = 0; i < betNumber[0].list.length; i++) {
                num_arr.push(betNumber[0].list[i][0]);
            }

            let list = this._combo(num_arr, subPlayInfo.min);
            for (let i = 0; i < list.length; i++) {
                new_list.push([list[i], odds, amount]);
            }
            console.log(new_list);
            betNumber[0].list = new_list;
        }
        this.setState({betNumber: betNumber});
    }

    /**
     * 获取余额
     */
    _fetchBalance() {
        let parm = {action:'refresh'};
        Http.request("memberBalance", parm, (res) => {
            // alert(JSON.stringify(res));
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/accountchange'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status) {
                    PublicUtils.updateUserInfo({is_balance: res.data.balance.balance});
                    this.setState({balance: res.data.balance.balance, betStatus: 1});
                }
            }
        });
    }

    _betting = () => {
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要投注吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._betting_submit();
                    }
                },
            ]
        });
    }
    /**
     * 投注
     * */
    _betting_submit = () => {
        this.setState({showProgress: true});
        const parma = {
            is_code: this.state.parm.is_code,
            is_no: this.state.parm.is_no,
            ball: this.state.parm.ball,
        };
        Http.request("betting", this.state.parm, async (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: '/'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status) {
                    PublicUtils.showToast(res.data.message);
                    const user = JSON.parse(Storage.retrieveData("user"));
                    PublicUtils.updateUserInfo({is_balance: user.is_balance - this.state.parm.total.amount});
                    PublicUtils.goBack();
                    // this._clearingBetInfo();
                } else if (res.data.status === 6) {
                    PublicUtils.showToast(res.data.message);
                    Storage.clearData();
                    PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'HG'});
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };

    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{position: "absolute"}}/>;
    };

    _renderItem = (item) => {
        function isArray(o) {
            return Object.prototype.toString.call(o) == '[object Array]';
        }

        if (isArray(item)) {
            // return item.join(' , ')
            return (
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap",}}>
                    {
                        item.map((subItem) => {
                            return (
                                <div style={{margin: 5}}>[{subItem}]</div>
                            )
                        })
                    }
                </div>
            )
        }
        return (
            <div style={{margin: 5}}>[{item}]</div>
        );
    }

    render() {
        const styles = {
            bodyContainer: {
                display: "flex",
                flex: 1,
                background: "linear-gradient(to bottom, " + style['primaryColorDeep' + model.SKINNAME] + ", #fff)",
                flexDirection: "column",
                paddingTop: 50,
            },
            header: {
                width: "100%",
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff"
            },
            titleContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "#000",
                padding: "0 10px",
                fontSize: 14,
                height: 30,
                alignItems: "center"
                // marginTop: 10
            },
            noContainer: {
                color: "rgba(0,0,0,0.5)"
            },
            no: {
                color: "#000",
                marginLeft: 3,
                marginRight: 3,
            },
            time: {},
            contentContainer: {
                margin: 10,
                backgroundColor: "#fff",
                flex: 1,
                borderRadius: 6,
                padding: 10
            },
            footerBox: {
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                // width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 55,
                alignItems: "center",
                borderTop: "1px solid #f5f7f9",
                zIndex: 10,
                backgroundColor: "#fff",
                paddingLeft: 10,
                paddingRight: 10,
            },
            footerLeftBox: {
                display: "flex",
                flexDirection: "column"
            },
            footerLeftTopBox: {
                display: "flex",
                flexDirection: "row",
                fontSize: 14
            },
            footerRightBox: {
                display: "flex",
                flexDirection: "row"
            },
            betBtnContainer: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
                color: "#fff",
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 80,
                borderRadius: 35,
                fontSize: 14
            },
            orderContainer: {
                // padding: 10
                marginBottom: 10
            },
            orderTitle: {
                borderBottom: "1px solid rgba(0,0,0,0.1)",
                height: 35,
                lineHeight: "35px",
                paddingLeft: 10,
                // borderRadius: 3,
                overflow: "hidden",
                fontSize: 14,
                backgroundColor: "rgba(0,0,0,0.05)"
            },
            orderHeadContainer: {
                // backgroundColor: "rgba(0,0,0,0.1)",
                marginTop: 10,
                borderRadius: 3,
                overflow: "hidden"
            },
            orderHeadRowContainer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 14,
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            orderColContainer: {
                display: "flex",
                // flex: 1,
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
            },
            disabledBtn: {
                opacity: 0.6
            },
            footerLeftBottomBox: {
                color: "rgba(0,0,0,0.5)",
                marginTop: 5,
                fontSize: 12
            },
            orderBodyContainerLM: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                borderBottom: "1px dotted rgba(0,0,0,0.1)",
                flexWrap: "wrap",
            },

            orderBodyContainer: {
                display: "flex",
                flexDirection: "column",
            },

            orderRowContainer: {
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px dotted rgba(0,0,0,0.1)",
                justifyContent: "center",
                alignItems: "center",
                // height: 35,
                // paddingLeft: 10,
                // paddingRight: 10,
                padding: 5,
                fontSize: 14
            },
            orderRowContainerLM: {
                display: "flex",
                flexDirection: "row",
                // borderBottom: "1px dotted rgba(0,0,0,0.1)",
                justifyContent: "center",
                alignItems: "center",
                height: 35,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 14
            },
            orderColContainerLM: {
                display: "flex",
                flex: 1,
                // justifyContent: "center",
                alignItems: "center",
            },
            orderXCTitleContainer: {
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px dotted rgba(0,0,0,0.1)",
                // justifyContent: "center",
                alignItems: "center",
                height: 35,
                fontSize: 14,
                paddingLeft: 10,
            },
            orderXCBodyContainer: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: 14,
                padding: 5,
                borderBottom: "1px dotted rgba(0,0,0,0.1)",
                flexWrap: "wrap",
            },
            orderXCItemContainer: {
                fontSize: 14,
                margin: 5

            },
        };
        return (
            <Container headerFontColor={"#fff"} headerPosition={"fixed"} headerBackgroundColor={"transparent"}
                // showHeader={false}
                // showBottomTab={false}
                       style={{backgroundColor: "#f5f7f9", flex: 1, display: "flex"}} title={'投注清单'}>
                <div style={styles.bodyContainer}>
                    {/*<div style={styles.header}>六合彩-下注清单</div>*/}


                    <div style={styles.contentContainer}>
                        <div style={styles.titleContainer}>
                            <div style={styles.time}>{this.state.parm.is_name}</div>
                            <div style={styles.noContainer}>第<span
                                style={styles.no}>{this.state.parm.is_no}</span>期
                            </div>
                        </div>
                        <div style={styles.orderHeadContainer}>
                            <div style={styles.orderHeadRowContainer}>
                                <div style={{...styles.orderColContainer, ...{flex: 2}}}>号码</div>
                                <div style={{...styles.orderColContainer, ...{flex: 1}}}>赔率</div>
                                <div
                                    style={{...styles.orderColContainer, ...{flex: 1, justifyContent: "flex-end"}}}>金额
                                </div>
                            </div>
                        </div>
                        <div style={{height: model.clientHeight - 200, overflow: "auto"}}>
                            {
                                this.state.betNumber.map((item) => {
                                    return (

                                        <div style={styles.orderContainer}>
                                            <div style={styles.orderTitle}>{item.name}</div>
                                            {/*{*/}
                                            {/*    this._renderList(item)*/}
                                            {/*}*/}
                                            <div style={styles.orderBodyContainer}>
                                                {
                                                    item.list.length && item.list.map((lItem) => {
                                                        return (
                                                            <div style={styles.orderRowContainer}>
                                                                <div
                                                                    style={{...styles.orderColContainer, ...{flex: 2}}}>{this._renderItem(lItem[0])}
                                                                </div>
                                                                <div
                                                                    style={{...styles.orderColContainer, ...{flex: 1}}}>@{lItem[1]}</div>
                                                                <div
                                                                    style={{
                                                                        ...styles.orderColContainer, ...{
                                                                            flex: 1,
                                                                            justifyContent: "flex-end"
                                                                        }
                                                                    }}>{lItem[2]}元
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div style={styles.footerBox}>
                    <div style={styles.footerLeftBox}>
                        <div style={styles.footerLeftTopBox}>
                            <span>注单：</span>
                            <span>{this.state.parm.total.count}笔</span>
                            <span style={{marginLeft: 10}}>共计：</span>
                            <span>{this.state.parm.total.amount}元</span>
                        </div>
                        {/*<div style={styles.footerLeftBottomBox}>*/}
                        {/*    余额：{this.state.balance}元*/}
                        {/*</div>*/}
                    </div>
                    <div style={styles.footerRightBox}>
                        <div
                            style={{...styles.betBtnContainer, ...(this.state.showProgress) ? styles.disabledBtn : null}}
                            onClick={() => {
                                !this.state.showProgress && this._betting();
                            }}>
                            {this.state.showProgress ? this._showProgress() :
                                <span>确认下注</span>}
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}
