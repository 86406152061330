import React, {Component} from 'react'

class ToastBox extends Component {
    constructor() {
        super()
        this.transitionTime = 300
        this.state = {notices: []}
        this.removeNotice = this.removeNotice.bind(this)
    }

    getNoticeKey() {
        const {notices} = this.state
        return `notice-${new Date().getTime()}-${notices.length}`
    }

    addNotice(notice) {
        const {notices} = this.state
        notice.key = this.getNoticeKey()

        // notices.push(notice);//展示所有的提示
        notices[0] = notice;//仅展示最后一个提示

        this.setState({notices});
        if (notice.duration > 0) {
            setTimeout(() => {
                this.removeNotice(notice.key)
            }, notice.duration)
        }
        return () => {
            this.removeNotice(notice.key)
        }
    }

    removeNotice(key) {
        const {notices} = this.state;
        this.setState({
            notices: notices.filter((notice) => {
                if (notice.key === key) {
                    if (notice.onClose) setTimeout(notice.onClose, this.transitionTime)
                    return false
                }
                return true
            })
        })
    }

    render() {
        const {notices} = this.state
        const icons = {
            info: 'toast_info',
            success: 'toast_success',
            error: 'toast_error',
            loading: 'toast_loading'
        }
        return (
            <div className="toast">
                {
                    notices.map((notice) => {
                        if (notice.type !== "alert") {
                            return (
                                <div className='toast_box'>
                                    {/*<div className={`toast_icon ${icons[notice.type]}`}></div>*/}
                                    <div className='toast_text'>{notice.content}</div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='alert_bg'>
                                    <div className='alert_box'>
                                        <div className='alert_title'>{notice.content.title}</div>
                                        <div className={'alert_content '+(notice.content.textAlign?notice.content.textAlign:'alert_content_fac')}><span className={notice.content.textAlign?'alert_content_pl4':''}></span> {notice.content.msg}</div>
                                        <div className='alert_button_box'>
                                            {
                                                notice.content.buttons && notice.content.buttons.map((item) => {
                                                    return (
                                                        <div onClick={() => {
                                                            const {notices} = this.state;
                                                            this.setState({
                                                                notices: notices.filter((noticeItem) => {
                                                                    if (noticeItem.key === notice.key) {
                                                                        return false
                                                                    }
                                                                    return true
                                                                })
                                                            })
                                                            item.onPress && item.onPress();
                                                        }} className={'alert_button'}>
                                                            {item.text}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        )
    }
}

export default ToastBox
