/**
 * 皇冠体育
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../../component/container";
import Loadding from "../../../component/loadding";
import NoneData from "../../../component/noneData";
import ActivityIndicator from "../../../component/activityIndicator";
import Icon from '../../../utils/icon'
// import Draggable from '../../../component/draggable';
import Http from "../../../services/http";
import MatchList from "./components/matchList";
import PlayType from "./components/playType";
import BallType from "./components/ballType";
import BetDialog from "./components/betDialog";
import CountDown from "./components/countDown";
import model from "../../../services/model";
import PublicUtils from "../../../utils/publicUtils";
import Storage from "../../../services/storage";
import MyPicker from "../../../component/myPicker";
import style from '../../../utils/config.scss'
import global from '../../../global'

const footerBarHeight = 45;



let ismoban  = {
  playTypeList: [
    {
      id: 2,
      name: "滚球"
    },
    {
      id: 0,
      name: "今日"
    },
    {
      id: 1,
      name: "早盘"
    },
    {
      id: 4,
      name: "过关"
    },
    {
      id: 5,
      name: "冠军"
    }
  ],
  ballTypeList: [
    {
      id: 1,
      name: "足球",
      icon: "zuqiu",
      size: 33
    },
    {
      id: 2,
      name: "篮球",
      icon: "lanqiu",
      size: 28
    },
    {
      id: 3,
      name: "羽毛球",
      icon: "yumaoqiu",
      size: 28
    },
    {
      id: 4,
      name: "乒乓球",
      icon: "pingpangqiu",
      size: 28
    },
    {
      id: 5,
      name: "网球",
      icon: "wangqiu",
      size: 28
    },
    {
      id: 6,
      name: "排球",
      icon: "paiqiu",
      size: 28
    },
    {
      id: 8,
      name: "棒球",
      icon: "bangqiu",
      size: 28
    },
    {
      id: 9,
      name: "其他",
      icon: "other",
      size: 28
    }
  ],
  betTypeList: [
    {
      id: 1,
      label: "独赢/让球/大小/单双"
    },
    {
      id: 2,
      label: "波胆"
    },
    {
      id: 3,
      label: "总入球"
    },
    {
      id: 4,
      label: "全场/半场"
    }
  ],
};

let isids = {
  playId: 2,
  ballId: 1,
  betId: 1,
};


if(global.ID == 'b3' || global.ID == 'b5'){

  isids = {
    playId: 0,
    ballId: 1,
    betId: 1,
  };



  ismoban  = {
    playTypeList: [

      {
        id: 0,
        name: "今日"
      },
      {
        id: 1,
        name: "早盘"
      },
      {
        id: 4,
        name: "过关"
      },
      {
        id: 5,
        name: "冠军"
      },
      {
        id: 2,
        name: "滚球"
      },
    ],
    ballTypeList: [
      {
        id: 1,
        name: "足球",
        icon: "zuqiu",
        size: 33
      },
      {
        id: 2,
        name: "篮球",
        icon: "lanqiu",
        size: 28
      },
      {
        id: 3,
        name: "羽毛球",
        icon: "yumaoqiu",
        size: 28
      },
      {
        id: 4,
        name: "乒乓球",
        icon: "pingpangqiu",
        size: 28
      },
      {
        id: 5,
        name: "网球",
        icon: "wangqiu",
        size: 28
      },
      {
        id: 6,
        name: "排球",
        icon: "paiqiu",
        size: 28
      },
      {
        id: 8,
        name: "棒球",
        icon: "bangqiu",
        size: 28
      },
      {
        id: 9,
        name: "其他",
        icon: "other",
        size: 28
      }
    ],
    betTypeList: [
      {
        id: 1,
        label: "独赢/让球/大小/单双"
      },
      {
        id: 2,
        label: "波胆"
      },
      {
        id: 3,
        label: "总入球"
      },
      {
        id: 4,
        label: "全场/半场"
      }
    ],
  };
}


export default class HG extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
            matchList: [],
            isReady: false,
            modalVisible: false,//是否显示投注模态框
            betAmount: 0,//投注金额
            canWin: 0,//可赢金额
            showProgress: false,
            translateY: 0,
            opacity: 0,
            menuOpacity: 0,
            betTips: "",
            showMenu: false,
            showBetTypeFliter: false,
            status: 0,
            BetType: this.template.betTypeList[0],
            _CG_: {
                list: [],
                odds: 0
            }
        };
    }

    /**
     * 模板
     * @type {{header: *}}
     */
    template =ismoban;




    amountTemplate = [
        {
            name: "10",
            value: 10
        },
        {
            name: "20",
            value: 20
        },
        {
            name: "50",
            value: 50
        },
        {
            name: "100",
            value: 100
        },
        {
            name: "500",
            value: 500
        },
        {
            name: "1000",
            value: 1000
        }
    ];
    /**
     * 玩法类、球类、下注类型
     * @type {{playId: number, ballId: number, betId: number}}
     */
    ids = isids;
    /**
     * 下注内容
     * _GQ_JR_ZP_:滚球，_GJ_：冠军，_CG_：串关
     * @type {{}}
     */
    betinfo = {
        _GQ_JR_ZP_GJ_: {},
        _CG_: {list: [], odds: ""}
    };
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._updateOddsTimer();
        this._fetchSportsMatch();
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.props.navigation.setParams({openMenu: this._openMenu});
    }

    _updateOddsTimer = () => {
        // window.setInterval(() => {
        //     // this.setState({index: this.state.index++});
        // }, 3000);

    };
    /**
     * 切换玩法
     * @private
     */
    _changePlayType = (id) => {
        this.ids.playId = id;
        if ((this.ids.playId !== 0 && this.ids.playId !== 1) || this.ids.ballId !== 1) {
            this.ids.betId = 1;
            this.setState({BetType: this.template.betTypeList[0]});
        }
        this._fetchSportsMatch();
    };
    /**
     * 切换球类
     * @private
     */
    _changeBallType = (id) => {
        this.ids.ballId = id;
        this.betinfo._CG_ = {list: [], odds: ""};
        if ((this.ids.playId !== 0 && this.ids.playId !== 1) || this.ids.ballId !== 1) {
            this.ids.betId = 1;
            this.setState({BetType: this.template.betTypeList[0]});
        }
        this._fetchSportsMatch();
    };
    /**
     * 切换投注类型
     * @private
     */
    _changeBetType = (id) => {
        this.ids.betId = id;
        this._fetchSportsMatch();
    };
    /**
     * 获取接口参数
     * @returns {any}
     */
    _getParm = () => {
        let parm: any = {
            item: this.ids.playId,
            ball: this.ids.ballId,
        };
        if ((this.ids.playId === 0 || this.ids.playId === 1) && this.ids.ballId === 1) {
            parm.type = this.ids.betId
        }
        if (this.ids.playId !== 0 && this.ids.playId !== 1) {
            parm.type = 1;
        }
        if (this.ids.playId === 4) {
            parm.item = 0;
            parm.type = 11;
        }
        if (this.ids.playId === 5) {
            parm.item = 1;
            parm.type = 99;
        }

        return parm;
    };
    /**
     * 获取赛事列表
     * @private
     */
    _fetchSportsMatch = () => {
        this.setState({matchList: [], isReady: false}, () => {
            //Http.abortFetching();

            // if ("AbortController" in window) {
            //     window.controller.abort();
            // }
            const parm = this._getParm();

            console.log(parm)

            Http.request("getSportsMatch", parm, (response) => {
                this.setState({isReady: true});
                let datalist = response.data.db;
                let dataObj = {
                    playId: this.ids.playId,
                    ballId: this.ids.ballId,
                    betId: this.ids.betId,
                    datalist: datalist || []
                };
                let state = {
                    matchList: this._buildMatchList(dataObj),
                    status: response.data.status,
                    name: response.data.name || "",
                    st: response.data.st || "",
                };
                this.setState(state, () => {
                    this.oldMatchList = dataObj;
                });
            })
        });
    };

    /**
     * 重新构建赛事json
     * @param list
     * @returns {any[]}
     */
    _buildMatchList(dataObj) {
        let listLength = dataObj.datalist.length;
        //检测赔率变化：上升还是降低
        if (this.oldMatchList && dataObj.playId === this.oldMatchList.playId && dataObj.ballId === this.oldMatchList.ballId && dataObj.betId === this.oldMatchList.betId) {
            const oddsList = model.getBetInfoIndexOddArry(dataObj.ballId, dataObj.betId);
            for (let i = 0; i < listLength; i++) {
                for (let l = 0; l < this.oldMatchList.datalist.length; l++) {
                    if (dataObj.datalist[i][0] === this.oldMatchList.datalist[l][0]) {
                        //滚球、今日、早盘、串关
                        if (this.ids.playId === 0 || this.ids.playId === 1 || this.ids.playId === 2 || this.ids.playId === 4) {
                            for (let o = 0; o < oddsList.length; o++) {
                                //赔率降低
                                if (this.oldMatchList.datalist[l][oddsList[o]] > dataObj.datalist[i][oddsList[o]]) {
                                    dataObj.datalist[i].push(0)
                                }
                                //赔率不变
                                if (this.oldMatchList.datalist[l][oddsList[o]] === dataObj.datalist[i][oddsList[o]]) {
                                    dataObj.datalist[i].push(1)
                                }
                                //赔率上升
                                if (this.oldMatchList.datalist[l][oddsList[o]] < dataObj.datalist[i][oddsList[o]]) {
                                    dataObj.datalist[i].push(2)
                                }
                            }
                        }
                        //冠军
                        if (this.ids.playId === 5) {
                            let oddStatus = [];
                            for (let o = 0; o < this.oldMatchList.datalist[l][4].length; o++) {
                                if (this.oldMatchList.datalist[l][4][o] > dataObj.datalist[i][4][o]) {
                                    oddStatus.push(0);
                                }
                                if (this.oldMatchList.datalist[l][4][o] === dataObj.datalist[i][4][o]) {
                                    oddStatus.push(1);
                                }
                                if (this.oldMatchList.datalist[l][4][o] < dataObj.datalist[i][4][o]) {
                                    oddStatus.push(2);
                                }
                            }
                            dataObj.datalist[i].push(oddStatus);
                        }
                        break;
                    }
                }
            }
        }
        //重新构建根据赛事名称和时间排序的赛事json
        let newList = [];
        for (let i = 0; i < listLength; i++) {
            let isExis = false;
            for (let l = 0; l < newList.length; l++) {
                if (newList[l].name === dataObj.datalist[i][1]) {
                    isExis = true;
                    newList[l].list.push(dataObj.datalist[i]);
                    newList[l].count++;
                }
            }
            if (!isExis) {
                newList.push({name: dataObj.datalist[i][1], list: [dataObj.datalist[i]], count: 1});
            }
        }
        // for (let i = 0; i < listLength; i++) {
        //     if (!match.name) {
        //         match.name = dataObj.datalist[i][1];
        //     } else if (match.name && match.name !== dataObj.datalist[i][1]) {
        //         match.count = match.list.length;
        //         match.id = dataObj.datalist[i][0];
        //         newList.push(match);
        //         match = {name: dataObj.datalist[i][1], list: [], checked: false};
        //     }
        //     match.list.push(dataObj.datalist[i]);
        // }
        // if (match.name) {
        //     match.count = match.list.length;
        //     newList.push(match);
        // }
        //检测赛事的打开关闭状态
        // for (let i = 0; i < this.state.matchList.length; i++) {
        //     for (let l = 0; l < newList.length; l++) {
        //         if (this.state.matchList[i].name === newList[l].name && this.state.matchList[i].id === newList[l].id) {
        //             newList[l].checked = this.state.matchList[i].checked;
        //         }
        //     }
        // }
        return newList;
    }

    _listFooterComponent() {
        // return this.ids.playId === 4 ? <div style={{flex: 1, height: footerBarHeight}}/> : null;
    }

    /**
     * 页脚工具栏
     */
    _getFooterBar() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column"
            },
            headerBox: {
                zIndex: 2,
                backgroundColor: "#fff"
            },
            bodyBox: {
                overflow: "auto"
            },
            disabledBtn: {
                opacity: 0.6
            },
            ballTypeWrapper: {
                width: "100%",
                overflow: "auto"
            },
            ballTypeBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgBox: {
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            operatorBar: {
                display: "flex",
                height: 40,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f5f5",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
            },
            openAllBtn: {
                marginRight: 5,
                color: "#444444",
                fontSize: 14
            },
            openAllBtnText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            pickerWrapper: {
                display: "flex",
                flex: 1
            },
            footerBox: {
                display: "flex",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                height: footerBarHeight,
                backgroundColor: "#fff",
                borderTop: "1px solid #f1f1f1",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10
            },
            clearBetBtn: {
                display: "flex", flexDirection: "row"
            },
            clearBetBtnText: {
                color: "#333", marginLeft: 3, fontSize: 16
            },
            betItems: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            betItemsText: {
                color: "#fff"
            },
            betModal: {
                display: "flex",
                backgroundColor: "rgba(0,0,0,0.3)",
                flex: 1,
            },
            betInnerBox: {
                position: "absolute",
                left: 20,
                right: 20,
                backgroundColor: "#fff",
                borderRadius: 4,
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#eff3f6"
            },
            betInnerBody: {
                padding: 10
            },
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3
            },
            betAmountInputWrapper: {
                border: "1px solid #f5f7f9",
                height: 30,
                width: 130,
                borderRadius: 4,
                fontSize: 12,
            },
            betAmountInput: {
                display: "flex",
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 14,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 14,
                color: "#e8ab09"
            },
            betOddsTips: {
                display: "flex",
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },

            pickerItem: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                alignItems: "center"
            },
            headerToolbarItemText: {
                color: "rgba(0,0,0,1)",
                fontSize: 14
            }
        };
        return this.ids.playId === 4 ?
            <div style={styles.footerBox}>
                <div onClick={() => {
                    this._clearCGBet();
                }} style={styles.clearBetBtn}>
                    <Icon icon={"laji"} size={20} color={"#333"}/>
                    {
                        this.betinfo._CG_.list.length ?
                            <div style={styles.footerBetAmountBox}>
                                <div style={styles.footerBetAmountItem}>{this.betinfo._CG_.list.length}</div>
                                <div style={styles.footerBetAmountItem}>串</div>
                                <div style={styles.footerBetAmountItem}>1</div>
                                <div style={styles.footerBetAmountItem}>@{this.state.odds}</div>
                            </div> :
                            <div style={styles.footerBetAmountItem}>请下注</div>
                    }
                </div>
                <div
                    onClick={() => {
                        this.betinfo._CG_.list.length !== 0 && this.setModalVisible(true);
                    }}
                    style={{...styles.betItems, ...this.betinfo._CG_.list.length === 0 ? styles.disabledBtn : null}}>
                    <div style={styles.betItemsText}>我的注单</div>
                </div>
            </div>
            : null;
    };

    /**
     * 计算串关玩法总赔率
     */
    getTotalOdd = () => {
        let totalOdd = 1;
        const _CG_ = this.betinfo._CG_.list;
        const _GQ_JR_ZP_GJ_ = this.betinfo._GQ_JR_ZP_GJ_;
        if (this.ids.playId === 4) {
            for (let i = 0; i < _CG_.length; i++) {
                if (_CG_[i].typeid === 3 || _CG_[i].typeid === 4 || _CG_[i].typeid === 5 || _CG_[i].typeid === 6 || _CG_[i].typeid === 14) {
                    totalOdd = totalOdd * (1 + Number(_CG_[i].odds));
                } else {
                    totalOdd = totalOdd * _CG_[i].odds;
                }
            }
        } else {
            if (_GQ_JR_ZP_GJ_.typeid === 3 || _GQ_JR_ZP_GJ_.typeid === 4 || _GQ_JR_ZP_GJ_.typeid === 5 || _GQ_JR_ZP_GJ_.typeid === 6 || _GQ_JR_ZP_GJ_.typeid === 14) {
                totalOdd = totalOdd * Number(_GQ_JR_ZP_GJ_.odds);
            } else {
                totalOdd = totalOdd * (_GQ_JR_ZP_GJ_.odds - 1);
            }
        }
        this.betinfo._CG_.odds = PublicUtils.subStringNum(totalOdd);
        this.setState({odds: this.betinfo._CG_.odds});
        return totalOdd;
    };
    /**
     * 点击下注
     * @param template：数据模板 data：数据
     */
    _onOddPress = async (template, data) => {
        // console.log(template);
        // console.log(data);
        if (!await PublicUtils._checkLogin()) {
            PublicUtils.navigate('Login', {nextPage: 'HG'});
            return;
        }
        if (this.ids.playId === 0 || this.ids.playId === 1 || this.ids.playId === 2) {
            if (template.play_list.pk_value && template.play_list.pk_type === "c") {
                template.play_list.pk_value = "-" + template.play_list.pk_value;
            }
            this._BET_GQ_JR_ZP(template, data);
        }
        if (this.ids.playId === 4) {
            this._BET_CG(template, data);
        }
        if (this.ids.playId === 5) {
            this._BET_GJ(template, data);
        }
    };
    /**
     * 滚球/今日/早盘下注
     * @param template：数据模板 data：数据
     */
    _BET_GQ_JR_ZP = (template, data) => {
        this.betinfo._GQ_JR_ZP_GJ_.gid = template.bet_list.id;
        this.betinfo._GQ_JR_ZP_GJ_.info = data;
        this.betinfo._GQ_JR_ZP_GJ_.gameId = template.play_list.typeid;
        this.betinfo._GQ_JR_ZP_GJ_.gameTypeName = template.play_list.name;
        this.betinfo._GQ_JR_ZP_GJ_.betContent = template.bet_list.name;
        this.betinfo._GQ_JR_ZP_GJ_.pk = data[template.play_list.pk_value];
        this.betinfo._GQ_JR_ZP_GJ_.bs = data[template.play_list.pk_id];
        this.betinfo._GQ_JR_ZP_GJ_.typeid = template.play_list.id;
        this.betinfo._GQ_JR_ZP_GJ_.odds = data[template.bet_list.odd];
        if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
            && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0)) {
            this.betinfo._GQ_JR_ZP_GJ_.pk = "+" + data[template.play_list.pk_value];
        }
        if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
            && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0)) {
            this.betinfo._GQ_JR_ZP_GJ_.pk = "-" + data[template.play_list.pk_value];
        }
        this.setModalVisible(true);
    };

    /**
     * 串关下注
     * @param template：数据模板 data：数据
     */
    _BET_CG = (template, data) => {
        let betStatus = this.isExisMatchinfo(template, data);
        if (betStatus === 2) {
            alert("一场赛事只能投注一次哦");
            return;
        }

        if (betStatus === 0) {
            let betInfo: any = {
                id: data[0],
                gid: template.bet_list.id,
                info: data,
                gameId: template.play_list.typeid,
                gameTypeName: template.play_list.name,
                betContent: template.bet_list.name,
                pk: data[template.play_list.pk_value],
                bs: data[template.play_list.pk_id],
                typeid: template.play_list.id,
                odds: data[template.bet_list.odd],
            }
            if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0)) {
                betInfo.pk = "+" + data[template.play_list.pk_value];
            }
            if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0)) {
                betInfo.pk = "-" + data[template.play_list.pk_value];
            }
            this.betinfo._CG_.list.push(betInfo);
            this.getTotalOdd();
            // this.setState({_CG_: {list: this.betinfo._CG_}}, () => {
            //     this.getTotalOdd();
            // });
            //console.log(this.betList);
        }
    };
    /**
     * 冠军下注
     * @param template：数据模板 data：数据
     */
    _BET_GJ = (template, data) => {
        this.betinfo._GQ_JR_ZP_GJ_.gid = data[5][template];
        this.betinfo._GQ_JR_ZP_GJ_.info = data;
        this.betinfo._GQ_JR_ZP_GJ_.gameId = 99;
        this.betinfo._GQ_JR_ZP_GJ_.gameTypeName = "冠军";
        this.betinfo._GQ_JR_ZP_GJ_.betContent = data[3][template];
        this.betinfo._GQ_JR_ZP_GJ_.bs = data[0];
        this.betinfo._GQ_JR_ZP_GJ_.typeid = 99;
        this.betinfo._GQ_JR_ZP_GJ_.odds = data[4][template];
        this.setModalVisible(true);
    };


    /**
     * 检查是否添加过改场比赛的投注信息（过关）
     * @param item
     */
    isExisMatchinfo(template, data) {
        let result = 0;
        for (let i = 0; i < this.betinfo._CG_.list.length; i++) {
            if (this.betinfo._CG_.list[i].info[2] === data[2] && this.betinfo._CG_.list[i].info[3] === data[3]) {
                result = 1;
                if (this.betinfo._CG_.list[i].betContent === template.bet_list.name && this.betinfo._CG_.list[i].bs === template.play_list.pk_id) {
                    result = 2;
                } else {
                    this.betinfo._CG_.list[i].id = data[0];
                    this.betinfo._CG_.list[i].gid = template.bet_list.id;
                    this.betinfo._CG_.list[i].info = data;
                    this.betinfo._CG_.list[i].gameId = template.play_list.typeid;
                    this.betinfo._CG_.list[i].gameTypeName = template.play_list.name;
                    this.betinfo._CG_.list[i].betContent = template.bet_list.name;
                    this.betinfo._CG_.list[i].pk = data[template.play_list.pk_value];
                    this.betinfo._CG_.list[i].bs = data[template.play_list.pk_id];
                    this.betinfo._CG_.list[i].typeid = template.play_list.id;
                    this.betinfo._CG_.list[i].odds = data[template.bet_list.odd];
                    if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                        && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0)) {
                        this.betinfo._CG_.list[i].pk = "+" + data[template.play_list.pk_value];
                    }
                    if ((template.bet_list.name === "主" && data[template.play_list.pk_type] === "h" && data[template.play_list.pk_value] !== 0) || (template.bet_list.name === "客"
                        && data[template.play_list.pk_type] === "c" && data[template.play_list.pk_value] !== 0)) {
                        this.betinfo._CG_.list[i].pk = "-" + data[template.play_list.pk_value];
                    }
                }
            }
        }

        // this.setState({_CG_: {list: this.betinfo._CG_}}, () => {
        //     this.getTotalOdd();
        // });
        this.getTotalOdd();
        return result;
    }


    /**
     *显示/隐藏投注框
     */
    setModalVisible(visible) {
        // visible ?
        //     this.setState({modalVisible: visible, betAmount: 0, canWin: 0, betTips: ""}, () => {
        //         this.parallelAnimatedOpen.start()
        //     }) : this.parallelAnimatedClose.start(() => {
        //         this.setState({modalVisible: visible});
        //     })
        let changeObj = {
            modalVisible: visible
        };
        this.setState(changeObj);
    }

    /**
     *投注金额
     */
    setBetAmount(amount) {
        this.setState({betAmount: amount}, () => {
            this.setState({canWin: this.state.betAmount * this.getTotalOdd() || 0.00});
        });
    }

    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };
    /**
     * 删除过关投注
     * @param item
     * @private
     */
    _deleteBet = (item) => {
        let betList = this.betinfo._CG_.list;
        betList.splice(item, 1);
        this.setState({_CG_: betList});
        this.getTotalOdd();
        if (this.betinfo._CG_.list.length === 0) {
            this.setState({modalVisible: false});
        }
    };
    /**
     * 确认是否清楚过关投注
     * @private
     */
    _clearCGBet = () => {
        PublicUtils.alert({
            title: "温馨提示",
            msg: "确定要清除所有投注吗？",
            buttons: [
                {
                    text: "取消", onPress: () => {
                    }
                },
                {
                    text: "是的", onPress: () => {
                        this._clearingBetInfo();
                    }
                },
            ]
        });
    };

    /**
     * 清除过关投注
     * @private
     */
    _clearingBetInfo = () => {

        this.betinfo._CG_.list = [];
        this.betinfo._CG_.odds = null;
        this.setState({_CG_: this.betinfo});
    };
    /**
     * 投注标题
     * @returns {string}
     * @private
     */
    _renderBetTitle = () => {
        if (this.ids.playId === 0 || this.ids.playId === 1 || this.ids.playId === 2) {
            return this.betinfo._GQ_JR_ZP_GJ_.info[1];
        } else if (this.ids.playId === 4) {
            return "串关";
        } else {
            return "冠军";
        }
    };
    /**
     * 比赛队伍
     * @private
     */
    _renderBetTeam = () => {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column"
            },
            headerBox: {
                zIndex: 2,
                backgroundColor: "#fff"
            },
            bodyBox: {
                overflow: "auto"
            },
            disabledBtn: {
                opacity: 0.6
            },
            ballTypeWrapper: {
                width: "100%",
                overflow: "auto"
            },
            ballTypeBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgBox: {
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            operatorBar: {
                display: "flex",
                height: 40,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f5f5",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
            },
            openAllBtn: {
                marginRight: 5,
                color: "#444444",
                fontSize: 14
            },
            openAllBtnText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            pickerWrapper: {
                display: "flex",
                flex: 1
            },
            footerBox: {
                display: "flex",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                height: footerBarHeight,
                backgroundColor: "#fff",
                borderTop: "1px solid #f1f1f1",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10
            },
            clearBetBtn: {
                display: "flex", flexDirection: "row"
            },
            clearBetBtnText: {
                color: "#333", marginLeft: 3, fontSize: 16
            },
            betItems: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            betItemsText: {
                color: "#fff"
            },
            betModal: {
                display: "flex",
                backgroundColor: "rgba(0,0,0,0.3)",
                flex: 1,
            },
            betInnerBox: {
                position: "absolute",
                left: 20,
                right: 20,
                backgroundColor: "#fff",
                borderRadius: 4,
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#eff3f6"
            },
            betInnerBody: {
                padding: 10
            },
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3
            },
            betAmountInputWrapper: {
                border: "1px solid #f5f7f9",
                height: 30,
                width: 130,
                borderRadius: 4,
                fontSize: 12,
            },
            betAmountInput: {
                display: "flex",
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 14,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 14,
                color: "#e8ab09"
            },
            betOddsTips: {
                display: "flex",
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },

            pickerItem: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                alignItems: "center"
            },
            headerToolbarItemText: {
                color: "rgba(0,0,0,1)",
                fontSize: 14
            }
        };
        if (this.ids.playId === 0 || this.ids.playId === 1 || this.ids.playId === 2) {
            return (
                <div style={styles.betModalRow}>
                    <div
                        style={{...styles.betMatchTeam, ...{flex: 1}}}>{this.betinfo._GQ_JR_ZP_GJ_.info[2]}</div>
                    <div style={styles.betMatchVs}>VS</div>
                    <div style={{
                        ...styles.betMatchTeam, ...{
                            flex: 1,
                            textAlign: 'right'
                        }
                    }}>{this.betinfo._GQ_JR_ZP_GJ_.info[3]}</div>
                </div>
            );
        } else if (this.ids.playId === 4) {
            return (
                <div style={{...styles.betModalRow, ...{justifyContent: 'space-between',}}}>
                    <div style={styles.betMatchTeam}>串关</div>
                    <div style={styles.footerBetAmountBox}>
                        <div style={styles.footerBetAmountItem}>{this.state._CG_.list.length}</div>
                        <div style={styles.footerBetAmountItem}>串</div>
                        <div style={styles.footerBetAmountItem}>1</div>
                        <div style={styles.footerBetAmountItem}>@ {this.state._CG_.odds}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={styles.betModalRow}>
                    <div style={styles.betMatchTeam}>{this.betinfo._GQ_JR_ZP_GJ_.info[1]}</div>
                </div>
            );
        }
    };
    /**
     * 确认投注
     */
    beting = () => {
        if (!this.state.betAmount) {
            this.setState({betTips: "请输入投注金额"});
            return;
        }
        let parm = {
            item: this.ids.playId,
            ball: this.ids.ballId,
            type: this.betinfo._GQ_JR_ZP_GJ_.gameId,
            bet_bs: this.betinfo._GQ_JR_ZP_GJ_.bs,
            bet_class: this.betinfo._GQ_JR_ZP_GJ_.typeid,
            bet_content: this.betinfo._GQ_JR_ZP_GJ_.gid,
            bet_amount: this.state.betAmount
        }
        if (this.ids.playId === 4) {
            let bet_bs_list = [];
            let bet_class_list = [];
            let bet_content_list = [];
            for (let i = 0; i < this.state._CG_.list.length; i++) {
                bet_bs_list.push(this.state._CG_.list[i].bs);
                bet_class_list.push(this.state._CG_.list[i].typeid);
                bet_content_list.push(this.state._CG_.list[i].gid);
            }
            parm.item = 0;
            parm.type = 11;
            parm.bet_bs = JSON.stringify(bet_bs_list);
            parm.bet_class = JSON.stringify(bet_class_list);
            parm.bet_content = JSON.stringify(bet_content_list);
        }

        this.setState({showProgress: true});
        Http.request("sportBetting", parm, (res) => {
            this.setState({showProgress: false, betAmount: 0, modalVisible: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate('Login', {nextPage: 'HG'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status === 1) {
                    PublicUtils.showToast(res.data.message);
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };
    /**
     * 计算盘口列表的高度
     * */
    _getBodyHeight = () => {
        if (this.ids.playId === 2) {
            return model.clientHeight - 149 - model.headerHeight
        } else if (this.ids.playId === 0) {
            return model.clientHeight - 190 - model.headerHeight;
        } else if (this.ids.playId === 1) {
            return model.clientHeight - 190 - model.headerHeight;
        } else if (this.ids.playId === 4) {
            return model.clientHeight - 149 - model.headerHeight - 46;
        } else if (this.ids.playId === 5) {
            return model.clientHeight - 149 - model.headerHeight;
        }
    };
    /**
     * 全部展开/全部收起
     * */
    _toggleList = () => {
        let matchList = this.state.matchList;
        for (let i = 0; i < matchList.length; i++) {
            matchList[i].checked = !this.state.showAll;
        }
        this.setState({matchList: matchList, showAll: !this.state.showAll});
    };

    /**
     * 赛事列表
     **/
    _renderResult = () => {
        if (this.state.isReady) {
            if (this.state.status) {
                if (this.state.matchList.length === 0) {
                    return <NoneData msg={'暂时没有该类赛事哦！'}/>
                } else {
                    return (<MatchList listFooterComponent={() => {
                        return this._listFooterComponent()
                    }} matchList={this.state.matchList} isReady={this.state.isReady}
                                       playId={this.ids.playId} ballId={this.ids.ballId}
                                       betId={this.ids.betId} onOddPress={(template, data) => {
                        this._onOddPress(template, data)
                    }}/>)
                }
            } else {
                return (<div style={{
                    flexDirection: "column",
                    display: "flex",
                    height: "100%",
                    alignItems: 'center',
                    justifyContent: "center",
                    fontSize: 16
                }}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{
                            color: style['primaryColor' + model.SKINNAME],
                            marginRight: 5,
                            fontSize: 16
                        }}>{this.state.name}</div>
                        <div>暂时维护！</div>
                    </div>
                    {
                        global.ID != 'ca'? <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                            <div>预计</div>
                            <div style={{
                                color: style['primaryColor' + model.SKINNAME],
                                margin: "0 5px",
                                fontSize: 16
                            }}>{this.state.st}</div>
                            <div>升级完毕！</div>
                        </div>:null
                    }

                </div>)
            }
        } else {
            return <Loadding/>;
        }
    };

    render() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "column"
            },
            headerBox: {
                zIndex: 2,
                backgroundColor: "#fff"
            },
            bodyBox: {
                overflow: "auto"
            },
            disabledBtn: {
                opacity: 0.6
            },
            ballTypeWrapper: {
                width: "100%",
                overflow: "auto"
            },
            ballTypeBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgBox: {
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            operatorBar: {
                display: "flex",
                height: 40,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f5f5",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
            },
            openAllBtn: {
                marginRight: 5,
                color: "#444444",
                fontSize: 14
            },
            openAllBtnText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            pickerWrapper: {
                display: "flex",
                flex: 1
            },
            footerBox: {
                display: "flex",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                height: footerBarHeight,
                backgroundColor: "#fff",
                borderTop: "1px solid #f1f1f1",
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10
            },
            clearBetBtn: {
                display: "flex", flexDirection: "row"
            },
            clearBetBtnText: {
                color: "#333", marginLeft: 3, fontSize: 16
            },
            betItems: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3
            },
            betItemsText: {
                color: "#fff"
            },
            betModal: {
                display: "flex",
                backgroundColor: "rgba(0,0,0,0.3)",
                flex: 1,
            },
            betInnerBox: {
                position: "absolute",
                left: 20,
                right: 20,
                backgroundColor: "#fff",
                borderRadius: 4,
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#eff3f6"
            },
            betInnerBody: {
                padding: 10
            },
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3
            },
            betAmountInputWrapper: {
                border: "1px solid #f5f7f9",
                height: 30,
                width: 130,
                borderRadius: 4,
                fontSize: 12,
            },
            betAmountInput: {
                display: "flex",
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 14,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 14,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 14,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 14,
                color: "#e8ab09"
            },
            betOddsTips: {
                display: "flex",
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },

            pickerItem: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                alignItems: "center"
            },
            headerToolbarItemText: {
                color: "rgba(0,0,0,1)",
                fontSize: 14
            }
        };
        return (
            <Container title={PublicUtils.getState(this.props.location.pathname).param.name} showMenuButton={true}
                       menuArray={[
                           {name: "比赛结果", icon: "jqkj", href: 'SportOpenResult'},
                           {
                               name: "投注记录",
                               icon: "tzjl",
                               href: 'SportBetRecord'
                           },
                           {
                               name: "玩法说明",
                               icon: "wfsm",
                               href: 'SportIntroduction'
                           }]}
                       style={{backgroundColor: "#fff"}}>
                <div style={styles.headerBox}>
                    {/* 玩法类别 */}
                    <PlayType onPress={(id) => {
                        this._changePlayType(id)
                    }} playId={this.ids.playId} playTypeList={this.template.playTypeList}/>
                    {/* 球类 */}
                    <div style={styles.ballTypeWrapper}>
                        <div style={styles.ballTypeBox}>
                            {
                                this.template.ballTypeList.map((item, key) => {
                                    const color = this.ids.ballId === item.id ? style['primaryColor' + model.SKINNAME] : style['fontColorLight' + model.SKINNAME];
                                    return (
                                        <BallType key={key} color={color} onPress={(id) => {
                                            this._changeBallType(id)
                                        }} ballId={this.ids.ballId} data={item}/>
                                    )
                                })
                            }
                        </div>
                    </div>


                    <div style={styles.operatorBar}>
                        <div style={styles.pickerWrapper}>
                            {(this.ids.playId === 0 || this.ids.playId === 1) && this.ids.ballId === 1 &&
                            <div onClick={() => {
                                this.setState({showBetTypeFliter: true});
                            }} style={styles.pickerItem}>
                                <div
                                    style={styles.headerToolbarItemText}>{this.state.BetType && this.state.BetType.label}</div>
                                <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                            </div>
                            }
                        </div>
                        <div style={styles.openAllBtn}>
                            {/*<CountDown playId={this.ids.playId} countDownComplete={() => {*/}
                            {/*this._fetchSportsMatch();*/}
                            {/*}} onPress={() => {*/}
                            {/*this._fetchSportsMatch()*/}
                            {/*}} isReady={this.state.isReady}/>*/}
                            {/*<div style={styles.openAllBtn} onClick={() => {*/}
                            {/*this._toggleList();*/}
                            {/*}}>{this.state.showAll ? "全部收起" : "全部展开"}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className={'scorll'} style={{...styles.bodyBox, ...{height: this._getBodyHeight()}}}>
                    {
                        this._renderResult()
                    }
                </div>
                {
                    // this._getFooterBar()
                }
                {/*<Draggable style={{right: 80, bottom: 120}}>*/}
                {/*<CountDown countDownComplete={() => {*/}
                {/*this._fetchSportsMatch();*/}
                {/*}} onPress={() => {*/}
                {/*this._fetchSportsMatch()*/}
                {/*}} isReady={this.state.isReady}/>*/}
                {/*</Draggable>*/}
                <MyPicker
                    title={'选择玩法'}
                    model={this.template.betTypeList}
                    display={this.state.showBetTypeFliter}
                    onHiden={() => {
                        this.setState({showBetTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({BetType: item.value[0].value, showBetTypeFliter: false}, () => {
                            // console.log(item);
                            this._changeBetType(item.value[0].value.id);
                        });
                    }}
                />
                {
                    this.state.modalVisible ?
                        <BetDialog playId={this.ids.playId}
                                   ballId={this.ids.ballId}
                                   betId={this.ids.betId}
                                   betinfo={this.betinfo}
                                   onBetSuccess={() => {
                                       this._clearingBetInfo();
                                   }}
                                   onClose={() => {
                                       this.setModalVisible(false);
                                   }}
                                   delCGItem={(item) => {
                                       this._deleteBet(item);
                                   }}/> : null
                }

            </Container>
        )
    }
}
