/**
 * 首页
 * @format
 * @flow
 */
import React from 'react';
import Container from "../../component/container";
import Icon from '../../utils/icon'
import Http from '../../services/http'
import Storage from "../../services/storage";
import PublicUtils from '../../utils/publicUtils'
import MyInput from '../../component/myInput'
import DatePicker from 'react-mobile-datepicker';
import style from "../../utils/config.scss";
import model from "../../services/model";
import {object} from "prop-types";
import global from '../../global'

export default class Userinfo extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        console.log(PublicUtils.getState(this.props.location.pathname).state);
        this.state = Object.assign(PublicUtils.getState(this.props.location.pathname).state, {
            time: new Date(),
            isOpen: false,
            birthday: PublicUtils.getState(this.props.location.pathname).state.user_birthday.value,
        });
    }

    /**
     * 绑定真实姓名
     * @private
     */
    _bindName = (text) => {
        const user_name = this.state.user_name;
        this.setState({user_name: {value: text, isEdit: user_name.isEdit}});
        // this.userInfo.user_name = text;
    };
    /**
     * 绑定出生日期
     * @private
     */
    _bindBirthday = (text) => {
        const user_birthday = this.state.user_birthday;
        this.setState({user_birthday: {value: text, isEdit: user_birthday.isEdit}});
        // this.userInfo.user_birthday = text;
    };
    /**
     * 绑定手机
     * @private
     */
    _bindPhone = (text) => {
        const user_phone = this.state.user_phone;
        this.setState({user_phone: {value: text, isEdit: user_phone.isEdit}});
        // this.userInfo.user_phone = text;
    };

    /**
     * 绑定邮箱
     * @private
     */
    _bindEmail = (text) => {
        const user_mail = this.state.user_mail;
        this.setState({user_mail: {value: text, isEdit: user_mail.isEdit}});
        // this.userInfo.user_mail = text;
    };

    /**
     * 绑定qq/微信
     * @private
     */
    _bindQQ = (text) => {
        const user_qq = this.state.user_qq;
        this.setState({user_qq: {value: text, isEdit: user_qq.isEdit}});
        // this.userInfo.user_qq = text;
    };

    /**
     * 修改昵称
     * @private
     */
    _bindNickname = (text) => {
        const nick_name = this.state.nick_name;
        this.setState({nick_name: {value: text, isEdit: nick_name.isEdit}});
        // this.userInfo.nick_name = text;
    };

    /**
     * 绑定真实姓名
     * @private
     */
    _bindName = (text) => {
        const user_name = this.state.user_name;
        this.setState({user_name: {value: text, isEdit: user_name.isEdit}});
        // this.userInfo.user_name = text;
    };
    /**
     * 绑定出生日期
     * @private
     */
    _bindBirthday = (text) => {
        const user_birthday = this.state.user_birthday;
        this.setState({user_birthday: {value: text, isEdit: user_birthday.isEdit}});
        // this.userInfo.user_birthday = text;
    };
    /**
     * 绑定手机
     * @private
     */
    _bindPhone = (text) => {
        const user_phone = this.state.user_phone;
        this.setState({user_phone: {value: text, isEdit: user_phone.isEdit}});
        // this.userInfo.user_phone = text;
    };

    /**
     * 绑定邮箱
     * @private
     */
    _bindEmail = (text) => {
        const user_mail = this.state.user_mail;
        this.setState({user_mail: {value: text, isEdit: user_mail.isEdit}});
        // this.userInfo.user_mail = text;
    };

    /**
     * 绑定qq/微信
     * @private
     */
    _bindQQ = (text) => {
        const user_qq = this.state.user_qq;
        this.setState({user_qq: {value: text, isEdit: user_qq.isEdit}});
        // this.userInfo.user_qq = text;
    };

    /**
     * 修改昵称
     * @private
     */
    _bindNickname = (text) => {
        const nick_name = this.state.nick_name;
        this.setState({nick_name: {value: text, isEdit: nick_name.isEdit}});
        // this.userInfo.nick_name = text;
    };

    /**
     * 保存会员信息
     * @returns {Promise<void>}
     */
    _saveUserInfo = () => {
        let parm = {
            user_name: this.state.user_name.value || "",
            user_birthday: this.state.birthday || "",
            user_phone: this.state.user_phone.value || "",
            user_mail: this.state.user_mail.value || "",
            user_qq: this.state.user_qq.value || "",
            nick_name: this.state.nick_name.value || "",
        };
        this.setState({showProgress: true});
        // alert(JSON.stringify(parm));
        // PublicUtils.showLoader({msg: "信息保存中...", duration: 0, showShadow: true});
        Http.request("memberEdit", parm, async (res) => {
            this.setState({showProgress: false});
            // PublicUtils.hideLoader();
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    PublicUtils.showToast("修改成功");
                    let user = JSON.parse(await Storage.retrieveData("user"));

                    Storage.storeData({user: JSON.stringify(Object.assign(user, parm))});
                    // this.commonData.loginData.user.user_question = this.userInfo.user_question.value;
                    // this.commonData.loginData.user.user_answer = this.userInfo.user_answer.value;
                    // this.commonData.loginData.user.user_name = this.userInfo.user_name.value;
                    // this.commonData.loginData.user.user_birthday = this.userInfo.user_birthday.value;
                    // this.commonData.loginData.user.user_phone = this.userInfo.user_phone.value;
                    // this.commonData.loginData.user.user_mail = this.userInfo.user_mail.value;
                    // this.commonData.loginData.user.user_qq = this.userInfo.user_qq.value;
                    // this.commonData.saveLocalStorage();
                    PublicUtils.goBack();
                } else {
                    PublicUtils.showToast(res.data.message, {type: "FAILD"});
                }
            }
        });


    };
    formatDate = (date) => {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let second = date.getSeconds();
        second = minute < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d;
    };
    handleClick = () => {
        if (!this.state.user_birthday.isEdit) {
            this.setState({isOpen: true});
        }
    }
    handleCancel = () => {
        this.setState({isOpen: false});
    }

    handleSelect = (time) => {
        this.setState({birthday: this.formatDate(time), isOpen: false});
        // this._checkForm({birthday: this.formatDate(time)});
    }

    render() {
        const styles = {
            iptBoxContainer: {
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                marginBottom: 20,
                paddingLeft: 10,
                paddingRight: 10,
            },
          
            iptBox: {
                display: "flex",
                flexDirection: "column",
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                marginTop: 5,
                marginBottom: 5,
                borderRadius: 3
            },
            iptLabel: {
                height: 38,
                lineHeight: "38px",
                paddingLeft: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            },
            textIpt: {
                height: 38,
                alignItems: "center",
                display: "flex",
                flexDirection: "row"
            },
            iptLabelYY: {
                height: 38,
                lineHeight: "38px",
                paddingLeft: 4,
                display: "flex",
                alignItems: "center",
                padding:"0 10px"
            },
            iptBoxYY: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                marginTop: 5,
                marginBottom: 5,
                borderRadius: 3
            },
            textIptYY: {
                height: 38,
                flex:1,
                alignItems: "center",
                display: "flex",
                flexDirection: "row"
                
            },
            iptLabelText: {
                color: "rgba(0,0,0,1)",
                fontSize: 14,
                marginLeft: 3
            },
            btnBox: {
                marginTop: 30,
                // paddingLeft: 20,
                // paddingRight: 20
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            headerContainer: {
                display: "flex",
                backgroundColor: "#fff",
                width: "100%",
                height: 50,
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 10
            },
            headerbs: {
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            headerText: {
                marginLeft: 10,
                fontSize: 16
            },
        };
        return (
            <Container title={"个人资料"}>
                <div style={styles.headerContainer}>
                    <div style={styles.headerbs}/>
                    <div style={styles.headerText}>用戶账号：{this.state.user_account.value}</div>
                </div>

                <div style={styles.iptBoxContainer}>
                    <div style={global.ID == "yy"?styles.iptBoxYY:styles.iptBox}>
                        <div style={global.ID == "yy"?styles.iptLabelYY:styles.iptLabel}>
                            <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-realname'}
                                  color={style['primaryColor' + model.SKINNAME]} size={16}/>
                            <div
                                style={styles.iptLabelText}>真实姓名{this.state.user_name.isEdit ?
                                <span style={{color: 'rgba(0,0,0,0.5)'}}>（不可修改）</span> : null}</div>
                        </div>
                        <MyInput value={this.state.user_name.value} readOnly={this.state.user_name.isEdit}
                                 placeholder={'请输入您的姓名（必须与银行户名相同，否则不能出款）'}
                                 style={global.ID == "yy"?styles.textIptYY : styles.textIpt} textContentType={'username'}
                                 onChangeText={(text) => this._bindName(text)}

                                 />
                    </div>

                    <div style={global.ID == "yy"?styles.iptBoxYY:styles.iptBox}>
                        <div style={global.ID == "yy"?styles.iptLabelYY:styles.iptLabel}>
                            <Icon style={{marginTop: -5, marginRight: 3}} icon={'user'}
                                  color={style['primaryColor' + model.SKINNAME]} size={18}/>
                            <div style={styles.iptLabelText}>昵称</div>
                        </div>
                        <MyInput value={this.state.nick_name.value}
                                 placeholder={'请输入昵称'}
                                 style={global.ID == "yy"?styles.textIptYY:styles.textIpt} textContentType={'username'}
                                 onChangeText={(text) => this._bindNickname(text)}/>
                    </div>
                    {
                        this.state.showBirthday ?
                            <div style={global.ID == "yy"?styles.iptBoxYY:styles.iptBox}>
                                <div style={global.ID == "yy"?styles.iptLabelYY:styles.iptLabel}>
                                    <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-birthday'}
                                          color={style['primaryColor' + model.SKINNAME]}
                                          size={16}/>
                                    <div
                                        style={styles.iptLabelText}>生日{this.state.user_birthday.isEdit ?
                                        <span style={{color: 'rgba(0,0,0,0.5)'}}>（不可修改）</span> : null}</div>
                                </div>

                                <MyInput onPress={this.handleClick} value={this.state.birthday}
                                         readOnly={this.state.user_birthday.isEdit}
                                         placeholder="请选择您的生日" style={global.ID == "yy"?styles.textIptYY:styles.textIpt}/>
                                {/*{*/}
                                {/*    this.state.user_birthday.isEdit ?*/}
                                {/*        <MyInput value={this.state.user_birthday.value} editable={false}*/}
                                {/*                   placeholder={'请选择生日'}*/}
                                {/*                   style={global.ID == "yy"?styles.textIptYY:styles.textIpt} textContentType={'username'}*/}
                                {/*                   onChangeText={(text) => this._bindNickname(text)}/> :*/}
                                {/*        <DatePicker*/}
                                {/*            // style={{flex: 1}}*/}
                                {/*            date={this.state.birthday && this.state.birthday}*/}
                                {/*            mode="date"*/}
                                {/*            placeholder={'请选择您的出生日期'}*/}
                                {/*            format="YYYY-MM-DD"*/}
                                {/*            minDate="1900-01-01"*/}
                                {/*            // maxDate={this._fetchDate()}*/}
                                {/*            confirmBtnText="确定"*/}
                                {/*            cancelBtnText="取消"*/}
                                {/*            showIcon={false}*/}
                                {/*            locale="zh"*/}
                                {/*            customStyles={{*/}
                                {/*                dateInput: {*/}
                                {/*                    marginLeft: 0,*/}
                                {/*                    borderWidth: 0,*/}
                                {/*                    alignItems: 'flex-start',*/}
                                {/*                },*/}
                                {/*                dateTouchBody: {*/}
                                {/*                    textAlign: "left"*/}
                                {/*                },*/}
                                {/*                dateText: {textAlign: "left"},*/}
                                {/*                placeholderText: {textAlign: "left"}*/}
                                {/*                // ... You can check the source to find the other keys.*/}
                                {/*            }}*/}
                                {/*            onDateChange={(date) => {*/}
                                {/*                this.setState({birthday: date});*/}
                                {/*                this._bindBirthday(date)*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*}*/}

                            </div>
                            : null
                    }
                    {
                        this.state.showPhone ?
                            <div style={global.ID == "yy"?styles.iptBoxYY:styles.iptBox}>
                                <div style={global.ID == "yy"?styles.iptLabelYY:styles.iptLabel}>
                                    <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-phone'}
                                          color={style['primaryColor' + model.SKINNAME]} size={16}/>
                                    <div
                                        style={styles.iptLabelText}>手机号码{this.state.user_phone.isEdit ?
                                        <span style={{color: 'rgba(0,0,0,0.5)'}}>（不可修改）</span> : null}</div>
                                </div>
                                <MyInput value={this.state.user_phone.value}
                                         readOnly={this.state.user_phone.isEdit}
                                         placeholder={'请输入您的手机号码'}
                                         style={global.ID == "yy"?styles.textIptYY:styles.textIpt} textContentType={'username'}
                                         onChangeText={(text) => this._bindPhone(text)}/>
                            </div>
                            : null
                    }
                    {
                        this.state.showMail ?
                            <div style={global.ID == "yy"?styles.iptBoxYY:styles.iptBox}>
                                <div style={global.ID == "yy"?styles.iptLabelYY:styles.iptLabel}>
                                    <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-email'}
                                          color={style['primaryColor' + model.SKINNAME]} size={16}/>
                                    <div
                                        style={styles.iptLabelText}>邮箱{this.state.user_mail.isEdit ?
                                        <span style={{color: 'rgba(0,0,0,0.5)'}}>（不可修改）</span> : null}</div>
                                </div>
                                <MyInput
                                    value={this.state.user_mail.value}
                                    readOnly={this.state.user_mail.isEdit}
                                    placeholder={'请输入您的邮箱地址'}
                                    style={global.ID == "yy"?styles.textIptYY:styles.textIpt} textContentType={'username'}
                                    onChangeText={(text) => this._bindEmail(text)}/>
                            </div>
                            : null
                    }
                    {
                        this.state.showQQ ?
                            <div style={global.ID == "yy"?styles.iptBoxYY:styles.iptBox}>
                                <div style={global.ID == "yy"?styles.iptLabelYY:styles.iptLabel}>
                                    <Icon style={{marginTop: -5, marginRight: 3}} icon={'new-qq'}
                                          color={style['primaryColor' + model.SKINNAME]} size={18}/>
                                    <div
                                        style={styles.iptLabelText}>QQ/微信{this.state.user_qq.isEdit ?
                                        <span style={{color: 'rgba(0,0,0,0.5)'}}>（不可修改）</span> : null}</div>
                                </div>
                                <MyInput
                                    value={this.state.user_qq.value}
                                    readOnly={this.state.user_qq.isEdit}
                                    placeholder={'请输入您的QQ或微信'}
                                    style={global.ID == "yy"?styles.textIptYY:styles.textIpt} textContentType={'username'}
                                    onChangeText={(text) => this._bindQQ(text)}/>
                            </div>
                            : null
                    }
                    <div style={styles.btnBox}>
                        <div
                            onClick={() => {
                                this._saveUserInfo();
                            }}>
                            <div style={styles.btn}>
                                <div style={styles.btnText}>完成</div>
                            </div>
                        </div>
                    </div>
                </div>
                <DatePicker
                    theme={'ios'}
                    value={this.state.time}
                    isOpen={this.state.isOpen}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}/>
            </Container>
        )
    }
}
