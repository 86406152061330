//申请成为代理

import React, {Component} from 'react';
import '../assets/css/app.css'
import Image from "../component/image";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import global from "../global";

export default class BeAgent extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            msg: null,
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 获取url参数
     * @param name
     */
    getQueryString = (name) => {
        let value = "";
        const paramArray = window.location.hash.split('?');
        if (paramArray.length > 1) {
            const paramItem = paramArray[1].split('&');
            for (let i = 0; i < paramItem.length; i++) {
                if (paramItem[i].split('=')[0] === name) {
                    value = paramItem[i].split('=')[1];
                }
            }
        }
        return value;
    };
    /**
     * 申请代理
     */
    joinAgent = () => {
        var service_url = null;

        function getRequest(url, data, callback) {
            xhr.open('get', url, true);
            //每当readyState改变时就会触发onreadystatechange函数
            //0: 请求未初始化
            //1: 服务器连接已建立
            //2: 请求已接收
            //3: 请求处理中
            //4: 请求已完成，且响应已就绪
            xhr.onreadystatechange = function () {
                //处理服务器返回的数据
                if (xhr.readyState == 4 && xhr.status == 200) {
                    //获取服务器返回的数据
                    var txt = xhr.responseText;
                    callback(JSON.parse(txt));
                    //通过拿到的数据更新页面
                }
            }
            xhr.send(null);
        }

        function postRequest(url, data, callback) {
            xhr.open('post', url, true);
            xhr.setRequestHeader('content-type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    var txt = xhr.responseText;
                    //拿到数据后更新页面
                    callback(JSON.parse(txt));
                }
            }
            xhr.send(data);
        }

        function getXhr() {

            var xhr = null;
            if (window.XMLHttpRequest) {
                //非IE浏览器
                xhr = new XMLHttpRequest();
            } else {
                //IE浏览器
                xhr = null;
            }
            return xhr;
        }

        var xhr = getXhr();


        // getRequest("http://twpubapi.bslivemobi.com/xtd/main", {}, function (res) {
        //     if (res.code === 200) {
        //         service_url = res.data.service_mobile;
        //     } else {
        //         alert("数据获取失败")
        //     }
        // })

        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        let uid = null;
        if (user.id) {
            uid = user.id
        }
        if (this.getQueryString("uid")) {
            uid = this.getQueryString("uid");
        }
        if (!uid) {
            this.setState({msg: "您还没有登录哦！", show: true});
            return;
        }
        postRequest(global.APP_API_URL.url + global.ID + "/agent-check", "user_id=" + user.id, (res) => {
            alert(res);
            if (res.code === 300) {
                this.setState({msg: "您还没有登录哦！", show: true});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    alert(0);
                    this.setState({msg: res.data.msg, show: true});
                }
            }
        });
        // Http.request("agentCheck", parm, (res) => {
        //     alert(res);
        //     if (res.code === 300) {
        //         Storage.clearData();
        //         PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
        //     }
        //     if (res.code === 200) {
        //         if (res.data.status) {
        //             PublicUtils.showToast(res.data.msg)
        //         }
        //     }
        // });
    };
    /**
     * 客服
     * @private
     */
    _services = () => {
        // PublicUtils.navigate('GameRoom', {url: this.serviceUrl, title: "客服", parma: null,});
        const serviceUrl = Storage.retrieveData("serviceUrl");
        PublicUtils.linking(serviceUrl || this.getQueryString("serviceUrl"), "_blank");
    };

    render() {
        return (
            <div className={"agent-wrapper"}>
                <div className={"agent-content"}>
                    <Image className={"agent-content-title"} uri={require("../assets/images/beagent/wyzdl.png")}/>
                    <div className={"agent-btns-wrapper"}>
                        <div className={"col"}>
                            <div className={"agent-img-btn"} onClick={() => {
                                this.joinAgent()
                            }}>
                                <Image
                                    uri={require("../assets/images/beagent/sq.png")}/>
                            </div>
                        </div>
                        <div className={"col"}>
                            <div className={"agent-img-btn"} onClick={() => {
                                this._services()
                            }}>
                                <Image uri={require("../assets/images/beagent/xw.png")}/>
                            </div>
                        </div>
                    </div>
                    <Image className={"bottom"} uri={require("../assets/images/beagent/sm.png")}/>
                </div>
                {
                    this.state.show ?
                        <div style={styles.dialogShadow}>
                            <div style={styles.dialogBox}>
                                <div style={styles.dialogContent}>{this.state.msg}</div>
                                <div onClick={() => {
                                    this.setState({show: false});
                                }} style={styles.dialogButton}>好的
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}
const styles = {
    dialogShadow: {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    dialogBox: {
        width: "68%",
        backgroundColor: "#fff",
        borderRadius: 3,
        overflow: "hidden"
    },
    dialogContent: {
        padding: 10,
        lineHeight: "22px",
        marginTop: 10,
        fontSize: 16
    },
    dialogButton: {
        margin: 10,
        backgroundColor: "rgba(245, 72, 68, 0.93)",
        padding: "10px 0",
        borderRadius: 3,
        color: "#fff"
    }
};
