/**
 * 自定义图标
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import model from "../services/model";
import style from '../utils/config.scss';
import Icon from "../utils/icon";
import MyInput from "./myInput";
import DatePicker from "react-mobile-datepicker";
import PublicUtils from "../utils/publicUtils";

export default class MyPicker extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedList: {
                dateTime: {
                    start: "",
                    end: ""
                },
                value: []
            },
            startTime: "",
            endTime: "",
            startTimeSwitch: false,
            endTimeSwitch: false,
        }
    }

    static propTypes = {
        display: PropTypes.bool,
        title: PropTypes.string,
        model: PropTypes.array,
        modelFunc: PropTypes.func,
        onHiden: PropTypes.func,
        onItemPress: PropTypes.func,
        rowCount: PropTypes.number,
        hasDatetimePicker: PropTypes.bool,
        multiple: PropTypes.bool
    };
    static defaultProps = {
        model: [],
        display: false,
        rowCount: 4,
        hasDatetimePicker: false,
        multiple: false
    };
    componentWillReceiveProps = (nextProps) => {
        this.setState({show: nextProps.display});
        // nextProps.display ?
        //     this.setState({showShadow: true}, () => {
        //     }) : this.hidenPicker.start(() => {
        //         this.setState({showShadow: false});
        //     })
    }
    /**
     * 隐藏picker
     * @private
     */
    _onHiden = () => {
        this.props.onHiden && this.props.onHiden();
        // this.hide();
    };
    /**
     * 点击选项
     * @private
     */
    _onItemPress = (item, i) => {
        let selectedList = this.state.selectedList;
        if (this.props.multiple) {
            selectedList.value.push({index: i, value: item});
        } else {
            selectedList.value = [{index: i, value: item}];
        }
        this.setState({selectedList: selectedList, searchType: 2});
        // this.props.onItemPress && this.props.onItemPress(item);
    };

    /**
     * 确定
     * @private
     */
    _submit = () => {
        if (this.state.selectedList.value.length || (this.state.startTime && this.state.endTime)) {
            let selectedList = this.state.selectedList;
            selectedList.dateTime.start = this.state.startTime;
            selectedList.dateTime.end = this.state.endTime;
            this.props.onItemPress && this.props.onItemPress(selectedList);
        }
        this._onHiden();
    }

    /**
     * 显示
     */
    show = () => {
        // this.setState({showShadow: true}, () => {
        //     this.showPicker.start()
        // })
        this.setState({showShadow: true});
    };

    /**
     * 隐藏
     */
    hide = () => {
        // alert(0);
        // this.hidenPicker.start(() => {
        //     this.setState({showShadow: false});
        // })
        this.setState({show: false});
    };


    /**
     * 组件加载完成
     * @param event
     * @private
     */
    _onLayout(event) {
        this.setState({height: event.nativeEvent.layout.height});
    }

    _check = (index) => {
        for (let i = 0; i < this.state.selectedList.value.length; i++) {
            if (this.state.selectedList.value[i].index == index) {
                return true;
            }
        }
        return false;
    };
    formatDate = (date) => {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let second = date.getSeconds();
        second = minute < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d;
    };
    handleStartTimeCancel = () => {
        this.setState({startTimeSwitch: false});
    }

    handleStartTimeSelect = (time) => {
        if (this.state.endTime && (Date.parse(time) > Date.parse(this.state.endTime))) {
            PublicUtils.showToast("开始时间必须小于结束时间", {type: "FAILD"});
            return;
        }
        this.setState({startTime: this.formatDate(time), startTimeSwitch: false});
    }
    handleEndTimeCancel = () => {
        this.setState({endTimeSwitch: false});
        // this.setState({isOpen: false});
    }

    handleEndTimeSelect = (time) => {
        if (this.state.startTime && (Date.parse(time) < Date.parse(this.state.startTime))) {
            PublicUtils.showToast("结束时间必须大于开始时间", {type: "FAILD"});
            return;
        }
        this.setState({endTime: this.formatDate(time), endTimeSwitch: false});
        // this.setState({date: this.formatDate(time), isOpen: false}, () => {
        //
        //     this._fetchList();
        // });
    }

    render() {
        const fliterStyles = {
            betAmountInput: {
                textAlign: "center"
            },
            fliterDateBox: {
                display: "flex",
                borderBottom: "1px solid #f1f1f1",
                margin: "0 10px",
                padding: "10px 0"
                // borderBottomColor: "@primary",
                // borderBottomWidth: 1,
                // borderStyle: "solid",
                // backgroundColor: "red",
            },
            fliterDateContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                // backgroundColor: "red",
                marginTop: 10,
                justifyContent: "space-between",
                alignItems: "center",
                // borderBottomColor: "@primary",
                // borderBottomWidth: 1,
                // borderStyle: "solid"
            },
            fliterDateLeftContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // borderBottomColor: "@primary",
                // borderBottomWidth: 1,
                // borderStyle: "solid",
                // backgroundColor: "#000",
            },
            fliterDateMidContainer: {
                display: "flex",
                width: 40,
                justifyContent: "center",
                alignItems: "center",
            },
            fliterDateMidText: {},
            fliterDateRightContainer: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // borderBottomColor: "@primary",
                // borderBottomWidth: 1,
                // borderStyle: "solid"
            },
            datepicker: {
                height: 40,
                borderWidth: 0,
                paddingLeft: 0,
                marginLeft: 0
            },
            fliterShadow: {
                display: "flex",
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 999
            },
            fliterBox: {
                display: "flex",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 100
            },
            fliterHeader: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #f8f8f8",
                fontSize: 18
            },
            closeBtn: {
                padding: "0 10px",
                fontSize: 14,
                color: style['primaryColor' + model.SKINNAME]
            },
            fliterBody: {
                display: "flex",
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 10,
                paddingBottom: 10,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
            },
            fliterItem: {
                display: "flex",
                margin: 5,
                backgroundColor: "#f1f1f1",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 40,
            },
            fliterFooter: {
                display: "flex",
                flexDirection: "row",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            fliterHeaderText: {
                fontSize: 15
            },
            fliterItemText: {
                fontSize: 12,
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            fliterFooterText: {
                fontSize: 15,
                color: "#fff"
            },
            checked: {
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            checkedText: {
                color: "#fff"
            }
        };
        const list = this.props.model.length ? this.props.model : this.props.modelFunc();
        return (
            this.state.show ?
                <div
                    style={{
                        ...fliterStyles.fliterShadow
                    }}
                    onClick={() => {
                        this._onHiden();
                    }}>
                    <div
                        onLayout={(e) => {
                            this._onLayout(e)
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        style={{
                            ...fliterStyles.fliterBox
                        }}>
                        <div style={{display: "flex", flex: 1, flexDirection: "column",}}>
                            <div style={fliterStyles.fliterHeader}>
                                <div style={fliterStyles.closeBtn} onClick={() => {
                                    this._onHiden();
                                }}>取消
                                </div>
                                <div style={fliterStyles.fliterHeaderText}>{this.props.title}</div>
                                <div style={fliterStyles.closeBtn} onClick={() => {
                                    this._submit();
                                }}>确定
                                </div>
                            </div>
                            {
                                this.props.hasDatetimePicker ?

                                    <div style={fliterStyles.fliterDateBox}>
                                        <div style={fliterStyles.fliterDateContainer}>
                                            <div style={fliterStyles.fliterDateLeftContainer}>
                                                <div>
                                                    <MyInput
                                                        canEmpty={true}
                                                        readOnly={true}
                                                        value={this.state.startTime}
                                                        placeholder={'开始时间'}
                                                        style={fliterStyles.betAmountInput}
                                                        onPress={() => {
                                                            this.setState({startTimeSwitch: true});
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.state.startTime ?
                                                        <div onClick={() => {
                                                            // this.setState({showDateTypeFliter: true})
                                                            this.setState({startTime: null})
                                                        }}>
                                                            <Icon icon={'circle-close'} size={18}
                                                                  color={'rgba(0,0,0,3)'}/>
                                                        </div> : null
                                                }
                                            </div>
                                            <div style={fliterStyles.fliterDateMidContainer}>
                                                <div
                                                    style={fliterStyles.fliterDateMidText}>至
                                                </div>
                                            </div>
                                            <div style={fliterStyles.fliterDateRightContainer}>
                                                <div>
                                                    <MyInput
                                                        canEmpty={true}
                                                        readOnly={true}
                                                        value={this.state.endTime}
                                                        placeholder={'结束时间'}
                                                        style={fliterStyles.betAmountInput}
                                                        onPress={() => {
                                                            this.setState({endTimeSwitch: true});
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.state.endTime ?
                                                        <div onClick={() => {
                                                            // this.setState({showDateTypeFliter: true})
                                                            this.setState({endTime: ""})
                                                        }}>
                                                            <Icon icon={'circle-close'} size={18}
                                                                  color={'rgba(0,0,0,3)'}/>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            <div style={fliterStyles.fliterBody}>
                                {
                                    list.map((item, key) => {
                                        return (
                                            <div key={key}
                                                 style={{...fliterStyles.fliterItem, ...this._check(key) ? fliterStyles.checked : null, ...{width: ((model.clientWidth - (this.props.rowCount * 10 + 10)) / this.props.rowCount),}}}
                                                 onClick={() => {
                                                     this._onItemPress(item, key);
                                                 }}>
                                                <div>
                                                    <div
                                                        style={{...fliterStyles.fliterItemText, ...this._check(key) ? fliterStyles.checkedText : null, ...{width: ((model.clientWidth - (this.props.rowCount * 10 + 10)) / this.props.rowCount)}}}>{item.label}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/*<div onClick={() => {*/}
                            {/*    this._onHiden();*/}
                            {/*}} style={fliterStyles.fliterFooter}>*/}
                            {/*    <div style={fliterStyles.fliterFooterText}>取消</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <DatePicker
                        theme={'ios'}
                        value={new Date()}
                        isOpen={this.state.startTimeSwitch}
                        onSelect={this.handleStartTimeSelect}
                        onCancel={this.handleStartTimeCancel}/>
                    <DatePicker
                        theme={'ios'}
                        value={new Date()}
                        isOpen={this.state.endTimeSwitch}
                        onSelect={this.handleEndTimeSelect}
                        onCancel={this.handleEndTimeCancel}/>
                </div> : null
        )
    }
}
