//头部导航

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import Icon from '../utils/icon'
import PropTypes from 'prop-types';
import style from '../utils/config.scss'
import history from '../services/history'
import PublicUtils from '../utils/publicUtils'
import model from "../services/model";
import global from "../global";

class Header extends React.Component {

    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };
    }

    static propTypes = {
        title: PropTypes.string,
        menuArray: PropTypes.array,
        showBackButton: PropTypes.bool,
        showMenuButton: PropTypes.bool,
        backgroundColor: PropTypes.string,
        fontColor: PropTypes.string
    };
    static defaultProps = {
        showBackButton: true,
        showMenuButton: false,
        backgroundColor: global.THEME === "default" ? style['headerColor' + model.SKINNAME] : "linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
        fontColor: global.THEME === "default" ? "#000" : "#fff"
    };

    render() {
        const styles = {
            container: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: style['headerColor' + model.SKINNAME],
                // backgroundColor: "#000",
                padding: 0,
                position: "relative",
            },
            backButtonContainer: {
                position: "absolute",
                height: 50,
                left: 10,
                top: 0,
                bottom: 0,
                margin: "auto",
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            backButton: {
                width: 30,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            titleBox: {
                left: 0,
                height: 50,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto",
                textAlign: "center",
                fontSize: 16,
                fontWeight: "blod",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            rightButtonContainer: {
                position: "absolute",
                height: 50,
                right: 10,
                top: 0,
                bottom: 0,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            rightButton: {
                width: 30,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            menuBox: {
                position: "fixed",
                right: 5,
                top: 45,
                opacity: 1,
                zIndex: 1000,
                width: 120,
            },
            menuHeader: {
                width: 0,
                height: 0,
                marginLeft: 95,
                borderStyle: 'solid',
                borderWidth: 8,
                borderTopColor: 'transparent',//下箭头颜色
                borderLeftColor: 'transparent',//右箭头颜色
                borderBottomColor: '#333',//上箭头颜色
                borderRightColor: 'transparent'//左箭头颜色
            },
            menuBody: {
                backgroundColor: "#333",
                borderRadius: 4,
                overflow: "hidden",
                boxShadow: "1px 1px 5px rgba(0,0,0,0.2)"
            },
            menuItem: {
                display: "flex",
                flexDirection: "row",
                paddingLeft: 10,
                height: 50,
                justifyContent: "center",
                alignItems: "center"
            },
            menuText: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 15,
                paddingRight: 10,
                borderBottomWidth: 1,
                borderBottomColor: "rgba(255,255,255,0.05)",
                height: 50,
                lineHeight: "50px"
            },
        };
        return (
            <div>
                <div
                    // style={{backgroundColor: style['headerColor' + model.SKINNAME]}}
                >
                    <div style={{...styles.container, ...{background: this.props.backgroundColor}}}>
                        <div style={{width: "100%", position: "relative"}} className={'headMargin'}>
                            {
                                this.props.showBackButton ?
                                    <div style={styles.backButtonContainer} onClick={() => {


                                        if(localStorage.getItem('jll')){
                                            localStorage.setItem('jll','');
                                            PublicUtils.navigate("Home");
                                        }

                                        PublicUtils.goBack();
                                    }}>
                                        <div style={styles.backButton}>
                                            <Icon icon={'jiantou1'} color={this.props.fontColor} size={26}/>
                                        </div>
                                    </div> : null
                            }
                            <div style={{...styles.titleBox, ...{color: this.props.fontColor}}}>
                                {this.props.title}
                            </div>
                            {
                                this.props.showMenuButton ?
                                    <div style={styles.rightButtonContainer}>
                                        <div style={styles.rightButton} onClick={() => {
                                            this.setState({showMenu: !this.state.showMenu});
                                        }}>
                                            <Icon icon={'caidan'} color={this.props.fontColor} size={20}/>
                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>
                {
                    (this.props.showMenuButton && this.state.showMenu) ?
                        <div style={styles.menuBox} className={'bodyPadding'}>
                            <div style={styles.menuHeader}/>
                            <div style={styles.menuBody}>
                                {
                                    this.props.menuArray.map((item, key) => {
                                        return (
                                            <div key={key} style={styles.menuItem} onClick={() => {



                                                PublicUtils.navigate(item.href, item.parma || {})
                                                // this.props.navigation.navigate(item.href);
                                                // this._closeMenuAnimate();
                                            }}>
                                                <Icon icon={item.icon} size={18} color={"#fff"}/>
                                                <div style={styles.menuText}>{item.name}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> : null
                }
            </div>
        );
    }
}


export default withRouter(Header);
