/**
 * 皇冠体育
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../../component/container";
import Icon from '../../../utils/icon'
import FooterBar from './components/footerBar';
import BetBody from './components/betBody';
import RefreshOddsBox from './components/refreshOddsBox';
import PublicUtils from "../../../utils/publicUtils";
import Http from "../../../services/http";
import model from "../../../services/model";
import Loadding from "../../../component/loadding";
import style from '../../../utils/config.scss'

export default class HgDetail extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            item: PublicUtils.getState(this.props.location.pathname).data,
            playId: PublicUtils.getState(this.props.location.pathname).playId,
            ballId: PublicUtils.getState(this.props.location.pathname).ballId,
            betId: PublicUtils.getState(this.props.location.pathname).betId,
            matchList: [],
            isReady: false,
        };
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._fetchMatchList();
    };
    /**
     * 下注内容
     * _GQ_JR_ZP_:滚球，_GJ_：冠军，_CG_：串关
     * @type {{}}
     */
    // betinfo = model.betinfo;
    // static navigationOptions = ({navigation}) => {
    //     const title = navigation.getParam('data').name + " - " + (navigation.getParam('playId') === 4 ? "过关" : "单式");
    //     return {
    //         title: title,
    //         headerRight: <Menubox itemArray={[{name: "比赛结果", icon: "jqkj", href: 'SportOpenResult'}, {
    //             name: "投注记录",
    //             icon: "tzjl",
    //             href: 'SportBetRecord'
    //         }]}/>
    //         // header: (<Header
    //         //     style={{borderBottomWidth: 0}} showRightButton={true} rightButtonIcon={'caidan'}
    //         //     rightButtonIconSize={16}
    //         //     rightButtonIconOnPress={
    //         //         navigation.getParam('openMenu')
    //         //     }>{navigation.getParam('code') === 4002 ? "重庆快乐十分" : "广东快乐十分"}</Header>)
    //     }
    // };
    /**
     * 获取接口参数
     * @returns {any}
     */
    _getParm = () => {
        let parm: any = {
            item: this.state.playId,
            ball: this.state.ballId,
            league: this.state.item.name
        };
        if ((this.state.playId === 0 || this.state.playId === 1) && this.state.ballId === 1) {
            parm.type = this.state.betId
        }
        if (this.state.playId !== 0 && this.state.playId !== 1) {
            parm.type = 1;
        }
        if (this.state.playId === 4) {
            parm.item = 0;
            parm.type = 11;
        }
        if (this.state.playId === 5) {
            parm.item = 1;
            parm.type = 99;
        }

        return parm;
    };
    /**
     * 获取联赛盘口
     * @private
     */
    _fetchMatchList = () => {
        this.setState({matchList: [], isReady: false});
        let parm: any = this._getParm();
        Http.request("getSportsMatch", parm, (response) => {
            this.setState({isReady: true});
            if (response.data.db) {
                let datalist = response.data.db;
                let dataObj = {
                    playId: this.state.playId,
                    ballId: this.state.ballId,
                    betId: this.state.betId,
                    datalist: datalist || []
                };
                this.setState({matchList: this._buildMatchList(dataObj)}, () => {
                    this.oldMatchList = dataObj;
                });
            } else {
                PublicUtils.goBack(null);
            }
        })
    };

    /**
     * 重新构建赛事json
     * @param list
     * @returns {any[]}
     */
    _buildMatchList(dataObj) {
        let listLength = dataObj.datalist.length;
        //检测赔率变化：上升还是降低
        if (this.oldMatchList && dataObj.playId === this.oldMatchList.playId && dataObj.ballId === this.oldMatchList.ballId && dataObj.betId === this.oldMatchList.betId) {
            const oddsList = model.getBetInfoIndexOddArry(dataObj.ballId, dataObj.betId);
            for (let i = 0; i < listLength; i++) {
                for (let l = 0; l < this.oldMatchList.datalist.length; l++) {
                    if (dataObj.datalist[i][0] === this.oldMatchList.datalist[l][0]) {
                        //滚球、今日、早盘、串关
                        if (this.state.playId === 0 || this.state.playId === 1 || this.state.playId === 2 || this.state.playId === 4) {
                            for (let o = 0; o < oddsList.length; o++) {
                                //赔率降低
                                if (this.oldMatchList.datalist[l][oddsList[o]] > dataObj.datalist[i][oddsList[o]]) {
                                    dataObj.datalist[i].push(0)
                                }
                                //赔率不变
                                if (this.oldMatchList.datalist[l][oddsList[o]] === dataObj.datalist[i][oddsList[o]]) {
                                    dataObj.datalist[i].push(1)
                                }
                                //赔率上升
                                if (this.oldMatchList.datalist[l][oddsList[o]] < dataObj.datalist[i][oddsList[o]]) {
                                    dataObj.datalist[i].push(2)
                                }
                            }
                        }
                        //冠军
                        if (this.state.playId === 5) {
                            let oddStatus = [];
                            for (let o = 0; o < this.oldMatchList.datalist[l][4].length; o++) {
                                if (this.oldMatchList.datalist[l][4][o] > dataObj.datalist[i][4][o]) {
                                    oddStatus.push(0);
                                }
                                if (this.oldMatchList.datalist[l][4][o] === dataObj.datalist[i][4][o]) {
                                    oddStatus.push(1);
                                }
                                if (this.oldMatchList.datalist[l][4][o] < dataObj.datalist[i][4][o]) {
                                    oddStatus.push(2);
                                }
                            }
                            dataObj.datalist[i].push(oddStatus);
                        }
                        break;
                    }
                }
            }
        }
        //重新构建根据赛事名称和时间排序的赛事json
        let newList = [];
        for (let i = 0; i < listLength; i++) {
            let isExis = false;
            for (let l = 0; l < newList.length; l++) {
                if (newList[l].name === dataObj.datalist[i][1]) {
                    isExis = true;
                    newList[l].list.push(dataObj.datalist[i]);
                    newList[l].count++;
                }
            }
            if (!isExis) {
                newList.push({name: dataObj.datalist[i][1], list: [dataObj.datalist[i]], count: 1});
            }
        }
        console.log(newList);
        // //检测赛事的打开关闭状态
        // for (let i = 0; i < this.state.matchList.length; i++) {
        //     for (let l = 0; l < newList.length; l++) {
        //         if (this.state.matchList[i].name === newList[l].name && this.state.matchList[i].id === newList[l].id) {
        //             newList[l].checked = this.state.matchList[i].checked;
        //         }
        //     }
        // }
        return newList;
    }

    /**
     * 清除过关投注
     * @private
     */
    _clearingBetInfo = () => {
        model.betinfo = {
            _GQ_JR_ZP_GJ_: {},
            _CG_: {list: [], odds: ""}
        };
        this.getTotalOdd();
    };

    /**
     * 页脚工具栏
     */
    _getFooterBar() {
        return this.state.playId === 4 ?
            <FooterBar playId={this.state.playId} betId={this.state.betId} ballId={this.state.ballId}
                       odds={this.getTotalOdd()} balance={this.state.balance} betinfo={model.betinfo}
                       clearBetInfo={() => {
                           this._clearingBetInfo();
                       }
                       }/>
            : null;
    };

    /**
     * 计算串关玩法总赔率
     */
    getTotalOdd = () => {
        let totalOdd = 1;
        const _CG_ = model.betinfo._CG_.list;
        const _GQ_JR_ZP_GJ_ = model.betinfo._GQ_JR_ZP_GJ_;
        if (this.state.playId === 4) {
            for (let i = 0; i < _CG_.length; i++) {
                if (_CG_[i].typeid === 3 || _CG_[i].typeid === 4 || _CG_[i].typeid === 5 || _CG_[i].typeid === 6 || _CG_[i].typeid === 14) {
                    totalOdd = totalOdd * (1 + Number(_CG_[i].odds));
                } else {
                    totalOdd = totalOdd * _CG_[i].odds;
                }
            }
        } else {
            if (_GQ_JR_ZP_GJ_.typeid === 3 || _GQ_JR_ZP_GJ_.typeid === 4 || _GQ_JR_ZP_GJ_.typeid === 5 || _GQ_JR_ZP_GJ_.typeid === 6 || _GQ_JR_ZP_GJ_.typeid === 14) {
                totalOdd = totalOdd * Number(_GQ_JR_ZP_GJ_.odds);
            } else {
                totalOdd = totalOdd * (_GQ_JR_ZP_GJ_.odds - 1);
            }
        }
        model.betinfo._CG_.odds = PublicUtils.subStringNum(totalOdd);
        this.setState({odds: model.betinfo._CG_.odds});
        return model.betinfo._CG_.odds;
    };

    render() {
        const footerBarHeight = 55;
        const title = this.state.item.name + " - " + (this.state.playId === 4 ? "过关" : "单式");
        // alert(this.state.ballId+"-"+this.state.betId+"-"+this.state.playId);
        return (
            <Container title={title} showMenuButton={true}
                       menuArray={[{name: "比赛结果", icon: "jqkj", href: 'SportOpenResult'}, {
                           name: "投注记录",
                           icon: "tzjl",
                           href: 'SportBetRecord'
                       }]}
                       style={{backgroundColor: "#fff"}}>
                <div style={{
                    paddingBottom: this.state.playId === 4 ? footerBarHeight : 0,
                    paddingTop: 35
                }}>
                    {this.state.isReady ?
                        <div>
                            <RefreshOddsBox playId={this.state.playId} isReady={this.state.isReady} complete={() => {
                                this._fetchMatchList();
                            }
                            }/>
                            <BetBody ballId={this.state.ballId} betId={this.state.betId} playId={this.state.playId}
                                     item={this.state.matchList[0]} cgOnPress={() => {
                                this.getTotalOdd();
                            }}/></div> : <Loadding/>
                    }
                </div>
                {
                    this._getFooterBar()
                }
            </Container>
        )
    }
}