/**
 * 彩票投注记录
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import MyPicker from "../component/myPicker";
import Icon from '../utils/icon'
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Http from "../services/http";
import Storage from "../services/storage";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import PullEndFooter from "../component/pullEndFooter";
import history from "../services/history";

export default class LotteryBetRecord extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        // this.lotterModel = model.lotteryCodeList;
        // this.is_code = PublicUtils.getState(this.props.location.pathname).code;
        // for (let i = 1; i < model.lotteryCodeList.length; i++) {
        //     if (this.is_code && this.is_code === model.lotteryCodeList[i].value) {
        //         this.lotteryType = model.lotteryCodeList[i];
        //     }
        // }

      this.is_code = model.isid

      let isgl =  model.isgl


      if(!this.is_code){
        this.is_code = isgl[0].id
      }

      let tempList = [];
      for(let i=0;i<isgl.length;i++){

        tempList.push({label: isgl[i].is_name, value: isgl[i].id, name:  isgl[i].tmp.toUpperCase()});

        if(this.is_code == isgl[i].id){

          this.lotteryType=  {label: isgl[i].is_name, value: isgl[i].id, name:  isgl[i].tmp.toUpperCase()}
        }
      }


      this.lotterModel = tempList;




      this.statusTypeModel = [
            {label: '全部', value: '9'},
            {label: '未开奖', value: '0'},
            {label: '已开奖', value: '1'},
            // {label: '已取消', value: '2'}
        ];
        this.dateTypeModel = model.dateType;
        this.state = {
            lotteryType: this.is_code ? this.lotteryType : this.lotterModel[0],
            statusType: this.statusTypeModel[0],
            dateType: this.dateTypeModel[0],
            showFoot: 0, // 控制foot， 0：隐藏footer  1：已加载完成,没有更多数据   2 ：显示加载中
            refreshing: false,//上拉刷新,
            total: 0,//总页数
            currpage: 1,//当前页
            list: [],
            showLotteryTypeFliter: false,
            showDateTypeFliter: false,
            showStatusTypeFliter: false
        };
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchList();
    };

    /**
     * 获取投注记录
     */
    _fetchList() {
        let parm = {
            is_code: this.state.lotteryType.value,
            day: this.state.dateType.value,
            is_status: this.state.statusType.value,
            page: this.state.currpage
        };
        // if (this.state.currpage > 1) {
        //     this.setState({showFoot: 2});
        // } else {
        //     this.setState({refreshing: true});
        // }
        if (this.state.currpage === 1) {
            this.setState({list: []});
        }
        this.setState({showFoot: 2});
        Http.request("getLotteryOrderList", parm, (res) => {
            this.setState({refreshing: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status) {
                    if (this.state.currpage === 1) {
                        this.setState({list: res.data.list, total: res.data.total});
                    } else {
                        this.setState({list: this.state.list.concat(res.data.list), total: res.data.total})
                    }
                    if (this.state.currpage >= res.data.total) {
                        this.setState({showFoot: 1});
                    } else {
                        this.setState({showFoot: 2});
                    }
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    }

    /**
     * 刷新
     * @private
     */
    _onRefresh = () => {
        this.setState({currpage: 1}, () => {
            //获取数据
            this._fetchList();
        });
    };

    _onEndReached() {
        if (this.state.currpage === this.state.total) {
            this.setState({showFoot: 1});
            return;
        }
        this.setState({currpage: this.state.currpage + 1}, () => {
            //获取数据
            this._fetchList();
        });

    }


    /**
     * 查看投注详情
     * @param item
     */
    _showDetail(item) {
        const navParma = {
            matchInfo: item
        };
        PublicUtils.navigate("LotteryBetRecordDetail", navParma);
    }

    _renderBetDetail = (bet_details) => {
        let renderView = null;
        const betDetailsArray = bet_details.split(",");
        for (let i = 0; i < betDetailsArray.length; i++) {

        }
        return renderView
    };

    render() {
        return (
            <Container title={'投注记录'} style={{backgroundColor: "#fff"}}>
                <div style={styles.headerToolbarBox}>
                    <div style={styles.headerToolbar}>
                        <div style={{...styles.headerToolbarItemBtn, ...{justifyContent: "flex-start"}}}
                             onClick={() => {
                                 this.setState({showLotteryTypeFliter: true})
                             }}>
                            <div style={styles.headerToolbarItem}>
                                <div style={styles.headerToolbarItemText}>{this.state.lotteryType.label}</div>
                                <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                            </div>
                        </div>
                        <div style={{...styles.headerToolbarItemBtn, ...{justifyContent: "flex-end"}}} onClick={() => {
                            this.setState({showDateTypeFliter: true})
                        }}>
                            <div style={styles.headerToolbarItem}>
                                <div style={styles.headerToolbarItemText}>{this.state.dateType.label}</div>
                                <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,01)'}/>
                            </div>
                        </div>
                        <div style={{...styles.headerToolbarItemBtn, ...{justifyContent: "flex-end"}}} onClick={() => {
                            this.setState({showStatusTypeFliter: true})
                        }}>
                            <div style={styles.headerToolbarItem}>
                                <div style={styles.headerToolbarItemText}>{this.state.statusType.label}</div>
                                <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,1)'}/>
                            </div>
                        </div>
                    </div>
                    <div style={styles.titleBar}>
                        <div style={styles.titleBarItemLeft}>投注内容</div>
                        <div style={styles.titleBarItemMid}>投注金额</div>
                        <div style={styles.titleBarItemRight}>输赢结果</div>
                    </div>
                </div>

                <div style={styles.betListBody}>
                    {
                        this.state.list.map((item, key) => {
                            return (
                                <div key={key} onClick={() => {
                                    this._showDetail(item);
                                }} style={styles.listItemBox}>
                                    {/*<View style={styles.iconBox}>*/}
                                    {/*{*/}
                                    {/*this._renderSportLogo(item.type_name)*/}
                                    {/*}*/}
                                    {/*{*/}
                                    {/*item.bet_type === 1 &&*/}
                                    {/*<Text style={styles.gq}>[滚球]</Text>*/}
                                    {/*}*/}
                                    {/*</View>*/}
                                    <div style={styles.listItemRight}>
                                        <div style={styles.betDetailBox}>

                                            <div style={{...styles.betContentRow}}>
                                                <div
                                                    style={{...styles.textSpace}}>{item.lottery_no}
                                                </div>
                                            </div>
                                            <div style={{...styles.betContentRow, ...styles.betType}}>
                                                <div
                                                    style={{...styles.textSpace, ...styles.betMatchName}}>{item.lottery_code}</div>
                                                <div
                                                    style={{...styles.textSpace}}>[{item.bet_class}]
                                                </div>
                                            </div>
                                            <div style={{...styles.betContentRow, ...styles.betType}}>
                                                <div
                                                    style={{
                                                        ...styles.color1, ...styles.textSpace, ...{}
                                                    }}>
                                                    {
                                                        item.bet_details.split(",").map((bet_detailsitem) => {
                                                            return (
                                                                <div style={{
                                                                    padding: "0 5px",
                                                                    height: 22,
                                                                    lineHeight: "22px",
                                                                    textAlign: "center",
                                                                    backgroundColor: "#ff3336",
                                                                    color: "#ffff",
                                                                    borderRadius: "3px",
                                                                    marginRight: 3,
                                                                    display: "inline-block",
                                                                    marginBottom: 3,
                                                                    fontSize: 12
                                                                }}>{bet_detailsitem}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div style={{...styles.betContentRow, ...{marginBottom: 0}}}>
                                                <div
                                                    style={{...styles.textSpace, ...styles.betTime}}>{PublicUtils.timestampFormat(item.add_time)}</div>
                                            </div>
                                        </div>
                                        <div style={styles.betAmountBox}>
                                            <div
                                                style={styles.betAmountText}>{PublicUtils.subStringNum(item.bet_amount)}</div>
                                        </div>
                                        <div style={styles.betAmountResult}>
                                            <div
                                                style={{...styles.betAmountText, ...item.bet_win !== "未结算" ? Number(item.bet_win) >= 0 ? styles.win : styles.lose : styles.notopen}}>{item.bet_win}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <PullEndFooter list={this.state.list} showFoot={this.state.showFoot} onEndReached={() => {
                        this._onEndReached();
                    }}/>
                </div>
                <MyPicker
                    title={'彩种'}
                    model={this.lotterModel}
                    display={this.state.showLotteryTypeFliter}
                    onHiden={() => {
                        this.setState({showLotteryTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({lotteryType: item.value[0].value, showLotteryTypeFliter: false, currpage: 1}, () => {
                            this._fetchList();
                        });
                    }}
                />
                <MyPicker
                    title={'日期'}
                    model={this.dateTypeModel}
                    display={this.state.showDateTypeFliter}
                    onHiden={() => {
                        this.setState({showDateTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({dateType: item.value[0].value, showDateTypeFliter: false, currpage: 1}, () => {
                            this._fetchList();
                        });
                    }}
                />
                <MyPicker
                    title={'状态'}
                    model={this.statusTypeModel}
                    display={this.state.showStatusTypeFliter}
                    onHiden={() => {
                        this.setState({showStatusTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({statusType: item.value[0].value, showStatusTypeFliter: false, currpage: 1}, () => {
                            this._fetchList();
                        });
                    }}
                />
            </Container>
        )
    }
}
const styles = {
    headerToolbarBox: {
        position: "fixed",
        left: 0,
        right: 0,
        zIndex:1
    },
    headerToolbar: {
        display: "flex",
        flexDirection: "row",
        borderBottom: '1px solid #f5f7f9',
        backgroundColor: "#f5f7f9",
        fontSize: 14
    },
    headerToolbarItemBtn: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 50
    },
    headerToolbarItem: {
        display: "flex",
        paddingLeft: 10,
        paddingRight: 10,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    headerToolbarItemText: {
        marginRight: 5,
        color: "rgba(0,0,0,1)"
    },
    betListBody: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 91
    },
    listItemBox: {
        display: "flex",
        paddingLeft: 10,
        paddingTop: 10,
        paddingRight: 10,
        flexDirection: "row",
        alignItems: "stretch",
        fontSize: 14
    },
    gq: {
        color: "#b0b0b0",
        fontSize: 12
    },
    iconBox: {
        width: 50,
        alignItems: "center",
    },
    listItemRight: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        paddingBottom: 10,
        justifyContent: "space-between",
        alignItems: "stretch",
        borderBottom: '1px solid #f5f7f9',
    },
    betDetailBox: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
    },
    betAmountBox: {
        display: "flex",
        justifyContent: "center",
        flex: 2,
    },
    betAmountResult: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        flex: 2,
    },
    betAmountText: {
        fontSize: 18,
        color: "rgba(0,0,0,1)",
    },
    betContentRow: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 5
    },
    betMatchName: {
        color: "#000",
        fontSize: 14
    },
    fontBlod: {},
    color4: {
        color: "#f55075"
    },
    color3: {
        color: "#37aef5"
    },
    betType: {},
    color1: {
        color: "#25a42a"
    },
    betTime: {
        color: "rgba(0,0,0,0.3)",
        fontSize: 13
    },
    footer: {
        display: "flex",
        flexDirection: "column"
    },
    footerBody: {
        display: "flex",
        height: 50,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    footerBodyText: {
        fontSize: 14,
        color: "#bbbbbb"
    },
    footerPadding: {
        height: 50
    },
    textSpace: {
        marginRight: 3
    },
    win: {backgroundColor: "#ff353b", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
    lose: {backgroundColor: "#1dc315", color: "#fff", padding: "3px 5px", borderRadius: 3, fontSize: 16},
    notopen: {
        backgroundColor: "#1dc315",
        color: "#fff",
        padding: "3px 5px",
        borderRadius: 3,
        fontSize: 16,
    },
    titleBar: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#eff1f3",
        padding: "10px 0",
        fontSize: 16
    },
    titleBarItem: {},
    titleBarItemLeft: {
        flex: 3,
        paddingLeft: 10
    },
    titleBarItemMid: {
        flex: 2,
        textAlign: "center"
    },
    titleBarItemRight: {
        flex: 2,
        paddingRight: 10,
        textAlign: "right"
    },
};
