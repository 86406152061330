//菜单栏

import React, {Component} from 'react';
import MyInput from "../../../component/myInput";
import style from "../../../utils/config.scss";
import model from "../../../services/model";
import ActivityIndicator from "../../../component/activityIndicator";
import Http from "../../../services/http";
import Storage from "../../../services/storage";
import PublicUtils from "../../../utils/publicUtils";
import global from "../../../global";

class Dialog extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            tips: "",
            money: ''
        }
    }

    static propTypes = {};
    static defaultProps = {};

    /**
     * 提交
     * @private
     */
    _submit = () => {
        if (!this.state.money) {
            this.setState({tips: "请输入转账金额"});
            return;
        } else {
            this.setState({tips: '', showProgress: true});
        }
        const parm = {
            money: this.state.money,
            type: this.props.type
        };
        Http.request("YEBTransfer", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code == 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code == 200) {
                if (res.data.status) {
                    // this.setState({info: res.data.data});
                    this.props.onSuccess && this.props.onSuccess();
                } else {
                    this.setState({tips: res.message});
                }
            }
        });
    }

    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator/>;
    };

    render() {
        console.log(this.props)
        const styles = {
            container: {
                position: "fixed",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                backgroundColor: "rgba(0,0,0,0.3)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                zIndex: 10,
            },
            bodyContainer: {
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                width: "80%",
                borderRadius: 4,
                overflow: "hidden"
            },
            bodyTitleContainer: {
                height: 35,
                display: "flex",
                paddingLeft: 10,
                alignItems: "center",
                background: "linear-gradient(to left, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")",
                fontSize: 14,
                color: "#fff",
                overflowY: "auto"
            },
            bodyContentContainer: {
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
            },
            bodyContentTipsContainer: {
                fontSize: 12,
                paddingTop: 5,
                paddingBottom: 5,
                color: "#ff6264"
            },
            betAmountInputWrapper: {
                border: "1px solid #f1f3f5",
                backgroundColor: "#f5f7f9",
                height: 35,
                flex: 1,
                borderRadius: 4,
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
            },
            betAmountInput: {
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            bodyBottomContainer: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            bodyBottomLeftBtnContainer: {
                marginLeft: 10,
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: style['primaryColorLight' + model.SKINNAME],
                color: style['primaryColor' + model.SKINNAME],
                height: 35,
                fontSize: 14
            },
            bodyBottomRightBtnContainer: {
                marginLeft: 10,
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                color: "#fff",
                height: 35,
                fontSize: 14
            },

        }
        return (
          <div style={styles.container}>
              {
                  global.ID == "y5" || global.ID == "y6"?
                    <div style={styles.bodyContainer} style={{width:'90%', height:"400px",overflow:"auto","background":"#fff"}}>
                        <div style={styles.bodyTitleContainer}>
                            <span>{this.props.type === 1 ? '转入余额宝' : '转出余额宝'}</span>
                        </div>
                        <div style={styles.bodyContentContainer}>
                            <div style={styles.bodyContentTipsContainer}>{this.state.tips}</div>
                            <div style={styles.betAmountInputWrapper}>
                                <MyInput
                                  value={this.state.money}
                                  placeholder={'请输入转账金额'}
                                  style={styles.betAmountInput}
                                  onChangeText={(text) => {
                                      this.setState({money: text.replace(/\D/g, '')});
                                  }}
                                />
                            </div>
                        </div>
                        <div style={{padding:"0 20px"}}>
                            <h4 style={{margin:'8px 0'}}>说明：</h4>
                            <div style={{marginBottom:'24px'}} dangerouslySetInnerHTML={{__html:'<span style="margin-right: 10px"></span>'+this.props.info.description}}></div>
                        </div>
                        <div style={styles.bodyBottomContainer}>
                            <div style={styles.bodyBottomLeftBtnContainer} onClick={() => {
                                this.props.onCancel && this.props.onCancel();
                            }}>
                                <span>取消</span>
                            </div>
                            <div style={styles.bodyBottomRightBtnContainer} onClick={() => {
                                this._submit();
                            }}>
                                {this.state.showProgress ? this._showProgress() : <span>确定</span>}

                            </div>
                        </div>
                    </div>:
                    <div style={styles.bodyContainer}>
                        <div style={styles.bodyTitleContainer}>
                            <span>{this.props.type === 1 ? '转入余额宝' : '转出余额宝'}</span>
                        </div>
                        <div style={styles.bodyContentContainer}>
                            <div style={styles.bodyContentTipsContainer}>{this.state.tips}</div>
                            <div style={styles.betAmountInputWrapper}>
                                <MyInput
                                  value={this.state.money}
                                  placeholder={'请输入转账金额'}
                                  style={styles.betAmountInput}
                                  onChangeText={(text) => {
                                      this.setState({money: text.replace(/\D/g, '')});
                                  }}
                                />
                            </div>
                        </div>
                        <div style={styles.bodyBottomContainer}>
                            <div style={styles.bodyBottomLeftBtnContainer} onClick={() => {
                                this.props.onCancel && this.props.onCancel();
                            }}>
                                <span>取消</span>
                            </div>
                            <div style={styles.bodyBottomRightBtnContainer} onClick={() => {
                                this._submit();
                            }}>
                                {this.state.showProgress ? this._showProgress() : <span>确定</span>}

                            </div>
                        </div>
                    </div>
              }

          </div>
        )
    }
}

export default Dialog;
