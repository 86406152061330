/**
 * API服务类
 */
import React, {Component,} from 'react';
import global from '../global';
import Storage from "../services/storage";
import PublicUtils from "../utils/publicUtils";
import Login from "../pages/login";
import promise from 'es6-promise';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

/**
 *
 * @type {{getRegisterFrom: *[请求名称,请求方式,是否需要token,是否跳转到登录界面], register: *[], login: *[], loginOut: *[], getNotice: *[], getMainPageData: *[], postAgent: *[], queryBalance: *[], transferBalance: *[], gameIn: *[], getMessage: *[], readMessage: *[], deleteMessage: *[], memberChange: *[], activity: *[], activityShow: *[], hot: *[], recentGames: *[], service: *[], online: *[], lotteryTime: *[], getLotteryOdds: *[], getLotteryOpen: *[], lotteryNumberList: *[], betting: *[], getLotteryOrderList: *[], getSingleLotteryOrder: *[], getSportsMatch: *[], sportBetting: *[], SportsGetOrder: *[], SportsGetResult: *[], memberInfo: *[], memberEdit: *[], updateLoginPassword: *[], updateSafePassword: *[], getBankInfo: *[], getWithdrawInfo: *[], Withdraw: *[], MemberDeposit: *[], PostMemberDeposit: *[], MemberDepositShow: *[], agentUserList: *[], agentUserTransfer: *[], memberBalance: *[], memberGameReport: *[], agentReport: *[], agentReportGame: *[], memberGameList: *[]}}
 */

class Http extends Component {
// 构造
    constructor(props) {
        super(props);
    }

    //接口别名 请求方式 是否需要用户token 是否允许中断请求
    static apiArray = {
        //获取注册需要提交的字段
        getRegisterFrom: ["register", "GET", false],
        //注册
        register: ['register', "POST", false],
        // 添加代理会员
        addAgentMember: ['agent/add_user', "POST", true],
        //注册  获取 验证码接口
        regCode: ['reg-code', "POST", false],
        //检测用户名是否存在
        valid: ['valid', "POST", false],
        login: ['login-check', "POST", false],
        //退出登录
        loginOut: ["login-out", "GET", true],
        //公告
        getNotice: ["notice", "GET", false],
        //首页数据-推荐游戏
        getMainPageData: ["main", "GET", false],
        //提交代理id
        postAgent: ["is-agent", "GET", false],
        //查询额度
        queryBalance: ["query-balance", "POST", true],
        //账户转账
        transferBalance: ["transfer-balance", "POST", true],
        //进入游戏
        gameIn: ["login-game", "GET", true],
        //获取站内消息
        getMessage: ["member-message", "GET", true],
        //阅读站内消息
        readMessage: ["member-message", "POST", true],
        //删除站内消息
        deleteMessage: ["member-message-del", "POST", true],
        //获取账变记录
        memberChange: ["member-change", "GET", true],
        //获取活动列表
        activity: ["activity", "GET", false],
        //获取活动列表
        activityShow: ["activity-show", "GET", false],
        //获取单个活动
        hot: ["hot", "GET", true],
        //获取近期玩过的游戏
        recentGames: ["play-record", "GET", true],
        //客服
        service: ["service_mobile", "GET", false],
        //查询/更新会员活动状态
        online: ["online", "GET", true],
        /*---------------彩票--------------*/
        //获取当前期数和封盘时间
        lotteryTime: ["lottery-get-time", "GET", true],
        //获取赔率
        getLotteryOdds: ["lottery-get-odds", "GET", true],
        //获取开奖号码
        getLotteryOpen: ["lottery-get-open", "GET", true],
        //获取开奖记录
        lotteryNumberList: ["lottery-number-list", "GET", false],
        //提交下注
        betting: ["lottery-betting", "POST", true, true],
        //获取投注记录
        getLotteryOrderList: ["lottery-order-list", "GET", true],
        //获取投注详情
        getSingleLotteryOrder: ["lottery-order-list", "POST", true],
        /*---------------体育--------------*/
        //获取赛事
        getSportsMatch: ["sports-get-match", "GET", false, true],
        //提交下注
        sportBetting: ["sports-betting", "POST", true],
        //提交下注
        sportMaxBetAmount: ["sports-betting", "GET", true],
        //获取体育投注记录
        SportsGetOrder: ["sports-get-order", "GET", true],
        //获取体育投注记录
        SportsGetResult: ["sports-get-result", "GET", true],
        /*---------------会员资料--------------*/
        //获取会员资料
        memberInfo: ["member-info", "GET", true],
        //修改会员资料
        memberEdit: ["member-edit", "POST", true],
        //修改会员登录密码
        updateLoginPassword: ["login-password", "POST", true],
        //修改会员取款密码
        updateSafePassword: ["safe-password", "POST", true],
        //获取会员银行卡信息
        getBankInfo: ["member-edit", "GET", true],
        //获取提款信息
        getWithdrawInfo: ["member-withdraw", "GET", true],
        //提款
        Withdraw: ["member-withdraw", "POST", true],
        //充值渠道
        MemberDeposit: ["member-deposit", "GET", true],
        //提交充值信息
        PostMemberDeposit: ["member-deposit", "POST", true],
        //充值渠道详情
        MemberDepositShow: ["member-deposit-show", "GET", true],
        //下级会员列表
        agentUserList: ["agent-user-list", "GET", true],
        agentUserList2: ["agent-user/index", "GET", true],
        //下级会员充值
        agentUserTransfer: ["agent-user-transfer", "POST", true],
        //获取余额
        memberBalance: ["member-balance", "GET", true],
        /*---------------报表--------------*/
        memberGameReport: ["member-game-report", "GET", true],
        //代理报表
        agentReport: ["agent-report", "GET", true],
        // 无限级代理报表
        agentReport2: ["agent-salary/index", "GET", true],
        // 无限级代理报表会员流水
        agentReport3: ["agent-salary/subSalary", "GET", true],
        //下级代理报表
        agentReportGame: ["agent-report-game", "GET", true],
        //获取游戏平台列表
        memberGameList: ["member-game-list", "GET", true],
        //找回密码
        mobileChange: ["change", "POST", false],
        //等级列表
        grade: ["grade", "GET", true],
        //积分变动记录
        score: ["score", "GET", true],
        //升级
        upgrade: ["grade-upgrade", "GET", true],
        //代理申请
        agentCheck: ["agent-check", "POST", true],
        //升级
        qrcode: ["qrcode", "GET", true],
        //生日彩金
        gradeLottery: ["grade-lottery", "GET", true],
        //获取在线充值渠道
        getUserPayment: ["get_user_payment", "POST", true],
        //获取在线充值渠道详情
        getPaymentInfo: ["get_payment_info", "POST", true],
        //提交在线充值请求
        getPaymentUrl: ["get_payment_url", "POST", true],
        //彩金列表接口
        memberBonusShow: ["member-bonus-show", "POST", true],
        //领取彩金接口
        memberBonusHandle: ["member-bonus-handle", "POST", true],
        //显示活动列表
        memberKuisunShow: ["member-kuisun-show", "POST", true],
        //领取金额
        memberKuisunHandle: ["member-kuisun-handle", "POST", true],
        //领取记录
        memberKuisunRecord: ["member-kuisun-record", "POST", true],
        //获取彩种接口
        lotteryLhcList: ["lottery-lhc-list", "GET", true],
        //获取注册短信验证码
        regMsgCode: ["reg-send", "POST", false],
        //获取修改密码短信验证码
        memberEditMsgCode: ["member-send", "POST", true],
        //查询是否需要发送短信验证码
        sendInfo: ["send-info", "GET", true],
        //请求验证码
        verify: ["verify", "GET", true],
        //请求验证码
        tnCode: ["tn_code", "GET", true],
        //余额宝配置
        YEBConfig: ["yuebao/config", "GET", true],
        //余额宝账变
        YEBRecord: ["yuebao", "GET", true],
        //余额宝出入金
        YEBTransfer: ["yuebao", "POST", true],
        //是否显示余额宝
        YEBAccess: ["/yuebao/access", "GET", true],
        //享有特权
        specil: ["member-specil", "GET", true],
        // 免提直充开关
        mtzc: ["withdrawDepositShow", "GET", true],
        // 免提直充充值
        mtzc2: ["withdrawDeposit", "POST", true],
        // 获取用户签到信息
        qdxx:["sign", "GET", true],
        // 点击签到
        djqd:["sign", "POST", true],
        //
        qdxq:["sign/read", "GET", true],
    };

    static timeout = 7000;

    static apiIndex = 0;

    static errinfo = [
        [1, "网络异常,请稍后重试"],
        [2, "请求超时,请稍后重试"],
    ]

    /**
     * 发送请求
     * @param name
     * @param body
     * @param successCallback
     * @returns {Promise<返回Promise>}
     */
    static request = async (name, body?: Object, callback) => {
        if (window.controller) {
            // window.controller.abort();
        }
        if (AbortController) {
            window.controller = new AbortController();
        }
        Http.fetchRequest(name, body, callback, Http.apiIndex)
    };
    static getUrl = (name) => {
        const line = Storage.retrieveData("line") && JSON.parse(Storage.retrieveData("line"));
        let url = "";
        const APP_API_URL = line || global.APP_API_URL;
        if (APP_API_URL.type === 1) {
            url = APP_API_URL.url + global.ID + "/" + name;
        } else {
            url = APP_API_URL.url + "mobile/" + name;
        }
        // const url = line ? line.url : "https://twpubapi.7171r.com/";
        return url;
    }

    static fetchRequest = async (name, body?: Object, callback, apiIndex) => {
        let url = await Http.getUrl(Http.apiArray[name][0]);
        const headers = new Headers({
            // "Content-Type": 'application/json',
            // "Accept": 'application/json',
            // "Origin": '*',
            // "Sec-Fetch-Dest": "empty",
            // "Sec-Fetch-Mode": "cors",
            // "Sec-Fetch-Site": "cross-site"
            // "Access-Control-Allow-Origin": '*'
        });
        const defaultOption = {
            method: Http.apiArray[name][1],
            mode: 'cors',
            headers
            // headers: {
            //     Origin: url,
            //     Referer: url
            //     // 'Access-Control-Allow-Origin':'*',
            //     // 'Access-Control-Allow-Methods':'*',
            //     // 'Access-Control-Allow-Header':'x-requested-with,content-type,Accept',
            // }
            // signal: window.controller.signal,
        };
        if (Http.apiArray[name][3]) {
            defaultOption.signal = window.controller.signal;
        }
        // let url = global.APP_API_URL[apiIndex] + global.ID + "/" + Http.apiArray[name][0];
        if (body && Http.apiArray[name][1] === "POST" && Object.keys(body).length) {
            let formData = new FormData();
            for (let item in body) {
                formData.append(item, body[item]);
            }
// alert(JSON.stringify(formData));
            defaultOption.body = formData;
        }

        if (Http.apiArray[name][2]) {
            const token = await Storage.retrieveData("token");
            if (!token && Http.apiArray[name][3]) {
                PublicUtils.replaceNavigate("Login");
                callback({code: 500});
                return;
            } else {
                url += "?token=" + token;
            }
            // url += "?token=" + await Storage.retrieveData("token");
        }
        if (body && Http.apiArray[name][1] === "GET" && Object.keys(body).length) {
            let paramsArray = [];
            //拼接参数
            Object.keys(body).forEach(key => paramsArray.push(key + '=' + body[key]));
            if (url.search(/\?/) === -1) {
                url += '?' + paramsArray.join('&')
            } else {
                url += '&' + paramsArray.join('&')
            }
        }
        let timer;
        new promise((resolve, reject) => {
            fetch(url, defaultOption)
                .then((response) => {
                    // console.log(response);
                    // alert(JSON.stringify(response));
                    // return response.json()
                    if (response.ok) {
                        return response.json()
                    } else {
                        return Promise.reject({
                            status: response.status,
                            statusText: response.statusText
                        })
                    }
                })
                .then((responseJson) => {
                    // console.log(responseJson);
                    if (responseJson.code === 800) {
                        PublicUtils.showToast(responseJson.message, {type: "FAILD"});
                    } else {
                        resolve(responseJson);
                    }
                })
                .catch((error) => {
                    console.log(error);

                    if(error.message){
                        if (error.message.indexOf('aborted') === -1) {
                            // callback({data: {status: 0, message: "网络异常,正在切换线路"}});
                            reject(Http.errinfo[0]);
                        }
                    }else{
                        reject(Http.errinfo[0]);
                    }

                });
            timer = setTimeout(() => {
                reject(Http.errinfo[0]);
            }, Http.timeout);
        })
            //成功
            .then((responseJson) => {
                    clearTimeout(timer);
                    callback(responseJson);
                },
            )
            //失败
            .catch((error) => {
                console.log(error);
                if (error[1]) {
                    PublicUtils.getBestUrl((res) => {
                        if (res.status) {
                            if (Http.apiArray[name][1] === "GET") {
                                setTimeout(()=>{
                                    Http.fetchRequest(name, body, callback, apiIndex);
                                }, 4000)
                            }
                        } else {
                            PublicUtils.showToast(res.msg, {type: "FAILD"});
                        }
                    });
                    // apiIndex++;
                    // if (apiIndex < 4) {
                    //     Http.fetchRequest(name, body, callback, apiIndex);
                    // } else {
                    //     PublicUtils.showToast(error[1]);
                    // }
                    // if (Http.apiIndex === global.APP_API_URL.length - 1) {
                    //     Http.apiIndex = 0;
                    // } else {
                    //     Http.apiIndex++;
                    // }
                    // clearTimeout(timer);
                    // PublicUtils.showToast(error[1]);
                    // // const delayTimer = setTimeout(() => {
                    // //     clearTimeout(delayTimer);
                    // //     Http.fetchRequest(name, body, callback, Http.apiIndex);
                    // // }, 2000);
                    // callback({data: {status: 0, message: error[1]}});
                }
            });
    };


    /**
     * 请求json文件
     */
    static requestJSON = (url, callback) => {
        let timer;
        new Promise((resolve, reject) => {
            fetch(url)
                .then((response) => {
                    // console.log(JSON.stringify(response));
                    return response.json()
                })
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {

                    setTimeout(()=>{
                        callback({data: {status: 0, message: "网络异常，请稍后重试"}});
                        reject("网络异常，请稍后重试");
                    }, 4000)

                    // console.log(JSON.stringify(error));

                });
            timer = setTimeout(() => {
                reject("请求超时，请稍后重试！");
            }, Http.timeout);
        })
            //成功
            .then((responseJson) => {
                    clearTimeout(timer);
                    callback(responseJson);
                },
            )
            //失败
            .catch((error) => {
                clearTimeout(timer);
                PublicUtils.showToast(error, {type: "FAILD"});
                setTimeout(()=>{
                    callback({data: {status: 0, message: error}});
                }, 4000)
            });
    }

}

export default Http;
