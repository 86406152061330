//充值渠道
import React, {Component} from 'react';
import Container from "../component/container";
import Loadding from "../component/loadding";
import NoneData from "../component/noneData";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Icon from '../utils/icon'
import style from '../utils/config.scss'
import Image from '../component/image'
import global from '../global'

export default class RechargeChannel extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);

        let tt = 10;

        let clv = 2;
        if(global.ID == 'ca'){
            clv = 4
        }

      if(global.ID == 'ai' || global.ID == 'bk' || global.ID == 'js'){
        clv = 4
      }



      this.state = {
            channel: clv,
            showProgress: false,
            channelList: [],
            onlineChannelList: [],
            virtualCurrencyChannelList:[],
            isqb:false,
        }



    }

    static navigationOptions = ({navigation}) => {
        return {}
    };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        // this._fetchRechargeChannel();
        this._changeChannel(this.state.channel);
        this._FetchvirtualCurrencyChannel();

    };
    /**
     * 切换充值类型
     * @param type
     * @private
     */
    _changeChannel = (type) => {
        this.setState({channel: type}, () => {
            if (this.state.channel === 1) {
                this._fetchOnlineRechargeChannel();
            }
            if (this.state.channel === 2) {
                this._fetchRechargeChannel();
            }

            if (this.state.channel === 4) {
                this._fetchOnlineRechargeChannel();
            }
        });
    };
    /**
     * 获取充值渠道
     * @private
     */
    _fetchRechargeChannel = () => {
        this.setState({showProgress: true});
        let params: any = {is_pay: this.state.channel};
        Http.request("MemberDeposit", params, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Rechargechannel'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    this.setState({channelList: res.data.bank});
                    // switch (this.state.channel) {
                    //     case 1:
                    //         this.setState({channelList: res.data.pay});
                    //         break;
                    //     case 2:
                    //         this.setState({channelList: res.data.bank});
                    //         break;
                    // }
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    };

    _FetchvirtualCurrencyChannel = async ()=>{



        let tempArr = [];
        let params = {};

        this.setState({showProgress: true});

        let self = this;

        await Http.request("getUserPayment", params, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Rechargechannel'});
            }
            if (res.code === 200) {

                if (res.data.status === 1) {




                    res.data.info.forEach(ele=>{

                        if(ele.is_wallet){
                            self.setState({isqb: true});
                        }

                        if(global.ID == 'fl'){
                            if(ele.payName.indexOf("虚拟")!==-1 || ele.payName.indexOf("数字")!=-1 || ele.payName.indexOf("USDT")!=-1 ){
                                ele.istype = 2;
                                tempArr.push(ele);
                            }
                        }else{
                            if(ele.payName.indexOf("虚拟")!==-1||ele.payName.indexOf("USDT")!=-1 || ele.payName.indexOf("数字")!=-1){
                                ele.istype = 2;
                                tempArr.push(ele);
                            }
                        }

                    })


                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });

        let params2= {is_pay: this.state.channel};
        await Http.request("MemberDeposit", params2, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Rechargechannel'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    res.data.bank.forEach(item=>{
                        if(item.is_type == 5 ){
                            tempArr.push(item)
                        }
                    })

                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });

       this.setState({virtualCurrencyChannelList:tempArr});


    }
    /**
     * 获取在线充值渠道
     * @private
     */
    _fetchOnlineRechargeChannel = () => {
        this.setState({showProgress: true});
        let params: any = {};
        Http.request("getUserPayment", params, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Rechargechannel'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    this.setState({onlineChannelList: res.data.info});
                } else {
                    PublicUtils.showToast(res.message);
                }
            }
        });
    };
    /**
     * 充值渠道图标
     * @param type
     * @returns {*}
     * @private
     */
    _getChannelItemIcon = (type,pname) => {

        let icon = null;
        const styles = {
            logoImg: {
                width: 45,
                height: 45
            }
        }
        if (this.state.channel === 2 ) {
            switch (type) {
                case 0:
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;
                    break;
                case 1:
                    // icon = 'alipay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz2.jpg')}/>;
                    break;
                case 2:
                    // icon = 'weixinpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz3.jpg')}/>;
                    break;
                case 3:
                    // icon = 'qqpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz4.jpg')}/>;
                    break;
                case 4:
                    // icon = 'qqpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz7.png')}/>;
                    break;
                case 5:
                    // icon = 'qqpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz8.png')}/>;
                    break;
                default:
                    // icon = 'qqpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz6.jpg')}/>;
                    break;
            }
        }

        if (this.state.channel === 1 || this.state.channel === 4) {

            // 支付宝图标
            if(type.indexOf('ALIPAY') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={require('../assets/images/theme1/cz2.jpg')}/>;
            }else if(type.indexOf('WECHAT') != -1){
                // 微信图标
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={require('../assets/images/theme1/cz3.jpg')}/>;
            }else{
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={require('../assets/images/theme1/cz1.jpg')}/>;
            }







            switch (type) {

                case 'QQ_H5':
                    // icon = 'qqpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz4.jpg')}/>;
                    break;
                case 'JD_H5':
                    // icon = 'jdpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz5.jpg')}/>;
                    break;
                case 'BAIDU_H5':
                    // icon = 'baidupay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz5.jpg')}/>;
                    break;
                case 'UNIONPAY_SHORTCUT':
                case 'CARD_TO_CARD':
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;
                    break;
                case 'BANK':
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;
                    break;

                case 'ALIPAY_SCAN':
                    // icon = 'alipay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz2.jpg')}/>;
                    break;
                case 'QQ_SCAN':
                    // icon = 'qqpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz4.jpg')}/>;
                    break;
                case 'UNIONPAY_SCAN':
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;
                    break;
                case 'JD_SCAN':
                    // icon = 'jdpay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz5.jpg')}/>;
                    break;
                case 'BAIDU_SCAN':
                    // icon = 'baidupay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz5.jpg')}/>;
                    break;
                case 'QUICK_CHARGE':
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;
                    break;
                case 'ALIPAY_WAP':
                    // icon = 'alipay';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz2.jpg')}/>;
                    break;

                case 'UNIONPAY_WAP':
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;
                    break;
                case 'UNIONPAY_H5':
                    // icon = 'bank16';
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={require('../assets/images/theme1/cz1.jpg')}/>;

            }





            if(global.ID == "ca"){
                // if(pname == '微信扫码' || pname == '微信大额' || pname == '微信截图扫码大额' || pname == '支付宝扫码（APP扫码）'){
                //     // 微信图标
                //     icon = <Image style={styles.logoImg} resizeMode='contain'
                //                   uri={require('../assets/images/theme1/cz3.jpg')}/>;
                // }

                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={require('../assets/images/theme1/zxzf.png')}/>;
            }


            if(global.ID == 'yy'){
                if(pname.indexOf('GoPay') != -1){
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={'https://appnew.hdyb33.com/code/yy/gg.jpg'}/>;
                }

              if(pname.indexOf('C币钱包') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/cbqb.png'}/>;
              }

              if(pname.indexOf('QT钱包') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/qtqb.jpg'}/>;
              }

              if(pname.indexOf('988钱包') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/988.jpg'}/>;
              }


              if(pname.indexOf('OkPay') != -1){
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={'https://appnew.hdyb33.com/code/yy/okpay.jpg'}/>;
                }

              if(pname.indexOf('爱博钱包') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/abpay.jpg'}/>;
              }

                if(pname.indexOf('Topay') != -1 || pname.indexOf('ToPay') != -1){

                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={'https://appnew.hdyb33.com/code/yy/topay.jpg'}/>;


                }


              if(pname.indexOf('K豆钱包') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/kdpay.jpg'}/>;
              }



              if(pname.indexOf('人民币') != -1 || pname.indexOf('7-20') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/szhb.png'}/>;
              }


              if( pname.indexOf('7-05') != -1 ||  pname.indexOf('539') != -1 ){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/xnb.png'}/>;
              }


              if( pname.indexOf('901') != -1){
                icon = <Image style={styles.logoImg} resizeMode='contain'
                              uri={'https://appnew.hdyb33.com/code/yy/yli.png'}/>;
              }

            }


            if(global.ID == 'b6'){
                if(pname.indexOf('okpay') != -1){
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={'https://newpic.sdcrhb.com/b6/okpay.jpg'}/>;
                }



            }



          if(global.ID == 'b3' || global.ID == 'b5'){
            if(pname.indexOf('抖音扫码') != -1){
              icon = <Image style={styles.logoImg} resizeMode='contain'
                            uri={'https://newpic.sdcrhb.com/b3/dysm.jpg'}/>;
            }



          }




        }

        if(this.state.channel ===3){

            icon = <Image style={styles.logoImg} resizeMode='contain'
                          uri={require('../assets/images/theme1/cz8.png')}/>


            if(global.ID == 'b6' && pname){
                if(pname.indexOf('okpay') != -1){
                    icon = <Image style={styles.logoImg} resizeMode='contain'
                                  uri={'https://newpic.sdcrhb.com/b6/okpay.jpg'}/>;
                }



            }


          if(global.ID == 'yy'){
            icon = <Image style={styles.logoImg} resizeMode='contain'
                          uri={'https://appnew.hdyb33.com/code/yy/usdt.jpg'}/>;
          }


        }

        return icon;
    };
    /**
     * 充值详情
     * @private
     */
    _rechargeDetail = async (item) => {

        if(this.state.channel == 3){

            if(item.istype){
                item.is_pay = 1;
            }else{
                item.is_pay = 2;
            }
        }else{



            item.is_pay = this.state.channel;

        }
        let param = {param: item};
        if (await PublicUtils._checkLogin()) {
            if (item.payTag === "QUICK_CHARGE") {
                PublicUtils.linking(item.url, "_blank");
            } else {
                PublicUtils.navigate("RechargeDetail", param);
            }
        } else {
            PublicUtils.navigate('Login', {nextPage: 'RechargeChannel'});
        }
    };

    // 钱包
    _rechargeDetail3 = async (item) => {

        item.is_pay = 1;

        let param = {param: item};
        if (await PublicUtils._checkLogin()) {
            if (item.payTag === "QUICK_CHARGE") {
                PublicUtils.linking(item.url, "_blank");
            } else {
                PublicUtils.navigate("RechargeDetail", param);
            }
        } else {
            PublicUtils.navigate('Login', {nextPage: 'RechargeChannel'});
        }
    };


    _rechargeDetail2 = async (item) => {
        if(this.state.channel == 3){
            if(item.payName && item.payName.indexOf("虚拟")!== -1 ){
                item.is_pay = 1;
            }else{
                item.is_pay = 2;
            }
        }else{
            item.is_pay = this.state.channel;
        }
        let param = {param: item};
        if (await PublicUtils._checkLogin()) {
            if (item.payTag === "QUICK_CHARGE") {
                PublicUtils.linking(item.url, "_blank");
            } else {
                PublicUtils.navigate("RechargeDetail2", param);
            }
        } else {
            PublicUtils.navigate('Login', {nextPage: 'RechargeChannel'});
        }
    };
    /**
     * （视图）充值渠道列表
     * @returns {*}
     * @private
     */
    _buildListView = () => {

        const styles = {
            rechargeChannel: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "#f5f7f9",
                borderBottom: "1px solid #f5f7f9",
                height: 50,
                alignItems: "center"
            },
            rechargeChannelItem: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            rechargeChannelItemText: {
                display: "flex",
                fontSize: 16,
                color: "#333",
                textAlign: "center"
            },
            activeRechargeChanel: {
                color: style['primaryColor' + model.SKINNAME]
            },
            channelItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10
            },
            channelItemLeft: {
                display: "flex",
                paddingRight: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            channelItemMiddle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start"
            },
            channelItemMiddleTop: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width:"100%"
            },
            channelItemMiddleTopText: {
                fontSize: 14,
                color: "#000",
                display:"flex",
                width:0,
                overflow: "hidden",
                flex:1
                // overflow: "hidden", //超出的文本隐藏
                // textOverflow: "ellipsis",
                // whiteSpace: "nowrap" //溢出不换行
            },
            channelItemMiddleTopTextWrapper:{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            channelItemMiddleBottom: {
                flex: 1,
                display: "flex",
                flexDirection: "row"
            },
            channelItemMiddleBottomText: {
                fontSize: 12,
                color: "#999"
            },
            highText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            channelItemRight: {
                display: "flex",
                width: 20,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            }
        };
        let viewContaier = null;
        if (this.state.showProgress) {
            viewContaier = <Loadding/>;
        } else {

            viewContaier = <div style={{padding: 10}}>
                {
                    this.state.channel === 2 ?
                        this.state.channelList.length > 0 ? this.state.channelList.map((item, key) => {
                          if(item.is_type == 5 ) return null;
                            return (
                                <div key={key} onClick={() => {


                                    if(global.ID == 'ar' && this.state.channel == 2){
                                        this._rechargeDetail2(item);
                                        return
                                    }

                                    this._rechargeDetail(item);
                                }} style={styles.channelItemBox}>
                                    <div style={styles.channelItemLeft}>
                                        {/*<Icon icon={this._getChannelItemIcon(item.is_type)} size={50}*/}
                                        {/*      color={style['primaryColor' + model.SKINNAME]}/>*/}
                                        {
                                            this._getChannelItemIcon(item.is_type)
                                        }
                                    </div>
                                    <div style={styles.channelItemMiddle}>
                                        <div style={styles.channelItemMiddleTop}>
                                            <div style={styles.channelItemMiddleTopText}>
                                                <span style={styles.channelItemMiddleTopTextWrapper}>{item.is_title}</span>
                                            </div>
                                        </div>
                                        {
                                            item.is_min && item.is_max ?
                                                <div style={styles.channelItemMiddleBottom}>
                                                    <div style={{...styles.channelItemMiddleBottomText}}>限额:</div>
                                                    <div
                                                        style={{...styles.channelItemMiddleBottomText, ...styles.highText}}>￥{PublicUtils.subStringNum(item.is_min)}~￥{PublicUtils.subStringNum(item.is_max)}</div>
                                                    <div style={{...styles.channelItemMiddleBottomText}}></div>
                                                </div> : null
                                        }
                                    </div>
                                    {/*<div style={styles.channelItemRight}>*/}
                                    {/*    <Icon icon={'right'} color={'#bebebe'} size={26}/>*/}
                                    {/*</div>*/}
                                </div>
                            )
                        }) : <NoneData/> : null
                }
                {

                    this.state.channel === 1 ?
                        this.state.onlineChannelList.length > 0 ? this.state.onlineChannelList.map((item, key) => {
                            if(global.ID == "fl"){
                                if(item.payName.indexOf("虚拟")!== -1 || item.payName.indexOf("数字")!== -1)return null;
                            }else{
                                if(item.payName.indexOf("虚拟")!== -1 || item.payName.indexOf("USDT")!== -1 || item.payName.indexOf("数字")!== -1)return null;
                            }
                            return (
                              item.is_wallet?<div></div>: <div key={key} onClick={() => {
                                    this._rechargeDetail(item);
                                }} style={styles.channelItemBox}>
                                    <div style={styles.channelItemLeft}>
                                        {/*<Icon icon={this._getChannelItemIcon(item.payTag)} size={50}*/}
                                        {/*      color={style['primaryColor' + model.SKINNAME]}/>*/}
                                        {
                                            this._getChannelItemIcon(item.payTag,item.payName)
                                        }
                                    </div>
                                    <div style={styles.channelItemMiddle}>
                                        <div style={styles.channelItemMiddleTop}>
                                            <div style={styles.channelItemMiddleTopText}>
                                                <span style={styles.channelItemMiddleTopTextWrapper}>{item.payName}</span>
                                            </div>
                                        </div>
                                        {
                                            item.payMin && item.payMax ?
                                                <div style={styles.channelItemMiddleBottom}>
                                                    <div style={{...styles.channelItemMiddleBottomText}}>（限额:</div>
                                                    <div
                                                        style={{...styles.channelItemMiddleBottomText, ...styles.highText}}>￥{PublicUtils.subStringNum(item.payMin)}~￥{PublicUtils.subStringNum(item.payMax)}</div>
                                                    <div style={{...styles.channelItemMiddleBottomText}}>）</div>
                                                </div> : null
                                        }
                                    </div>
                                    {/*<div style={styles.channelItemRight}>*/}
                                    {/*    <Icon icon={'right'} color={'#bebebe'} size={26}/>*/}
                                    {/*</div>*/}
                                </div>
                            )
                        }) : <NoneData/> : null
                }

                {

                    this.state.channel === 4 ?
                      this.state.onlineChannelList.length > 0 ? this.state.onlineChannelList.map((item, key) => {
                          if(global.ID == "fl"){
                              if(item.payName.indexOf("虚拟")!== -1 || item.payName.indexOf("数字")!== -1)return null;
                          }else{
                              if(item.payName.indexOf("虚拟")!== -1 || item.payName.indexOf("USDT")!== -1 || item.payName.indexOf("数字")!== -1)return null;
                          }
                          return (
                           item.is_wallet?<div key={key} onClick={() => {
                                this._rechargeDetail3(item);
                            }} style={styles.channelItemBox}>
                                <div style={styles.channelItemLeft}>
                                    {/*<Icon icon={this._getChannelItemIcon(item.payTag)} size={50}*/}
                                    {/*      color={style['primaryColor' + model.SKINNAME]}/>*/}
                                    {
                                        this._getChannelItemIcon(item.payTag,item.payName)
                                    }
                                </div>
                                <div style={styles.channelItemMiddle}>
                                    <div style={styles.channelItemMiddleTop}>
                                        <div style={styles.channelItemMiddleTopText}>
                                            <span style={styles.channelItemMiddleTopTextWrapper}>{item.payName}</span>
                                        </div>
                                    </div>
                                    {
                                        item.payMin && item.payMax ?
                                          <div style={styles.channelItemMiddleBottom}>
                                              <div style={{...styles.channelItemMiddleBottomText}}>（限额:</div>
                                              <div
                                                style={{...styles.channelItemMiddleBottomText, ...styles.highText}}>￥{PublicUtils.subStringNum(item.payMin)}~￥{PublicUtils.subStringNum(item.payMax)}</div>
                                              <div style={{...styles.channelItemMiddleBottomText}}>）</div>
                                          </div> : null
                                    }
                                </div>
                                {/*<div style={styles.channelItemRight}>*/}
                                {/*    <Icon icon={'right'} color={'#bebebe'} size={26}/>*/}
                                {/*</div>*/}
                            </div>: <div></div>
                          )
                      }) : <NoneData/> : null
                }

                {

                    this.state.channel === 3?
                    this.state.virtualCurrencyChannelList.length > 0 ? this.state.virtualCurrencyChannelList.map((item, key) => {
                          if(item.is_type == 5 || item.payName.indexOf("虚拟")!== -1 || item.payName.indexOf("USDT")!== -1 || item.payName.indexOf("数字")!== -1){
                            return (
                                <div key={key} onClick={() => {
                                    this._rechargeDetail(item);
                                }} style={styles.channelItemBox}>
                                    <div style={styles.channelItemLeft}>
                                        {

                                            this._getChannelItemIcon(item.is_type,item.payName)
                                        }
                                    </div>
                                    <div style={styles.channelItemMiddle}>
                                        <div style={styles.channelItemMiddleTop}>
                                            <div style={styles.channelItemMiddleTopText}>
                                                <span style={styles.channelItemMiddleTopTextWrapper}>{item.is_title || item.payName}</span>
                                            </div>

                                        </div>
                                        {
                                            item.is_min && item.is_max ?
                                                <div style={styles.channelItemMiddleBottom}>
                                                    <div style={{...styles.channelItemMiddleBottomText}}>限额:</div>
                                                    <div
                                                        style={{...styles.channelItemMiddleBottomText, ...styles.highText}}>￥{PublicUtils.subStringNum(item.is_min)}~￥{PublicUtils.subStringNum(item.is_max)}</div>
                                                    <div style={{...styles.channelItemMiddleBottomText}}></div>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            )
                          }else{
                            return (
                                <div key={key} onClick={() => {
                                    this._rechargeDetail(item);
                                }} style={styles.channelItemBox}>
                                    <div style={styles.channelItemLeft}>
                                    {
                                        this._getChannelItemIcon(item.is_type)
                                    }
                                    </div>
                                    <div style={styles.channelItemMiddle}>
                                        <div style={styles.channelItemMiddleTop}>
                                            <div style={styles.channelItemMiddleTopText}>
                                                <span style={styles.channelItemMiddleTopTextWrapper}>{item.payName}</span>
                                            </div>
                                        </div>
                                        {
                                            item.payMin && item.payMax ?
                                                <div style={styles.channelItemMiddleBottom}>
                                                    <div style={{...styles.channelItemMiddleBottomText}}>（限额:</div>
                                                    <div
                                                        style={{...styles.channelItemMiddleBottomText, ...styles.highText}}>￥{PublicUtils.subStringNum(item.payMin)}~￥{PublicUtils.subStringNum(item.payMax)}</div>
                                                    <div style={{...styles.channelItemMiddleBottomText}}>）</div>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            )
                          }

                        }) : <NoneData/> : null
                }
            </div>
        }
        return viewContaier;
    };

    render() {
        const styles = {
            rechargeChannel: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9",
                height: 50,
                lineHeight: "50px"
                // alignItems: "center"
            },
            rechargeChannelItem: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                margin: "0 5px"
                // backgroundColor: "#000"
            },
            rechargeChannelItemText: {
                display: "flex",
                flex: 1,
                fontSize: 14,
                color: "#333",
                textAlign: "center",
                flexDirection: "row",
                justifyContent: "center",
            },
            activeRechargeChanelContainer: {
                borderBottom: "2px solid " + style['primaryColor' + model.SKINNAME],
                // borderBottomColor: "@primary",
                // borderBottomWidth: 2,
                // borderStyle: "solid"
            },
            activeRechargeChanel: {
                color: style['primaryColor' + model.SKINNAME]
            },
            channelItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderBottom: "1px solid #f5f7f9"
            },
            channelItemLeft: {
                display: "flex",
                paddingRight: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            channelItemMiddle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start"
            },
            channelItemMiddleTop: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width:"100%"
            },
            channelItemMiddleTopText: {
                fontSize: 16,
                color: "#000",
                display:"flex",
                width:0,
                overflow: "hidden",
                flex:1
                // overflow: "hidden", //超出的文本隐藏
                // textOverflow: "ellipsis",
                // whiteSpace: "nowrap" //溢出不换行
            },
            channelItemMiddleTopTextWrapper:{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            channelItemMiddleBottom: {
                flex: 1,
                display: "flex",
                flexDirection: "row"
            },
            channelItemMiddleBottomText: {
                fontSize: 12,
                color: "#999"
            },
            highText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            channelItemRight: {
                display: "flex",
                width: 20,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            }
        };
        return (
            <Container showBottomTab={global.THEME === "theme1"} tabIndex={2} style={{backgroundColor: "#f5f7f9"}}
                       title={'充值渠道'}>



              {
                global.ID == 'ai'? <div style={styles.rechargeChannel}>

                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 4 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(4);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 4 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                      {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      钱包支付
                    </div>
                  </div>


                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 1 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(1);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 1 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                      {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      在线支付
                    </div>
                  </div>





                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 3 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(3);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 3 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                      {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      虚拟币
                    </div>
                  </div>


                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 2 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(2);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 2 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'bank16'} size={20}*/}
                      {/*      color={this.state.channel === 2 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      公司入款
                    </div>
                  </div>




                </div>: <div style={styles.rechargeChannel}>

                  {
                    this.state.isqb && global.ID == 'ca' ||  this.state.isqb && global.ID == 'bk' || this.state.isqb && global.ID == 'js'? <div
                        style={{...styles.rechargeChannelItem, ...this.state.channel === 4 ? styles.activeRechargeChanelContainer : null}}
                        onClick={() => {
                          this._changeChannel(4);
                        }}>
                      <div
                          style={{...styles.rechargeChannelItemText, ...this.state.channel === 4 ? styles.activeRechargeChanel : null}}>
                        {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                        {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                        钱包支付
                      </div>
                    </div>:<span></span>
                  }






                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 2 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(2);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 2 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'bank16'} size={20}*/}
                      {/*      color={this.state.channel === 2 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      公司入款
                    </div>
                  </div>


                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 1 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(1);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 1 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                      {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      在线支付
                    </div>
                  </div>
                  <div
                      style={{...styles.rechargeChannelItem, ...this.state.channel === 3 ? styles.activeRechargeChanelContainer : null}}
                      onClick={() => {
                        this._changeChannel(3);
                      }}>
                    <div
                        style={{...styles.rechargeChannelItemText, ...this.state.channel === 3 ? styles.activeRechargeChanel : null}}>
                      {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                      {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                      虚拟币
                    </div>
                  </div>


                  {
                    this.state.isqb && global.ID != 'ca' && global.ID != 'bk' && global.ID != 'js'? <div
                        style={{...styles.rechargeChannelItem, ...this.state.channel === 4 ? styles.activeRechargeChanelContainer : null}}
                        onClick={() => {
                          this._changeChannel(4);
                        }}>
                      <div
                          style={{...styles.rechargeChannelItemText, ...this.state.channel === 4 ? styles.activeRechargeChanel : null}}>
                        {/*<Icon style={{marginRight: 10}} icon={'chongzhi'} size={18}*/}
                        {/*      color={this.state.channel === 1 ? style['primaryColor' + model.SKINNAME] : "rgba(0,0,0,0.5)"}/>*/}
                        钱包支付
                      </div>
                    </div>:<span></span>
                  }



                </div>
              }




                {this._buildListView()}
            </Container>
        )
    }
}
