/**
 * 投注类型
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from "../../../utils/config.scss";
import Icon from "../../../utils/icon";
import model from "../../../services/model";

export default class BetTypeNav extends Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
    }

    static propTypes = {
        templateType: PropTypes.string,
        betTemplate: PropTypes.array,
        onPress: PropTypes.func,
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.setState({second: this.props.second}, () => {
        //     this._countdown();
        // });
    }

    /**
     * 页面离开时
     */
    componentWillUnmount = () => {
    };
    componentWillReceiveProps = (nextProps) => {
    };
    /**
     * 切换玩法
     * */
    _changeBetTemplate = (i, item) => {
        this.setState({index: i});
        this.props.onPress && this.props.onPress(i, item);
    };

    render() {
        const betInfoStyles = {
            betInfoBox: {
                fontSize: 14,
                display: "flex",
                flex: 1,
                flexDirection: "row",
                borderTop: "1px solid #f5f7f9"
            },
            betTypeBox: {
                display: "flex",
                flex: 1,
                // backgroundColor: "#f5f7f9",
                // borderRight: "1px solid #f5f7f9"
            },
            betBallBox: {
                width: 270,
            },
            btnBox: {
                paddingLeft: 10,
                paddingRight: 10
            },
            betTypeInnerBox: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                maxWidth: 110,
                // borderRightWidth: 1,
                // borderRightColor: "#f5f7f9"
            },
            betTypeItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
                lineHeight: "40px",
                fontSize: 15,
                color: "rgba(0,0,0,0.3)",
                overflow: "hidden"
            },
            betTypeItem: {
                display: "flex",
                flex: 1,
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
                // color: "rgba(0,0,0,0.7)",
            },
            betCount: {
                width: 18,
                height: 18,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                lineHeight: "18px",
                textAlign: "center",
                color: "#fff",
                borderRadius: 18,
                fontSize: 10
            },
            betTypeItemActive: {
                backgroundColor: "#fff",
                borderLeft: "2px solid " + style['primaryColor' + model.SKINNAME],
                color: "rgba(0,0,0,1)"
            },
            betBallInnerBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: 5,
            },
            betNumberBox: {
                display: "flex",
                flexDirection: "row",
                margin: 5,
                paddingLeft: 8,
                paddingRight: 8,
                height: 40,
                alignItems: "center",
                border: "1px dashed rgba(0,0,0,0.15)",
                justifyContent: "space-between",
                borderRadius: 0.1,
                width: 120,
            },
            betNumberActive: {
                border: "1px solid " + style['primaryColor' + model.SKINNAME],
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betNameActive: {
                color: "#fff"
            },
            betAmountActive: {
                color: "#fff"
            },
            betOddsActive: {
                color: "#fcff23"
            },
            betName: {
                fontSize: 14,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "red"
            },
            betAmount: {
                fontSize: 13,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "green"
            },
            betOdds: {
                fontSize: 12,
                color: "rgba(246, 48, 85, 0.94)",
                textAlign: "center",
                // backgroundColor: "blue"
            },
        };
        return (
            <div style={betInfoStyles.betTypeInnerBox}>
                {
                    this.props.betTemplate.map((item, i) => {
                        return (
                            <div key={i} onClick={() => {
                                this._changeBetTemplate(i, item);
                            }}
                                 style={{...betInfoStyles.betTypeItemBox, ...this.state.index === i ? betInfoStyles.betTypeItemActive : null}}>
                                <div
                                    style={{...betInfoStyles.betTypeItem}}>{item.name}</div>
                                {
                                    item.count ?
                                        <div
                                            style={{...betInfoStyles.betCount}}>{item.count}</div> : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}