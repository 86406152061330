//图片

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "../utils/icon";

class Image extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            isError: false,
            width: this.props.width || 0,
            height: this.props.height || 0,
            uri: this.props.uri
        }
    }

    static propTypes = {
        loaddingImg: PropTypes.string,
        uri: PropTypes.string,
        style: PropTypes.object,
        resizeMode: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.number,
        placeholderSize: PropTypes.object,
        hasPlaceholder: PropTypes.bool
        // placeholderStyle: Text.propTypes.style,
    };
    static defaultProps = {
        resizeMode: "contain",
        placeholderSize: {
            width: "auto",
            height: "auto"
        },
        hasPlaceholder: false
    };

    _onLoad = (nextProps, nextState) => {
        this.setState({status: 1})
    }

    _onError = () => {
        this.setState({isError: true})
    }

    render() {
        const styles = {
            imgBox: {
                maxWidth: "100%",
                display: "block",
                opacity: this.state.isError ? 1 : 1
            },
            imgContainer: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"

            },
            iconBox: {
                position: "absolute",
                display: "flex",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                justifyContent: "center",
                alignItems: "center",
                // zIndex: 1
            },
            gameTabImg: {
                width: 55,

            }
        };
        return this.props.hasPlaceholder ?
            <div style={{
                ...this.props.style,
                ...styles.imgContainer, ...(this.props.placeholderSize && this.state.status === 0) ? {
                    width: this.props.placeholderSize.width,
                    height: this.props.placeholderSize.height,
                    backgroundColor: "#e9e9e9",
                    borderRadius: 3
                } : null
            }}>
                <img onLoad={() => {
                    this._onLoad();
                }}
                     onError={() => {
                         this._onError();
                     }}
                     style={{...styles.imgBox, ...this.props.width ? {maxWidth: this.props.width} : null, ...this.props.height ? {maxHeight: this.props.height} : null}}
                     src={this.props.uri}/>
                {
                    this.state.status !== 1 ?
                        <div style={styles.iconBox}>
                            {/*<Icon icon={"youxi1"} size={40} color={"#d3d3d3"}/>*/}
                            <Image style={styles.gameTabImg} uri={require("../assets/images/youxi.svg")}/>
                        </div> : null
                }
            </div> :
            <img
                style={{...this.props.style, ...styles.imgBox, ...this.props.width ? {width: this.props.width} : null, ...this.props.height ? {height: this.props.height} : null}}
                src={this.props.uri}/>

    }
}

export default Image;
