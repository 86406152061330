/**
 * 注册图标（首页）
 */
import React from 'react'
import PropTypes from 'prop-types';
import model from "../../../services/model";
import Image from "../../../component/image";
import global from '../../../global'

export default class GameBodyImg extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {};

    }

    static propTypes = {
        navigation: PropTypes.object
    };
    static defaultProps = {};
    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (nextProps.playName === "生肖连") {
        //     alert(nextState.subPlayName + "--" + this.state.subPlayName);
        // }
        return this.props.icon !== nextProps.icon;
        // || (this.state.data[2] && (nextState.betContent !== this.state.data[0]));
    }

    render() {
        return (
            <div style={styles.gameItemImg}>
            {
                global.ID == "b5"?<Image hasPlaceholder={true}
                       placeholderSize={{
                           width: ((model.clientWidth - 50) / 3) - 30,
                           height: ((model.clientWidth - 50) / 3) - 30
                       }}
                       uri={this.props.icon}
                       style={styles.gameImg}
                       width={((model.clientWidth - 50) / 3) - 50}
                       height={((model.clientWidth - 50) / 3) - 60}
                />:<Image hasPlaceholder={true}
                       placeholderSize={{
                           width: ((model.clientWidth - 50) / 3) - 30,
                           height: ((model.clientWidth - 50) / 3) - 30
                       }}
                       uri={this.props.icon}
                       style={styles.gameImg}
                       width={((model.clientWidth - 50) / 3) - 30}
                       height={((model.clientWidth - 50) / 3) - 40}
                />
            }

            </div>
        )
    }
}
const styles = {
    gameItemImg: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    gameImg: {
    },
};
