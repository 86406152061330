/**
 * 游戏报表
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import Storage from "../services/storage";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import MyPicker from "../component/myPicker";
import Icon from '../utils/icon'

export default class GameReport extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.dateTypeModel = model.dateType;
        this.state = {
            showDateTypeFliter: false,
            dateType: this.dateTypeModel[0],
            dataList: [],
            showProgress: false
        }
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 初始化
     */
    _init_() {
        this._fetchReportData();
        const user = Storage.retrieveData("user") && JSON.parse(Storage.retrieveData("user"));
        if (user) {

            let newState = {};
            for (let key in user) {
                newState[key] = {value: user[key]};
                if (user[key]) {
                    newState[key].isEdit = true;
                }
                //this.setState(item);
            }
            this.setState(newState);
            console.log(newState);
        }
    }


    _fetchReportData() {
        this.setState({dataList: [], showProgress: true});
        let dataList = [];
        let parm = {day: this.state.dateType.value};
        Http.request("memberGameReport", parm, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    if (res.data.list) {
                        let listObj = res.data.list;
                        for (let key in listObj) {
                            let item: any = {};
                            item.isShowDetail = false;
                            switch (key) {
                                case '1':
                                    item.name = "体育";
                                    break;
                                case '2':
                                    item.name = "彩票";
                                    break;
                                case '3':
                                    item.name = "视讯";
                                    break;
                                case '4':
                                    item.name = "电子游戏";
                                    break;
                                case '5':
                                    item.name = "棋牌";
                                    break;
                                case '6':
                                    item.name = "捕鱼";
                                    break;
                                case '8':
                                    item.name = "红包";
                                    break;
                            }
                            if (listObj[key]['0']) {
                                item.total = listObj[key]['0'];
                            }
                            item.subitem = [];
                            for (let subkey in listObj[key]) {
                                if (subkey !== '0') {
                                    item.subitem.push(listObj[key][subkey]);
                                }
                            }
                            dataList.push(item);
                        }
                        this.setState({dataList: dataList});
                    }
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    }

    /**
     * 体育图标
     * @private
     */
    _renderSportLogo = (name) => {
        let logo;
        switch (name) {
            case '足球':
                logo = <Icon icon={'zuqiu'} size={25} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '篮球':
                logo = <Icon icon={'lanqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '羽毛球':
                logo = <Icon icon={'yumaoqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '乒乓球':
                logo = <Icon icon={'pingpangqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '网球':
                logo = <Icon icon={'wangqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '排球':
                logo = <Icon icon={'paiqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '棒球':
                logo = <Icon icon={'bangqiu'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
            case '其他':
                logo = <Icon icon={'qita'} size={22} color={'rgba(0,0,0,0.4)'}/>;
                break;
        }
        return logo;
    };

    render() {
        return (
            <Container title={'游戏报表'}>
                <div style={styles.box}>
                    <div style={styles.acountTitle}>会员账号</div>
                    <div style={styles.acountContent}>{this.state.user_account.value}</div>
                </div>
                {
                    this.state.dataList.map((item,key) => {
                        return (
                            <div key={key} style={styles.box}>
                                <div onClick={() => {
                                    this.setState({showDateTypeFliter: true})
                                }} style={styles.title}>
                                    <div style={{...styles.titleItemText, ...styles.name}}>{item.name}</div>
                                    <div style={styles.titleItemText}>-</div>
                                    <div style={styles.titleItemText}>{this.state.dateType.label}</div>
                                    <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,0.5)'}/>
                                </div>
                                <div style={styles.header}>
                                    <div style={styles.headerItem}>
                                        <div style={styles.headerItemHeaderText}>注单</div>
                                        <div
                                            style={{...styles.headerItemText, ...!item.total.bet_num ? styles.dimText : null}}>{item.total.bet_num}</div>
                                    </div>
                                    <div style={styles.headerItem}>
                                        <div style={styles.headerItemHeaderText}>流水</div>
                                        <div
                                            style={{...styles.headerItemText, ...!item.total.valid_amount ? styles.dimText : null}}>{PublicUtils.subStringNum(item.total.valid_amount)}</div>
                                    </div>
                                    <div style={styles.headerItem}>
                                        <div style={styles.headerItemHeaderText}>输赢</div>
                                        <div
                                            style={{...styles.headerItemText, ...!item.total.win_amount ? styles.dimText : null}}>{PublicUtils.subStringNum(item.total.win_amount)}</div>
                                    </div>
                                </div>
                                {
                                    item.subitem.map((subitem,key) => {
                                        if (subitem.valid_amount !== 0 || subitem.bet_num !== 0 || subitem.win_amount !== 0) {
                                            return (
                                                <div key={key} style={styles.body}>
                                                    <div style={styles.headerItem}>
                                                        <div
                                                            style={{...styles.headerItemHeaderText, ...styles.headerItemGameName}}>{subitem.game_name}</div>
                                                        <div
                                                            style={{...styles.headerItemText, ...!subitem.bet_num ? styles.dimText : null}}>{subitem.bet_num}</div>
                                                    </div>
                                                    <div style={styles.headerItem}>
                                                        {/*<Text style={styles.headerItemHeaderText}>流水</Text>*/}
                                                        <div
                                                            style={{...styles.headerItemText, ...!subitem.valid_amount ? styles.dimText : null}}>{PublicUtils.subStringNum(subitem.valid_amount)}</div>
                                                    </div>
                                                    <div style={styles.headerItem}>
                                                        {/*<Text style={styles.headerItemHeaderText}>输赢</Text>*/}
                                                        <div
                                                            style={{...styles.headerItemText, ...!subitem.win_amount ? styles.dimText : null}}>{PublicUtils.subStringNum(subitem.win_amount)}</div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        )
                    })
                }
                {
                    this.state.showProgress ? <Loadding/> : null
                }
                <MyPicker
                    title={'日期'}
                    model={this.dateTypeModel}
                    display={this.state.showDateTypeFliter}
                    onHiden={() => {
                        this.setState({showDateTypeFliter: false})
                    }}
                    onItemPress={(item) => {
                        this.setState({dateType: item.value[0].value, showDateTypeFliter: false, currpage: 1}, () => {
                            this._fetchReportData();
                        });
                    }}
                />
            </Container>
        )
    }
}

const styles = {
    box: {
        backgroundColor: "#fff",
        marginBottom: 10,
    },
    acountTitle:{
        textAlign:"center",
        padding:"10px 0 5px"
    },
    acountContent:{
        textAlign:"center",
        padding:"5px 0 10px",
        fontSize:18
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: 40,
        alignItems: "center",
        borderBottom: "1px solid #f9f9f9",
    },
    name: {},
    titleItemText: {
        marginLeft: 5,
        marginRight: 5,
        fontSize: 14,
        color: "rgba(0,0,0,0.5)"
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px solid #f9f9f9",
        flex: 1,
    },
    headerItemHeaderText: {
        fontSize: 14,
        color: "rgba(0,0,0,0.4)",
    },
    headerItemText: {
        fontSize: 18,
        color: "rgba(0,0,0,1)",
        marginTop: 10
    },
    body: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #f9f9f9",
    },
    headerItemGameName: {
        // color: "#000",
        // fontSize: 16
    },
    dimText: {
        color: "rgba(0,0,0,0.4)"
    }
};
