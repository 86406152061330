/**
 * 自定义图标
 */

import React, {Component} from 'react';
import ActivityIndicator from './activityIndicator'
import PropTypes from 'prop-types';

export default class Loadding extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        msg: PropTypes.string,
        style: PropTypes.object
    };
    static defaultProps = {
        msg: "加载中..."
    };

    render() {
        const styles = {
            loaddingBox: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: 30
            },
            loaddingMsgBox: {
                display: "flex",
                justifyContent: "center",
            },
            loaddingMsg: {
                display: "flex",
                textAlign: "center",
                color: "rgba(0,0,0,0.5)",
                fontSize: "14px"
            },
            loaddingIcon: {
                marginLeft: "auto",
                marginRight: "auto",
            }
        };
        return (
            <div style={{...styles.loaddingBox, ...this.props.style}}>
                <div style={styles.loaddingIcon}><ActivityIndicator color={"rgba(0,0,0,0.5)"}/></div>
                <div style={styles.loaddingMsgBox}>
                    <div style={styles.loaddingMsg}>{this.props.msg}</div>
                </div>
            </div>
        )
    }
}
