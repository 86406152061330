/*
 * @,@Author: ,: your name
 * @,@Date: ,: 2020-10-18 21:25:03
 * @,@LastEditTime: ,: 2020-10-29 13:38:30
 * @,@LastEditors: ,: Please set LastEditors
 * @,@Description: ,: In User Settings Edit
 * @,@FilePath: ,: /html-project/src/pages/active-detail.js
 */
/**
 * 活动详情
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import Http from "../services/http";
import PublicUtils from "../utils/publicUtils";
import Loadding from "../component/loadding";
import history from "../services/history";
import global from "../global";

// 背景颜色
let bgc = '';
if(global.ID == 'b3'){
    bgc = '#004531';
}else{
    bgc = '';
}


export default class ActiveDetail extends React.PureComponent {
    // 构造
    constructor(props) {


        super(props);



        this.state = {
            id: PublicUtils.getState(this.props.location.pathname).id,
            title: PublicUtils.getState(this.props.location.pathname).title,
            showProgress: false,
        };
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    // 导航颜色切换
    qhys(){




        let htmls = <Container title={this.state.title} style={{backgroundColor:bgc}} >
            {
                this.state.content ? <div style={styles.box}>
                    {

                        global.ID == 'yy' ? <div dangerouslySetInnerHTML={{__html: "<div class='active-box' style='text-align:left'>"+this.state.content+"</div>"}}/>:<div dangerouslySetInnerHTML={{__html: "<div class='active-box' style='text-align:center'>"+this.state.content+"</div>"}}/>
                    }
                </div> : null
            }
            {
                this.state.showProgress ? <Loadding/> : null
            }
        </Container>


        if(global.ID == 'b1'){

            htmls = <Container title={this.state.title} style={{backgroundColor:bgc}} headerBackgroundColor="#115F83" headerFontColor="yellow">
                {
                    this.state.content ? <div style={styles.box}>
                        {

                            global.ID == 'yy' ? <div dangerouslySetInnerHTML={{__html: "<div class='active-box' style='text-align:left'>"+this.state.content+"</div>"}}/>:<div dangerouslySetInnerHTML={{__html: "<div class='active-box' style='text-align:center'>"+this.state.content+"</div>"}}/>
                        }
                    </div> : null
                }
                {
                    this.state.showProgress ? <Loadding/> : null
                }
            </Container>
        }


        return htmls;
    }
    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchData();
    };
    /**
     * 获取数据
     * @private
     */
    _fetchData = () => {
        this.setState({showProgress: true});
        let parm: any = {id: this.state.id};
        Http.request("activityShow", parm, (res) => {
            this.setState({showProgress: false});
            if (res.data.status === 1) {
                this.setState({title: res.data.info.is_title, content: res.data.info.is_content});
            } else {
                PublicUtils.showToast(res.message);
            }
        });
    };

    render() {
        return (
            this.qhys()
        )
    }
}

const styles = {
    box: {
        width: "100%",
        overflow: "auto",
        backgroundColor: bgc
    },
    activeBox:{
        textAlign:"center"
    }
};
