/**
 * 消息
 * @format
 * @flow
 */
import React from 'react';
import Container from "../component/container";
import Http from "../services/http";
import PublicUtils from "../utils/publicUtils";
import Storage from "../services/storage";
import model from "../services/model";

export default class MemberSpecil extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            max_discount: 0,
            withdraw_num: 0,
            min_withdraw: 0,
            birthday_amount:0,
            list: []
        };
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentDidMount = () => {
        // PublicUtils.setParams({openMenu: this._openMenu});
        this.fetchData();
    };


    /**
     * 提交代理
     */
    fetchData() {
        let parm = {
            // agent: this.ComFn.getQueryString("ag")
        };
        Http.request("specil", parm, (res) => {
            PublicUtils.hideLoader();
            // return;
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'Me'});
            }
            if (res.code === 200) {
                this.setState({
                    max_discount: res.data.max_discount,
                    withdraw_num: res.data.withdraw_num,
                    min_withdraw: res.data.min_withdraw,
                    birthday_amount:res.data.birthday_amount,
                    list: res.data.game_list
                })
            }
        });
    }

    render() {
        const styles = {
            wrapper: {
                // display: "flex",
                // flex: 1,
                // flexDirection: "column",
                backgroundColor: "#fff",
                borderTop: "1px solid rgba(0,0,0,0.2)",
                borderLeft: "1px solid rgba(0,0,0,0.2)",
                borderRight: "1px solid rgba(0,0,0,0.2)",
            },
            headContainer: {
                // display: "flex",
                // flex: 1,
                // flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center"
            },
            row: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid rgba(0,0,0,0.2)"
            },
            cellTitle: {
                borderRight: "1px solid rgba(0,0,0,0.2)"
            },
            cell: {
                display: "flex",
                flex: 1,
                padding: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            bodyContainer: {},
            bodyTitle: {
                borderBottom: "1px solid rgba(0,0,0,0.2)",
                fontSize: 16,
                padding: 10,
                textAlign: "center",
                backgroundColor: "rgba(0,0,0,0.08)"
            },
            bodyContent: {
                display: "flex",
                flexDirection: "column",
            },
        };
        return (
            <Container title={'我享有的特权'} style={{padding: 10}}>
                <div style={styles.wrapper}>
                    <div style={styles.headContainer}>
                        <div style={styles.bodyTitle}>
                            <span>我的特权</span>
                        </div>
                        <div style={styles.row}>
                            <div style={{...styles.cell, ...styles.cellTitle}}><span>每日提款次数</span></div>
                            <div style={styles.cell}><span>{this.state.withdraw_num}</span></div>
                        </div>
                        <div style={styles.row}>
                            <div style={{...styles.cell, ...styles.cellTitle}}><span>最低取款额</span></div>
                            <div style={styles.cell}><span>{this.state.min_withdraw}</span></div>
                        </div>
                        <div style={styles.row}>
                            <div style={{...styles.cell, ...styles.cellTitle}}><span>最大反水金额</span></div>
                            <div style={styles.cell}><span>{this.state.max_discount}</span></div>
                        </div>
                        <div style={styles.row}>
                            <div style={{...styles.cell, ...styles.cellTitle}}><span>生日彩金</span></div>
                            <div style={styles.cell}><span>{this.state.birthday_amount}</span></div>
                        </div>
                    </div>
                    <div style={styles.bodyContainer}>
                        <div style={styles.bodyTitle}>
                            <span>返水比例</span>
                        </div>
                        <div style={styles.bodyContent}>
                            {
                                this.state.list.map((item) => {
                                    if (item.rule > 0) {
                                        return (
                                            <div style={styles.row}>
                                                <div style={{...styles.cell, ...styles.cellTitle}}>
                                                    <span>{item.game_name}</span></div>
                                                <div style={styles.cell}><span>{item.rule}%</span></div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}
