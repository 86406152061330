//按钮

import React, {Component} from 'react';
import App from "../App";
import PropTypes from 'prop-types';
import ActivityIndicator from './activityIndicator'
import style from '../utils/config.scss'
import model from "../services/model";

class MyButton extends React.Component {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        disabled: PropTypes.bool,
        onPress: PropTypes.func,
        style: PropTypes.object,
        title: PropTypes.string,
        showProgress: PropTypes.bool,
        className: PropTypes.string
    };
    static defaultProps = {
        disabled: false,
        showProgress: false
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator/>;
    };

    render() {
        const styles = {
            btnBox: {
                flex: 1,
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderRadius: 3
            },
            btnText: {
                display: "flex",
                color: "#fff",
                fontSize: 16,
                textAlign: "center"
            },
            disabledBtn: {
                opacity: 0.6
            },
        };
        return <div className={this.props.className} onClick={() => {
            (this.props.onPress && !this.props.disabled) && this.props.onPress();
        }}
                    style={{...styles.btnBox, ...this.props.disabled ? styles.disabledBtn : null}}>
            {this.props.showProgress ? this._showProgress() : <div
                style={{...styles.btnText, ...!this.props.disabled ? styles.disabledBtnText : null}}>{this.props.title}</div>}
        </div>;
    }
}

export default MyButton;
