/**
 * PC蛋蛋
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../component/container";
import BetTypeNav from "./components/betTypeNav";
import BetNumberlist from "./components/betNumberlist";
import RecentOpenList from "./components/recentOpenList";
import Footer from "./components/footer";
import Betbox from "./components/betbox";
import TimeBox from "./components/timeBox";
import style from "../../utils/config.scss";
import Icon from "../../utils/icon";
import model from "../../services/model";
import Http from "../../services/http";
import history from "../../services/history";
import PublicUtils from "../../utils/publicUtils";

export default class KLSF extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.is_code = PublicUtils.getState(this.props.location.pathname).id;
        this.defaultBetAmount = "";
        this.betTemplate = [
            {
                name: "第一位",
                selected: true,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第二位",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第三位",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第四位",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第五位",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第六名",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第七名",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "第八名",
                selected: false,
                nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["尾大", 0], ["尾小", 0], ["合单", 0], ["合双", 0], ["东", 0], ["南", 0], ["西", 0], ["北", 0], ["中", 0], ["发", 0], ["白", 0]]
            },
            {
                name: "总和",
                selected: false,
                nums: [["总大", 0], ["总小", 0], ["总单", 0], ["总双", 0], ["尾大", 0], ["尾小", 0]]
            },
            {
                name: "龙虎",
                selected: false,
                nums: [["龙", 0], ["虎", 0]]
            }
        ];
        this.lotteryInfo = {
            state: 0,//投注状态 0：正在投注  1：停止投注
            lottery_no: null,//当前期号
            stop_second: 0,
            open_second: 0,
        };
        this.total = {
            count: 0,
            amount: 0
        };
        this.currentBetTemplate = {index: 0, name: this.betTemplate[0].name, nums: this.betTemplate[0].nums};
        this.currentBetNumber = {};
        this.state = {
            showBetBox: false,
            render: false//重新渲染页面
        };
        this.hasSetAmount = false;
    }


    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.props.navigation.setParams({openMenu: this._openMenu});
    }

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchLotteryOdds();
        this._fetchLotteryTime();
    };

    /**
     * 获取当前期数和封盘时间
     */
    _fetchLotteryTime() {
        let parm = {is_code: this.is_code};
        Http.request("lotteryTime", parm, (res) => {
            if (res.data && res.data.status) {
                this.lotteryInfo = {
                    lottery_no: res.data.is_no,//当前期号
                    state: res.data.stop_time > 0 ? 0 : 1,//投注状态 0：正在投注  1：停止投注
                    stop_second: res.data.stop_time,
                    open_second: res.data.open_time,
                    // state: 0,
                    // stop_second: 5,
                    // open_second: 5,
                };
                // this._fetchHistoryResult();
                // this.lotteryBaseInfo.stop_second = 10000;
                // this.lotteryBaseInfo.open_second = 10003;
                // this.getHistoryResult();
                /*当前期正在下注*/
                if (this.lotteryInfo.stop_second > 0) {

                }
                this.setState({render: !this.state.render});
            } else {
                PublicUtils.alert({
                    title: "温馨提示",
                    msg: res.data.message,
                    buttons: [
                        {
                            text: "好的", onPress: () => {
                                PublicUtils.navigate("GameNavigation");
                            }
                        },
                    ]
                });
            }
        });
    }

    /**
     * 切换玩法
     * */
    _changeBetTemplate = (i, item) => {
        this.currentBetTemplate = {index: i, name: item.name, nums: item.nums};
        this.setState({showBetBox: false, render: !this.state.render});
        // this._hideBetBox();
    };
    /**
     * 点击投注号码
     * */
    _betNumberClick = (item) => {
        // this.setState({
        //     currentBetNumber: {name: item[0], odds: item[1]},
        //     betAmount: item[2] || "",
        //     showBetBox: true
        // }, () => {
        //     this._betAmount(this.defaultBetAmount);
        // });

        this.currentBetNumber = {name: item[0], odds: item[1], amount: item[2] || this.defaultBetAmount};
        this._betAmount(item[2] || this.defaultBetAmount, 'betClick');
    };
    /**
     * 清除单个投注号码
     * @param item
     * @private
     */
    _clearBetNumber = (item) => {
        this.currentBetNumber = {name: item[0], odds: item[1], amount: 0};
        this._betAmount(0);
        this.setState({showBetBox: false});
    };
    /**
     * 投注金额
     * */
    _betAmount = (value, type) => {
        // this.setState({betAmount: value});
        if (type !== "betClick") {
            this.defaultBetAmount = value;
        }
        this.currentBetNumber.amount = value;
        this.betAmount = value;
        let currentBetTemplate = this.currentBetTemplate;
        let count = 0;//当前玩法投注数量
        let totalCount = 0;//总投注数量
        let totalAmount = 0;//总投注数量
        let showBetBox = true;
        // alert(this.hasSetAmount);
        console.log(this.betAmount);
        for (let i = 0; i < currentBetTemplate.nums.length; i++) {
            if (currentBetTemplate.nums[i][0] === this.currentBetNumber.name) {
                currentBetTemplate.nums[i][3] = type === "betClick" ? !currentBetTemplate.nums[i][3] : currentBetTemplate.nums[i][3];
            }
            if (currentBetTemplate.nums[i][3]) {
                if (!currentBetTemplate.nums[i][2] || !this.hasSetAmount) {
                    currentBetTemplate.nums[i][2] = this.betAmount;
                }
            } else {
                currentBetTemplate.nums[i][2] = "";
            }
            if (currentBetTemplate.nums[i][2]) {
                count++;
            }
        }
        currentBetTemplate.count = count;
        for (let i = 0; i < this.betTemplate.length; i++) {
            if (this.betTemplate[i].name === this.currentBetTemplate.name) {
                this.betTemplate[i].nums = this.currentBetTemplate.nums;
                this.betTemplate[i].count = count;
            }
            for (let l = 0; l < this.betTemplate[i].nums.length; l++) {
                if (this.betTemplate[i].nums[l][3]) {
                    totalCount++;
                }
                if (this.betTemplate[i].nums[l][2]) {
                    totalAmount += Number(this.betTemplate[i].nums[l][2]);
                }
            }
            // if (this.betTemplate[i].count) {
            //     totalCount += this.betTemplate[i].count;
            // }
            // }
        }
        // const showBetBox = totalCount > this.total.count;
        this.total = {count: totalCount, amount: totalAmount};
        this.currentBetTemplate = currentBetTemplate;
        this.setState({showBetBox: showBetBox, render: !this.state.render});
        // this.setState({
        //     betAmount: value,
        //     currentBetTemplate: currentBetTemplate,
        //     total: {count: totalCount, amount: totalAmount}
        // });
    };


    /**
     * 获取彩种赔率
     */
    _fetchLotteryOdds() {
        let parm = {is_code: this.is_code};
        Http.request("getLotteryOdds", parm, (res) => {
            if (res.data && res.data.status) {
                // this.lotteryBetArea.Odds = res.data.odds;
                this._bindLotteryOdds(res.data.odds);
            }
        });
    }


    /**
     * 绑定彩种赔率
     */
    _bindLotteryOdds(odds) {
        for (let i = 0; i < this.betTemplate.length; i++) {
            for (let l = 0; l < this.betTemplate[i].nums.length; l++) {
                this.betTemplate[i].nums[l][1] = odds[i + 1][l + 1];
            }
        }
        this.currentBetTemplate.nums = this.betTemplate[this.currentBetTemplate.index].nums;
        this.setState({render: this.state.render});
    }


    /**
     * 开奖结果总和
     * @param numbers
     * @returns {number}
     */
    _total = (numbers) => {
        let numList = numbers.split(',');
        let total = 0;
        for (let i = 0; i < numList.length; i++) {
            total += Number(numList[i]);
        }
        return total;
    };
    /**
     * 彩种
     **/
    _checkTitle = () => {
        this.lotteryInfo.is_name = PublicUtils.getState(this.props.location.pathname).is_name;
        return PublicUtils.getState(this.props.location.pathname).is_name;
        // if (this.is_code === 4002) {
        //     return "重庆快乐十分";
        // }
        // if (this.is_code === 4001) {
        //     return "广东快乐十分";
        // }
        // if (this.is_code === 40011) {
        //     return "三分十分";
        // }
    };


    render() {
        const styles = {
            topBox: {},
            lotteryBox: {
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden"
            },
            openListBox: {
                backgroundColor: "#fff",
                zIndex: 10
            },
            disabledBtn: {
                opacity: 0.6
            },
        };
        const betInfoStyles = {
            betInfoBox: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #f5f7f9",
            },
            betTypeBox: {
                display: "flex",
                flex: 1,
                backgroundColor: "#f5f7f9",
                // borderRight: "1px solid #f5f7f9"
            },
            scrollBox: {
                width: "100%",
                overflow: "auto",
                height: "100%"
            },
            betBallBox: {
                width: 270,
                backgroundColor: "#fff"
            },
            btnBox: {
                paddingLeft: 10,
                paddingRight: 10
            },
            betTypeInnerBox: {
                // borderRightWidth: 1,
                // borderRightColor: "#f5f7f9"
            },
            betTypeItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
                lineHeight: "40px",
                fontSize: 15,
            },
            betTypeItem: {
                color: "rgba(0,0,0,0.7)"
            },
            betCount: {
                width: 18,
                height: 18,
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                textAlign: "center",
                color: "#fff",
                borderRadius: 18,
                fontSize: 10
            },
            betTypeItemActive: {
                backgroundColor: "#f5f7f9",
            },
            betBallInnerBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: 5,
            },
            betNumberBox: {
                flexDirection: "row",
                margin: 5,
                paddingLeft: 8,
                paddingRight: 8,
                height: 40,
                alignItems: "center",
                border: "1px solid rgba(0,0,0,0.15)",
                justifyContent: "space-between",
                borderStyle: 'dashed',
                borderRadius: 0.1,
                width: 120,
            },
            betNumberActive: {
                borderColor: style['primaryColor' + model.SKINNAME],
                borderStyle: "solid",
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betNameActive: {
                color: "#fff"
            },
            betAmountActive: {
                color: "#fff"
            },
            betOddsActive: {
                color: "#fcff23"
            },
            betName: {
                fontSize: 14,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "red"
            },
            betAmount: {
                fontSize: 13,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "green"
            },
            betOdds: {
                fontSize: 12,
                color: "rgba(246, 48, 85, 0.94)",
                textAlign: "center",
                // backgroundColor: "blue"
            },
        };
        return (
            <Container title={this._checkTitle()}
                       headerBackgroundColor={"linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")"}
                       headerFontColor={"#fff"}
                       showMenuButton={true}
                       menuArray={[{
                           name: "近期开奖",
                           icon: "jqkj",
                           href: 'RecentLotteryOpen',
                           parma: {code: this.is_code,templateType:"KLSF"}
                       }, {
                           name: "投注记录",
                           icon: "tzjl",
                           href: 'LotteryBetRecord'
                       }, {
                           name: "玩法说明",
                           icon: "wfsm",
                           href: 'LotteryIntroduction',
                           parma: {code: this.is_code,name:this._checkTitle()}
                       }]}
                       onPress={() => {
                           this.setState({showBetBox: false})
                           // this._hideBetBox();
                       }}>
                <div style={styles.lotteryBox}>
                    <div style={styles.topBox}>
                        {/*<RecentOpenList code={this.is_code} lottery_no={this.lotteryInfo.lottery_no}/>*/}
                        <TimeBox templateType={'KLSF'} lotteryInfo={this.lotteryInfo} code={this.is_code}
                                 lottery_no={this.lotteryInfo.lottery_no}
                                 openSecondCountdownEnd={() => {
                                     this._init_();
                                 }}
                                 stopSecondCountdownEnd={(lotteryInfo) => {
                                     this.lotteryInfo = lotteryInfo;
                                 }}/>
                    </div>
                    <div
                        style={{...betInfoStyles.betInfoBox, ...{height: model.clientHeight - model.headerHeight - 212}}}>
                        <div style={{display: "flex", flex: 1, flexDirection: "row",height:'100%'}}>
                            <div style={betInfoStyles.betTypeBox}>
                                <div className={'scorll'} style={betInfoStyles.scrollBox}>
                                    <BetTypeNav betTemplate={this.betTemplate} onPress={(i, item) => {
                                        this._changeBetTemplate(i, item);
                                    }}/>
                                </div>
                            </div>
                            <div style={betInfoStyles.betBallBox}>
                                <div className={'scorll'}
                                     style={{...betInfoStyles.scrollBox}}>
                                    <BetNumberlist templateType={'KLSF'}
                                                   style={{...this.state.showBetBox ? {maxHeight: model.clientHeight - model.headerHeight - 212 - 62} : {maxHeight: model.clientHeight - model.headerHeight - 212}}}
                                                   code={this.is_code} playName={this.currentBetTemplate.name}
                                                   data={this.currentBetTemplate.nums} onPress={(item) => {
                                        this._betNumberClick(item);
                                    }}
                                                   onClosePress={(item) => {
                                                       this._clearBetNumber(item);
                                                   }}
                                    />
                                </div>

                            </div>
                        </div>
                        {/* 投注弹窗 */}
                        <Betbox templateType={'KLSF'}
                                betAmount={this.currentBetNumber.amount}
                                onBlur={(text) => {
                                    this.hasSetAmount = !!text;
                                }}
                                code={this.is_code}
                                total={this.total}
                                betTemplate={this.currentBetTemplate}
                                betContent={this.currentBetNumber}
                                disabled={!!this.lotteryInfo.state}
                                show={this.state.showBetBox}
                                hideDialog={(newBetTemplate) => {
                                    this.currentBetTemplate = newBetTemplate;
                                    this.setState({showBetBox: false});
                                }}
                                onChangeText={(value) => {
                                    this._betAmount(value);
                                }}
                                amountInputFn={(value) => {
                                    this.hasSetAmount = !!value;
                                    this._betAmount(value)
                                }}/>
                    </div>

                    <Footer templateType={'KLSF'}
                            code={this.is_code}
                            lotteryInfo={this.lotteryInfo}
                            total={this.total}
                            betTemplate={this.betTemplate}
                            clearBetInfoCallbck={(newBetTemplate) => {
                                this.total = {count: 0, amount: 0};
                                this.betTemplate = newBetTemplate;
                                this.setState({render: this.state.render});
                            }}/>
                </div>
            </Container>
        )
    }
}
