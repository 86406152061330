//充值详情
import React from 'react';
import Container from "../component/container";
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import Storage from "../services/storage";
import Http from "../services/http";
import Image from '../component/image'
import Icon from '../utils/icon'
import MyInput from '../component/myInput'
import MyButton from '../component/myButton'
import style from '../utils/config.scss'
import copy from 'copy-to-clipboard';
import MyPicker from '../component/myPicker'
import global from "../global";

export default class RechargeDetail2 extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.state = {
            data: PublicUtils.getState(this.props.location.pathname).param,
            qrImgUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUoAAAFKAQMAAABB54RGAAAABlBMVEX///8AAABVwtN+AAABp0lEQVRoge2aza3EMAiEkVKAS0rrKWkLiMQmwBjyIr3byhxmDvmxP59Y8OCsCEVRP9LQ0CGb2qvsHxFVu7hOol3QuF+ozV2vNyr79WRjiRDtgBqgxx3iY5xYdAd2jhHthl5pGMCMM9G+qDh/peFcRLQdaootzlC997l/6yvRRShch9fL5yVEtAua8pRTxfIXQHQ5CsDmHhb/nhBBLhJtglqBREO2KUqlznbtJNoElRnJj21xdbND5STaBR3Rlfnb/plnHwbgFIRoD1TQhnn8cuU44f3rb4DoehRz5vOLKzmQi0R7oA6Eu0dDtgXve58SbYVmWXxE1/Sor0SXo3mQ6OdTsePBSNbAEl2LTju/e2s23b0XTZFSX4muRkvRhIcUPP0xJESXozift1IZZt8/s8SllFSiq9GBc95qFWOl1nabaAP0vShTE2MvnugiFC4x3X3akOL4ifZA424ZWHvoEYEl2gu1RItebH5cidaMaFNUxJs0V/6jTYk2RC0N8V0lLeWjNSO6HDX5SBj7zVMximY9zyK6FlUtVTI2thjKXCTaA6Uo6gf6AtLYNZiDew5zAAAAAElFTkSuQmCC",
            showProgress: false,
            amount: null,
            USDTNumber:0,
            nickname: null,
            rechargeInfo: {
                info: {},
                activity: [],
            },
            moneyTemplate: [],
            discount: null,
            mannerOptionFliter: false,
            manner: 0,
            isOther:false,
        }
        this.mannerOption = [
            {label: "银行柜台"},
            {label: "ATM现金"},
            {label: "ATM卡转"},
            {label: "网银转账"},
            {label: "微信支付"},
            {label: "支付宝支付"},
            {label: "QQ支付"},
            {label: "银联扫码"},
            {label: "云闪付"}
        ]
    }

    static navigationOptions = ({navigation}) => {
        return {}
    };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 初始化
     * @returns {Promise<void>}
     * @private
     */
    _init_ = async () => {
        if (this.state.data.is_pay === 2) {
            this._fetchRechargeChannelDetail();
        } else if (this.state.data.is_pay === 1) {
            this._fetchOnlineRechargeChannelDetail();
        } else {
            PublicUtils.showToast("充值类型错误");
        }
    };
    /**
     * 设置转账金额
     * @param amount
     */
    _setAmount = (amount) => {
        if(this.state.rechargeInfo.info.is_type == 5){
            var is_bank = Number(this.state.rechargeInfo.info.is_bank)
            this.setState({
                amount:amount,
                USDTNumber:amount.length == 0?"0":Math.round(Number(amount) / is_bank * 100 )/100 });
        }else{
            this.setState({amount: amount});
        }
    };
    _setUSDTNumber = (number) =>{
        var is_bank = Number(this.state.rechargeInfo.info.is_bank)
        this.setState({
            USDTNumber:number,
            amount: number.length == 0? "0" : Math.round(Number(number) * is_bank* 100 )/100
        },function () {
            console.log(this.state.amount)
        })
    }

    /**
     * 获取充值详情
     * @private
     */
    _fetchRechargeChannelDetail() {
        let params: any = {is_pay: this.state.data.is_pay, id: this.state.data.id};
        Http.request("MemberDepositShow", params, (res) => {
            console.log(res)
            // alert(JSON.stringify(res));
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/rechargedetail'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    let rechargeInfo = res.data;


                    // 开户银赋值
                    this.state.data.is_address = res.data.info.is_address;


                    rechargeInfo.is_pay = this.state.data.is_pay;
                    this.setState({
                        rechargeInfo: rechargeInfo,
                        moneyTemplate: rechargeInfo.info.preset_money.split(','),
                        discount: rechargeInfo.activity && (rechargeInfo.activity.length ? rechargeInfo.activity[0] : null)
                    });

                }
            }
        });
    }

    /**
     * 获取在线充值详情
     * @private
     */
    _fetchOnlineRechargeChannelDetail = () => {
        let params: any = {payId: this.state.data.payId};
        Http.request("getPaymentInfo", params, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/rechargedetail'});
            }
            if (res.code === 200) {
                let rechargeInfo = res.data;
                rechargeInfo.is_pay = this.state.data.is_pay;
                this.setState({
                    rechargeInfo: rechargeInfo,
                    moneyTemplate: rechargeInfo.info.preset_money.split(','),
                    discount: rechargeInfo.activity && (rechargeInfo.activity.length ? rechargeInfo.activity[0] : null)
                });
            }
        });
    };

    /**
     * 复制内容到剪贴板
     * @param text
     * @private
     */
    _copyText = async (text) => {
        // Clipboard.setString(text);
        // await Clipboard.getString();
        copy(text);
        PublicUtils.showToast("已复制到剪贴板");
    };
    /**
     * 保存二维码
     * @private
     */
    _saveQrImg = () => {
        // PublicUtils.downloadLocalImage(this.state.rechargeInfo.info.is_card);
    };

    nextFn=()=>{
        if (!this.state.amount) {
            PublicUtils.showToast("请输入充值金额");
            return;
        }


        if (!this.state.nickname) {
            PublicUtils.showToast("请输入转账人的昵称");
            return;
        }
        if (!this.state.manner && global.ID !== "wq") {
            PublicUtils.showToast("请选择汇款方式");
            return;
        }


        this.setState({isOther:true})



    }

    /**
     * 确认充值
     * @private
     */
    _finshRecharge = () => {
        if (!this.state.amount) {
            PublicUtils.showToast("请输入充值金额");
            return;
        }
        if (!this.state.nickname) {
            PublicUtils.showToast("请输入转账人的昵称");
            return;
        }
        if (!this.state.manner && global.ID !== "wq") {
            PublicUtils.showToast("请选择汇款方式");
            return;
        }


        let params = {
            is_pay: this.state.rechargeInfo.is_pay,
            id: this.state.rechargeInfo.info.id,
            is_amount: this.state.amount,
            is_activity: this.state.discount ? this.state.discount[0] : null,
            nickname: this.state.nickname,
            usdt_num:this.state.USDTNumber,
        };
        if (global.ID !== "wq") {
            params.manner = this.state.manner;
        }




        this.setState({showProgress: true});
        Http.request("PostMemberDeposit", params, (res) => {
            this.setState({showProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: '/rechargedetail'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    let parmas = {
                        is_amount: this.state.amount,
                        is_activity: this.state.discount ? this.state.discount[1] : "",
                        nickname: this.state.nickname,
                        message: res.data.message,
                        order_no: res.data.order_no
                    };

                    if(res.data.order_no){
                        PublicUtils.navigate("RechargeComplete", parmas);
                    }else{
                        PublicUtils.showToast('充值订单异常');
                    }
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });

    };
    /**
     * （视图）获取充值渠道
     * @private
     */
    _getRechargeChannel = () => {
        const styles = {
            tipsBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 8,
                paddingBottom: 8,
            },
            tipsIcon: {
                display: "flex",
                width: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            tipsBody: {
                display: "flex",
                flex: 1
            },
            tipsText: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            amountContainer: {
                padding: 10,
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999"
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)"
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexWrap: "wrap",
            },
            amountTemplateItem: {
                display: "flex",
                justifyContent: "center",
                width: (model.clientWidth - 20) / 3,
                border: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
                boxSizing: "border-box",
                marginBottom: 10
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },

            fundPasswordContainer: {
                display: "flex",
                minHeight: 45,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                borderBottom: "1px solid #f5f7f9",
                alignItems: "center",
                fontSize: 14
            },
            fundPasswordInput: {
                marginLeft: 10
            },
            accountInfoBox: {},
            accountInfoItem: {
                display: "flex",
                flexDirection: "row",
                padding: 10,
                // justifyContent: "space-between",
                // paddingLeft: 10,
                // paddingRight: 10,
                // height: 45,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10,
                alignItems: "center"
                // alignItems: "center",
            },
            accountInfoTitle: {
                fontSize: 14,
                color: "rgba(0,0,0,1)",
                width: 70
            },
            accountInfoItemText: {
                fontSize: 14,
                color: "#000",
                flex: 1
            },
            accountInfoItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            },
            accountInfoItemCopyBtn: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                borderRadius: 30,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            accountInfoItemCopyBtnText: {
                color: "#fff",
                fontSize: 12,
                marginLeft: 5
            },
            qrImgContainer: {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderBottom: "1px solid #f5f7f9",
            },
            qrImgBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            qrImg: {
                width: model.clientWidth / 2,
                height: model.clientWidth / 2
            },
            saveQrImgBtn: {
                paddingLeft: 30,
                paddingRight: 30,
                marginTop: 10
            },
            discountContainer: {},
            discountItemBox: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                height: 40,
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            discountItemBoxText: {
                fontSize: 14,
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            activeDiscount: {
                color: style['primaryColor' + model.SKINNAME]
            },
            discountCheckBox: {
                display: "flex",
                width: 5,
                height: 12,
                borderBottom: "2px solid " + style['primaryColor' + model.SKINNAME],
                borderRight: "2px solid " + style['primaryColor' + model.SKINNAME],
                transform: "rotate(45deg)"
            }
        };
        let viewContainer = null;
        if (this.state.rechargeInfo.info.is_class === 2) {
            viewContainer =
                (<div style={styles.qrImgContainer}>
                    <div style={{fontSize: 14}}>收款二维码：</div>
                    <div style={styles.qrImgBox}>
                        <Image hasPlaceholder={true}
                               placeholderSize={{
                                   width: model.clientWidth / 2,
                                   height: model.clientWidth / 2
                               }} uri={this.state.rechargeInfo.info.is_card} style={styles.qrImg}/>
                        <div onClick={() => {
                            this._saveQrImg()
                        }} style={{...styles.accountInfoItemCopyBtn, ...styles.saveQrImgBtn}}>
                            <div style={styles.accountInfoItemCopyBtnText}>保存二维码</div>
                        </div>
                    </div>
                </div>)
        }
        if (this.state.rechargeInfo.info.is_class === 0) {
            viewContainer =
                (
                    <div style={styles.accountInfoBox}>
                        <div style={styles.accountInfoItem}>
                            <div style={styles.accountInfoTitle}>收款人</div>
                            <div style={styles.accountInfoItemText}>{this.state.rechargeInfo.info.is_name}</div>
                            <div style={styles.accountInfoItemRight}>
                                <div onClick={() => {
                                    this._copyText(this.state.rechargeInfo.info.is_name)
                                }} style={styles.accountInfoItemCopyBtn}>
                                    <Icon style={{marginTop: -5}} icon={'ziliao'} size={12} color={'#fff'}/>
                                    <div style={styles.accountInfoItemCopyBtnText}>复制</div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.accountInfoItem}>
                            <div style={styles.accountInfoTitle}>收款平台</div>
                            <div style={styles.accountInfoItemText}>{this.state.rechargeInfo.info.is_bank}</div>
                            <div style={styles.accountInfoItemRight}>
                                <div onClick={() => {
                                    this._copyText(this.state.rechargeInfo.info.is_bank)
                                }} style={styles.accountInfoItemCopyBtn}>
                                    <Icon style={{marginTop: -5}} icon={'ziliao'} size={12} color={'#fff'}/>
                                    <div style={styles.accountInfoItemCopyBtnText}>复制</div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.accountInfoItem}>
                            <div style={styles.accountInfoTitle}>收款账户</div>
                            <div style={styles.accountInfoItemText}>{this.state.rechargeInfo.info.is_card}</div>
                            <div style={styles.accountInfoItemRight}>
                                <div onClick={() => {
                                    this._copyText(this.state.rechargeInfo.info.is_card)
                                }} style={styles.accountInfoItemCopyBtn}>
                                    <Icon style={{marginTop: -5}} icon={'ziliao'} size={12} color={'#fff'}/>
                                    <div style={styles.accountInfoItemCopyBtnText}>复制</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }

        if (this.state.rechargeInfo.info.is_class === 1) {
            viewContainer =
                (
                    <div style={styles.accountInfoBox}>
                        <div style={styles.accountInfoItem}>
                            <div style={styles.accountInfoTitle}>收款人</div>
                            <div style={styles.accountInfoItemText}>{this.state.rechargeInfo.info.is_name}</div>
                            <div style={styles.accountInfoItemRight}>
                                <div onClick={() => {
                                    this._copyText(this.state.rechargeInfo.info.is_name)
                                }} style={styles.accountInfoItemCopyBtn}>
                                    <Icon icon={'ziliao'} size={12} color={'#fff'}/>
                                    <div style={styles.accountInfoItemCopyBtnText}>复制</div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.accountInfoItem}>
                            <div style={styles.accountInfoTitle}>收款平台</div>
                            <div style={styles.accountInfoItemText}>{this.state.rechargeInfo.info.is_bank}</div>
                            <div style={styles.accountInfoItemRight}>
                                <div onClick={() => {
                                    this._copyText(this.state.rechargeInfo.info.is_bank)
                                }} style={styles.accountInfoItemCopyBtn}>
                                    <Icon icon={'ziliao'} size={12} color={'#fff'}/>
                                    <div style={styles.accountInfoItemCopyBtnText}>复制</div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.accountInfoItem}>
                            <div style={styles.accountInfoTitle}>收款账户</div>
                            <div style={styles.accountInfoItemText}>{this.state.rechargeInfo.info.is_card}</div>
                            <div style={styles.accountInfoItemRight}>
                                <div onClick={() => {
                                    this._copyText(this.state.rechargeInfo.info.is_card)
                                }} style={styles.accountInfoItemCopyBtn}>
                                    <Icon icon={'ziliao'} size={12} color={'#fff'}/>
                                    <div style={styles.accountInfoItemCopyBtnText}>复制</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
        return viewContainer;
    };
    /**
     * 转账人昵称
     * @returns {*}
     * @private
     */
    _renderTransferTitle = () => {
        let viewContainer = null;
        // if (this.state.rechargeInfo.info.is_class === 0) {
        //
        //     viewContainer = (<div style={{fontSize: 14}}>转账人姓名</div>);
        // }
        // if (this.state.rechargeInfo.info.is_class === 1) {
        //     if (this.state.rechargeInfo.info.is_type === 1) {
        //         viewContainer = (<div style={{fontSize: 14}}>支付宝昵称</div>);
        //     }
        //     if (this.state.rechargeInfo.info.is_type === 2) {
        //         viewContainer = (<div style={{fontSize: 14}}>微信昵称</div>);
        //     }
        //     if (this.state.rechargeInfo.info.is_type === 3) {
        //         viewContainer = (<div style={{fontSize: 14}}>QQ昵称</div>);
        //     }
        //
        // }
        // if (this.state.rechargeInfo.info.is_class === 2) {
        //     viewContainer = (<div style={{fontSize: 14}}>转账人姓名</div>);
        // }
        if (this.state.rechargeInfo.info.is_class === 0) {
            viewContainer = (<div style={{fontSize: 14}}>汇款人姓名</div>);
            if (global.ID == "ca" && this.state.rechargeInfo.info.is_title.includes("微信" || "支付宝")) viewContainer = (
                <div style={{fontSize: 14}}>汇款人姓名+到账时间</div>);
        } else {
            if (this.state.rechargeInfo.info.is_type === 0 || this.state.rechargeInfo.info.is_type === 4) {
                viewContainer = (<div style={{fontSize: 14}}>汇款人姓名</div>);
            }
            if (this.state.rechargeInfo.info.is_type === 1) {
                viewContainer = (<div style={{fontSize: 14}}>支付宝昵称</div>);
                if (global.ID === "ca") viewContainer = (<div style={{fontSize: 14}}>支付宝昵称+到账时间</div>);

            }
            if (this.state.rechargeInfo.info.is_type === 2) {
                viewContainer = (<div style={{fontSize: 14}}>微信昵称</div>);
                if (global.ID === "ca") viewContainer = (<div style={{fontSize: 14}}>微信昵称+到账时间</div>);
            }
            if (this.state.rechargeInfo.info.is_type === 3) {
                viewContainer = (<div style={{fontSize: 14}}>QQ昵称</div>);
            }
        }
        return viewContainer;
    }

    /**
     * 拷贝虚拟币功能
     */
    copy = () =>{
        const copyEle = document.querySelector(".contentText");
        const range = document.createRange();
        window.getSelection.removeAllRanges();
        range.selectNode(copyEle);
        window.getSelection.addRange(range);
        const copyStatus = document.execCommand("copy")
        if(copyStatus){
            console.log("复制成功")
        }else{
            console.log("复制失败")
        }
    }

    render() {
        const styles = {
            tipsBox: {
                display: "flex",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 8,
                paddingBottom: 8,
            },
            tipsIcon: {
                display: "flex",
                width: 25,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            tipsBody: {
                display: "flex",
                flex: 1
            },
            tipsText: {
                color: "#fff",
                marginLeft: 5,
                fontSize: 14
            },
            amountWrapper: {
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10
            },
            amountContainer: {
                padding: 10,
                // border: "1px solid rgba(0,0,0,0.06)",
                // backgroundColor: "#fff",
                // borderRadius: 3,
            },
            usdtContainer:{
                display:"flex"
            },
            amountTitle: {},
            amountInputContainer: {
                display: "flex",
                // borderBottom: "1px solid #f5f7f9",
                flexDirection: "row",
                marginTop: 25,
                paddingBottom: 10
                // alignItems: "center"
            },
            amountInputIconBox: {
                display: "flex",
                width: 30,
                flexDirection: "row",
                alignItems: "center"
            },
            amountInputBox: {},
            amountInput: {
                fontSize: 25,
            },
            transferTips: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
            },
            transferTipsLeft: {
                display: "flex",
                flexDirection: "row",
            },
            transferTipsColor1: {
                color: "#999"
            },
            transferTipsColor2: {
                color: "rgba(245, 72, 68, 0.93)"
            },
            transferTipsRight: {},
            amountTemplate: {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                borderRadius: 3,
                overflow: "hidden",
                flexWrap: "wrap",
                backgroundColor: "#fff"
            },
            amountTemplateItem: {
                display: "flex",
                justifyContent: "center",
                width: (model.clientWidth - 45) / 3,
                border: "1px solid #f5f7f9",
                paddingTop: 10,
                paddingBottom: 10,
                boxSizing: "border-box",
                marginBottom: 10
            },
            amountTemplateItemText: {
                textAlign: "center"
            },
            btnBox: {
                marginTop: 20,
                // paddingLeft: 10,
                // paddingRight: 10
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },

            fundPasswordContainer: {
                display: "flex",
                minHeight: 45,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                alignItems: "center",
                fontSize: 14,
                marginBottom: 10
            },
            fundPasswordInput: {
                marginLeft: 10
            },
            accountInfoBox: {},
            accountInfoItem: {
                display: "flex",
                flexDirection: "row",
                padding: 10,
                // justifyContent: "space-between",
                // paddingLeft: 10,
                // paddingRight: 10,
                // height: 45,
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
                marginBottom: 10,
                alignItems: "center"
                // alignItems: "center",
            },
            accountInfoTitle: {
                fontSize: 14,
                color: "rgba(0,0,0,1)",
                width: 70
            },
            accountInfoItemText: {
                fontSize: 14,
                color: "#000",
                flex: 1
            },
            accountInfoItemRight: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            },
            accountInfoItemCopyBtn: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                borderRadius: 30,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            accountInfoItemCopyBtnText: {
                color: "#fff",
                fontSize: 12,
                marginLeft: 5
            },
            qrImgContainer: {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderBottom: "1px solid #f5f7f9",
            },
            qrImgBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            qrImg: {
                width: model.clientWidth / 2,
                height: model.clientWidth / 2
            },
            saveQrImgBtn: {
                paddingLeft: 30,
                paddingRight: 30,
                marginTop: 10
            },
            discountContainer: {
                border: "1px solid rgba(0,0,0,0.06)",
                backgroundColor: "#fff",
                borderRadius: 3,
            },
            discountItemBox: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                height: 40,
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #f5f7f9"
            },
            discountItemBoxText: {
                fontSize: 14,
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            activeDiscount: {
                color: style['primaryColor' + model.SKINNAME]
            },
            discountCheckBox: {
                display: "flex",
                width: 5,
                height: 12,
                borderBottom: "2px solid " + style['primaryColor' + model.SKINNAME],
                borderRight: "2px solid " + style['primaryColor' + model.SKINNAME],
                transform: "rotate(45deg)"
            },
            xnbAddress:{
                padding:"5px 0",
                overflow:"hidden"
            },
            addressTitle:{
               display:"flex",
               justifyContent:"space-between",
               paddingTop:5
            },
            addressTips:{
                lineHeight:"28px"
            },
            xnbBox:{
                width:"100%"
            },
            content:{
                width:"100%"
            },
            skdz:{
                marginTop:20,
                marginBottom:20,
                padding:10,
                backgroundColor:'white',
            }
        };
        return (
            <Container style={{
                backgroundColor: "#f5f7f9",
                paddingBottom: 30,
                paddingTop: 10,
                paddingLeft: 10,
                paddingRight: 10,
            }} title={'充值详情'}>
                {this.state.rechargeInfo.info.is_user_set_money === 0 ?
                    <div style={styles.tipsBox}>
                        {/*<div style={styles.tipsIcon}>*/}
                        {/*<Icon icon={'tips'} color={'#fff'} size={16}/>*/}
                        {/*</div>*/}
                        <div style={styles.tipsBody}>
                            <div style={styles.tipsText}>此渠道不允许输入金额，请在预设金额中选择</div>
                        </div>
                    </div> : null
                }
                {
                    this.state.isOther?<div>
                        <div style={styles.accountInfoItem}>

                            <div style={styles.accountInfoTitle}>充值方式</div>
                            <div
                              style={styles.accountInfoItemText}>{this.state.data.is_title || this.state.data.payName}</div>
                        </div>
                        {this._getRechargeChannel()}
                        {
                            this.state.rechargeInfo.info.is_address ?
                              <div style={styles.accountInfoItem}>
                                  <div style={styles.accountInfoTitle}>开户行</div>
                                  <div
                                    style={styles.accountInfoItemText}>{this.state.data.is_address || this.state.data.is_address}</div>
                              </div> : null
                        }


                        <div style={styles.btnBox}>
                            <MyButton title={'上一步'}
                                      onPress={() => {
                                          this.setState({isOther:false})
                                      }}/>
                        </div>


                        <div style={styles.btnBox}>
                            <MyButton title={'确认充值'} disabled={this.state.showProgress} showProgress={this.state.showProgress}
                                      onPress={() => {
                                          this._finshRecharge()
                                      }}/>
                        </div>


                    </div>:<div>
                        <div style={styles.accountInfoItem}>

                            <div style={styles.accountInfoTitle}>充值方式</div>
                            <div
                              style={styles.accountInfoItemText}>{this.state.data.is_title || this.state.data.payName}</div>
                        </div>
                        {/*{this._getRechargeChannel()}*/}
                        {
                            this.state.rechargeInfo.info.is_address ?
                              <div style={styles.accountInfoItem}>
                                  <div style={styles.accountInfoTitle}>开户行</div>
                                  <div
                                    style={styles.accountInfoItemText}>{this.state.data.is_address || this.state.data.is_address}</div>
                              </div> : null
                        }
                        {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                        <div style={styles.amountWrapper}>


                            <div style={styles.amountContainer}>
                                <div style={styles.amountTitle}>{global.ID == 'yy'?'充值金额':'转入金额'}</div>
                                <div style={styles.amountInputContainer}>
                                    <div style={styles.amountInputIconBox}>
                                        <Icon icon={'rmb'} size={24} color={'#000'}/>
                                    </div>
                                    <div style={styles.amountInputBox}>
                                        <MyInput style={styles.amountInput}
                                                 value={this.state.amount}
                                                 readOnly={!this.state.rechargeInfo.info.is_user_set_money}
                                                 placeholder={"(限额：" + (this.state.rechargeInfo.info.is_min || this.state.rechargeInfo.info.payMin || "0.00") + "~" + (this.state.rechargeInfo.info.is_max || this.state.rechargeInfo.info.payMax || "0.00") + ")"}
                                                 onChangeText={(text) => {
                                                     // this._setAmount(text);
                                                     // this.setState({amount: text});
                                                 }}
                                                 onKeyUp={(text) => {
                                                     if (this.state.rechargeInfo.info.is_user_set_money) {
                                                         if (this.state.rechargeInfo.info.is_point) {
                                                             this._setAmount(text);
                                                             // this.setState({amount: text.replace(/^[0-9]*[1-9][0-9]*$/, '')});
                                                         } else {
                                                             this._setAmount(text.replace(/\D/g, ''));
                                                             // this.setState({amount: text.replace(/\D/g, '')});
                                                         }
                                                     } else {
                                                         this._setAmount(text);
                                                         // this.setState({amount: text.replace(/\D/g, '')});
                                                     }
                                                 }}/>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.amountTemplate}>
                                {
                                    this.state.moneyTemplate.map((item, key) => {
                                        return (
                                          <div key={key} onClick={() => {
                                              this._setAmount(item);
                                          }}
                                               style={{...styles.amountTemplateItem, ...this.state.amount === item ? styles.active : null}}>
                                              <div
                                                style={{...styles.amountTemplateItemText, ...this.state.amount === item ? styles.activeText : null}}>￥{item}
                                              </div>
                                          </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            this.state.data.is_pay === 2 ? <div>
                                {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                                <div style={styles.fundPasswordContainer}>
                                    {
                                        this._renderTransferTitle()
                                    }
                                    <div style={this.state.rechargeInfo.info.is_type == 5 ?styles.content:null}>
                                        {
                                            ((this.state.rechargeInfo.info.is_type == 1 || this.state.rechargeInfo.info.is_type == 2) && global.ID == "ca") ?
                                          <MyInput style={styles.fundPasswordInput} value={this.state.nickname}
                                                   placeholder={'请输入姓名+到账时间'} onChangeText={(text) => {
                                              this.setState({nickname: text});
                                          }}/> : <MyInput style={styles.fundPasswordInput} value={this.state.nickname}
                                                          placeholder={'请输入姓名'} onChangeText={(text) => {
                                              this.setState({nickname: text});
                                          }}/>
                                        }


                                    </div>
                                </div>
                            </div> : null
                        }



                        {
                            this.state.data.is_pay === 2 && global.ID !== "wq" && this.state.rechargeInfo.info.is_type != 5 ?
                              <div>
                                  <div style={styles.fundPasswordContainer}>
                                      <div style={{fontSize: 14}}>汇款方式</div>
                                      <div style={{
                                          display: "flex",
                                          flex: 1,
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center"
                                      }}>

                                          <MyInput onPress={() => {
                                              this.setState({mannerOptionFliter: true});
                                              console.log(this.state.manner)
                                          }} style={styles.fundPasswordInput}
                                                   readOnly={true}
                                                   value={this.state.manner}
                                                   placeholder={'请选择汇款方式'} onChangeText={(text) => {
                                          }}/>
                                          <Icon icon={'sjjt'} size={12} color={'#000'}/>
                                      </div>
                                  </div>
                              </div> : null
                        }
                        <div>
                            <div style={styles.discountContainer}>
                                {/*<div onClick={() => {*/}
                                {/*this.setState({discount: null});*/}
                                {/*}} style={styles.discountItemBox}>*/}
                                {/*<div*/}
                                {/*style={{...styles.discountItemBoxText, ...this.state.discount === null ? styles.activeDiscount : null}}>不参与优惠*/}
                                {/*</div>*/}
                                {/*{*/}
                                {/*this.state.discount === null &&*/}
                                {/*<div style={styles.discountCheckBox}/>*/}
                                {/*}*/}
                                {/*</div>*/}
                                {
                                    this.state.rechargeInfo.activity && this.state.rechargeInfo.activity.map((item, key) => {
                                        return (
                                          <div key={key} onClick={() => {
                                              if (global.ID !== "ao" && global.ID !== "aw" && global.ID !== "y7" && global.ID !== "ct" && global.ID !== "bw" && global.ID !== "y2" && global.ID !== "ar" && global.ID !== "br") {
                                                  this.setState({discount: this.state.discount === item ? "" : item});
                                              } else {
                                                  this.setState({discount: item});
                                              }
                                          }} style={styles.discountItemBox}>
                                              <div
                                                style={{...styles.discountItemBoxText, ...this.state.discount === item ? styles.activeDiscount : null}}>{item[1]}</div>
                                              {this.state.discount === item &&
                                              <div style={styles.discountCheckBox}/>}
                                          </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/*<div style={{height: 10, backgroundColor: "#f5f7f9"}}/>*/}
                        {
                            this.state.rechargeInfo.info.point_text ?
                              <div style={{padding: 10, fontSize: 16}}
                                   dangerouslySetInnerHTML={{__html: this.state.rechargeInfo.info.point_text}}/> : null
                        }



                        <div style={styles.btnBox}>
                            <MyButton title={'下一步'} disabled={this.state.showProgress} showProgress={this.state.showProgress}
                                      onPress={() => {
                                          this.nextFn()
                                      }}/>
                        </div>
                    </div>
                }
                <MyPicker rowCount={3}
                          title={'汇款方式'}
                          model={this.mannerOption}
                          display={this.state.mannerOptionFliter}
                          onHiden={() => {
                              this.setState({mannerOptionFliter: false})
                          }}
                          onItemPress={(item) => {
                              this.setState({mannerOptionFliter: false, manner: item.value[0].value.label});
                              // this.setState({playType: item, showPlayTypeFliter: false, currpage: 1}, () => {
                              //     this._fetchList();
                              // });
                          }}
                />
            </Container>
        )
    }
}
